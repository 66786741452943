import player, {
  AnimationConfigWithData,
  AnimationConfigWithPath,
  AnimationDirection, AnimationEventCallback, AnimationEventName,
  AnimationItem,
} from "lottie-web";
import { LottiePlayer } from "ngx-lottie/src/symbols";

// Note we need a separate function as it's required
// by the AOT compiler.
export function playerFactory() {
  return player;
}

export function mockPlayerFactory(): LottiePlayer {
  return {
    play(name?: string) {},
    pause(name?: string) {},
    stop(name?: string) {},
    setSpeed(speed: number, name?: string) {},
    setDirection(direction: AnimationDirection, name?: string) {},
    searchAnimations(
      animationData?: any,
      standalone?: boolean,
      renderer?: string
    ) {},
    loadAnimation(
      params: AnimationConfigWithPath | AnimationConfigWithData
    ): AnimationItem {
      return {
        // PWA required
        destroy: () => {
        },
        setSubframe: (b: boolean) => {
        },
        addEventListener: (name: AnimationEventName, callback: AnimationEventCallback) => {
        }
      } as AnimationItem;
    },
    destroy(name?: string) {},
    registerAnimation(element: Element, animationData?: any) {},
    setQuality(quality: string | number) {},
    setLocationHref(href: string) {},
  };
}
