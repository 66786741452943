import { Component, HostBinding, Input, OnInit } from "@angular/core";

export type TooltipDirection = "left" | "right" | "top-small-screen";

@Component({
  selector: "oz-warning-tooltip",
  templateUrl: "./warning-tooltip.component.pug",
})
export class WarningTooltipComponent implements OnInit {
  @HostBinding("class") hostClasses = "inline-block";
  @Input() direction: TooltipDirection = "right";
  classes = {};
  showTooltip = false;
  ngOnInit() {
    switch (this.direction) {
      case "left":
        this.classes = { left: true };
        break;
      case "right":
        this.classes = { right: true };
        break;
      case "top-small-screen":
        this.classes = { topSmallScreen: true };
        break;
      default:
        throw new Error(
          `Unsupported tooltip opening direction: "${this.direction}"`
        );
    }
  }
}
