import { Component, Output, EventEmitter, Input } from "@angular/core";

import { SWITCH_ANIMATION_MS } from "./switch.component";

@Component({
  selector: "oz-switch-deprecated",
  templateUrl: "./switch-deprecated.component.pug",
})
export class SwitchDeprecatedComponent {
  public isChecked: boolean;
  public animationMS = SWITCH_ANIMATION_MS;

  @Input() set value(newValue: boolean) {
    this.isChecked = newValue;
  }

  @Output() valueChange = new EventEmitter<boolean>();

  onChange($event) {
    this.isChecked = $event.target.checked;
    this.valueChange.emit(this.isChecked);
  }
}
