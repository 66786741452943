import { Component } from "@angular/core";
import { AdminConfig } from "../../../../services/admin-config.service";
import { removeManufacturerName } from "../../../../shared/pipes/device-name.pipe";
import { DfuModalService } from "../../../../services/dfu-modal.service";
import { Toasts } from "../toasts.service";
import {
  OzDeviceWithRepositoryFirmwareAndWarnings,
  PostponeDfuService,
} from "./postpone-dfu.service";

@Component({
  selector: "oz-postpone-dfu-toast",
  templateUrl: "./postpone-dfu-toast.component.pug",
})
export class PostponeDfuToastComponent {
  // Used in case many devices need update
  expanded = false;
  showDfuConfirmModal = false;
  devices: OzDeviceWithRepositoryFirmwareAndWarnings[];
  selectedDevice: OzDeviceWithRepositoryFirmwareAndWarnings;
  translateParams = {
    deviceName: "",
  };

  constructor(
    private postponeDfuService: PostponeDfuService,
    private dfuModal: DfuModalService,
    private toasts: Toasts,
    private adminConfig: AdminConfig
  ) {
    this.postponeDfuService.devices.subscribe((devices) => {
      this.devices = devices;
      this.updateTranslateParams();
    });
  }

  onAskMeLater(device: OzDeviceWithRepositoryFirmwareAndWarnings) {
    if (this.postponeDfuService.postponeDfu(device)) {
      this.toasts.push({
        type: "status",
        status: "success",
        text:
          this.adminConfig.dfuRemindHours === 1
            ? "SUCCESSFULLY_POSTPONED_DFU_ONE_HOUR"
            : "SUCCESSFULLY_POSTPONED_DFU_MANY_HOURS",
        textParams: {
          hours: this.adminConfig.dfuRemindHours,
          deviceName: removeManufacturerName(device.name),
        },
      });
    }
  }

  onDfuConfirm() {
    this.dfuModal.open({
      device: this.selectedDevice,
      dfuType: "Remote",
      archiveLocation: this.selectedDevice.repositoryFirmware.archiveLocation,
    });
  }

  private updateTranslateParams() {
    // In case of a single device, we need to include device's name into the toast's text.
    if (this.devices?.length === 1) {
      this.translateParams.deviceName = removeManufacturerName(
        this.devices[0].name
      );
    }
  }
}
