import { ILoggingService } from "../../app/services/logging.service";
import { SETTINGS } from "../../app/utils/constants";
import { UnsupportedDeviceError } from "./errors";
import { WhDevice } from "./wh-device";
import { hex4l } from "./wh-utils";


const UVC_WB_MODE = "whiteBalanceMode";
const UVC_WB_TEMP = "colorTemperature";
const UVC_WB_MANUAL = "manual";
const UVC_WB_AUTOMATIC = "continuous";

export class UvcDevice {

  constructor(private device: WhDevice, private logger: ILoggingService) {
  }

  loadDevice(): Promise<void> {
    return this.loadUvcData();
  }

  private findVideoDevice(): Promise<MediaDeviceInfo | undefined> {
    // looking for "(<vid>:<pid>)" in name
    const ids = `${this.device.vendorId().toString(16).padStart(4, '0')}:${this.device.productId().toString(16).padStart(4, '0')}`;
    return navigator.mediaDevices.enumerateDevices().then((arr) => {
      this.logger.info("media devices", arr);
      let devices = arr.filter(di => 'videoinput' === di.kind);
      if (0 === devices.length) {
        return Promise.reject(new UnsupportedDeviceError("no video device found"));
      }
      devices = devices.filter(di => di.label?.includes(ids));
      if (1 !== devices.length) {
        this.logger.warn(`unexpected media device count of ${devices.length}: ${devices.map(d => d.label)}`);
        return undefined;
      }
      return devices[0];
    });
  }

  private loadUvcData(): Promise<void> {
    return this.findVideoDevice()
      .then(devInfo => {
        const opts: any = { video: { pan: true, tilt: true, zoom: true } };
        if (undefined !== devInfo) {
          opts.video.deviceId = devInfo.deviceId;
        }
        return navigator.mediaDevices.getUserMedia(opts);
      })
      .then(stream => {
        const tracks = stream.getVideoTracks();
        const track = tracks[0];

        const capabilities: MediaTrackCapabilities = track.getCapabilities();
        const settings: MediaTrackSettings = track.getSettings();
        this.logger.info(`Camera capabilities ${track.label}, track-cnt=${tracks.length}`, capabilities);
        this.logger.info("Camera settings", settings);

        const wbId = parseInt(SETTINGS.WHITE_BALANCE);
        const settingDefs = this.device.settingDefs();
        for (let sett of settingDefs) {
          if (undefined !== sett.uvc) {
            const s = sett.uvc;
            const cap: any = capabilities[s.name as keyof MediaTrackCapabilities];
            const value = settings[s.name as keyof MediaTrackSettings]?.toString();
            if (wbId === sett.id) {
              this.loadUvcWhiteBalance(track, wbId);
            }
            else if (cap !== undefined && value !== undefined) {
              if (cap.min !== undefined && cap.max !== undefined && cap.step !== undefined) {
                this.addSetting(sett.id, [cap.min, cap.max, cap.step], val => this.setUvcSetting(track, sett.id, s.name, val));
                this.device.storeSetting(sett.id, value);
              }
            }
          }
        }
      })
  }

  private loadUvcWhiteBalance(track: MediaStreamTrack, id: number) {
    const caps: MediaTrackCapabilities = track.getCapabilities();
    const settings: MediaTrackSettings = track.getSettings();
    const ctCap: any = caps[UVC_WB_TEMP as keyof MediaTrackCapabilities];
    const ctVal = settings[UVC_WB_TEMP as keyof MediaTrackSettings];
    const wbCap = caps[UVC_WB_MODE as keyof MediaTrackCapabilities];
    const wbVal = settings[UVC_WB_MODE as keyof MediaTrackSettings];
    if (ctCap && undefined !== ctCap.min && ctCap.max && ctCap.step && undefined !== ctVal && wbCap && wbVal) {
      let val = ctVal.toString();
      const min = ctCap.min.toString();
      const max = ctCap.max.toString();
      const step = ctCap.step.toString();
      if (parseInt(val) < parseInt(min) || parseInt(max) < parseInt(val)) {
        this.logger.warn(`color temperature [${val}] is out of range [${min}, ${max}]`);
        val = min;
      }
      this.addSetting(id, [min, max, step], (val, auto) => this.setUvcWhiteBalance(track, id, val, auto), true);
      this.device.storeSetting(id, val, UVC_WB_MANUAL !== wbVal);
    }
  }

  private setUvcWhiteBalance(track: MediaStreamTrack, id: number, value: string, automatic?: boolean): Promise<void> {
    const mtc: any = {};
    mtc[UVC_WB_TEMP] = value;
    mtc[UVC_WB_MODE] = automatic ? UVC_WB_AUTOMATIC : UVC_WB_MANUAL;
    this.logger.info(`Applying constraint`, mtc);
    return this.applyUvcSettings(track, mtc, id, value, automatic);
  }

  private setUvcSetting(track: MediaStreamTrack, id: number, name: string, value: string): Promise<void> {
    const mtc: any = {};
    mtc[name] = value;
    this.logger.info(`Applying constraint ${name}=${mtc[name]}`);
    return this.applyUvcSettings(track, mtc, id, value, false);
  }

  private applyUvcSettings(track: MediaStreamTrack, mtc: any, id: number, value: string, automatic?: boolean): Promise<void> {
    return track.applyConstraints({advanced: [mtc]}).then(() => {
      this.device.storeSetting(id, value, automatic);
      this.device.publishSettingsIfChanged();
    });
  }

  private addSetting(settingId: number, options: Iterable<string> | ArrayLike<string>,
    setter: (val: string, autoEnabled?: boolean) => Promise<void>, autoSupported?: boolean): void {
    this.logger.info(`setting ${hex4l(settingId)}: UVC [${options}]${autoSupported ? ' auto' : ''}`);
    this.device.addSetting(settingId, options, setter, autoSupported);
  }
}
