import { Injectable } from "@angular/core";
import { Observable, BehaviorSubject, EMPTY } from "rxjs";
import { map } from "rxjs/operators";
import { DeviceManagerService, OzDevice } from "./device-manager.service";
import { StorageService } from "./storage.service";

// List of devices which need to be reconnected to complete the update process
export const NEED_RECONNECT_TO_COMPLETE_DFU_SETTING =
  "NEED_RECONNECT_TO_COMPLETE_DFU";

@Injectable({
  providedIn: "root",
})
export class DfuNeedReconnectService {
  private store = new BehaviorSubject<string[]>([]);

  constructor(
    private storageService: StorageService,
    private deviceManager: DeviceManagerService
  ) {
    // Set initial state of the store by reading the setting value
    this.store.next(this.readSettingValue());

    // Any time internal store is changed, update the setting value
    this.store.subscribe((newList) => {
      this.storageService.setItem(
        NEED_RECONNECT_TO_COMPLETE_DFU_SETTING,
        newList
      );
    });

    // Check for the reconnected devices, and remove them from the store
    this.deviceManager.getDevices().subscribe((devices: OzDevice[]) => {
      devices.forEach((device) => {
        if (device.reconnected) {
          this.removeNeedReconnect(device);
        }
      });
    });
  }

  setNeedReconnect(device: OzDevice): void {
    if (device?.uniqueId) {
      const { uniqueId } = device;
      const currentList = this.store.getValue();
      if (!currentList.includes(uniqueId)) {
        currentList.push(uniqueId);
        this.store.next(currentList);
      }
    }
  }

  getNeedReconnect(device: OzDevice): Observable<boolean> {
    return device
      ? this.store.pipe(map((all) => all.includes(device?.uniqueId)))
      : EMPTY;
  }

  private removeNeedReconnect(device: OzDevice): void {
    if (device) {
      const currentValue = this.store.getValue();
      const index = currentValue.indexOf(device.uniqueId);
      if (index > -1) {
        currentValue.splice(index, 1);
        this.store.next(currentValue);
      }
    }
  }

  private readSettingValue(): string[] {
    const foo = this.storageService.getItem(
      NEED_RECONNECT_TO_COMPLETE_DFU_SETTING
    );
    return foo ? foo : [];
  }
}
