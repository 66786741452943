import { Component, Input, forwardRef, OnChanges } from "@angular/core";
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";

export interface TranslatedOption {
  // if value not always set, must set useIndexAsValue
  value?: string;
  text: string;
  // this will override the text value if present and a non-empty string
  translatedText?: string;
  params?: { [key: string]: string | number };
  disabled?: boolean;
}

export enum Placeholder {
  UNAVAILABLE_PLACEHOLDER,
  SELECT_PLACEHOLDER,
}

export type TranslatedOptions = Array<TranslatedOption>;

/**
 * Note regarding translate option:
 * Optional translation of 'text' with optional 'params' of a TranslatedOption (see type above)
 * @example oz-dropdown([translate]="true", [options]="[{value: 0, text: 'GENERAL.FAVORITE_NUMBER', params: {number: 0}}]")
 *
 */
@Component({
  selector: "oz-dropdown",
  templateUrl: "./dropdown.component.pug",
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DropdownComponent),
      multi: true,
    },
  ],
})
export class DropdownComponent implements ControlValueAccessor, OnChanges {
  @Input() className?: string;
  @Input() options: TranslatedOption[];
  @Input() disabled: boolean;
  @Input() translate: boolean = false;
  @Input() useIndexAsValue: boolean = false;

  private onChange = (_: any) => {};
  private onTouched = () => {};
  public value: any;

  currentPlaceholder = {
    value: null,
    text: null,
  };

  public get placeholder(): typeof Placeholder {
    return Placeholder;
  }

  ngOnChanges() {
    this.setPlaceholder();
  }

  emitChanges() {
    this.onChange(this.value);
  }

  writeValue(val: any): void {
    this.value = val;
    this.setPlaceholder();
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  /**
   * This function MUST pair with the [value] logic on the `option` tag in the dropdown.component.pug template.
   */
  trackByFn = (index: number, option: TranslatedOption) => {
    return this.useIndexAsValue ? index : option.value;
  };

  setPlaceholder() {
    if (!this.options || this.options.length === 0) {
      this.currentPlaceholder.value = this.placeholder.UNAVAILABLE_PLACEHOLDER;
      this.currentPlaceholder.text = "DROPDOWN.UNAVAILABLE_PLACEHOLDER";
      this.value = null;

      return;
    }

    if (
      this.options.filter((option, index) => {
        if (this.useIndexAsValue) {
          return index === +this.value;
        }

        return option.value === this.value;
      }).length === 0
    ) {
      this.currentPlaceholder.value = this.placeholder.SELECT_PLACEHOLDER;
      this.currentPlaceholder.text = "DROPDOWN.SELECT_PLACEHOLDER";
      this.value = null;

      return;
    }

    this.currentPlaceholder.value = null;
  }
}
