import { cameraControlsAnimation } from "../animations/camera.animations";
import { DetailNavService } from "../services/detail-nav.service";
import {
  FavoriteOption,
  FavoritesService,
} from "../services/favorites.service";
import { ILoggingService } from "../services/logging.service";
import { BehaviorSubject, Subject, of, combineLatest } from "rxjs";
import { DeviceManagerService } from "../services/device-manager.service";
import { ParamMap, Router } from "@angular/router";
import {
  filter,
  mergeMap,
  take,
  distinctUntilChanged,
  takeUntil,
  debounceTime,
} from "rxjs/operators";
import { ActivatedRoute } from "@angular/router";
import { Device, DeviceSetting } from "@poly/hub-native";
import {
  AfterViewInit,
  Component,
  ElementRef,
  HostListener,
  OnDestroy,
  OnInit,
  ViewChild,
} from "@angular/core";
import { NotificationsService } from "../services/notifications.service";
import {
  SETTINGS,
  CONTROLS_PAGE_SUPPORTED_SETTINGS,
  CONTROLS_PAGE_HIDDEN_SETTINGS,
} from "../utils/constants";
import {
  cloneDeep as _cloneDeep,
  debounce as _debounce,
  find as _find,
  isEqual as _isEqual,
} from "lodash";
import { UtilityService } from "../services/utility.service";
// import * as remote from "@electron/remote";
import { TranslateService } from "@ngx-translate/core";
import {
  defaultFeatures,
  Features,
  MainWindowState,
  StateService,
} from "../services/state.service";
import { Subscriptions } from "../utils/subscriptions";
import { SettingsUI } from "../device-settings/settings-ui.model";
import { Toast, Toasts } from "../shared/components/toast/toasts.service";
import {PolytronServiceApi} from "../polytron/polytron.service.api";

// TODO: const fs = require("fs");

const VIDEO_NOT_STARTED_TOAST: Toast = {
  type: "status",
  status: "error",
  text: "NOTIFICATIONS.VIDEO_NOT_STARTED.MESSAGE",
};

export interface ControlsDeviceSetting extends DeviceSetting {
  type: "switch" | "slider" | "dropdown";
  disabled: boolean;
}

type uiActionTypes =
  | "expand"
  | "grid"
  | "zoom_in"
  | "zoom_out"
  | "zoom_in_levels"
  | "zoom_out_levels"
  | "pan_left"
  | "pan_right"
  | "tilt_up"
  | "tilt_down"
  | "snapshot"
  | "preview"
  | "led_auto"
  | "led_linked";
// settings that can only operate during preview; included here for parity with type
const PREVIEW_TYPES: Array<uiActionTypes> = ["expand", "grid", "snapshot"];

const NEEDS_TRACKING_MODE_ON = [
  "0xC18", // Tracking Speed
  "0xC1A", // Frame Size
];

const NEEDS_TRACKING_MODE_OFF = [
  "0xC0A", // Pan
  "0xC0B", // Tilt
  "0xC0D", // Zoom
];

// Cameras P5 and P21 have fixed values for zoom
export const CAMERA_ZOOM_LEVELS = [1, 1.3, 2, 3, 4];
// Available resolutions for cameras
export const CAMERA_RESOLUTIONS = {
  DCI_4K: { width: 3840, height: 2160 }, // DCI 4K
  FULL_HD: { width: 1920, height: 1080 }, // Full HD
  HD: { width: 1280, height: 720 }, // High Definition
  qHD: { width: 960, height: 540 }, // qHD one-quarter of a Full HD
  SD: { width: 640, height: 360 }, // Standard Definition
  sHD: { width: 480, height: 270 }, // One sixteenth of a Full HD
};
// List of cameras with supported preview resolutions
export const CAMERAS = {
  "431A": {
    // modelId
    name: "P21",
    width: CAMERA_RESOLUTIONS.FULL_HD.width,
    height: CAMERA_RESOLUTIONS.FULL_HD.height,
    zoomResolutions: [
      // resolutions per zoom level
      {
        width: CAMERA_RESOLUTIONS.FULL_HD.width, // width for zoom level defined in this object
        height: CAMERA_RESOLUTIONS.FULL_HD.height, // height for zoom level defined in this object
        zoomFrom: CAMERA_ZOOM_LEVELS[0], // resolution will be implemented from this zoom level
        zoomTo: CAMERA_ZOOM_LEVELS[1], // resolution will be implemented to this zoom level
      },
      {
        width: CAMERA_RESOLUTIONS.HD.width,
        height: CAMERA_RESOLUTIONS.HD.height,
        zoomFrom: CAMERA_ZOOM_LEVELS[1],
        zoomTo: CAMERA_ZOOM_LEVELS[2],
      },
      {
        width: CAMERA_RESOLUTIONS.qHD.width,
        height: CAMERA_RESOLUTIONS.qHD.height,
        zoomFrom: CAMERA_ZOOM_LEVELS[2],
        zoomTo: CAMERA_ZOOM_LEVELS[3],
      },
      {
        width: CAMERA_RESOLUTIONS.SD.width,
        height: CAMERA_RESOLUTIONS.SD.height,
        zoomFrom: CAMERA_ZOOM_LEVELS[3],
        zoomTo: CAMERA_ZOOM_LEVELS[4],
      },
      {
        width: CAMERA_RESOLUTIONS.sHD.width,
        height: CAMERA_RESOLUTIONS.sHD.height,
        zoomFrom: CAMERA_ZOOM_LEVELS[4], // this is maximum level
        zoomTo: CAMERA_ZOOM_LEVELS[4] + 1, // but set some bigger to cover this option
      },
    ],
  },
  "9296": {
    name: "P5",
    width: CAMERA_RESOLUTIONS.FULL_HD.width,
    height: CAMERA_RESOLUTIONS.FULL_HD.height,
    zoomResolutions: [
      {
        width: CAMERA_RESOLUTIONS.FULL_HD.width,
        height: CAMERA_RESOLUTIONS.FULL_HD.height,
        zoomFrom: CAMERA_ZOOM_LEVELS[0],
        zoomTo: CAMERA_ZOOM_LEVELS[1],
      },
      {
        width: CAMERA_RESOLUTIONS.HD.width,
        height: CAMERA_RESOLUTIONS.HD.height,
        zoomFrom: CAMERA_ZOOM_LEVELS[1],
        zoomTo: CAMERA_ZOOM_LEVELS[2],
      },
      {
        width: CAMERA_RESOLUTIONS.qHD.width,
        height: CAMERA_RESOLUTIONS.qHD.height,
        zoomFrom: CAMERA_ZOOM_LEVELS[2],
        zoomTo: CAMERA_ZOOM_LEVELS[3],
      },
      {
        width: CAMERA_RESOLUTIONS.SD.width,
        height: CAMERA_RESOLUTIONS.SD.height,
        zoomFrom: CAMERA_ZOOM_LEVELS[3],
        zoomTo: CAMERA_ZOOM_LEVELS[4],
      },
      {
        width: CAMERA_RESOLUTIONS.sHD.width,
        height: CAMERA_RESOLUTIONS.sHD.height,
        zoomFrom: CAMERA_ZOOM_LEVELS[4],
        zoomTo: CAMERA_ZOOM_LEVELS[4] + 1,
      },
    ],
  },
  "92B2": {
    name: "R30",
    width: CAMERA_RESOLUTIONS.DCI_4K.width,
    height: CAMERA_RESOLUTIONS.DCI_4K.height,
    zoomResolutions: [], // leave empty if max resolution is for all zoom levels
  },
  "92B4": {
    name: "R30 NR",
    width: CAMERA_RESOLUTIONS.DCI_4K.width,
    height: CAMERA_RESOLUTIONS.DCI_4K.height,
    zoomResolutions: [],
  },
  "9290": {
    name: "P15",
    width: CAMERA_RESOLUTIONS.DCI_4K.width,
    height: CAMERA_RESOLUTIONS.DCI_4K.height,
    zoomResolutions: [],
  },
  "9217": {
    name: "STUDIO USB",
    width: CAMERA_RESOLUTIONS.DCI_4K.width,
    height: CAMERA_RESOLUTIONS.DCI_4K.height,
    zoomResolutions: [],
  },
};

/**
 * Device Controls
 * Used on route /device/:id/controls
 * only available if video device, and connected
 *
 * Favorite storage is in localstorage, not in the native layer.
 * Favorite management is an Angular task, with reads and writes
 * to the native layer for a device happening when requested
 * by Angular.
 *
 * The data model for this is for the ControlsComponent to update
 * FavoritesService, which then updates LensSettingsService.
 *
 * When a change is finally made to LensSettingsService, FavoritesService
 * emits an update which is then tracked by ControlsComponent.
 * This is a synchronous (localstorage) task.
 *
 * It is *NECESSARY* that changes to favorites and which favorite is
 * selected are first pushed to FavoritesService.  Then, when the change
 * has taken effect, ControlsComponent can operate on the new values.
 * This is in contrast to local state management inside ControlsComponent.
 * This prevents spaghetti soup state management, and is similar to a "redux"
 * style of state management.
 */
@Component({
  templateUrl: "./controls.component.pug",
  animations: [cameraControlsAnimation],
})
export class ControlsComponent implements OnInit, AfterViewInit, OnDestroy {
  private uiActionDebounceMS = 100;
  private device: Device;
  private deviceInitFinished: boolean = false;
  private mediaDevice: MediaDeviceInfo;
  private hideCameraControlsAfterInitMS = 3000;
  private hideCameraControlsTimeout;
  private favoritesInitialized = false;
  private _onDestroy$: Subject<void> = new Subject();
  private subs = new Subscriptions();
  public showControlLabels = true;
  public showFavoriteDeleteModal = false;
  public uiToggles = {
    expand: false,
    // default preview to off state
    preview: false,
    grid: false,
    led_linked: true,
    led_auto: true,
  };
  public uiToggleHover = {
    preview: false,
    expand: false,
    snapshot: false,
    grid: false,
    led_left_auto: false,
    led_right_auto: false,
    led_left_link: false,
    led_right_link: false,
  };
  public baseColor = "#000000";
  public hoverColor = "#0270E3";
  public previewVisible$ = new BehaviorSubject<boolean>(this.uiToggles.preview);
  public features: Features = defaultFeatures;
  // track the current device's settings
  public controlSettings$: BehaviorSubject<
    Array<ControlsDeviceSetting>
  > = new BehaviorSubject([]);
  // holds updated key/value pair of data for easy pug access
  public controlSettingsValues: {
    [key in keyof typeof SETTINGS]?: string;
  } = {};
  // primary data object for the pug view; updated when absolutely necessary
  // to prevent jarring UI effects, not on every remote
  // deviceManager.getDeviceSettings observable emission
  public controlSettingsVisible: Array<ControlsDeviceSetting> = [];
  // updating controlSettingsVisible is jarring to UI; thus track if an update is required,
  // e.g. after a restore defaults
  public updateControlSettingsNextCycle = true;
  public takeSnapshot$ = new Subject<boolean>();
  public UtilityService = UtilityService;
  public smallCameraMaxWidth = 450;
  public startingVideo = false;
  // NOTE: This will by dynamically overridden
  public largeCameraMaxWidth = 450;
  public largeCameraHardWidthLimit = 750;
  public cameraMaxWidth = this.smallCameraMaxWidth;
  // cameraMaxHeight is used to avoid a flash of unstyled content as webcam gets the video frame
  // 16:9 aspect ratio is in design spec (.5625), but 4:3 (.75) matches cameras like EE Cube;
  // choosing the higher of the two
  public maxAspectRatio = 0.75;
  public smallCameraMaxHeight = this.smallCameraMaxWidth * this.maxAspectRatio;
  public largeCameraMaxHeight = this.largeCameraMaxWidth * this.maxAspectRatio;
  public cameraMaxHeight = this.smallCameraMaxHeight;
  public cameraMarginTop = 0;
  public zoomSetting: ControlsDeviceSetting;
  public zoomSettingWithFixedZoomLevels: number = 0; // current zoom level for cameras that have list of fixed zoom levels
  public fixedZoomLevels: number = CAMERA_ZOOM_LEVELS.length - 1; // number of fixed zoom levels
  public vanityLEDLeftSetting: ControlsDeviceSetting;
  public vanityLEDRightSetting: ControlsDeviceSetting;
  public uiActionWaitMS = 100;
  public uiActionLoopMS = 100;
  public uiActionLoopMsZoomWithFixedLevels = 250;
  public videoSrc: MediaStream;
  public cameraControlsHover$: BehaviorSubject<boolean> = new BehaviorSubject(
    true
  );
  // this resolution will be applied for video preview, if camera has no defined other resolution in CAMERAS
  private previewResolution = {
    width: 320,
    height: 180,
  };
  // variable that tells ui whether the used camera has a fixed zoom levels (true) or continuous (false)
  public uiCameraWithFixedZoomLevels = false;
  // this favoriteOptions is specific to the currently loaded device
  // **IMPORTANT** do not make direct changes to this; rather,
  // save a new setting through favoritesService, which will automatically
  // update the controller variable
  public favoriteOptions: Array<FavoriteOption>;
  // 0-based array index
  public favoriteOptionSelected: number;
  public currentFavoriteState = {
    editing: false,
    nameUntranslated: "",
    name: "",
    canEditName: false,
    canDelete: false,
    showSaved: false,
    // for example, IT Managed favorite (managed by the cloud) operates in read only mode
    readOnly: false,
  };
  public isWindows = UtilityService.isWindows();
  public windowsWithPreviewOn = this.isWindows && this.uiToggles["preview"];
  public windowsWithPreviewOff = this.isWindows && !this.uiToggles["preview"];

  // Number of pending requests (for changing device settings)
  private pendingDeviceSettingChangeRequests = 0;

  dependencies: SettingsUI.SettingDependency[] =
    SettingsUI.cameraControlsDependencies;

  @ViewChild("container", { static: false }) container: ElementRef;
  @ViewChild("video") videoEle: ElementRef;
  /** Canvas for Video Snapshots */
  @ViewChild("canvas", { static: true }) private canvas: any;
  hasCameraAccess: boolean = false;
  componentDestroyed = false;

  // Show modal if video controls can't be read
  settingReadError = false;
  commonMarkup = { line_feed: "<br>" };

  // Disable "Favorities" dropdown while saving is in progress
  savingInProgress = false;

  constructor(
    private detailNavService: DetailNavService,
    private deviceManager: DeviceManagerService,
    private favoritesService: FavoritesService,
    private logger: ILoggingService,
    private notificationService: NotificationsService,
    private route: ActivatedRoute,
    private router: Router,
    private stateService: StateService,
    private toasts: Toasts,
    private translateService: TranslateService,
    private polytron: PolytronServiceApi
) {}

  async ngOnInit() {
    this.initFeatures();
    this.initSubscriptions();
    await this.setInitialValues();
    this.initDevice();
  }

  /**
   * Update currently selected favorite in favorites service
   * Local effects of favorite index changing should be in getFavoriteIndex$ handler,
   * not here.
   */
  favoriteValueChanged() {
    this.favoritesService.setFavoriteIndex(
      this.device.uniqueId,
      this.favoriteOptionSelected
    );
  }

  getCurrentFavorite(): FavoriteOption {
    return this.favoriteOptions?.[this.favoriteOptionSelected];
  }

  /**
   * Gets selected option and updates various UI features such as whether to
   * show "edit name" in context menu.
   */
  updateCurrentFavoriteState(favorite: FavoriteOption) {
    if (!favorite) {
      // unrecoverable, cannot update with no favorite found
      return;
    }

    // update this.currentFavoriteState next cycle, otherwise getFavoriteOptionsByID$ only fires once (!?)
    this.currentFavoriteState.canEditName = favorite.canEditName;
    this.currentFavoriteState.canDelete = favorite.canDelete;
    this.currentFavoriteState.nameUntranslated = favorite.text;
    this.currentFavoriteState.readOnly = favorite.isITManaged;
    if (favorite.translatedText) {
      this.currentFavoriteState.name = favorite.translatedText;

      return;
    }

    this.translateService
      .get(favorite.text, favorite.params)
      .subscribe((translation) => {
        this.currentFavoriteState.name = translation;
      });
  }

  favoriteAction(type: "delete" | "edit_name" | "save_as") {
    switch (type) {
      case "edit_name":
        this.currentFavoriteState.editing = true;

        break;
      case "delete":
        this.showFavoriteDeleteModal = true;

        break;
      case "save_as":
        const saveAsSuccess = this.favoritesService.saveAsFavorite(
          this.device.uniqueId,
          this.favoriteOptionSelected
        );

        if (false !== saveAsSuccess) {
          this.favoriteOptionSelected = saveAsSuccess;
          this.currentFavoriteState.editing = true;
          this.currentFavoriteState.showSaved = true;
          this.hideSavedDelay(3000);
        }

        break;
      default:
        break;
    }
  }

  private hideSavedDelay(delayMS = 3000) {
    setTimeout(() => {
      this.currentFavoriteState.showSaved = false;
    }, delayMS);
  }

  private async setInitialValues() {
    // on load, show user camera controls for n milliseconds, then hide
    this.hideCameraControlsTimeout = setTimeout(() => {
      this.setCameraControlsHover(false);
    }, this.hideCameraControlsAfterInitMS);

    // prevent error from detail.component.pug use of controlsRoute$:
    // ExpressionChangedAfterItHasBeenCheckedError: Expression has changed after it was checked.
    setTimeout(() => {
      this.stateService.setState("DeviceDetails", { controlsRoute: true });
    }, 0);

    // Mac allows multiple applications to handle stream, so start preview immediately
    if (UtilityService.isMac() || UtilityService.isBrowser()) {
      this.previewVisible$.next(true);
    }

    try {
      this.hasCameraAccess = await this.polytron.askForCameraAccess();

    } catch (e) {
      // context problem for tests, ignore
      this.hasCameraAccess = false;
    }

    this.detailNavService.configure({ showNav: true });
  }

  private initSubscriptions() {
    this.subs.add(
      this.controlSettings$.subscribe(() => {
        this.settingsUpdated();
      })
    );

    // watch for changes to previewVisible$ and make changes
    this.subs.add(
      this.previewVisible$
        .pipe(distinctUntilChanged())
        .subscribe((previewNowVisible) => {
          previewNowVisible ? this.initializeVideo() : this.stopCameraPreview();

          if (previewNowVisible) {
            // in case previewVisible change was programmatically executed
            this.ensureUiToggle("preview", true);
          }

          // if expanded size and preview turns off, contract size & turn off grid
          if (!previewNowVisible) {
            this.ensureUiToggle("preview", false);
            this.ensureUiToggle("expand", false);
            this.ensureUiToggle("grid", false);
          }

          // update view tracking variable; this is to make controls.component.pug DRYer
          this.windowsWithPreviewOn =
            this.isWindows && this.uiToggles["preview"];
          this.windowsWithPreviewOff =
            this.isWindows && !this.uiToggles["preview"];
        })
    );

    this.subs.add(
      this.stateService
        .getDeepState$("MainWindow", "state", MainWindowState.OPEN)
        .subscribe((state: MainWindowState) => {
          switch (state) {
            case MainWindowState.OPEN:
            case MainWindowState.OPEN_MAXIMIZED:
              // if main Electron window is open, only start preview on Mac automatically
              // note Mac does not have access to the preview hide & show control button
              if (UtilityService.isMac() || UtilityService.isBrowser()) {
                this.previewVisible$.next(true);
              }

              break;
            case MainWindowState.CLOSED:
            case MainWindowState.MINIMIZED:
              // if main Electron window gets closed or minimized, stop preview on Mac & Windows OS
              this.previewVisible$.next(false);

              break;
            default:
              this.logger.error(
                "Controls - error: Unexpected window state value received",
                {
                  state,
                }
              );

              break;
          }
        })
    );
  }

  private initDevice() {
    // get path parameter, set device
    this.subs.add(
      this.route.parent.paramMap
        .pipe(
          // if paramMap changes, will be a route change; thus take 1
          take(1),
          mergeMap((paramMap: ParamMap) => {
            return this.deviceManager.getDevice(paramMap.get("id")); // id is Device#uniqueId
          }),
          takeUntil(this._onDestroy$),
          filter((d) => !!d),
          mergeMap(async (d) => {
            if (!d.isConnected) {
              await this.router.navigate(["../overview"], {
                relativeTo: this.route,
              });

              return of([]);
            }

            this.device = d;

            this.initializeFavorites(this.device.uniqueId);

            return this.deviceManager.getDeviceSettings(this.device.id);
          }),
          takeUntil(this._onDestroy$),
          mergeMap((r) => r), // Unwraps it one more layer
          takeUntil(this._onDestroy$),
          // This is debounced because one setting update may trigger
          // 50+ responses from getDeviceSettings.
          filter((settings: DeviceSetting[]) => {
            // Will skip device settings responses if new requests are at the moment being executed by native layer.
            // This is to avoid "jumpy" sliders (LENS-1542).
            if (this.pendingDeviceSettingChangeRequests === 0) {
              return true;
            }

            this.pendingDeviceSettingChangeRequests -= 1;
            return false;
          }),
          debounceTime(300)
        )
        .subscribe((settings: DeviceSetting[]) => {
          // dev assistance for settings, should not be uncommented in develop branch
          if (!UtilityService.isBuildBranchMaster()) {
            this.logSettings(settings);
          }

          const controlSettings = settings
            // Filter out any settings we don't want to show on the controls page
            .filter((s) =>
              CONTROLS_PAGE_SUPPORTED_SETTINGS.includes(
                UtilityService.unpadHex(s.id, true)
              )
            )
            .map((s: ControlsDeviceSetting) => {
              this.setControlType(s);
              return s;
            })
            .sort((a, b) => {
              return (
                CONTROLS_PAGE_SUPPORTED_SETTINGS.indexOf(
                  UtilityService.unpadHex(a.id, true)
                ) -
                CONTROLS_PAGE_SUPPORTED_SETTINGS.indexOf(
                  UtilityService.unpadHex(b.id, true)
                )
              );
            });
          this.logger.info("Original settings", settings);
          this.logger.info("Filtered settings", controlSettings);

          // keep local controlSettings$ updated with value changes
          this.controlSettings$.next(controlSettings);
          // Set default resolution for camera based on modelId
          this.setPreviewResolution();
          // Does this camera have fixed zoom levels? If yes, this is important info for UI, to use alternative slider.
          this.uiCameraWithFixedZoomLevels = !!CAMERAS[this.device?.modelId]
            ?.zoomResolutions.length;

          //As a mitigation for the LENS-8225 we avoid to potentialy set settingReadError flag to true.
          //It causes a false alarm (pop-up message and user is unable to access the camera controls).
          //this.settingReadError = !this.device.cameraControlsAvailable;

          // Enable "Favorities" dropdown because device settings are saved
          this.savingInProgress = false;
          this.deviceInitFinished = true;
        })
    );
  }

  /**
   * Set preview camera resolution based on camera model and current zoom level
   * Function sets this.previewResolution.width and this.previewResolution.height
   * based on camera model and current zoom level
   */
  setPreviewResolution() {
    const cameraModel = this.device?.modelId.toUpperCase();

    if (CAMERAS[cameraModel]) {
      const currentZoom = this.zoomSetting?.value; // Zoom can be set even when preview is turned off
      const minZoom = this.zoomSetting?.options[0]; // We need this to convert zoom levels into real zoom numbers

      if (CAMERAS[cameraModel].zoomResolutions.length) {
        // Camera has separate resolutions for different zoom levels
        const newResolution = CAMERAS[cameraModel].zoomResolutions.find(
          (x) =>
            currentZoom >= x.zoomFrom * minZoom &&
            currentZoom < x.zoomTo * minZoom
        );
        if (newResolution) {
          this.previewResolution.width = newResolution.width;
          this.previewResolution.height = newResolution.height;
        } else {
          this.logger.error(
            "Controls - error: Resolution for zoom level " +
              currentZoom +
              " is missing!"
          );
        }
      } else {
        // The camera does not have separate resolutions for different zoom levels
        this.previewResolution.width = CAMERAS[cameraModel].width;
        this.previewResolution.height = CAMERAS[cameraModel].height;
      }
    } else {
      this.logger.error(
        "Controls - error: Resolution for camera model " +
          cameraModel +
          " is missing!"
      );
    }
  }

  /**
   * Response to feature flags.  These are useful for testing, e.g.
   * disabling favorites or enabling experimental (pending hub-native adoption
   * or not fully built out) camera controls.
   *
   * To prevent race conditions, this is synchronous / immediate based on current stateService value.
   */
  private initFeatures() {
    this.features = this.stateService.getState("Features", {});
  }

  /**
   * User-friendly and searchable (e.g. SATURATION or 0xC03) logging of each setting for a given device.
   */
  logSettings(settings: DeviceSetting[]) {
    this.logger.info(">>> settings", settings);

    const SETTINGS_BY_ID = {};
    for (const KEY in SETTINGS) {
      const id = UtilityService.unpadHex(SETTINGS[KEY], true);
      SETTINGS_BY_ID[id] = KEY;
    }

    settings.forEach((setting) => {
      const hex = UtilityService.unpadHex(setting.id, true);
      const niceName = SETTINGS_BY_ID[hex] || "Not Mapped";
      this.logger.info(`>>> setting ${setting.id} ${niceName}`, {
        value: setting.value,
        options: setting.options,
      });
    });
  }

  ngAfterViewInit() {
    // this needs to be here to ensure the container viewChild properly binds
    this.resizeWindow(false);
  }

  /**
   * Initialize this.videoSrc with proper device video stream.
   *
   * @param delayCallMS - optional delay call in milliseconds
   */
  private async initializeVideo(delayCallMS = 0) {
    if (delayCallMS) {
      await new Promise((resolve) => setTimeout(resolve, delayCallMS));
    }

    if (!this.deviceInitFinished) {
      // retry after device set up
      await this.initializeVideo(300);

      return;
    }

    // safety check, never need to initializeVideo again since component destroyed
    if (this.componentDestroyed) {
      return;
    }

    // no need to recursively call initializeVideo, because showing preview will call initializeVideo again
    if (this.videoSrc) {
      return;
    }

    this.startingVideo = true;
    this.mediaDevice = await this.findMediaDevice(this.device);

    // could not connect to selected video device
    if (!this.mediaDevice) {
      this.hasCameraAccess = false;
    }

    if (!this.hasCameraAccess) {
      this.toasts.push(VIDEO_NOT_STARTED_TOAST);
      this.startingVideo = false;

      return;
    }

    try {
      console.info('Initializing video for', this.mediaDevice?.deviceId);
      this.videoSrc = await navigator.mediaDevices.getUserMedia({
        video: {
          deviceId: { exact: this.mediaDevice?.deviceId },
          width: { ideal: this.previewResolution.width },
          height: { ideal: this.previewResolution.height },
        },
      });

      // in the case where getUserMedia takes a long time AND component is already in destroy process,
      // for instance with quick navigation away from component, intentionally tie up loose ends
      if (this.componentDestroyed) {
        this.stopCameraPreview();
        this.startingVideo = false;

        return;
      }
    } catch (e) {
      // intentionally not logged, likely user has not yet given camera permissions
      // this.hasCameraAccess = false;
      const context = {
        deviceId: this.mediaDevice?.deviceId,
        width: this.previewResolution.width,
        height: this.previewResolution.height,
      };
      this.logger.error(
        "Controls - error: User has not yet given camera permissions. Error occured: ",
        {
          context,
          e,
        }
      );
    }

    this.startingVideo = false;

    if (this.videoSrc) {
      this.videoEle.nativeElement.srcObject = this.videoSrc;
      this.videoEle.nativeElement.play();

      return;
    }

    await this.initializeVideo(2000);

    this.toasts.push(VIDEO_NOT_STARTED_TOAST);
  }

  /**
   * @param uniqueId - device.uniqueId, which is the same even when USB port changes
   */
  initializeFavorites(uniqueId: string) {
    if (!this.features.cameraFavorites) {
      return;
    }

    this.subs.add(
      // catch just first resolution of favoriteOptions$ and controlSettings$ for set up
      combineLatest([
        this.controlSettings$,
        this.favoritesService.favoriteOptions$,
      ])
        .pipe(
          filter(([controlSettings, favoriteOptions]) => {
            // if no control setting (empty array or undefined), don't pass through
            if (!controlSettings?.length) {
              return false;
            }

            // favoriteOptions is {} (truthy) on init; check that it is truthy for safety
            return !!favoriteOptions;
          }),
          // will take 1 AFTER the filter passes a successful value for both observables
          take(1)
        )
        .subscribe(([controlSettings]) => {
          // even though take(1) is in effect, force prevent running more than once per lift of controls component
          if (this.favoritesInitialized) {
            return;
          }

          // NOTE favoriteOptions$ value is not used because favoritesService already has it,
          // and it may be different by time this zip fires
          this.favoriteOptions = this.favoritesService.ensureFavorites(
            uniqueId,
            controlSettings
          );

          this.updateCurrentFavoriteState(this.getCurrentFavorite());

          this.initFavoritesWatcher(uniqueId);

          this.favoritesInitialized = true;
        })
    );
  }

  /**
   * After initialization/checking of favorites, create watchers for favorite options
   * and favorite selected.
   * This is run AFTER controlSettings is initialized for predictable state.
   */
  initFavoritesWatcher(uniqueId: string) {
    this.subs.add(
      this.favoritesService
        .getFavoriteOptionsByUniqueId$(uniqueId)
        .pipe(filter((options) => !!options))
        .subscribe((favoriteOptions) => {
          this.favoriteOptions = favoriteOptions;

          this.updateCurrentFavoriteState(this.getCurrentFavorite());
        })
    );

    // effects of a changed favorite
    this.subs.add(
      this.favoritesService
        .getFavoriteIndex$(this.device.uniqueId)
        .subscribe((index) => {
          // favorite already selected properly, no changes required
          if (this.favoriteOptionSelected === index) {
            return;
          }

          this.favoriteOptionSelected = index;

          const favorite = this.getCurrentFavorite();
          if (!favorite) {
            // unrecoverable error, could not find selected option
            return;
          }

          this.updateCurrentFavoriteState(favorite);

          // if settings stored, apply them to device settings
          if (favorite.settings) {
            // Tracking mode needs to be sent to device's AI module. It takes some time to switch the modes.
            // Change takes at least 200ms on device and it is slower then other settings.

            // Exclude TrackingMode from all settings
            const trackingModeSetting: ControlsDeviceSetting = favorite.settings.find(
              (setting) =>
                UtilityService.hexIsEqual(setting.id, SETTINGS.TRACKING_MODE)
            );
            const remainingSettings: ControlsDeviceSetting[] = favorite.settings.filter(
              (setting) =>
                !UtilityService.hexIsEqual(setting.id, SETTINGS.TRACKING_MODE)
            );

            // Disable "Favorities" dropdown unless device settings are saved
            this.savingInProgress = true;
            // Set device settings
            if (!!trackingModeSetting) {
              this.pendingDeviceSettingChangeRequests += 2;
              // There is change of Tracking mode
              // Set Tracking mode first
              this.deviceManager.setDeviceSetting(
                this.device.id,
                trackingModeSetting
              );
              // Set all other values with delay of 2500ms (this number is the result of experiments)
              setTimeout(() => {
                this.deviceManager.setDeviceSettings(
                  this.device.id,
                  remainingSettings
                );
              }, 2500); // We need much more time than 200ms to see applied Tracking mode
            } else {
              this.pendingDeviceSettingChangeRequests += 1;
              // There is no change of Tracing mode
              this.deviceManager.setDeviceSettings(
                this.device.id,
                favorite.settings
              );
            }

            // flag UI as needing to update next time controlSettings are updated from DeviceManager
            this.updateControlSettingsNextCycle = true;

            // TWS -- this may be temporary if we decide
            // to publish the device from DM.  For now,
            // it's necessary to see the changes when switching favorites
            this.controlSettings$.next(favorite.settings);

            return;
          }

          // otherwise, save current settings to favorite as new values
          this.saveCurrentSettingsToFavorite();
        })
    );
  }

  setControlType(ds: ControlsDeviceSetting): void {
    if (
      ds.options[0] === "0" && // min
      ds.options[1] === "1" && // max
      ds.options[2] === "1"
    ) {
      ds.type = "switch";
    } else if (
      (ds.options[0] === "false" && ds.options[1] === "true") ||
      (ds.options[0] === "true" && ds.options[1] === "false")
    ) {
      ds.type = "switch";
      // Some devices report them backwards so we just straighten them out
      ds.options[0] = "false";
      ds.options[1] = "true";
    } else if (
      ds.id === SETTINGS.INVERT.toLowerCase() &&
      ds.options[0] === "0" &&
      ds.options[1] === "3"
    ) {
      ds.type = "switch";
    } else if (isNaN(ds.options[0])) {
      ds.type = "dropdown";
    } else {
      ds.type = "slider";
    }
  }

  async findMediaDevice(device: Device) {
    // const videoDevices = (
    //   (await navigator.mediaDevices.enumerateDevices()) || []
    // ).filter((d) => d.kind === "videoinput");
    const videoDevices = await navigator.mediaDevices.enumerateDevices();
    const hexDevicePid = UtilityService.getDeviceVideoPid(device);
    let pidVideoDevices = videoDevices.filter(
      (d) => d.label && d.label.indexOf(hexDevicePid) >= 0
    );

    if (pidVideoDevices.length === 0) {
      this.logger.error("Controls - error: Cannot find matching media device", {
        device,
        videoDevices,
      });
      return null;
    }

    if (pidVideoDevices.length > 1) {
      pidVideoDevices = pidVideoDevices.filter(
        (d) => d.label && d.label.indexOf(`:${hexDevicePid}`) >= 0
      );
    }
    if (pidVideoDevices.length > 1) {
      this.logger.error(
        `Controls - error: multiple matching video devices for ${hexDevicePid}`,
        {
          device,
          pidVideoDevices,
        }
      );
    }

    return pidVideoDevices[0];
  }

  /**
   * Take action on device setting update:
   * 1. Update the individual setting via deviceManager
   * 2. Update data local to this controller.
   *
   * @param setting
   * @param toggleAuto - whether to toggle (boolean flip) the auto flag; this is
   *                     NOT the desired state of setting.autoEnabled
   */
  valueChanged(setting: ControlsDeviceSetting, toggleAuto = false) {
    const updatedSetting: Partial<DeviceSetting> = {
      id: setting.id,
      value: setting.value + "",
    };

    if (toggleAuto && setting.autoEnabledSupported) {
      setting.autoEnabled = !setting.autoEnabled;
      updatedSetting.autoEnabled = setting.autoEnabled;
    }

    this.logger.debug("Setting changed: ", setting);
    this.pendingDeviceSettingChangeRequests += 1;
    this.deviceManager.setDeviceSetting(this.device.id, updatedSetting);

    // always update controlSettings to keep controlSettings$ and controlSettingsValues
    // up to date with proper string values
    // without this, zoom (which has a slider AND zoom in/out controls) would not stay in sync
    // also, the getDeviceSettings subscription does not reliably fire after an update,
    // so need to keep a pristine local state
    const newControlSettings = _cloneDeep(this.controlSettings$.getValue());
    const settingIndex = newControlSettings.findIndex((clonedSetting) =>
      UtilityService.hexIsEqual(setting.id, clonedSetting.id)
    );
    if (settingIndex >= 0) {
      // a slider will set a value as a number; thus, always
      // coerce setting.value to string
      setting.value = setting.value + "";
      newControlSettings[settingIndex] = setting;
    }

    this.controlSettings$.next(newControlSettings);

    // If changed settings is ZOOM, let we check if we need to update preview resolution
    if (
      UtilityService.hexIsEqual(setting.id, SETTINGS.ZOOM) &&
      this.windowsWithPreviewOn &&
      CAMERAS[this.device?.modelId].zoomResolutions.length
    ) {
      this.setPreviewResolution();
      // replace track with new width and height, update stream constraints
      const constraints = {
        width: this.previewResolution.width,
        height: this.previewResolution.height,
      };

      this.videoSrc?.getVideoTracks().forEach((track) =>
        track.applyConstraints(constraints).catch((e) => {
          this.logger.error(
            "Controls - error: Cannot apply given constraints ",
            {
              constraints,
              e,
            }
          );
        })
      );
    }
  }

  /**
   * Debounce will wait until function stops being called for n milliseconds
   * to issue an update, which helps for cases like sliders.  Not recommended
   * for things like pan-tilt-zoom (PTZ) buttons.
   */
  valueChangedDebounced = _debounce(
    (setting: ControlsDeviceSetting) => {
      this.valueChanged(setting);
    },
    this.uiActionDebounceMS,
    { leading: false, trailing: true }
  );
  /**
   * For cameras without continual zoom, like P5 and P21
   * we need to map key of zoom value into zoom value
   * in order to save it into camera
   *
   * @param zoomKey - key of CAMERA_ZOOM_LEVELS array
   */
  mapZoomValueChanged(zoomKey) {
    this.zoomSetting.value =
      CAMERA_ZOOM_LEVELS[zoomKey] * this.zoomSetting.options[0];
    this.valueChangedDebounced(this.zoomSetting);
  }

  /**
   * Perform effects on a controlSettings$ update.
   *
   * This can be called from a debounced device manager update or a local (Angular) change
   * such as changing "Tracking Mode" dropdown.
   *
   * @example input [{id: '0xc19', value: 'Group', ...}, ...]
   * will update this.controlSettingsValues = { 'TRACKING_MODE': 'Group', ... }
   */
  settingsUpdated() {
    // For each control setting, save value for simple access, such as
    // this.controlSettingsVisible['ZOOM']
    const controlSettingsVisible = this.controlSettings$.value.filter(
      (controlSetting) => {
        const search = UtilityService.unpadHex(controlSetting.id, true);

        return !CONTROLS_PAGE_HIDDEN_SETTINGS.includes(search);
      }
    );

    // this is an expensive and jarring operation for the user experience,
    // so only apply changes when setting ids have changed
    const existingSettingIDs = this.controlSettingsVisible.map((o) => o.id);
    const newSettingIDs = controlSettingsVisible.map((o) => o.id);
    // if setting IDs has changed or length is different, do a full update
    const controlSettingsNotInitialized = !this.controlSettingsVisible.length;
    const controlSettingsHaveDrasticallyChanged = !_isEqual(
      existingSettingIDs,
      newSettingIDs
    );

    // if UI is in readOnly mode, safe and effective to update every time
    if (
      this.currentFavoriteState.readOnly ||
      controlSettingsNotInitialized ||
      controlSettingsHaveDrasticallyChanged ||
      this.updateControlSettingsNextCycle
    ) {
      this.controlSettingsVisible = controlSettingsVisible;
      this.updateControlSettingsNextCycle = false;
    }

    this.controlSettingsValues = {};
    this.controlSettings$.value.forEach((setting: DeviceSetting) => {
      const settingsIndex = Object.values(SETTINGS).findIndex((i) =>
        UtilityService.hexIsEqual(setting.id, i)
      );
      const settingName = Object.keys(SETTINGS)[settingsIndex];
      this.controlSettingsValues[settingName] = setting.value;
    });

    this.controlSettingsVisible.forEach((setting) => {
      setting.disabled = this.isSettingDisabled(
        controlSettingsVisible,
        setting.id
      );
    });

    // start effects
    this.zoomSetting = this.getSetting(SETTINGS.ZOOM);
    // If zoomSetting is available and camera has fixed zoom levels, map value into key
    if (this.zoomSetting && this.uiCameraWithFixedZoomLevels) {
      const zoomIndex = Object.keys(CAMERA_ZOOM_LEVELS).findIndex(
        (key) =>
          CAMERA_ZOOM_LEVELS[key] ===
          +this.zoomSetting.value / +this.zoomSetting.options[0]
      );
      if (zoomIndex > -1) this.zoomSettingWithFixedZoomLevels = zoomIndex;
    }

    this.vanityLEDLeftSetting = this.getSetting(SETTINGS.VANITY_LED_LEFT);
    this.vanityLEDRightSetting = this.getSetting(SETTINGS.VANITY_LED_RIGHT);
    // if device does not support, led_linked will initialize as false
    this.uiToggles["led_linked"] =
      "false" === this.getSetting(SETTINGS.VANITY_LED_MANUAL)?.value;
    this.uiToggles["led_auto"] =
      "true" === this.getSetting(SETTINGS.AMBIENT_LIGHT_SENSOR)?.value;

    // update control settings
    this.saveCurrentSettingsToFavorite();
  }

  /**
   * The only viable dependency action for camera controls currently is "disable",
   * If more actions added, consider refactoring
   * deviceSettingsCategoryItemComponent.checkForDependencies to make it reusable here
   */
  isSettingDisabled(
    settingsList: Array<ControlsDeviceSetting>,
    settingId: string
  ): boolean {
    let disabled = false;
    this.dependencies
      .filter((d) => UtilityService.hexIsEqual(d.settingId, settingId))
      .forEach((dependency) => {
        dependency.when.forEach((when) => {
          disabled =
            disabled ||
            _isEqual(
              when.value,
              settingsList.find((s) =>
                UtilityService.hexIsEqual(s.id, when.settingId)
              )?.value
            );
        });
      });
    return disabled;
  }

  /**
   * Stores current controlSettings configuration to the currently selected favorite.
   */
  saveCurrentSettingsToFavorite() {
    if (!this.features.cameraFavorites) {
      return;
    }

    if (!this.controlSettings$.value.length || !this.device?.id) {
      // Unknown device ID or control settings not yet initialized, could not update settings to local storage
      // This is OK, since when controlSettings$ and favoriteOptions$ are both truthy values,
      // this function will run again.

      return;
    }

    if (undefined === this.favoriteOptionSelected) {
      // cannot save setting when favorite value not yet set
      return;
    }

    // save settings to favorites (local storage), only if settings are non-empty
    // note there is a limit to Chrome browser localstorage, however this usage does not seem to get close
    // @help https://developer.chrome.com/docs/apps/offline_storage/#:~:text=Apps%20that%20were%20designed%20to%20run%20in%20Google%20Chrome.&text=Note%3A%20Web%20storage%20APIs%20like,remain%20fixed%20at%205%20MB.&text=WebSQL%20(deprecated)-,Note%3A%20Web%20storage%20APIs%20like%20LocalStorage%20and,remain%20fixed%20at%205%20MB.
    this.favoritesService.setFavorite(
      this.device.uniqueId,
      [+this.favoriteOptionSelected, "settings"],
      this.controlSettings$.value
    );
  }

  getSetting(settingId: string): ControlsDeviceSetting | undefined {
    // cloneDeep used as a safeguard to ensure the BehaviorSubject controlSettings$'s value is not mutated
    return _cloneDeep(
      _find(
        this.controlSettings$.getValue(),
        (setting: ControlsDeviceSetting) => {
          return UtilityService.hexIsEqual(setting.id, settingId);
        }
      )
    );
  }

  /**
   * Given a boolean toggle (expand, grid, etc), ensure it is set to proper boolean state.
   * This is useful for changing state, for example "ensure expand is set to false".
   */
  ensureUiToggle(type: "expand" | "grid" | "preview", state = true) {
    if (this.uiToggles[type] !== state) {
      this.uiAction(type, true);
    }
  }

  /**
   * Central machine for changing stage of this.uiToggles and acting on changes.
   *
   * @param type
   * @param force - force action; if false, action may be discarded based on UI rules
   */
  uiAction(type: uiActionTypes, force = false) {
    let setting;

    if (!force && !this.uiToggles["preview"] && PREVIEW_TYPES.includes(type)) {
      // preview is off: discard uiAction
      // it is more elegant to discard the action here rather than to disable 15+ actions in the view

      return;
    }

    switch (type) {
      case "led_auto":
        this.uiToggles[type] = !this.uiToggles[type];
        const ledAutoSetting = this.getSetting(SETTINGS.AMBIENT_LIGHT_SENSOR);
        ledAutoSetting.value = this.uiToggles[type];
        this.valueChanged(ledAutoSetting);

        break;
      case "led_linked":
        this.uiToggles[type] = !this.uiToggles[type];
        const linkedSetting = this.getSetting(SETTINGS.VANITY_LED_MANUAL);
        // boolean switch because if manual is ON, linked is false
        linkedSetting.value = !this.uiToggles[type];
        this.valueChanged(linkedSetting);

        // when "linking" left and right, ensure right brightness matches left
        if (this.uiToggles[type]) {
          this.vanityLEDRightSetting.value = this.vanityLEDLeftSetting.value;
          this.valueChanged(this.vanityLEDRightSetting);
        }

        // because pointer-events is none, mouseleave will not fire after a click without this:
        this.uiToggleHover["led_right_link"] = false;

        break;
      case "grid":
        this.uiToggles[type] = !this.uiToggles[type];

        break;
      case "preview":
        // on Windows (Mac does not have preview functionality), prevent rapid
        // toggling of preview state while video preview is in progress hoisting
        if (this.startingVideo && !force) {
          return;
        }

        this.uiToggles[type] = !this.uiToggles[type];
        this.previewVisible$.next(this.uiToggles[type]);

        break;
      case "expand":
        this.uiToggles[type] = !this.uiToggles[type];
        this.hideControlLabelsFor(1000);
        this.setCameraSize();
        this.stateService.setDeepState(
          "DeviceDetails",
          "cameraMax",
          this.uiToggles[type]
        );

        break;
      case "zoom_in":
      case "zoom_out":
        setting = this.getSetting(SETTINGS.ZOOM);
        if (setting) {
          if (type === "zoom_in") {
            this.incrementValue(setting);
          }

          if (type === "zoom_out") {
            this.decrementValue(setting);
          }

          this.valueChanged(setting);
        }

        break;
      case "zoom_in_levels":
      case "zoom_out_levels":
        setting = this.getSetting(SETTINGS.ZOOM);
        if (setting) {
          if (type === "zoom_in_levels") {
            this.incrementLevelValue(setting);
          }

          if (type === "zoom_out_levels") {
            this.decrementLevelValue(setting);
          }

          this.valueChanged(setting);
        }

        break;
      case "pan_right":
      case "pan_left":
        setting = this.getSetting(SETTINGS.PAN);
        if (setting) {
          if (type === "pan_right") {
            this.incrementValue(setting);
          }

          if (type === "pan_left") {
            this.decrementValue(setting);
          }

          this.valueChanged(setting);
        }

        break;
      case "tilt_up":
      case "tilt_down":
        setting = this.getSetting(SETTINGS.TILT);
        if (setting) {
          if (type === "tilt_up") {
            this.incrementValue(setting);
          }

          if (type === "tilt_down") {
            this.decrementValue(setting);
          }

          this.valueChanged(setting);
        }

        break;
      case "snapshot":
        this.takeSnapshot();

        break;
      default:
        break;
    }
  }

  decrementValue(setting: ControlsDeviceSetting): void {
    const [min, , step] = setting.options;
    if (+setting.value > +min) {
      setting.value = String(+setting.value - +step);
    }

    // check for out of range issue
    if (+setting.value < +min) {
      setting.value = min;
    }
  }

  incrementValue(setting: ControlsDeviceSetting): void {
    const [, max, step] = setting.options;
    if (+setting.value < +max) {
      setting.value = String(+setting.value + +step);
    }

    // check for out of range issue
    if (+setting.value > +max) {
      setting.value = max;
    }
  }
  // When you click minus icon at zoom slider, let we go to the previous CAMERA_ZOOM_LEVELS value
  decrementLevelValue(setting: ControlsDeviceSetting): void {
    const [min, ,] = setting.options;
    const newZoomLevel = Math.max(this.zoomSettingWithFixedZoomLevels - 1, 0);
    setting.value = String(CAMERA_ZOOM_LEVELS[newZoomLevel] * min);
  }
  // When you click plus icon at zoom slider, let we go to the next CAMERA_ZOOM_LEVELS value
  incrementLevelValue(setting: ControlsDeviceSetting): void {
    const [min, ,] = setting.options;
    const newZoomLevel = Math.min(
      this.zoomSettingWithFixedZoomLevels + 1,
      this.fixedZoomLevels
    );
    setting.value = String(CAMERA_ZOOM_LEVELS[newZoomLevel] * min);
  }

  takeSnapshot(): void {
    this.takeSnapshot$.next(true);

    // const unique_id = Math.
    this.translateService
      .get([
        "DETAIL.SNAPSHOT_SAVE",
        "DETAIL.SNAPSHOT_SUGGESTED_FILENAME_PREFIX",
      ])
      .subscribe((translations) => {
        // NOTE this gets unique file name path, assuming user saves to default documents directory
        const defaultPath = UtilityService.getUniqueFileName(
          this.polytron.getPath("documents"),
          translations["DETAIL.SNAPSHOT_SUGGESTED_FILENAME_PREFIX"],
          ".jpg"
        );

        this.polytron.showSaveDialog({
          title: translations["DETAIL.SNAPSHOT_SAVE"],
          description: "Image snapshot",
          suggestedName: "snapshot",
          filters: {'image/jpeg': ['.jpg', '.jpeg']},
        })
          .then(({canceled, filePath, writableFile}) => {
            if (!canceled && filePath && writableFile !== undefined) {
              this.saveSnapshot(filePath, writableFile);
            }
          });
      });
  }

  /**
   * Get snapshot and save it to a filePath.
   *
   * See also https://github.com/basst314/ngx-webcam/blob/master/src/app/modules/webcam/webcam/webcam.component.ts
   *
   * @param filePath
   */
  async saveSnapshot(filePath: string, writableFile: FileSystemWritableFileStream): Promise<void> {
    // set canvas size to actual video size
    const _video = this.videoEle.nativeElement;
    const dimensions = {
      width: this.cameraMaxWidth,
      height: this.cameraMaxHeight,
    };

    if (_video.videoWidth) {
      dimensions.width = _video.videoWidth;
      dimensions.height = _video.videoHeight;
    }

    const _canvas = this.canvas.nativeElement;
    _canvas.width = dimensions.width;
    _canvas.height = dimensions.height;

    // paint snapshot image to canvas
    const context2d = _canvas.getContext("2d");
    context2d.drawImage(_video, 0, 0);

    // read canvas content as image
    const mimeType: string = "image/jpeg";
    const quality: number = 0.92;
    const dataUrl: string = _canvas.toDataURL(mimeType, quality);

    const base64Data = dataUrl.replace(/^data:[^;]+;base64,/, "");
    let bstr:string = atob(base64Data);
    let n: number = bstr.length;
    let byteArray:Uint8Array = new Uint8Array(n);
    while(n--) {
      byteArray[n] = bstr.charCodeAt(n);
    }

    this.logger.info("About to write snapshot to [" + filePath + "]");
    try {
      await writableFile.write(byteArray);
    } catch (err) {
      this.logger.error(`Could not save file ${filePath}, error ${err}`);
      this.notificationService.singleNotificationUntranslated({
        title: "DETAIL.SNAPSHOT_SAVED_FAIL",
      });
      return;
    } finally {
      await writableFile.close();
    }

    this.notificationService.singleNotificationUntranslated({
      title: "DETAIL.SNAPSHOT_SAVED_SUCCESS",
    });
  }

  setCameraControlsHover(newState: boolean) {
    clearTimeout(this.hideCameraControlsTimeout);

    // this function should be the *only* place that this.cameraControlHovers$.next is called,
    // which simplifies state management for custom logic such as above

    // if preview is off, always show controls
    if (!this.uiToggles["preview"]) {
      newState = true;
    }

    this.cameraControlsHover$.next(newState);
  }

  applyFavoriteChange(newName: string) {
    this.currentFavoriteState.editing = false;

    if (this.currentFavoriteState.name !== newName) {
      // update name
      this.currentFavoriteState.name = newName;

      if (this.favoriteOptions[this.favoriteOptionSelected]) {
        this.favoritesService.setFavorite(
          this.device.uniqueId,
          [+this.favoriteOptionSelected, "translatedText"],
          this.currentFavoriteState.name
        );
      }

      this.currentFavoriteState.showSaved = true;
      this.hideSavedDelay(3000);
    }
  }

  @HostListener("window:resize")
  resizeWindow(resized = true) {
    const leftRightMargin = 60;
    // set the max camera view width based on window size.
    // Math.max ensures a reasonable minimum in case container is undefined
    this.largeCameraMaxWidth = Math.max(
      this.smallCameraMaxWidth,
      Math.min(
        this.largeCameraHardWidthLimit,
        +this.container?.nativeElement.offsetWidth - leftRightMargin
      )
    );
    this.largeCameraMaxHeight = this.largeCameraMaxWidth * this.maxAspectRatio;
    if (resized) {
      this.setCameraSize();
    }
  }

  setCameraSize() {
    let aspect = this.maxAspectRatio;

    if (
      this.videoEle?.nativeElement &&
      +this.videoEle.nativeElement.offsetHeight &&
      +this.videoEle.nativeElement.offsetWidth
    ) {
      aspect =
        this.videoEle.nativeElement.offsetHeight /
        this.videoEle.nativeElement.offsetWidth;
    }

    this.cameraMaxWidth = this.uiToggles["expand"]
      ? this.largeCameraMaxWidth
      : this.smallCameraMaxWidth;
    this.cameraMaxHeight = this.uiToggles["expand"]
      ? this.largeCameraMaxHeight
      : this.smallCameraMaxHeight;

    const actualHeight = this.cameraMaxWidth * aspect;
    //
    const CAMERA_COVERS_NAVIGATION = 375;
    this.stateService.setDeepState(
      "DeviceDetails",
      "hideNavigation",
      actualHeight > CAMERA_COVERS_NAVIGATION
    );
  }

  /**
   * Useful for animations - prevent control hover effects & labels for n milliseconds.
   *
   * @param time_ms
   */
  hideControlLabelsFor(time_ms: number = 0) {
    this.showControlLabels = false;

    setTimeout(() => {
      this.showControlLabels = true;
    }, time_ms);
  }

  /**
   * If the component is being destroyed, stopCameraPreview can be called.
   * Otherwise, call `this.previewVisible$.next(false)` to have UI respond.
   */
  stopCameraPreview() {
    this.videoSrc?.getTracks().forEach((t) => t.stop());
    this.videoSrc = undefined;
  }

  deleteModalClick(state: boolean) {
    if (state) {
      const deleteSuccess = this.favoritesService.deleteFavorite(
        this.device.uniqueId,
        this.favoriteOptionSelected
      );

      if (false !== deleteSuccess) {
        // deleteFavorite if successful returns a new selected index
        this.favoriteOptionSelected = deleteSuccess;
      }
    }

    // hide modal again
    this.showFavoriteDeleteModal = false;
  }

  ngOnDestroy() {
    this.componentDestroyed = true;
    this._onDestroy$.next();
    this._onDestroy$.complete();
    this.stopCameraPreview();
    this.stateService.clearState("DeviceDetails");
    this.subs.unsubscribe();
  }
}
