import {
  Component,
  Input,
  OnDestroy,
  AfterViewInit,
  Output,
  EventEmitter,
} from "@angular/core";
import { SETTINGS_MODALS } from "../../../utils/constants";

@Component({
  selector: "oz-how-nb-works-modal",
  templateUrl: "./how-nb-works-modal.component.pug",
})
export class HowNBWorksModalComponent implements AfterViewInit, OnDestroy {
  @Input() id: keyof typeof SETTINGS_MODALS;
  @Input() options: string[] = [];
  @Output() closeModal = new EventEmitter<boolean>();

  public show: boolean = false;
  public commonMarkup = { line_feed: "<br><br>" };

  constructor() {}

  close() {
    this.show = false;
    this.closeModal.emit(true);
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.show = true;
    }, 0);
  }

  ngOnDestroy() {}
}
