import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { CloudRepository } from "./repository-cloud.service";
import { OnPremRepository } from "./repository-onprem.service";
import {
  IRepository,
  RepositoryDisplayLink,
  RepositoryFirmware,
  RepositoryProductCatalog,
  RepositorySoftware,
  RepositorySoftwareOptions,
  RepositoryDeviceResources,
  RepositoryDevicePolicy,
  RepositoryDevicesUsage,
  RepositoryFirmwareArchiveDownloadProgress,
  RepositoryFirmwareArchiveDownloadOptions,
  RepositoryFirmwareOptions,
  RepositoryProductCatalogOptions,
  RepositoryDevicePolicyOptions,
} from "./model";

@Injectable({
  providedIn: "root",
})
export class Repository implements IRepository {
  private repo: IRepository;

  constructor(
    private cloudRepo: CloudRepository,
    private onpremRepo: OnPremRepository
  ) {
    this.repo = this.cloudRepo ?? this.onpremRepo;
  }

  getDisplayLink(platform: "win" | "mac"): Observable<RepositoryDisplayLink> {
    return this.repo.getDisplayLink(platform);
  }

  getSoftware(
    options: RepositorySoftwareOptions
  ): Observable<RepositorySoftware> {
    return this.repo.getSoftware(options);
  }

  getProductCatalog(
    options: RepositoryProductCatalogOptions
  ): Observable<RepositoryProductCatalog> {
    return this.repo.getProductCatalog(options);
  }

  getFirmware(
    options: RepositoryFirmwareOptions
  ): Observable<RepositoryFirmware> {
    return this.repo.getFirmware(options);
  }

  getDeviceResources(pid: number): Observable<RepositoryDeviceResources> {
    return this.repo.getDeviceResources(pid);
  }

  getDevicePolicy(
    options: RepositoryDevicePolicyOptions
  ): Observable<RepositoryDevicePolicy> {
    return this.repo.getDevicePolicy(options);
  }

  getDevicesUsage(tenantId: string): Observable<RepositoryDevicesUsage> {
    return this.repo.getDevicesUsage(tenantId);
  }

  getMyIP(): Observable<string> {
    return this.repo.getMyIP();
  }

  getFirmwareArchive(
    options: RepositoryFirmwareArchiveDownloadOptions
  ): Observable<RepositoryFirmwareArchiveDownloadProgress> {
    return this.repo.getFirmwareArchive(options);
  }

  setDevicePolicyFirmwareSetting(
    pid: number,
    deviceId: string,
    settings: { archive_url: string; rules_json: string }
  ): void {
    this.repo.setDevicePolicyFirmwareSetting(pid, deviceId, settings);
  }
}
