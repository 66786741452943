import { Component, OnInit } from "@angular/core";
import { SoftwareSetting } from "@poly/hub-native";
import { combineLatest, Subscription } from "rxjs";
import { DeviceManagerService } from "../../services/device-manager.service";
import { QdNotificationsService } from "../../services/qd-notifications.service";

export interface IDisconnectOption {
  value: string;
  text: string;
}

const holdActiveCall = "holdActiveCall";
const lockScreen = "lockScreen";
const lockScreenAndHoldCall = "lockScreenAndHoldCall";
const doNothing = "doNothing";

enum DisconnectOption {
  holdActiveCall = "DeviceSettings.QUICK_DISCONNECT.HOLD_ACTIVE_CALL",
  lockComputerScreen = "DeviceSettings.QUICK_DISCONNECT.LOCK_SCREEN",
  lockScreenAndHoldCall = "DeviceSettings.QUICK_DISCONNECT.LOCK_SCREEN_AND_HOLD_CALL",
  doNothing = "DeviceSettings.QUICK_DISCONNECT.DO_NOTHING",
}

const SettingKeys = {
  smartHold: "smartHold",
  smartLock: "smartLock",
};

const SettingsMap = {
  holdActiveCall: {
    smartHold: true,
    smartLock: false,
  },
  lockScreen: {
    smartHold: false,
    smartLock: true,
  },
  lockScreenAndHoldCall: {
    smartHold: true,
    smartLock: true,
  },
  doNothing: {
    smartHold: false,
    smartLock: false,
  },
};

@Component({
  selector: "oz-device-disconnect",
  templateUrl: "./device-settings-disconnect-item.component.pug",
})
export class DeviceSettingsDisconnectItemComponent implements OnInit {
  private softwareSettingsSub: Subscription;
  smartHold: boolean;
  smartLock: boolean;
  disconnectOptions: IDisconnectOption[] = [
    {
      value: holdActiveCall,
      text: DisconnectOption.holdActiveCall,
    },
    {
      value: lockScreen,
      text: DisconnectOption.lockComputerScreen,
    },
    {
      value: lockScreenAndHoldCall,
      text: DisconnectOption.lockScreenAndHoldCall,
    },
    {
      value: doNothing,
      text: DisconnectOption.doNothing,
    },
  ];
  selectedDisconnectOption: string;

  constructor(
    private deviceManager: DeviceManagerService,
    private qdNotificationsService: QdNotificationsService
  ) {}

  ngOnInit(): void {
    this.softwareSettingsSub = this.deviceManager
      .getSoftwareSettings()
      .subscribe((settings) => {
        if (!!settings.length) {
          settings.forEach((setting) => {
            if (setting.id === SettingKeys.smartHold) {
              this.smartHold = setting.value;
            }
            if (setting.id === SettingKeys.smartLock) {
              this.smartLock = setting.value;
            }
          });

          this.setOptionValueFromSWSettings();
        }
      });
  }

  onDisconnectOptionChanged(value: string) {
    this.setSWSettingsValues(value);

    const smartHoldSoftwareSetting: Partial<SoftwareSetting> = {
      id: SettingKeys.smartHold,
      value: this.smartHold,
    };
    const smartLockSoftwareSetting: Partial<SoftwareSetting> = {
      id: SettingKeys.smartLock,
      value: this.smartLock,
    };

    this.deviceManager.setSoftwareSettings([
      smartHoldSoftwareSetting,
      smartLockSoftwareSetting,
    ]);

    // Set Screen Lock OS notification on QD setting change
    this.qdNotificationsService.qdDisconnectChanged$.next(this.smartLock);
  }

  setSWSettingsValues(value: string) {
    this.smartHold = SettingsMap[value].smartHold;
    this.smartLock = SettingsMap[value].smartLock;
  }

  setOptionValueFromSWSettings() {
    for (const [key, value] of Object.entries(SettingsMap)) {
      if (
        value.smartHold === this.smartHold &&
        value.smartLock === this.smartLock
      ) {
        this.selectedDisconnectOption = key;
      }
    }
  }

  ngOnDestroy() {
    this.softwareSettingsSub.unsubscribe();
  }
}
