import {Injectable} from "@angular/core";
import {Observable, Subject} from "rxjs";
import {
  AcousticIncident,
  CallEvent,
  ConversationAnalyticsEvent,
  DeviceEvent,
  IEventsManager, LinkQualityEvent,
  TwaEvent
} from "../api/events-manager.api";

@Injectable({
  providedIn: "root"
})
export class WhEventsManager extends IEventsManager {
  private linkQualityEvent$ = new Subject<LinkQualityEvent>();
  private twaEvent$ = new Subject<TwaEvent>();
  private acousticIncidentEvent$ = new Subject<AcousticIncident>();
  private conversationAnalyticsEvent$ = new Subject<ConversationAnalyticsEvent>();

  getDeviceEvents(): Observable<DeviceEvent> {
    throw new Error("getDeviceEvents not implemented.");
  }

  getCallEvents(): Observable<CallEvent> {
    throw new Error("getCallEvents not implemented.");
  }

  getAcousticIncident(): Observable<AcousticIncident> {
    return this.acousticIncidentEvent$.asObservable();
  }

  getTwaEvents(): Observable<TwaEvent> {
    return this.twaEvent$.asObservable();
  }

  getConversationAnalyticsEvents(): Observable<ConversationAnalyticsEvent> {
    return this.conversationAnalyticsEvent$.asObservable();
  }

  getLinkQualityEvents(): Observable<LinkQualityEvent> {
    return this.linkQualityEvent$.asObservable();
  }

  getAudioIntelligenceEvents(): Observable<object> {
    throw new Error("getAudioIntelligenceEvents not implemented.");
  }

  setAudioIntelligenceEnabled(enabled: boolean) {
    throw new Error("setAudioIntelligenceEnabled not implemented.");
  }

  getVideoIntelligenceEvents(): Observable<object> {
    throw new Error("getVideoIntelligenceEvents not implemented.");
  }

  setVideoIntelligenceEnabled(enabled: boolean) {
    throw new Error("setVideoIntelligenceEnabled not implemented.");
  }
}
