import { Injectable } from "@angular/core";
import { Http } from "azure-iot-provisioning-device-http";
import { SymmetricKeySecurityClient } from "azure-iot-security-symmetric-key";
import { ProvisioningDeviceClient } from "azure-iot-provisioning-device";
import {take} from "rxjs/operators";

import { ILoggingService } from "./logging.service";
import { ConfigService } from "./config.service";

const util = require("util"); // seems to change the type inference on promisify
const retry = require('async-retry');

// Min and Max throttling times used for handling the retries.  Initially, this is
// set at 10 seconds (in millis) but can be adjusted in the future to
// anything.  Just be aware that the max time is a multiple now so
// if you lengthen min, you may also want to adjust the max
const MIN_THROTTLING_TIMEOUT_MS = 10 * 1000;
const MAX_THROTTLING_TIMEOUT_MS = MIN_THROTTLING_TIMEOUT_MS * 4;

@Injectable({
  providedIn: "root",
})
export class IotDpsService {
  constructor(private config: ConfigService, private logger: ILoggingService) {}

  async registerSymKey(
    deviceSymmetricKey: string,
    registrationId: string
  ): Promise<any> {
    const securityClient = new SymmetricKeySecurityClient(
      registrationId,
      deviceSymmetricKey
    );
    let env = await this.config.env$.pipe(take(1)).toPromise();

    const deviceClient = ProvisioningDeviceClient.create(
      env.IOT_PROVISIONING_HOST,
      env.IOT_ID_SCOPE,
      new Http(),
      securityClient
    );
    const register = util.promisify(deviceClient.register).bind(deviceClient);
    return await retry(
      async (bail: (arg0: Error) => void) => {
        try {
          this.logger.debug("attempting to register SymKey for IoT");
          const result = await register();
          this.logger.debug("app registration succeeded");
          this.logger.debug("assigned hub=" + result.assignedHub);
          this.logger.debug("deviceId=" + result.deviceId);
          this.logger.debug("registration dump: " + JSON.stringify(result));
          return result;
        } catch (err) {
          if (err.name === "ThrottlingError") {
            this.logger.warn("registration throttled -- retrying");
            throw err;
          }
          this.logger.error("registration failed", err);
          bail(err);
        }
      },
      {
        retries: 10000,   // default is 10 so we need to make this large
        minTimeout: MIN_THROTTLING_TIMEOUT_MS,
        maxTimeout: MAX_THROTTLING_TIMEOUT_MS,
        randomize: true,   // async-retry will randomize between min and max
      }
    );
  }
}
