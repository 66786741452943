import {
  animate,
  query,
  sequence,
  state,
  style,
  transition,
  trigger,
} from "@angular/animations";
import { UtilityService } from "../services/utility.service";

// **IMPORTANT** there is a Windows-only icon in dock, make sure to test width changes with that
const windowsAdjustment = UtilityService.isWindows() ? 35 : 0;
const cameraControlsWidthExpandedPx = 280 + windowsAdjustment;
const cameraControlsWidthCollapsedPx = 120 + windowsAdjustment;
const cameraControlsWidthExpanded = {
  width: `${cameraControlsWidthExpandedPx}px`,
  left: `calc((100% - ${cameraControlsWidthExpandedPx}px) / 2)`,
};
const cameraControlsWidthCollapsed = {
  width: `${cameraControlsWidthCollapsedPx}px`,
  left: `calc((100% - ${cameraControlsWidthCollapsedPx}px) / 2)`,
};
const bottomShelfTransitionMS = "300ms ease-in-out";
const bottomShelfFadeMS = "400ms ease-in-out";

const bottomShelfVisible = {
  transform: "translateY(0)",
};
const bottomShelfInvisible = {
  transform: "translateY(100%)",
};

// .bottom-shelf animations:
// 1. an expanded (with PTZ controls) and collapsed state (without PTZ controls)
//    note, this animation may not be visible based on whether .bottom-shelf is visible
// 2. a slide in on hover of the camera image
export const cameraControlsAnimation = [
  trigger("bottomShelfAnimation", [
    state("expanded", style(cameraControlsWidthExpanded)),
    state("collapsed", style(cameraControlsWidthCollapsed)),
    transition("collapsed => expanded", [
      sequence([
        query(".animation-pan-tilt-zoom", [style({ opacity: 0 })]),
        animate(bottomShelfTransitionMS, style(cameraControlsWidthExpanded)),
        query(".animation-pan-tilt-zoom", [
          animate(bottomShelfFadeMS, style({ opacity: 1 })),
        ]),
      ]),
    ]),
    transition("expanded => collapsed", [
      sequence([
        query(".animation-pan-tilt-zoom", [style({ opacity: 1 })]),
        query(".animation-pan-tilt-zoom", [
          animate(bottomShelfFadeMS, style({ opacity: 0 })),
        ]),
        animate(bottomShelfTransitionMS, style(cameraControlsWidthCollapsed)),
      ]),
    ]),
  ]),
  trigger("bottomShelfVisibleAnimation", [
    state("visible", style(bottomShelfVisible)),
    state("invisible", style(bottomShelfInvisible)),
    transition("* => *", [animate("400ms ease-in-out")]),
  ]),
];
