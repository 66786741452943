import { Injectable } from "@angular/core";
import { Apollo } from "apollo-angular";
import { MS_IN_DAY } from "../../utils/constants";
import { merge, Observable, ReplaySubject, Subscription } from "rxjs";
import { filter, map, pluck, tap, switchMap } from "rxjs/operators";
import { UtilityService } from "../utility.service";
import {
  RepositorySoftwareOptions,
  RepositorySoftware,
  IRepository,
  RepositoryDisplayLink,
  RepositoryFirmware,
  RepositoryProductCatalog,
  RepositoryHardwareProduct,
  RepositoryDeviceResources,
  RepositoryDevicePolicy,
  RepositoryDevicesUsage,
  RepositoryFirmwareArchiveDownloadProgress,
  RepositoryFirmwareArchiveDownloadOptions,
  ProductBuild,
  RepositoryFirmwareOptions,
  RepositoryProductCatalogOptions,
  RepositoryDevicePolicyOptions,
} from "./model";
import { DELETE_USER_ACCOUNT, Queries } from "./repository-cloud-queries";
import { AdminConfig } from "../admin-config.service";
import { FileDownload } from "../file-download.service";
import { MS_IN_SECOND } from "../../utils/constants";
import { toPerc } from "../../utils/utils";
import { ILoggingService } from "../logging.service";
import { TenantService } from "../tenant.service";
import { AuthService } from "../auth.service";
import { tag } from "rxjs-spy/operators/tag";
import { OzDevice } from "../device-manager.service";

interface ProductCatalogImageMetaData {
  type: string;
  width: number;
  height: number;
}

interface ProductCatalogHardwareFamily {
  id: string;
  name: string;
}

interface ProductCatalogHardwareManufacturer {
  id: string;
  name: string;
}

interface ProductCatalogHardwareModel {
  id: string;
  name: string;
  skus: Array<string>;
  hardwareFamily: ProductCatalogHardwareFamily;
  hardwareManufacturer: ProductCatalogHardwareManufacturer;
}

interface ProductCatalogImage {
  name: string;
  url: string;
  metadata: ProductCatalogImageMetaData;
}

interface ProductCatalogHardwareProduct {
  id: string;
  name: string;
  images: Array<ProductCatalogImage>;
  hardwareModel: ProductCatalogHardwareModel;
}

interface AvailableFirmwareSettings {
  [deviceId: string]: AvailableFirmwareSetting;
}

@Injectable({
  providedIn: "root",
  useFactory: (
    adminConfig: AdminConfig,
    apollo: Apollo,
    fileDownload: FileDownload,
    logger: ILoggingService,
    tenantService: TenantService,
    authService: AuthService
  ): IRepository => {
    return adminConfig.mode !== "network"
      ? new CloudRepository(
          apollo,
          fileDownload,
          logger,
          tenantService,
          authService
        )
      : null;
  },
  deps: [
    AdminConfig,
    Apollo,
    FileDownload,
    ILoggingService,
    TenantService,
    AuthService,
  ],
})
export class CloudRepository implements IRepository {
  private availableFirmwareSettings: AvailableFirmwareSettings = {};
  private _tenantId: string;
  public updateCheckReady = false; // we're ready after we get a settings notification when logged in (tenant id set), after some timer, or right away if not logged in

  constructor(
    private apollo: Apollo,
    private fileDownload: FileDownload,
    private logger: ILoggingService,
    private tenantService: TenantService,
    public authService: AuthService
  ) {
    // At startup, if we're not logged in(refresh token not set) then update checks are ready
    this.logger.info(
      `Initializing update check ready, has refresh token: ${this.authService.hasRefreshToken()}`
    );
    this.resetUpdateCheckReady(!this.authService.hasRefreshToken());

    // watch for tenant id changes
    this.tenantService.tenantId$
      .pipe(tag(`CloudRepository.tenantId watcher`))
      .subscribe((id: string) => {
        const tenantIdChanged = this._tenantId != id;
        this._tenantId = id;

        if (tenantIdChanged) {
          // if tenant id was unset (logged out) then we're ready otherwise reset ready state
          this.logger.info(
            `Resetting update check ready for tenant id change ${id}`
          );
          this.resetUpdateCheckReady(!this._tenantId);

          // clear policy config on tenant id changes
          Object.values(this.availableFirmwareSettings).forEach((setting) =>
            setting.policySet(undefined)
          );
        }
      });
  }

  // delete user account function
  executeDeleteUserAccount(): Observable<any> {
    return this.apollo.mutate({
      mutation: DELETE_USER_ACCOUNT,
    }).pipe(
      map(result => result.data)
    );
  }

  // updateCheckReady only stays set to false for a finite amount of time
  resetUpdateCheckReady(ready: boolean) {
    if (ready) {
      // user has logged in
      this.updateCheckReady = true;
      // emit the latest value now that we're ready
      Object.values(this.availableFirmwareSettings).forEach((setting) =>
        setting.emit()
      );
    } else {
      this.updateCheckReady = false;
      setTimeout(() => {
        if (!this.updateCheckReady) {
          this.logger.info(`Resetting update check ready on timer`);
          this.resetUpdateCheckReady(true);
        }
      }, 15 * MS_IN_SECOND);
    }
  }

  getDisplayLink(platform: "win" | "mac"): Observable<RepositoryDisplayLink> {
    return this.apollo
      .watchQuery({
        query: Queries.AVAILABLE_DISPLAYLINK.QUERY,
        variables: {
          productId:
            platform === "win"
              ? "displaylink-manager-windows"
              : "displaylink-manager-mac",
        },
      })
      .valueChanges.pipe(
        pluck("data", Queries.AVAILABLE_DISPLAYLINK.QUERY_NAME)
      );
  }

  getSoftware(
    options: RepositorySoftwareOptions
  ): Observable<RepositorySoftware> {
    return this.apollo
      .watchQuery<{ availableProductSoftwareByPid: RepositorySoftware }>({
        query: Queries.AVAILABLE_SOFTWARE_VERSION.QUERY,
        variables: {
          productId: options.productId,
          releaseChannel: options.releaseChannel,
        },
        fetchPolicy: "network-only",
        pollInterval: options.pollInterval,
      })
      .valueChanges.pipe(map((r) => r.data.availableProductSoftwareByPid));
  }

  getProductCatalog(
    options: RepositoryProductCatalogOptions
  ): Observable<RepositoryProductCatalog> {
    return this.apollo
      .watchQuery<{ hardwareProducts: ProductCatalogHardwareProduct[] }>({
        query: Queries.HARDWARE_PRODUCTS.QUERY,
        fetchPolicy: "network-only",
        pollInterval: options.pollInterval,
      })
      .valueChanges.pipe(
        map((r) => {
          return r?.data?.hardwareProducts
            ?.filter(
              // Track all hardware products with at least one image
              (product) => product.images.length
            )
            .map((product) => {
              const url = product.images[0].url;
              UtilityService.addPrefetch(url);
              return {
                id: product.id,
                image: url,
                supported: !!product?.hardwareModel?.skus?.length,
              } as RepositoryHardwareProduct;
            });
        })
      );
  }

  deviceFirmwareSettings(
    device: OzDevice,
    pollInterval: number
  ): AvailableFirmwareSetting {
    let deviceSettings = this.availableFirmwareSettings[device.uniqueId];
    if (!deviceSettings || deviceSettings.pidCompare(device.pid)) {
      deviceSettings = new AvailableFirmwareSetting(
        this.apollo,
        device.pid,
        device.uniqueId,
        pollInterval,
        this,
        this.logger
      );
      this.availableFirmwareSettings[device.uniqueId] = deviceSettings;
    }
    return deviceSettings;
  }

  getFirmware(
    options: RepositoryFirmwareOptions
  ): Observable<RepositoryFirmware> {
    let deviceSettings = this.deviceFirmwareSettings(
      options.device,
      options.pollInterval
    );
    return deviceSettings.observable$;
  }

  setDevicePolicyFirmwareSetting(
    pid: number,
    deviceId: string,
    settings: {
      archive_url: string;
      rules_json: string;
      policy: { version: string };
    }
  ) {
    const deviceFirmwareSettings = this.deviceFirmwareSettings(
      {
        pid,
        uniqueId: deviceId,
      } as OzDevice,
      MS_IN_DAY
    );
    let policyFirmwareSetting = undefined;

    if (!settings) {
      this.logger.info(
        `setDevicePolicyFirmwareSetting: clearing software update settings for ${deviceId}`
      );
    } else {
      let parsedRulesJson = undefined;
      try {
        parsedRulesJson = JSON.parse(settings.rules_json);
      } catch (error) {}
      const productBuild = {
        archiveUrl: settings.archive_url,
        rules: { document: parsedRulesJson },
      } as ProductBuild;
      if (!productBuild.archiveUrl || !productBuild.rules?.document) {
        this.logger.info(
          `setDevicePolicyFirmwareSetting: invalid software update settings for ${deviceId}: ${JSON.stringify(
            settings
          )}`
        );
      } else {
        this.logger.info(
          `setDevicePolicyFirmwareSetting: software update settings for ${deviceId}: ${productBuild.archiveUrl}`
        );
        policyFirmwareSetting = {
          archiveLocation: productBuild.archiveUrl,
          rules: productBuild.rules.document,
          policy: !!settings.policy?.version,
        } as RepositoryFirmware;
      }
    }
    deviceFirmwareSettings.policySet(policyFirmwareSetting);

    if (!this.updateCheckReady) {
      // now that we've received policy, we're ready
      this.resetUpdateCheckReady(true);
    }
  }

  getDeviceResources(pid: number): Observable<RepositoryDeviceResources> {
    return this.apollo
      .watchQuery({
        query: Queries.DEVICE_RESOURCES.QUERY,
        variables: {
          id: pid.toString(16),
        },
      })
      .valueChanges.pipe(
        pluck("data", Queries.DEVICE_RESOURCES.QUERY_NAME, "metadata")
      );
  }

  getDevicePolicy(
    options: RepositoryDevicePolicyOptions
  ): Observable<RepositoryDevicePolicy> {
    return this.apollo
      .watchQuery({
        query: Queries.DEVICE_POLICY.QUERY,
        variables: {
          deviceId: options.deviceId,
          productId: options.productId.toString(16),
        },
        fetchPolicy: "network-only",
        pollInterval: options.pollInterval,
      })
      .valueChanges.pipe(pluck("data", Queries.DEVICE_POLICY.QUERY_NAME));
  }

  getDevicesUsage(tenantId: string): Observable<RepositoryDevicesUsage> {
    return this.apollo
      .query({
        query: Queries.DEVICE_USAGE.QUERY,
        variables: {
          tenant: tenantId,
        },
        fetchPolicy: "network-only",
      })
      .pipe(pluck("data", Queries.DEVICE_USAGE.QUERY_NAME));
  }

  getMyIP(): Observable<string> {
    return this.apollo
      .watchQuery<{ getMyIp: { ip: string } }>({
        query: Queries.GET_MY_IP.QUERY,
        pollInterval: MS_IN_DAY,
      })
      .valueChanges.pipe(pluck("data", Queries.GET_MY_IP.QUERY_NAME, "ip"));
  }

  getFirmwareArchive(
    options: RepositoryFirmwareArchiveDownloadOptions
  ): Observable<RepositoryFirmwareArchiveDownloadProgress> {
    return this.fileDownload
      .download(options.archiveLocation, options.path)
      .pipe(
        map(({ state, downloadedBytes, totalBytes }) => {
          return {
            progress: toPerc(downloadedBytes, totalBytes),
            status: state,
          };
        })
      );
  }
}

// Avaible firmware is either queried from the backend or set via policy
class AvailableFirmwareSetting {
  networkQueryRef = this.apollo.watchQuery({
    query: Queries.AVAILABLE_FIRMWARE.QUERY,
    variables: { pid: this.pid.toString(16) },
    fetchPolicy: "cache-first",
    pollInterval: this.pollInterval, // Poll once a day. This will by-pass apollo's cache.
  });
  manualEmitter$ = new ReplaySubject<RepositoryFirmware>(1);
  policyValue: RepositoryFirmware | undefined = undefined;
  lastNetworkValue: RepositoryFirmware = undefined;
  policyFirmwareSub: Subscription;

  public observable$: Observable<RepositoryFirmware>;
  constructor(
    private apollo: Apollo,
    private pid: number,
    private deviceId: string,
    private pollInterval: number,
    private repo: CloudRepository,
    private logger: ILoggingService
  ) {
    this.policyFirmwareSub = repo.authService.isAuthenticated$
      .pipe(
        filter((authenticated) => authenticated),
        switchMap((authenticated) => {
          return repo
            .getDevicePolicy({
              deviceId: this.deviceId,
              productId: this.pid,
              pollInterval: this.pollInterval,
            })
            .pipe(
              pluck("capabilities", "com", "poly", "software_update"),
              filter((fw) => !!fw?.rules_json?.value),
              map((productBuild: any) => {
                let parsedRulesJson;
                try {
                  parsedRulesJson = JSON.parse(productBuild.rules_json.value);
                } catch (error) {}
                return {
                  archiveLocation: productBuild?.archive_url?.value,
                  rules: parsedRulesJson,
                  policy: true,
                } as RepositoryFirmware;
              })
            );
        })
      )
      .subscribe((firmwarePolicy) => {
        if (!!firmwarePolicy.rules && !this.policyValue) {
          this.policySet(firmwarePolicy);
        }
      });
    // This observable emits from either network lookups or when a build is set from a policy.
    // Only report the policy value if set (allows us to clear by sending undefined to replay subject)
    // Only report the network value if there isn't a policy value
    // Store the last network value so we can report it if the policy value gets cleared
    this.observable$ = merge(
      this.manualEmitter$.pipe(filter((x) => x !== undefined)),
      this.networkQueryRef.valueChanges.pipe(
        pluck("data", Queries.AVAILABLE_FIRMWARE.QUERY_NAME, "productBuild"),
        map(
          (productBuild: any) =>
            (this.lastNetworkValue = {
              archiveLocation: productBuild?.archiveUrl,
              rules: productBuild?.rules?.document,
            } as RepositoryFirmware)
        ),
        filter((x) => this.policyValue === undefined)
      )
    ).pipe(
      tap((firmware) =>
        this.logger.info(
          `Firmware info: device id: ${this.deviceId} pid: ${this.pid.toString(
            16
          )} updateInfo: ${firmware?.archiveLocation} (policy: ${
            firmware?.policy
          }) service ready: ${repo.updateCheckReady}`
        )
      ),
      map((firmware) => (repo.updateCheckReady ? firmware : undefined)) // only emit values if we're ready, otherwise send undefineds
    );
  }

  policySet(value: RepositoryFirmware | undefined | null) {
    if (!value) {
      // when the policy value is cleared (null/undefined), go back to network polling
      if (this.lastNetworkValue) {
        // emit the last network lookup
        this.manualEmitter$.next(this.lastNetworkValue);
      }
      // "clear" the manually set value
      this.manualEmitter$.next(undefined);
      this.policyValue = undefined;

      // start the network poller and fetch latest
      this.networkQueryRef.startPolling(this.pollInterval);
      this.networkQueryRef.refetch();
    } else {
      // Value is now set from the policy
      // turn off the network query
      this.networkQueryRef.stopPolling();
      this.policyValue = value;

      //emit new value
      this.manualEmitter$.next(value);
    }
  }

  emit(): void {
    // send out the latest value
    const value = this.policyValue ?? this.lastNetworkValue;
    this.manualEmitter$.next(value);
  }

  pidCompare(newPid: number): boolean {
    return this.pid != newPid;
  }
}
