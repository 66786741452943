import { ILoggingService } from "./logging.service";
import { mergeMap } from "rxjs/operators";
import { take } from "rxjs/operators";
import { filter } from "rxjs/operators";
import { IotService } from "./iot.service";
import { Injectable } from "@angular/core";
import { Repository } from "./repository/repository.service";

@Injectable({
  providedIn: "root",
})
export class IpReporterService {
  constructor(
    private iot: IotService,
    private repo: Repository,
    private logger: ILoggingService
  ) {}

  public init() {
    // iot is null for on-prem deployment
    // PWA Required: see LENS-11188
    this.iot?.ready$
      .pipe(
        filter((ready) => ready),
        take(1),
        mergeMap(() => this.repo.getMyIP())
      )
      .subscribe((pip) => {
        this.logger.debug("Got IP: ", { pip });
        this.iot?.patchReportedProperties({ pip });
      });
  }
}
