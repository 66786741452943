import { Component, Input, OnInit } from "@angular/core";
import { Device } from "@poly/hub-native";
import { DeviceManagerService } from "../../services/device-manager.service";
import { ReconnectDeviceEvents } from "../../services/reconnect-device-events.service";
import { UtilityService } from "../../services/utility.service";
import { DEVICE_SETTING_ID_RESTART_DEVICE } from "../../utils/constants";

/**
 * Restart Device
 *
 * For devices which support provisioning, this option is
 * available under Admin category (Settings tab).
 */

@Component({
  selector: "oz-device-setting-restart-device",
  templateUrl: "./restart-device.component.pug",
})
export class DeviceSettingRestartDeviceComponent implements OnInit {
  readonly TRANSLATION_PREFIX = "MODALS.RESTART_DEVICE.";

  // Translated name of a setting
  name: string;

  // Translated button text of a setting
  buttonText: string;

  // Translated description of a setting
  description: string;

  // Manages visibility of the restart device confirm dialog
  showDialog = false;

  @Input()
  device: Device;

  constructor(
    private deviceManager: DeviceManagerService,
    private reconnectDeviceEvents: ReconnectDeviceEvents
  ) {}

  ngOnInit() {
    this.name = this.translate("name");
    this.description = this.translate("description");
    this.buttonText = this.translate("resetBtn");
  }

  onConfirmClick() {
    // Hide the dialog
    this.showDialog = false;

    // Initiate a process of restarting the device
    this.deviceManager.setDeviceSetting(this.device.id, {
      id: DEVICE_SETTING_ID_RESTART_DEVICE,
      value: "true",
    });

    // Fire off an event in the UI code that the device is being reconnected
    this.reconnectDeviceEvents.reconnectStarted(this.device);
  }

  /**
   * Obtains a translation string for this setting.
   *
   * @param path The translation path
   * @returns Translated string found on the provided path
   */
  private translate(path: string) {
    return UtilityService.getDeviceSettingTranslatePath(
      { id: DEVICE_SETTING_ID_RESTART_DEVICE },
      path
    );
  }
}
