import {
  AfterViewInit,
  Component,
  ElementRef,
  forwardRef,
  Input,
  ViewChild,
} from "@angular/core";
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";

const COLORS = {
  disabled: "#000",
  save: "#26870d",
  cancel: "#e2184a",
};

@Component({
  selector: "oz-input-with-confirm",
  templateUrl: "./input-with-confirm.component.pug",
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => InputWithConfirmComponent),
      multi: true,
    },
  ],
})
export class InputWithConfirmComponent
  implements ControlValueAccessor, AfterViewInit {
  /**
   * Supports optional on / off values, defaults to true (on) / false (off)
   */
  @Input() valueOff = false;
  @Input() valueOn = true;
  @Input() waitForAnimation = false;
  // focus input immediately on creation
  @Input() focusOnCreate = true;
  @ViewChild("textInput") textInput: ElementRef;

  private onChange: (_: any) => {};
  private onTouched: (_: any) => {};
  public disabled: boolean;
  public can = {
    save: true,
    cancel: true,
  };
  public color = {
    save: COLORS.save,
    cancel: COLORS.cancel,
  };
  public newValue: string = undefined;
  private originalValue: string = undefined;

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  writeValue(value: string | null): void {
    // Angular initializes values to null, return early
    if (null === value) {
      return;
    }

    // this.originalValue starts as undefined, gets set as null, then a real value
    // on initial value set, select all text if focus is on
    const isFirstValue = "undefined" === typeof this.originalValue;

    this.originalValue = value;
    this.newValue = value;

    if (isFirstValue && this.focusOnCreate) {
      setTimeout(() => {
        this.textInput.nativeElement.setSelectionRange(0, value.length);
      }, 0);
    }
  }

  updateValue() {
    // It is OK for user to save even if new value is same as original,
    // for instance saving a new favorite - user can edit name but can also accept given name
    this.can.save = this.newValue.trim() !== "";

    this.can.cancel = true;

    this.color.save = this.can.save ? COLORS.save : COLORS.disabled;
  }

  emitChange(confirm: boolean) {
    if (false === confirm) {
      this.newValue = this.originalValue;
    }

    this.doOnChange();
  }

  doOnChange() {
    this.onChange(this.newValue);
  }

  ngAfterViewInit() {
    if (this.focusOnCreate) {
      this.textInput.nativeElement.focus();
    }
  }
}
