import { MS_IN_HOUR } from "../../../utils/constants";
import { toPerc } from "../../../utils/utils";
import { AnimationItem } from "lottie-web";
import { ILoggingService } from "../../../services/logging.service";
import { throttleTime, tap, take, filter } from "rxjs/operators";
import { switchMap } from "rxjs/operators";
import { Component, Input, NgZone, OnDestroy, OnInit } from "@angular/core";
import { Subject, Subscription, timer, merge, Observable } from "rxjs";
import { UtilityService } from "../../../services/utility.service";
// TODO: import * as open from "open";
import { SettingsMetaData } from "../../lens-settings-ui.model";
import { LensSettingsService } from "../../../services/lens-settings.service";
import { gte as semverGte, valid as semverValid } from "semver";
import { IFileDownload } from "../../../services/file-download.service";
import { runInZone } from "../../../utils/rxjs.utils";
import { AccordionItem } from "../../../shared/components/accordion/accordion.component";
import { Repository } from "../../../services/repository/repository.service";
import { RepositorySoftware } from "../../../services/repository/model";
import { PolytronServiceApi } from "../../../polytron/polytron.service.api";
import { AdminConfig } from "../../../services/admin-config.service";
import { TranslateService } from "@ngx-translate/core";

const path = require("path");
const REFRESH_INTERVAL = MS_IN_HOUR * 2;

export interface LensSettingSoftwareUpdateMetadata {
  action: string;
  type?: string;
  title: string;
}

@Component({
  selector: "oz-lens-settings-software-update",
  templateUrl: "./lens-settings-software-update.component.pug",
})
export class LensSettingsSoftwareUpdateComponent implements OnInit, OnDestroy {
  private settingsSubscription: Subscription;
  private softwareUpdateSub: Subscription;
  private _manualRefresh$ = new Subject();

  checkingForUpdates = false;
  clicked = false;

  @Input() set accordionData(value: AccordionItem) {
    this.setting = value.data;
  }

  setting: LensSettingSoftwareUpdateMetadata;
  softwareUpdateSettings: SettingsMetaData;

  updateAvailable: boolean;
  build = UtilityService.getBuild();
  version = UtilityService.getBuildVersion();
  updateBuild: string;
  downloadUrl: string;
  pwa: boolean

  constructor(
    private lensSettingsService: LensSettingsService,
    private dlService: IFileDownload,
    private zone: NgZone,
    private logger: ILoggingService,
    private repo: Repository,
    private polytron: PolytronServiceApi,
    private adminConfig: AdminConfig,
    private translate: TranslateService,
  ) {
    this.pwa = adminConfig.mode === "pwa";
  }

  ngOnInit(): void {
    this.settingsSubscription = this.lensSettingsService.lensSettings.subscribe(
      (settings) => {
        this.softwareUpdateSettings = { ...settings };
      }
    );

    if(!this.pwa) {
      this.softwareUpdateSub = merge(
        timer(0, REFRESH_INTERVAL),
        this._manualRefresh$
      )
        .pipe(switchMap(() => this.requestSoftwareUpdates()))
        .subscribe((result: RepositorySoftware) => {
          setTimeout(() => (this.checkingForUpdates = false), 1000);
          if (!result) return;
          const version = result?.version;
          const archiveUrl = result?.productBuild?.archiveUrl;
          const build = result?.productBuild?.build;
          const versionsValid: boolean = null !== semverValid(version) && null !== semverValid(this.version);
          //If the semver version is greater or equal, and the builds are different, update
          if (
            (!versionsValid || semverGte(version, this.version)) &&
            build != this.build &&
            archiveUrl
          ) {
            this.updateAvailable = true;
            this.updateBuild = build;
            this.downloadUrl = archiveUrl;
          }
        });
    }
    else {
      this.checkingForUpdates = false;
      this.updateAvailable = false;
    }
  }

  requestSoftwareUpdates(): Observable<RepositorySoftware> {
    this.checkingForUpdates = true;

    return this.repo.getSoftware({
      productId: UtilityService.getBuildProductId(),
      releaseChannel: UtilityService.getBuildReleaseChannel(),
    });
  }

  ngOnDestroy() {
    this.settingsSubscription.unsubscribe();
    if(!this.pwa) {
      this.softwareUpdateSub.unsubscribe();
    }
  }

  onValueChanged(ev: any, action: string) {
    this.softwareUpdateSettings[action] = ev;
    this.lensSettingsService.setLensSettings(this.softwareUpdateSettings);
  }

  checkForUpdates() {
    if(!this.pwa) {
      return;
    }
    this._manualRefresh$.next(null);
  }

  showUpdateModal = false;
  downloadPercent = 0;
  downloadState: "downloading" | "complete" | "error" = null;
  async updateLensSoftware() {
    this.showUpdateModal = true;
    this.downloadState = "downloading";
    this.downloadPercent = 0;
    const dest = this.getDownloadDestination();

    this.dlService
      .download(this.downloadUrl, dest)
      .pipe(
        throttleTime(20, undefined, { leading: true, trailing: true }),
        runInZone(this.zone),
        filter(({ state, downloadedBytes, totalBytes }) => {
          this.downloadPercent = toPerc(downloadedBytes, totalBytes);
          return ["success", "failed"].includes(state);
        }),
        take(1),
        tap((success) => {
          success.state === "success"
            ? this.logger.log(
                `${this.downloadUrl}: Archive downloaded to ${dest}`
              )
            : this.logger.error(
                `${this.downloadUrl}: Failed to save archive to ${dest}`
              );
        })
      )
      .subscribe((prog) => {
        this.downloadState = prog.state === "success" ? "complete" : "error";
      });
  }

  getDownloadDestination() {
    const parts = this.downloadUrl.split("/");
    const filename = parts[parts.length - 1];
    const dest = path.join(this.polytron.getPath("downloads"), filename);
    return dest;
  }

  async quitAppAndInstall() {
    await open(this.getDownloadDestination());
    // on windows, have to wait for a bit before we quit otherwise the installer never opens
    setTimeout(() => this.polytron.quit(), 5000);
  }

  animationCreated(animationItem: AnimationItem): void {
    // very laggy Windows performance has been observed due to lottie; this improves the condition by not
    // rendering more subframes than necessary based on JSON After Effects frames per second
    animationItem.setSubframe(false);
  }

  openReleaseNotes() {
    UtilityService.openExternalBrowser(
      "https://info.lens.poly.com/lens-web-app-rn"
    );
  }
}
