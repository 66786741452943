import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";

import { DeviceManagerService } from "./device-manager.service";
import {
  BluetoothParams,
  SetBluetoothResponse,
  BluetoothInfo,
} from "@poly/hub-native";

@Injectable({
  providedIn: "root",
})
export class BluetoothService {
  private _bluetoothStatus$ = new BehaviorSubject<BluetoothInfo>(null);

  constructor(private deviceManager: DeviceManagerService) {}

  get bluetoothStatus$() {
    return this._bluetoothStatus$.asObservable();
  }

  setBluetoothSettings(request: BluetoothParams): void {
    this.deviceManager
      .setBluetoothSettings(request)
      .then((response: SetBluetoothResponse) => {
        this.getBluetoothStatus(response.deviceId);
      });
  }

  setBluetoothPairing(deviceId: string): void {
    this.deviceManager.setBluetoothPairing({ deviceId: deviceId });
  }

  getBluetoothStatus(deviceId: string): void {
    this.deviceManager.getBluetoothStatus(deviceId).then((status) => {
      this._bluetoothStatus$.next(status);
    });
  }
}
