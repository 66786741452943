import { LensSettingsService } from "./lens-settings.service";
import { DeviceManagerService } from "./device-manager.service";
import { CallEvent } from "@poly/hub-native";
import { Injectable } from "@angular/core";
import { Howl } from "howler";

@Injectable({
  providedIn: "root",
})
export class SoundscapeService {
  private howl?: Howl;
  private soundscapeSound: any;
  private soundscapeVolume = -1;
  private soundscapeEnabled: boolean;
  private cancelPause = false;

  private activeCalls = new Set<string>();

  constructor(
    private lensSettings: LensSettingsService,
    private deviceManager: DeviceManagerService
  ) {
    this.lensSettings.lensSettings.subscribe((settings) => {
      const { soundscapeSound, soundscapeEnabled, soundscapeVolume } = settings;

      this.soundscapeEnabled = soundscapeEnabled;

      if (!Number.isFinite(soundscapeVolume)) {
        this.lensSettings.patchLensSettings({ soundscapeVolume: 0.5 });
        return;
      }

      if (soundscapeVolume != this.soundscapeVolume)
        this.setSoundscapeVolume(soundscapeVolume);

      if (!soundscapeEnabled) {
        this.disableSoundscape();
      } else if (this.soundscapeSound != soundscapeSound) {
        this.enableSoundscape(soundscapeSound);
      }
    });

    //TODO/JTB: handle this
    // this.deviceManager.getCallEvents().subscribe((callEvent) => {
    //   this.processCallEvent(callEvent);
    // });
  }

  public playSoundscape() {
    this.cancelPause = true;
    if (this.soundscapeEnabled) {
      this.howl?.play();
      this.howl?.fade(0, this.soundscapeVolume, 500);
    }
  }

  public pauseSoundscape() {
    this.cancelPause = false;
    this.howl?.fade(this.soundscapeVolume, 0, 500);
    this.howl?.once("fade", () => {
      if (this.cancelPause) return;

      this.howl?.pause();
    });
  }

  public setSoundscapeVolume(volume: number) {
    if (volume < 0) volume = 0;
    if (volume > 1) volume = 1;

    this.howl?.fade(this.soundscapeVolume, volume, 500);
    this.soundscapeVolume = volume;
  }

  isPlaying(): boolean {
    return this.howl?.playing() ?? false;
  }

  private enableSoundscape(soundscapeSound: string) {
    const sound = "./assets/audio/" + soundscapeSound + ".ogg";
    this.soundscapeSound = soundscapeSound;
    this.stopAndExecute(() => {
      this.howl = new Howl({
        src: sound,
        loop: true,
        volume: 0,
      });

      this.howl?.once("load", () => {
        if (this.activeCalls.size === 0) {
          this.howl?.play();
          this.howl?.fade(0, this.soundscapeVolume, 500);
        }
      });
    });
  }

  private disableSoundscape() {
    this.soundscapeSound = null;
    this.stopAndExecute();
  }

  private stopAndExecute(fn?: Function) {
    if (!this.howl) {
      if (fn) fn();
      return;
    }

    if (this.howl && !this.howl.playing()) {
      this.howl?.unload();
      this.howl = null;
      if (fn) fn();
      return;
    }

    this.howl?.fade(this.soundscapeVolume, 0, 500);
    this.howl?.once("fade", () => {
      this.howl?.unload();
      this.howl = null;
      if (fn) fn();
    });
  }

  private processCallEvent(callEvent: CallEvent) {
    this.updateCall(callEvent.callId, callEvent.name);
    if (this.activeCalls.size > 0) this.pauseSoundscape();
    else this.playSoundscape();
  }

  private updateCall(callId: string, event: string) {
    let inCall = false;
    switch (event) {
      case "accept":
      case "ringing":
      case "resume":
      case "mute":
      case "inProgress":
      case "mobileCallRinging":
      case "mobileCallInProgress":
      case "makeCall":
        inCall = true;
    }

    if (inCall) this.activeCalls.add(callId);
    else this.activeCalls.delete(callId);
  }
}
