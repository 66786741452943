import { NetworkTestResult } from "../IPC/SpeedTest";
import { PingTest } from "./PingTest";
import { Utility } from "./Utility";
import {ILoggingService} from "../app/services/logging.service";

// const logger = require("electron-log");
// const wifi = require("node-wifi");
// const { networkInterfaces } = require("os");
const _ = require("lodash");
// const defaultGateway = require("default-gateway");

export interface INetworkInterface {
  address: string;
  netmask: string;
  family: "IPv4" | "IPv6";
  mac: string;
  internal: boolean;
  cidr: string;
}

export interface INetworkInterfaces {
  [key: string]: Array<INetworkInterface>;
}

export interface IWiFiNetwork {
  ssid: string; // broadcast SSID, e.g. "Romans10:9"
  bssid: string; // e.g. "9c:3b:ad:18:0d:ff"
  mac: string; // equals bssid for backwards compatibility, e.g. "9c:3b:ad:18:0d:ff"
  channel: string | number;
  frequency: number;
  signal_level: number; // e.g. -43
  quality: number; // same as signal level, but in percent; numbers higher than 100 have been observed
  security: string; // format depending on locale for open networks in Windows, e.g. 'WPA WPA2'
  security_flags: Array<string>; // encryption protocols (format currently depending of the OS)
  mode?: string; // network mode like Infra (format currently depending of the OS)
}

export type IWiFiNetworkConcealedIdentifiers = Omit<
  IWiFiNetwork,
  "ssid" | "bssid" | "mac"
>;

export interface LocalNetworkValue {
  value: INetworkInterfaces | Array<IWiFiNetwork> | IDefaultGateways;
  metric:
    | "interfaces"
    | "wifi-current-network"
    | "wifi-networks"
    | "default-gateways";
}

export interface IDefaultGateways {
  v4: {
    gateway: string;
    interface: null | string;
    success: boolean;
    error?: string;
  };
  v6: {
    gateway: string;
    interface: null | string;
    success: boolean;
    error?: string;
  };
  v4icmp?: NetworkTestResult;
}

export class LocalNetworkTest {
  private readonly wifiInitialized: boolean = false;

  constructor(private options: any,
              private logger?: ILoggingService) {
    // Required initialization of wifi module
    // node-wifi spawns another process which may produce a (non-fatal) error if no wifi interface exists on machine
    // TODO:
    // try {
    //   wifi.init({
    //     iface: null, // network interface; choose a random wifi interface if set to null
    //   });
    //   this.wifiInitialized = true;
    // } catch (err) {
    //   logger.info(
    //     "Error initializing WiFi interface, likely due to no WiFi on machine.",
    //     err
    //   );
    // }
  }

  public async run(): Promise<Array<LocalNetworkValue>> {
    // synchronous function
    const interfaces = this.getInterfaces();

    // if changing this array, also review Promise.all(promise).then(...) to ensure proper array values being assigned
    const promises: Array<Promise<any>> = [
      this.getActiveWiFiConnections(),
      this.getDefaultGateways(),
    ];

    if (this.options.wifi_scan) {
      promises.push(this.getWiFiScan());
    }

    // TODO resolve typing, Promise.all returns `void | Array<LocalNetworkValue>`, need to remove void
    // @ts-ignore
    return Promise.all(promises)
      .then(
        ([currentNetworks, defaultGateways, wifiNetworks]): Array<
          LocalNetworkValue
        > => {
          const localNetworkValues: Array<LocalNetworkValue> = [
            {
              value: this.concealIdentifiers(
                interfaces,
                this.options.conceal_identifiers
              ),
              metric: "interfaces",
            },
            {
              value: defaultGateways,
              metric: "default-gateways",
            },
            {
              value: this.concealIdentifiers(
                currentNetworks,
                this.options.conceal_identifiers
              ),
              metric: "wifi-current-network",
            },
          ];

          if (wifiNetworks) {
            localNetworkValues.push({
              value: this.concealIdentifiers(
                wifiNetworks,
                this.options.conceal_identifiers
              ),
              metric: "wifi-networks",
            });
          }

          return localNetworkValues;
        }
      )
      .catch((err) => console.error(err));
  }

  private getDefaultGateways(): Promise<IDefaultGateways> {
    // interface may be null if non-determinable
    return new Promise(async (resolve) => {
      let v4: any;
      let v6: any;
      let v4icmp: NetworkTestResult;

      const resolveObject = () => {
        const { gateways_test } = this.options;
        if (gateways_test && v4.success) {
          gateways_test.address = v4.gateway;

          new PingTest(gateways_test, this.logger).run().then((values) => {
            v4icmp = {
              completed: true,
              testConfig: gateways_test,
              values,
            };

            // final object with v4icmp
            resolve({ v4, v6, v4icmp });
          });

          return;
        }

        // final object, v4icmp not run
        resolve({ v4, v6, v4icmp });
      };

      const createErrorObject = (error: any) => {
        return {
          gateway: undefined,
          interface: undefined,
          success: false,
          error,
        };
      };

/*
      defaultGateway
        .v4()
        .then((result) => {
          v4 = result;
          v4.success = true;
        })
        .catch((err) => {
          v4 = createErrorObject(err);
        })
        .finally(() => {
          if (v6) {
            resolveObject();
          }
        });

      defaultGateway
        .v6()
        .then((result) => {
          v6 = result;
          v6.success = true;
        })
        .catch((err) => {
          v6 = createErrorObject(err);
        })
        .finally(() => {
          if (v4) {
            resolveObject();
          }
        });
*/
    });
  }

  private getInterfaces(): INetworkInterfaces {
    const results = {} as any;
    // TODO:
    // const nets = networkInterfaces();
    //
    // for (const name of Object.keys(nets)) {
    //   for (const net of nets[name]) {
    //     // skip over non-ipv4 and internal (i.e. 127.0.0.1) addresses
    //     if (net.family === "IPv4" && !net.internal) {
    //       if (!results[name]) {
    //         results[name] = [];
    //       }
    //
    //       results[name].push(net);
    //     }
    //   }
    // }

    return results;
  }

  /**
   * Scan nearby WiFi networks.  Returns a collection of networks.
   *
   * @private
   */
  private getWiFiScan(): Promise<Array<IWiFiNetwork>> {
    return new Promise((resolve, reject) => {
      if (!this.wifiInitialized) {
        reject("WiFi not initialized, unable to run WiFi scan test.");

        return;
      }

      // TODO:
      console.log("XXX - wifi.scan");
      // wifi.scan((error, networks) => {
      //   if (error) {
      //     reject(error);
      //   } else {
      //     resolve(networks);
      //   }
      // });
    });
  }

  private getActiveWiFiConnections(): Promise<Array<IWiFiNetwork>> {
    return new Promise((resolve, reject) => {
      if (!this.wifiInitialized) {
        reject(
          "WiFi not initialized, unable to run active WiFi connections test."
        );

        return;
      }

      // TODO:
      // wifi.getCurrentConnections((error, currentConnections) => {
      //   if (error) {
      //     console.error(error);
      //     reject(error);
      //   } else {
      //     resolve(currentConnections);
      //   }
      // });
    });
  }

  /**
   * Searches an array or object for identifying ethernet and WiFi
   * keys, including mac, ssid and bssid.
   *
   * @param values
   * @param concealIdentifiers - optional, default to true
   * @private
   */
  private concealIdentifiers(values: any, concealIdentifiers: boolean = true) {
    if (!concealIdentifiers) {
      return values;
    }

    const deleteKeys = ["bssid", "mac"];
    // network WiFi may show multiple channels and multiple entries for the same SSID
    // for example, the "CR4EVA" network may have multiple 2.4GHz and 5GHz channels
    // and be available via mesh (multiple routers with same config)
    // in this case, the same ssid may be visible multiple times; thus,
    // one-way hash ssid if concealIdentifiers is true so that
    // network congestion can be determined independent of the same ssid
    const hashKeys = ["ssid"];

    if (Array.isArray(values)) {
      values.forEach((value, i) => {
        values[i] = this.concealIdentifiers(value);
      });

      return values;
    }

    if ("object" === typeof values) {
      for (const prop in values) {
        if (values.hasOwnProperty(prop)) {
          if (deleteKeys.includes(prop)) {
            delete values[prop];

            // go to next prop since this props is now deleted
            continue;
          }

          if (hashKeys.includes(prop)) {
            values[prop] =
              values[prop].substr(0, 3) +
              "-" +
              Utility.simpleHash(values[prop]).toString();

            // no need to further process this prop
            continue;
          }

          if ("object" === typeof values[prop]) {
            values[prop] = this.concealIdentifiers(values[prop]);
          }
        }
      }
    }

    return values;
  }
}
