import { Component, OnInit } from "@angular/core";

@Component({
  selector: "oz-getting-started",
  templateUrl: "./getting-started.component.pug",
})
export class GettingStartedComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
