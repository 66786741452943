import { Pipe, PipeTransform } from "@angular/core";
import { Device } from "@poly/hub-native";

export function getBatteryLevel(device: Device) {
  if (device && device.battery?.level) {
    const level: number = device.battery?.level;

    switch (level) {
      /**
       * In plain English:
       * 0 means the battery is empty
       * 1..3 indicate a level of battery between empty and full
       * 4 means the battery is full
       * 5 means the battery is unknown
       *
       * For code purposes, this is the mapping
       * 0 -> battery >= 0% and < 25% (critical)
       * 1 -> battery ~ 25%
       * 2 -> battery ~ 50%
       * 3 -> battery ~ 75%
       * 4 -> battery = 100% (full)
       * 5 -> battery status is unknown
       */
      case 0:
        return "Critical";
      case 1:
        return "25%";
      case 2:
        return "50%";
      case 3:
        return "75%";
      case 4:
        return "100%";
      case 5:
        return "Unknown";
    }
  }
  return "Unknown"
}

@Pipe({
  name: "batteryLevel",
})
export class BatteryLevelPipe implements PipeTransform {
  transform(device: Device): string {
    return getBatteryLevel(device);
  }
}
