import ByteBuffer from "bytebuffer";
import { ILoggingService } from "../../app/services/logging.service";
import { DEV_SETTING_ID } from "./settings-def";
import { ErrorResponseError, InvalidResponseError, ReqTimeoutError, UnsupportedDeviceError, WhError } from "./errors";
import {
  decodeText,
  ROH_PACKET_STATUS,
  RohBodyType,
  RohRequest,
  RohResponseHeader,
  RohResponseParser,
  RohType,
  RohUrl
} from "./roh-message";
import {ROH_DEVICE_SETTINGS, RohDeviceSetting} from "./roh-settings-def";
import {WhDevice} from "./wh-device";
import { buf2hex, hex4l } from "./wh-utils";
import {
  AdminLoginResponse,
  CreateCertificateRequest,
  CreateCertificateResponse,
  GeneralSettings,
  GetCertificateFileResponse,
  GetCertificateInfoResponse,
  GetGeneralSettingsResponse,
  GetInstalledCertificatesResponse, NetworkProvisioningInfo, NetworkProvisioningParams,
  RESTDeviceError,
  ScannedNetwork,
  ScannedNetworksResponse, SetGeneralSettingsResponse,
  SetServerCAValidationResponse,
  SetSimplePasswordResponse,
  SimplePasswordResponse,
  WiFiConnectionInfo,
  WiFiConnectParams,
  WiFiStatus,
  WiFiStatusResponse
} from "../api/device-manager.api";


interface NetProvServerInfo {
  provision_server_url: string;
  provision_server_type: string;
  provision_username: string;
  provision_mode: string;
  provision_interval: string;
}

type RohAction = () => Promise<void>;

interface RohOperation {
  type: RohType;
  url: RohUrl
}


const P5_DEVICE_ID = 0x9296;
const P5_URLS = {
  supported_urls: {
    device_info: {
      json: true,
      ops: [ "GET" ],
      params: {
        device_name: { "type": "string" },
        model: { type: "string" },
        sn: { type: "string" },
        version_hw: { type: "string" },
        version_sw: { type: "string" }
      }
    },
    ops: {
      json: true,
      ops: [ "PUT" ],
      params: {
        action: {
          allowed: [ "reset" ],
          type: "string"
        },
      }
    },
  }
};

function extractUsbVersion(versionSw: string): number {
  // Web USB API is unavailable so we have to extract USB bcdDevice from version_sw:
  //   P5:  1.96 => 0x196
  //   P15: 2.1.0.001158 => 0x1158
  let s = versionSw.replace('.', '');
  if (4 < s.length) {
    s = s.substring(s.length - 4);
  }
  return parseInt('0x' + s);
}

type JsonResponse = any; // we really need type that is 'any' but not 'undefined | null | string | number | boolean | symbol'

export class RohHid {

  constructor(private device: WhDevice, private hidDevice: HIDDevice,
              private inReportId: number, private featureReportId: number, private featureReportSize: number,
              public logger: ILoggingService) {
    this.transaction = undefined;
    this.responseParser = new RohResponseParser(logger);
  }

  loadDeviceData(): Promise<void> {
    this.supportedUrls = {};
    let promise = this.postGetJsonRequest(RohUrl.RU_DEVICE_INFO)
      .then(json => this.processDeviceInfo(json))
      .then(() => this.postGetJsonRequest(RohUrl.RU_SUPPORTED_URLS)
        .catch(() => (P5_DEVICE_ID === this.device.productId()) ? P5_URLS : Promise.reject())
        .then(json => {
          const actions = json.supported_urls?.ops?.params?.action?.allowed;
          if (Array.isArray(actions) && actions.includes("reset")) {
            this.addRestoreDefaultsAction();
          }
          this.supportedUrls = json.supported_urls ?? {};
        })
        .catch(() => {})
      )
      .then(() => {
        let promise = Promise.resolve();
        for (let s of ROH_DEVICE_SETTINGS) {
          if (this.isSupported(RohType.RT_GET, s.url, s.name)) {
            const param = this.supportedUrls[s.url].params[s.name];
            const vals = param.allowed;
            const range = param.range;
            this.logger.info(`setting ${hex4l(s.id)}: RoH ${s.url} ${s.name} ${vals ? '' : 'range'}[${vals ?? range}]`);
            promise = promise
            .then(() => this.postGetJsonRequest(s.url)
              .then(json => this.storeRohSetting(s, json))
              .catch(() => {})
            );
          }
        }
        return promise;
      })
      .then(() => {
        if (this.isSupported(RohType.RT_GET, RohUrl.RU_WIFI_STATUS)) {
          promise = promise
          .then(() => this.postGetJsonRequest(RohUrl.RU_WIFI_STATUS)
            .then(json => this.logger.info("WiFi status", json))
          );
        }
      });

    return promise;
  }

  private isSupported(type: RohType, url: RohUrl, param?: string): boolean {
    if (url in this.supportedUrls) {
      const urlDef = this.supportedUrls[url];
      const ops = urlDef.ops;
      if (Array.isArray(ops) && ops.includes(type)) {
        return undefined === param || (param in urlDef.params);
      }
    }
    return false;
  }

  private checkUrlOp(type: RohType, url: RohUrl): Promise<RohOperation> {
    if (this.isSupported(type, url)) {
      return Promise.resolve({type, url});
    }
    return Promise.reject(new UnsupportedDeviceError(`unsupported ${type} ${url}`))
  }

  private postGetJsonRequest(url: RohUrl): Promise<JsonResponse> {
    return this.postRohRequest(RohType.RT_GET, url)
      .then(buf => {
        let txt: string | undefined;
        try {
          txt = decodeText(buf);
          this.logger.info(`GET ${url} returned: ${txt}`);
          const obj = JSON.parse(txt);
          if ("object" !== typeof obj) {
            return Promise.reject(new InvalidResponseError(`Unexpected JSON response body: ${obj}`));
          }
          return obj;
        }
        catch (err) {
          this.logger.warn(`Unparsable JSON response body: ${txt ?? buf2hex(buf)}`);
          return Promise.reject(new InvalidResponseError(String(err)));
        }
      });
  }

  onReport(dv: DataView) {
    this.featureReportRetries = 0;
    const type = dv.getUint8(0);
    const msgId = dv.getUint16(1, true);
    this.logger.debug(`onReport type=${type} msg-id=${msgId}`);
    if (dv.byteLength === 3) {
      if (type === 1) {
        this.handleNewReport(msgId);
      }
      else {
        this.handleAsyncNotification(msgId);
      }
    }
  }

  private handleAsyncNotification(msgId: number): void {
    this.logger.info("Received asynch notification", msgId);
  }

  private handleNewReport(msgId: number): void {
    this.hidDevice.receiveFeatureReport(this.featureReportId)
      .then(dv => {
        this.logger.info("Raw report:", dv);
        if (dv.byteLength < 4) {
          this.logger.info("RoH report too short -- ignoring");
          return;
        }

        const status = this.responseParser.examinePacket(dv);
        switch (status) {
          case ROH_PACKET_STATUS.ERROR:
            this.failTransaction(new InvalidResponseError("RoH response error"));
            return;
          case ROH_PACKET_STATUS.NOT_LAST:
            this.handleNewReport(msgId);
            return;
          default:
          case ROH_PACKET_STATUS.LAST:
            const [headerBB, bodyBB, bodyType] =  this.responseParser.parse();
            this.processRohBuffers(headerBB, bodyBB, bodyType);
            break;
        }
      })
      .catch(err => {
        if (this.featureReportRetries++ < 3) {
          this.logger.warn("USB feature report not ready to read. Retrying..");
          const _boundHandleNewReport = this.handleNewReport.bind(this);
          setTimeout(() => { _boundHandleNewReport(msgId)}, 200);
        }
        else {
          this.logger.error(`After retries, failed to read feature report ${this.featureReportId}`, err)
          this.failTransaction(new ReqTimeoutError(`failed to read feature report ${this.featureReportId}: ${err}`));
        }
      });
  }

  private processRohBuffers(headerBB: ByteBuffer | undefined, bodyBB: ByteBuffer, bodyType: RohBodyType): void {

    let bodyBuf = bodyBB.toBuffer();
    // This means the parsing did not yield a valid RoH response
    if (headerBB === undefined && bodyBuf.length === 0) {
      this.failTransaction(new InvalidResponseError("missing RoH response header"));
      return;
    }

    let header: RohResponseHeader | undefined = undefined;
    if (headerBB !== undefined) {
      const txt = decodeText(headerBB.toBuffer());
      this.logger.info(`Roh header: ${txt}`);
      try {
        header = JSON.parse(txt);
      }
      catch (err) {
        this.logger.error("Failed reading JSON for header", err);
        this.failTransaction(new InvalidResponseError(`Invalid json in RoH response header (${err})`));
        return;
      }
      if (!header?.status_code?.startsWith("2")) {
        if (header?.status_code === "404") {
          this.logger.info("Device does not support URL");
        }
        else {
          this.logger.warn("Failed RoH request", header);
        }
        this.failTransaction(new ErrorResponseError(`RoH response with status code=${header?.status_code}`));
        return;
      }
      if (header.msg_id !== this.transaction?.id) {
        this.logger.warn(`ignoring unexpected response, expected id=${this.transaction?.id}`, header);
        return;
      }
    }

    this.endTransaction(bodyBB.toArrayBuffer());
  }

  private processDeviceInfo(bodyStr: { [key: string]: string }) {
      let isDevInfo: boolean = false;
      this.device.deleteSetId();
      if (bodyStr['version_sw'] !== undefined) {
        this.device.setUsbVersion(extractUsbVersion(bodyStr['version_sw']));
        isDevInfo = true;
      }
      if (bodyStr['sn'] !== undefined) {
        this.device.setVideoSerialNumber(bodyStr['sn']);
        this.device.setUniqueId(bodyStr['sn']);
      }
      if (isDevInfo) {
        this.device.setVideoHardwareVersion(bodyStr['version_hw']);
        this.device.setVideoSoftwareVersion(bodyStr['version_sw']);
        this.device.setVideoDiagCode(bodyStr['emmc_pre_eol']);
        this.device.setVideoDeviceName(bodyStr['device_name']);
        this.device.setVideoDeviceTime(bodyStr['device_time']);
        this.device.setVideoIpAddress(bodyStr['main_ip_address']);
        this.device.setVideoMacAddress(bodyStr['main_mac_address']);
      }
  }

  private startTransaction(): void {
    this.logger.debug(`Starting RoH transaction, id=${this.transaction?.id}`);
    this.transaction.action().catch((err) => {
      this.logger.error(`Failed action for transaction, id=${this.transaction?.id}`, err);
      this.failTransaction(err);
    });
  }

  private endTransaction(body: ArrayBuffer) {
    this.transaction?.resolve(body);
    this.logger.debug(`Ended RoH transaction, id=${this.transaction?.id}`);
    this.nextTransaction();
  }

  private failTransaction(reason: WhError) {
    this.transaction?.reject(reason);
    this.logger.debug(`Failed RoH transaction, id=${this.transaction?.id}`);
    this.nextTransaction();
  }

  private nextTransaction() {
    this.transaction = this.rohQueue.shift();
    if (undefined !== this.transaction) {
      this.startTransaction();
    }
  }

  private storeRohSetting(s: RohDeviceSetting, obj: JsonResponse): void {
    let opts: string[];
    if (s.mapping !== undefined) {
      opts = Object.keys(s.mapping);
    }
    else if (s.range_fields != undefined) {
      opts = [obj[s.range_fields.min].toString(), obj[s.range_fields.max].toString(), '1'];
      if (s.range_fields.step && obj[s.range_fields.step] !== undefined) {
        opts[2] = obj[s.range_fields.step].toString();
      }
    }
    else {
      opts = [];
    }

    this.device.addSetting(s.id, opts, val => this.setRohSetting(s, val));
    this.storeSetting(s.id, RohHid.fromDeviceValue(s, obj[s.name]));
  }

  private storeSetting(settingId: number, value: string | undefined): boolean {
    return undefined === value ? false : this.device.storeSetting(settingId, value)
  }

  private addRestoreDefaultsAction() {
    this.device.addSetting(DEV_SETTING_ID.RESTORE_DEFAULTS, [],
      () => {
        this.logger.info("Restoring device settings to default values");
        const body = this.device.productId() === P5_DEVICE_ID ? `{"action":"reset"}` : `{"action":"reset", "force":true}`;
        return this.postRohRequest(RohType.RT_PUT, RohUrl.RU_OPS, body).then(() => {});
      });
  }

  isRohReport(reportId: number) {
    return reportId == this.inReportId;
  }

  private static toDeviceValue(s: RohDeviceSetting, value: string): string {
    if (s.mapping === undefined) {
      return value;
    }
    return s.mapping[value];
  }

  private static fromDeviceValue(s: RohDeviceSetting, value: any): string | undefined {
    value = value?.toString();
    if (s.mapping === undefined || value === undefined) {
      return value;
    }
    for(const [k, v] of Object.entries(s.mapping)) {
      if (v === value) {
        return k;
      }
    }
  }

  private setRohSetting(s: RohDeviceSetting, value: string): Promise<void> {
    const body = `{"${s.name}":"${RohHid.toDeviceValue(s, value)}"}`;
    return this.postRohRequest(RohType.RT_PUT, s.url, body)
    .then(() => {
      this.storeSetting(s.id, value);
    });
  }

  private postRohRequest(type: RohType, url: RohUrl, body?: string): Promise<ArrayBuffer> {
    const req = new RohRequest(type, url, this.featureReportSize, body);
    const id = req.id;
    const pkt = req.getPacket();
    const action = () => {
      this.logger.info(`sending ${type} ${url} msg_id=${id}`, body);
      return this.hidDevice.sendFeatureReport(this.featureReportId, pkt);
    }
    let t = new RohTransaction(action, id);
    this.logger.info(`new transaction, ${type} ${url} msg_id=${id}`)
    if (this.transaction === undefined) {
      this.transaction = t;
      this.startTransaction();
    }
    else {
      this.rohQueue.push(t);
    }
    return t.promise;
  }

  getLogs(): Promise<ArrayBuffer> {
    return this.checkUrlOp(RohType.RT_GET, RohUrl.RU_SYSTEM_LOG)
    .then(op => this.postRohRequest(op.type, op.url));
  }

  getScannedNetworks(): Promise<ScannedNetworksResponse> {
    return this.checkUrlOp(RohType.RT_GET, RohUrl.RU_WIFI_AVAILABLE)
    .then(op => this.postGetJsonRequest(op.url))
    .then(json => parseScannedNetworks(this.device.deviceId(), json));
  }

  getWiFiStatus(): Promise<WiFiStatusResponse> {
    return this.checkUrlOp(RohType.RT_GET, RohUrl.RU_WIFI_STATUS)
    .then(op => this.postGetJsonRequest(op.url))
    .then(json => parseWiFiStatus(this.device.deviceId(), json));
  }

  getCertificateInfo(): Promise<GetCertificateInfoResponse> {
    return new Promise<GetCertificateInfoResponse>((resolve, reject) => {
      if (RohUrl.RU_CERTIFICATE in this.supportedUrls) {
        this.postGetJsonRequest(RohUrl.RU_CERTIFICATE).then(json => {
          this.logger.info("Get certificate CSR info", json);
          const csrInfo = parseCsrInfo(this.device.deviceId(), json);
          resolve(csrInfo);
        });
      } else {
        reject("Certificates not supported");
      }
    });
  }

  setServerCAValidation(enable: boolean): Promise<SetServerCAValidationResponse> {
    return new Promise<SetServerCAValidationResponse>((resolve, reject) => {
      if (RohUrl.RU_SERVER_CA_VALIDATION in this.supportedUrls) {
        return this.postRohRequest(RohType.RT_PUT, RohUrl.RU_SERVER_CA_VALIDATION, `{"enable": "${enable}"}`).then((buf) => {
          resolve({deviceId: this.device.deviceId(), status: "OK"});
        });
      }
      else {
        reject("Setting CA certificate validation not supported");
      }
    });
  }

  getInstalledCertificates(): Promise<GetInstalledCertificatesResponse> {
    return new Promise<GetInstalledCertificatesResponse>((resolve, reject) => {
      if (RohUrl.RU_LIST_CERTIFICATES in this.supportedUrls) {
        this.postGetJsonRequest(RohUrl.RU_LIST_CERTIFICATES).then(json => {
          this.logger.info("Get installed certificates", json);
          const certs = parseInstalledCerts(this.device.deviceId(), json);
          resolve(certs);
        });
      } else {
        reject("Listing certificates not supported");
      }
    });
  }

  createCertificateCsr(request: CreateCertificateRequest): Promise<CreateCertificateResponse> {
    return new Promise<GetCertificateFileResponse>((resolve, reject) => {
      if (RohUrl.RU_CERTIFICATE in this.supportedUrls) {
        return this.postRohRequest(RohType.RT_PUT, RohUrl.RU_CERTIFICATE, toJsonCreateCsr(request))
          .then((buf) => this.parseJson(buf))
          .then((json) => {
            resolve({
              deviceId: this.device.deviceId(),
              status: "OK"
            });
          });
      }
      else {
        reject("Setting CSR data not supported");
      }
    });
  }

  getCsrFile(): Promise<GetCertificateFileResponse> {
    return new Promise<GetCertificateFileResponse>((resolve, reject) => {
      if (RohUrl.RU_CERTIFICATE_FILE in this.supportedUrls) {
        return this.postRohRequest(RohType.RT_GET, RohUrl.RU_CERTIFICATE_FILE)
          .then((buf) => {
            const csrFile = parseCsrResponse(this.device.deviceId(), buf);
            this.logger.info("Read CSR data from device", csrFile.status);
            resolve(csrFile);
          });
      } else {
        reject("Getting CSR file not supported");
      }
    });
  }

  getGeneralSettings(): Promise<GetGeneralSettingsResponse> {
    return new Promise<GetGeneralSettingsResponse>((resolve, reject) => {
      if (RohUrl.RU_GENERAL_SETTINGS in this.supportedUrls) {
        this.postGetJsonRequest(RohUrl.RU_GENERAL_SETTINGS).then(json => {
          const settings = parseGeneralSettings(this.device.deviceId(), json);
          this.logger.info("General settings response", json, settings);
          resolve(settings);
        });
      } else {
        reject("General settings not supported");
      }
    });
  }

  setGeneralSettings(request: GeneralSettings): Promise<SetGeneralSettingsResponse> {
    return new Promise<SetGeneralSettingsResponse>((resolve, reject) => {
      if (RohUrl.RU_GENERAL_SETTINGS in this.supportedUrls) {
        this.postRohRequest(RohType.RT_PUT, RohUrl.RU_GENERAL_SETTINGS, toJsonGeneralSettings(request)).then(() => {
          resolve({deviceId: this.device.deviceId(), status: "OK"});
        });
      } else {
        reject("General settings not supported");
      }
    });
  }

  adminLogin(password: string): Promise<AdminLoginResponse> {
    return this.postRohRequest(RohType.RT_PUT, RohUrl.RU_ADMIN_LOGIN, `{"password": "${password}"}`)
      .then((buf) => this.parseJson(buf)).then((json) => {
        this.logger.info("Admin login response", json);
        return Promise.resolve({ deviceId: this.device.deviceId(), status: "OK", result: true });
    });
  }

  parseJson(buf: ArrayBuffer): Promise<JsonResponse> {
    let txt: string | undefined;
    if (buf.byteLength === 0) {
      return Promise.resolve({});
    }
    try {
      txt = decodeText(buf);
      this.logger.info(`Roh json body: ${txt}`);
      const obj = JSON.parse(txt);
      if ("object" !== typeof obj) {
        return Promise.reject(`Unexpected JSON response body: ${obj}`);
      }
      return obj;
    }
    catch (err) {
      this.logger.warn(`Unparsable JSON response body: ${undefined !== txt ? txt : buf2hex(buf)}`);
      return Promise.reject(err);
    }
  }

  setWiFiParameters(request: WiFiConnectParams): Promise<void> {
    const json = toJson(request);
    this.logger.info("Setting WiFi parameters", json);
    return this.postRohRequest(RohType.RT_PUT, RohUrl.RU_WIFI, json.toString()).then(() => {
      this.logger.info("End setWiFiParameters transaction");
      return Promise.resolve();
    });
  }

  simplePassword(): Promise<SimplePasswordResponse> {
    return this.postGetJsonRequest(RohUrl.RU_SIMPLE_PASSWORD).then((json) => {
      const ret: SimplePasswordResponse = {
        deviceId: this.device.deviceId(),
        status: "OK",
        simple: json['simple_password'] === 'true'
      }
      return Promise.resolve(ret);
    });
  }

  setSimplePassword(simple: boolean): Promise<SetSimplePasswordResponse> {
    return this.postRohRequest(RohType.RT_PUT, RohUrl.RU_SIMPLE_PASSWORD).then((buf) => {
      return Promise.resolve({ deviceId: this.device.deviceId(), status: "OK"});
    });
  }

  getNetworkProvisioning(): Promise<NetworkProvisioningInfo> {
    return new Promise<NetworkProvisioningInfo>((resolve, reject) => {
      if (RohUrl.RU_PROVISION_SERVER in this.supportedUrls) {
        this.postGetJsonRequest(RohUrl.RU_PROVISION_SERVER).then(json => {
          const settings = parseNetworkProvisioning(this.device.deviceId(), json);
          this.logger.info("Network provisioning settings response", json, settings);
          resolve(settings);
        });
      } else {
        reject("Network provisioning settings not supported");
      }
    });
  }

  setNetworkProvisioning(request: NetworkProvisioningParams): Promise<NetworkProvisioningInfo> {
    return new Promise<NetworkProvisioningInfo>((resolve, reject) => {
      if (RohUrl.RU_PROVISION_SERVER in this.supportedUrls) {
        this.postRohRequest(RohType.RT_PUT, RohUrl.RU_GENERAL_SETTINGS, toJsonNetworkProvisioning(request)).then(() => {
          let ret: NetworkProvisioningInfo = {
            deviceId: this.device.deviceId(),
            status: "OK",
            interval: 0,
            provisioningMode: "",
            serverAddress: "",
            serverType: "",
            username: "",
          }
          resolve(ret);
        });
      } else {
        reject("Network provisioning settings not supported");
      }
    });
  }

  private transaction?: RohTransaction | undefined = undefined;
  private rohQueue: Array<RohTransaction> = [];
  private featureReportRetries = 0;
  private supportedUrls: JsonResponse = {};
  private responseParser: RohResponseParser;
}

function path(url: RohUrl, args: string[] ): string {
  if (args.length === 0) {
    return url;
  }
  return `${url}?${args.join(',')}`;
}


class RohTransaction {
  constructor(readonly action: RohAction, readonly id: string) {
    this.promise = new Promise<ArrayBuffer>((resolve, reject) => {
      this.resolve = resolve;
      this.reject = reject;
    });
  }

  public promise: Promise<ArrayBuffer>;
  public resolve: (body: ArrayBuffer) => void;
  public reject: (reason: any) => void;
}


function toJson(request: WiFiConnectParams): string {
  const json: any = {
    wifi_action: request.wifi_action,
    wifi_connect_param: {
      anonymousIdentity: request.anonymousIdentity,
      auto_connect: request.auto_connect,
      caCert: request.caCert,
      clientCert: request.clientCert,
      dhcp: request.dhcp,
      dns1: request.dns1,
      dns2: request.dns2,
      eapMethod: request.eapMethod,
      gateway: request.gateway,
      identity: request.identity,
      ip_address: request.ip_address,
      password: request.password,
      phase2: request.phase2,
      securityType: request.securityType,
      ssid: request.ssid,
      subnet_mask: request.subnet_mask
    }
  };
  return JSON.stringify(json);
}

function parseWiFiStatus(deviceId: string, json: JsonResponse): WiFiStatusResponse {
  const s: WiFiStatusResponse = {
    deviceId: deviceId,
    status: "OK",
    wifi_connection_info: {} as WiFiConnectionInfo,
    wifi_enable: json["wifi_enable"] as boolean,
    wifi_auto_connect: json["wifi_auto_connect"] as boolean,
    main_ip_address: json["main_ip_address"] as string,
    main_mac_address: json["main_mac_address"] as string,
    wifi_status: json["wifi_status"] as WiFiStatus,
  };
  return s;
}

function parseGeneralSettings(deviceId: string, json: {[key: string]: string | boolean}): GetGeneralSettingsResponse {
  const s: GetGeneralSettingsResponse = {
    deviceId: deviceId,
    status: "OK",
    device_name: json["device_name"] as string,
    country_region: json["country_region"] as string,
    ntp_server: json["ntp_server"] as string,
    ntp_mode: json["ntp_mode"] as string,
    camera_vivid_mode: json["camera_vivid_mode"] as boolean,
  }
  return s;
}

function parseScannedNetworks(deviceId: string, json: JsonResponse): ScannedNetworksResponse {
  const ret : ScannedNetworksResponse = {
    deviceId: deviceId,
    status: "OK",
    scannedNetworks: [],
  };
  json.ssid_info.forEach((j: any) => {
    ret.scannedNetworks.push(j as ScannedNetwork);
  });
  return ret;
}

function parseCsrInfo(deviceId: string, json: JsonResponse) {
  const ret: GetCertificateInfoResponse = {
    status: "OK",
    deviceId: deviceId,
    country: json["country"],
    state: json["state"],
    locality: json["locality"],
    organization: json["organization"],
    unit: json["unit"],
    commonName: json["commonName"],
  };
  return ret;
}

function parseInstalledCerts(deviceId: string, json: JsonResponse): GetInstalledCertificatesResponse {
  const ret: GetInstalledCertificatesResponse = {
    status: "OK",
    deviceId: deviceId,
    validation: json["server_ca_validation"] as boolean,
    certs: json["info"],
  };
  return ret;
}

function parseCsrResponse(deviceId: string, data: ArrayBuffer): GetCertificateFileResponse {
  const ret: GetCertificateFileResponse = {
    status: "OK",
    deviceId: deviceId,
    file: new TextDecoder().decode(data),
  };
  return ret;
}

function toJsonGeneralSettings(request: GeneralSettings): string {
  let json: any = {
    device_name: request.device_name,
    country_region: request.country_region,
    ntp_server: request.ntp_server,
    ntp_mode: request.ntp_mode,
    camera_vivid_mode: request.camera_vivid_mode,
  }
  return JSON.stringify(json);
}

function parseNetworkProvisioning(deviceId: string, json: JsonResponse): NetworkProvisioningInfo {
  const serverInfo: NetProvServerInfo = json["provision_server_info"];
  const ret: NetworkProvisioningInfo = {
    deviceId: deviceId,
    status: "OK",
    serverAddress: serverInfo.provision_server_url,
    serverType: serverInfo.provision_server_type,
    username: serverInfo.provision_username,
    provisioningMode: serverInfo.provision_mode,
    interval: Number(serverInfo.provision_interval),
  }
  return ret;
}

function toJsonNetworkProvisioning(request: NetworkProvisioningParams): string {
  let json: any = {
    provision_server_url: request.serverAddress,
    provision_username: request.username,
    provision_password: request.password,
    provision_mode: request.provisioningMode,
    provision_server_type: request.serverType,
  }
  return JSON.stringify(json);
}

function toJsonCreateCsr(request: CreateCertificateRequest): string {
  let json: any = {
    csr_info_provision_country: request.country,
    csr_info_provision_state: request.state,
    csr_info_provision_locality: request.locality,
    csr_info_provision_organization: request.organization,
    csr_info_provision_unit: request.unit,
    csr_info_provision_common_name: request.commonName,
  };
  return JSON.stringify(json);
}
