import { Component, Input, Output, EventEmitter } from "@angular/core";

export interface TranslatedOption {
  value: string;
  text: string;
}

/**
 * @deprecated
 * This is considered deprecated in favor of `oz-dropdown`, which uses
 * ControlValueAccessor and allows ngModel usage.  This allows the new
 * dropdown to be responsive to value updates and to be composed in the
 * "Angular way".
 */
@Component({
  selector: "app-dropdown-deprecated",
  templateUrl: "./dropdown-deprecated.component.pug",
})
export class DropdownDeprecatedComponent {
  @Input() className?: string;

  @Input() options: TranslatedOption[];

  @Input() selected: string;

  @Output() valueChanged = new EventEmitter<string>();

  onChange(newValue: string) {
    this.valueChanged.emit(newValue);
  }
}
