import {
  Component,
  Input,
  OnChanges,
  EventEmitter,
  Output,
  SimpleChanges,
  OnDestroy,
} from "@angular/core";
import { StateService } from "../services/state.service";

@Component({
  selector: "oz-modal",
  templateUrl: "./modal.component.pug",
})
export class ModalComponent implements OnChanges, OnDestroy {
  @Input() show: boolean = false;
  @Input() wide: boolean = false;
  @Output() showChange = new EventEmitter();

  constructor(private stateService: StateService) {}

  ngOnDestroy() {
    this.updateTitleBarDisabled(false);
  }

  ngOnChanges(changes: SimpleChanges) {
    // toggle title bar icons to be disabled when modal shown
    if (
      changes?.show &&
      changes.show.currentValue !== changes.show.previousValue
    ) {
      this.updateTitleBarDisabled(changes.show.currentValue);
    }
  }

  updateTitleBarDisabled(disabled: boolean) {
    this.stateService.setState("TitleBar", {
      disabled,
    });
  }
}
