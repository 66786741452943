import { Component, Input, OnInit } from "@angular/core";

@Component({
  selector: "oz-cta-item",
  templateUrl: "./cta-item.component.pug",
})
export class CtaItemComponent implements OnInit {
  @Input()
  theme: "danger" | "primary" | "default" = "default";
  @Input()
  disabled = false;

  constructor() {}

  ngOnInit(): void {}
}
