import { gql } from "apollo-angular";

export const Queries = {
  AVAILABLE_DISPLAYLINK: {
    QUERY_NAME: "availableProductSoftwareByPid",
    get QUERY() {
      return gql`
        query DisplayLinkSoftware($productId: ID!) {
          ${this.QUERY_NAME}(pid: $productId) {
            id
            name
            version
            publishDate
            productBuild {
              id
              archiveUrl
              storagePath
            }
          }
        }`;
    },
  },
  AVAILABLE_SOFTWARE_VERSION: {
    QUERY_NAME: "availableProductSoftwareByPid",
    get QUERY() {
      return gql`
        query LensSoftwareUpdateAvailable(
          $productId: ID!
          $releaseChannel: String
        ) {
          availableProductSoftwareByPid(
            pid: $productId
            releaseChannel: $releaseChannel
          ) {
            version
            productBuild {
              build
              archiveUrl
            }
          }
        }
      `;
    },
  },
  HARDWARE_PRODUCTS: {
    QUERY_NAME: "hardwareProducts",
    get QUERY() {
      return gql`
        {
          ${this.QUERY_NAME} {
            id
            name
            images {
              url
              name
              metadata {
                type
                width
                height
              }
            }
            hardwareModel {
              id
              name
              skus {
                id
              }
              hardwareFamily {
                id
                name
              }
              hardwareManufacturer {
                id
                name
              }
            }
          }
        }
      `;
    },
  },
  AVAILABLE_FIRMWARE: {
    QUERY_NAME: "availableProductSoftwareByPid",
    get QUERY() {
      return gql`
        query lensDesktopFirmwareUpdateCheck($pid: ID!) {
          ${this.QUERY_NAME}(pid: $pid) {
            productBuild {
              archiveUrl
              rules {
                document
              }
            }
          }
        }`;
    },
  },
  DEVICE_RESOURCES: {
    QUERY_NAME: "hardwareProduct",
    get QUERY() {
      return gql`
        query deviceResources($id: ID!) {
          ${this.QUERY_NAME}(id: $id) {
            metadata {
              quickStartUrl
              userGuideUrl
            }
          }
        }`;
    },
  },
  DEVICE_POLICY: {
    QUERY_NAME: "devicePolicyCapabilities",
    get QUERY() {
      return gql`
        query devicePolicyCapabilities(
          $deviceId: String!
          $productId: String
        ) {
          ${this.QUERY_NAME}(
            deviceId: $deviceId
            productId: $productId
          ) {
            deviceId
            capabilities {
              com {
                poly {
                  software_update {
                    policy {
                      version {
                        value
                      }
                    }
                    base_url {
                      value
                    }
                    archive_url {
                      value
                    }
                    rules_json {
                      value
                    }
                  }
                }
              }
            }
          }
        }`;
    },
  },
  DEVICE_USAGE: {
    QUERY_NAME: "topicIndicators",
    get QUERY() {
      return gql`
          query callUsageWithChartData($tenant: [String!]!) {
            ${this.QUERY_NAME}(
              tenantId: $tenant
              input: {
                locationScope: DEVICE
                timeScope: WEEK
                threshold: 0
                topic: "Call Usage"
              }
            ) {
              edges {
                node {
                  id
                  device {
                    id
                    name
                  }
                  timePeriodStart
                  indicator {
                    ... on CountOfTotalIndicator {
                      count
                    }
                    ... on AverageIndicator {
                      average
                    }
                  }
                  chartData {
                    series {
                      name
                      data {
                        x
                        y
                      }
                    }
                  }
                }
              }
            }
          }
        `;
    },
  },
  GET_MY_IP: {
    QUERY_NAME: "getMyIp",
    get QUERY() {
      return gql`
        {
          ${this.QUERY_NAME} {
            ip
          }
        }
      `;
    },
  },
};

export const DELETE_USER_ACCOUNT = gql`
mutation DeleteUserAccount{
  deleteUser{
  userId
  message
  }
}
`;
