import { Injectable } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import {
  IIPCNotificationScheduledParams,
  IIPCNotificationSingleParams,
  IpcService,
} from "./ipc.service";
import { StorageService } from "./storage.service";
import { Subject, Subscription } from "rxjs";
import { take } from "rxjs/operators";

/**
 * NOTE WHEN USING:
 * The icon is DUPLICATE to the app's icon, so only
 * use an icon when absolutely necessary.
 */
type NotificationIcon =
  | "app-icon.png"
  | "battery-charged-notification.png"
  | "charging-notification.png";

export interface SingleNotification {
  title: string;
  body?: string;
  icon?: NotificationIcon;
  actions?: Array<{ type: "button"; text: string }>;
  origin?:
    | "CHARGING"
    | "DISCONNECTED"
    | "MUTE"
    | "UNPAIRED"
    | "BATTERY_LOW"
    | "DFU_UPDATE";
}

interface SingleNotificationUntranslated extends SingleNotification {
  translationParams?: { [key: string]: string | number };
}

export interface NotificationEvent {
  action: "button" | "close";
  index: number;
  notification: {
    title: string;
    body: string;
    actions: Array<{ type: "button"; text: string }>;
  };
}

@Injectable({
  providedIn: "root",
})
export class NotificationsService {
  private notificationEvents$: Subject<NotificationEvent> = new Subject<
    NotificationEvent
  >();
  private scheduledNotificationsMap = {};

  constructor(
    private ipcService: IpcService,
    private storageService: StorageService,
    private translateService: TranslateService
  ) {
    const notificationMapFromStorage = storageService.getItem(
      "scheduledNotificationsMap"
    );

    if (notificationMapFromStorage) {
      this.scheduledNotificationsMap = notificationMapFromStorage;
    }

    for (const notification in this.scheduledNotificationsMap) {
      this.scheduleNotification(this.scheduledNotificationsMap[notification]);
    }

    this.watchNotifications();
  }

  get events() {
    return this.notificationEvents$;
  }

  public singleNotification({
    title,
    body,
    icon,
    actions,
    origin,
  }: SingleNotification) {
    const params: IIPCNotificationSingleParams["data"] = {
      title,
      body,
      icon,
      actions,
      type: "single",
      origin,
    };
    this.ipcService.send$("messages", {
      namespace: "notification",
      action: "send",
      data: params,
    });
  }

  public singleNotificationUntranslated({
    title,
    body,
    icon,
    translationParams = {},
  }: SingleNotificationUntranslated) {
    this.translateService
      .get([title, body], translationParams)
      .subscribe((translations) => {
        this.singleNotification({
          title: translations[title],
          body: body ? translations[body] : "",
          icon,
        });
      });
  }

  public scheduleNotification({
    title,
    body,
    schedule,
    type,
    key,
    icon,
    origin,
  }: IIPCNotificationScheduledParams["data"]) {
    // if previous notification with same key already exists, delete previous one
    if (this.scheduledNotificationsMap[key]) {
      delete this.scheduledNotificationsMap[key];
    }

    // add new scheduled notification to map
    this.scheduledNotificationsMap[key] = {
      title,
      body,
      schedule,
      type,
      key,
      icon,
      origin,
    };

    // store updated notifications map in local storage
    this.storageService.setItem(
      "scheduledNotificationsMap",
      this.scheduledNotificationsMap
    );

    this.translateService
      .stream([title, body])
      .pipe(take(1))
      .subscribe((translations) => {
        this.ipcService.send$("messages", {
          namespace: "notification",
          action: "send",
          data: {
            title: translations[title],
            body: body ? translations[body] : "",
            schedule,
            type,
            key,
            icon,
            origin,
          },
        });
      });
  }

  public destroyScheduledNotification(key: string) {
    // remove notification from map
    if (this.scheduledNotificationsMap[key]) {
      delete this.scheduledNotificationsMap[key];
    }

    // update local storage with current notification settings
    this.storageService.setItem(
      "scheduledNotificationsMap",
      this.scheduledNotificationsMap
    );

    this.ipcService.send$("messages", {
      namespace: "notification",
      action: "send",
      data: {
        type: "destroy",
        key,
      },
    });
  }

  private watchNotifications() {
    this.ipcService
      .send$("messages", {
        namespace: "notification",
        action: "listen",
      })
      .subscribe(
        (update: NotificationEvent) => {
          this.notificationEvents$.next(update);
        },
        (err) => {
          console.error("notification listen error", err);
        }
      );
  }
}
