import { Pipe, PipeTransform } from "@angular/core";

/**
 * Removes manufacturer name ("Plantronics", "Polycom", "Poly") if it appears as a prefix of a deivce name.
 */
export function removeManufacturerName(value: string): string {
  let result = value + "";
  if (result.toLowerCase().indexOf("plantronics") === 0) {
    result = result.slice(11);
  } else if (result.toLowerCase().indexOf("polycom inc. poly") === 0) {
    result = result.slice(17);
  } else if (result.toLowerCase().indexOf("polycom") === 0) {
    result = result.slice(7);
  } else if (result.toLowerCase().indexOf("poly, inc. poly") === 0) {
    result = result.slice(15);
  } else if (result.toLowerCase().indexOf("poly") === 0) {
    result = result.slice(4);
  }
  return result.trim();
}

@Pipe({
  name: "deviceName",
})
export class DeviceNamePipe implements PipeTransform {
  transform(value) {
    return removeManufacturerName(value);
  }
}
