import {
  Component,
  EventEmitter,
  Input,
  Output,
  forwardRef,
} from "@angular/core";
import {
  HydrationAndVisionUtils,
  WellnessDaysFrequency,
  OptionData,
} from "../../utils/hydrationAndVisionUtils";
import { cloneDeep as _cloneDeep } from "lodash";
import { NG_VALUE_ACCESSOR } from "@angular/forms";

export interface ReminderModalSettings {
  from: string;
  to: string;
  span: string;
  days: Array<string>;
}

@Component({
  selector: "oz-reminders-modal",
  templateUrl: "./reminders-modal.component.pug",
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => RemindersModalComponent),
      multi: true,
    },
  ],
})
export class RemindersModalComponent {
  @Input() option: OptionData;
  @Output() closeModal = new EventEmitter();
  @Output() confirmModal = new EventEmitter<ReminderModalSettings>();

  public show = false;
  private onChange: (_: any) => {};
  private onTouched: (_: any) => {};
  public selectedSettings: ReminderModalSettings;

  public dayOptions: Array<{
    value: string;
    text: string;
    shortText: string;
  }>;
  daysFrequency: number;
  public wellnessDaysFrequency = WellnessDaysFrequency;

  constructor(public hydrationAndVisionUtils: HydrationAndVisionUtils) {
    this.dayOptions = hydrationAndVisionUtils.getDayOptions();
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  writeValue(settings: ReminderModalSettings): void {
    this.selectedSettings = _cloneDeep(settings);
    if (this.selectedSettings) {
      this.daysFrequency = this.hydrationAndVisionUtils.convertDaysToFrequency(
        this.selectedSettings.days
      );

      // If timeTo is set to 12AM, set slider 'to' value on the far right 12AM for better UX
      if (this.selectedSettings.to === "0") this.selectedSettings.to = "24";

      // wait until settings are loaded before showing the modal
      setTimeout(() => {
        this.show = true;
      }, 0);
    }
  }

  doOnChange() {
    this.onChange(this.selectedSettings);
  }

  confirm() {
    this.show = false;
    this.confirmModal.emit(_cloneDeep(this.selectedSettings));
  }

  close() {
    this.show = false;
    this.closeModal.emit();
  }

  changeDaysFrequency(daysFreq: number) {
    this.daysFrequency = daysFreq;
    if (daysFreq === WellnessDaysFrequency.CUSTOM) {
      return;
    }

    this.selectedSettings.days = this.hydrationAndVisionUtils.convertFrequencyToDays(
      daysFreq
    );
  }

  toggleDay(day: string) {
    if (this.selectedSettings.days.includes(day)) {
      const index = this.selectedSettings.days.indexOf(day);
      if (index > -1) {
        this.selectedSettings.days.splice(index, 1);
      }

      return;
    }

    this.selectedSettings.days.push(day);
    this.selectedSettings.days.sort();
  }

  checkSelectedDays(day: string) {
    return this.selectedSettings.days.includes(day);
  }

  onTimeInputChanged(ev: string, action: "from" | "to") {
    let value = this.hydrationAndVisionUtils.convertTimeToValue(ev);
    // If timeTo is set to 12AM, set slider 'to' value on the far right 12AM for better UX
    if (ev === "00:00" && action === "to") {
      value = "24";
    }
    this.selectedSettings[action] = value;
  }

  onTimeRangeChanged(ev: any | any[], action: "from" | "to" | "span") {
    this.selectedSettings[action] = ev.toString();
  }
}
