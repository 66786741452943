import { Pipe, PipeTransform } from "@angular/core";
import { Device } from "@poly/hub-native";

export function getFirmwareVersion(device: Device) {
  if (device) {
    if (device.isVideoDevice && device.videoDeviceStatus?.versionSW) {
      return device.videoDeviceStatus.versionSW;
    } else {
      const version = device.firmwareVersion;

      if (version?.setId) {
        const { major, minor, build, revision } = version.setId;
        return `${major}.${minor}.${revision}.${build}`;
      } else if (version?.usb) {
        return `${version.usb}`;
      } else if (version?.headset) {
        return `${version.headset}`;
      } else if (version?.base) {
        return `${version.base}`;
      } else {
        return "Unknown";
      }
    }
  }
}

@Pipe({
  name: "firmwareVersion",
})
export class FirmwareVersionPipe implements PipeTransform {
  transform(device: Device): string {
    return getFirmwareVersion(device);
  }
}
