import { Pipe, PipeTransform } from "@angular/core";
import { Toast } from "./toasts.service";

/**
 * Sorts toasts by the following order (repsect to the toasts "type"):
 *  1. action
 *  2. action-postpone-dfu
 *  3. status
 */
export function sortToasts(toasts: Toast[]): Toast[] {
  return toasts.sort((a: Toast, b: Toast) => {
    if (a.type < b.type) {
      return -1;
    }
    if (a.type > b.type) {
      return 1;
    }
    return 0;
  });
}

@Pipe({
  name: "sortToasts",
  pure: false,
})
export class SortToastsPipe implements PipeTransform {
  transform(toasts: Toast[]): Toast[] {
    return sortToasts(toasts);
  }
}
