import { IConfigManager, Softphone, SoftwareSetting } from "../api/config-manager.api";
import { Observable, BehaviorSubject, combineLatest, EMPTY } from "rxjs";
import {Injectable} from "@angular/core";

@Injectable({
  providedIn: "root"
})
export class WhConfigManager extends IConfigManager {
    startElevatedSoftwareUpdate(environment: string, version: string): void {
        throw new Error("Method not implemented.");
    }

    constructor() {
        super();
    }

    getSoftphones(): Observable<Softphone[]> {
        return EMPTY;
    }

    getSoftphone(softphoneId: number): Observable<Softphone> {
      return EMPTY;
    }

    setSoftphone(softphone: Partial<Softphone>): void {
    }

    setSoftphones(softphones: Partial<Softphone>[]): void {
    }

    getSoftwareSettings(): Observable<SoftwareSetting[]> {
      return EMPTY;
    }

    getSoftwareSetting(softwareSettingId: string): Observable<SoftwareSetting> {
        return EMPTY;
    }

    setSoftwareSetting(softwareSetting: Partial<SoftwareSetting>): void {
    }


    setSoftwareSettings(softwareSettings: Partial<SoftwareSetting>[]): void {
    }
}
