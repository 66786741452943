import { Component, Input, OnInit } from "@angular/core";
import { Softphone, SoftwareSetting } from "@poly/hub-native";
import { DeviceManagerService } from "../../../services/device-manager.service";
import { combineLatest, Subscription } from "rxjs";
import { TranslateService } from "@ngx-translate/core";
import { cloneDeep as _cloneDeep } from "lodash";
import { AccordionItem } from "../../../shared/components/accordion/accordion.component";

export interface LensSettingSoftphoneMetadata {
  action: string;
  title: string;
  description: string;
}

export interface DropdownValue {
  value: string;
  text: string;
}

const SettingKeys = {
  defaultSoftPhone: "defaultSoftPhone",
  incomingCall: "incomingCall",
};

@Component({
  selector: "oz-lens-settings-softphone",
  templateUrl: "./lens-settings-softphone.component.pug",
})
export class LensSettingsSoftphoneComponent implements OnInit {
  public displayedSoftPhones: Softphone[] = [];
  public mediaPlayerActionOptions = [
    { value: "Pause", text: "SOFTPHONES.PAUSE" },
    { value: "DoNothing", text: "SOFTPHONES.NO_ACTION" },
  ];
  public setting: LensSettingSoftphoneMetadata;
  public softPhones: Softphone[] = [];
  public softPhoneOptions = [];
  public softwareSettings: { [key in keyof typeof SettingKeys] } = {
    incomingCall: "",
    defaultSoftPhone: "",
  };
  public tableExpanded = false;

  private softPhoneSub: Subscription;

  @Input() set accordionData(value: AccordionItem) {
    this.setting = value.data;
  }

  constructor(
    public deviceManager: DeviceManagerService,
    private translateService: TranslateService
  ) {
    this.translateService
      .get(this.mediaPlayerActionOptions.map((opt) => opt.text))
      .subscribe((trans) => {
        Object.keys(trans).forEach((key) => {
          const opt = this.mediaPlayerActionOptions.find((o) => o.text === key);

          if (opt) {
            opt.text = trans[key];
          }
        });
      });
  }

  ngOnInit() {
    this.loadSettings();
  }

  ngOnDestroy() {
    this.softPhoneSub.unsubscribe();
  }

  loadSettings() {
    this.softPhoneSub = combineLatest([
      this.deviceManager.getSoftphones(),
      this.deviceManager.getSoftwareSettings(),
    ]).subscribe(([softPhones, settings]) => {
      this.softPhones = this.getAlphabeticallySortedSoftPhones(softPhones);
      /**
       * FIXME: The if condition wrapping this is necessary
       * to work around a bug in hub native that doesn't properly emit
       * the new settings after a setSoftwareSetting is executed.
       *
       * @see LENS-1288 for updates on this.
       */
      if (settings && settings.length) {
        Object.keys(SettingKeys).forEach((key) => {
          this.softwareSettings[key] = String(
            settings.find((setting) => setting.id === key)?.value
          );
        });
      }

      this.getTargetSoftPhoneDropdownOptions();
      this.setDisplayedSoftPhones();
    });
  }

  getAlphabeticallySortedSoftPhones(softPhones: Softphone[]): Softphone[] {
    return [
      ...softPhones.sort((a, b) => {
        if (a.displayName > b.displayName) {
          return 1;
        }
        if (a.displayName < b.displayName) {
          return -1;
        }
        return 0;
      }),
    ];
  }

  getTargetSoftPhoneDropdownOptions() {
    this.softPhoneOptions = this.softPhones
      .filter((softPhone) => softPhone.canBeTarget)
      .map((softPhone) => {
        return {
          value: `${softPhone.id}`,
          text: softPhone.displayName,
        } as DropdownValue;
      });
  }

  onIncomingChanged(id: string): void {
    const partialSoftwareSetting: Partial<SoftwareSetting> = {
      id: id,
      value: this.softwareSettings[id],
    };
    this.deviceManager.setSoftwareSetting(partialSoftwareSetting);
  }

  onTargetChanged(softPhoneId: string) {
    const partialSoftwareSetting: Partial<SoftwareSetting> = {
      id: SettingKeys.defaultSoftPhone,
      value: +softPhoneId,
    };

    this.deviceManager.setSoftwareSetting(partialSoftwareSetting);
  }

  onSoftPhoneEnabledChanged(enabled: boolean, id: number): void {
    const partialSoftPhone: Partial<Softphone> = { id, enabled };
    this.deviceManager.setSoftphone(partialSoftPhone);
  }

  toggleFullTableView(): void {
    this.tableExpanded = !this.tableExpanded;
    this.setDisplayedSoftPhones();
  }

  setDisplayedSoftPhones() {
    if (this.tableExpanded) {
      this.displayedSoftPhones = _cloneDeep(this.softPhones);
    } else {
      this.displayedSoftPhones = _cloneDeep(this.softPhones.slice(0, 5));
    }
  }
}
