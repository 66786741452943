import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, ParamMap, Router } from "@angular/router";
import { Device } from "@poly/hub-native";
import { EMPTY } from "rxjs";
import { filter, switchMap } from "rxjs/operators";
import { DetailNavService } from "../../../services/detail-nav.service";
import {
  DeviceManagerService,
  DeviceSetupByConfig,
} from "../../../services/device-manager.service";
import {
  OzFileDialog,
  OzFileDialogOptions,
} from "../../../services/file-dialog.service";
import { OzFileSystem } from "../../../services/file-system.service";
import { Subscriptions } from "../../../utils/subscriptions";
import { Location } from "@angular/common";

const TRANSLATION_PREFIX = "DeviceSettings.IMPORT_EXPORT_CONFIG";

const FILTERS = [{ name: "Configuration File", extensions: ["xml"] }];

const IMPORT_CONFIG_DIALOG_OPTIONS: OzFileDialogOptions = {
  title: `${TRANSLATION_PREFIX}.IMPORT_DIALOG_TITLE`,
  titleTranslate: true,
  filters: FILTERS,
};

@Component({
  selector: "oz-ftc-device-start-setup",
  templateUrl: "./ftc-device-start-setup.component.pug",
})
export class FtcDeviceStartSetupComponent implements OnInit {
  public showDialog = false;
  public device: Device;
  public deviceName = "";

  private subs = new Subscriptions();

  constructor(
    private fileDialog: OzFileDialog,
    private fileSystem: OzFileSystem,
    private nav: DetailNavService,
    private router: Router,
    private route: ActivatedRoute,
    private deviceManager: DeviceManagerService,
    private location: Location
  ) {
    this.nav.configure({
      showNav: false,
      showDeviceHeading: false,
    });
  }

  ngOnInit() {
    this.subs.add(
      this.route.parent.paramMap
        .pipe(
          switchMap((paramMap: ParamMap) => {
            return this.deviceManager.getDevice(paramMap.get("id")); // "id" is Device#uniqueId
          }),
          filter((device) => !!device)
        )
        .subscribe((device: Device) => {
          if (!device.isConnected) {
            this.router.navigate(["../../../"], { relativeTo: this.route });
          }
          this.device = device;
        })
    );
  }

  public onBeginSetup(): void {
    this.router.navigate(["ftc-wizard", this.device.uniqueId], {
      state: this.location.getState(), // Pass on factory device password
    });
  }

  public openImportModal(): void {
    this.showDialog = true;
  }

  public onImport(): void {
    this.fileDialog
      // Open an open-file dialog
      .openFile(IMPORT_CONFIG_DIALOG_OPTIONS)
      .pipe(
        switchMap(({ paths, canceled }) => {
          return !canceled && paths?.length
            ? this.fileSystem.readFile(paths[0])
            : EMPTY;
        })
      )
      .subscribe((fileReadResult) => {
        // TODO: UI: Handle error in case of failure of reading file
        this.router.navigate(
          ["ftc-wizard-imported-config", this.device?.uniqueId],
          {
            state: {
              deviceName: this.deviceName?.trim() || this.device.displayName,
              config: fileReadResult.content,
            } as DeviceSetupByConfig,
          }
        );
      });
  }

  public onCancel(): void {
    this.showDialog = false;
    this.deviceName = "";
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }
}
