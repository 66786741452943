import {ILoggingService} from "./logging.service";
import {
  Device,
  DFUOptions,
  DeviceUpdateProgress,
  DeviceSetting,
  Softphone,
  SoftwareSetting,
  SaveDeviceLogsStatus,
  WiFiStatusResponse,
  ScannedNetworksResponse,
  KnownNetworksResponse,
  WiFiConnectParams,
  NetworkProvisioningParams,
  NetworkProvisioningInfo,
  DFUError,
  CallEvent,
  DeviceNameResponse,
  BluetoothInfo,
  BluetoothParams,
  SetBluetoothResponse,
  GetGeneralSettingsResponse,
  SetGeneralSettingsResponse,
  GeneralSettings,
  AdminLoginRequest,
  AdminLoginResponse,
  AdminPasswordChangeRequest,
  AdminPasswordChangeResponse,
  AdminPasswordEnabledResponse,
  SimplePasswordResponse,
  SetSimplePasswordRequest,
  SetSimplePasswordResponse,
  CreateCertificateRequest,
  CreateCertificateResponse,
  GetCertificateInfoResponse,
  GetCertificateFileResponse,
  InstallCertificateRequest,
  InstallCertificateResponse,
  GetInstalledCertificatesResponse,
  DeleteCertificateRequest,
  DeleteCertificateResponse,
  CertificateDetailsRequest,
  CertificateDetailsResponse,
  ImportConfigurationRequest,
  ImportConfigurationResponse,
  ExportConfigurationResponse,
  SetServerCAValidationRequest,
  SetServerCAValidationResponse,
  StartAudioTestResponse,
  StopAudioTestRequest,
  StopAudioTestResponse,
  NeedOOBResponse,
  BluetoothPairingRequest,
  SetWifiClientCertPasswordRequest,
  SetWifiClientCertPasswordResponse,
  GetCrashFilesRequest,
  GetCrashFilesResponse,
  IsAppInstalledResponse,
  AgentAudioControl,
  BrickedDevice,
  BrickedDeviceInfo, IHubNative,
} from "@poly/hub-native";
import {
  Observable,
  of,
  BehaviorSubject,
  combineLatest,
  from,
  Subject,
} from "rxjs";
import { map, tap } from "rxjs/operators";
import { StorageService } from "./storage.service";
import { Injectable, NgZone } from "@angular/core";
import { NativeService } from "./hub-native-service/hub-native.service";
import { runInZone } from "../utils/rxjs.utils";
import {
  BAD_DEVICE_IDS,
  DEVICE_TYPE,
  HISTORICAL_DEVICES_KEY,
  NEED_SETUP_DEVICES,
} from "../utils/constants";
import * as _ from "lodash";
import {
  mergeObjectsWithoutOverwrite,
  addPeripheralSuffix,
  removePeripheralSuffix,
} from "../utils/utils";
import { removeManufacturerName } from "../shared/pipes/device-name.pipe";

export interface DeviceSetupByConfig {
  deviceName?: string;
  config: string;
}

export interface DeviceSetupParams {
  deviceName?: string;
  country?: string;
  oldPassword?: string;
  newPassword?: string;
  provisioningParams?: NetworkProvisioningParams;
}

export interface OzDevice extends Device {
  /**
   * `true` if a device has been reconnected.
   *
   * (Device was historical, and then was connected again.)
   */
  reconnected: boolean;

  /**
   * The best *display name* of a device.
   *
   * (Reduces a *display name* and a *device name* to a single property)
   */
  name: string;

  /**
   * The exact moment a device was last connected.
   */
  lastConnected?: number;

  /**
   * Parent device, if any.
   */
  parent?: OzDevice;

  /**
   * `true` if either this device, or its parent, could be set as a primary device.
   */
  canBePrimary: boolean;

  /**
   * `true` if either this device, or its parent, is primary device.
   */
  isPrimary: boolean;

  /**
   * `true` if setup is needed (FTC Wizard) - only for Studio USB, R30, R30 NR
   */
  needSetup?: boolean;
}

export type HistoricalDevices = { [uniqueId: string]: OzDevice };

export interface DeviceManagerEvent {
  type: "dfu-completed";
  deviceId: string;
}

@Injectable({
  providedIn: "root",
})
export class DeviceManagerService {
  private deviceApi: IHubNative;
  private devices: BehaviorSubject<OzDevice[]> = new BehaviorSubject([]);

  events = new Subject<DeviceManagerEvent>();

  private needOOBmap = new Map<string, boolean>();
  needOOBmap$: BehaviorSubject<Map<string, boolean>> = new BehaviorSubject<
    Map<string, boolean>
  >(null);

  constructor(
    private nativeService: NativeService,
    private storage: StorageService,
    private zone: NgZone,
    private logger: ILoggingService
  ) {
    // Init needOOBmap observable - first emit
    this.setNeedOOBmap("", false);

    //TODO/FYI: imho, this API should be injected directly, with no talk about a NativeService.
    // Further, the API name of "IHubNative" is also unfortunate - it's just a DeviceMgmt API (and
    // any use of native mechanisms is simply an implementation detail.)
    this.deviceApi = nativeService.getApi();
    this.deviceApi.getDeviceManager().getDevices()
      .pipe(
        runInZone(this.zone),
        tap((devices: Device[]) => {
          this.logger.debug("Device manager devices", devices);
        }),
        map((devices: Device[]) => this.removeInvalidDevices(devices)),
        map((devices: Device[]) => this.fixDeviceNames(devices)),
        map((devices: Device[]) =>
          devices.map((device) => this.toOzDevice(device))
        ),
        map((devices: OzDevice[]) => this.checkNeedSetup(devices)),
        map((devices: OzDevice[]) => this.handlePeripheralDevices(devices)),
        map((devices: OzDevice[]) => this.mergeWithStore(devices))
      )
      .subscribe((devices: OzDevice[]) => {
        this.devices.next(devices);
      });
  }

  private removeInvalidDevices(devices: Device[]): Device[] {
    return devices.filter(
      (device) =>
        !!device &&
        !!device.displayName?.length &&
        !!device.uniqueId?.length &&
        !BAD_DEVICE_IDS.includes(device.uniqueId)
    );
  }

  private fixDeviceNames(devices: Device[]) {
    devices.forEach((device) => {
      if (device.displayName.startsWith("Poly ")) {
        device.displayName = device.displayName.replace("Poly ", "");
      }
    });
    return devices;
  }

  private mergeWithStore(nativeDevices: OzDevice[]): OzDevice[] {
    const store = this.storage.getItem(HISTORICAL_DEVICES_KEY) ?? {};
    // List of devices from store
    const storeDevices: OzDevice[] = Object.keys(store).map(
      (key) => store[key]
    );

    // Map each Device to OzDevice
    const storeOzDevices = storeDevices.map((storeDevice) => {
      if (!storeDevice.name) {
        return this.toOzDevice(storeDevice);
      } else {
        return storeDevice;
      }
    });
    // Assume store ozDevices are disconnected
    const idMap = new Map<string, string>();
    storeOzDevices.forEach((storeOzDevice) => {
      storeOzDevice.isConnected = false;
      idMap.set(storeOzDevice.id, storeOzDevice.uniqueId);
    });
    const newStore: HistoricalDevices = (storeOzDevices.reduce(
      (store, ozDevice) => {
        store[ozDevice.uniqueId] = ozDevice;
        return store;
      },
      {}
    ) as unknown) as HistoricalDevices;
    this.logger.debug("stored devices:" +
      `${Object.values(newStore).reduce((s, d) => s + ` ${d.id}:${d.parentDeviceId}(${d.parent?.id})`, '')}`);
    nativeDevices.forEach((nativeDevice) => {
      // Check if the device has just been reconnected
      if (
        newStore[nativeDevice.uniqueId] &&
        !newStore[nativeDevice.uniqueId].isConnected
      ) {
        nativeDevice.lastConnected = Date.now();
      }
      newStore[nativeDevice.uniqueId] = nativeDevice;
    });
    // Change transient device IDs to (permanent) unique IDs for disconnected devices
    // so that parent-child relations survive app restarts.
    Object.values(newStore).forEach((dev) => {
      if (!dev.isConnected) {
        const mappedId = idMap.get(dev.id);
        if (undefined !== mappedId) {
          dev.id = mappedId;
        }
        if (undefined !== dev.parentDeviceId) {
          const newId = idMap.get(dev.parentDeviceId);
          if (undefined !== newId) {
            if (newId !== dev.parentDeviceId) {
              this.logger.debug("remapping parent ID " +
                `${dev.uniqueId}: ${dev.parentDeviceId} -> ${newStore[newId].id} ${newId}`);
              dev.parentDeviceId = newStore[newId].id;
              if (dev.parent) {
                dev.parent.id = dev.parentDeviceId;
              }
            }
          }
          else {
            this.logger.debug(`deleting parent info from ${dev.uniqueId}`);
            const o: any = dev;
            delete o.parentDeviceId;
            delete o.parent;
          }
        }
      }
    });
    // Save a new device list into the localstorage
    this.storage.setItem(HISTORICAL_DEVICES_KEY, newStore);
    // Return list of devices
    return Object.values(newStore);
  }

  /**
   * Saves changes of device details into Historical devices in localStorage
   *
   */
  saveDevices() {
    this.storage.setItem(HISTORICAL_DEVICES_KEY, this.devices.getValue());
  }

  /**
   * `true` if a device needs to be setup before usage. Typical for Studio USB and Asgard.
   */
  getNeedSetup(device: Device): Observable<boolean> {
    // Need setup only for Studio USB, Asgard (R30) and Asgard No radio (R30 NR)
    if (NEED_SETUP_DEVICES.includes(device.pid)) {
      return from(this.needOOB(device.id)).pipe(
        map(({ status, result }) => status === "OK" && result)
      );
    } else {
      return of(false);
    }
  }

  /**
   * Check every device if needs to be setup before usage. Typical for Studio USB and Asgard.
   * Check if setup is needed with needOOB promise (no await) and if so trigger needOOBmap$ with setNeedOOBmap
   */
  private checkNeedSetup(devices: OzDevice[]): OzDevice[] {
    devices.forEach((device) => {
      if (device.isConnected && NEED_SETUP_DEVICES.includes(device.pid)) {
        this.needOOB(device.id).then((needSetup) => {
          this.setNeedOOBmap(
            device.id,
            needSetup.status === "OK" && needSetup.result
          );
        });
      }
    });
    return devices;
  }

  canPerformDFU(deviceId: string): Observable<DFUError[]> {
    return from(
      this.deviceApi.getDFUManager()
        .canPerformDFU(removePeripheralSuffix(deviceId))
    );
  }

  getDevices(): Observable<OzDevice[]> {
    return this.devices.asObservable();
  }

  getConnectedDevices(): Observable<OzDevice[]> {
    return this.getDevices().pipe(
      map((devices: OzDevice[]) =>
        devices.filter(({ isConnected }) => isConnected)
      )
    );
  }

  getDevice(uniqueId: string): Observable<OzDevice> {
    return this.devices.pipe(
      map((devices) => {
        return devices.find((device) => device.uniqueId === uniqueId);
      })
    );
  }

  private getReconnectedStatus(device: Device): boolean {
    const devices = this.devices.getValue();
    return (
      device.isConnected &&
      !devices.find(({ uniqueId }) => uniqueId === device.uniqueId)?.isConnected
    );
  }

  private toOzDevice(device: Device): OzDevice {
    const dm = this;
    return mergeObjectsWithoutOverwrite(
      {
        // Override Device#uniqueId
        uniqueId: device.uniqueId.toLowerCase(),
        get name(): string {
          return (
            device.deviceName || removeManufacturerName(device.displayName)
          );
        },
        reconnected: this.getReconnectedStatus(device),
        get parent(): OzDevice {
          const devices = dm.devices.getValue();
          return devices.find(
            ({ id, pid }) => id === this.parentDeviceId && pid !== this.pid
          );
        },
        // Override Device#canBePrimary
        get canBePrimary(): boolean {
          return device?.canBePrimary || !!this.parent?.canBePrimary;
        },
        // Override Device#isPrimary
        get isPrimary(): boolean {
          return device?.isPrimary || !!this.parent?.isPrimary;
        },
      } as OzDevice,
      device
    );
  }

  private handlePeripheralDevices(devices: OzDevice[]): OzDevice[] {
    let peripheralParentDevices: OzDevice[] = [];
    devices.forEach((device) => {
      // Add peripheral's host as parent device, e.g. MDA400
      const customDeviceId = addPeripheralSuffix(device.id);
      if (
        device.peripheralInfo?.isPeripheral &&
        device.peripheralInfo?.hostDevicePID !== -1 &&
        device.peripheralInfo?.hostDeviceVersion !== -1
      ) {
        const peripheralParentDevice: OzDevice = {
          ...device,
          id: customDeviceId,
          uniqueId: customDeviceId,
          pid: device.peripheralInfo.hostDevicePID,
          firmwareVersion: {
            ...device.firmwareVersion,
            usb: device.peripheralInfo.hostDeviceVersion.toString(),
            tuning: "",
          },
          serialNumber: { ...device.serialNumber, base: "" },
          isConnected: true,
          // TODO: UI: Name should not be hard-coded, replace this when peripheral device name is supported from native
          displayName: device.peripheralInfo.hostDeviceName,
          name: device.peripheralInfo.hostDeviceName,
        };
        delete peripheralParentDevice["headsetConnectedState"];
        delete peripheralParentDevice["peripheralInfo"];
        peripheralParentDevices.push(peripheralParentDevice);

        // Set parentId for child device, e.g. DA90
        device.parentDeviceId = customDeviceId;
      }
    });
    return [...devices, ...peripheralParentDevices];
  }

  public getCallEvents(): Observable<CallEvent> {
    return this.deviceApi.getEventsManager().getCallEvents();
  }

  public removeDevice(uniqueId: string): boolean {
    const devices = this.devices.getValue();
    let removeIndex = -1;
    for (let i = 0; i < devices.length; i++) {
      if (devices[i].uniqueId === uniqueId) {
        removeIndex = i;
        break;
      }
    }
    if (removeIndex > -1) {
      const device = devices[removeIndex];
      if (!device.isConnected) {
        // Remove device
        devices.splice(removeIndex, 1);
        // Save list of devices into localstorage
        this.storage.setItem(HISTORICAL_DEVICES_KEY, devices);
        // Dispatch next list of devices
        this.devices.next(devices);
        // send a message to the real DeviceManager to remove the device
        this.deviceApi.getDeviceManager().removeDevice(device.uniqueId);
        // Indicate success
        return true;
      }
    }
    // Indicate failure
    return false;
  }

  public startDfu(options: DFUOptions): Observable<DeviceUpdateProgress> {
    const dfuOptions = {
      ...options,
      deviceId: removePeripheralSuffix(options.deviceId),
    };
    return this.deviceApi.getDFUManager()
      .startDFU(dfuOptions)
      .pipe(
        tap(({ deviceId, status }) => {
          if (status === "Completed") {
            this.events.next({ type: "dfu-completed", deviceId });
          }
        })
      );
  }

  public cancelDfu() {
    this.deviceApi.getDFUManager().cancelDFU();
  }

  public retryDfu() {
    this.deviceApi.getDFUManager().retryDFU();
  }

  public continueDfu() {
    this.retryDfu();
  }

  public getDeviceSettings(deviceId: string) {
    return this.deviceApi.getDeviceSettingsManager()
      .getDeviceSettings(deviceId)
      .pipe(runInZone(this.zone));
  }

  public setDeviceSetting(deviceId: string, setting: Partial<DeviceSetting>) {
    this.deviceApi.getDeviceSettingsManager()
      .setDeviceSetting(deviceId, setting);
  }

  public setDeviceSettings(
    deviceId: string,
    settings: Partial<DeviceSetting>[]
  ) {
    this.deviceApi.getDeviceSettingsManager()
      .setDeviceSettings(deviceId, settings);
  }

  public setPrimaryDevice(deviceId: string) {
    this.deviceApi.getDeviceManager().setPrimaryDevice(deviceId);
  }

  public isUpdateNeeded(
    deviceId: string,
    rules: string,
    policy?: boolean
  ): Promise<boolean> {
    if (rules && this.getDeviceById(deviceId)?.isConnected) {
      return this.deviceApi.getDFUManager().isUpdateNeeded(deviceId, rules); //TODO: pass policy arg here
    } else {
      return Promise.resolve(false);
    }
  }

  public getDeviceById(deviceId: string): OzDevice {
    const _devices = this.devices.getValue();
    return _devices.find((device) => device.id === deviceId);
  }

  public getSoftphones(): Observable<Softphone[]> {
    return this.deviceApi.getConfigManager().getSoftphones();
  }

  public setSoftphone(softphone: Partial<Softphone>) {
    this.deviceApi.getConfigManager().setSoftphone(softphone);
  }

  public getSoftwareSettings(): Observable<Array<SoftwareSetting>> {
    return this.deviceApi.getConfigManager().getSoftwareSettings();
  }

  public getSoftwareSetting(
    softwareSettingId: string
  ): Observable<SoftwareSetting> {
    return this.deviceApi.getConfigManager()
      .getSoftwareSetting(softwareSettingId);
  }

  public setSoftwareSetting(softwareSetting: Partial<SoftwareSetting>) {
    return this.deviceApi.getConfigManager()
      .setSoftwareSetting(softwareSetting);
  }

  public setSoftwareSettings(softwareSettings: Partial<SoftwareSetting>[]) {
    return this.deviceApi.getConfigManager()
      .setSoftwareSettings(softwareSettings);
  }

  public saveLogs(
    deviceId: string,
    filepath: string|FileSystemWritableFileStream,
  ): Promise<SaveDeviceLogsStatus> {
    return this.deviceApi.getDeviceManager()
      .saveDeviceLogs(deviceId, filepath);
  }

  public getDeviceByUniqueId(uniqueId: string): OzDevice {
    const devices = this.devices.getValue();
    return devices.find((device) => device.uniqueId === uniqueId);
  }

  public getDeviceIdByUniqueId(uniqueId: string): string {
    const found = this.getDeviceByUniqueId(uniqueId);
    return found ? found.id : "";
  }

  public getWiFiStatus(deviceId: string): Observable<WiFiStatusResponse> {
    return this.deviceApi.getDeviceManager()
      .getWiFiStatus(deviceId);
  }

  public getScannedNetworks(
    deviceId: string
  ): Promise<ScannedNetworksResponse> {
    return this.deviceApi.getDeviceManager()
      .getScannedNetworks(deviceId);
  }

  public getKnownNetworks(deviceId: string): Promise<KnownNetworksResponse> {
    return this.deviceApi.getDeviceManager()
      .getKnownNetworks(deviceId);
  }

  public setWiFiParameters(request: WiFiConnectParams): void {
    this.deviceApi.getDeviceManager()
      .setWiFiParameters(request);
  }

  public getNetworkProvisioning(
    deviceId: string
  ): Promise<NetworkProvisioningInfo> {
    return this.deviceApi.getDeviceManager()
      .getNetworkProvisioning(deviceId);
  }

  public setNetworkProvisioning(
    request: NetworkProvisioningParams
  ): Promise<NetworkProvisioningInfo> {
    return this.deviceApi.getDeviceManager()
      .setNetworkProvisioning(request);
  }

  public setDeviceName(
    deviceId: string,
    name: string
  ): Promise<DeviceNameResponse> {
    return this.deviceApi.getDeviceManager()
      .setDeviceName(deviceId, name);
  }

  public setBluetoothSettings(
    request: BluetoothParams
  ): Promise<SetBluetoothResponse> {
    return this.deviceApi.getDeviceManager()
      .setBluetoothSettings(request);
  }

  public setBluetoothPairing(
    request: BluetoothPairingRequest
  ): Promise<SetBluetoothResponse> {
    return this.deviceApi.getDeviceManager()
      .setBluetoothPairing(request);
  }

  public getBluetoothStatus(deviceId: string): Promise<BluetoothInfo> {
    return this.deviceApi.getDeviceManager()
      .getBluetoothStatus(deviceId);
  }

  public setGeneralSettings(
    request: GeneralSettings
  ): Promise<SetGeneralSettingsResponse> {
    return this.deviceApi.getDeviceManager()
      .setGeneralSettings(request)
      .then((response) => {
        // TODO: UI: This should be moved into native layer
        if (request.device_name && response.status === "OK") {
          const devices = this.devices.getValue();
          const found = devices.find(
            (device) => device.id === request.deviceId
          );
          if (found) {
            found.deviceName = request.device_name;
            this.storage.setItem(HISTORICAL_DEVICES_KEY, devices);
            this.devices.next(devices);
          }
        }
        return response;
      });
  }

  public getGeneralSettings(
    deviceId: string
  ): Promise<GetGeneralSettingsResponse> {
    return this.deviceApi.getDeviceManager()
      .getGeneralSettings(deviceId);
  }

  public adminLogin(request: AdminLoginRequest): Promise<AdminLoginResponse> {
    return this.deviceApi.getDeviceManager()
      .adminLogin(request);
  }

  public adminPasswordChange(
    request: AdminPasswordChangeRequest
  ): Promise<AdminPasswordChangeResponse> {
    return this.deviceApi.getDeviceManager()
      .adminPasswordChange(request);
  }

  public adminPasswordEnabled(
    deviceId: string
  ): Promise<AdminPasswordEnabledResponse> {
    return this.deviceApi.getDeviceManager()
      .adminPasswordEnabled(deviceId);
  }

  public simplePassword(deviceId: string): Promise<SimplePasswordResponse> {
    return this.deviceApi.getDeviceManager()
      .simplePassword(deviceId);
  }

  public setSimplePassword(
    request: SetSimplePasswordRequest
  ): Promise<SetSimplePasswordResponse> {
    return this.deviceApi.getDeviceManager()
      .setSimplePassword(request);
  }

  public createCertificate(
    request: CreateCertificateRequest
  ): Promise<CreateCertificateResponse> {
    return this.deviceApi.getDeviceManager()
      .createCertificate(request);
  }

  public getCertificateInfo(
    deviceId: string
  ): Promise<GetCertificateInfoResponse> {
    return this.deviceApi.getDeviceManager()
      .getCertificateInfo(deviceId);
  }

  public getCertificateFile(
    deviceId: string
  ): Promise<GetCertificateFileResponse> {
    return this.deviceApi.getDeviceManager()
      .getCertificateFile(deviceId);
  }

  public installCertificate(
    request: InstallCertificateRequest
  ): Promise<InstallCertificateResponse> {
    return this.deviceApi.getDeviceManager()
      .installCertificate(request);
  }

  public getInstalledCertificates(
    deviceId: string
  ): Promise<GetInstalledCertificatesResponse> {
    return this.deviceApi.getDeviceManager()
      .getInstalledCertificates(deviceId);
  }

  public deleteCertificate(
    request: DeleteCertificateRequest
  ): Promise<DeleteCertificateResponse> {
    return this.deviceApi.getDeviceManager()
      .deleteCertificate(request);
  }

  public certificateDetails(
    request: CertificateDetailsRequest
  ): Promise<CertificateDetailsResponse> {
    return this.deviceApi.getDeviceManager()
      .certificateDetails(request);
  }

  public importConfiguration(
    request: ImportConfigurationRequest
  ): Promise<ImportConfigurationResponse> {
    return this.deviceApi.getDeviceManager()
      .importConfiguration(request);
  }

  public exportConfiguration(
    deviceId: string
  ): Promise<ExportConfigurationResponse> {
    return this.deviceApi.getDeviceManager()
      .exportConfiguration(deviceId);
  }

  public setServerCAValidation(
    request: SetServerCAValidationRequest
  ): Promise<SetServerCAValidationResponse> {
    return this.deviceApi.getDeviceManager()
      .setServerCAValidation(request);
  }

  public startAudioTest(deviceId: string): Promise<StartAudioTestResponse> {
    return this.deviceApi.getDeviceManager()
      .startAudioTest(deviceId);
  }

  public stopAudioTest(
    request: StopAudioTestRequest
  ): Promise<StopAudioTestResponse> {
    return this.deviceApi.getDeviceManager()
      .stopAudioTest(request);
  }

  private needOOB(deviceId: string): Promise<NeedOOBResponse> {
    return this.deviceApi.getDeviceManager().needOOB(deviceId);
  }

  public getNeedOOBmap(deviceId: string): boolean {
    return this.needOOBmap.get(deviceId);
  }

  public setNeedOOBmap(deviceId: string, needSetup: boolean) {
    this.needOOBmap.set(deviceId, needSetup);
    this.needOOBmap$.next(this.needOOBmap);
  }

  public setWifiClientCertPassword(
    request: SetWifiClientCertPasswordRequest
  ): Promise<SetWifiClientCertPasswordResponse> {
    return this.deviceApi.getDeviceManager()
      .setWifiClientCertPassword(request);
  }

  public getCrashFiles(
    request: GetCrashFilesRequest
  ): Promise<GetCrashFilesResponse> {
    return this.deviceApi.getDeviceManager()
      .getCrashFiles(request);
  }

  public isAppInstalled(appName: string): Promise<IsAppInstalledResponse> {
    return this.deviceApi.getDeviceManager()
      .isAppInstalled(appName);
  }

  setupDeviceByConfig(
    deviceId: string,
    { deviceName, config }: DeviceSetupByConfig
  ): void {
    this.importConfiguration({ deviceId, config }).then((response) => {
      if (response.status === "OK" && response.success && deviceName) {
        this.setGeneralSettings({
          deviceId,
          device_name: deviceName,
        });
      }
    });
  }

  setupDevice(deviceId: string, options: DeviceSetupParams): void {
    combineLatest([
      this.setGeneralSettings({
        deviceId,
        country_region: options.country,
        device_name: options.deviceName,
      }),
      options.provisioningParams
        ? this.setNetworkProvisioning({
            deviceId,
            provisioningMode: options.provisioningParams.provisioningMode,
            serverType: options.provisioningParams.serverType ?? "",
            serverAddress: options.provisioningParams.serverAddress,
            username: options.provisioningParams.username,
            password: options.provisioningParams.password,
          } as NetworkProvisioningParams)
        : of({ status: "OK" }),
    ]).subscribe();
  }

  setAacSetting(aac: AgentAudioControl): void {
    return this.deviceApi.getDeviceManager()
      .setAacSetting(aac);
  }

  getBrickedDevice(): Observable<BrickedDevice> {
    return this.deviceApi.getDeviceManager()
      .getBrickedDevice();
  }

  brickedDeviceStatus(): Promise<BrickedDeviceInfo> {
    return this.deviceApi.getDFUManager()
      .brickedDeviceStatus();
  }

  requestDevice() {
    this.deviceApi.getDeviceManager().requestDevice();
  }

  // If Charge Case is connected over BT700, device list returns Charge Case data as part of Earbuds device
  // This will transform Earbuds device into Charge Case device
  transformDeviceIntoChargeCase(device: OzDevice): OzDevice {
    if (!device.connectedDevices?.includes("chargeCase")) return null;

    let chargeCase = _.cloneDeep(device);
    chargeCase.pid = device.peerChargeCase?.UsbPid;
    chargeCase.uniqueId = device.peerChargeCase?.GenesGuid.toUpperCase();
    chargeCase.deviceType = DEVICE_TYPE.CHARGING_CASE;

    // TODO: Check how to resolve fw version string - native returns only partial info i.e. 409.4011
    chargeCase.firmwareVersion.setId = null;
    chargeCase.firmwareVersion.usb = device.peerChargeCase?.FirmwareVersion;
    return chargeCase;
  }
}
