import { Component, Input, OnChanges } from "@angular/core";
import { OzDevice } from "../../../services/device-manager.service";
import { ProductCatalog } from "../../../services/product.catalog.service";

@Component({
  selector: "oz-connection-indicator",
  templateUrl: "./connection-indicator.component.pug",
})
export class ConnectionIndicatorComponent implements OnChanges {
  deviceConnected = false;

  @Input() device: OzDevice;
  @Input() earbud: string;

  constructor(public productCatalog: ProductCatalog) {}

  ngOnChanges() {
    this.deviceConnected =
      !!this.earbud && this.device?.isConnected
        ? this.getEarbudConnectedStatus()
        : this.device?.isConnected;
  }

  getEarbudConnectedStatus() {
    const earbudConnected =
      (this.earbud === "left" &&
        this.device?.leftEarbudStatus?.connected &&
        (this.device?.leftEarbudStatus?.primary ||
          this.device?.leftEarbudStatus?.peerConnected)) ||
      (this.earbud === "right" &&
        this.device?.rightEarbudStatus?.connected &&
        (this.device?.rightEarbudStatus?.primary ||
          this.device?.rightEarbudStatus?.peerConnected));

    return earbudConnected;
  }
}
