import { Injectable } from "@angular/core";

type DetailNavOpts = {
  showNav?: boolean;
  showDeviceHeading?: boolean;
  deviceHeading?: string;
};

const defaults: DetailNavOpts = {
  showNav: true,
  showDeviceHeading: true,
  deviceHeading: null,
};

@Injectable({
  providedIn: "root",
})
export class DetailNavService {
  constructor() {}

  configure(opts: DetailNavOpts) {
    setTimeout((_) => Object.assign(this, defaults, opts));
  }

  readonly showNav: boolean;
  readonly showDeviceHeading: boolean;
  readonly deviceHeading: string;
}
