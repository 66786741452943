import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import deepCopy from './deep-copy';

/**
 * RxJs operator.
 *
 * Filters incomming array and picks only one object with the provided id value.
 *
 * @param idName Name of the identifier field in an object, e.g. 'id'.
 * @param idValue Value of the ID to look for.
 */
export function pickElement<T extends {[key: string]: any | undefined}>(idName: string, idValue: any) {
  return (observable: Observable<T[]>) =>
    new Observable<T>((subscriber) => {
      const subscription = observable.subscribe({
        next(value) {
          const t = value.find(t => t[idName] === idValue);
          if (undefined !== t) {
            subscriber.next(t)
          }
        },
        error(err) { subscriber.error(err); },
        complete() { subscriber.complete(); }
      });
      return () => { subscription.unsubscribe(); };
    });
}

export function pick<T>(predicate: (value: T) => boolean) {
  return (observable: Observable<T[]>) =>
    new Observable<T>((subscriber) => {
      const subscription = observable.subscribe({
        next(value) {
        const t = value.find(predicate);
        if (undefined !== t) {
          subscriber.next(t)
        }
        },
        error(err) { subscriber.error(err); },
        complete() { subscriber.complete(); }
      });
      return () => { subscription.unsubscribe(); };
    });
}
/**
 * RxJs operator.
 *
 * Performs a deep copy of incomming data.
 */
export function DEEP_COPY<T>() {

    return (t: T[]) => deepCopy(t)
}

/**
 * Converts a number into Hex, formatted like "0xAB45".
 *
 * @param x Number to convert.
 */
export function paddedHex(x: number | string) {
    if (x) {
        if (typeof x === 'string') {
            x = parseInt(x, 16)
        }

        return `0x${x.toString(16).toUpperCase().padStart(4, '0')}`
    }
    return "";
}

/**
 * Returns non-padded hex. For input "0x0024" it returns "0x24".
 */
export function unpaddedHex(x: string | number) {
    if (x) {

        if (typeof x === 'string') {
            x = parseInt(x, 16)
        }

        return `0x${x.toString(16).toUpperCase()}`
    }
    return "";
}

/**
 * Checks if two hex are equal.
 *
 * It applies smart check, for example, all these pairs are equal:
 *  ("0xa12", "A12"),
 *  ("0xa12", "0xA12"),
 *  ("A", 10).
 *
 * @param firstHex First hex.
 * @param secondHex Second hex.
 */
export function hexEqual(firstHex: string | number, secondHex: string | number) {

    // If either of these hex are unknown, then they are not equal
    if (nullOrUndefined(firstHex) || nullOrUndefined(secondHex)) {
        return false
    }

    try {

        // If firstHex is string, convert it to number
        if (typeof firstHex === 'string') {
            firstHex = parseInt(firstHex, 16)
        }

        // If secondHex is string, convert it to number
        if (typeof secondHex === 'string') {
            secondHex = parseInt(secondHex, 16)
        }

        // Compare two numbers
        return firstHex === secondHex

    } catch (ex) {

        // In case of error, hex are not equal
        return false
    }
}

/**
 * Checks if the value of the provided object exist (e.g. it is neither null nor undefined).
 */
export function exist(x: any) {
    return x !== undefined && x !== null
}

/**
 * Checks if the parameter is null or undefined.
 */
export function nullOrUndefined(x: any) {
    return x === null || x === undefined
}

/**
 * Use this method as a workaround to using a string as an index to an object
 * Example:
 *   let someKey:string = "someprop"
 *   let someObject: object = {
 *     someprop: string,
 *   }
 *   if(someObject[someKey]) // betterer error -- No index signature with a parameter of type string
 *
 *   Instead use this method like this:
 *   if (hasKey(someObject,someKey) && someObject[someKey])
 *
 * @param obj object to look into
 * @param key typically string that you want to use as a key
 *
 * @returns whether the key to be used for indexing
 */

export function hasKey<O extends object>(obj: O, key: PropertyKey): key is keyof O {
  return key in obj
}
