import { Injectable } from "@angular/core";
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  UrlTree,
  Router,
} from "@angular/router";
import { Observable, combineLatest } from "rxjs";
import { EulaService } from "../services/eula.service";
import { AdminConfig } from "../services/admin-config.service";
import { map } from "rxjs/operators";
import { AuthService } from "../services/auth.service";

/**
 *
 */
@Injectable({
  providedIn: "root",
})
export class EulaGuard implements CanActivate {
  private isLoginless: boolean = false;
  private acceptEulaByUser: boolean = false;

  constructor(
    private router: Router,
    private eulaService: EulaService,
    private adminConfig: AdminConfig,
    private authService: AuthService
  ) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    return combineLatest([
      this.eulaService.agreed$,
      this.authService.isLoginless$,
      this.eulaService.acceptEula$,
    ]).pipe(
      map(([agreed, isLoginless, acceptEula]) => {
        this.acceptEulaByUser = acceptEula && acceptEula === "1";

        if (!agreed || this.acceptEulaByUser) {
          return true;
        }
        this.isLoginless = isLoginless;

        if (
          this.adminConfig.mode !== "cloud" ||
          (this.isLoginless && !this.acceptEulaByUser)
        ) {
          this.router.navigate(["/home"]);
        } else {
          this.router.navigate(["/ftu"]);
        }

        return false;
      })
    );
  }
}
