import { Injectable } from "@angular/core";
import { mergeObjectsWithoutOverwrite } from "../../../utils/utils";
import { BehaviorSubject, Observable } from "rxjs";
import { v4 } from "uuid";
import { NavigationEnd, Router } from "@angular/router";
import {
  DfuModalEvent,
  DfuModalService,
} from "../../../services/dfu-modal.service";
import { map } from "rxjs/operators";

export interface ToastAction {
  text: string; // action caption
  type: "primary" | "secondary"; // style of an action
  dismissToast?: boolean; // if true, action dismisses the notification (defaults to true)
  action(): void; // action handler
}

const TOAST_ACTION_DEFAULTS: Partial<ToastAction> = {
  dismissToast: true,
};

export interface Toast {
  id?: string; // auto generated
  type: "status" | "status-persistent" | "action" | "action-postpone-dfu";
  status?: "success" | "error"; // for for "status" type only
  text?: string; // content of a toast
  textParams?: object; // Additional parameters for transaltion
  title?: string; // used for "action" type only
  closeable?: boolean; // if `true`, notification can be dismissed manually (never used for "action-postpone-dfu")
  closeAction?: string; // type of specific action which should happen after closing the toast
  dismissAfter?: number; // number of seconds to automatically dismiss a notification (by default does not dismiss) - It will never dismiss if set to 0
  actions?: ToastAction[]; // list of actions, used for "action" type only
  deviceUniqueId?: string; // device unique id, used to identify to which device toast is related to
}

const TOAST_DEFAULT_VALUES: Partial<Toast> = {
  closeable: false,
  actions: null,
  title: null,
  dismissAfter: null,
  textParams: {},
};

@Injectable({
  providedIn: "root",
})
export class Toasts {
  // List of active toasts
  _changes = new BehaviorSubject<Toast[]>([]);
  // Current state of the DFU modal dialog
  private dfuModalEvent: DfuModalEvent;

  constructor(private router: Router, private dfuModal: DfuModalService) {
    this.router.events.subscribe((ev) => {
      if (ev instanceof NavigationEnd) {
        this.dismissStatusToasts();
      }
    });
    this.dfuModal.events.subscribe((ev) => {
      this.dfuModalEvent = ev;
      // Re-emit toasts
      this._changes.next(this._changes.getValue());
    });
  }

  get changes(): Observable<Toast[]> {
    return this._changes.pipe(
      map((toasts) =>
        this.dfuModalEvent === "opened"
          ? toasts.filter(({ type }) => type === "status")
          : toasts
      )
    );
  }

  /**
   * Creates a new toast.
   *
   * @returns `id` of a toast
   */
  push(toast: Toast) {
    const id = v4(); // Toast UUID
    const toastWithDefaults = mergeObjectsWithoutOverwrite(toast, {
      id,
      ...TOAST_DEFAULT_VALUES,
    });
    toastWithDefaults.actions = toastWithDefaults.actions?.map((action) =>
      mergeObjectsWithoutOverwrite(action, TOAST_ACTION_DEFAULTS)
    );
    const value = this._changes.getValue();
    value.push(toastWithDefaults);
    this._changes.next(value);
    return id;
  }

  /**
   * Snapshot of all toasts.
   */
  get all(): Toast[] {
    return this._changes.getValue();
  }

  /**
   * Dismisses a toast.
   */
  dismiss(id: string) {
    const value = this._changes.getValue();
    this._changes.next(value.filter((t) => t.id !== id));
  }

  /**
   * Dismiss all "status" toasts.
   */
  private dismissStatusToasts() {
    const value = this._changes.getValue();
    this._changes.next(value.filter((toast) => toast.type !== "status"));
  }
}
