import { ChangeDetectorRef, Component, OnDestroy, OnInit } from "@angular/core";
import { flyInOutRTLAnimation } from "../animations/slideshow.animations";

@Component({
  selector: "oz-slideshow",
  templateUrl: "./slideshow.component.pug",
  animations: [flyInOutRTLAnimation],
})
export class SlideshowComponent implements OnInit, OnDestroy {
  slides = [
    {
      header: "FTU.CONNECTIVITY.HEADER",
      title: "FTU.CONNECTIVITY.TITLE",
      logo: "camera_mounting",
    },
    {
      header: "FTU.BEST_PRACTICES.HEADER",
      title: "FTU.BEST_PRACTICES.TITLE",
      logo: "desk_setup_desk",
    },
    {
      header: "FTU.HEALTH_WELLNESS.HEADER",
      title: "FTU.HEALTH_WELLNESS.TITLE",
      logo: "moon_astronaut",
    },
  ];

  public currentSlide = 0;
  public cards = [];
  private intervalId: number;
  private readonly slideInterval = 3000;

  constructor(private changeDetectorRef: ChangeDetectorRef) {}

  ngOnDestroy(): void {
    window.clearInterval(this.intervalId);
  }

  ngOnInit(): void {
    this.cards = [this.slides[0]];
    this.intervalId = window.setInterval(() => this.nextSlide(), this.slideInterval);
  }

  public nextSlide(): void {
    this.goToSlide(++this.currentSlide);
  }

  public goToSlide(position: number): void {
    this.currentSlide = position % this.slides.length;
    this.cards = [this.slides[this.currentSlide]];
    this.changeDetectorRef.detectChanges();
  }
}
