import { Injectable } from "@angular/core";
import { DeviceManagerService, OzDevice } from "./device-manager.service";
import { NotificationsService } from "./notifications.service";
import { EulaService } from "./eula.service";
import { LensSettingsService } from "./lens-settings.service";
import { combineLatest, BehaviorSubject } from "rxjs";
import { filter, mergeMap } from "rxjs/operators";

@Injectable({
  providedIn: "root",
})
export class QdNotificationsService {
  private notify: boolean;
  private qdDevicesMap: Map<string, boolean> = new Map();
  public qdDisconnectChanged$: BehaviorSubject<boolean> = new BehaviorSubject(
    null
  );

  constructor(
    private deviceManager: DeviceManagerService,
    private notificationService: NotificationsService,
    private eulaService: EulaService,
    private lensSettingsService: LensSettingsService
  ) {
    // Listen to connected devices and compare headsetConnectedState DA devices
    this.handleQuickDisconnect();

    // Notify if device notifications are turned ON and if smart lock setting is active
    this.checkSettings();
  }

  handleQuickDisconnect() {
    this.eulaService.agreed$
      .pipe(
        filter((v) => !!v),
        mergeMap((v) => this.deviceManager.getConnectedDevices())
      )
      .subscribe((devices) => {
        let deviceUniqueIds: string[] = [];

        // Check if device is QD
        devices.forEach((d) => {
          if (d.featureList.includes("quickDisconnect")) {
            // Connected QD device disconnects headset
            if (this.qdDevicesMap.get(d.uniqueId) && !d.headsetConnectedState) {
              this.notifyIfNeeded(d);
            }

            // Set QD devices with headsetConnectedState
            this.qdDevicesMap.set(d.uniqueId, d.headsetConnectedState);
            deviceUniqueIds.push(d.uniqueId);
          }
        });

        // If DA is disconnected remove QD device from map
        this.removeQdDevice(deviceUniqueIds);
      });
  }

  checkSettings() {
    combineLatest([
      this.lensSettingsService.lensSettings,
      this.deviceManager.getSoftwareSettings(),
      this.qdDisconnectChanged$.asObservable(),
    ]).subscribe(([settings, swSettings, qdDisconnectChanged]) => {
      let smartLockActive = false;
      if (!!swSettings.length) {
        swSettings.forEach((setting) => {
          if (setting.id === "smartLock") {
            smartLockActive = setting.value;
          }
        });
      }
      const smartLock = qdDisconnectChanged
        ? qdDisconnectChanged
        : smartLockActive;
      this.notify =
        settings.deviceAlerts && settings.enableNotifications && smartLock;
    });

    // First emit - no qd setting change
    this.qdDisconnectChanged$.next(false);
  }

  notifyIfNeeded(device: OzDevice) {
    if (!this.notify) {
      return;
    }

    this.notificationService.singleNotificationUntranslated({
      title: "GENERAL.POLY_LENS",
      body: "NOTIFICATIONS.QD_SCREEN_LOCK.DESCRIPTION",
    });
  }

  removeQdDevice(deviceUniqueIds: string[]) {
    this.qdDevicesMap.forEach((value, deviceUniqueId) => {
      if (!deviceUniqueIds.includes(deviceUniqueId)) {
        this.qdDevicesMap.delete(deviceUniqueId);
      }
    });
  }
}
