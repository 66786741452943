import { Injectable } from "@angular/core";
import { Subscription } from "rxjs";
import { switchMap } from "rxjs/operators";
import { filter } from "rxjs/operators";
import { TranslateService } from "@ngx-translate/core";
import { EulaService } from "./eula.service";
import { NotificationsService } from "./notifications.service";
import { SettingsMetaData } from "../lens-settings/lens-settings-ui.model";
import { DeviceManagerService, OzDevice } from "./device-manager.service";
import { LensSettingsService } from "./lens-settings.service";
import { addManufacturerPrefix } from "../utils/device.utils";
import { BATTERY, BATTERY_WITH_CRITICAL_VALUE } from "../utils/constants";

@Injectable({
  providedIn: "root",
})
export class LowBatteryNotificationsService {
  private devicesSub: Subscription;
  private lowBatteryState: { [uniqueId: string]: boolean } = {};
  private translatedCopy = "";
  private lastConnected: { [uniqueId: string]: boolean } = {};

  constructor(
    private lensSettingsService: LensSettingsService,
    private deviceManager: DeviceManagerService,
    private notificationService: NotificationsService,
    private translateService: TranslateService,
    private eulaService: EulaService
  ) {
    this.lensSettingsService.lensSettings.subscribe((settings) => {
      if (settings.enableNotifications && settings.deviceAlerts) {
        this.setupNotifications(settings);
      } else {
        this.teardownNotifications();
      }
    });

    this.translateService
      .stream("NOTIFICATIONS.LOW_BATTERY.MESSAGE")
      .subscribe((translation) => {
        this.translatedCopy = translation;
      });
  }

  private setupNotifications(settings: SettingsMetaData) {
    if (settings.lowBattery && !this.devicesSub) {
      this.devicesSub = this.eulaService.agreed$
        .pipe(
          filter((v) => !!v),
          switchMap(() => this.deviceManager.getDevices())
        )
        .subscribe((devices) => {
          devices.forEach((d) => this.notifyIfNeeded(d));
        });
    } else if (!settings.lowBattery && this.devicesSub) {
      this.teardownNotifications();
    }
  }

  private teardownNotifications() {
    this.devicesSub?.unsubscribe();
    this.devicesSub = null;
    this.lowBatteryState = {};
  }

  private notifyIfNeeded(device: OzDevice) {
    const previouslyLowBattery = this.lowBatteryState[device.uniqueId];
    const lowBattery = this.isBatteryLow(device);

    if (
      device.isConnected &&
      ((previouslyLowBattery === undefined && lowBattery) ||
        (lowBattery !== previouslyLowBattery && lowBattery))
    ) {
      this.sendNotification(device);
    } else if (
      this.lastConnected[device.uniqueId] !== undefined &&
      !this.lastConnected[device.uniqueId] &&
      device.isConnected &&
      lowBattery
    ) {
      this.sendNotification(device);
    }

    this.lastConnected[device.uniqueId] = device.isConnected;
    this.lowBatteryState[device.uniqueId] = lowBattery;
  }

  private isBatteryLow(device: OzDevice): boolean {
    if (!device.featureList.includes("batteryIndication")) return false;

    if (device?.battery?.isChargeLevelValid) {
      return device?.battery?.chargeLevel ===
        BATTERY_WITH_CRITICAL_VALUE.PERCENT_0 ||
        device?.battery?.chargeLevel ===
          BATTERY_WITH_CRITICAL_VALUE.BATTERY_CRITICAL ||
        device?.battery?.chargeLevel === BATTERY_WITH_CRITICAL_VALUE.PERCENT_25
        ? true
        : false;
    }
    return device?.battery?.level === BATTERY.PERCENT_0 ||
      device?.battery?.level === BATTERY.PERCENT_25
      ? true
      : false;
  }

  private sendNotification(device: OzDevice) {
    this.notificationService.singleNotification({
      title: `${addManufacturerPrefix(device.displayName)}`,
      body: this.translatedCopy,
      origin: "BATTERY_LOW",
    });
  }
}
