import { Component, OnInit, Input, OnDestroy } from "@angular/core";
import { LensSettingsService } from "../../../services/lens-settings.service";
import { Subscription } from "rxjs";
import { SettingsMetaData } from "../../lens-settings-ui.model";
import { AccordionItem } from "../../../shared/components/accordion/accordion.component";

export interface LensSettingDeviceAlertsMetadata {
  action: string;
  type: string;
  title: string;
  description: string;
  visibleInPwa: boolean;
}

/**
 * @deprecated
 * This file is currently not used.
 *
 * NOTE: If it goes into use, the template file needs to be updated
 * to perform translations / i18n
 */
@Component({
  templateUrl: "./lens-settings-device-alerts.component.pug",
})
export class LensSettingsDeviceAlertsComponent implements OnInit, OnDestroy {
  private settingsSubscription: Subscription;

  @Input() set accordionData(value: AccordionItem) {
    this.setting = value.data;
  }

  setting: LensSettingDeviceAlertsMetadata;

  deviceAlertsSettings: SettingsMetaData;

  constructor(private lensSettingsService: LensSettingsService) {}

  ngOnInit(): void {
    this.settingsSubscription = this.lensSettingsService.lensSettings.subscribe(
      (settings) => {
        this.deviceAlertsSettings = { ...settings };
      }
    );
  }

  ngOnDestroy(): void {
    this.settingsSubscription.unsubscribe();
  }

  onValueChanged(ev: any, action: string) {
    this.deviceAlertsSettings[action] = ev;
    this.lensSettingsService.setLensSettings(this.deviceAlertsSettings);
  }
}
