import { Injectable } from "@angular/core";
import { Device } from "@poly/hub-native";
import { BehaviorSubject } from "rxjs";
import { distinctUntilChanged } from "rxjs/operators";
import { TenantService } from "./tenant.service";

/**
 * Keeps track of devices that were logged in during the user's session.
 *
 * Once user logs out, the list of devices that were tracked is cleared.
 */

@Injectable({
  providedIn: "root",
})
export class LoggedInDevices {
  /**
   * List of devices that are logged-in into by users.
   */
  all = new BehaviorSubject<string[]>([]);

  constructor(private tenantService: TenantService) {
    this.tenantService.tenantId$.pipe(distinctUntilChanged()).subscribe(() => {
      this.all.next([]);
    });
  }

  addDevice(device: Device) {
    if (device) {
      const value = this.all.getValue();
      if (!value.includes(device.uniqueId)) {
        value.push(device.uniqueId);
        this.all.next(value);
      }
    }
  }

  removeDevice(device: Device) {
    if (device) {
      const value = this.all.getValue();
      const index = value.indexOf(device.uniqueId);
      if (index >= -1) {
        value.splice(index, 1);
        this.all.next(value);
      }
    }
  }
}
