import {Injectable, NgModule} from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { FileFilter } from "../polytron/polytron.api";
import { Observable, of } from "rxjs";
import { map, switchMap, take } from "rxjs/operators";
import { PolytronServiceApi } from "../polytron/polytron.service.api";

export interface OzFileDialogOptions {
  /**
   *  The title of a dialog.
   */
  title: string;

  /**
   * If `true`, the `title` is considered to be a translation path.
   */
  titleTranslate: boolean; // TODO: UI: Translation should be managed outside of this service

  /**
   * Filesystem path the dialog points to when it is opened.
   *
   * Defaults to the "Document" directory on Windows.
   */
  path?: string;

  /**
   * List of possible extensions.
   */
  filters?: FileFilter[];
}

interface OzFileDialogResult {
  canceled: boolean;
}

export interface OzOpenFileResult extends OzFileDialogResult {
  paths: string[];
}

export interface OzSaveFileResult extends OzFileDialogResult {
  path: string;
}

@Injectable({
  providedIn: "root",
})
export class OzFileDialog {

  constructor(private translateService: TranslateService, private polytron: PolytronServiceApi) {}

  openFile(options: OzFileDialogOptions): Observable<OzOpenFileResult> {
    return this.translateTitle(options).pipe(
      switchMap((title) =>
        this.polytron.showOpenDialog({
          title,
          properties: ["openFile"],
          filters: options.filters,
        })
      ),
      map(({ canceled, filePaths: paths }) => ({
        canceled,
        paths,
      }))
    );
  }

  saveFile(options: OzFileDialogOptions): Observable<OzSaveFileResult> {
    return this.translateTitle(options).pipe(
      switchMap((title) =>
        this.polytron.showSaveDialog({
          title,
          properties: ["createDirectory"],
          filters: options.filters,
        })
      ),
      map(({ canceled, filePath: path }) => ({
        canceled,
        path,
      }))
    );
  }

  /**
   * Translates a title of a dialog.
   *
   * // TODO: UI: We should move this outside of this service. It should be up to the component to manage the translation.
   */
  private translateTitle(options: OzFileDialogOptions) {
    return of(options.titleTranslate).pipe(
      // Check if the title needs to be translated
      switchMap((translate) =>
        translate
          ? this.translateService.stream(options.title).pipe(take(1))
          : of(options.title)
      )
    );
  }
}
