import { Injectable, OnDestroy } from "@angular/core";
import { Device } from "@poly/hub-native";
import { MS_IN_MINUTE, MS_IN_SECOND } from "../utils/constants";
import { Observable, race, timer, Subject, BehaviorSubject } from "rxjs";
import { filter, map } from "rxjs/operators";
import { Subscriptions } from "../utils/subscriptions";
import { DeviceManagerService } from "./device-manager.service";
import { ILoggingService } from "./logging.service";

// Non-video devices do not manifest the same delays / unavailable after a reset
// so use drastically shorter times.
export const RESET_WAIT_AFTER_RECONNECT_NON_VIDEO_MS = MS_IN_SECOND;
export const RESET_RELEASE_NO_LATER_THAN_NON_VIDEO_MS = 3 * MS_IN_SECOND;

// Time to wait after the device reconnects before declaring "all clear"
// **IMPORTANT** this variable must be shorter than RESET_RELEASE_NO_LATER_THAN_MS
export const RESET_WAIT_AFTER_RECONNECT_VIDEO_MS = 15 * MS_IN_SECOND;
// Absolute latest to wait for device reconnecting before declaring "all clear";
// **IMPORTANT** this variable must be longer than RESET_WAIT_AFTER_RECONNECT_MS
export const RESET_RELEASE_NO_LATER_THAN_VIDEO_MS = MS_IN_MINUTE;

export type ReconnectDeviceStatus = "Reconnecting" | "ReconnectCompleted";

interface ReconnectDeviceEvent {
  // Status of device reconection.
  reconnectDeviceStatus: ReconnectDeviceStatus;
  // Device that is being reconnected
  device: Device;
}

/**
 * Object having a Device#uniqueId as a key, which maps to device's last emited event for reconnecting status.
 */
type Events = {
  [uniqueId: string]: ReconnectDeviceEvent;
};

@Injectable({
  providedIn: "root",
})
export class ReconnectDeviceEvents implements OnDestroy {
  private subs = new Subscriptions();

  private events = new BehaviorSubject<Events>({});

  constructor(
    private logger: ILoggingService,
    private deviceManager: DeviceManagerService
  ) {}

  /**
   * @param device Device that is being reconnected.
   */
  reconnectStarted(device: Device) {
    const waitAfterReconnect = device.isVideoDevice
      ? RESET_WAIT_AFTER_RECONNECT_VIDEO_MS
      : RESET_WAIT_AFTER_RECONNECT_NON_VIDEO_MS;
    const releaseNoLaterThan = device.isVideoDevice
      ? RESET_RELEASE_NO_LATER_THAN_VIDEO_MS
      : RESET_RELEASE_NO_LATER_THAN_NON_VIDEO_MS;

    this.fireEvent({ reconnectDeviceStatus: "Reconnecting", device });

    if (releaseNoLaterThan <= waitAfterReconnect) {
      this.logger.error(
        "Code logic error, RESET_RELEASE_NO_LATER_THAN_MS must be longer than RESET_WAIT_AFTER_RECONNECT_MS"
      );
    }

    race(
      // Waiting for a device to be reconnected
      this.deviceReconnected(device.uniqueId, waitAfterReconnect),
      // A decent time given to the reconnection process to complete before declaring it "completed"
      timer(releaseNoLaterThan)
    ).subscribe(() => {
      // Fire event that a reconnection process is completed
      this.fireEvent({ reconnectDeviceStatus: "ReconnectCompleted", device });
      // Remove last emitted event for this device from the internal events store
      this.removeReconnectStatus(device);
    });
  }

  /**
   * @returns Pipeline which delivers a reconnection status of a device
   */
  getStatus(device: Device): Observable<ReconnectDeviceStatus> {
    return this.events.pipe(
      map((eventsSnapshot) => {
        for (const uniqueId in eventsSnapshot) {
          if (device.uniqueId === uniqueId) {
            return eventsSnapshot[uniqueId].reconnectDeviceStatus;
          }
        }
      }),
      filter((x) => !!x)
    );
  }

  /**
   * Removes a reconnection status information for a given device.
   */
  private removeReconnectStatus(device: Device) {
    const eventsSnapshot = this.events.getValue();
    delete eventsSnapshot[device.uniqueId];
    this.events.next(eventsSnapshot);
  }

  /**
   * Fires a new reconnection status event.
   */
  private fireEvent(ev: ReconnectDeviceEvent) {
    const eventsSnapshot = this.events.getValue();
    eventsSnapshot[ev.device.uniqueId] = ev;
    this.events.next(eventsSnapshot);
  }

  /**
   * A sophisticated way to check that a device reconnects after a reset,
   * then wait a certain amount of time before giving the "all clear" (an
   * observable .complete() method).
   *
   * Sophisticated methods can fail under fringe cases, which is why a race
   * condition is raced against this to prevent too much time passing for the user.
   */
  private deviceReconnected(
    uniqueId: string,
    waitAfterReconnectMS
  ): Observable<any> {
    const deviceReconnected$ = new Subject<undefined>();
    let deviceDisconnectedAtLeastOnce = false;

    const sub = this.deviceManager
      .getDevice(uniqueId)
      .pipe(
        filter((device) => !!device)
        // then the following is likely
        // device isConnected = true (initial state), then false (resetting), then true (device is back)
      )
      .subscribe((device: Device) => {
        // trap first false (resetting) case
        if (!device.isConnected) {
          deviceDisconnectedAtLeastOnce = true;

          return;
        }

        // return if device has not been disconnected yet
        if (!deviceDisconnectedAtLeastOnce) {
          return;
        }

        // at this point, device is now connected & available, wait an additional amount of time before resolving
        if (device.isConnected) {
          setTimeout(() => {
            deviceReconnected$.next();
            deviceReconnected$.complete();

            // TODO: UI: This is an anti-pattern. The code should not manage a subscription inside the (same) subscription.
            sub.unsubscribe();
          }, waitAfterReconnectMS);
        }
      });

    return deviceReconnected$;
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }
}
