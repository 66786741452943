import {Injectable } from "@angular/core";
import { Subject } from "rxjs";


@Injectable({
  providedIn: "root",
})
export class WindowsEventsService {
  public onWindowsEvent$ = new Subject<string>();

  constructor() {
    // other window events might be needed
    window.onfocus = () => this.onWindowsEvent$.next("focus");
  }
}
