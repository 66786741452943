import {OpenDialogReturnValue, SaveDialogReturnValue} from "./polytron.api";
import {EventEmitter} from "events";

export class IpcRenderer extends EventEmitter {
  send(channel: string, ...args: any[]): void {
  };
}

export class PolytronServiceApi {
  getIpcRenderer(): IpcRenderer {
    throw new Error("Not implemented");
  }

  getPath(name: string): string {
    throw new Error("Not implemented");
  }

  getAppPath(): string {
    throw new Error("Not implemented");
  }

  quit() {
    throw new Error("Not implemented");
  }

  quitable(): boolean {
    throw new Error("Not implemented");
  }

  askForCameraAccess(): Promise<boolean> {
    throw new Error("Not implemented");
  }

  showOpenDialog(options: { [key: string]: any }): Promise<OpenDialogReturnValue> {
    throw new Error("Not implemented");
  }

  showSaveDialog(options: { [key: string]: any }): Promise<SaveDialogReturnValue> {
    throw new Error("Not implemented");
  }
}
