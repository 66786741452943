import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { AuthService } from "../services/auth.service";
import { DeviceManagerService } from "../services/device-manager.service";
import { EulaService } from "../services/eula.service";
import { LensSettingsService } from "../services/lens-settings.service";
import { StorageService } from "../services/storage.service";
import { TrayService } from "../services/tray.service";
import { TenantService } from "../services/tenant.service";
import { HISTORICAL_DEVICES_KEY } from "./constants";

@Injectable({
  providedIn: "root",
})
export class RestoreDefaultUtils {
  private isAuthed: boolean;

  constructor(
    private lensSettingsService: LensSettingsService,
    private storageService: StorageService,
    private authService: AuthService,
    private trayService: TrayService,
    private deviceManager: DeviceManagerService,
    private eulaService: EulaService,
    private router: Router,
    private tenantService: TenantService
  ) {
    this.authService.isAuthenticated$.subscribe((authed) => {
      this.isAuthed = authed;
    });
  }

  async restoreDefault() {
    // remove all non connected devices
    const devices = this.storageService.getItem(HISTORICAL_DEVICES_KEY);
    for (const device in devices) {
      this.deviceManager.removeDevice(device);
    }

    // clear storage (logged in state, historical devices, preferences, ...)
    await this.storageService.clearAllItems();
    // this writes default Poly Lens Settings to storage
    this.lensSettingsService.resetDefaultSettings();
    this.eulaService.clear();
    this.trayService.init();

    // log user out and redirect back to first-time user experience page
    // if user is logged in, otherwise just redirect back to first-time user experience page
    if (this.isAuthed) {
      this.tenantService.setTenantId(null);
      this.tenantService.setTenantName(null);
      this.authService.startLogoutFlow("");
    } else {
      this.router.navigate([""]);
    }
  }

  async restoreDefaultAfterDeletion() {
    // remove all non connected devices
    const devices = this.storageService.getItem(HISTORICAL_DEVICES_KEY);
    for (const device in devices) {
      this.deviceManager.removeDevice(device);
      console.log("Remove Device")
    }
    this.router.navigate([""]);
      console.log("redirect")
    // clear storage (logged in state, historical devices, preferences, ...)
    await this.storageService.clearAllItems();
    console.log("items cleared")
    // this writes default Poly Lens Settings to storage
    this.lensSettingsService.resetDefaultSettings();
    this.eulaService.clear();
    this.trayService.init();
    
    this.tenantService.setTenantId(null);
    this.tenantService.setTenantName(null);

    window.location.reload();
  }
}
