// common parent class that takes care of prototype chain and name
export class WhError extends Error {
  constructor(message: string) {
    super(message);
    this.name = new.target.name;
    Object.setPrototypeOf(this, new.target.prototype);
  }
}


// Low level communication error (no response or invalid one)
export class DeviceCommError extends WhError {
  constructor(message: string) {
    super(message);
  }
}


// No response from device at all
export class ReqTimeoutError extends DeviceCommError {
  constructor(message: string) {
    super(message);
  }
}


// Garbled response from device (syntax or validation problems)
export class InvalidResponseError extends DeviceCommError {
  constructor(message: string) {
    super(message);
  }
}


// Valid response with error code 
export class ErrorResponseError extends WhError {
  constructor(message: string) {
    super(message);
  }
}


// Device misses expected functionality
export class UnsupportedDeviceError extends WhError {
  constructor(message: string) {
    super(message);
  }
}
