import {Injectable, NgModule} from "@angular/core";
import { Observable, Observer, Subscriber } from "rxjs";

import { v4 as uuidv4 } from "uuid";
import { PolytronServiceApi } from "../polytron/polytron.service.api";

export interface FileDownloadProgress {
  url: string; // target url
  dest: string; // destination filepath
  totalBytes: number; // total bytes to download
  downloadedBytes: number; // currently downloaded number of bytes
  state: "success" | "progress" | "failed"; // success | progress | failed
  err?: string; // error description (in case state === "failed")
}

const FILE_DOWNLOAD_MESSAGE = "__FileDownload__";

@Injectable({
  providedIn: "root",
})
export abstract class IFileDownload {
  // Downloads a file from a given URL and saves it on the specified destination.
  abstract download(
    url: string,
    destination: string
  ): Observable<FileDownloadProgress>;
}

@Injectable({
  providedIn: "root",
})
export class FileDownload extends IFileDownload {

  // Maps file download ID to observer (in order to distinguish paralell file downloads)
  private idToObserver: {
    [key: string]: Subscriber<FileDownloadProgress>;
  } = {};

  constructor(private polytron: PolytronServiceApi) {
    super();

    this.polytron.getIpcRenderer().on(
      FILE_DOWNLOAD_MESSAGE,
      (ev, { id, url, dest, totalBytes, downloadedBytes, state, err }) => {
        // Get the right subscriber
        const ob: Observer<FileDownloadProgress> = this.idToObserver[id];

        if (ob) {
          // Define new progress event
          const progress: FileDownloadProgress = {
            url,
            dest,
            totalBytes,
            downloadedBytes,
            state,
            err,
          };
          // And pass the progress on
          ob.next(progress);
          // If file download finished (either with no error or with error)
          if (progress.state !== "progress") {
            // Complete, as file download is finished
            ob.complete();
            // Remove the observer from the store
            delete this.idToObserver[id];
          }
        }
      }
    );
  }

  download(url: string, dest: string): Observable<FileDownloadProgress> {
    return new Observable((ob) => {
      // Unique ID (to distinguish parallel file downloads)
      const id: string = uuidv4();
      // Create association of the ID and the observer
      this.idToObserver[id] = ob;
      // Request a file download
      this.polytron.getIpcRenderer().send(FILE_DOWNLOAD_MESSAGE, { id, url, dest });
    });
  }
}
