import { Injectable } from "@angular/core";
// import * as path from "path";
// TODO: import * as fs from "fs";

/**
 * AdminStorage is service responsible for retrieval of administrative configuration.
 *
 * Administrative configuration is set of optional read only configuration options
 * to be managed by administrators on target deployments.
 */
@Injectable()
export class AdminConfig {
  mode: "cloud" | "network" | "pwa" = "pwa";
  dfuPath: string;
  eulaAccept: boolean = true;
  dfuRemindHours = 8;
  displayLinkDriverPath: string = null;
  launchAtStartup: boolean | null = null;
  launchMinimized: boolean | null = null;

  // Show device settings in "cloud" or "pwa" mode always and in "network" mode only if admin
  // deploys a configuration file with this option turned on
  get deviceSettingsEnabled(): boolean {
    return (
      this.mode !== "network" ||
      this._deviceSettingsEnabled
    );
  }
  // Actual value of the configuration for showing device settings in the "network" mode
  _deviceSettingsEnabled = false;

  load() {
    /* TODO:
    const adminConfigPath = path.join(
      this.polytron.getPath("appData"),
      "oz-client",
      "admin-config.json"
    );
    if (fs.existsSync(adminConfigPath)) {
      try {
        const config = JSON.parse(fs.readFileSync(adminConfigPath).toString());
        this.mode = config?.mode ?? "cloud";
        this.dfuPath = config?.dfuPath ?? null;
        this.eulaAccept = (config?.["Accept EULA"] ?? "No") === "No";
        this.dfuRemindHours = config.dfuRemindHours ?? 8;
        this.displayLinkDriverPath = config.displayLinkDriverPath ?? null;
        this._deviceSettingsEnabled = config.deviceSettingsEnabled ?? false;
        this.launchAtStartup = config?.launchAtStartup ?? null;
        this.launchMinimized = config?.launchMinimized ?? null;
      } catch (e) {
        console.error(
          `Could not parse data retrieved from "admin-config.json" file.`
        );
      }
    }
     */
  }
}
