import { Component, EventEmitter, Input, Output } from "@angular/core";

/**
 * @example usage
 *
 * oz-modal([show]="showFavoriteDeleteModal")
 *   oz-confirm((confirm)="deleteModalClick(true)", (cancel)="deleteModalClick(false)")
 *     div {{ "DETAIL.FAVORITE_DELETE_CONFIRM" | translate }}
 */
@Component({
  selector: "oz-confirm",
  templateUrl: "./confirm.component.pug",
})
export class ConfirmComponent {
  @Input() cancelText = "GENERAL.CANCEL";
  @Input() confirmText = "GENERAL.OK";
  @Output() cancel = new EventEmitter<void>();
  @Output() confirm = new EventEmitter<void>();
}
