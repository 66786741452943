import { BADGE_ACCOUNT, BadgeCountService } from "./badge-count.service";
import { Injectable } from "@angular/core";
import { Observable, of } from "rxjs";
import { AuthService, AuthProfile } from "./auth.service";
import {
  map,
  mergeMap,
  switchMap,
  filter,
  tap,
  shareReplay,
} from "rxjs/operators";
import { Apollo, gql, QueryRef } from "apollo-angular";
import { ApolloQueryResult } from "@apollo/client/core";
import { EmptyObject } from "apollo-angular/types";

const MUTATE_REJECT_INVITE = gql`
  mutation rejectUserInvite($code: ID!) {
    rejectUserInvite(code: $code) {
      invite {
        id
      }
    }
  }
`;

const MUTATE_ACCEPT_INVITE = gql`
  mutation acceptUserInvite($code: ID!) {
    acceptUserInvite(code: $code) {
      invite {
        id
        tenant_id
      }
    }
  }
`;

const GET_PENDING_INVITES = gql`
  {
    userInvites {
      invites {
        id
        email
        created_at
        tenant_id
        role_name
        invited_by_accessor_id
        invited_by_email
        tenant_name
        accepted_at
      }
    }
  }
`;

const GET_DESKTOP_ACCESSOR = gql`
  query lensDesktopAccessor($accessorId: ID!) {
    accessor(accessorId: $accessorId) {
      id
      grants {
        resourceId
        resourceType
        roles {
          name
        }
      }
    }
  }
`;

const GET_TENANT_INFO = gql`
  query tenant($tenantId: ID!) {
    tenant(id: $tenantId) {
      id
      name
    }
  }
`;

export type Accessor = {
  id: string;
  grants: {
    resourceId: string;
    resourceType: string;
    roles: {
      name: string;
    }[];
  }[];
};

export type AccessorResult = {
  accessor: Accessor;
};

export type UserInvite = {
  id: string;
  email: string;
  created_at: Date;
  accepted_at?: Date;
  accepted_by_accessor_id?: string;
  tenant_id: string;
  role_name: string;
  invited_by_accessor_id: string;
  invited_by_email: string;
  tenant_name: string;
};

export type UserInvites = {
  userInvites: {
    invites: Array<UserInvite>;
  };
};

@Injectable({
  providedIn: "root",
})
export class AccessorService {
  private _accessor$: Observable<Accessor>;
  private _invitesWatchQuery: QueryRef<UserInvites>;
  private _accessorWatchQuery: QueryRef<Accessor>;

  constructor(
    private auth: AuthService,
    private apollo: Apollo,
    private badgeService: BadgeCountService
  ) {
    this.pendingInvites$.subscribe();
  }

  public get accessor$() {
    if (!this._accessor$) {
      this._accessor$ = this.auth.isLoginless$.pipe(
        switchMap((isLoginless) => {
          if (isLoginless) {
            return of(null);
          }

          return this.auth.profile$.pipe(
            filter((profile) => !!profile),
            switchMap((profile: AuthProfile) => {
              return this.getAccessorWatchQuery(profile.sub).valueChanges;
            }),
            map((result: ApolloQueryResult<AccessorResult>) => {
              return result.data.accessor;
            }),
            shareReplay(1)
          );
        })
      );
    }

    return this._accessor$;
  }

  public get pendingInvites$(): Observable<Array<UserInvite>> {
    return this.getPendingInvites();
  }

  public declineInvite(id: string) {
    return this.apollo
      .mutate({
        mutation: MUTATE_REJECT_INVITE,
        variables: {
          code: id,
        },
      })
      .pipe(
        map((data) => {
          if (data) {
            this._invitesWatchQuery?.refetch();
            return true;
          }
          return false;
        })
      );
  }

  public acceptInvite(id: string): Observable<boolean> {
    return this.apollo
      .mutate<{ acceptUserInvite: boolean }>({
        mutation: MUTATE_ACCEPT_INVITE,
        variables: {
          code: id,
        },
      })
      .pipe(
        map(({ data }) => {
          if (data && data.acceptUserInvite) {
            this._invitesWatchQuery?.refetch();
            this._accessorWatchQuery?.refetch();
            return true;
          }
          return false;
        })
      );
  }

  private getAccessorWatchQuery(accessorId): QueryRef<unknown, EmptyObject> {
    if (!this._accessorWatchQuery) {
      this._accessorWatchQuery = this.apollo.watchQuery({
        query: GET_DESKTOP_ACCESSOR,
        variables: { accessorId },
        fetchPolicy: "network-only",
      });

      return this._accessorWatchQuery;
    }

    this._accessorWatchQuery.setVariables({ accessorId });
    return this._accessorWatchQuery;
  }

  private getTenantWatchQuery(
    tenantId: string
  ): QueryRef<unknown, EmptyObject> {
    return this.apollo.watchQuery({
      query: GET_TENANT_INFO,
      variables: { tenantId },
      fetchPolicy: "network-only",
    });
  }

  private getPendingInvitesWatchQuery(): QueryRef<unknown, EmptyObject> {
    if (!this._invitesWatchQuery) {
      this._invitesWatchQuery = this.apollo.watchQuery<UserInvites>({
        query: GET_PENDING_INVITES,
        fetchPolicy: "network-only",
      });

      return this._invitesWatchQuery;
    }

    this._invitesWatchQuery.refetch();
    return this._invitesWatchQuery;
  }

  private getPendingInvites(): Observable<UserInvite[]> {
    return this.auth.isLoginless$.pipe(
      switchMap((isLoginless) => {
        if (isLoginless) {
          return of([]);
        }

        return this.auth.profile$.pipe(
          filter((profile) => !!profile),
          mergeMap(() => {
            return this.getPendingInvitesWatchQuery().valueChanges;
          }),
          map((result: ApolloQueryResult<UserInvites>) => {
            return result.data.userInvites.invites.filter((invite) => {
              return invite.role_name === "device_user" && !invite.accepted_at;
            });
          }),
          tap((invites) => {
            this.badgeService.setCountForKey(
              BADGE_ACCOUNT,
              invites?.length || 0
            );
          }),
          shareReplay(1)
        );
      })
    );
  }

  public getTenant(tenantId: string): Observable<any> {
    return this.getTenantWatchQuery(tenantId).valueChanges;
  }
}
