/**
 * Models device settings by category, and the order of device settings inside of one category.
 *
 * This is consumed by the component which renders list of device settings on a screen.
 */
import {
  DisabledOptions,
  HiddenOptions,
} from "../shared/components/dropdown/conditional-options.pipe";
import {
  DEVICE_SETTING_ID_RESTORE_DEFAULTS,
  SETTINGS_MODALS,
  SETTINGS,
  DEVICE_SETTING_ID_RESTART_DEVICE,
  DEVICE_SETTING_ID_ANSWER_IGNORE,
  DEVICE_SETTING_ID_ANSWERING_CALL_ALERT,
  DEVICE_SETTING_ID_ANSWERING_CALL,
  DEVICE_SETTING_ID_AUDIO_CHANNEL_TONE,
  DEVICE_SETTING_ID_AUDIO_SENSING,
  DEVICE_SETTING_ID_CLOSE_CONVERSATION_LIMITING,
  DEVICE_SETTING_ID_CALLED_ID,
  DEVICE_SETTING_ID_MUTE_OFF_ALERT,
  DEVICE_SETTING_ID_MUTE_REMINDER_MODE,
  DEVICE_SETTING_ID_MUTE_REMINDER_TIMING,
  DEVICE_SETTING_ID_ONLINE_INDICATOR,
  DEVICE_SETTING_ID_AUTO_ANSWER_NO_SENSOR,
  DEVICE_SETTING_ID_DEFAULT_PHONE_LINE,
  DEVICE_SETTING_ID_SECOND_INCOMMING_CALL,
  DEVICE_SETTING_ID_SELECT_HEADSET_TYPE,
  DEVICE_SETTING_ID_TONE_CONTROL,
  DEVICE_SETTING_ID_MUTE_ALERTS,
  DEVICE_SETTING_ID_MUTE_ALERT_TYPES,
  DEVICE_SETTING_ID_NOTIFICATION_TONES,
  DEVICE_SETTING_ID_INCOMMING_CALL_ALERT,
  DEVICE_SETTING_ID_HOLD_REMINDER,
  DEVICE_SETTING_ID_CONNECTION_INDICATION,
  DEVICE_SETTING_ID_ROCKET_BUTTON_BEHAVIOR,
  DEVICE_SETTING_ID_DAISY_CHAIN_AUDIO,
  DEVICE_SETTING_ID_BATTERY_LEVEL_PROMPT,
  DEVICE_SETTING_ID_MUTE_REMINDER_MODE_FOR_SPEAKER_PHONES,
  DEVICE_SETTING_ID_SPEAKER_TRACKING,
  DEVICE_SETTING_ID_SPEAKER_TRACKING_MODE,
  DEVICE_SETTING_ID_BLUETOOTH_PERMISSIONS,
  DEVICE_SETTING_ID_EQIALIZER,
  DEVICE_SETTING_ID_DESKPHONE_TONE,
  DEVICE_SETTING_ID_DESKPHONE_VOLUME,
  DEVICE_SETTING_ID_MOBILE_PHONE,
  DEVICE_SETTING_ID_MOBILE_PHONE_VOLUME,
  DEVICE_SETTING_ID_PC,
  DEVICE_SETTING_ID_PC_VOLUME,
  DEVICE_SETTING_ID_CHARGE_CONFIRMATION,
  DEVICE_SETTING_ID_SIDETONE,
  DEVICE_SETTING_ID_VIBRATE_ON_RING,
  DEVICE_SETTING_ID_VOLUME_LEVEL_TONES,
  DEVICE_SETTING_ID_INDEPENDENT_VOLUME_CONTROL,
  DEVICE_SETTING_ID_ANC_TIMEOUT,
  DEVICE_SETTING_ID_MUTE_REMINDER_VOLUME,
  DEVICE_SETTING_ID_SYSTEM_TONE,
  DEVICE_SETTING_ID_WEARING_PREFERENCE_FOR_VOLUME_CONTROL,
  DEVICE_SETTING_ID_RINGTONE,
  DEVICE_SETTING_ID_VOLUME_MIN_MAX_ALERTS,
  DEVICE_SETTING_ID_AUDIO_BASS,
  DEVICE_SETTING_ID_AUDIO_TREBLE,
  DEVICE_SETTING_ID_WEARING_SENSOR_MASTER,
  DEVICE_SETTING_ID_ACTIVE_CALL_AUDIO,
  DEVICE_SETTING_ID_AUTO_ANSWER,
  DEVICE_SETTING_ID_AUTO_PAUSE_MUSIC,
  DEVICE_SETTING_ID_CALL_BUTTON_LOCK,
  DEVICE_SETTING_ID_SMART_AUDIO_TRANSFER,
  DEVICE_SETTING_ID_AUTO_MUTE,
  DEVICE_SETTING_ID_DIAL_TONE,
  DEVICE_SETTING_ID_AUDIO_QUALITY,
  DEVICE_SETTING_ID_EXTENDED_RANGE_MODE,
  DEVICE_SETTING_ID_HD_VOICE,
  DEVICE_SETTING_ID_AUTO_CONNECT_TO_MOBILE_PHONE,
  DEVICE_SETTING_ID_AUTO_CONNECT_TO_MOBILE_PHONE_2,
  DEVICE_SETTING_ID_BLUETOOTH,
  DEVICE_SETTING_ID_BLUETOOTH_2,
  DEVICE_SETTING_ID_DESKPHONE_AUDIO_BANDWIDTH,
  DEVICE_SETTING_ID_PC_AUDIO_BANDWIDTH,
  DEVICE_SETTING_ID_MOBILE_VOICE_COMMANDS,
  DEVICE_SETTING_ID_OVER_THE_AIR_SUBSCRIPTION,
  DEVICE_SETTING_ID_RANGE,
  DEVICE_SETTING_ID_STREAMING_AUDIO,
  DEVICE_SETTING_ID_CLEAR_TRUSTED_DEVICE_LIST,
  DEVICE_SETTING_ID_SECURE_BLUETOOTH,
  DEVICE_SETTING_ID_G616,
  DEVICE_SETTING_ID_ANTI_STARTLE,
  DEVICE_SETTING_ID_ANTI_STARTLE_2,
  DEVICE_SETTING_ID_NOISE_EXPOSURE,
  DEVICE_SETTING_ID_HOURS_ON_PHONE_PER_DAY,
  DEVICE_SETTING_ID_BISTO,
  DEVICE_SETTING_ID_CLEAR_TRUSTED_DEVICES,
  DEVICE_SETTINGS_CATEGORY_RESET_DEVICE,
  DEVICE_SETTINGS_CATEGORY_GENERAL,
  DEVICE_SETTINGS_CATEGORY_LIGHTS,
  DEVICE_SETTINGS_CATEGORY_RINGTONE_AND_VOLUME,
  DEVICE_SETTINGS_CATEGORY_AUDIO,
  DEVICE_SETTINGS_CATEGORY_SENSOR_AND_PRESENCE,
  DEVICE_SETTINGS_CATEGORY_SOFTPHONES_AND_MEDIA_PLAYERS,
  DEVICE_SETTINGS_CATEGORY_WIRELESS,
  DEVICE_SETTINGS_CATEGORY_ADVANCED,
  DEVICE_SETTINGS_CATEGORY_ANC,
  DEVICE_SETTINGS_CATEGORY_CUSTOM_BUTTON,
  DEVICE_SETTINGS_CATEGORY_CUSTOM_SWIPE,
  DEVICE_SETTING_ID_ANC_MODE,
  DEVICE_SETTING_ID_TRANSPARENCY_MODE,
  DEVICE_SETTING_ID_CUSTOM_SLIDER_IDLE,
  DEVICE_SETTING_ID_CUSTOM_SLIDER_CALL,
  DEVICE_SETTING_ID_CUSTOM_SLIDER_MEDIA,
  DEVICE_SETTINGS_CATEGORY_DIAGNOSTICS,
  DEVICE_SETTING_ID_PC_AUDIO_PERSISTENCE_MODE,
  DEVICE_SETTINGS_CATEGORY_VOICE_PROMPTS_AND_TONES,
  DEVICE_SETTING_ID_MANAGE_ALL,
  DEVICE_SETTING_ID_INCOMMING_CALL,
  DEVICE_SETTING_ID_ANSWER_IGNORE_2,
  DEVICE_SETTING_ID_CALLS_ANNOUNCEMENT,
  DEVICE_SETTING_ID_CONNECTION,
} from "../utils/constants";

/**
 * Some settings that should be toggle switches, but have values
 * other than "true" and "false".
 *
 * ValueRules provide "mapping" from dropdown-like options to toggle-switch options.
 * Since a toggle switch has true / false, the mapping allows defining which
 * setting value is the "truthy" value, and which is the "falsy" value.
 */
export interface ValueRules {
  true: string | boolean;
  false: string | boolean;
}

export namespace SettingsUI {
  export interface Category {
    category: string;
    settings: Setting[];
  }

  // TODO: UI: This "type" of the setting should indicate a way of device setting handling,
  // not just a control used to preview possible values of the setting.
  export type SettingControlType =
    | "radio"
    | "dropdown"
    | "button"
    | "slider"
    | "up-down"
    | "restart-device"
    | "restore-defaults"
    | "clear-trusted-devices";

  export interface Setting {
    id: string;
    type: SettingControlType;
    sortAlpha?: boolean;
    modalId?: keyof typeof SETTINGS_MODALS;
    appUrl?: object;
    valueRules?: ValueRules;
    disabledOptions?: DisabledOptions;
    hiddenOptions?: HiddenOptions;
    // optional function to convert values before display, for example changing a range (slider) array to enumerated values
    // @example ANTI_FLICKER [ 0, 3, 1 ] (which is [ min, max, step ]) would mutate to the values [ 0, 1, 2, 3 ]
    mutateOptions?: Function;
    subsettings?: any[];
  }

  // this is the data input for a slider
  export type RangeSetting = [string, string, string];

  export interface SettingDependency {
    settingId: string;
    action: string;
    when: DependencySource[];
  }

  export interface DependencySource {
    settingId: string;
    value: string;
  }

  export interface MergedSettings {
    parentSetting: string;
    childSetting: string;
    excludePids?: number[];
  }

  /**
   * Given a string array of [min, max, step], convert to an enumerated value array.
   *
   * See settings-ui.model.spec.ts for examples.
   */
  export const convertRangeOptionsToEnumeratedValues = ([
    min,
    max,
    step,
  ]: RangeSetting) => {
    let newOptions = [];
    for (let i = +min; i <= +max; i += +step) {
      // push number as a string
      newOptions.push("" + i);
    }

    return newOptions;
  };

  // **IMPORTANT**
  // These settings should migrate towards named IDs (e.g. SETTINGS.STEREO_AUDIO)
  // and empty titles, eventually removing titles. This is because the interface
  // does not use titles, it uses a lookup like DeviceSettings.0xC00
  export const json: Category[] = [
    {
      category: DEVICE_SETTINGS_CATEGORY_GENERAL,
      settings: [
        {
          id: SETTINGS.PARTICIPANT_COUNT,
          type: "radio",
        },
        {
          id: SETTINGS.APP_BUTTON,
          type: "dropdown",
        },
        {
          id: SETTINGS.ANTI_FLICKER,
          type: "dropdown",
          mutateOptions: convertRangeOptionsToEnumeratedValues,
        },
        {
          id: DEVICE_SETTING_ID_ANSWER_IGNORE,
          type: "radio",
        },
        {
          id: DEVICE_SETTING_ID_ANSWERING_CALL_ALERT,
          type: "radio",
        },
        {
          id: DEVICE_SETTING_ID_AUDIO_CHANNEL_TONE,
          type: "radio",
        },
        {
          id: DEVICE_SETTING_ID_AUDIO_SENSING,
          type: "radio",
        },
        {
          id: DEVICE_SETTING_ID_CLOSE_CONVERSATION_LIMITING,
          type: "dropdown",
        },
        {
          id: DEVICE_SETTING_ID_CALLED_ID,
          type: "radio",
        },
        {
          id: DEVICE_SETTING_ID_MUTE_OFF_ALERT,
          type: "dropdown",
        },
        {
          id: DEVICE_SETTING_ID_MUTE_REMINDER_MODE,
          type: "dropdown",
        },
        {
          id: DEVICE_SETTING_ID_MUTE_REMINDER_TIMING,
          type: "dropdown",
        },
        {
          id: DEVICE_SETTING_ID_ONLINE_INDICATOR,
          type: "radio",
        },
        {
          id: DEVICE_SETTING_ID_AUTO_ANSWER_NO_SENSOR,
          type: "radio",
        },
        {
          id: DEVICE_SETTING_ID_DEFAULT_PHONE_LINE,
          type: "dropdown",
        },
        {
          id: DEVICE_SETTING_ID_SECOND_INCOMMING_CALL,
          type: "dropdown",
        },
        {
          id: DEVICE_SETTING_ID_TONE_CONTROL,
          type: "dropdown",
        },
        {
          id: DEVICE_SETTING_ID_MUTE_ALERTS,
          type: "radio",
        },
        {
          id: DEVICE_SETTING_ID_MUTE_ALERT_TYPES,
          type: "dropdown",
        },
        {
          id: DEVICE_SETTING_ID_NOTIFICATION_TONES,
          type: "radio",
        },
        {
          id: DEVICE_SETTING_ID_INCOMMING_CALL_ALERT,
          type: "radio",
        },
        {
          id: DEVICE_SETTING_ID_CONNECTION_INDICATION,
          type: "dropdown",
        },
        {
          id: DEVICE_SETTING_ID_HOLD_REMINDER,
          type: "radio",
        },
        {
          id: DEVICE_SETTING_ID_DAISY_CHAIN_AUDIO,
          type: "dropdown",
        },
        {
          id: DEVICE_SETTING_ID_BATTERY_LEVEL_PROMPT,
          type: "radio",
        },
        {
          id: DEVICE_SETTING_ID_MUTE_REMINDER_MODE_FOR_SPEAKER_PHONES,
          type: "dropdown",
        },
        {
          id: DEVICE_SETTING_ID_SPEAKER_TRACKING,
          type: "radio",
        },
        {
          id: DEVICE_SETTING_ID_SPEAKER_TRACKING_MODE,
          type: "dropdown",
        },
        {
          id: DEVICE_SETTING_ID_BLUETOOTH_PERMISSIONS,
          type: "radio",
        },
        {
          id: SETTINGS.DISPLAY_BRIGHTNESS,
          type: "up-down",
        },
        {
          id: SETTINGS.DISPLAY_CONTRAST,
          type: "slider",
        },
        {
          id: DEVICE_SETTING_ID_EQIALIZER,
          type: "dropdown",
        },
        {
          id: DEVICE_SETTING_ID_PC_AUDIO_PERSISTENCE_MODE,
          type: "radio",
        },
      ],
    },
    {
      category: DEVICE_SETTINGS_CATEGORY_VOICE_PROMPTS_AND_TONES,
      settings: [
        {
          id: DEVICE_SETTING_ID_MANAGE_ALL,
          type: "radio",
        },
        {
          id: DEVICE_SETTING_ID_CALLS_ANNOUNCEMENT,
          type: "radio",
          subsettings: [
            {
              id: DEVICE_SETTING_ID_INCOMMING_CALL,
              type: "radio",
            },
            {
              id: DEVICE_SETTING_ID_ANSWERING_CALL,
              type: "radio",
            },
            {
              id: DEVICE_SETTING_ID_ANSWER_IGNORE_2,
              type: "radio",
            },
            {
              id: DEVICE_SETTING_ID_AUDIO_CHANNEL_TONE,
              type: "radio",
            },
          ],
        },
        {
          id: DEVICE_SETTING_ID_CONNECTION_INDICATION,
          type: "radio",
        },
        {
          id: DEVICE_SETTING_ID_HOLD_REMINDER,
          type: "radio",
        },
      ],
    },
    {
      category: DEVICE_SETTINGS_CATEGORY_LIGHTS,
      settings: [
        {
          id: SETTINGS.STATUS_LED_BRIGHTNESS,
          type: "slider",
        },
        {
          id: SETTINGS.STATUS_LED_IDLE_STATE,
          type: "radio",
        },
        {
          id: SETTINGS.STATUS_LED_INCOMING_CALL,
          type: "radio",
        },
        {
          id: SETTINGS.STATUS_LED_ACTIVE_CALL,
          type: "radio",
        },
        {
          id: SETTINGS.STATUS_LED_HELD_CALL,
          type: "radio",
        },
        {
          id: SETTINGS.STATUS_LED_QI_CHARGING,
          type: "radio",
        },
      ],
    },
    {
      category: DEVICE_SETTINGS_CATEGORY_RINGTONE_AND_VOLUME,
      settings: [
        {
          id: SETTINGS.BASE_RINGER_VOLUME,
          type: "dropdown",
        },
        {
          id: DEVICE_SETTING_ID_DESKPHONE_TONE,
          type: "dropdown",
        },
        {
          id: DEVICE_SETTING_ID_DESKPHONE_VOLUME,
          type: "dropdown",
        },
        {
          id: DEVICE_SETTING_ID_MOBILE_PHONE,
          type: "dropdown",
        },
        {
          id: DEVICE_SETTING_ID_MOBILE_PHONE_VOLUME,
          type: "dropdown",
        },
        {
          id: DEVICE_SETTING_ID_PC,
          type: "dropdown",
        },
        {
          id: DEVICE_SETTING_ID_PC_VOLUME,
          type: "dropdown",
        },
        {
          id: DEVICE_SETTING_ID_CHARGE_CONFIRMATION,
          type: "radio",
        },
        {
          id: DEVICE_SETTING_ID_SIDETONE,
          type: "dropdown",
        },
        {
          id: DEVICE_SETTING_ID_VIBRATE_ON_RING,
          type: "radio",
        },
        {
          id: DEVICE_SETTING_ID_VOLUME_LEVEL_TONES,
          type: "dropdown",
        },
        {
          id: DEVICE_SETTING_ID_INDEPENDENT_VOLUME_CONTROL,
          type: "radio",
        },
        {
          id: DEVICE_SETTING_ID_ANC_TIMEOUT,
          type: "dropdown",
        },
        {
          id: DEVICE_SETTING_ID_MUTE_REMINDER_VOLUME,
          type: "dropdown",
        },
        {
          id: DEVICE_SETTING_ID_SYSTEM_TONE,
          type: "dropdown",
        },
        {
          id: DEVICE_SETTING_ID_WEARING_PREFERENCE_FOR_VOLUME_CONTROL,
          type: "dropdown",
        },
        {
          id: DEVICE_SETTING_ID_RINGTONE,
          type: "radio",
        },
        {
          id: DEVICE_SETTING_ID_VOLUME_MIN_MAX_ALERTS,
          type: "dropdown",
        },
      ],
    },
    {
      category: DEVICE_SETTINGS_CATEGORY_AUDIO,
      settings: [
        {
          id: SETTINGS.ACOUSTIC_FENCE,
          type: "dropdown",
          modalId: "ACOUSTIC_FENCE",
        },
        {
          id: SETTINGS.NOISEBLOCK_TX,
          type: "radio",
          modalId: "NOISEBLOCKAI",
        },
        {
          id: SETTINGS.NOISEBLOCK_RX,
          type: "radio",
          modalId: "NOISEBLOCKAI",
        },
        {
          id: SETTINGS.BASS_BOOST,
          type: "radio",
        },
        {
          id: SETTINGS.STEREO_AUDIO,
          type: "radio",
        },
        {
          id: DEVICE_SETTING_ID_AUDIO_BASS,
          type: "slider",
        },
        {
          id: DEVICE_SETTING_ID_AUDIO_TREBLE,
          type: "slider",
        },
      ],
    },
    {
      category: DEVICE_SETTINGS_CATEGORY_SENSOR_AND_PRESENCE,
      settings: [
        {
          id: DEVICE_SETTING_ID_WEARING_SENSOR_MASTER,
          type: "radio",
        },
        {
          id: DEVICE_SETTING_ID_ACTIVE_CALL_AUDIO,
          type: "dropdown",
        },
        {
          id: DEVICE_SETTING_ID_AUTO_ANSWER,
          type: "radio",
        },
        {
          id: DEVICE_SETTING_ID_AUTO_PAUSE_MUSIC,
          type: "radio",
        },
        {
          id: DEVICE_SETTING_ID_CALL_BUTTON_LOCK,
          type: "radio",
        },
        {
          id: DEVICE_SETTING_ID_SMART_AUDIO_TRANSFER,
          type: "radio",
        },
        {
          id: DEVICE_SETTING_ID_AUTO_MUTE,
          type: "radio",
        },
      ],
    },
    {
      category: DEVICE_SETTINGS_CATEGORY_SOFTPHONES_AND_MEDIA_PLAYERS,
      settings: [
        {
          id: DEVICE_SETTING_ID_DIAL_TONE,
          type: "radio",
        },
      ],
    },
    {
      category: DEVICE_SETTINGS_CATEGORY_WIRELESS,
      settings: [
        {
          id: DEVICE_SETTING_ID_AUDIO_QUALITY,
          type: "dropdown",
        },
        {
          id: DEVICE_SETTING_ID_EXTENDED_RANGE_MODE,
          type: "radio",
        },
        {
          id: DEVICE_SETTING_ID_HD_VOICE,
          type: "radio",
          valueRules: {
            true: "wideband",
            false: "narrowband",
          },
        },
        {
          id: DEVICE_SETTING_ID_AUTO_CONNECT_TO_MOBILE_PHONE,
          type: "radio",
        },
        {
          id: DEVICE_SETTING_ID_AUTO_CONNECT_TO_MOBILE_PHONE_2,
          type: "dropdown",
        },
        {
          id: DEVICE_SETTING_ID_BLUETOOTH,
          type: "radio",
        },
        {
          id: DEVICE_SETTING_ID_BLUETOOTH_2,
          type: "radio",
          valueRules: {
            true: "on",
            false: "off",
          },
        },
        {
          id: DEVICE_SETTING_ID_DESKPHONE_AUDIO_BANDWIDTH,
          type: "dropdown",
        },
        {
          id: DEVICE_SETTING_ID_PC_AUDIO_BANDWIDTH,
          type: "dropdown",
        },
        {
          id: DEVICE_SETTING_ID_MOBILE_VOICE_COMMANDS,
          type: "radio",
        },
        {
          id: DEVICE_SETTING_ID_OVER_THE_AIR_SUBSCRIPTION,
          type: "radio",
        },
        {
          id: DEVICE_SETTING_ID_RANGE,
          type: "dropdown",
        },
        {
          id: DEVICE_SETTING_ID_STREAMING_AUDIO,
          type: "radio",
        },
        {
          id: DEVICE_SETTING_ID_CLEAR_TRUSTED_DEVICE_LIST,
          type: "radio",
        },
        {
          id: DEVICE_SETTING_ID_SECURE_BLUETOOTH,
          type: "radio",
        },
        {
          id: "0x070A",
          type: "radio",
        },
        {
          id: "0x070B",
          type: "radio",
        },
      ],
    },
    {
      category: DEVICE_SETTINGS_CATEGORY_ADVANCED,
      settings: [
        {
          id: DEVICE_SETTING_ID_G616,
          type: "radio",
        },
        {
          id: DEVICE_SETTING_ID_ANTI_STARTLE,
          type: "radio",
        },
        {
          id: DEVICE_SETTING_ID_ANTI_STARTLE_2,
          type: "dropdown",
        },
        {
          id: DEVICE_SETTING_ID_NOISE_EXPOSURE,
          type: "dropdown",
        },
        {
          id: DEVICE_SETTING_ID_HOURS_ON_PHONE_PER_DAY,
          type: "dropdown",
        },
        {
          id: DEVICE_SETTING_ID_SELECT_HEADSET_TYPE,
          type: "dropdown",
        },
      ],
    },
    {
      category: DEVICE_SETTINGS_CATEGORY_ANC,
      settings: [
        {
          id: DEVICE_SETTING_ID_ANC_MODE,
          type: "dropdown",
        },
        {
          id: DEVICE_SETTING_ID_TRANSPARENCY_MODE,
          type: "dropdown",
        },
      ],
    },
    {
      category: "Apps",
      settings: [
        {
          id: "0x0904", // DEVICE_SETTING_ID_TILE
          type: "radio",
          appUrl: {
            ios_app: "tileapp://",
            ios_store:
              "https://itunes.apple.com/us/app/tile-find-lost-keys-phone/id664939913?mt\u003d8",
            android_app: "https://tile.app.link/plantronics_activation",
            android_store: "market://details?id\u003dcom.thetileapp.tile",
          },
        },
        {
          id: "0x0905", // DEVICE_SETTING_ID_ALEXA
          type: "radio",
          appUrl: {
            ios_app:
              "alexa://?fragment\u003dv2/accessory-settings/gateway?deviceid\u003d",
            ios_store:
              "https://itunes.apple.com/us/app/amazon-alexa/id944011620?mt\u003d8",
            android_app:
              "https://alexa.amazon.com/?fragment\u003dv2/accessory-settings/gateway?deviceid\u003d",
            android_store: "market://details?id\u003dcom.amazon.dee.app",
          },
        },
        {
          id: DEVICE_SETTING_ID_BISTO,
          type: "radio",
        },
      ],
    },
    {
      category: DEVICE_SETTINGS_CATEGORY_CUSTOM_BUTTON,
      settings: [
        {
          id: SETTINGS.ROCKET_BUTTON,
          type: "dropdown",
          // this is a way of the UI hiding options that hub-native may have available as options, but either don't yet work or will not be implemented
          // VPA and redial options will never be implemented @help https://jira.poly.com/browse/EN-203878?focusedCommentId=1259084&page=com.atlassian.jira.plugin.system.issuetabpanels%3Acomment-tabpanel#comment-1259084
          // lens is advanced functionality (requires an emit event from hub-native), may be implemented at some point:
          // @help https://jira.poly.com/browse/LENS-388?focusedCommentId=1231507&page=com.atlassian.jira.plugin.system.issuetabpanels%3Acomment-tabpanel#comment-1231507
          // videoMute will never be implemented @help https://jira.poly.com/browse/LENS-1552
          hiddenOptions: ["vpa", "redial", "lens", "videoMute"],
        },
        {
          id: DEVICE_SETTING_ID_ROCKET_BUTTON_BEHAVIOR,
          type: "dropdown",
          //sortAlpha: true,
        },
      ],
    },
    {
      category: DEVICE_SETTINGS_CATEGORY_CUSTOM_SWIPE,
      settings: [
        {
          id: DEVICE_SETTING_ID_CUSTOM_SLIDER_IDLE,
          type: "dropdown",
        },
        {
          id: DEVICE_SETTING_ID_CUSTOM_SLIDER_CALL,
          type: "dropdown",
        },
        {
          id: DEVICE_SETTING_ID_CUSTOM_SLIDER_MEDIA,
          type: "dropdown",
        },
      ],
    },
    {
      category: DEVICE_SETTINGS_CATEGORY_RESET_DEVICE,
      settings: [
        {
          id: DEVICE_SETTING_ID_RESTART_DEVICE,
          type: "restart-device",
        },
        {
          id: DEVICE_SETTING_ID_RESTORE_DEFAULTS,
          type: "restore-defaults",
        },
        {
          id: DEVICE_SETTING_ID_CLEAR_TRUSTED_DEVICES,
          type: "clear-trusted-devices",
        },
      ],
    },
    {
      category: DEVICE_SETTINGS_CATEGORY_DIAGNOSTICS,
      settings: [
        {
          id: SETTINGS.ON_SCREEN_DISPLAY,
          type: "radio",
        },
      ],
    },
  ];

  export const cameraControlsDependencies: SettingDependency[] = [
    {
      // when tracking mode is OFF, maximum zoom is disabled
      settingId: SETTINGS.MAXIMUM_ZOOM,
      action: "disable",
      when: [
        {
          settingId: SETTINGS.TRACKING_MODE,
          value: "off",
        },
      ],
    },
    {
      settingId: SETTINGS.CAMERA_MOVEMENT,
      action: "disable",
      when: [
        {
          settingId: SETTINGS.TRACKING_MODE,
          value: "off",
        },
      ],
    },
    {
      settingId: SETTINGS.TRACKING_SPEED,
      action: "disable",
      when: [
        {
          settingId: SETTINGS.TRACKING_MODE,
          value: "off",
        },
      ],
    },
    {
      settingId: SETTINGS.FRAME_SIZE,
      action: "disable",
      when: [
        {
          settingId: SETTINGS.TRACKING_MODE,
          value: "off",
        },
      ],
    },
  ];

  export const dependencies: SettingDependency[] = [
    {
      settingId: DEVICE_SETTING_ID_AUTO_ANSWER,
      action: "disable",
      when: [
        {
          settingId: DEVICE_SETTING_ID_WEARING_SENSOR_MASTER,
          value: "false",
        },
      ],
    },
    {
      settingId: DEVICE_SETTING_ID_SMART_AUDIO_TRANSFER,
      action: "disable",
      when: [
        {
          settingId: DEVICE_SETTING_ID_WEARING_SENSOR_MASTER,
          value: "false",
        },
      ],
    },
    {
      settingId: DEVICE_SETTING_ID_CALL_BUTTON_LOCK,
      action: "disable",
      when: [
        {
          settingId: DEVICE_SETTING_ID_WEARING_SENSOR_MASTER,
          value: "false",
        },
      ],
    },
    {
      settingId: DEVICE_SETTING_ID_AUTO_PAUSE_MUSIC,
      action: "disable",
      when: [
        {
          settingId: DEVICE_SETTING_ID_WEARING_SENSOR_MASTER,
          value: "false",
        },
      ],
    },
    {
      settingId: DEVICE_SETTING_ID_AUTO_MUTE,
      action: "disable",
      when: [
        {
          settingId: DEVICE_SETTING_ID_WEARING_SENSOR_MASTER,
          value: "false",
        },
      ],
    },
    {
      settingId: DEVICE_SETTING_ID_ACTIVE_CALL_AUDIO,
      action: "disable",
      when: [
        {
          settingId: DEVICE_SETTING_ID_WEARING_SENSOR_MASTER,
          value: "false",
        },
      ],
    },
    {
      settingId: DEVICE_SETTING_ID_HOURS_ON_PHONE_PER_DAY,
      action: "hide",
      when: [
        {
          settingId: DEVICE_SETTING_ID_NOISE_EXPOSURE,
          value: "off",
        },
      ],
    },
    {
      settingId: "0x0603",
      action: "hide",
      when: [
        {
          settingId: DEVICE_SETTING_ID_MUTE_REMINDER_MODE,
          value: "off",
        },
      ],
    },
    {
      settingId: "0x0603",
      action: "disable",
      when: [
        {
          settingId: DEVICE_SETTING_ID_MUTE_REMINDER_MODE,
          value: "voiceAudible",
        },
        {
          settingId: DEVICE_SETTING_ID_MUTE_REMINDER_MODE,
          value: "voiceVisible",
        },
        {
          settingId: DEVICE_SETTING_ID_MUTE_REMINDER_MODE,
          value: "voiceVisibleAndAudible",
        },
      ],
    },
    {
      settingId: DEVICE_SETTING_ID_MUTE_ALERT_TYPES,
      action: "disable",
      when: [
        {
          settingId: DEVICE_SETTING_ID_MUTE_ALERTS,
          value: "false",
        },
      ],
    },
    {
      settingId: DEVICE_SETTING_ID_CALLS_ANNOUNCEMENT,
      action: "disable",
      when: [
        {
          settingId: DEVICE_SETTING_ID_MANAGE_ALL,
          value: "false",
        },
      ],
    },
    {
      settingId: DEVICE_SETTING_ID_CONNECTION_INDICATION,
      action: "disable",
      when: [
        {
          settingId: DEVICE_SETTING_ID_MANAGE_ALL,
          value: "false",
        },
      ],
    },
    {
      settingId: DEVICE_SETTING_ID_HOLD_REMINDER,
      action: "disable",
      when: [
        {
          settingId: DEVICE_SETTING_ID_MANAGE_ALL,
          value: "false",
        },
      ],
    },
  ];

  export const mergedSettings: MergedSettings[] = [
    {
      parentSetting: DEVICE_SETTING_ID_MUTE_REMINDER_MODE,
      childSetting: "0x0603",
      // These PID checks are super temporary.
      // This is because "0x0603" is displayed differently for different devices.
      // Here, we exclude merging "0x0603" with other setting for Jaguar.
      excludePids: [
        +"0xAC27",
        +"0xAC28",
        +"0xAC29",
        +"0xAC2B",
        +"0xAC31",
        +"0xAC32",
        +"0xAC33",
        +"0xAC34",
        +"0xAC35",
        +"0xAC36",
        +"0xAC37",
        +"0xAC38",
        +"0xAC39",
        +"0xAC3A",
        +"0xAC3B",
        +"0xAB03",
        +"0xAB04",
        +"0xAB05",
        +"0xAB06",
        +"0xAB07",
        +"0xAB08",
        +"0xAB09",
        +"0xAB0A",
      ],
    },
  ];
}
