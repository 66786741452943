import { exec } from "shelljs";

export async function getUserOID(): Promise<string> {
  const path = "whoami /user";
  const res = new Promise<string>((resolve, reject) => {
    exec(path, { silent: false }, function (code, stdout, stderr) {
      if (code == 0 && stdout) {
        resolve(stdout);
      } else {
        reject();
      }
    });
  });
  const sid = await res;
  if (sid) {
    const parsedSid = sid
      .substring(sid.lastIndexOf(" S-"), sid.length)
      .replace(/(\r\n|\n|\r)/gm, "");
    const sidSplit = parsedSid.split("-");
    if (sidSplit.length > 7) {
      const sidUUID = [sidSplit[4], sidSplit[5], sidSplit[6], sidSplit[7]].join(
        "-"
      );
      return "auth0|" + decUUIDToHexUUID(sidUUID.toString());
    } else {
      return "";
    }
  } else {
    return "";
  }
}

export async function getUserMail(): Promise<string> {
  const path = "whoami /upn";
  const res = new Promise<string>((resolve, reject) => {
    exec(path, { silent: false }, function (code, stdout, stderr) {
      if (code == 0 && stdout) {
        resolve(stdout);
      } else {
        reject();
      }
    });
  });
  const userMail = await res;
  return userMail.trim();
}

export async function getUserName(): Promise<string> {
  const path = "echo %username%";
  const res = new Promise<string>((resolve, reject) => {
    exec(path, { silent: false }, function (code, stdout, stderr) {
      if (code == 0 && stdout) {
        resolve(stdout);
      } else {
        reject();
      }
    });
  });
  const userName = await res;
  return userName.trim();
}

export async function getFullName(): Promise<string[]> {
  const path = "whoami /fqdn";
  const res = new Promise<string>((resolve, reject) => {
    exec(path, { silent: false }, function (code, stdout, stderr) {
      if (code == 0 && stdout) {
        resolve(stdout);
      } else {
        resolve("");
      }
    });
  });
  const userName = await res;
  const parsedUserName = userName
    .substring(userName.indexOf("=") + 1, userName.indexOf(","))
    .split(" ");
  return parsedUserName;
}

function decUUIDToHexUUID(duuid: string) {
  let parts = duuid.split("-");
  let values = [];
  let hexValues = [];
  let hv = "";
  let hpLengths = [8, 8, 8, 8];
  for (let i = 0; i < parts.length; i++) {
    values[i] = parseInt(parts[i]);
    hexValues[i] = values[i].toString(16);
    let hvLen = hexValues[i].length;
    for (let j = 0; j < hpLengths[i] - hvLen; j++) {
      hexValues[i] = "0" + hexValues[i];
    }
    hv = hv + hexValues[i];
  }
  let hvparts = [
    hv.substring(0, 8),
    hv.substring(12, 16),
    hv.substring(8, 12),
    hv.substring(22, 24) + hv.substring(20, 22),
    hv.substring(18, 20) +
      hv.substring(16, 18) +
      hv.substring(30, 32) +
      hv.substring(28, 30) +
      hv.substring(26, 28) +
      hv.substring(24, 26),
  ];

  return (
    hvparts[0] +
    "-" +
    hvparts[1] +
    "-" +
    hvparts[2] +
    "-" +
    hvparts[3] +
    "-" +
    hvparts[4]
  );
}
