import { Component, HostListener, OnInit, Input } from "@angular/core";

/**
 * See styling in _cta.scss
 * @example oz-cta.bottom-right(size="4")
 */
@Component({
  selector: "oz-cta",
  templateUrl: "./cta.component.pug",
})
export class CtaComponent implements OnInit {
  public open = false;

  @Input("size") size = 5;

  @HostListener("document:click", ["$event"])
  close(e) {
    this.open = false;
  }

  constructor() {}

  ngOnInit(): void {}

  toggle($event) {
    this.open = !this.open;
    $event.stopPropagation();
  }
}
