import { Component } from "@angular/core";
import { AuthService } from "../services/auth.service";

@Component({
  selector: "oz-ftu",
  templateUrl: "./ftu.component.pug",
})
export class FtuComponent {
  constructor(private authService: AuthService) {}

  login() {
    this.authService.startLoginFlow("/home");
  }
}
