import { BehaviorSubject, Observable } from "rxjs";
import { map } from "rxjs/operators";

export class AuthProfile {
  is_new: boolean;
  info: {
    acceptedToS: Date;
    theme: "DARK" | "LIGHT";
    lastTenantId: string;
  };
  given_name: string;
  family_name: string;
  nickname: string;
  name: string;
  picture: string;
  updated_at: Date;
  email: string;
  iss: string;
  sub: string;
  aud: string;
  iat: number;
  exp: number;
  last_connected: Date|undefined;

  static build(obj: any): AuthProfile {
    const ap = new AuthProfile();
    const prefix = "https://lens.poly.com/";

    for (let key in obj) {
      const val = obj[key]; // Remember the object value - we may change the key below.
      if (key.indexOf(prefix) === 0) {
        // We just want the non-namespaced key
        key = key.substr(prefix.length);
      }
      ap[key] = val;
    }

    return ap;
  }
}

export interface IAuthService {
  startLoginFlow(redirectPath: string): void;
  isAuthenticated$: Observable<boolean>;
  accessToken$: Observable<string>;
  profile$: Observable<AuthProfile>;
  tenantToken$: Observable<string>;
  isLoginless$: Observable<boolean>;
  registerPreLogoutHandler(v: Function);
  startLogoutFlow(redirectPath: string): void;
  refreshTokens(): Promise<void>;
  hasRefreshToken(): boolean;
  setIsAuthenticated(isAuthenticated: boolean): void;
  setProfile(profile: AuthProfile): void;
  setTenantToken(token: string): void;
  getTenantToken(): void;
  handleCallback(url: URL): void;
}

export class AuthService implements IAuthService {
  private _accessToken$ = new BehaviorSubject<string>(null);
  private _isAuthenticated$ = new BehaviorSubject<boolean>(false);
  private _preLogoutHandlers: Array<Function> = [];
  private _profile$ = new BehaviorSubject<AuthProfile>(null);
  private _tenantToken$ = new BehaviorSubject<string>(null);
  public static hasRefreshTokenFlag = false;

  startLoginFlow(redirectPath: string): void {
    this._isAuthenticated$.next(true);
  }
  get isAuthenticated$(): Observable<boolean> {
    return this._isAuthenticated$.asObservable();
  }

  public setIsAuthenticated(isAuthenticated: boolean) {
    this._isAuthenticated$.next(isAuthenticated);
  }

  get accessToken$() {
    return this._accessToken$.asObservable();
  }

  get accessTokenValue() {
    return this._accessToken$.value;
  }

  get profile$() {
    return this._profile$.asObservable();
  }

  public setProfile(profile: AuthProfile) {
    this._profile$.next(profile);
  }

  get tenantToken$() {
    return this._tenantToken$.asObservable();
  }

  public setTenantToken(token: string) {
    this._tenantToken$.next(token);
  }

  get isLoginless$() {
    return this.tenantToken$.pipe(map((token) => !!token));
  }

  public getTenantToken(): void {}

  public registerPreLogoutHandler(v: Function) {
    this._preLogoutHandlers.push(v);
  }

  startLogoutFlow(redirectPath: string) {
    this._isAuthenticated$.next(false);
  }

  async refreshTokens(): Promise<void> {}

  hasRefreshToken() {
    return AuthService.hasRefreshTokenFlag;
  }

  handleCallback(url: URL) {
    console.debug("called AuthService.handleCallback " + url);
  }
}
