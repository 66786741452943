
export function hex(n: number) {
  return n.toString(16)
}

export function hex4l(n: number | undefined) {
  return undefined == n ? undefined : '0x' + n.toString(16)
}

export function buf2hex(buffer: ArrayBuffer, len = buffer.byteLength) {
  return (new Uint8Array(buffer, 0, len)).reduce((s, n) => s + n.toString(16).padStart(2, '0'), '')
}
