import { DetailNavService } from "../services/detail-nav.service";
import {
  DeviceManagerService,
  OzDevice,
} from "../services/device-manager.service";
import { Component, OnInit, OnDestroy } from "@angular/core";
import { ActivatedRoute, ParamMap, Router } from "@angular/router";
import { Observable, Subscription, combineLatest, of, from } from "rxjs";
import { SettingsUI, ValueRules } from "./settings-ui.model";
import { DeviceSetting } from "@poly/hub-native";
import { filter, shareReplay, switchMap } from "rxjs/operators";
import { hexEqual } from "../utils/utils";
import {
  DEVICE_FEATURES,
  DEVICE_SETTINGS_CATEGORY_ADMIN,
  DEVICE_SETTINGS_CATEGORY_DIAGNOSTICS,
  DEVICE_SETTINGS_CATEGORY_GENERAL,
  DEVICE_SETTINGS_CATEGORY_LANGUAGE,
  DEVICE_SETTINGS_CATEGORY_RESET_DEVICE,
  DEVICE_SETTINGS_CATEGORY_SENSOR_AND_PRESENCE,
  DEVICE_SETTINGS_CATEGORY_WIRELESS,
  DEVICE_SETTINGS_CATEGORY_SOFTWARE_SETTINGS,
  DEVICE_SETTING_ID_AUDIO_CHANNEL_TONE,
  DEVICE_SETTING_ID_CALLS_ANNOUNCEMENT,
  DEVICE_SETTING_ID_CONNECTION_INDICATION,
  DEVICE_SETTING_ID_HOLD_REMINDER,
  DEVICE_SETTINGS_CATEGORY_VOICE_PROMPTS_AND_TONES,
} from "../utils/constants";
import { AnimationOptions } from "ngx-lottie";
import { AnimationItem } from "lottie-web";
import {
  AccordionCategories,
  AccordionItems,
} from "../shared/components/accordion/accordion.component";
import { AccordionType } from "../shared/components/accordion/accordion.component";

// This type seems like it should be a merge / extension of the types in setting-ui.model
export interface DeviceSettingMetadata {
  device: OzDevice;
  id: string; // global device setting id
  options: any[]; // available options for this setting
  value: any; // current setting value
  type: SettingsUI.SettingControlType;
  sortAlpha?: boolean; // true if options localization needs to be sorted in alphabetical order
  status: string; // status of device setting
  confirmMessage?: string;
  completeTitle?: string;
  completeMessage?: string;
  completeMessageNoReplug?: string;
  settings$: Observable<[DeviceSetting[], DeviceSetting[]]>;
  valueRules?: ValueRules; // any specific rules for mapping values can be included here
  childSettingId?: string; // setting that should be displayed in the same accordion item
  // Caution from UX: disabled options can create a frustrating user experience, use appropriately
  disabledOptions?: Array<string>;
  hiddenOptions?: Array<string>;
  mutateOptions?: Function;
  modalId?: string;
  subsettings?: any[];
}

function createAccordionCategories(
  accordionCategories: AccordionCategories,
  deviceSettings: DeviceSetting[],
  device: OzDevice,
  settings$: Observable<[DeviceSetting[], DeviceSetting[]]>
) {
  // Add "Language" section only if device has language change feature
  if (device?.featureList.includes(DEVICE_FEATURES.LANGUAGE)) {
    // Add accordion item for a language change
    const langCat = accordionCategories.find(
      (cat) => cat.title === DEVICE_SETTINGS_CATEGORY_LANGUAGE
    );
    if (!langCat) {
      accordionCategories.push({
        title: DEVICE_SETTINGS_CATEGORY_LANGUAGE,
        items: [
          {
            type: AccordionType.deviceLanguage,
            data: { device },
          },
        ],
      });
    }
  }

  // Add "Admin" section only if device has admin settings feature
  if (device?.featureList.includes(DEVICE_FEATURES.ADMIN_SETTINGS)) {
    const adminCat = accordionCategories.find(
      (cat) => cat.title === DEVICE_SETTINGS_CATEGORY_ADMIN
    );
    if (!adminCat) {
      accordionCategories.push({
        title: DEVICE_SETTINGS_CATEGORY_ADMIN,
        items: [
          {
            type: AccordionType.deviceAdmin,
            data: { device },
          },
        ],
      });
    }
  }

  // Check if this device supports Calls setting
  const callsSetting = deviceSettings.find((deviceSetting) =>
    hexEqual(deviceSetting.id, DEVICE_SETTING_ID_CALLS_ANNOUNCEMENT)
  );

  // Iterate through all categories in SettingsUI.json
  for (const uiCategory of SettingsUI.json) {
    // In case device supports admin settings, skip "Reset Device" category,
    // all options from this category will go under "Admin" category.
    if (
      device?.featureList.includes(DEVICE_FEATURES.ADMIN_SETTINGS) &&
      uiCategory.category === DEVICE_SETTINGS_CATEGORY_RESET_DEVICE
    ) {
      continue;
    }

    // Show Voice Prompts & Tones category only if device supports Calls setting
    if (
      !callsSetting &&
      uiCategory.category === DEVICE_SETTINGS_CATEGORY_VOICE_PROMPTS_AND_TONES
    ) {
      continue;
    }

    let cat = accordionCategories.find(
      (cat) => cat.title === uiCategory.category
    );
    let items: AccordionItems = cat ? cat.items : [];
    let newCat = false;
    if (!cat) {
      newCat = true;
      cat = {
        title: uiCategory.category,
        items,
      };
    }

    // Iterate through all settings from the specific UI category
    for (let i = 0; i < uiCategory.settings.length; i++) {
      const uiSetting = uiCategory.settings[i];
      // Iterate through device settings
      for (const deviceSetting of deviceSettings) {
        // If match found and is not a child setting
        const mergedPair = SettingsUI.mergedSettings.find((s) =>
          hexEqual(deviceSetting.id, s.childSetting)
        );
        const excluded = mergedPair?.excludePids?.includes(device.pid);

        // If device has "Calls" setting, ignore "Active Audio Tone" in General category
        if (
          !!callsSetting &&
          hexEqual(deviceSetting.id, DEVICE_SETTING_ID_AUDIO_CHANNEL_TONE) &&
          uiCategory.category === DEVICE_SETTINGS_CATEGORY_GENERAL
        ) {
          continue;
        }

        // If device has "Calls" setting, ignore "Connection Indication" in General category
        if (
          !!callsSetting &&
          hexEqual(deviceSetting.id, DEVICE_SETTING_ID_CONNECTION_INDICATION) &&
          uiCategory.category === DEVICE_SETTINGS_CATEGORY_GENERAL
        ) {
          continue;
        }

        // If device has "Calls" setting, ignore "Hold Reminder" in General category
        if (
          !!callsSetting &&
          hexEqual(deviceSetting.id, DEVICE_SETTING_ID_HOLD_REMINDER) &&
          uiCategory.category === DEVICE_SETTINGS_CATEGORY_GENERAL
        ) {
          continue;
        }

        if (
          hexEqual(deviceSetting.id, uiSetting.id) &&
          (excluded || !mergedPair)
        ) {
          // Push this to be an item in the category
          let item = items.find((i) => hexEqual(i.data.id, deviceSetting.id));

          if (!item) {
            let mergedPair = SettingsUI.mergedSettings.find((s) =>
              hexEqual(s.parentSetting, deviceSetting.id)
            );

            // Push subsettings
            let subsettings = [];
            if (uiSetting.subsettings?.length) {
              for (let j = 0; j < uiSetting.subsettings.length; j++) {
                const uiSubsetting = uiSetting.subsettings[j];
                const deviceSubsetting = deviceSettings.find((setting) =>
                  hexEqual(uiSubsetting.id, setting.id)
                );
                subsettings.push({
                  device,
                  settings$,
                  childSettingId: null,
                  ...deviceSubsetting,
                  ...uiSubsetting,
                });
              }
            }

            item = {
              type: AccordionType.deviceSetting,
              data: {
                device,
                settings$,
                childSettingId: mergedPair?.childSetting,
                ...deviceSetting,
                ...uiSetting,
                subsettings,
              } as DeviceSettingMetadata,
            };
            items.push(item);
          } else {
            item.data.value = deviceSetting.value;
          }
        }
      }
    }

    // Add "Sensors And Presence" section only if device has quickDisconnect feature
    // This section is ordered where is intended per Settings UI model
    if (
      device?.featureList.includes(DEVICE_FEATURES.QUICK_DISCONNECT) &&
      uiCategory.category === DEVICE_SETTINGS_CATEGORY_SENSOR_AND_PRESENCE &&
      accordionCategories.find(
        (cat) => cat.title !== DEVICE_SETTINGS_CATEGORY_SENSOR_AND_PRESENCE
      )
    ) {
      let newCat = false;
      newCat = true;
      items = [
        {
          type: AccordionType.deviceQuickDisconnect,
          data: { device },
        },
      ];
      cat = {
        title: uiCategory.category,
        items: items,
      };
    }

    if (newCat && items.length > 0) {
      accordionCategories.push(cat);
    }

    if (
      device?.featureList.includes(DEVICE_FEATURES.KEEP_LINK_UP) &&
      uiCategory.category === DEVICE_SETTINGS_CATEGORY_WIRELESS
    ) {
      if (
        accordionCategories.find(
          (cat) => cat.title === DEVICE_SETTINGS_CATEGORY_WIRELESS
        )
      ) {
        // Wireless category exists in accordionCategories, just need to add keepLinkUp setting to it
        cat.items.push({
          type: AccordionType.keepLinkUp,
          data: { device },
        });
      } // Wireless category doesn't exist in accordionCategories, need to create Wireless category with keepLinkUp setting and to add it to the accordionCategories
      else {
        items = [
          {
            type: AccordionType.keepLinkUp,
            data: { device },
          },
        ];
        cat = {
          title: uiCategory.category,
          items: items,
        };
        accordionCategories.push(cat);
      }
    }
  }

  if (device?.featureList.includes(DEVICE_FEATURES.AUDIO_TEST)) {
    const audio = accordionCategories.find(
      (cat) => cat.title === DEVICE_SETTINGS_CATEGORY_DIAGNOSTICS
    );
    if (!audio) {
      accordionCategories.push({
        title: DEVICE_SETTINGS_CATEGORY_DIAGNOSTICS,
        items: [
          {
            type: AccordionType.audioTest,
            data: { device },
          },
        ],
      });
    } else {
      const audioTest = audio.items.find(
        (item) => item.type === AccordionType.audioTest
      );
      if (!audioTest) {
        audio.items.push({
          type: AccordionType.audioTest,
          data: { device },
        });
      }
    }
  }

  // Software settings
  if (device?.featureList.includes(DEVICE_FEATURES.PLAY_GREETING)) {
    // Add accordion item for a sw settings at specific index
    const swSettingsCat = accordionCategories.find(
      (cat) => cat.title === DEVICE_SETTINGS_CATEGORY_SOFTWARE_SETTINGS
    );
    if (!swSettingsCat) {
      const cat = {
        title: DEVICE_SETTINGS_CATEGORY_SOFTWARE_SETTINGS,
        items: [
          {
            type: AccordionType.softwareSettings,
            data: {},
          },
        ],
      };
      accordionCategories.splice(1, 0, cat);
    }
  }

  // Return composed categories (which are ready for accordion)
  return accordionCategories;
}

@Component({
  templateUrl: "./device-settings.component.pug",
})
export class DeviceSettingsComponent implements OnInit, OnDestroy {
  private sub: Subscription;
  device: OzDevice;
  categories: AccordionCategories = [];
  parentCategories: AccordionCategories = [];
  parentDevice: OzDevice;
  loading = true;

  options: AnimationOptions = {
    path: "assets/lottie/ReceivingSettings.json",
  };

  constructor(
    private route: ActivatedRoute,
    private deviceManager: DeviceManagerService,
    private router: Router,
    private detailNavService: DetailNavService
  ) {}

  onAccordionChanged(ev: AccordionCategories) {
    this.categories = ev;
  }

  onAccordionChangedParentDevice(evp: AccordionCategories) {
    this.parentCategories = evp;
  }

  animationCreated(animationItem: AnimationItem) {
    animationItem.setSubframe(false);
  }

  scroll(scrollToEl: HTMLElement) {
    const el = document.getElementsByClassName("scrollable-content")[0];
    el.scrollTo({
      top: scrollToEl.offsetTop - 200,
      behavior: "smooth",
    });
  }

  ngOnInit() {
    this.detailNavService.configure({ showNav: true });

    const settings$ = this.route.parent.paramMap.pipe(
      switchMap((paramMap: ParamMap) => {
        return this.deviceManager.getDevice(paramMap.get("id")); // "id" is Device#uniqueId
      }),
      filter((device) => !!device),
      switchMap((device) => {
        if (!device.isConnected) {
          this.router.navigate(["../overview"], { relativeTo: this.route });
          return from([null]);
        }
        this.device = device;

        const parentDevice = this.device.parent;

        return this.deviceManager.getDevice(
          parentDevice ? parentDevice.uniqueId : null
        );
      }),
      switchMap((parentDevice) => {
        this.parentDevice = parentDevice;
        return combineLatest([
          this.deviceManager.getDeviceSettings(this.device.id),
          this.device.parentDeviceId
            ? this.deviceManager.getDeviceSettings(this.device.parentDeviceId)
            : of<DeviceSetting[]>([]),
        ]);
      }),
      shareReplay(1)
    );

    this.sub = settings$.subscribe(([deviceSettings, parentSettings]) => {
      this.categories = createAccordionCategories(
        this.categories,
        deviceSettings,
        this.device,
        settings$
      );

      this.parentCategories = createAccordionCategories(
        this.parentCategories,
        parentSettings,
        this.parentDevice,
        settings$
      );

      this.loading = false;
    });
  }

  ngOnDestroy() {
    this.sub?.unsubscribe();
  }
}
