import { Component } from "@angular/core";
import { Toasts, Toast } from "./toasts.service";

@Component({
  selector: "oz-toasts",
  templateUrl: "./toasts.component.pug",
})
export class ToastsComponent {
  list: Toast[];
  constructor(private toasts: Toasts) {
    this.toasts.changes.subscribe((toasts) => {
      this.list = toasts;
    });
  }
}
