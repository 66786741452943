import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { MS_IN_SECOND } from "../../../../utils/constants";
import {
  Toast,
  Toasts,
} from "../../../../shared/components/toast/toasts.service";

@Component({
  selector: "oz-status-toast",
  templateUrl: "./status-toast.component.pug",
})
export class StatusToastComponent implements OnInit {
  @Input()
  options: Toast;

  @Output()
  close = new EventEmitter<void>();

  constructor(private toasts: Toasts) {}

  ngOnInit() {
    // Status toasts are dismissed after 3 seconds by default.
    // Never dismiss if dismissAfter is set to 0
    if (this.options.dismissAfter !== 0) {
      setTimeout(() => {
        this.dismiss();
      }, (this.options.dismissAfter ?? 3) * MS_IN_SECOND);
    }
  }

  onClose() {
    this.dismiss();
  }

  dismiss() {
    this.toasts.dismiss(this.options.id);
  }
}
