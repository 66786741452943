import { DeviceResourceService } from "../services/device-resource.service";
import { UtilityService } from "../services/utility.service";
import { DetailNavService } from "../services/detail-nav.service";
import {
  DeviceManagerService,
  OzDevice,
} from "../services/device-manager.service";
import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, ParamMap } from "@angular/router";
import { Device } from "@poly/hub-native";
import { filter, switchMap, map } from "rxjs/operators";
import { of, Subscription } from "rxjs";
import { RepositoryDeviceResources } from "../services/repository/model";
import { AdminConfig } from "../services/admin-config.service";

interface TileContent {
  title: string;
  icon: string;
  link?: string;
  text?: string;
}

@Component({
  selector: "support",
  templateUrl: "./support.component.pug",
})
export class SupportComponent implements OnInit {
  device: Device;
  parentDevice: Device;
  public supportItems: TileContent[];
  public deviceResources: RepositoryDeviceResources;
  public parentResources: RepositoryDeviceResources;
  private paramMapSub: Subscription;

  constructor(
    private deviceManager: DeviceManagerService,
    private route: ActivatedRoute,
    private detailNavService: DetailNavService,
    private deviceResourceService: DeviceResourceService,
    public adminConfig: AdminConfig // used in PUG
  ) {}

  ngOnInit() {
    this.detailNavService.configure({ showNav: true });
    this.paramMapSub = this.route.parent.paramMap
      .pipe(
        switchMap((paramMap: ParamMap) => {
          return this.deviceManager.getDevice(paramMap.get("id")); // "id" is Device#uniqueId
        }),
        filter((d) => !!d),
        // get the device docs based on the PID
        switchMap((device: OzDevice) => {
          this.device = device;
          this.parentDevice = device.parent;
          return this.deviceResourceService.getDeviceResources(this.device.pid);
        }),
        // Include the parent device docs, if there are any
        switchMap((doc) => {
          if (!this.parentDevice?.pid) {
            return of({ device: doc, parent: undefined });
          }

          return this.deviceResourceService
            .getDeviceResources(this.parentDevice?.pid)
            .pipe(map((parentDocs) => ({ device: doc, parent: parentDocs })));
        })
      )
      .subscribe((resources) => {
        this.deviceResources = resources.device;
        this.parentResources = resources.parent;
      });
  }

  handleOpenBrowser(url: string) {
    if (this.adminConfig.mode !== "network") {
      UtilityService.openExternalBrowser(url);
    }
  }

  ngOnDestroy(): void {
    this.paramMapSub.unsubscribe();
  }
}
