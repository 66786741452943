import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";

@Component({
  selector: "oz-checkbox",
  templateUrl: "./checkbox.component.pug",
})
export class CheckboxComponent implements OnInit {
  isChecked: boolean;

  @Input() set value(newValue: boolean) {
    this.isChecked = newValue;
  }

  @Input() isDisabled?: boolean;

  @Input("checkboxId") checkboxId: string;

  @Output() valueChanged = new EventEmitter<boolean>();

  constructor() {}

  ngOnInit(): void {
    if (this.checkboxId === undefined || this.checkboxId === null) {
      throw new Error("Must provide checkboxId to checkbox component.");
    }
  }

  onChange(ev) {
    this.valueChanged.emit(ev.target.checked);
  }
}
