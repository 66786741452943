export const EULA_AGREED_KEY = "EULA_AGREED";
export const EULA_URL = "https://www.hp.com/go/sweula";
export const EULA_VIDEO_DEVICES_URL = "https://support.polycom.com/content/support/polycom-agreements.html";
export const PRIVACY_POLICY_URL = "https://www.hp.com/us-en/privacy/privacy.html?jumpid=in_R11928_/us/en/corp/privacy-central/privacy-statements";
export const TERMS_OF_SERVICE = "https://www.poly.com/us/en/legal/terms/lens-supplemental-service-terms";
export const FEEDBACK_FORM_URL = "https://lens.poly.com/appfeedback";

/**
 * Device Settings: Global Device Settings IDs
 */
export const DEVICE_SETTING_ID_RESTORE_DEFAULTS = "0x90a";
export const DEVICE_SETTING_ID_MUTE_ALERTS_ENABLED = "0x0610";
export const DEVICE_SETTING_ID_PAIRING_MODE = "0x709";
export const DEVICE_SETTING_ID_RESTART_DEVICE = "0x090c";
export const DEVICE_SETTING_ID_ANSWER_IGNORE = "0x0600";
export const DEVICE_SETTING_ID_ANSWERING_CALL_ALERT = "0x0601";
export const DEVICE_SETTING_ID_AUDIO_CHANNEL_TONE = "0x060C";
export const DEVICE_SETTING_ID_AUDIO_SENSING = "0x0A00";
export const DEVICE_SETTING_ID_CLOSE_CONVERSATION_LIMITING = "0x0802";
export const DEVICE_SETTING_ID_CALLED_ID = "0x060A";
export const DEVICE_SETTING_ID_MUTE_OFF_ALERT = "0x0602";
export const DEVICE_SETTING_ID_MUTE_REMINDER_MODE = "0x060D";
export const DEVICE_SETTING_ID_MUTE_REMINDER_TIMING = "0x603";
export const DEVICE_SETTING_ID_ONLINE_INDICATOR = "0x0902";
export const DEVICE_SETTING_ID_AUTO_ANSWER_NO_SENSOR = "0x0300";
export const DEVICE_SETTING_ID_DEFAULT_PHONE_LINE = "0x0400";
export const DEVICE_SETTING_ID_SECOND_INCOMMING_CALL = "0x0102";
export const DEVICE_SETTING_ID_SELECT_HEADSET_TYPE = "0x0800";
export const DEVICE_SETTING_ID_TONE_CONTROL = "0x0801";
export const DEVICE_SETTING_ID_MUTE_ALERTS = "0x0610";
export const DEVICE_SETTING_ID_MUTE_ALERT_TYPES = "0x0607";
export const DEVICE_SETTING_ID_NOTIFICATION_TONES = "0x0805";
export const DEVICE_SETTING_ID_INCOMMING_CALL_ALERT = "0x0B05";
export const DEVICE_SETTING_ID_HOLD_REMINDER = "0x0913";
export const DEVICE_SETTING_ID_CONNECTION_INDICATION = "0x060F";
export const DEVICE_SETTING_ID_ROCKET_BUTTON_BEHAVIOR = "0x0908";
export const DEVICE_SETTING_ID_DAISY_CHAIN_AUDIO = "0x0807";
export const DEVICE_SETTING_ID_BATTERY_LEVEL_PROMPT = "0x060E";
export const DEVICE_SETTING_ID_MUTE_REMINDER_MODE_FOR_SPEAKER_PHONES = "0x0611";
export const DEVICE_SETTING_ID_SPEAKER_TRACKING = "0x0809";
export const DEVICE_SETTING_ID_SPEAKER_TRACKING_MODE = "0x080A";
export const DEVICE_SETTING_ID_BLUETOOTH_PERMISSIONS = "0x080B";
export const DEVICE_SETTING_ID_EQIALIZER = "0x0808";
export const DEVICE_SETTING_ID_DESKPHONE_TONE = "0x0107";
export const DEVICE_SETTING_ID_DESKPHONE_VOLUME = "0x0104";
export const DEVICE_SETTING_ID_MOBILE_PHONE = "0x0108";
export const DEVICE_SETTING_ID_MOBILE_PHONE_VOLUME = "0x0105";
export const DEVICE_SETTING_ID_PC = "0x0106";
export const DEVICE_SETTING_ID_PC_VOLUME = "0x0103";
export const DEVICE_SETTING_ID_CHARGE_CONFIRMATION = "0x0901";
export const DEVICE_SETTING_ID_SIDETONE = "0x0803";
export const DEVICE_SETTING_ID_VIBRATE_ON_RING = "0x0900";
export const DEVICE_SETTING_ID_VOLUME_LEVEL_TONES = "0x0609";
export const DEVICE_SETTING_ID_INDEPENDENT_VOLUME_CONTROL = "0x0806";
export const DEVICE_SETTING_ID_ANC_TIMEOUT = "0x0804";
export const DEVICE_SETTING_ID_TRANSPARENCY_MODE = "0x1500";
export const DEVICE_SETTING_ID_CUSTOM_SLIDER_IDLE = "0x1800";
export const DEVICE_SETTING_ID_CUSTOM_SLIDER_CALL = "0x1801";
export const DEVICE_SETTING_ID_CUSTOM_SLIDER_MEDIA = "0x1802";
export const DEVICE_SETTING_ID_MUTE_REMINDER_VOLUME = "0x0604";
export const DEVICE_SETTING_ID_SYSTEM_TONE = "0x0608";
export const DEVICE_SETTING_ID_WEARING_PREFERENCE_FOR_VOLUME_CONTROL = "0x0903";
export const DEVICE_SETTING_ID_RINGTONE = "0x0109";
export const DEVICE_SETTING_ID_VOLUME_MIN_MAX_ALERTS = "0x0B06";
export const DEVICE_SETTING_ID_AUDIO_BASS = "0x0C21";
export const DEVICE_SETTING_ID_AUDIO_TREBLE = "0x0C22";
export const DEVICE_SETTING_ID_WEARING_SENSOR_MASTER = "0x0200";
export const DEVICE_SETTING_ID_ACTIVE_CALL_AUDIO = "0x0206";
export const DEVICE_SETTING_ID_AUTO_ANSWER = "0x0201";
export const DEVICE_SETTING_ID_AUTO_PAUSE_MUSIC = "0x0204";
export const DEVICE_SETTING_ID_CALL_BUTTON_LOCK = "0x0203";
export const DEVICE_SETTING_ID_SMART_AUDIO_TRANSFER = "0x0202";
export const DEVICE_SETTING_ID_AUTO_MUTE = "0x0205";
export const DEVICE_SETTING_ID_DIAL_TONE = "0x0A01";
export const DEVICE_SETTING_ID_AUDIO_QUALITY = "0x0700";
export const DEVICE_SETTING_ID_EXTENDED_RANGE_MODE = "0x0705";
export const DEVICE_SETTING_ID_HD_VOICE = "0x0708";
export const DEVICE_SETTING_ID_AUTO_CONNECT_TO_MOBILE_PHONE = "0x0301";
export const DEVICE_SETTING_ID_AUTO_CONNECT_TO_MOBILE_PHONE_2 = "0xFFF1";
export const DEVICE_SETTING_ID_BLUETOOTH = "0x0401";
export const DEVICE_SETTING_ID_BLUETOOTH_2 = "0x080C";
export const DEVICE_SETTING_ID_DESKPHONE_AUDIO_BANDWIDTH = "0x0707";
export const DEVICE_SETTING_ID_PC_AUDIO_BANDWIDTH = "0x0706";
export const DEVICE_SETTING_ID_MOBILE_VOICE_COMMANDS = "0x0402";
export const DEVICE_SETTING_ID_OVER_THE_AIR_SUBSCRIPTION = "0x0701";
export const DEVICE_SETTING_ID_RANGE = "0x0702";
export const DEVICE_SETTING_ID_STREAMING_AUDIO = "0x0704";
export const DEVICE_SETTING_ID_CLEAR_TRUSTED_DEVICE_LIST = "0x0909";
export const DEVICE_SETTING_ID_SECURE_BLUETOOTH = "0x0907";
export const DEVICE_SETTING_ID_G616 = "0x0505";
export const DEVICE_SETTING_ID_ANTI_STARTLE = "0x0504";
export const DEVICE_SETTING_ID_ANTI_STARTLE_2 = "0xFFF2";
export const DEVICE_SETTING_ID_NOISE_EXPOSURE = "0x0500";
export const DEVICE_SETTING_ID_HOURS_ON_PHONE_PER_DAY = "0x0501";
export const DEVICE_SETTING_ID_ANC_MODE = "0x0810";
export const DEVICE_SETTING_ID_BISTO = "0x0906";
export const DEVICE_SETTING_ID_CLEAR_TRUSTED_DEVICES = "0x0848";
export const DEVICE_SETTING_ID_PC_AUDIO_PERSISTENCE_MODE = "0x0910";
export const DEVICE_SETTING_ID_MANAGE_ALL = "0x1300";
export const DEVICE_SETTING_ID_CALLS_ANNOUNCEMENT = "0x1301";
export const DEVICE_SETTING_ID_INCOMMING_CALL = "0x1302";
export const DEVICE_SETTING_ID_ANSWERING_CALL = "0x1305";
export const DEVICE_SETTING_ID_ANSWER_IGNORE_2 = "0x1303";
export const DEVICE_SETTING_ID_CONNECTION = "0x1304";

/**
 * Device Settings: Categories
 */
export const DEVICE_SETTINGS_CATEGORY_LANGUAGE = "Language";
export const DEVICE_SETTINGS_CATEGORY_ADMIN = "Admin";
export const DEVICE_SETTINGS_CATEGORY_RESET_DEVICE = "ResetHeadset";
export const DEVICE_SETTINGS_CATEGORY_GENERAL = "General";
export const DEVICE_SETTINGS_CATEGORY_LIGHTS = "Lights";
export const DEVICE_SETTINGS_CATEGORY_RINGTONE_AND_VOLUME = "RingtoneAndVolume";
export const DEVICE_SETTINGS_CATEGORY_AUDIO = "Audio";
export const DEVICE_SETTINGS_CATEGORY_SENSOR_AND_PRESENCE = "SensorAndPresence";
export const DEVICE_SETTINGS_CATEGORY_SOFTPHONES_AND_MEDIA_PLAYERS =
  "SoftphonesAndMediaPlayers";
export const DEVICE_SETTINGS_CATEGORY_WIRELESS = "Wireless";
export const DEVICE_SETTINGS_CATEGORY_ADVANCED = "Advanced";
export const DEVICE_SETTINGS_CATEGORY_ANC = "ANC";
export const DEVICE_SETTINGS_CATEGORY_CUSTOM_BUTTON = "CustomButton";
export const DEVICE_SETTINGS_CATEGORY_CUSTOM_SWIPE = "CustomSwipe";
export const DEVICE_SETTINGS_CATEGORY_DIAGNOSTICS = "Diagnostics";
export const DEVICE_SETTINGS_CATEGORY_SOFTWARE_SETTINGS = "SoftwareSettings";
export const DEVICE_SETTINGS_CATEGORY_VOICE_PROMPTS_AND_TONES =
  "VoicePromptsAndTones";

// this object is organized with an uppercase key, which assists
// with logic in controls.component.ts for specific settings
// if changing to lowercase, please review all uses of SETTINGS,
// CONTROLS_PAGE_SUPPORTED_SETTINGS and CONTROLS_PAGE_HIDDEN_SETTINGS

// SETTINGS enum tracks settings used on the control and settings pages.
// **IMPORTANT** Do **NOT** comment items in the SETTINGS enum out:
// Unsupported SETTINGS on the controls page are indicated so by not including
// in CONTROLS_PAGE_SUPPORTED_SETTINGS.
export enum SETTINGS {
  // CAUTION: this is the wrong hex code for P21 audio endpoint, do not enable until resolved
  // "AUDIO_ENDPOINT" = "0xC8C",
  "ACOUSTIC_FENCE" = "0xC15",
  "ANTI_FLICKER" = "0xC23",
  "ANTI_STARTLE" = "0x0504",
  "ANTI_STARTLE_ENHANCED" = "0xFFF2",
  "BASS_ADJUST" = "0xC21",
  "BASS_BOOST" = "0xC26",
  "BACK_LIGHT_COMP" = "0xC08",
  "BRIGHTNESS" = "0xC00",
  "CAMERA_MOVEMENT" = "0xC1C",
  "COLOR" = "0xC06",
  "COLOR_TEMPERATURE" = "0xC28",
  "CONTRAST" = "0xC01",
  "DISPLAY_SCREEN_BRIGHTNESS" = "0xC80",
  "DISPLAY_SCREEN_CONTRAST" = "0xC81",
  "EXPOSURE" = "0xC0E",
  "FOCUS" = "0xC10",
  "FRAME_SIZE" = "0xC1A",
  "G616_ANTI_STARTLE" = "0x0505",
  "GAIN" = "0xC09",
  "GAMMA" = "0xC05",
  "ROCKET_BUTTON" = "0xC8E",
  "HUE" = "0xC02",
  "IRIS" = "0xC0F",
  "INVERT" = "0xC27",
  "MAXIMUM_ZOOM" = "0xC1B",
  "NOISEBLOCK_TX" = "0xC16",
  "NOISEBLOCK_RX" = "0xC24",
  "ON_SCREEN_DISPLAY" = "0xC14",
  "PAN" = "0xC0A",
  "PARTICIPANT_COUNT" = "0xC13",
  "APP_BUTTON" = "0xC8D",
  "SATURATION" = "0xC03",
  "SHARPNESS" = "0xC04",
  "STATUS_LED_BRIGHTNESS" = "0xC82",
  "STATUS_LED_IDLE_STATE" = "0xC84",
  "STATUS_LED_INCOMING_CALL" = "0xC85",
  "STATUS_LED_ACTIVE_CALL" = "0xC86",
  "STATUS_LED_HELD_CALL" = "0xC87",
  "STATUS_LED_QI_CHARGING" = "0xC89",
  "STEREO_AUDIO" = "0xC17",
  "TILT" = "0xC0B",
  "TRACKING_MODE" = "0xC19",
  "TRACKING_SPEED" = "0xC18",
  "TREBLE_ADJUST" = "0xC22",
  "VANITY_LED_LEFT" = "0xC8F",
  "VANITY_LED_RIGHT" = "0xC83",
  "VANITY_LED_MANUAL" = "0xC8B", // allows independent vanity control
  "AMBIENT_LIGHT_SENSOR" = "0xC8A", // automatic adjustment based on ambient conditions
  "WHITE_BALANCE" = "0xC07",
  "ZOOM" = "0xC0D",
  "DISPLAY_BRIGHTNESS" = "0x0C90",
  "DISPLAY_CONTRAST" = "0x0C81",
  "BASE_RINGER_VOLUME" = "0x010A",
  "WIDE_DYNAMIC_RANGE" = "0x0C12",
}

// This array defines the settings and their order on the controls page
export const CONTROLS_PAGE_SUPPORTED_SETTINGS: Array<string> = [
  SETTINGS.COLOR_TEMPERATURE,
  SETTINGS.BRIGHTNESS,
  SETTINGS.CONTRAST,
  SETTINGS.HUE,
  SETTINGS.SATURATION,
  SETTINGS.SHARPNESS,
  SETTINGS.GAMMA,
  SETTINGS.WHITE_BALANCE,
  SETTINGS.COLOR,
  SETTINGS.INVERT,
  SETTINGS.BACK_LIGHT_COMP,
  SETTINGS.GAIN,
  SETTINGS.FOCUS,
  SETTINGS.EXPOSURE,
  SETTINGS.WIDE_DYNAMIC_RANGE,
  SETTINGS.IRIS,
  SETTINGS.TRACKING_MODE,
  SETTINGS.MAXIMUM_ZOOM,
  SETTINGS.CAMERA_MOVEMENT,
  SETTINGS.TRACKING_SPEED,
  SETTINGS.FRAME_SIZE,

  // these are supported, but hidden due to camera PTZ controls, so placed at end
  SETTINGS.PAN,
  SETTINGS.TILT,
  SETTINGS.ZOOM,
  SETTINGS.VANITY_LED_LEFT,
  SETTINGS.VANITY_LED_RIGHT,
  SETTINGS.VANITY_LED_MANUAL,
  SETTINGS.AMBIENT_LIGHT_SENSOR,
];

// supported settings, but settings that should not display in UI,
// typically because controls are already implemented in a different way
export const CONTROLS_PAGE_HIDDEN_SETTINGS: Array<string> = [
  SETTINGS.PAN,
  SETTINGS.TILT,
  SETTINGS.ZOOM,
  SETTINGS.VANITY_LED_LEFT,
  SETTINGS.VANITY_LED_RIGHT,
  SETTINGS.VANITY_LED_MANUAL,
  SETTINGS.AMBIENT_LIGHT_SENSOR,
];

export const LANGUAGES = [
  "en",
  "en-GB",
  "fr",
  "fr-ca",
  "ja",
  "da", // Danish
  "de", // German
  "fi", // Finnish
  "it", // Italian
  "nl", // Dutch
  "pl", // Polish
  "zh-cn", // Chinese (China)
  "zh-tw", // Chinese (Taiwan)
];

// Looking for language name mapping? See the en.json LANGUAGES key
export const EXPERIMENTAL_LANGUAGES = [
  "es-xl", // Spanish used in Latin America
  "es-es",
  // "hu",
  "in",
  "ko",
  "pt-pt",
  "pt-br",
  "nb",
  "sv",
  "ru-ru",
  "th",
];
export const DEFAULT_LANGUAGE = "en";

/* Storage items: */
export const LAST_DEVICE_INFO_STATE_SENDING = "LAST_DEVICE_INFO_STATE_SENDING";
export const IS_AUTHENTICATED = "IS_AUTHENTICATED";
export const IOT_DEVICE_ID = "IOT_DEVICE_ID";
export const HISTORICAL_DEVICES_KEY = "DM_HIST_DEVICES";
export const DEVICES_LAST_SEEN_KEY = "DEVICES_LAST_SEEN";
export const AUTH_RESOLVED_SESSION_KEY = "auth-resolved";
export const P21_DISPLAY_LINK_TOASTS = "P21_DISPLAY_LINK_TOASTS";
export const REGISTERED_DEVICES = "REGISTERED_DEVICES";

/* Time constants: */
export const MS_IN_SECOND = 1000;
export const MS_IN_MINUTE = 60 * MS_IN_SECOND;
export const MS_IN_HOUR = 60 * MS_IN_MINUTE;
export const MS_IN_DAY = 24 * MS_IN_HOUR;

export const BAD_DEVICE_IDS: Array<string> = ["000000000"];

export const POLY_LENS_DESKTOP = "Poly Lens Desktop";
export const POLY_LENS_PWA = "Poly Lens Web App";

/* Route related: */
export const ROUTE_HOME = "home";
export const ROUTE_SETTINGS = "settings";
export const ROUTE_CONTROLS = "controls";

export const OVERVIEW_PATH = "overview";
export const USB_ADAPTER_PATH = "usb_adapter";
export const CHARGING_CASE_PATH = "charging_case";

export const COLOR_SUCCESS = "#26870d";
export const COLOR_DANGER = "#e91a42";
export const COLOR_WARNING = "#ebcc60";

/* Battery Levels: */
/**
 * In plain English:
 * 0 means the battery is empty
 * 1..3 indicate a level of battery between empty and full
 * 4 means the battery is full
 * 5 means the battery is unknown
 *
 * For code purposes, this is the mapping
 * 0 -> battery = 0% (empty)
 * 1 -> battery ~ 25%
 * 2 -> battery ~ 50%
 * 3 -> battery ~ 75%
 * 4 -> battery = 100% (full)
 * 5 -> battery status is unknown
 */
export const BATTERY = {
  PERCENT_0: 0,
  PERCENT_25: 1,
  PERCENT_50: 2,
  PERCENT_75: 3,
  PERCENT_100: 4,
  UNKNOWN: 5,
};

export const BATTERY_WITH_CRITICAL_VALUE = {
  PERCENT_0: 0,
  BATTERY_CRITICAL: 1,
  PERCENT_25: 2,
  PERCENT_50: 3,
  PERCENT_75: 4,
  PERCENT_100: 5,
  UNKNOWN: 6,
};

export const DevicePID = {
  P5: 37526,
  P21: 17178,
};

// used to determine if a device has built-in noiseblockai.
// NOTE: acoustic fence specifically does NOT count.
export const NOISEBLOCK_SETTINGS = [
  SETTINGS.NOISEBLOCK_RX,
  SETTINGS.NOISEBLOCK_TX,
];

export const SETTINGS_MODALS = {
  ACOUSTIC_FENCE: {
    linkText: "MODALS.ACOUSTIC_FENCE.LINK_TEXT",
    content: "acousticfence",
  },
  NOISEBLOCKAI: {
    linkText: "MODALS.NOISEBLOCKAI.LINK_TEXT",
    content: "noiseblockai",
  },
};

// MouseEvent.button mappings
// @help https://developer.mozilla.org/en-US/docs/Web/API/MouseEvent/button
export const MOUSE_BUTTON = {
  LEFT: 0, // aka main button; is right-most button on a left-handed mouse
  MIDDLE: 1, // auxiliary button such as wheel or middle button
  RIGHT: 2, // aka secondary button; is left-most button on a left-handed mouse
  BROWSER_BACK: 3,
  BROWSER_FORWARD: 4,
};

// test related
export const MAX_TEST_RUNNER_DEVICE_INFO_STATE_CALLS_BY_JOB = 10;

// If a wifi signal is weaker than 33, it means that the signal is weak
export const WIFI_WEAK_SIGNAL = 33;
// If a wifi signal is stronger than 66, it means that the signal is strong
export const WIFI_STRONG_SIGNAL = 66;

export const SETTING_ID_MAP: { [id: number]: string } = {
  0x1: "com.poly.general.language",
  0x102: "com.poly.call_control.second_incoming_call",
  0x103: "com.poly.call_control.computer_volume",
  0x104: "com.poly.call_control.desk_phone_volume",
  0x105: "com.poly.call_control.mobile_phone_volume",
  0x106: "com.poly.call_control.computer_ringtone",
  0x107: "com.poly.call_control.desk_phone_ringtone",
  0x108: "com.poly.call_control.mobile_phone_ringtone",
  0x109: "com.poly.call_control.default_ringtone",
  0x200: "com.poly.general.sensor_settings_enabled",
  0x201: "com.poly.call_control.auto_answer",
  0x202: "com.poly.call_control.smart_audio_transfer",
  0x203: "com.poly.call_control.call_button_lock",
  0x204: "com.poly.audio.auto_pause_music",
  0x205: "com.poly.call_control.auto_mute",
  0x206: "com.poly.call_control.active_call_audio",
  0x300: "com.poly.call_control.auto_answer_cradle",
  //0x301: "com.poly.call_control.auto_connect_mobile",
  0x302: "com.poly.call_control.auto_disconnect_cradle",
  0x400: "com.poly.call_control.default_phone_line",
  0x401: "com.poly.wireless.bluetooth",
  0x402: "com.poly.call_control.mobile_voice_commands",
  0xb05: "com.poly.call_control.call_announcement",
  0x913: "com.poly.call_control.hold_reminder",
  0xb06: "com.poly.audio.volume_alerts",
  0x500: "com.poly.audio.noise_exposure_limit",
  0x501: "com.poly.audio.hours_on_phone_per_day",
  //0x504: "com.poly.audio.anti_startle",
  0x505: "com.poly.audio.g616",
  0x600: "com.poly.call_control.answer_voice_prompt",
  0x601: "com.poly.call_control.answering_call_alert",
  0x602: "com.poly.call_control.mute_off_alert",
  0x603: "com.poly.call_control.mute_reminder_timing",
  0x604: "com.poly.call_control.mute_reminder_volume",
  0x607: "com.poly.call_control.mute_alert_type",
  0x608: "com.poly.call_control.audio_prompt_volume",
  0x609: "com.poly.audio.volume_level_indicators",
  0x60a: "com.poly.call_control.caller_id",
  0x60c: "com.poly.call_control.audio_channel_tone",
  0x60d: "com.poly.call_control.mute_reminder_mode",
  0x610: "com.poly.call_control.mute_alerts",
  0x700: "com.poly.audio.audio_quality",
  0x701: "com.poly.call_control.over_air_subscription",
  0x702: "com.poly.call_control.range",
  0x704: "com.poly.audio.streaming_audio",
  0x705: "com.poly.audio.extended_range",
  0x706: "com.poly.call_control.computer_audio_bandwidth",
  0x707: "com.poly.call_control.desk_phone_audio_bandwidth",
  0x708: "com.poly.call_control.hd_voice",
  0x800: "com.poly.call_control.increase_qd_headset_volume",
  0x801: "com.poly.audio.tone_control",
  0x802: "com.poly.call_control.close_conversation_limiting",
  0x803: "com.poly.call_control.sidetone",
  0x804: "com.poly.audio.anc_timeout",
  0x805: "com.poly.call_control.notification_tones",
  0x806: "com.poly.audio.independent_volume_control",
  0x810: "com.poly.audio.anc_mode",
  0x90a: "com.poly.general.restore_defaults",
  0x848: "com.poly.wireless.clear_trusted_devices",
  0x900: "com.poly.call_control.ring_vibration",
  0x901: "com.poly.general.charge_vibration",
  0x902: "com.poly.call_control.online_indicator",
  0x903: "com.poly.call_control.wearing_preference",
  0xa00: "com.poly.call_control.audio_sensing",
  0xa01: "com.poly.call_control.dialtone",
  //[LENS-8531] - 0xfff1/0x301, 0xfff2/0x504 individual global setting ID maps to the same c10 ID as the combined setting which causes issues with applying policies.
  //TODO: revisit whether to create separate c10 IDs for the combined settings
  0xfff1: "com.poly.call_control.auto_connect_mobile",
  0xfff2: "com.poly.audio.anti_startle",
  // 0x301: "com.poly.call_control.auto_connect_mobile",
  // 0x504: "com.poly.audio.anti_startle",
  0x60e: "com.poly.general.battery_status_alert",
  0x60f: "com.poly.wireless.connection_indication",
  0x80c: "com.poly.wireless.bluetooth_streaming",
  0x907: "com.poly.wireless.secure_bluetooth",
  0x908: "com.poly.call_control.rocket_button",
  0x909: "com.poly.wireless.exclusive_connection",
  0xc00: "com.poly.video.brightness",
  0xc01: "com.poly.video.contrast",
  0xc02: "com.poly.video.hue",
  0xc03: "com.poly.video.saturation",
  0xc04: "com.poly.video.sharpness",
  0xc05: "com.poly.video.gamma",
  0xc06: "com.poly.video.color",
  0xc07: "com.poly.video.white_balance",
  0xc08: "com.poly.video.back_light_compensation",
  0xc09: "com.poly.video.gain",
  0xc0a: "com.poly.video.pan",
  0xc0b: "com.poly.video.tilt",
  0xc0c: "com.poly.video.roll",
  0xc0d: "com.poly.video.zoom",
  0xc0e: "com.poly.video.exposure",
  0xc0f: "com.poly.video.iris",
  0xc10: "com.poly.video.focus",
  0xc11: "com.poly.video.skin_enhancement",
  0xc12: "com.poly.video.wide_dynamic_range",
  0xc13: "com.poly.video.participant_count",
  0xc14: "com.poly.video.onscreen_display",
  0xc15: "com.poly.audio.acoustic_fence",
  0xc16: "com.poly.audio.noise_block_ai",
  0xc17: "com.poly.audio.stereo",
  0xc18: "com.poly.video.tracking_speed",
  0xc19: "com.poly.video.tracking_mode",
  0xc21: "com.poly.audio.bass",
  0xc22: "com.poly.audio.treble",
  0xc23: "com.poly.video.anti_flicker",
  0xc28: "com.poly.video.color_temperature",
  0xc1a: "com.poly.video.frame_size",
  0xc1b: "com.poly.video.max_zoom",
  0xc1c: "com.poly.video.camera_movement",
};

export interface DeviceLanguage {
  id: string;
  name: string;
}

export interface DeviceLanguages {
  archiveLocation: string;
  list: DeviceLanguage[];
}

/**
 * All languages that are supported by devices.
 */
export const ALL_DEVICES_LANGUAGES: DeviceLanguage[] = [
  { id: "3076", name: "Cantonese" },
  { id: "1030", name: "Danish" },
  { id: "2057", name: "English UK" },
  { id: "1033", name: "English US" },
  { id: "1036", name: "French" },
  { id: "1031", name: "German" },
  { id: "1040", name: "Italian" },
  { id: "1041", name: "Japanese" },
  { id: "1042", name: "Korean" },
  { id: "1045", name: "Polish" },
  { id: "2052", name: "Mandarin" },
  { id: "1044", name: "Norwegian" },
  { id: "2068", name: "Norwegian" },
  { id: "1049", name: "Russian" },
  { id: "1034", name: "Spanish" },
  { id: "1053", name: "Swedish" },
  { id: "16393", name: "English Indian" },
  { id: "1057", name: "Indonesian" },
  { id: "1054", name: "Thai" },
  { id: "1046", name: "Brazilian Portuguese" },
];

/**
 * All languages ids that are supported by devices.
 */
export const ALL_DEVICES_LANGUAGES_IDS: string[] = ALL_DEVICES_LANGUAGES.map(
  ({ id }) => id
);

export enum DEVICE_FEATURES {
  LANGUAGE = "langdfu",
  DEVICE_LOG = "deviceLog",
  CRASH_REPORTS = "crashReports",
  DFU = "dfu",
  SETTINGS = "settingsSupported",
  ADMIN_SETTINGS = "adminSettingsSupported",
  WIFI = "wiFiSupported",
  BLUETOOTH = "bluetoothSupported",
  NETWORK_PROVISIONING = "networkProvisioning",
  OTA = "otaCapable",
  AUDIO_TEST = "audioTestSupported",
  QUICK_DISCONNECT = "quickDisconnect",
  KEEP_LINK_UP = "keepLinkUp",
  CAMERA_VIVID_MODE = "cameraVividMode",
  PLAY_GREETING = "playGreeting",
}

export const DEVICE_TYPE = {
  EARBUDS: "earbuds",
  CHARGING_CASE: "chargingCase",
};

export enum TOAST_CLOSE_ACTION_TYPES {
  P21_DISPLAY_LINK = "P21_DISPLAY_LINK",
}
export const SOFTWARE_SETTING_PLAY_GREETING = "playGreeting";
export const SOFTWARE_SETTING_PLAY_GREETING_FILE = "playGreetingFile";

/* Windows registry keys */
export const POLY_REGISTRY_PATH = "HKCU\\SOFTWARE\\Poly, Inc.\\Poly Lens";
export const POLY_REGISTRY_PATH_ALL_USERS =
  "HKLM\\SOFTWARE\\WOW6432Node\\Poly, Inc.\\Poly Lens";
export const TENANT_TOKEN = "Tenant Token";
export const TELEMETRY_FLAG = "noData";
export const USER_INFO_FLAG = "noUserInfo";
export const ACCEPT_EULA = "acceptEula";

/* Devices which support FTC Wizard (needOOB) - Studio USB, R30, R30 NR */
export const NEED_SETUP_DEVICES = [+"0x9217", +"0x92B2", +"0x92B4"];
