import { ACCEPT_EULA, EULA_AGREED_KEY } from "../utils/constants";
import { StorageService } from "./storage.service";
import { BehaviorSubject, Observable } from "rxjs";
import { Injectable } from "@angular/core";
import { AdminConfig } from "./admin-config.service";
import { nullOrUndefined } from "../utils/utils";
import { filter } from "rxjs/operators";
import { getRegistryValue } from "../utils/registry.utils";
import { AuthService } from "./auth.service";

@Injectable({
  providedIn: "root",
})
export class EulaService {
  private _agreed$: BehaviorSubject<boolean> = new BehaviorSubject(null);
  private isAcceptedByDefault: boolean = false;
  private _acceptEula$ = new BehaviorSubject(null);

  constructor(
    private storageService: StorageService,
    private adminConfig: AdminConfig,
    private authService: AuthService
  ) {
    this.getAcceptEulaFlag();
    this.authService.isLoginless$.subscribe((isLoginless) => {
      this.isAcceptedByDefault = !this.adminConfig.eulaAccept || isLoginless;
      this._agreed$.next(
        this.isAcceptedByDefault ||
          this.storageService.getItem(EULA_AGREED_KEY, false)
      );
    });
  }
  agree() {
    this.storageService.setItem(EULA_AGREED_KEY, true);
    this._agreed$.next(true);
  }

  clear() {
    this.storageService.setItem(EULA_AGREED_KEY, false);
    this._agreed$.next(this.isAcceptedByDefault);
  }

  get agreed$() {
    return this._agreed$
      .asObservable()
      .pipe(filter((v) => !nullOrUndefined(v)));
  }

  agreed(): boolean {
    return this._agreed$.getValue();
  }

  public setAcceptEulaFlag(value: string) {
    this._acceptEula$.next(value);
  }

  public get acceptEula$(): Observable<string> {
    return this._acceptEula$
      .asObservable()
      .pipe(filter((v) => !nullOrUndefined(v)));
  }

  public getAcceptEulaFlag(): void {
    getRegistryValue(ACCEPT_EULA).then((acceptEula) => {
      this.setAcceptEulaFlag(acceptEula ?? "");
    });
  }
}
