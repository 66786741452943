import { Component, Input } from "@angular/core";
import { UtilityService } from "../services/utility.service";

@Component({
  selector: "dynamic-card",
  templateUrl: "./dynamic-card.component.pug",
})
export class DynamicCardComponent {
  @Input("header") header?: string;
  @Input("title") title: string;
  @Input("logo") logo: string;
  @Input("style") style: string;
  @Input("description") description: string;
  @Input("index") index: string;
  @Input("sourceLink") sourceLink: string;
  @Input("sourceLinkLabel") sourceLinkLabel: string;
  @Input("source") source: string;
  @Input("disabled") disabled = false;
  @Input("tooltip_text") tooltip_text: string;
  @Input("idElement") idElement: string;

  handleOpenBrowser() {
    UtilityService.openExternalBrowser(this.sourceLink);
  }
}
