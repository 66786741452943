import { ILoggingService, LogWrapper, LOG } from "../../app/services/logging.service";
import { hex, hex4l, buf2hex } from "./wh-utils";
import {
  AdminLoginResponse,
  Battery,
  CreateCertificateRequest,
  CreateCertificateResponse,
  Device, GeneralSettings,
  GetCertificateFileResponse,
  GetCertificateInfoResponse,
  GetGeneralSettingsResponse,
  GetInstalledCertificatesResponse, NetworkProvisioningInfo, NetworkProvisioningParams,
  RESTDeviceError,
  ScannedNetworksResponse, SetGeneralSettingsResponse,
  SetId,
  SetServerCAValidationResponse,
  SetSimplePasswordResponse,
  SimplePasswordResponse,
  VideoDeviceStatus,
  WiFiConnectionInfo,
  WiFiConnectParams,
  WiFiStatus,
  WiFiStatusResponse
} from "../api/device-manager.api";
import { DeviceSetting } from "../api/device-settings-manager.api";
import { DeviceSettingDef, deviceSettingDefs } from "./settings-def";
import { BrDevice } from "./br-device";
import { DEVICE_FEATURES } from "../../app/utils/constants";
import { RohHid } from "./roh_hid";
import { FeatureList, Product, Products } from "../common/products-json";
import { hasKey, paddedHex } from "../common/utils/utils";
import { UvcDevice } from "./uvc-device";
import { ReqTimeoutError, UnsupportedDeviceError } from "./errors";
import {Observable} from "rxjs";


// USB vendor IDs
export const enum VID {
  PLANTRONICS = 0x047F,
  POLYCOM = 0x095d,
  HP = 0x03f0,
}

const PWA_FEATURES_SUPPORTED: string[] = [
  DEVICE_FEATURES.SETTINGS.valueOf(),
  DEVICE_FEATURES.DEVICE_LOG.valueOf(),
  DEVICE_FEATURES.WIFI.valueOf(),
  DEVICE_FEATURES.NETWORK_PROVISIONING.valueOf(),
  //DEVICE_FEATURES.BLUETOOTH.valueOf(),
  DEVICE_FEATURES.AUDIO_TEST.valueOf(),
  DEVICE_FEATURES.ADMIN_SETTINGS.valueOf(),
];

const VOYAGER_5200_NAME: string = "Voyager 5200 Series";
const VOYAGER_4310_NAME: string = "Voyager 4310 Series";
const VOYAGER_4320_NAME: string = "Voyager 4320 Series";

const deviceDisplayName = new Map<number, string>([
  [0x130, VOYAGER_5200_NAME],
  [0x132, VOYAGER_5200_NAME],
  [0x133, VOYAGER_5200_NAME],
  [0x134, VOYAGER_5200_NAME],
  [0x135, VOYAGER_5200_NAME],
  [0x167, VOYAGER_4320_NAME],
  [0x168, VOYAGER_4320_NAME],
  [0x169, VOYAGER_4320_NAME],
  [0x16a, VOYAGER_4310_NAME],
  [0x16b, VOYAGER_4310_NAME],
  [0x16c, VOYAGER_4310_NAME],
]);

function getDisplayName(productId: number, name: string): string {
  let newName = deviceDisplayName.get(productId);
  return newName !== undefined ? newName : name;
}


function getManufactureName(vendorId: number): string {
  switch (vendorId) {
  case VID.PLANTRONICS: return "Plantronics";
  case VID.POLYCOM: return "Polycom";
  case VID.HP: return "HP";
  default: return "Unknown";
  }
}

/**
 * Returns the list of supported features based on products.json file and underlying platform.
 *
 * @param featurelist Feature list from products.json.
 * @param appInfo Application information.
 */
function toFeatureList(
  featureList: Partial<FeatureList>|undefined,
): string[] {
  const toReturn: string[] = [];
  if(featureList === undefined) {
    return toReturn;
  }

  for (const prop in featureList) {
    if (hasKey(featureList,prop) && featureList[prop] && PWA_FEATURES_SUPPORTED.includes(prop)) {
      toReturn.push(prop);
    }
  }

  return toReturn;
}


interface SettingInfo {
  cached: DeviceSetting;
  setter: (val: string, autoEnabled?: boolean) => Promise<void>
  parent?: VirtualSettingInfo;
}

interface SubsettingInfo {
  settingInfo: SettingInfo;
  values: Array<string | undefined>;
}


class VirtualSettingInfo implements SettingInfo {
  cached: DeviceSetting;
  subsettings: Array<SubsettingInfo>;

  constructor(settingId: number, values: string[], subsettings: SubsettingInfo[]) {
    this.cached = { id: '0x' + hex(settingId), options: values, value: '', status: 'Unknown'};
    this.subsettings = subsettings;
    subsettings.forEach(ss => ss.settingInfo.parent = this)
    this.calcValue();
  }

  setter(value: string): Promise<void>
  {
    const idx = this.cached.options.findIndex(val => val === value);
    if (undefined !== idx) {
      let promise = Promise.resolve();
      this.subsettings.forEach(ss => {
        const subvalue = ss.values[idx];
        if (undefined !== subvalue) {
          promise = promise.then(() => ss.settingInfo.setter(subvalue))
        }
      })
      return promise;
    }
    throw new Error(`setting ${this.cached.id}: unsupported value ${value}`)
  }

  calcValue(): void {
    this.cached.status = 'Unknown';
    this.cached.options.forEach((val, idx) => {
      if (this.subsettings.every(ss => undefined === ss.values[idx] || ss.settingInfo.cached.value === ss.values[idx])) {
        this.cached.value = val;
        this.cached.status = 'OK';
      }
    });
  }
}


export interface WhDeviceObserver {
  onWhDeviceUpdate(): void;
  onWhDeviceSettingsUpdate(dev: WhDevice): void;
  onChildDeviceAdded(dev: WhDevice): void;
  onChildDeviceRemoved(dev: WhDevice): void;
}


export class WhDevice {
  static deviceInit(id: string, vendorId: number, productId: number, productName: string): Device {
    const paddedPid = paddedHex(productId);
    const product: Partial<Product> | undefined = this.products.getById(paddedPid);
    const dev: Device = {
      id: id,
      parentDeviceId: '',
      uniqueId: 'incomplete-' + id,
      pid: productId,
      /*TODO*/firmwareVersion: {base: '', headset:'', bluetooth: '', pic: '', tuning:'', usb:'', setId: {major:'', minor:'', revision:'', build:''}},
      productSerialNumber: { base: '', headset: '' },
      productBuildCode: { base: '', headset: '' },
      serialNumber: { base: '', headset: '' },
      displayName: getDisplayName(productId, productName),
      deviceName: '',
      //isDeviceNameCustomizable: this.getDeviceNameCustomizable(hidDevice),
      /*TODO*/isPrimary: false,
      canBePrimary: false,
      isVideoDevice: false,
      cameraControlsAvailable: false,
      isMuted: false,
      isConnected: true,
      languageId: '',
      /*TODO*/featureList: toFeatureList(product?.featurelist),
      battery: { level: 5, charging: false, docked: false, numLevels: 11 },
      vid: vendorId,
      manufacturerName: getManufactureName(vendorId),
      modelId: hex(productId),
      //headsetModelId: "headsetModelId",
      //headsetType: "headsetType",
      //isTeamsSKU: false,
      //isUSBTypeC: false,
      //peripheralInfo: this.getPeripheralInfo(hidDevice),
      manufacturerInfo: { mftInfo: '', hardwareVersion: '', additionalHardwareVersion: '' },
      //headsetConnectedState: true,
      //additionalHeadsetInfo: null,
      //recordVoiceNotes: "not_supported",
      //deviceType: this.getDeviceType(hidDevice),
      //additionalBatteryInfo: null,
      //playPCAudio : "some audio",
    };
    return dev;
  }

  static make(id: string, hidDevice: HIDDevice, observer: WhDeviceObserver, logger: ILoggingService): WhDevice {
    const dev = WhDevice.deviceInit(id, hidDevice.vendorId, hidDevice.productId, hidDevice.productName);
    const result = new WhDevice(hidDevice, dev, observer, logger);

    hidDevice.oninputreport = (e: HIDInputReportEvent) => { result.reportCb(e); };
    result.processReportCollections();
    result.calcDevSettings();
    return result;
  }

  addBrChild(port: number): Promise<void> {
    const dev = WhDevice.deviceInit(this.devMgrDevice.id + '.' + port, 0, 0, '');
    const result = new WhDevice(this.hidDevice, dev, this.observer, this.logger.logger);
    result.priority = this.priority + 1;
    result.brDev = this.brDev?.makeChild(result, port, result.logger);
    return result.loadDevice()
    .then(() => {
      result.devMgrDevice.parentDeviceId = this.devMgrDevice.id;
      this.devMgrDevice.firmwareVersion.headset = result.devMgrDevice.firmwareVersion.usb;
      this.observer.onChildDeviceAdded(result);
    })
    .catch(err => {
      if (err instanceof UnsupportedDeviceError) {
        if (LOG.DEBUG) this.logger.warn(`ignored child device(${result.deviceId()}): ${err}`);
      }
      else {
        this.logger.error(`child device(${result.deviceId()}) load: ${err}`);
      }
      return Promise.reject(err);
    });
  }

  removeChild(dev: WhDevice): void {
    this.observer.onChildDeviceRemoved(dev);
  }

  private constructor(public hidDevice: HIDDevice, device: Device, private observer: WhDeviceObserver, logger: ILoggingService) {
    this.devMgrDevice = device;
    this.logger = new LogWrapper(logger, `${this.devMgrDevice.id}: `);
    this.logger.info(`new device ${this.devMgrDevice.displayName}`)
    // workaround to delete mandatory field
    {
      const a: any = this.devMgrDevice;
      delete a.parentDeviceId;
    }
  }

  private processReportCollections() {
    if (LOG.DEBUG) {
      for (const coll of this.hidDevice.collections) {
        this.logger.info(`collection ${hex4l(coll.usagePage)}`);
        coll.inputReports?.forEach(report => this.logger.info(" i-" + this.dumpReportDesc(report)))
        coll.outputReports?.forEach(report => this.logger.info(" o-" + this.dumpReportDesc(report)))
        coll.featureReports?.forEach(report => this.logger.info(" f-" + this.dumpReportDesc(report)))
      }
    }
    const { inBrReportId, inBrReportLen, outBrReportId, outBrReportLen } = this.findBrReports();
    const { reportId } = this.findHwVerReport();
    this.hwVerReportId = reportId;
    const { inRohReportId, inRohReportLen, outRohReportId, outRohReportLen } = this.findRohReports();
    if (LOG.DEBUG) {
      this.logger.info(`${this.hidDevice.productName} (vid/pid=${hex4l(this.hidDevice.vendorId)}/${hex4l(this.hidDevice.productId)})`
        + `, reports: BR=${inBrReportId}/${outBrReportId} hwVer=${reportId} RoH=${inRohReportId}/${outRohReportId}`);
    }
    else {
      this.logger.info(`${this.hidDevice.productName} (vid/pid=${hex4l(this.hidDevice.vendorId)}/${hex4l(this.hidDevice.productId)})`);
    }

    if (inBrReportId && inBrReportLen && outBrReportId && outBrReportLen) {
      this.brDev = BrDevice.make(this, this.hidDevice, inBrReportId, inBrReportLen, outBrReportId, outBrReportLen, this.logger);
    }
    if (inRohReportId && outRohReportId && outRohReportLen) {
      this.rohHid = new RohHid(this, this.hidDevice, inRohReportId, outRohReportId, outRohReportLen, this.logger);
    }

    this.devMgrDevice.canBePrimary = this.findCallControl();

    for (const coll of this.hidDevice.collections) {
      coll.inputReports?.forEach(report => {
        if (undefined !== report.reportId) {
          this.inReports.set(report.reportId, report)
        }
      })
      coll.featureReports?.forEach(report => {
        if (undefined !== report.reportId) {
          this.inReports.set(report.reportId, report)
        }
      })
    }
  }

  deviceId(): string { return this.devMgrDevice.id }
  vendorId(): number { return this.devMgrDevice.vid }
  productId(): number { return this.devMgrDevice.pid }
  displayPriority(): number { return this.priority }
  settingDefs(): DeviceSettingDef[] { return this.devSettings! }

  loadDevice(): Promise<void> {
    return new Promise((resolve, reject) => {
      let stuckCnt = 0;
      let changeCnt = this.devChangeCnt + this.settingChangeCnt;
      this.intervalId = setInterval(() => {
        this.logger.debug(`checking loading progress, stuckCnt=${stuckCnt}`);
        if (changeCnt < this.devChangeCnt + this.settingChangeCnt) {
          stuckCnt = 0;
          changeCnt = this.devChangeCnt + this.settingChangeCnt;
        }
        else if (3 <= ++stuckCnt) {
          this.clearInterval();
          reject(new ReqTimeoutError("loading got stuck"));
        }
      }, 1500);
      this.doLoadDevice()
      .then(() => resolve())
      .catch(err => reject(err))
      .finally(() => {
        this.clearInterval()
        this.lastDevChangeCnt = this.devChangeCnt;
        this.lastSettingChangeCnt = this.settingChangeCnt;
      })
    });
  }

  private clearInterval(): void {
    if (undefined !== this.intervalId) {
      clearInterval(this.intervalId);
      this.intervalId = undefined;
    }
  }

  private isInitialLoadDone(): boolean { return undefined === this.intervalId }

  private doLoadDevice(): Promise<void> {
    if (undefined !== this.brDev) {
      return this.loadDevice2();
    }
    if (undefined === this.rohHid) {
      if (VID.PLANTRONICS == this.devMgrDevice.vid || VID.POLYCOM == this.devMgrDevice.vid) {
        return Promise.reject(new UnsupportedDeviceError("Unable to read complete report collections"));
      }
    }
    // RoH device or UVC camera
    return navigator.mediaDevices.getUserMedia({video: true})
    .then(() => {
      this.uvcDev = new UvcDevice(this, this.logger);
      return this.loadDevice2();
    })
  }

  private loadDevice2(): Promise<void> {
    let promise = Promise.resolve();
    if (undefined !== this.hwVerReportId) {
      const id = this.hwVerReportId;
      promise = this.hidDevice.receiveFeatureReport(this.hwVerReportId)
      .then(dv => this.onReport(id, dv))
      .catch(err => this.logger.error("get hw version report failed", err));
    }
    if (this.brDev) {
      const dev = this.brDev;
      promise = promise
      .then(() => dev.loadDevice())
      .then(() => this.initVirtSettings())
      .then(() => { if (LOG.DEBUG) this.logger.info("initial br fetch done", this.devMgrDevice) })
    }
    else if (this.rohHid) {
      //this.devMgrDevice.featureList.push(DEVICE_FEATURES.SETTINGS);
      const hid = this.rohHid;
      promise = promise
      .then(() => hid.loadDeviceData())
      .then(() => { if (LOG.DEBUG) this.logger.info("initial roh fetch done", this.devMgrDevice) });
    }
    if (this.uvcDev) {
      this.allocateVideoDeviceStatus();
      this.devMgrDevice.cameraControlsAvailable = true;
      this.devMgrDevice.isVideoDevice = true;
      const dev = this.uvcDev;
      promise = promise
      .then(() => dev.loadDevice())
      .then(() => { if (LOG.DEBUG) this.logger.info("initial uvc fetch done", this.devMgrDevice) })
    }
    return promise;
  }

  getDeviceSettings(): DeviceSetting[] {
    const result = new Array<DeviceSetting>();
    this.settings.forEach(ds => {
      if (undefined === ds.parent) {
        result.push(ds.cached)
      }
    })
    return result;
  }

  setDeviceSetting(settingId: number, value: string, autoEnabled?: boolean): Promise<void> {
    const sett = this.settings.get(settingId);
    if (undefined !== sett) {
      if (!sett.cached.autoEnabledSupported && autoEnabled) {
        throw new Error(`setting ${hex4l(settingId)}: autoEnabled is unsupported`);
      }
      return sett.setter(value, autoEnabled)
      .then(() => {
        this.timeoutCnt = 0;
        this.publishSettingsIfChanged();
      })
      .catch(err => {
        if (err instanceof ReqTimeoutError) {
          this.timeoutCnt += 1;
        }
        return Promise.reject(err);
      })
    }
    throw new Error(`setting ${hex4l(settingId)}: unsupported`);
  }

  tooManyTimeouts(): boolean {
    return 2 <= this.timeoutCnt
  }

  private findSingleUsageReport(usage: number, reports?: HIDReportInfo[]): { reportId?: number, reportLen?: number } {
    if (reports) {
      for (const rep of reports) {
        // some reports have 2 items but 2nd item has no usages
        if (usage === rep.items?.[0].usages?.[0] &&
          (1 === rep.items.length || (2 === rep.items.length && 0 === rep.items?.[1].usages?.length))) {
          return { reportId: rep.reportId, reportLen: rep.items[0].reportCount };
        }
      }
    }
    return {};
  }

  private hasSingleUsage(usage: number, reports?: HIDReportInfo[]): boolean {
    if (reports) {
      for (const rep of reports) {
        if (undefined !== rep.items) {
          for (const i of rep.items) {
            if (i.usages?.find(u => u === usage)) {
              return true
            }
          }
        }
      }
    }
    return false;
  }

  private findBrReports(): { inBrReportId?: number, inBrReportLen?: number, outBrReportId?: number, outBrReportLen?: number } {
    for (const coll of this.hidDevice.collections) {
      if (0xFFA2 === coll.usagePage) {
        const { reportId: inBrReportId, reportLen: inBrReportLen } = this.findSingleUsageReport(0xFFA200BE, coll.inputReports);
        const { reportId: outBrReportId, reportLen: outBrReportLen } = this.findSingleUsageReport(0xFFA200BE, coll.outputReports);
        return { inBrReportId, inBrReportLen, outBrReportId, outBrReportLen };
      }
    }
    return {};
  }

  private findHwVerReport(): { reportId?: number, reportLen?: number } {
    for (const coll of this.hidDevice.collections) {
      if (0xFFA2 === coll.usagePage) {
        return this.findSingleUsageReport(0xFFA200F0, coll.featureReports);
      }
    }
    return {};
  }

  private findRohReports(): { inRohReportId?: number, inRohReportLen?: number, outRohReportId?: number, outRohReportLen?: number } {
    for (const coll of this.hidDevice.collections) {
      if (0xFFA0 === coll.usagePage) {
        const { reportId: inRohReportId, reportLen: inRohReportLen } = this.findSingleUsageReport(0xFFA00013, coll.inputReports);
        //const { reportId: outRohReportId, reportLen: outRohReportLen } = this.findSingleUsageReport(0xFFA00010, coll.featureReports);
        const { reportId: outRohReportId, reportLen: outRohReportLen } = this.findSingleUsageReport(0xFFA00011, coll.featureReports);
        return { inRohReportId, inRohReportLen, outRohReportId, outRohReportLen };
      }
    }
    return {};
  }

  private findCallControl(): boolean {
    for (const coll of this.hidDevice.collections) {
      if (0xFFA2 === coll.usagePage) {
        return this.hasSingleUsage(0xFFA20018, coll.inputReports) ||
          this.hasSingleUsage(0xFFA20018, coll.outputReports) ||
          this.hasSingleUsage(0xFFA20018, coll.featureReports);
      }
    }
    return false;
  }

  private initVirtSettings(): void {
    const settings = deviceSettingDefs(this.devMgrDevice.pid);
    settings.forEach(sett => {
      if (undefined !== sett.virtual) {
        // check value array lengths
        if (!sett.virtual.subsettings.every(ss => sett.values.length === ss.values.length)) {
          this.logger.error(`Invalid virtual setting: ${hex4l(sett.id)} (${sett.values.join(", ")})`);
        }
        const ss_vals = new Array<string>(sett.values.length);
        ss_vals.fill('');
        const children = sett.virtual.subsettings.reduce((arr, ss) => {
          const s = this.settings.get(ss.id);
          if (undefined !== s) {
            arr.push({ settingInfo: s, values: ss.values })
            ss.values.forEach((s, idx) => ss_vals[idx] += ` ${s}`)
          }
          return arr;
        }, new Array<SubsettingInfo>());
        const vals2 = new Set(ss_vals);
        // use virtual setting even if some subsettings are missing as long as all value combinations of existing subsettings
        // are unique (so each virtual setting value maps to unique combination of subsetting values)
        if (ss_vals.length === vals2.size) {
          this.settings.set(sett.id, new VirtualSettingInfo(sett.id, sett.values, children));
          if (LOG.DEBUG) this.logger.info(`setting ${hex4l(sett.id)}: virtual(${children.length}) [${sett.values.join(' ')}]`);
        }
      }
    });
    // if (0 < this.settings.size) {
    //   this.devMgrDevice.featureList.push(DEVICE_FEATURES.SETTINGS)
    // }
    if (this.brDev?.supportsBtPairing()) {
      this.devMgrDevice.featureList.push('bluetoothPairing')
    }
  }

  private reportCb(e: HIDInputReportEvent) {
    this.onReport(e.reportId, e.data);
  }

  private onReport(reportId: number, data: DataView) {
    if (LOG.DEBUG) this.logger.info(`Received report ${reportId}`, data);
    if (this.brDev?.inReportId() === reportId) {
      this.brDev.onReport(data);
    }
    else if (this.hwVerReportId === reportId) {
      this.onHwVerReport(data);
    }
    else if (this.rohHid && this.rohHid.isRohReport(reportId)) {
      this.rohHid.onReport(data);
    }
    else {
      this.onReport2(reportId, data);
    }

    if (this.isInitialLoadDone()) {
      this.publishDevChangedIfChanged();
      this.publishSettingsIfChanged();
      this.brDev?.forEachChild(child => {
        if (child.isInitialLoadDone()) {
          child.publishDevChangedIfChanged();
          child.publishSettingsIfChanged();
        }
      });
    }
  }

  publishDevChangedIfChanged(): void {
    if (this.lastDevChangeCnt !== this.devChangeCnt) {
      this.observer.onWhDeviceUpdate();
      this.lastDevChangeCnt = this.devChangeCnt;
    }
  }

  publishSettingsIfChanged(): void {
    if (this.lastSettingChangeCnt !== this.settingChangeCnt) {
      this.observer.onWhDeviceSettingsUpdate(this);
      this.lastSettingChangeCnt = this.settingChangeCnt;
    }
  }

  private dumpReportDesc(report: HIDReportInfo): string {
    let s = ''
    let offset = 0
    report.items?.forEach(item => {
      s = s + ` ${item.reportCount}*${item.reportSize} @${Math.floor(offset/8)}.${offset%8} ${item.usages?.map(u => hex4l(u)).join(' ')}`;
      if (undefined !== item.reportCount && undefined !== item.reportSize) {
        offset += item.reportCount * item.reportSize;
      }
    })
    return `report ${report.reportId} (${offset} bits):` + s;
  }

  private dumpReportItem(dv: DataView, offset: number, len: number): string {
    const startB = Math.floor(offset / 8);
    if (startB === Math.floor((offset + len - 1) / 8)) {
      const mask = ~(1 << len);
      return hex((dv.getUint8(startB) >> (offset % 8)) & mask);
    }
    if (0 !== (offset % 8)) {
      this.logger.error(`unexpected offset=${offset} and legth=${len}`);
    }
    let s = ''
    for (let i = 0; i < Math.ceil(len / 8); ++i) {
      s = s + (dv.getUint8(startB + i)).toString(16).padStart(2, '0')
    }
    return s;
  }

  private onReport2(reportId: number, data: DataView) {
    this.logger.info(`report ${reportId} buffer=[` + buf2hex(data.buffer) + ']');
    const report = this.inReports.get(reportId);
    if (undefined === report) {
      this.logger.error(`unknown report id=${reportId}`);
      return;
    }
    let offset = 0
    report.items?.forEach(item => {
      if (undefined !== item.reportCount && undefined !== item.reportSize) {
        for (let i = 0; i < item.reportCount; ++i) {
          if (item.usages && 0 < item.usages.length) {
            const usage = i < item.usages?.length ? item.usages[i] : item.usages[0]
            this.logger.info(`  ${hex4l(usage)} ${this.dumpReportItem(data, offset, item.reportSize)}`);
            offset = offset + item.reportSize;
          }
        }
      }
    })
  }

  private onHwVerReport(data: DataView) {
    let array = new Uint8Array(data.buffer, data.byteOffset + 1, data.byteLength - 1);
    const i = array.findIndex(val => 0 === val);
    array = new Uint8Array(array.buffer, array.byteOffset, 0 <= i ? i : array.byteLength);
    const decoder = new TextDecoder('UTF-8');
    this.setHwVer(decoder.decode(array));
  }

  setHwVer(hv: string) {
    if (LOG.DEBUG) this.logger.info(`hw-version=${hv}`);
    this.devMgrDevice.manufacturerInfo = { mftInfo: '', hardwareVersion: hv, additionalHardwareVersion: '' };
    this.devChangeCnt += 1;
  }

  setSerialNo(sn: string) {
    if (LOG.DEBUG) this.logger.info(`serial-number=${sn}`);
    this.devMgrDevice.productSerialNumber = {base: sn, headset: '' };
    this.devChangeCnt += 1;
  }

  setBuildCode(code: string) {
    if (LOG.DEBUG) this.logger.info(`build-code=${code}`);
    this.devMgrDevice.productBuildCode = { base: code, headset: '' };
    this.devChangeCnt += 1;
  }

  setUsbVersion(ver: number) {
    if (LOG.DEBUG) this.logger.info(`USB version=0x${hex(ver)}`);
    this.devMgrDevice.firmwareVersion.usb = hex(ver);
    this.devChangeCnt += 1;
  }

  setBluetoothVersion(ver: number) {
    // versions are usually in hex but Lens Desktop uses decimal in this case
    if (LOG.DEBUG) this.logger.info(`bluetooth version=${ver}`);
    this.devMgrDevice.firmwareVersion.bluetooth = ver.toString();
    this.devChangeCnt += 1;
  }

  setPartNo(partNo: number) {
    if (LOG.DEBUG) this.logger.info(`partNo=${partNo}`);
  }

  setModelId(modelId: string) {
    if (LOG.DEBUG) this.logger.info(`modelId=${modelId}`);
    this.devMgrDevice.modelId = modelId.toUpperCase();
    this.devChangeCnt += 1;
  }

  setSetId(id: SetId) {
    if (LOG.DEBUG) this.logger.info(`set-ID=${id.major}.${id.minor}.${id.revision}.${id.build}`);
    this.devMgrDevice.firmwareVersion.setId = id;
    this.devChangeCnt += 1;
  }

  deleteSetId() {
    // workaround to delete mandatory field
    const a: any = this.devMgrDevice.firmwareVersion;
    delete a.setId;
  }

  setUniqueId(id: string) {
    if (LOG.DEBUG) this.logger.info(`unique-ID=${id}`);
    this.devMgrDevice.uniqueId = id;
    this.devMgrDevice.serialNumber.base = id;
    this.devChangeCnt += 1;
  }

  setLang(lang: number) {
    if (LOG.DEBUG) this.logger.info("language=" + lang);
    this.devMgrDevice.languageId = lang.toString();
    this.devChangeCnt += 1;
  }

  setMuted(muted: boolean) {
    if (LOG.DEBUG) this.logger.info("muted=" + muted);
    if (this.devMgrDevice.isMuted !== muted) {
      this.devMgrDevice.isMuted = muted;
      this.devChangeCnt += 1;
    }
  }

  setVendorId(id: number) {
    if (LOG.DEBUG) this.logger.info("USB VID=0x" + id.toString(16));
    this.devMgrDevice.vid = id;
    this.devMgrDevice.manufacturerName = getManufactureName(id);
    this.devChangeCnt += 1;
  }

  setProductId(id: number) {
    if (id !== this.devMgrDevice.pid) {
      if (LOG.DEBUG) this.logger.info("USB PID=0x" + id.toString(16));
      this.devMgrDevice.pid = id;
      this.calcDevSettings();
      this.devChangeCnt += 1;
    }
  }

  setProductName(name: string) {
    let niceDisplayName = getDisplayName(this.productId(), name);
    if (LOG.DEBUG) this.logger.info("product name=" + niceDisplayName);
    this.devMgrDevice.displayName = niceDisplayName;
    this.devChangeCnt += 1;
  }

  setHeadsetConnectedState(connected: boolean) {
    if (LOG.DEBUG) this.logger.info("headset connected=" + connected);
    this.devMgrDevice.headsetConnectedState = connected;
    this.devChangeCnt += 1;
  }

  private allocateVideoDeviceStatus(): void {
    this.devMgrDevice.videoDeviceStatus = <VideoDeviceStatus> {};
    this.devMgrDevice.videoDeviceStatus.id = this.deviceId();
  }

  setVideoHardwareVersion(hwVer: string): void {
    if (this.devMgrDevice.videoDeviceStatus) {
      if (LOG.DEBUG) this.logger.info("video hardware version=" + hwVer);
      this.devMgrDevice.videoDeviceStatus.versionHW = hwVer;
      this.devChangeCnt += 1;
    }
  }

  setVideoSoftwareVersion(swVer: string): void {
    if (this.devMgrDevice.videoDeviceStatus) {
      if (LOG.DEBUG) this.logger.info("video software version=" + swVer);
      this.devMgrDevice.videoDeviceStatus.versionSW = swVer;
      this.devChangeCnt += 1;
    }
  }

  setVideoDiagCode(diagCode: string): void {
    if (this.devMgrDevice.videoDeviceStatus) {
      if (LOG.DEBUG) this.logger.info("video diagnostic code=" + diagCode);
      this.devMgrDevice.videoDeviceStatus.diagnosticCode = diagCode;
      this.devChangeCnt += 1;
    }
  }

  setVideoSerialNumber(sn: string): void {
    if (this.devMgrDevice.videoDeviceStatus) {
      if (LOG.DEBUG) this.logger.info(`videoSerialNumber=${sn}`);
      this.devMgrDevice.videoDeviceStatus.serialNumber = sn;
      this.devChangeCnt += 1;
    }
  }

  setVideoDeviceName(name: string): void {
    if (this.devMgrDevice.videoDeviceStatus) {
      if (LOG.DEBUG) this.logger.info("video device name=" + name);
      this.devMgrDevice.videoDeviceStatus.deviceName = name;
      this.devChangeCnt += 1;
    }
  }

  setVideoDeviceTime(deviceTime: string): void {
    if (this.devMgrDevice.videoDeviceStatus) {
      if (LOG.DEBUG) this.logger.info("video device time=" + deviceTime);
      this.devMgrDevice.videoDeviceStatus.deviceTime = deviceTime;
      this.devChangeCnt += 1;
    }
  }

  setVideoIpAddress(ip: string): void {
    if (ip === undefined) {
      return;
    }
    if (this.devMgrDevice.videoDeviceStatus) {
      if (LOG.DEBUG) this.logger.info("video main ip address=" + ip);
      this.devMgrDevice.videoDeviceStatus.mainIpAddress = ip;
      this.devChangeCnt += 1;
    }
  }

  setVideoMacAddress(mac: string): void {
    if (mac === undefined) {
      return;
    }
    if (this.devMgrDevice.videoDeviceStatus) {
      if (LOG.DEBUG) this.logger.info("video mac address=" + mac);
      this.devMgrDevice.videoDeviceStatus.mainMacAddress = mac;
      this.devChangeCnt += 1;
    }
  }

  setBatteryStatus(battery: Battery) {
    const old = this.devMgrDevice.battery;
    if (old?.chargeLevel !== battery.chargeLevel || old?.charging !== battery.charging) {
      this.devMgrDevice.battery = battery;
      this.devChangeCnt += 1;
    }
  }

  addSetting(settingId: number, options: Iterable<string> | ArrayLike<string>,
    setter: (val: string, autoEnabled?: boolean) => Promise<void>, autoSupported?: boolean): void {
    let ds = this.settings.get(settingId);
    if (!ds) {
      const opts = Array.from(options);
      const status = 0 === opts.length ? 'OK' : 'Unknown';
      const cached: DeviceSetting = { id: '0x' + hex(settingId), options: opts, value: '', status };
      if (undefined !== autoSupported) {
        cached.autoEnabledSupported = autoSupported;
        cached.autoEnabled = false;
      }
      this.settings.set(settingId, { cached, setter });
    }
    else {
      this.logger.warn(`duplicate setting ${hex4l(settingId)}`);
    }
  }

  storeSetting(settingId: number, value: string, autoEnabled?: boolean): boolean {
    let ds = this.settings.get(settingId);
    if (undefined === ds) {
      this.logger.warn(`unknown setting ${hex4l(settingId)}`);
      return false;
    }
    const changeCnt = this.settingChangeCnt;
    if (ds.cached.value !== value) {
      ds.cached.value = value;
      this.settingChangeCnt += 1;
    }
    if (ds.cached.autoEnabledSupported && ds.cached.autoEnabled !== autoEnabled) {
      ds.cached.autoEnabled = autoEnabled;
      this.settingChangeCnt += 1;
    }
    if (changeCnt !== this.settingChangeCnt) {
      ds.cached.status = 'OK';
      ds.parent?.calcValue();
      return true;
    }
    return false;
  }

  private getRohDevice(): Promise<RohHid> {
    return this.rohHid ? Promise.resolve(this.rohHid) : Promise.reject(new UnsupportedDeviceError("RoH unsupported"))
  }

  downloadLogs(writableFile: FileSystemWritableFileStream): Promise<void> {
    return this.getRohDevice()
    .then(rohDev => rohDev.getLogs())
    .then(buf => writableFile.write(buf))
    .then(() => this.logger.info('logs written'));
  }

  onRemoval() {
    this.logger.info("device disconnected");
    this.clearInterval();
    this.devMgrDevice.isConnected = false;
    this.brDev?.forEachChild(child => {
      child.devMgrDevice.isConnected = false;
      this.observer.onChildDeviceRemoved(child)
    });
  }

  private calcDevSettings() {
    this.devSettings = deviceSettingDefs(this.devMgrDevice.pid);
  }

  getScannedNetworks(): Promise<ScannedNetworksResponse> {
    return this.getRohDevice()
    .then(rohDev => rohDev.getScannedNetworks());
  }

  getWiFiStatus(): Promise<WiFiStatusResponse> {
    return this.getRohDevice()
    .then(rohDev => rohDev.getWiFiStatus());
  }

  getCertificateInfo(): Promise<GetCertificateInfoResponse> {
    return this.getRohDevice()
    .then(rohDev => rohDev.getCertificateInfo());
  }

  setServerCAValidation(enable: boolean): Promise<SetServerCAValidationResponse> {
    return this.getRohDevice()
    .then(rohDev => rohDev.setServerCAValidation(enable));
  }

  getInstalledCertificates(): Promise<GetInstalledCertificatesResponse> {
    return this.getRohDevice()
    .then(rohDev => rohDev.getInstalledCertificates());
  }

  createCertificateCsr(request: CreateCertificateRequest): Promise<CreateCertificateResponse> {
    return this.getRohDevice()
    .then(rohDev => rohDev.createCertificateCsr(request));
  }

  getCsrFile(): Promise<GetCertificateFileResponse> {
    return this.getRohDevice()
    .then(rohDev => rohDev.getCsrFile());
  }

  getWiFiStatusObservable(): Observable<WiFiStatusResponse> {
    return this.wiFiStatus$
  }

  setWiFiStatusObservable(o: Observable<WiFiStatusResponse>) {
    this.wiFiStatus$ = o;
  }

  getGeneralSettings(): Promise<GetGeneralSettingsResponse> {
    return this.getRohDevice()
    .then(rohDev => rohDev.getGeneralSettings());
  }

  setGeneralSettings(request: GeneralSettings): Promise<SetGeneralSettingsResponse> {
    return this.getRohDevice()
    .then(rohDev => rohDev.setGeneralSettings(request));
  }

  adminLogin(password: string): Promise<AdminLoginResponse> {
    return this.getRohDevice()
    .then(rohDev => rohDev.adminLogin(password));
  }

  setWiFiParameters(request: WiFiConnectParams): Promise<void> {
    return this.getRohDevice()
    .then(rohDev => rohDev.setWiFiParameters(request));
  }

  simplePassword(): Promise<SimplePasswordResponse> {
    return this.getRohDevice()
    .then(rohDev => rohDev.simplePassword());
  }

  setSimplePassword(simple: boolean): Promise<SetSimplePasswordResponse> {
    return this.getRohDevice()
    .then(rohDev => rohDev.setSimplePassword(simple));
  }

  getNetworkProvisioning(): Promise<NetworkProvisioningInfo> {
    return this.getRohDevice()
    .then(rohDev => rohDev.getNetworkProvisioning());
  }

  setNetworkProvisioning(request: NetworkProvisioningParams): Promise<NetworkProvisioningInfo> {
    return this.getRohDevice()
    .then(rohDev => rohDev.setNetworkProvisioning(request));
  }

  readonly devMgrDevice: Device;
  private priority: number = 1;
  private logger: LogWrapper;
  private intervalId?: NodeJS.Timeout;
  private devSettings?: DeviceSettingDef[];
  private devChangeCnt: number = 0;
  private settingChangeCnt: number = 0;
  private lastDevChangeCnt: number = 0;
  private lastSettingChangeCnt: number = 0;
  private timeoutCnt: number = 0;
  private settings = new Map<number, SettingInfo>();
  private inReports = new Map<number, HIDReportInfo>();
  private brDev?: BrDevice;
  private rohHid?: RohHid;
  private uvcDev?: UvcDevice;
  private hwVerReportId?: number;
  private wiFiStatus$?: Observable<WiFiStatusResponse>
  private static products = new Products();
}
