import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { DFUError, DFUUserAction } from "@poly/hub-native";
import { DfuModalService } from "../../services/dfu-modal.service";
import { AccordionItem } from "../../shared/components/accordion/accordion.component";
import { ALL_DEVICES_LANGUAGES, DeviceLanguage } from "../../utils/constants";
import { Subscriptions } from "../../utils/subscriptions";
import { ILoggingService } from "../../services/logging.service";
import { DfuWarnings } from "../../services/dfu-warnings.service";
import { DfuNeedReconnectService } from "../../services/dfu-need-reconnect.service";
import { combineLatest } from "rxjs";
import { OzDevice } from "../../services/device-manager.service";
import { Dfu } from "../../services/dfu.service";
import {
  TranslatedOptions,
  TranslatedOption,
} from "../../shared/components/dropdown/dropdown.component";

@Component({
  selector: "oz-device-language",
  templateUrl: "./device-settings-language-item.component.pug",
})
export class DeviceSettingsLanguageItemComponent implements OnInit, OnDestroy {
  @Input() set accordionData(newValue: AccordionItem) {
    this.device = newValue.data.device;
  }

  // Device instance
  device: OzDevice;

  // Supported languages for selected device
  supportedLanguages: TranslatedOptions = [];

  // URL for DFU archive in case user wants to change a language
  archiveUrl: string;

  // Selected Language ID (in a dropdown)
  selectedLanguageId: string;

  // Show/Hide modal flags
  showDfuConfirmModal = false;

  // DFU warnings data (in case DFU cannot be performed)
  dfuWarnings: (DFUError | DFUUserAction)[] = [];

  // Can't change language through base (e.g., Lang DFU supported only via USB cable)
  canNotChangeLanguageThroughBase = false;

  private subs = new Subscriptions();

  constructor(
    private dfuModalService: DfuModalService,
    private logger: ILoggingService,
    private dfuWarningsPolling: DfuWarnings,
    private dfuNeedReconnectService: DfuNeedReconnectService,
    private dfu: Dfu
  ) {}

  ngOnInit() {
    this.canNotChangeLanguageThroughBase = this.device?.additionalHeadsetInfo?.flags.includes(
      "canNotChangeLanguageThroughBase"
    );

    if (this.canNotChangeLanguageThroughBase) {
      this.supportedLanguages = [];
    } else {
      const found = ALL_DEVICES_LANGUAGES.find(
        (language) => language.id === this.device.languageId
      );
      this.supportedLanguages = found ? [this.toTranslatedOption(found)] : [];
      this.selectedLanguageId = found ? found.id : null;

      combineLatest([
        // Retrieve the list of all languages that the device supports
        this.dfu.availableLanguages(this.device),
        // Check for the DFU warnings
        this.dfuWarningsPolling.poll(this.device),
        // Retrieve the reconnect status
        this.dfuNeedReconnectService.getNeedReconnect(this.device),
      ]).subscribe(([languages, dfuWarnings, needReconnect]) => {
        if (languages?.list?.length) {
          this.archiveUrl = languages.archiveLocation;
          this.supportedLanguages = languages.list.map(this.toTranslatedOption);
        }
        this.dfuWarnings = needReconnect ? ["ReplugDevice"] : dfuWarnings;
      });
    }
  }

  onConfirm() {
    // Hide Language Confirm Dialog
    this.showDfuConfirmModal = false;
    if (this.archiveUrl) {
      this.runDfu();
    } else {
      this.logger.error(
        `Confirmed to start DFU on a dialog. However DFU archive URL is not available, DFU cannot start.`
      );
    }
  }

  runDfu() {
    this.dfuModalService.open({
      device: this.device,
      dfuType: "LanguageChange",
      archiveLocation: this.archiveUrl,
      languageId: this.selectedLanguageId,
    });
  }

  onLanguageCancel() {
    this.selectedLanguageId = this.device.languageId;
  }

  private toTranslatedOption(option: DeviceLanguage): TranslatedOption {
    return {
      value: option.id,
      text: `DEVICE_LANGUAGES.${option.id}`,
    };
  }

  ngOnDestroy() {
    this.subs?.unsubscribe();
  }
}
