import {
  Directive,
  Input,
  Output,
  EventEmitter,
  HostBinding,
  HostListener,
} from "@angular/core";

/**
 * Directive to handle long press events, for instance panning left
 * on a camera where user would get frustrated to click the button 50 times.
 *
 * Based on @help https://medium.com/@amcdnl/angular2-long-press-directive-6e257e991a32
 *
 * Note click event is still called once on release of a long-press. One
 * improvement would be a pattern that called a click, but a long-press canceled
 * the click event.
 *
 * @example
 * This example waits 2 seconds of holding down a button before calling helloMcFly().
 * It then calls helloMcFly every 100ms after that while button is held down.
 *
 * button((click)="instantAction()", long-press, waitMS="2000", loopMS="100", (onLongPressing)="helloMcFly()") Biff Knock
 */
@Directive({ selector: "[long-press]" })
export class LongPressDirective {
  // time to wait before an onLongPress is activated
  @Input() waitMS: number = 250;
  // time between each onLongPressing event
  @Input() loopMS: number = 100;

  @Output() onLongPress: EventEmitter<any> = new EventEmitter();
  @Output() onLongPressing: EventEmitter<any> = new EventEmitter();
  @Output() onLongPressEnd: EventEmitter<any> = new EventEmitter();

  private pressing: boolean;
  private longPressing: boolean;
  private timeout: ReturnType<typeof setTimeout>;
  private mouseX: number = 0;
  private mouseY: number = 0;

  @HostBinding("class.press")
  get press() {
    return this.pressing;
  }

  @HostBinding("class.longpress")
  get longPress() {
    return this.longPressing;
  }

  @HostListener("mousedown", ["$event"])
  onMouseDown(event) {
    // don't do right/middle clicks
    if (event.which !== 1) return;

    this.mouseX = event.clientX;
    this.mouseY = event.clientY;

    this.pressing = true;
    this.longPressing = false;

    this.timeout = setTimeout(() => {
      this.longPressing = true;
      this.onLongPress.emit(event);
      this.loop(event);
    }, this.waitMS);

    this.loop(event);
  }

  @HostListener("mousemove", ["$event"])
  onMouseMove(event) {
    if (this.pressing && !this.longPressing) {
      const xThres = event.clientX - this.mouseX > 10;
      const yThres = event.clientY - this.mouseY > 10;
      if (xThres || yThres) {
        this.endPress();
      }
    }
  }

  @HostListener("mouseup")
  onMouseUp() {
    this.endPress();
  }

  @HostListener("mouseout")
  onMouseOut() {
    this.endPress();
  }

  loop(event) {
    if (this.longPressing) {
      this.timeout = setTimeout(() => {
        this.onLongPressing.emit(event);
        this.loop(event);
      }, this.loopMS);
    }
  }

  endPress() {
    clearTimeout(this.timeout);
    this.longPressing = false;
    this.pressing = false;
    this.onLongPressEnd.emit(true);
  }
}
