import { MS_IN_DAY } from "../utils/constants";
import { EulaService } from "./eula.service";
import { mergeMapTo, take } from "rxjs/operators";
import { filter } from "rxjs/operators";
import { Injectable } from "@angular/core";
import { LensSettingsService } from "./lens-settings.service";
import { NotificationsService } from "./notifications.service";
import { TranslateService } from "@ngx-translate/core";
import { Dfu, OzDeviceWithRepositoryFirmware } from "./dfu.service";
import { OzDevice } from "./device-manager.service";
import { ILoggingService } from "./logging.service";
import { addManufacturerPrefix } from "../utils/device.utils";
import {AdminConfig} from "./admin-config.service";

@Injectable({
  providedIn: "root",
})
export class DfuNotificationsService {
  private notify: boolean;
  private fwVersions: Map<string, string> = new Map(); //[device.uniqueId, previousFwVersionFoundOnServer]
  private messageBody: string;

  constructor(
    private lensSettingsService: LensSettingsService,
    private notificationService: NotificationsService,
    private translateService: TranslateService,
    private eulaService: EulaService,
    private dfu: Dfu,
    private logger: ILoggingService,
    private adminConfig: AdminConfig,
  ) {
    if(adminConfig.mode !== "pwa") {
      this.eulaService.agreed$
        .pipe(
          filter((v) => v),
          take(1),
          mergeMapTo(this.dfu.allConnectedDevices())
        )
        .subscribe((responses) => {
          responses.forEach((device) => {
            this.setupDeviceFirmwareSubscription(device);
            setInterval(
              () => this.setupDeviceFirmwareSubscription(device),
              MS_IN_DAY
            );
          });
        });
    }

    this.lensSettingsService.lensSettings.subscribe((settings) => {
      this.notify =
        settings.deviceSoftwareUpdate &&
        settings.deviceAlerts &&
        settings.enableNotifications;
    });
  }

  private setupDeviceFirmwareSubscription(
    device: OzDeviceWithRepositoryFirmware
  ) {
    const { repositoryFirmware, repositoryFirmwareVersion } = device;

    let previousVersion = this.fwVersions.get(device.uniqueId);
    this.logger.info(
      `setupDeviceFirmwareSubscription: device: ${device.uniqueId} repoFirmwareVersion: ${repositoryFirmwareVersion} previousVersion: ${previousVersion}`
    );

    if (!previousVersion) {
      previousVersion = "";
    }

    if (repositoryFirmware && previousVersion !== repositoryFirmwareVersion) {
      this.notifyIfNeeded(device);
      this.fwVersions.set(device.uniqueId, repositoryFirmwareVersion);
    }
  }

  notifyIfNeeded(device: OzDevice) {
    if (!this.notify) {
      return;
    }

    this.translateService
      .stream("NOTIFICATIONS.DEVICE_UPDATE")
      .subscribe((translation) => {
        this.messageBody = translation.DESCRIPTION;
        this.notificationService.singleNotification({
          title: `${addManufacturerPrefix(device.displayName)}`,
          body: this.messageBody,
          origin: "DFU_UPDATE",
        });
      });
  }
}
