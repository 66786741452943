import { DetailNavService } from "../services/detail-nav.service";
import {
  DeviceManagerService,
  OzDevice,
} from "../services/device-manager.service";
import { Component, OnInit } from "@angular/core";
import {
  DFUError,
  DFUUserAction,
  GetCrashFilesRequest,
  GetCrashFilesResponse,
} from "@poly/hub-native";
import { ActivatedRoute, ParamMap } from "@angular/router";
import { BehaviorSubject, combineLatest, iif, of } from "rxjs";
import { filter, pluck, switchMap } from "rxjs/operators";
import { DfuModalService } from "../services/dfu-modal.service";
import { TranslateService } from "@ngx-translate/core";
import { SaveLogsData, SaveLogsService } from "../services/save-logs-service";
import { DeviceUtils } from "../utils/device.utils";
import { DEVICE_FEATURES } from "../utils/constants";
import { DfuWarnings } from "../services/dfu-warnings.service";
import { DfuNeedReconnectService } from "../services/dfu-need-reconnect.service";
import { StateService } from "../services/state.service";
import { Subscriptions } from "../utils/subscriptions";
import { TenantService } from "../services/tenant.service";
import { Toast, Toasts } from "../shared/components/toast/toasts.service";
import * as _ from "lodash";
import { AdminConfig } from "../services/admin-config.service";
import {PolytronServiceApi} from "../polytron/polytron.service.api";

const path = require("path");

type DeviceButtonId = "headset" | "adapter";

const CRASH_REPORTS_SUCCESS_TOAST: Toast = {
  type: "status",
  status: "success",
  text: "NOTIFICATIONS.CRASH_REPORTS_SUCCESS",
};

const CRASH_REPORTS_FAILED_TOAST: Toast = {
  type: "status",
  status: "error",
  text: "NOTIFICATIONS.CRASH_REPORTS_FAILED",
};

@Component({
  templateUrl: "./device-info-and-logs.component.pug",
})
export class DeviceInfoAndLogsComponent implements OnInit {
  public device: OzDevice;
  public deviceButtonId: DeviceButtonId;
  public headsetPid: number;
  // DFU warnings
  public dfuWarnings: (DFUError | DFUUserAction)[] = [];
  public downloading = false;
  public downloadingCrashFiles = false;
  public parentDevice: OzDevice;
  public selectedDevice: OzDevice;
  public showDfuConfirmModal = false;

  private tenantId: string;
  private tenantName: string;

  private filepath: string;
  private labels = {
    SAVE_LOGS: "",
    SAVE_CRASH_REPORTS: "",
    SELECT_SOFTWARE_UPDATE: "",
  };
  private selectedDevice$ = new BehaviorSubject<OzDevice>(null);
  private subscriptions = new Subscriptions();

  public showHardwareRevision = false;
  public showAdditionalHardwareRevision = false;

  constructor(
    private deviceManager: DeviceManagerService,
    private deviceUtils: DeviceUtils,
    private detailNavService: DetailNavService,
    private dfuModalService: DfuModalService,
    private dfuNeedReconnectService: DfuNeedReconnectService,
    private warnings: DfuWarnings,
    private route: ActivatedRoute,
    private saveLogsService: SaveLogsService,
    private stateService: StateService,
    private translateService: TranslateService,
    private tenantService: TenantService,
    private toasts: Toasts,
    private adminConfig: AdminConfig,
    private polytron: PolytronServiceApi,
  ) {
    this.translateService
      .stream([
        "DEVICE_INFO_LOGS.SELECT_SOFTWARE_UPDATE",
        "DEVICE_INFO_LOGS.SAVE_LOGS",
        "DEVICE_INFO_LOGS.SAVE_CRASH_REPORTS",
      ])
      .subscribe((translations) => {
        Object.keys(translations).forEach((key) => {
          this.labels[key.replace("DEVICE_INFO_LOGS.", "")] = translations[key];
        });
      });
  }

  private getTenant(): void {
    this.subscriptions.add(
      this.tenantService.tenantId$
        .pipe(
          switchMap((tenantId) =>
            iif(() => !!tenantId, this.tenantService.getTenantInfo(tenantId))
          ),
          pluck("data", "tenant")
        )
        .subscribe((tenant) => {
          this.tenantId = tenant.id;
          this.tenantName = tenant.name;
        })
    );
  }

  ngOnInit() {
    this.detailNavService.configure({
      showNav: false,
      showDeviceHeading: false,
    });

    // TODO: UI: This is a quick fix: avoid tenant info for "network" deployment.
    if (this.adminConfig.mode !== "network") {
      this.getTenant();
    }
    this.subscriptions.add(
      this.route.parent.paramMap
        .pipe(
          switchMap(
            (paramMap: ParamMap) =>
              this.deviceManager.getDevice(paramMap.get("id")) // "id" is Device#uniqueId
          ),
          filter((device) => !!device),
          // Map a device into the list of DFU warnings and download logs progress state
          // or do nothing if the device is disconnected
          switchMap((device: OzDevice) => {
            this.device = device; // if a parent device exist, then this would be a child device
            this.parentDevice = device.parent;
            this.selectedDevice = device;
            this.selectedDevice$.next(this.selectedDevice);
            this.headsetPid = this.selectedDevice?.additionalHeadsetInfo?.headsetPID;

            this.showHardwareRevision = this.showHwRevision();
            this.showAdditionalHardwareRevision = this.showAdditionalHwRevision();

            if (!this.selectedDevice.isConnected) {
              return of([]);
            } else {
              return combineLatest([
                // [dfuWarnings]
                // Listen for the reconnect status of the selected device
                this.dfuNeedReconnectService
                  .getNeedReconnect(this.selectedDevice)
                  .pipe(
                    switchMap((needReconnect: boolean) => {
                      return needReconnect
                        ? of(["ReplugDevice"])
                        : // If device does not need to be reconnected, check for the list of DFU warnings
                          this.selectedDevice$.pipe(
                            switchMap((device: OzDevice) =>
                              this.warnings.poll(device)
                            )
                          );
                    })
                  ),
                // [progress] Save Logs Data
                this.saveLogsService.progress(this.selectedDevice?.id),
              ]);
            }
          })
        )
        .subscribe(([dfuWarnings, progress]: [DFUError[], SaveLogsData]) => {
          this.dfuWarnings = dfuWarnings;
          this.downloading = progress?.state === "Progress";
        })
    );
  }

  getSerialNumber() {
    return this.deviceUtils.getSerial(this.selectedDevice);
  }

  getCountry() {
    return _.get(this.selectedDevice, "videoDeviceStatus.countryRegion", null);
  }

  getLensAccount() {
    return this.tenantName;
  }

  getTenantId() {
    return this.tenantId;
  }

  getHardwareVersion() {
    return _.get(this.selectedDevice, "videoDeviceStatus.versionHW", null);
  }

  getMacAddress() {
    return _.get(this.selectedDevice, "videoDeviceStatus.mainMacAddress", null);
  }

  getIPAddress() {
    return _.get(this.selectedDevice, "videoDeviceStatus.mainIpAddress", null);
  }

  getDeviceDiagnosticCode() {
    return _.get(this.selectedDevice, "videoDeviceStatus.diagnosticCode", null);
  }

  onUpload() {
    this.stateService.setState("TitleBar", { disabled: true });
    this.polytron.showOpenDialog({
        title: this.labels.SELECT_SOFTWARE_UPDATE,
        properties: ["openFile"],
      })
      .then(({ canceled, filePaths }) => {
        this.stateService.setState("TitleBar", { disabled: false });
        if (!canceled) {
          this.filepath = filePaths[0];
          this.showDfuConfirmModal = true;
        }
      });
  }

  onDownloadLogs() {
    // NOTE: Intentionally skipped these strings for translation for consistency
    // So that when a support tech asks for logs, the names are consistent
    const defaultName = this.selectedDevice.name + '_0x' + this.selectedDevice.pid.toString(16);
    this.polytron.showSaveDialog({
      title: this.labels.SAVE_LOGS,
      filters: {'application/zip': ['.zip']},
      description: "Device-specific Logs",
      suggestedName: defaultName,

    })
      .then(({canceled, filePath, writableFile}) => {
        if (!canceled && filePath && writableFile !== undefined) {
          this.saveLogsService.start(this.selectedDevice.id, writableFile);
        }
      });
  }

  onDownloadCrashFiles() {
    const defaultPath = path.join(
      this.polytron.getPath("documents"),
      this.selectedDevice
        ? `${this.selectedDevice.name} Crash Files`
        : "Crash Files"
    );
    this.polytron.showSaveDialog({
        title: this.labels.SAVE_CRASH_REPORTS,
        defaultPath,
        filters: [{ name: "ZIP File", extensions: ["zip"] }],
        properties: ["createDirectory"], // [macOS] Allow creating new directories from dialog.
      })
      .then(({ canceled, filePath }) => {
        // If user made selection
        if (!canceled && filePath) {
          this.downloadingCrashFiles = true;
          const request: GetCrashFilesRequest = {
            deviceId: this.device?.id,
            fileName: filePath,
          };
          this.deviceManager
            .getCrashFiles(request)
            .then((response: GetCrashFilesResponse) => {
              if (response.status === "OK" && !!response.path) {
                this.toasts.push(CRASH_REPORTS_SUCCESS_TOAST);
              } else {
                this.toasts.push(CRASH_REPORTS_FAILED_TOAST);
              }
              this.downloadingCrashFiles = false;
            });
        }
      });
  }

  supportDownloadLogs() {
    return this.selectedDevice?.featureList.includes(
      DEVICE_FEATURES.DEVICE_LOG
    );
  }

  supportCrashReports() {
    return this.selectedDevice?.featureList.includes(
      DEVICE_FEATURES.CRASH_REPORTS
    );
  }

  onDfuConfirm() {
    this.dfuModalService.open({
      device: this.selectedDevice,
      dfuType: "Local",
      archiveLocation: this.filepath,
    });
  }

  buttonClick(buttonId) {
    this.selectedDevice =
      "headset" === buttonId ? this.device : this.parentDevice;
    this.selectedDevice$.next(this.selectedDevice);
    this.showHardwareRevision = this.showHwRevision();
    this.showAdditionalHardwareRevision = this.showAdditionalHwRevision();
  }

  showHwRevision() {
    const hwRevision = this.selectedDevice?.manufacturerInfo?.hardwareVersion;
    return !!hwRevision.length && !/^prod/i.test("" + hwRevision);
  }

  showAdditionalHwRevision() {
    const hwRevision = this.selectedDevice?.manufacturerInfo
      ?.additionalHardwareVersion;
    return !!hwRevision.length && !/^prod/i.test("" + hwRevision);
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  private supportCustomSoftwareUpload() : boolean {
    return this.adminConfig.mode !== "pwa";
  }
}
