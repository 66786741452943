import {
  Component,
  HostListener,
  ViewChild,
  ElementRef,
  Input,
} from "@angular/core";
import { AnimationOptions, BMCompleteLoopEvent } from "ngx-lottie";
import { AnimationItem } from "lottie-web";

enum AnimationButtonId {
  off = "off",
  narrow = "60",
  medium = "90",
  wide = "120",
  cameraView = "cameraView",
}
/**
 * Design Spec:
 * https://www.figma.com/file/THluWuI2GTA4Y2HqJE07gh/Lens-Desktop?node-id=19018%3A149643
 */
@Component({
  selector: "oz-animation-acoustic-fence",
  templateUrl: "./acoustic-fence.animation.component.pug",
})
export class AcousticFenceAnimationComponent {
  @Input() options: string[] = [];
  @ViewChild("animationWrap", { static: false }) animationWrapEl: ElementRef<
    HTMLElement
  >;
  private resizeTimeout: ReturnType<typeof setTimeout>;

  public animationButtonId = AnimationButtonId;
  public animation: AnimationOptions;
  public animationHeight = "19vw";
  public animationBGItem: AnimationItem;
  public animationItem: AnimationItem;
  public animationButton: AnimationButtonId = AnimationButtonId.off;
  public animationBG: AnimationOptions = {
    loop: true,
    path: "assets/lottie/acoustic_fence_layer_bg.json",
  };
  public animationNarrow: AnimationOptions = {
    loop: false,
    path: "assets/lottie/acoustic_fence_layer_narrow.json",
  };
  public animationMedium: AnimationOptions = {
    loop: false,
    path: "assets/lottie/acoustic_fence_layer_medium.json",
  };
  public animationWide: AnimationOptions = {
    loop: false,
    path: "assets/lottie/acoustic_fence_layer_wide.json",
  };
  public animationCameraView: AnimationOptions = {
    loop: false,
    path: "assets/lottie/acoustic_fence_layer_camera_view.json",
  };
  public buttonId = "";

  constructor() {}

  public isOptionPresent(option: AnimationButtonId): boolean {
    return this.options.includes(option);
  }

  animationBGCreated(animationItem: AnimationItem): void {
    this.animationBGItem = animationItem;

    setTimeout(() => {
      this.setAnimationMinHeight();
    }, 500);
    // very laggy Windows performance has been observed due to lottie; this improves the condition by not
    // rendering more subframes than necessary based on JSON After Effects frames per second
    this.animationBGItem.setSubframe(false);
  }

  animationCreated(animationItem: AnimationItem): void {
    this.animationItem = animationItem;
    this.animationItem.setSubframe(false);
  }

  loopAgain(event: BMCompleteLoopEvent): void {
    if (AnimationButtonId.off !== this.animationButton) {
      return;
    }

    // for the "enabled" animation, once the animation loops,
    // skip the initial part of the animation that shows the fan / animals being noisy.
    // 10 is the exact frame that skips it. Using 21 in order to make fan blades smooth / not jumpy...
    this.animationItem.goToAndPlay(21, true);
  }

  @HostListener("window:resize")
  resizeWindow() {
    clearTimeout(this.resizeTimeout);
    this.resizeTimeout = setTimeout(() => {
      this.animationHeight = "0";
      this.setAnimationMinHeight();
    }, 300);
  }

  setAnimationMinHeight() {
    if (!this.animationWrapEl || !this.animationWrapEl.nativeElement) {
      return;
    }

    // wait one tick to prevent expressionhaschangedafterithasbeencheckederror errors
    setTimeout(() => {
      this.animationHeight = `${
        this.animationWrapEl.nativeElement.offsetHeight - 14
      }px`;
    }, 0);
  }

  buttonClick(buttonId: AnimationButtonId) {
    this.animationButton = buttonId;
    switch (this.animationButton) {
      case AnimationButtonId.off:
        this.animation = null;
        break;
      case AnimationButtonId.narrow:
        this.animation = this.animationNarrow;
        break;
      case AnimationButtonId.medium:
        this.animation = this.animationMedium;
        break;
      case AnimationButtonId.wide:
        this.animation = this.animationWide;
        break;
      case AnimationButtonId.cameraView:
        this.animation = this.animationCameraView;
        break;
    }
  }
}
