import { Component, forwardRef, Input } from "@angular/core";
import { NG_VALUE_ACCESSOR } from "@angular/forms";

@Component({
  selector: "oz-up-down",
  templateUrl: "./up-down.component.pug",
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => UpDownComponent),
      multi: true,
    },
  ],
})
export class UpDownComponent {
  @Input() disabled = false;
  @Input() upValue = "up";
  @Input() downValue = "down";

  private onChange = (_: any) => {};
  private onTouched = (_: any) => {};

  public value: any;

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  writeValue(val: any): void {
    this.value = val;
  }

  emitChange(value) {
    this.onChange(value);
  }
}
