import { ILoggingService } from "../logging.service";
import { Injectable } from "@angular/core";
import { IHubNative } from "@poly/hub-native";
import { HubNativeWebHID } from "./hub-native-webhid";

@Injectable({
  providedIn: "root",
})
export abstract class INativeService {
  /**
   * Returns the API instance
   */
  abstract getApi(): IHubNative;
}

@Injectable({
  providedIn: "root",
})
export class NativeService extends INativeService {
  api: IHubNative;

  constructor(private logger: ILoggingService) {
    super();
    //DESKTOP: this.api = new HubNativeRMI(logger, polytron);
    this.api = new HubNativeWebHID(logger);
  }

  getApi(): IHubNative {
    return this.api;
  }
}
