import { Component, OnDestroy, OnInit } from "@angular/core";
import { SettingsMetaData } from "../../lens-settings/lens-settings-ui.model";
import { LensSettingsService } from "../../services/lens-settings.service";
import {
  HydrationAndVisionUtils,
  WellnessKeys,
  WellnessDaysFrequency,
  OptionData,
} from "../../utils/hydrationAndVisionUtils";
import { cloneDeep as _cloneDeep } from "lodash";
import { ReminderModalSettings } from "../reminders-modal/reminders-modal.component";
import { Subscriptions } from "../../utils/subscriptions";

@Component({
  templateUrl: "./hydration.component.pug",
})
export class HydrationComponent implements OnInit, OnDestroy {
  private subs = new Subscriptions();

  public allLensSettings: SettingsMetaData;
  public cards = [
    {
      title: "HEALTH_AND_WELLNESS.HYDRATION.CARDS.DRINK.TITLE",
      description: "HEALTH_AND_WELLNESS.HYDRATION.CARDS.DRINK.DESCRIPTION",
      logo: "wellness_hydration",
      sourceLink:
        "https://www.nationalacademies.org/news/2004/02/report-sets-dietary-intake-levels-for-water-salt-and-potassium-to-maintain-health-and-reduce-chronic-disease-risk",
      sourceLinkLabel:
        "HEALTH_AND_WELLNESS.HYDRATION.CARDS.DRINK.SOURCE_LINK_LABEL",
      source: "HEALTH_AND_WELLNESS.HYDRATION.CARDS.DRINK.SOURCE",
      id: "drinkWater",
    },
  ];

  public frequencyLabel = "";
  public hydrationDayOptions;
  public hydrationTimeOptions;
  public hydrationTimeSpanOptions;
  public option: OptionData;
  public reminderModal: { open: boolean; settings: ReminderModalSettings } = {
    open: false,
    settings: {
      from: "",
      to: "",
      span: "",
      days: [],
    },
  };
  public wellnessDaysFrequency = WellnessDaysFrequency;
  public wellnessKeys = WellnessKeys;

  constructor(
    private lensSettings: LensSettingsService,
    public hydrationAndVisionUtils: HydrationAndVisionUtils
  ) {
    this.hydrationDayOptions = this.hydrationAndVisionUtils.getDayOptions();
    this.hydrationTimeOptions = this.hydrationAndVisionUtils.getTimeOptions();
    this.hydrationTimeSpanOptions = this.hydrationAndVisionUtils.getTimeSpanOptions();

    this.option = {
      action: "hydrationEnabled",
      schedule: {
        days: this.hydrationDayOptions,
        timeFrom: this.hydrationTimeOptions,
        timeTo: this.hydrationTimeOptions,
        timespan: this.hydrationTimeSpanOptions,
      },
      title: "",
      type: "",
    };
  }

  ngOnInit(): void {
    this.subs.add(
      this.lensSettings.lensSettings.subscribe((settings) => {
        this.allLensSettings = _cloneDeep(settings);

        this.hydrationAndVisionUtils.initializeRemindersData(
          this.allLensSettings,
          this.lensSettings
        );
      })
    );
  }

  showReminderModal() {
    this.reminderModal = {
      open: true,
      settings: this.hydrationAndVisionUtils.getReminderSettings(
        "hydrationEnabled",
        this.allLensSettings
      ),
    };
  }

  confirmReminderModal(settings: ReminderModalSettings) {
    const hydrationSettings: Pick<
      SettingsMetaData,
      | "hydrationDays"
      | "hydrationTimeTo"
      | "hydrationTimeFrom"
      | "hydrationTimeSpan"
    > = {
      hydrationTimeFrom: settings.from,
      hydrationTimeTo: settings.to,
      hydrationTimeSpan: settings.span,
      hydrationDays: settings.days,
    };

    this.allLensSettings = { ...this.allLensSettings, ...hydrationSettings };

    this.lensSettings.setLensSettings(this.allLensSettings);
    this.wellnessChange();
    this.closeReminderModal();
  }

  closeReminderModal() {
    this.reminderModal = {
      open: false,
      settings: {
        from: "",
        to: "",
        span: "",
        days: [],
      },
    };
  }

  wellnessChange() {
    const val = this.allLensSettings[WellnessKeys.HYDRATION_ENABLED];
    this.allLensSettings = this.hydrationAndVisionUtils.enableOrDisableNotifications(
      this.allLensSettings
    );

    this.lensSettings.setLensSettings(this.allLensSettings);
    this.hydrationAndVisionUtils.handleNotificationToggle(
      "hydration",
      this.allLensSettings,
      val
    );
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }
}
