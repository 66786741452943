import { ZooEntityUpdatePayload } from "./device.messaging/utils/zoo-entity-update-message.types";
import { ILoggingService } from "./logging.service";
import { Device, DeviceSetting } from "@poly/hub-native";
import { DeviceManagerService } from "./device-manager.service";
import { StorageService } from "./storage.service";
import { AuthProfile, AuthService } from "./auth.service";
import { UtilityService } from "./utility.service";
import { TenantService } from "./tenant.service";
import { OsService } from "./os.service";
import { BehaviorSubject, from, Observable } from "rxjs";
import { IotDpsService } from "./iot-dps-service";
import {
  distinctUntilChanged,
  filter,
  map,
  mergeMap,
  shareReplay,
  switchMap,
  take,
} from "rxjs/operators";
import { Apollo, gql } from "apollo-angular";
import { Injectable } from "@angular/core";
import { MqttWs } from "azure-iot-device-mqtt";
import { Message, Client } from "azure-iot-device";
import { v4 as UUID } from "uuid";
import { CallEventsService } from "./call.events.service";
import { AcousticEventsService } from "./acoustic-events.service";
import { DeviceEventService } from "./device.event.service";
import { DeviceStatusEventData } from "./device.messaging/device.status.event.data.builder";
import {
  DeviceInfoStatePayload,
  DeviceInfoUpdatePayload,
} from "./device.messaging/utils/device.message.json.subobjects";
import { LensSettingsService } from "./lens-settings.service";
import {
  POLY_LENS_PWA,
  REGISTERED_DEVICES,
  SETTING_ID_MAP,
  TELEMETRY_FLAG,
  USER_INFO_FLAG,
} from "../utils/constants";
import { Subscriptions } from "../utils/subscriptions";
import { C10SettingMappingService } from "./c10-setting-mapping.service";
import _, { cloneDeep as _cloneDeep, set as _set } from "lodash";
import { AdminConfig } from "./admin-config.service";
import { Repository } from "./repository/repository.service";
import { getRegistryValue } from "../utils/registry.utils";
import { nullOrUndefined } from "../utils/utils";
import crypto from "crypto";
import xor from "buffer-xor";
import { DeviceMessagingUtils } from "./device.messaging/utils/device.messaging.utils";
import {
  getFullName,
  getUserMail,
  getUserName,
  getUserOID,
} from "../utils/user.data";

import { MqttBaseTransportConfig } from 'azure-iot-mqtt-base';
import { AuthenticationProvider, TransportConfig } from 'azure-iot-common';
import {Utility} from "../../SpeedTest/Utility";

// MQTT over websocket with no client certificate
class MqttNoCertWs extends MqttWs {
  constructor(authenticationProvider: AuthenticationProvider, mqttBase?: any) {
    super(authenticationProvider, mqttBase);
  }

  protected _getBaseTransportConfig(credentials: TransportConfig): MqttBaseTransportConfig {
    const baseConfig: MqttBaseTransportConfig = super._getBaseTransportConfig(credentials);
    baseConfig.uri += '?iothub-no-client-cert=true';
    return baseConfig;
  }
}

export const IOT_DEVICE_ID = "IOT_DEVICE_ID";
const IOT_DEVICE_KEY = "IOT_DEVICE_KEY";
const IOT_ASSIGNED_HUB = "IOT_ASSIGNED_HUB";
const PROXY_AGENT_LENS_BASE = "com.poly.lens.proxy.agent.lens.base";

// map of registered devices {uniqueId: timestamp}

@Injectable({
  providedIn: "root",
  useFactory(
    adminConfig: AdminConfig,
    apollo: Apollo,
    iotDps: IotDpsService,
    os: OsService,
    authService: AuthService,
    tenantService: TenantService,
    storage: StorageService,
    deviceManager: DeviceManagerService,
    lensSettingsService: LensSettingsService,
    logger: ILoggingService,
    callEventsService: CallEventsService,
    acousticEventsService: AcousticEventsService,
    devicesEventsService: DeviceEventService,
    c10SettingMappingService: C10SettingMappingService,
    repo: Repository
  ) {
    if (adminConfig.mode !== "network") {
      return new IotService(
        apollo,
        iotDps,
        os,
        authService,
        tenantService,
        storage,
        deviceManager,
        lensSettingsService,
        logger,
        callEventsService,
        acousticEventsService,
        devicesEventsService,
        c10SettingMappingService,
        repo
      );
    } else {
      return null;
    }
  },
  deps: [
    AdminConfig,
    Apollo,
    IotDpsService,
    OsService,
    AuthService,
    TenantService,
    StorageService,
    DeviceManagerService,
    LensSettingsService,
    ILoggingService,
    CallEventsService,
    AcousticEventsService,
    DeviceEventService,
    C10SettingMappingService,
    Repository,
  ],
})
export class IotService {
  private hubClient: Client;
  private tenantId: string;
  private _hubClient$ = new BehaviorSubject<Client>(null);
  private _ready$: Observable<boolean>;
  private telemetryFlag: string = null;
  private deviceCache: string[] = [];
  private static secretKey: Buffer = null;
  private static ivector: Buffer = null;
  private subs = new Subscriptions();
  private _sendTelemetry = new BehaviorSubject(null);
  private _userInfo = new BehaviorSubject(null);

  constructor(
    private apollo: Apollo,
    private iotDps: IotDpsService,
    private os: OsService,
    private authService: AuthService,
    private tenantService: TenantService,
    private storage: StorageService,
    private deviceManager: DeviceManagerService,
    private lensSettingsService: LensSettingsService,
    private logger: ILoggingService,
    private callEventsService: CallEventsService,
    private acousticEventsService: AcousticEventsService,
    private devicesEventsService: DeviceEventService,
    private c10SettingMappingService: C10SettingMappingService,
    private repo: Repository
  ) {
    this.authService.registerPreLogoutHandler(async (isAuthenticated) => {
      if (isAuthenticated) {
        // Only unregister app if we are authenticated
        await this.unregisterApp();
      }
      return true;
    });
    this.getTelemetryFlag();
    this.getUserInfo();
  }

  // private connectedDevices = {};
  public get ready$(): Observable<boolean> {
    if (!this._ready$) {
      this._ready$ = this._hubClient$.pipe(
        map((client) => !!client),
        distinctUntilChanged(),
        shareReplay(1)
      );
    }
    return this._ready$;
  }

    public get userInfo$(): Observable<string> {
    return this._userInfo
      .asObservable()
      .pipe(filter((v) => !nullOrUndefined(v)));
  }

  public setUserInfo(value: string) {
    this._userInfo.next(value);
  }

  public getUserInfo(): void {
    getRegistryValue(USER_INFO_FLAG).then((userInfo) =>
      this.setUserInfo(userInfo ?? "")
    );
  }

  public get sendTelemetry$(): Observable<string> {
    return this._sendTelemetry
      .asObservable()
      .pipe(filter((v) => !nullOrUndefined(v)));
  }

  public setTelemetryFlag(value: string) {
    this._sendTelemetry.next(value);
  }

  public getTelemetryFlag(): void {
    getRegistryValue(TELEMETRY_FLAG).then((telemetry) => {
      this.setTelemetryFlag(telemetry ?? "");
      this.telemetryFlag = telemetry;
    });
  }

  private get deviceId(): string {
    return this.storage.getItem(IOT_DEVICE_ID);
  }

  private set deviceId(v: string) {
    this.storage.setItem(IOT_DEVICE_ID, v);
  }

  private get deviceKey(): string {
    return this.storage.getItem(IOT_DEVICE_KEY);
  }

  private set deviceKey(v: string) {
    this.storage.setItem(IOT_DEVICE_KEY, v);
  }

  private get assignedHub(): string {
    return this.storage.getItem(IOT_ASSIGNED_HUB);
  }

  private set assignedHub(v: string) {
    this.storage.setItem(IOT_ASSIGNED_HUB, v);
  }

  private get registeredDevices(): { [id: string]: any } {
    return this.storage.getItem(REGISTERED_DEVICES) || {};
  }

  private set registeredDevices(v: { [id: string]: any }) {
    this.storage.setItem(REGISTERED_DEVICES, { ...v });
  }

  public patchReportedProperties(patch) {
    this.hubClient$.pipe(take(1)).subscribe(async (client) => {
      const twin = await client.getTwin();
      twin.properties.reported.update(patch, (err: Error) => {
        if (err) {
          this.logger.error("unable to update twin: " + err.toString());
        } else {
          this.logger.debug("twin state reported");
        }
      });
    });
  }

  /** This is intended to be run on app boot */
  public init() {
    //ONLY for Windows: read token from registry
    this.authService.tenantToken$
      .pipe(
        switchMap((token) => {
          if (token) {
            return this.userInfo$.pipe(
              map((userNameFlag) => ({ token, userNameFlag, tenantId: null }))
            );
          } else {
            return this.userInfo$.pipe(
              switchMap((userNameFlag) => {
                return this.authService.isAuthenticated$.pipe(
                  switchMap(() => {
                    return this.tenantService.tenantId$.pipe(
                      distinctUntilChanged(),
                      filter((value) => !!value),
                      map((tenantId: string) => ({
                        token: null,
                        userNameFlag: userNameFlag,
                        tenantId: tenantId,
                      })),
                      distinctUntilChanged()
                    );
                  })
                );
              })
            );
          }
        })
      )
      .subscribe(async ({ token, userNameFlag, tenantId }) => {
        this.devicesEventsService.userInfoFlag = userNameFlag;
        this.logger.debug(`user info flag ${JSON.stringify(userNameFlag)}`);
        if (token) {
          this.logger.debug(`tenant token value ${JSON.stringify(token)}}`);
          const userOID = await getUserOID();
          await this.getSecretKey(userOID, token);
          const userMail = await getUserMail();
          const userName = await getUserName();
          let fullName = await getFullName();
          if (fullName[0] === "") {
            fullName = userMail.substring(0, userMail.indexOf("@")).split(".");
          }
          const authProfile: AuthProfile = {
            is_new: true,
            info: null,
            given_name: fullName[0],
            family_name: fullName[1],
            nickname: fullName[0].concat(".").concat(fullName[1]).toLowerCase(),
            name: fullName[0].concat(" ").concat(fullName[1]),
            picture: null,
            updated_at: null,
            email: userMail,
            iss: null,
            sub: userOID,
            aud: null,
            iat: null,
            exp: null,
            last_connected: undefined
          };
          DeviceMessagingUtils.encryptedMail = this.encryptMessage(
            authProfile.email
          );
          DeviceMessagingUtils.encryptedName = this.encryptMessage(
            authProfile.name
          );
          DeviceMessagingUtils.encryptedUserName = this.encryptMessage(
            userName
          );
          this.authService.setProfile(authProfile);
          if (
            (this.telemetryFlag && this.telemetryFlag === "0") ||
            !this.telemetryFlag
          ) {
            await this.registerAppForToken(token);
          }
          this.checkTelemetry();
        } else {
          this.tenantId = tenantId;

          // unsubscribe events listeners when the tenant changes
          this.subs.unsubscribe();
          // subscribe to events if tenantId exists
          if (tenantId) {
            this.deviceCache = [];
            //skip registration on Cloud when app installed with noData=1
            if (
              (this.telemetryFlag && this.telemetryFlag === "0") ||
              !this.telemetryFlag
            ) {
              this.logger.info(`registerApp with tenantId=${tenantId}`);
              await this.registerApp();
            }
            this.checkTelemetry();
          }
        }
      });
  }

  private async getSecretKey(
    userOID: string,
    tenantToken: string
  ): Promise<void> {
    const clientECDH = crypto.createECDH("secp521r1");
    const clientPublicKey = clientECDH.generateKeys();
    const clientVector: Buffer = crypto.randomBytes(16);

    try {
      let publicKeyResponse = await this.exchangeKeysForEncryption(
        clientVector.toString("hex"),
        clientPublicKey.toString("hex"),
        userOID,
        tenantToken
      );

      if (publicKeyResponse?.success) {
        const sharedSecretKey = clientECDH.computeSecret(
          Buffer.from(publicKeyResponse.serverPublicKey, "hex")
        );
        IotService.secretKey = sharedSecretKey;
        IotService.ivector = xor(
          Buffer.from(publicKeyResponse.serverVector, "hex"),
          clientVector
        );
      } else {
        this.logger.error(
          `Failed to exchange encryption key with server for userOID ${userOID}`
        );
      }
    } catch (e) {
      this.logger.error(`Failed to execute exchangePublicKey API`);
    }
  }

  private encryptMessage(msg: string): string {
    if (msg === "") return msg;
    if (IotService.ivector && IotService.secretKey) {
      let cipher = crypto.createCipheriv(
        "aes-256-cbc",
        IotService.secretKey.slice(0, 32),
        IotService.ivector
      );
      let encryptedData = cipher.update(msg, "utf8", "hex");
      encryptedData += cipher.final("hex");
      return encryptedData;
    }
    return "";
  }

  private checkTelemetry(): void {
    this._sendTelemetry.subscribe((telemetryFlag) => {
      this.logger.debug(`telemetry flag ${telemetryFlag}`);
      // noData = 0 means send telemetry
      if (!telemetryFlag || (telemetryFlag && telemetryFlag === "0")) {
        this.sendPrimaryDeviceInfoMessage().then();
        // NOT_PWA this.sendNetworkInfo().then();
        this.setUpDeviceSettingsWatcher();
        this.setUpSoftwareSettingsWatcher();
        this.subscribeToCallEvents();
        this.subscribeToAcousticEvents();
        this.listenForCloudMessages();
      }
      this.subscribeToDeviceEvents(telemetryFlag);
    });
  }

  get hubClient$() {
    return this._hubClient$;
  }

  private fetchHubClient(forceReset: boolean = false): Client {
    if (!this.hubClient || forceReset) {
      try {
        const connectionString: string = this.deviceConnectionString;
        this.logger.debug(connectionString);
        if (!connectionString || connectionString.trim().length == 0) {
          this.hubClient = null;
          return this.hubClient;
        }

        this.hubClient = Client.fromConnectionString(
          connectionString,
          MqttNoCertWs
        );

        if (this._pendingMessages.length > 0) {
          while (this._pendingMessages?.length > 0) {
            const message = this._pendingMessages.shift();
            try {
              this.hubClient.sendEvent(
                message,
                this.logResultsFor("sendEvent", this.logger)
              );
            } catch (error) {
              this.logger.error(
                `error sending IoT message`,
                { error, message }
              );
            }
          }
        }

        this._hubClient$.next(this.hubClient);
      } catch (error) {
        // Unable to get hub connection
        this.logger.error(`unable to setup hub client`, error);
      }
    }

    return this.hubClient;
  }

  private logResultsFor(op, logger) {
    return function printResult(err, res) {
      if (err) logger.error(op + " error: " + err.toString());
      if (res) logger.debug(op + " status: " + res.constructor.name);
    };
  }

  private async registerAppForToken(tenantToken: string) {
    let registerResult = await this.registerEndUserDeviceForTenant(
      tenantToken,
      this.deviceId
    );
    this.logger.debug(`Results from registerEndUserDevice:`, {
      result: registerResult,
    });

    if (registerResult?.success) {
      await this.registerSymKey(
        registerResult.deviceId,
        registerResult.deviceKey
      );
      this.tenantService.setTenantId(registerResult.tenantId);
      this.devicesEventsService.setLensAppId(this.deviceId);
      this.devicesEventsService.processAndSendDeviceInfoState();
    } else {
      this.logger.error(`unable to register end user device`);
    }
  }

  private async registerApp() {
    if (!this.deviceId || !this.assignedHub) {
      let registerResult = await this.registerEndUserDevice(
        this.deviceId,
        this.tenantId
      );

      if (registerResult?.success) {
        await this.registerSymKey(
          registerResult.deviceId,
          registerResult.deviceKey
        );
        this.devicesEventsService.processAndSendDeviceInfoState();
      } else {
        this.logger.error(`unable to register end user device`);
      }
    }

    // This is how we'll tap into messages being pushed down
    // this.hubClient.on("message", (...args) => {
    //   // this.logger.log(JSON.stringify(args, null, 2));
    // });

    // TODO: verify deviceInfoState/deviceInfoUpdate update device data and then remove INIT message code
    // this.sendInitMessage();
  }

  private async unregisterApp() {
    if (this.deviceId && this.deviceId.trim().length) {
      console.log(`unregistering app ${this.deviceId}`);
      return await this.unregisterDevice(this.deviceId);
    }

    return { success: true };
  }

  private async registerSymKey(deviceId: string, deviceKey: string) {
    this.deviceId = deviceId;
    this.deviceKey = deviceKey;
    if (!this.assignedHub) {
      let dpsResult = await this.iotDps.registerSymKey(
        this.deviceKey,
        this.deviceId
      );
      this.logger.debug({ dpsResult });
      this.assignedHub = dpsResult.assignedHub;
    }
  }

  private listenForCloudMessages() {
    this.subs.add(
      this.hubClient$.subscribe((client) => {
        if (client) {
          this.listenCloudForEvents(client);
          client.on("message", (msg: any) => {
            var msgData = msg.data.toString();
            const payload: {
              method: string;
              id: string;
              type: "request" | "event";
              frameId: string;
              payload: any;
              name: string;
            } = JSON.parse(msgData);
            this.logger.info(
              "*** Received Message data",
              JSON.stringify(payload, null, 2)
            );
            const method = payload.method;
            if (method === "com.poly.lens.command.config.set") {
              // update lens app settings
              this.updateLensAppSettings(payload);
            }

            if (payload.name === "com.poly.lens.command.c10") {
              const c10Payload: {
                input: any;
                deviceToUpdateId: string;
              } = JSON.parse(payload.payload);

              const { deviceToUpdateId, input } = c10Payload;

              const inputCapabilities = _cloneDeep(input);
              const softwareUpdateCaps = inputCapabilities?.com?.poly?.software_update;

              const device = this.deviceManager.getDeviceByUniqueId(deviceToUpdateId);

              if (device) {
                this.repo.setDevicePolicyFirmwareSetting(
                  device.pid,
                  deviceToUpdateId,
                  softwareUpdateCaps
                );
              } else {
                this.logger.error(
                  `*** Cannot find deviceId: ${deviceToUpdateId} to update firmware settings`
                );
              }
              // handle software update capabilities seperately since they aren't handled by the device
              if (softwareUpdateCaps) {
                delete inputCapabilities.com.poly.software_update;
              }

              const capabilities = this.c10SettingMappingService.getCapabilityMap(
                inputCapabilities
              );
              const settings = this.c10SettingMappingService.getSettingsFromCapabilities(
                capabilities
              );

              this.c10SettingMappingService
                .verifySupportedSettings(deviceToUpdateId, settings)
                .subscribe(({ supportedSettings, settingsToRemove }) => {
                  this.logger.info(
                    `*** Settings input for deviceId: ${deviceToUpdateId}`,
                        settings
                  );
                  this.c10SettingMappingService.updateDeviceSettings(
                    deviceToUpdateId,
                    supportedSettings
                  );

                  if (settingsToRemove && settingsToRemove.length > 0) {
                    const message: ZooEntityUpdatePayload = {
                      attr: "hubv3",
                      version: "0.0.1",
                      value: {
                        eventTime: new Date(),
                        eventType: "Zoo.entityUpdate",
                        eventVersion: "0.0.1",
                        eventData: {
                          entity_id: deviceToUpdateId,
                          data: {},
                        },
                      },
                    };

                    settingsToRemove.map((setting: DeviceSetting) => {
                      const settingPath = SETTING_ID_MAP[parseInt(setting.id)];
                      _set(message.value.eventData.data, settingPath, {
                        _data: null,
                      });
                    });
                    // noData = 0 means send telemetry
                    if (
                      !this.telemetryFlag ||
                      (this.telemetryFlag && this.telemetryFlag === "0")
                    ) {
                      this.sendZooEntityUpdateMessage(message);
                    }
                  }
                });

              const messageText = JSON.stringify({
                  type: "response",
                  name: "com.poly.lens.command.c10",
                  payload: input,
                  frameId: payload.frameId,
                });
              this.logger.info(
                "*** responding to message with data: ",
                messageText
              );
              this.sendHubMessage(messageText, true);
            }
          });
        }
      })
    );
  }

  private listenCloudForEvents(client: Client) {
    client.on("disconnect", (massage) => {
      this.devicesEventsService.setCloudConnection(false);
    });
    client.on("connect", (massage) => {
      this.devicesEventsService.setCloudConnection(true);
    });
  }

  private updateLensAppSettings(payload: any) {
    let settings = payload.vars;
    let mappedSettings = settings.reduce(
      (obj, item) =>
        Object.assign(obj, {
          [item.name]: JSON.parse(item.value),
        }),
      {}
    );
    this.lensSettingsService.patchLensSettings(mappedSettings);
  }

  // TODO: verify deviceInfoState/deviceInfoUpdate update device data and then remove INIT message code
  /*
   We keep a map of currently connected devices which is updated every time a device connects/disconnects.
   On device connect an INIT message is sent for that device.
   */
  // private initDevice(devices: Device[]) {
  //   devices.forEach((device) => {
  //     if (!device.isConnected) {
  //       //disconnected device - remove from map
  //       delete this.connectedDevices[device.uniqueId];
  //     } else {
  //       if (!this.connectedDevices.hasOwnProperty(device.uniqueId)) {
  //         //newly connected device - send INIT message
  //         this.sendDeviceInitMessage(device);
  //       }
  //       this.connectedDevices[device.uniqueId] = device;
  //     }
  //   });
  // }

  private setUpSoftwareSettingsWatcher() {
    this.subs.add(
      this.lensSettingsService.lensSettings
        .pipe(
          map((s) => {
            return Object.keys(s).map((settingName) => ({
              name: settingName,
              result: "OK",
              value: JSON.stringify(s[settingName]),
            }));
          })
        )
        .subscribe((settings) => {
          this.sendSettingsMessage(this.deviceId, settings);
        })
    );
  }

  private setUpDeviceSettingsWatcher() {
    this.subs.add(
      this.deviceManager
        .getDevices()
        .pipe(
          mergeMap((devices: Device[]) => {
            return from(
              devices.filter(
                (d) =>
                  d.isConnected &&
                  d.uniqueId &&
                  !this.deviceCache.includes(d.uniqueId)
              )
            ).pipe(
              mergeMap((d) => {
                this.deviceCache.push(d.uniqueId);
                return this.deviceManager.getDeviceSettings(d.id).pipe(
                  distinctUntilChanged((x, y) => {
                    const xObj = x.reduce((result, setting) => {
                      result[setting.id] = setting.value;
                      return result;
                    }, {});
                    const yObj = y.reduce((result, setting) => {
                      result[setting.id] = setting.value;
                      return result;
                    }, {});

                    // PWA required -- was Node util.isDeepStrictEqual which does not exist in browser
                    return _.isEqual(xObj, yObj);
                  }),
                  map((settings) => {
                    // map settings to format acceptable by back-end
                    return {
                      id: d.uniqueId,
                      settings: settings.map((s) => ({
                        name: s.id,
                        result: s.status,
                        value: s.value,
                      })),
                    };
                  })
                );
              })
            );
          })
        )
        .subscribe((result) => {
          this.sendSettingsMessage(result.id, result.settings);
        })
    );
  }

  private subscribeToDeviceEvents(telemetryFlag: string) {
    this.devicesEventsService.initDevicesConnectionSubscription(this.deviceId);
    if (!telemetryFlag || (telemetryFlag && telemetryFlag === "0")) {
      /* Subscribe to DeviceInfo events: */
      this.subs.add(
        this.devicesEventsService
          .getDeviceInfoStateEvent()
          .subscribe((deviceInfoStatePayload) => {
            if (deviceInfoStatePayload) {
              deviceInfoStatePayload.value.eventData.entities.forEach((p) => {
                p.proxyAgent = PROXY_AGENT_LENS_BASE;
                p.proxyAgentVersion = UtilityService.getBuildVersion();
              });
              this.sendDeviceInfoMessage(deviceInfoStatePayload);
            }
          })
      );
      this.subs.add(
        this.devicesEventsService
          .getDeviceInfoUpdateEvent()
          .subscribe((deviceInfoUpdatePayload) => {
            if (deviceInfoUpdatePayload) {
              deviceInfoUpdatePayload.value.eventData.proxyAgent = PROXY_AGENT_LENS_BASE;
              deviceInfoUpdatePayload.value.eventData.proxyAgentVersion = UtilityService.getBuildVersion();
              this.sendDeviceInfoMessage(deviceInfoUpdatePayload);
            }
          })
      );
      /* Subscribe to DeviceStatus events: */
      this.subs.add(
        this.devicesEventsService
          .getDeviceStatusEvent()
          .subscribe((deviceStatusEventData) => {
            if (deviceStatusEventData) {
              this.sendDeviceStatusMessage(deviceStatusEventData);
            }
          })
      );
    }
    /* Subscribe to reRegisterLens events: */
    this.subs.add(
      this.devicesEventsService.getReRegisterLensEvent().subscribe(async () => {
        if (this.tenantId) {
          this.logger.info(`re-register with tenantId=${this.tenantId}`);
          const registerResult = await this.registerEndUserDevice(
            this.deviceId,
            this.tenantId
          );
          if (registerResult?.success) {
            await this.registerSymKey(
              registerResult.deviceId,
              registerResult.deviceKey
            );
          }
          this.devicesEventsService.processAndSendDeviceInfoState();
        }
      })
    );
  }

  private async registerEndUserDevice(deviceId?: string, tenantId?: string) {
    this.logger.info(`registerEndUserDevice(${deviceId}, ${tenantId})`)
    return this.apollo
      .mutate({
        mutation: gql`
          mutation registerEndUserDevice($deviceId: String, $tenantId: String) {
            registerEndUserDevice(
              device: { deviceId: $deviceId, tenantId: $tenantId }
            ) {
              success
              error
              deviceId
              deviceKey
            }
          }
        `,
        variables: {
          deviceId: deviceId,
          tenantId: tenantId,
        },
      })
      .pipe(
        take(1),
        map((result: any) => result.data.registerEndUserDevice)
      )
      .toPromise();
  }

  private async registerEndUserDeviceForTenant(
    tenantToken: string,
    deviceId: string
  ) {
    return this.apollo
      .mutate({
        mutation: gql`
          mutation registerEndUserDeviceForTenant(
            $deviceId: String
            $tenantToken: String
          ) {
            registerEndUserDeviceForTenant(
              registerRequest: {
                deviceId: $deviceId
                tenantToken: $tenantToken
              }
            ) {
              success
              deviceId
              deviceKey
              tenantId
            }
          }
        `,
        variables: {
          deviceId: deviceId,
          tenantToken: tenantToken,
        },
      })
      .pipe(
        take(1),
        map((result: any) => result.data.registerEndUserDeviceForTenant)
      )
      .toPromise();
  }

  private async unregisterDevice(deviceId: string) {
    return this.apollo
      .mutate({
        mutation: gql`
          mutation unregisterDevice($deviceId: String!) {
            unregisterDevice(deviceId: $deviceId) {
              success
              error
            }
          }
        `,
        variables: {
          deviceId: deviceId,
        },
      })
      .pipe(
        take(1),
        map((result: any) => result.data.unregisterDevice)
      )
      .toPromise();
  }

  private async exchangeKeysForEncryption(
    clientVector: string,
    clientPublicKey: string,
    userOID: string,
    tenantToken: string
  ) {
    return this.apollo
      .mutate({
        mutation: gql`
          mutation exchangePublicKey(
            $clientVector: String!
            $clientPublicKey: String!
            $userOID: String!
            $tenantToken: String!
          ) {
            exchangePublicKey(
              clientVector: $clientVector
              clientPublicKey: $clientPublicKey
              userOID: $userOID
              tenantToken: $tenantToken
            ) {
              success
              serverPublicKey
              serverVector
            }
          }
        `,
        variables: {
          clientVector: clientVector,
          clientPublicKey: clientPublicKey,
          userOID: userOID,
          tenantToken: tenantToken,
        },
      })
      .pipe(
        take(1),
        map((result: any) => result.data.exchangePublicKey)
      )
      .toPromise();
  }

  private subscribeToCallEvents() {
/* TODO:
        this.subs.add(
          this.callEventsService
            .getCallConnectionMessages()
            .subscribe((callConnectionMessage) => {
          this.sendHubMessage(JSON.stringify(callConnectionMessage), true);
        })
    );

        this.subs.add(
          this.callEventsService
            .getCallDetailRecordMessages()
            .subscribe((callDetailRecordMessage) => {
          this.sendHubMessage(JSON.stringify(callDetailRecordMessage), true);
        })
    );
    */
  }

  private subscribeToAcousticEvents() {
    this.subs.add(
      this.acousticEventsService
        .getAcousticIncidentMessages()
        .subscribe((acousticIncidentMessage) => {
          this.sendHubMessage(JSON.stringify(acousticIncidentMessage), true);
        })
    );

    this.subs.add(
      this.acousticEventsService.getTwaMessages().subscribe((twaMessage) => {
        this.sendHubMessage(JSON.stringify(twaMessage), true);
      })
    );

    this.subs.add(
      this.acousticEventsService
        .getLinkQualityMessages()
        .subscribe((linkQualityMessage) => {
          this.sendHubMessage(JSON.stringify(linkQualityMessage), true);
        })
    );

    this.subs.add(
      this.acousticEventsService
        .getConversationAnalyticsMessages()
        .subscribe((conversationAnalyticsMessage) => {
          this.sendHubMessage(
            JSON.stringify(conversationAnalyticsMessage),
                true
              );
            })
        );
  }

  // TODO: verify deviceInfoState/deviceInfoUpdate update device data and then remove INIT message code
  // private sendInitMessage(): void {
  //   const uuid = UUID();
  //   // Create message
  //   const body = {
  //     frameId: uuid,
  //     payload: {
  //       proxyAgent: PROXY_AGENT_LENS_BASE,
  //       proxyAgentVersion: this.version.version,
  //       serialNumber: this.deviceId,
  //       deviceId: this.deviceId,
  //       macAdress: "",
  //       usbVendorId: "Poly", // hard-coded for now
  //       hardwareFamily: "Lens", //hard-coded for now
  //       hardwareModel: appId, // hard-coded for now
  //       hardwareProduct: productId,
  //       usbProductId: productId,
  //       manufacturer: "Poly",
  //       softwareBuild: build, // from buildInfo file
  //       productFamily: "Lens Desktop", // hard-coded for now
  //     },
  //     type: "request",
  //     name: "INIT",
  //   };
  //   this.logger.debug("init request body", body);
  //   const message = new Message(JSON.stringify(body));
  //
  //   message.messageId = uuid;
  //   (message as any).outputName = "INIT";
  //
  //   this.sendHubMessage(message);
  // }

  // TODO: verify deviceInfoState/deviceInfoUpdate update device data and then remove INIT message code
  // private sendDeviceInitMessage(d: Device): void {
  //   const uuid = UUID();
  //   const hexPid = this.deviceMessagingUtils.getHexString(d.pid);
  //   //TODO: better logic for versioning
  //   //if device supports setid, use that. Otherwise concat the component versions for now.
  //   const version = d?.firmwareVersion?.setId
  //     ? d?.firmwareVersion?.setId
  //     : [
  //         d.firmwareVersion?.base,
  //         d?.firmwareVersion?.bluetooth,
  //         d?.firmwareVersion?.pic,
  //         d?.firmwareVersion?.tuning,
  //         d?.firmwareVersion?.usb,
  //       ]
  //         .filter(Boolean)
  //         .join(".");
  //   const body = {
  //     frameId: uuid,
  //     payload: {
  //       proxyAgent: PROXY_AGENT_LENS_BASE,
  //       proxyAgentVersion: this.version.version,
  //       serialNumber: d?.serialNumber?.base
  //         ? d.serialNumber.base
  //         : d.serialNumber.headset,
  //       deviceId: d.uniqueId,
  //       macAdress: "",
  //       usbVendorId: "", // TBD from native layer
  //       hardwareFamily: "", // TBD from native layer
  //       hardwareModel: "", // TBD from native layer
  //       hardwareProduct: hexPid,
  //       usbProductId: hexPid,
  //       manufacturer: "Poly", // TBD from native layer
  //       softwareBuild: version,
  //       productFamily: "", //TBD from native layer
  //     },
  //     type: "request",
  //     name: "INIT",
  //   };
  //   console.log("device init request body", body);
  //   const message = new Message(JSON.stringify(body));
  //
  //   message.messageId = uuid;
  //   (message as any).outputName = "INIT";
  //
  //   this.sendHubMessage(message);
  // }

  private async sendPrimaryDeviceInfoMessage() {
    const uuid = UUID();
    // Create message
    const messageText = JSON.stringify({
      frameId: uuid,
      name: "LENS_APP",
      appName: "OZ_APP",
      type: "telemetry",
      payload: {
        attr: "hubv3",
        value: {
          eventdata: {
            hardwareModel: "Lens PWA",  // value from GraphQL catalogModels
            hardwareProduct: "Lens Web",
            hardwareRevision: this.os.platform, // Web version,
            productFamily: "Lens Client",
            productName: POLY_LENS_PWA,
            productId: UtilityService.getBuildProductId(),  // value from GraphQL catalogProducts
            //macAddress: 'unavail MAC addr', //await this.os.getMacAddress(),
            manufacturer: "Poly",
            offsetGMT: UtilityService.getUTCOffset(),
            serialNumber: this.deviceId,
            softwareBuild: "",
            softwareRelease: UtilityService.getBuild(),
            hostOS: this.os.hostPlatform,
            hostOSVersion: this.os.release,
            //hostOSBuild: 'NA',//this.os.build,
            //hostHardware: 'NA',//this.os.hostHardware(),
            proxyAgent: PROXY_AGENT_LENS_BASE,
            proxyAgentVersion: UtilityService.getBuildVersion(),
            proxyAgentId: this.deviceId,
          },
          eventTime: new Date(),
          eventType: "DeviceInfo.primaryDeviceInfo",
          eventVersion: "1.0.0",
        },
        version: "0.0.1",
      },
    });

    this.sendHubMessage(messageText, true);
  }

  private async sendNetworkInfo(): Promise<void> {
    const uuid = UUID();
    const iface = await this.os.getActiveNetworkInterface();
    // Create message
    let messageText: string = JSON.stringify({
      name: "LENS_APP",
      appName: "OZ_APP",
      type: "telemetry",
      payload: {
        attr: "hubv3",
        value: {
          eventData: {
            interfaces: [
              {
                "802.1xStatus": "false",
                cdpStatus: "NA",
                connectionMode: "full-duplex",
                connectionSpeed: "NA",
                  connectionType: "unknown", //iface.iface,
                dnsAlternativeAddress: "NA",
                dnsDomain: "NA",
                dnsPrimaryAddress: "NA",
                eapMethod: "NA",
                  ipv4Address: "unknown", //iface.ip4,
                ipv4AddressSource: "DHCP",
                ipv4Gateway: "NA",
                  ipv4Subnet: "unknown", //iface.ip4subnet,
                ipv4Vlan: "1",
                ipv6AddressSource: "NA",
                ipv6GlobalAddress: "NA",
                ipv6LinkLocalAddress: "NA",
                ipv6ULA: "NA",
                lldpLocationInformation: "NA",
                lldpNeighbors: "NA",
                lldpStatus: "NA",
                ntpServer: "Auto",
              },
            ],
          },
          eventTime: new Date(),
          eventType: "DeviceInfo.networkInfo",
          eventVersion: "1.0.0",
        },
        version: "0.0.1",
      },
    });

    this.sendHubMessage(messageText, true);
  }

  private sendSettingsMessage(deviceId: any, settings: MappedSettings[]): void {
    const uuid = UUID();
    let messageText: string = JSON.stringify({
      frameId: uuid,
      name: "LENS_APP",
      appName: "OZ_APP",
      payload: {
        attr: "hubv3",
        value: {
          eventdata: {
            otheritems: settings,
          },
          eventTime: new Date(),
          eventType: "DeviceInfo.deviceConfigRecord",
          eventVersion: "1.0.0",
        },
        version: "0.0.1",
      },
      type: "telemetry",
      deviceid: deviceId,
    });

    this.sendHubMessage(messageText, true);
  }

  private sendDeviceStatusMessage(
    deviceEventData: DeviceStatusEventData
  ): void {
    const uuid = UUID();
    let messageText: string = JSON.stringify({
      frameId: uuid,
      name: "LENS_APP",
      appName: "OZ_APP",
      type: "telemetry",
      payload: {
        attr: "hubv3",
        version: "0.0.1",
        value: {
          eventData: deviceEventData,
          eventTime: new Date(),
          eventType: "DeviceStatus.deviceStatus",
          eventVersion: "0.0.1",
        },
      },
    });

    this.sendHubMessage(messageText, true);
  }

  private sendDeviceInfoMessage(
    deviceInfoPayload: DeviceInfoStatePayload | DeviceInfoUpdatePayload
  ): void {
    const uuid = UUID();
    let messageText: string = JSON.stringify({
      frameId: uuid,
      name: "LENS_APP",
      appName: "OZ_APP",
      type: "telemetry",
      payload: deviceInfoPayload,
    });

    this.sendHubMessage(messageText, true);
  }

  public sendZooEntityUpdateMessage(payload: ZooEntityUpdatePayload) {
    const uuid = UUID();
    let messageText: string = JSON.stringify({
      frameId: uuid,
      name: "LENS_APP",
      appName: "OZ_APP",
      type: "telemetry",
      payload,
    });

    this.sendHubMessage(messageText, true);
  }

  private sendHubMessage(
    messageText: string,
    ensureDelivery: boolean = false
  ): void {
    const message: Message = new Message(messageText);
    message.messageId = UUID();
    message.contentEncoding = "utf-8";
    message.contentType = "application/json";
    this.logger.debug("sendHubMessage invocation", messageText);
    let messageSent: boolean = false;

    try {
      const client: Client = this.fetchHubClient();
      if (client) {
        this.hubClient.sendEvent(
          message,
          this.logResultsFor("sendEvent", this.logger)
        );
        messageSent = true;
      }
    } catch (error) {
      // Do nothing
      this.logger.error("JTB: failed to sendEvent to IoT hub", error);
    }

    if (!messageSent && ensureDelivery) {
      this.addPendingMessage(message);
    }
  }

  private static MAX_PENDING_MESSAGES: number = 100;
  private _pendingMessages: Array<Message> = [];

  private addPendingMessage(message: Message): void {
    // Poor man's attempt to avoid potential memory leak --> should probably do something smarter at some point
    const maxMessages: number = Math.max(0, IotService.MAX_PENDING_MESSAGES);
    while (this._pendingMessages.length >= maxMessages) {
      this._pendingMessages.shift();
    }
    this._pendingMessages.push(message);
  }

  private get deviceConnectionString(): string {
    if (
      !this.assignedHub ||
      this.assignedHub.trim().length == 0 ||
      !this.deviceId ||
      this.deviceId.trim().length == 0 ||
      !this.deviceKey ||
      this.deviceKey.trim().length == 0
    ) {
      this.logger.debug(`Invalid Connection Params assignedHub=${this.assignedHub} deviceId=${this.deviceId} deviceKey=${this.deviceKey}`);
      return null;
    }
    return `HostName=${this.assignedHub};DeviceId=${this.deviceId};SharedAccessKey=${this.deviceKey}`;
  }
}

type MappedSettings = {
  name: string;
  result: string;
  value: string;
};

type LensDevice = {
  uniqueId: string;
  displayName: string;
};
