import { Component } from "@angular/core";
import { Router } from "@angular/router";
import { HomeComponentState } from "../../../home/home.component.state";
import { ILoggingService } from "../../../services/logging.service";
import { ROUTE_HOME } from "../../../utils/constants";

const HOME_COMPONENT_STATE_PAGE_NOT_FOUND_TOAST: HomeComponentState = {
  toast: {
    type: "status",
    status: "error",
    text: "NOTIFICATIONS.PAGE_NOT_FOUND.MESSAGE",
  },
};

@Component({
  selector: "oz-page-not-found",
  templateUrl: "./page-not-found.component.pug",
})
export class PageNotFoundComponent {
  public routeHome = ROUTE_HOME;

  constructor(private logger: ILoggingService, private router: Router) {
    this.logger.error("Page not found", {
      route: router.url,
    });

    this.router.navigate([ROUTE_HOME], {
      state: { ...HOME_COMPONENT_STATE_PAGE_NOT_FOUND_TOAST },
    });
  }
}
