import { Injectable } from "@angular/core";
import { Subscription } from "rxjs";
import { switchMap } from "rxjs/operators";
import { filter } from "rxjs/operators";
import { TranslateService } from "@ngx-translate/core";
import { EulaService } from "./eula.service";
import { NotificationsService } from "./notifications.service";
import { SettingsMetaData } from "../lens-settings/lens-settings-ui.model";
import { DeviceManagerService, OzDevice } from "./device-manager.service";
import { LensSettingsService } from "./lens-settings.service";
import { UtilityService } from "./utility.service";

@Injectable({
  providedIn: "root",
})
export class MuteNotificationsService {
  private muteState: { [uniqueId: string]: boolean } = {};
  private devicesSub: Subscription;
  private translations = {
    "NOTIFICATIONS.MUTE.ON": "Mute On",
    "NOTIFICATIONS.MUTE.OFF": "Mute Off",
  };

  constructor(
    private lensSettingsService: LensSettingsService,
    private deviceManager: DeviceManagerService,
    private notificationService: NotificationsService,
    private translateService: TranslateService,
    private eulaService: EulaService
  ) {
    this.lensSettingsService.lensSettings.subscribe((settings) => {
      if (settings.enableNotifications && settings.deviceAlerts) {
        this.setupNotifications(settings);
      } else {
        this.teardownNotifications();
      }
    });

    this.translateService
      .stream(Object.keys(this.translations))
      .subscribe((translations) => {
        Object.keys(translations).forEach((key) => {
          this.translations[key] = translations[key];
        });
      });
  }

  private setupNotifications(settings: SettingsMetaData) {
    if (settings.muteAlert && !this.devicesSub) {
      this.devicesSub = this.eulaService.agreed$
        .pipe(
          filter((v) => v),
          switchMap(() => this.deviceManager.getDevices())
        )
        .subscribe((devices) => {
          // When muting, the notification should show the actual
          // child device (e.g., "Voyager Focus 2") that is being
          // muted, as opposed to the parent (e.g., "BT700")
          const devicesWithChildNames = UtilityService.mapChildDeviceName(
            devices
          );
          devicesWithChildNames.forEach((d) => this.notifyIfNeeded(d));
        });
    } else if (!settings.muteAlert && this.devicesSub) {
      this.teardownNotifications();
    }
  }

  private teardownNotifications() {
    this.devicesSub?.unsubscribe();
    this.devicesSub = null;
    this.muteState = {};
  }

  private notifyIfNeeded(device: OzDevice) {
    const previouslyMuted = this.muteState[device.uniqueId];

    // Only notify if have already seen this device and mute state is different
    if (previouslyMuted !== undefined && previouslyMuted !== device.isMuted) {
      const bodyKey = device.isMuted ? "ON" : "OFF";
      this.notificationService.singleNotification({
        title: `${device.name}`,
        body: this.translations["NOTIFICATIONS.MUTE." + bodyKey],
        origin: "MUTE",
      });
    }

    this.muteState[device.uniqueId] = device.isMuted;
  }
}
