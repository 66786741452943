import { Component, OnInit, Input } from "@angular/core";
import { BadgeCount } from "../../../services/badge-count.service";
import {
  accordionCategoryAnimationClose,
  accordionCategoryAnimationOpen,
} from "../../../animations/accordion.animations";

export enum AccordionType {
  about = "about",
  account = "account",
  deviceLanguage = "deviceLanguage",
  deviceAdmin = "deviceAdmin",
  deviceSetting = "deviceSetting",
  eulaAccepted = "eulaAccepted",
  language = "language",
  notifications = "notifications",
  softphone = "softphone",
  softwareUpdate = "softwareUpdate",
  appSettings = "appSettings",
  audioTest = "audioTest",
  deviceQuickDisconnect = "quickDisconnect",
  keepLinkUp = "keepLinkUp",
  softwareSettings = "softwareSettings", // This is "Software Settings" category under Settings tab
}

/**
 * Models an accordion category.
 */
export interface AccordionCategory {
  /**
   * Header item.
   */
  title: string;

  /**
   * List of items of one category.
   */
  items: AccordionItems;

  open?: boolean;

  /**
   * Disabled Collapse during search through items
   */
  disabled?: boolean;
}

/**
 * Models accordion category header and item.
 */
export interface AccordionItem {
  /**
   * Data passed to the component.
   */
  data: any;

  // name of type, rather than specific component
  type:
    | AccordionType.about
    | AccordionType.account
    | AccordionType.deviceLanguage
    | AccordionType.deviceAdmin
    | AccordionType.deviceSetting
    | AccordionType.eulaAccepted
    | AccordionType.language
    | AccordionType.notifications
    | AccordionType.softphone
    | AccordionType.softwareUpdate
    | AccordionType.appSettings
    | AccordionType.audioTest
    | AccordionType.deviceQuickDisconnect
    | AccordionType.keepLinkUp
    | AccordionType.softwareSettings;
}

export type AccordionItems = Array<AccordionItem>;

export type AccordionCategories = Array<AccordionCategory>;

enum AudioEqualizer {
  audio = "Audio Treble",
  bass = "Audio Bass",
}

@Component({
  selector: "oz-accordion",
  templateUrl: "./accordion.component.pug",
  animations: [accordionCategoryAnimationOpen, accordionCategoryAnimationClose],
})
export class AccordionComponent implements OnInit {
  @Input() categories: AccordionCategories = [];
  @Input() badgeCounts: BadgeCount = { total: 0 };
  @Input() isDeviceSettings: boolean = false;

  public audioSettings: AccordionItems;
  public settingsWithoutAudio: AccordionItems;

  constructor() {}

  ngOnInit(): void {}

  toggleAccordion(index) {
    if (this.categories[index].disabled) {
      return;
    }

    const isOpen = this.categories[index].open;
    this.categories = this.categories.map((category) => ({
      ...category,
      open: false,
    }));

    if (!isOpen) {
      this.categories[index].open = true;
      this.audioSettings = this.categories[index].items.filter((item) =>
        this.isAudioEqualizer(item.data.title)
      );
      this.settingsWithoutAudio = this.categories[index].items.filter(
        (item) => !this.isAudioEqualizer(item.data.title)
      );
    }
  }

  private isAudioEqualizer(title: string): boolean {
    return title === AudioEqualizer.audio || title === AudioEqualizer.bass;
  }

  public get accordionType() {
    return AccordionType;
  }
}
