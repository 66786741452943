import {
  AfterViewInit,
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
} from "@angular/core";
import { fromEvent } from "rxjs";
import {
  debounceTime,
  distinctUntilChanged,
  filter,
  switchMap,
  tap,
} from "rxjs/operators";
import { DetailNavService } from "../../../services/detail-nav.service";
import { ActivatedRoute, ParamMap, Router } from "@angular/router";
import { Subscriptions } from "../../../utils/subscriptions";
import { DeviceManagerService } from "../../../services/device-manager.service";
import { AnimationOptions } from "ngx-lottie";
import { AnimationItem } from "lottie-web";
import {
  AdminLoginRequest,
  AdminLoginResponse,
  Device,
} from "@poly/hub-native";

export interface FactoryPasswordState {
  password: string;
}

@Component({
  selector: "oz-ftc-enter-password",
  templateUrl: "./ftc-enter-password.component.pug",
})
export class FtcEnterPasswordComponent
  implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild("input") input: ElementRef;

  public showDialog = false;
  public deviceName = "";
  public showError = false;
  public password: string;

  private deviceId: string;

  public lottieAnimation: AnimationOptions = {
    path: "assets/lottie/device_detected.json",
  };

  private subs = new Subscriptions();

  constructor(
    private nav: DetailNavService,
    private router: Router,
    private route: ActivatedRoute,
    private deviceManager: DeviceManagerService
  ) {
    this.nav.configure({
      showNav: false,
      showDeviceHeading: false,
    });
  }

  ngOnInit() {
    this.subs.add(
      this.route.parent.paramMap
        .pipe(
          switchMap((paramMap: ParamMap) => {
            return this.deviceManager.getDevice(paramMap.get("id")); // "id" is Device#uniqueId
          }),
          filter((device) => !!device)
        )
        .subscribe((device: Device) => {
          if (!device.isConnected) {
            this.router.navigate(["../../../"], { relativeTo: this.route });
          }
          this.deviceId = device.id;
        })
    );
  }

  animationCreated(animationItem: AnimationItem): void {
    // very laggy Windows performance has been observed due to lottie; this improves the condition by not
    // rendering more subframes than necessary based on JSON After Effects frames per second
    animationItem.setSubframe(false);
  }

  onGetStarted() {
    this.router.navigate(["ftc-wizard", this.deviceId]);
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }

  ngAfterViewInit() {
    this.subs.add(
      fromEvent(this.input.nativeElement, "keyup")
        .pipe(
          tap(() => {
            this.showError = false;
          }),
          debounceTime(500),
          distinctUntilChanged()
        )
        .subscribe((password) => {
          if (this.password?.length) {
            this.checkPassword();
          }
        })
    );
  }

  checkPassword() {
    const request: AdminLoginRequest = {
      deviceId: this.deviceId,
      password: this.password,
    };
    this.deviceManager
      .adminLogin(request)
      .then((response: AdminLoginResponse) => {
        if (response.status === "OK" && response.result) {
          this.router.navigate(["../ftc_start_setup"], {
            relativeTo: this.route,
            state: {
              password: this.password,
            } as FactoryPasswordState,
          });
        } else {
          this.showError = true;
        }
      });
  }
}
