import { Component, HostListener, ViewChild, ElementRef } from "@angular/core";
import { AnimationOptions, BMCompleteLoopEvent } from "ngx-lottie";
import { AnimationItem } from "lottie-web";

type AnimationButtonId = "enabled" | "disabled";

@Component({
  selector: "oz-animation-noiseblockai",
  templateUrl: "./noiseblockai.animation.component.pug",
})
export class NoiseblockAIAnimationComponent {
  @ViewChild("animationWrap", { static: false }) animationWrapEl: ElementRef<
    HTMLElement
  >;

  private resizeTimeout: ReturnType<typeof setTimeout>;

  public animation: AnimationOptions;
  public animationHeight = "20vw";
  public animationItem: AnimationItem;
  public animationButton: "enabled" | "disabled" = "disabled";
  public animationNBAIOff: AnimationOptions = {
    loop: true,
    path: "assets/lottie/noiseblockai_off.json",
  };
  public animationNBAIOOn: AnimationOptions = {
    loop: true,
    path: "assets/lottie/noiseblockai_on.json",
  };
  public buttonId = "";
  public buttonsDisabled = false;
  public show: boolean = false;

  constructor() {
    this.animation = this.animationNBAIOff;
  }

  animationCreated(animationItem: AnimationItem): void {
    this.animationItem = animationItem;
    setTimeout(() => {
      this.setAnimationMinHeight();
    }, 500);
    // very laggy Windows performance has been observed due to lottie; this improves the condition by not
    // rendering more subframes than necessary based on JSON After Effects frames per second
    this.animationItem.setSubframe(false);
  }

  loopAgain(event: BMCompleteLoopEvent): void {
    if ("enabled" !== this.animationButton) {
      return;
    }

    // for the "enabled" animation, once the animation loops,
    // skip the initial part of the animation that shows the fan / animals being noisy.
    // 10 is the exact frame that skips it. Using 21 in order to make fan blades smooth / not jumpy...
    this.animationItem.goToAndPlay(21, true);
  }

  @HostListener("window:resize")
  resizeWindow() {
    clearTimeout(this.resizeTimeout);
    this.resizeTimeout = setTimeout(() => {
      this.animationHeight = "0";
      this.setAnimationMinHeight();
    }, 300);
  }

  setAnimationMinHeight() {
    if (!this.animationWrapEl || !this.animationWrapEl.nativeElement) {
      return;
    }

    // wait one tick to prevent ExpressionChangedAfterItHasBeenCheckedError errors
    setTimeout(() => {
      this.animationHeight = `${
        this.animationWrapEl.nativeElement.offsetHeight - 14
      }px`;
    }, 0);
  }

  buttonClick(buttonId: AnimationButtonId) {
    this.animationButton = buttonId;
    this.animation =
      "enabled" === this.animationButton
        ? this.animationNBAIOOn
        : this.animationNBAIOff;
  }
}
