import { Component, EventEmitter, Input, Output } from "@angular/core";

@Component({
  selector: "oz-input-password",
  templateUrl: "./input-password.component.pug",
})
export class InputPasswordComponent {
  public isPasswordVisible: boolean = false;
  public password: string;

  @Output() onPasswordChange: EventEmitter<string> = new EventEmitter<string>();
  @Input() placeholder: string;

  constructor() {}

  public reset(): void {
    this.password = "";
    this.isPasswordVisible = false;
  }

  public onChange(): void {
    this.onPasswordChange.emit(this.password);
  }
}
