import { Component, Input } from "@angular/core";
import { Toast, Toasts } from "../../../shared/components/toast/toasts.service";

const COPIED_TO_CLIPBOARD_TOAST: Toast = {
  type: "status",
  status: "success",
  text: "NOTIFICATIONS.COPIED_TO_CLIPBOARD",
};

@Component({
  selector: "oz-copy-button",
  templateUrl: "./copy-button.component.pug",
})
export class CopyButtonComponent {
  @Input() textToCopy: string;

  constructor(private toasts: Toasts) {}

  onClick() {
    navigator.clipboard.writeText(this.textToCopy);
    this.toasts.push(COPIED_TO_CLIPBOARD_TOAST);
  }
}
