import {
  Component,
  Input,
  OnInit,
  OnChanges,
  Output,
  EventEmitter,
} from "@angular/core";

@Component({
  selector: "oz-slider-range",
  templateUrl: "./slider-range.component.pug",
})
export class SliderRangeComponent implements OnInit {
  @Input()
  minValueModel: number;
  @Input()
  maxValueModel: number;
  @Output()
  minValueModelChange = new EventEmitter<number>();
  @Output()
  maxValueModelChange = new EventEmitter<number>();
  @Input()
  min: number;
  @Input()
  max: number;
  @Input()
  step: number;
  @Input()
  labels: string[];
  @Input()
  disabled: boolean;

  minValuePercent: number;
  maxValuePercent: number;
  highlightTrackColor: string;

  constructor() {}

  ngOnInit(): void {
    this.setPercentValues();
    this.highlightTrackColor = this.disabled ? "#888" : "black";
  }

  ngOnChanges(): void {
    this.setPercentValues();
  }

  onMinSliderChange(eventValue) {
    this.minValueModel = eventValue;
    this.setPercentValues();
    this.minValueModelChange.emit(this.minValueModel);
  }
  onMaxSliderChange(eventValue) {
    this.maxValueModel = eventValue;
    this.setPercentValues();
    this.maxValueModelChange.emit(this.maxValueModel);
  }

  // Percent values are used for seting linear-gradiend which
  // highlights slider track between min and max value
  setPercentValues() {
    this.minValuePercent = Math.round((this.minValueModel / this.max) * 100);
    this.maxValuePercent = Math.round((this.maxValueModel / this.max) * 100);
  }
}
