/**
 * Makes a deep copy.
 */
export default function deepCopy(obj: any) {
    
    if (obj !== null && obj !== undefined) {
        return JSON.parse(JSON.stringify(obj))
    }
    return null

    // TODO: test and switch to implementation below

    // // number, string, boolean, undefined
    // if (typeof obj !== 'object') {
    //     return obj
  
    // // object, null
    // } else {
  
    //     if (obj === null) {
    //         return null   
    //     } else if (Array.isArray(obj)) {
    //         const copy = []
    //         for (const element of obj) {
    //             copy.push(deepCopy(element))
    //         }
    //         return copy
    //     } else {
    //         const copy = {}
    //         for (const prop in obj) {
    //             copy[prop] = deepCopy(obj[prop])
    //         }
    //         return copy
    //     }
    // }
  
  }
  