import {Injectable, NgModule, NgZone} from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { distinctUntilChanged, map } from "rxjs/operators";
import { runInZone } from "../utils/rxjs.utils";
import { LodashAccessor } from "./utility.service";
import {
  cloneDeep as _cloneDeep,
  get as _get,
  isEqual as _isEqual,
  set as _set,
} from "lodash";
import { PolytronServiceApi } from "../polytron/polytron.service.api";

enum STATE_KEYS {
  "DeviceDetails",
  "MainWindow", // track window open/closed/minimized state
  "Features", // feature flags, such as experimental support
  "TitleBar", // title bar state for controls (disabled, etc)
  "TestCoverage",
}

/**
 * Changing enum values? Keep in sync with main/main.ts method updateWindowState typing
 *
 * **IMPORTANT** Going from maximized window to minimized (in tray) and then clicking on
 * window to restore has **DIFFERENT** behavior on each OS:
 * -> on Windows, "maximize" event is fired (and thus OPEN_MAXIMIZED window state)
 * -> on Mac, "restore" event is fired
 *
 * On both Windows and Mac, going from non-maximized open window to minimized, then
 * clicking on window both fires "restore" event.
 */
export enum MainWindowState {
  "OPEN" = "OPEN", // window is open following initial app launch, task bar "Open" or restored from a minimized state
  "OPEN_MAXIMIZED" = "OPEN_MAXIMIZED", // window is open AND maximized
  "CLOSED" = "CLOSED", // window is closed (note app is still running in taskbar)
  "MINIMIZED" = "MINIMIZED", // window is minimized
}

type StateKey = keyof typeof STATE_KEYS;

export type State = {
  [key in StateKey]?: { [key: string]: any };
};

export type DeviceControlsState = {
  controlsRoute?: boolean;
  cameraMax?: boolean;
  hideNavigation?: boolean;
};

export type Features = {
  cameraFavorites: boolean;
  experimentalDeviceControlsAndSettings: boolean;
  frequentReminders: boolean;
  experimentalLanguages: boolean;
  acousticEvents: boolean;
  frequentPolicyPolling: boolean;
};

export const defaultFeatures: Features = {
  cameraFavorites: true,
  experimentalDeviceControlsAndSettings: false,
  frequentReminders: false,
  experimentalLanguages: false,
  acousticEvents: false,
  frequentPolicyPolling: false,
};

/**
 * The StateService is designed to assist in inter-component communications.
 *
 * Example use case: the DeviceDetails component needs to be aware of
 * variables from the controls component.
 *
 * This service should be used with caution; if another more specific or existing
 * service can handle data, it probably should. Too many uses of this generic
 * StateService could lead to "spaghetti code".
 *
 * Architecturally, getState$ and getDeepState$ observables used to fire for every update
 * to any part of state service. Since this is undesirable, distinctUntilChanged with
 * a deep comparison is used to prevent duplicate messages and only emit changes. This means
 * that the state service is unsuited for specifically repeated values.
 *
 * For example, given input of([1, 2, 2, 2, 3]), the state service will emit of([1, 2, 3]).
 * If it is important to get all three values of the number 2, would need to use a
 * separate service or observable.
 *
 * It is expected that a growing usage of controller could expose a pattern or grouping
 * that would make sense as a new service.
 */
@Injectable({
  providedIn: "root",
})
export class StateService {
  private _state$: BehaviorSubject<State> = new BehaviorSubject({});

  constructor(private ngZone: NgZone, private polytron: PolytronServiceApi) {
    this.initMainWindowListener();
    this.initFeatures();
  }

  get state$() {
    return this._state$;
  }

  /**
   * Given a key, return a value synchronously.
   * This is handy for generally static values such as feature flags.
   */
  public getState(key: StateKey, defaultValue = {}): any {
    return this._state$.value[key] || defaultValue;
  }

  /**
   * Given a key, return an observable with updated values.
   * Due to state service architecture (see comments above), only changed values are emitted.
   */
  public getState$(key: StateKey): any {
    return this._state$.pipe(
      map((state) => {
        return state[key] || {};
      }),
      distinctUntilChanged((previous, current) => _isEqual(previous, current)),
      runInZone(this.ngZone)
    );
  }

  /**
   * Given an accessor, return an observable with updated values for that specific accessor.
   * Due to state service architecture (see comments above), only changed values are emitted.
   */
  public getDeepState$<T>(
    key: StateKey,
    accessor: LodashAccessor,
    defaultValue = {}
  ): Observable<T> {
    return this._state$.pipe(
      map((state) => {
        return _get(state[key], accessor, defaultValue) as T;
      }),
      distinctUntilChanged((previous, current) => _isEqual(previous, current)),
      runInZone(this.ngZone)
    );
  }

  public setState(key: StateKey, value: any) {
    this._state$.next({
      ...this._state$.value,
      [key]: value,
    });
  }

  /**
   * Update a property of a state given an accessor path.
   *
   * @example { DeviceDetails: { controls: true, other: [ { num: 7 } ] } }
   *
   * @param key - e.g. 'DeviceDetails'
   * @param accessor - lodash style path, e.g. 'controls' or ['other', 0, 'num']
   * @param value
   */
  public setDeepState(key: StateKey, accessor: LodashAccessor, value: any) {
    const stateObject = _cloneDeep(this._state$.value[key]) || {};

    _set(stateObject, accessor, value);

    this.setState(key, stateObject);
  }

  public clearState(key: StateKey) {
    this.setState(key, {});
  }

  private initMainWindowListener() {
    this.polytron.getIpcRenderer().on(
      "__StateService_Main_Window_State__",
      (event, state: MainWindowState) => {
        this.setDeepState("MainWindow", "state", state);
      }
    );

    // initialize MainWindow state
    this.setState("MainWindow", { state: MainWindowState.OPEN });
  }

  private initFeatures() {
    this.setState("Features", defaultFeatures);
  }
}
