import {SETTINGS} from "../../app/utils/constants";
import {RohUrl} from "./roh-message";

export interface RohRange {
  min: string;
  max: string;
  step?: string;
}

export interface RohDeviceSetting {
  id: number;
  name: string;
  url: RohUrl;
  range_fields?:  RohRange;
  mapping?: { [key: string]: string };
}

export const ROH_DEVICE_SETTINGS: RohDeviceSetting[] = [
  { id: parseInt(SETTINGS.TRACKING_MODE), name: 'tracking_mode', url: RohUrl.RU_AUTO_TRACKING,
    mapping: { off: 'Off', group: 'Group', gallery: "Gallery"}},
  { id: parseInt(SETTINGS.FRAME_SIZE), name: 'framing_size', url: RohUrl.RU_AUTO_TRACKING,
    mapping:  { tight: 'Tight', medium: 'Medium', wide: 'Wide'} },
  { id: parseInt(SETTINGS.TRACKING_SPEED), name: 'tracking_speed', url: RohUrl.RU_AUTO_TRACKING,
    mapping: { slow: 'Slow', normal: 'Normal', fast: 'Fast'}},
  { id: parseInt(SETTINGS.BASS_ADJUST), name: 'cur_bass', url: RohUrl.RU_BASS_CONTROL,
    range_fields: {min: 'min_bass', max: 'max_bass'}},
  { id: parseInt(SETTINGS.TREBLE_ADJUST), name: 'cur_treble', url: RohUrl.RU_TREBLE_CONTROL,
    range_fields: {min: 'min_treble', max: 'max_treble'} },
  { id: parseInt(SETTINGS.INVERT), name: 'image_mirror_flip', url: RohUrl.RU_IMAGE_MIRROR_FLIP,
    mapping: { '0': '0', '3': '3'} },
  { id: parseInt(SETTINGS.ACOUSTIC_FENCE), name: 'acoustic_beam', url: RohUrl.RU_AUDIO,
    mapping: { off: 'false', '60': '60', '90': '90', '120': '120', cameraView: 'camera_view'}},
  { id: parseInt(SETTINGS.NOISEBLOCK_RX), name: 'noise_block_ai', url: RohUrl.RU_AUDIO },
  { id: parseInt(SETTINGS.NOISEBLOCK_TX), name: 'noise_blocker', url: RohUrl.RU_AUDIO },
  { id: parseInt(SETTINGS.MAXIMUM_ZOOM), name: 'zoom_level', url: RohUrl.RU_MAXIMUM_ZOOM,
    mapping: {'2x': '2', '3x': '3', '4x': '4'} },
  { id: parseInt(SETTINGS.ON_SCREEN_DISPLAY), name: 'enable', url: RohUrl.RU_ON_SCREEN_DISPLAY },
];
