import { Component, OnInit } from "@angular/core";
import { Location } from "@angular/common";

@Component({
  selector: "connection-checklist",
  templateUrl: "./connection-checklist.component.pug",
})
export class ConnectionChecklistComponent implements OnInit {
  connectionChecklistTitle = "CONNECTION_CHECKLIST.TITLE";
  checklistItems = [
    {
      title: "CONNECTION_CHECKLIST.POWER.TITLE",
      description: "CONNECTION_CHECKLIST.POWER.DESCRIPTION",
      icon: "power_switch",
    },
    {
      title: "CONNECTION_CHECKLIST.BLUETOOTH.TITLE",
      description: "CONNECTION_CHECKLIST.BLUETOOTH.DESCRIPTION",
      icon: "bluetooth",
    },
    {
      title: "CONNECTION_CHECKLIST.SUPPORT.TITLE",
      description: "CONNECTION_CHECKLIST.SUPPORT.DESCRIPTION",
      icon: "support_circle",
      link: {
        text: "CONNECTION_CHECKLIST.SUPPORT.LINK.TEXT",
        route: "/poly-support/app",
      },
    },
  ];

  constructor(private location: Location) {}

  ngOnInit(): void {}

  goBack() {
    this.location.back();
  }
}
