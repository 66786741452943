import { Component, OnInit } from "@angular/core";

@Component({
  selector: "oz-health-wellness",
  templateUrl: "./health-wellness.component.pug",
})
export class HealthWellnessComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
