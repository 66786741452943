
//GIST: the basic idea of this module is to accomplish the following:
// import * as cm from "./api/config-manager.api";
// import * as dm from "./api/device-manager.api";
// import * as dsm from "./api/device-settings-manager.api";
// import * as dfu from "./api/dfu-manager.api";
// import * as em from "./api/events-manager.api";
//
// export { cm, dm, dsm, em, dfu }

import {
  IConfigManager,
  Softphone,
  SoftwareSetting,
} from "./config-manager.api"

import {
  AdminLoginRequest,
  AdminLoginResponse,
  AdminPasswordChangeRequest,
  AdminPasswordChangeResponse,
  AdminPasswordEnabledResponse,
  AgentAudioControl,
  Battery,
  BluetoothInfo,
  BluetoothPairingRequest,
  BluetoothParams,
  BrickedDevice,
  BrickedStatus,
  CertificateDetailsRequest,
  CertificateDetailsResponse,
  CreateCertificateRequest,
  CreateCertificateResponse,
  DeleteCertificateRequest,
  DeleteCertificateResponse,
  Device,
  DeviceNameResponse,
  ExportConfigurationResponse,
  FirmwareVersion,
  GeneralSettings,
  GetCertificateFileResponse,
  GetCertificateInfoResponse,
  GetCrashFilesRequest,
  GetCrashFilesResponse,
  GetGeneralSettingsResponse,
  GetInstalledCertificatesResponse,
  IDeviceManager,
  ImportConfigurationRequest,
  ImportConfigurationResponse,
  InstallCertificateRequest,
  InstallCertificateResponse,
  IsAppInstalledResponse,
  KnownNetworksResponse,
  NeedOOBResponse,
  NetworkProvisioningInfo,
  NetworkProvisioningParams,
  NonPolyDevice,
  RESTDeviceError,
  SaveDeviceLogsStatus,
  ScannedNetwork,
  ScannedNetworksResponse,
  SetBluetoothResponse,
  SetGeneralSettingsResponse,
  SetId,
  SetServerCAValidationRequest,
  SetServerCAValidationResponse,
  SetSimplePasswordRequest,
  SetSimplePasswordResponse,
  SetWifiClientCertPasswordRequest,
  SetWifiClientCertPasswordResponse,
  SimplePasswordResponse,
  StartAudioTestResponse,
  StopAudioTestRequest,
  StopAudioTestResponse,
  WiFiConnectParams,
  WiFiConnectionInfo,
  WiFiStatusResponse,
} from "./device-manager.api"

import {
  DeviceSetting,
  IDeviceSettingsManager,
  AERSSetting,
} from "./device-settings-manager.api";

import {
  BrickedDeviceInfo,
  DFUError,
  DFUOptions,
  DFUStatus,
  DFUUserAction,
  DeviceUpdateProgress,
  IDFUManager,
} from "./dfu-manager.api";

import {
  AcousticIncident,
  CallEvent,
  ConversationAnalyticsEvent,
  DeviceEvent,
  IEventsManager,
  LinkQualityEvent,
  TwaEvent,
} from "./events-manager.api";

import {
  IHubNative,
} from "./hub-native.api";

export {
  AcousticIncident,
  AdminLoginRequest,
  AdminLoginResponse,
  AdminPasswordChangeRequest,
  AdminPasswordChangeResponse,
  AdminPasswordEnabledResponse,
  AERSSetting,
  AgentAudioControl,
  Battery,
  BluetoothInfo,
  BluetoothPairingRequest,
  BluetoothParams,
  BrickedDevice,
  BrickedDeviceInfo,
  BrickedStatus,
  CallEvent,
  CertificateDetailsRequest,
  CertificateDetailsResponse,
  ConversationAnalyticsEvent,
  CreateCertificateRequest,
  CreateCertificateResponse,
  DFUError,
  DFUOptions,
  DFUStatus,
  DFUUserAction,
  DeleteCertificateRequest,
  DeleteCertificateResponse,
  Device,
  DeviceEvent,
  DeviceNameResponse,
  DeviceSetting,
  DeviceUpdateProgress,
  ExportConfigurationResponse,
  FirmwareVersion,
  GeneralSettings,
  GetCertificateFileResponse,
  GetCertificateInfoResponse,
  GetCrashFilesRequest,
  GetCrashFilesResponse,
  GetGeneralSettingsResponse,
  GetInstalledCertificatesResponse,
  IConfigManager,
  IDFUManager,
  IDeviceManager,
  IDeviceSettingsManager,
  IEventsManager,
  IHubNative,
  ImportConfigurationRequest,
  ImportConfigurationResponse,
  InstallCertificateRequest,
  InstallCertificateResponse,
  IsAppInstalledResponse,
  KnownNetworksResponse,
  LinkQualityEvent,
  NeedOOBResponse,
  NetworkProvisioningInfo,
  NetworkProvisioningParams,
  NonPolyDevice,
  RESTDeviceError,
  SaveDeviceLogsStatus,
  ScannedNetwork,
  ScannedNetworksResponse,
  SetBluetoothResponse,
  SetGeneralSettingsResponse,
  SetId,
  SetServerCAValidationRequest,
  SetServerCAValidationResponse,
  SetSimplePasswordRequest,
  SetSimplePasswordResponse,
  SetWifiClientCertPasswordRequest,
  SetWifiClientCertPasswordResponse,
  SimplePasswordResponse,
  Softphone,
  SoftwareSetting,
  StartAudioTestResponse,
  StopAudioTestRequest,
  StopAudioTestResponse,
  TwaEvent,
  WiFiConnectParams,
  WiFiConnectionInfo,
  WiFiStatusResponse,
}
