import { Injectable } from "@angular/core";
import { EMPTY, Observable, of, timer } from "rxjs";
import { AdminConfig } from "../admin-config.service";
import {
  IRepository,
  RepositoryDisplayLink,
  RepositoryFirmware,
  RepositoryProductCatalog,
  RepositorySoftwareOptions,
  RepositorySoftware,
  RepositoryDeviceResources,
  RepositoryDevicePolicy,
  RepositoryDevicesUsage,
  RepositoryFirmwareArchiveDownloadOptions,
  RepositoryFirmwareArchiveDownloadProgress,
  RepositoryFirmwareOptions,
  RepositoryProductCatalogOptions,
  RepositoryDevicePolicyOptions,
} from "./model";
import path from "path";
import { OzFileSystem } from "../file-system.service";
import { map, shareReplay, switchMap } from "rxjs/operators";

@Injectable({
  providedIn: "root",
  useFactory: (
    adminConfig: AdminConfig,
    fileSystem: OzFileSystem
  ): IRepository => {
    return adminConfig.mode !== "network"
      ? null
      : new OnPremRepository(adminConfig, fileSystem);
  },
  deps: [AdminConfig, OzFileSystem],
})
export class OnPremRepository implements IRepository {
  constructor(
    private adminConfig: AdminConfig,
    private fileSystem: OzFileSystem
  ) {}

  getDisplayLink(platform: "win" | "mac"): Observable<RepositoryDisplayLink> {
    return of({
      productBuild: {
        archiveUrl: this.adminConfig.displayLinkDriverPath,
      },
    } as RepositoryDisplayLink);
  }

  getSoftware(
    options: RepositorySoftwareOptions
  ): Observable<RepositorySoftware> {
    return of(null);
  }

  getProductCatalog(
    options: RepositoryProductCatalogOptions
  ): Observable<RepositoryProductCatalog> {
    return EMPTY;
  }

  getFirmware(
    options: RepositoryFirmwareOptions
  ): Observable<RepositoryFirmware> {
    if (this.adminConfig.dfuPath) {
      let rulesPath = path.join(
        this.adminConfig.dfuPath,
        options.device.pid.toString(16),
        "rules.json"
      );
      let archiveLocation = path.join(
        this.adminConfig.dfuPath,
        options.device.pid.toString(16),
        "dfu.zip"
      );

      return timer(0, options.pollInterval).pipe(
        switchMap(() => {
          return this.fileSystem.readFile(rulesPath).pipe(
            map(({ content, success }) => {
              return success
                ? ({
                    archiveLocation,
                    rules: JSON.parse(content),
                  } as RepositoryFirmware)
                : null;
            })
          );
        }),
        shareReplay(1)
      );
    } else {
      return EMPTY;
    }
  }

  getDeviceResources(pid: number): Observable<RepositoryDeviceResources> {
    return of(null);
  }

  getDevicePolicy(
    options: RepositoryDevicePolicyOptions
  ): Observable<RepositoryDevicePolicy> {
    return EMPTY;
  }

  getDevicesUsage(tenantId: string): Observable<RepositoryDevicesUsage> {
    return EMPTY;
  }

  getMyIP(): Observable<string> {
    return EMPTY;
  }

  getFirmwareArchive(
    options: RepositoryFirmwareArchiveDownloadOptions
  ): Observable<RepositoryFirmwareArchiveDownloadProgress> {
    return this.fileSystem.copyFile(options.archiveLocation, options.path).pipe(
      map((success) => {
        return {
          progress: 100,
          status: success ? "success" : "failed",
        } as RepositoryFirmwareArchiveDownloadProgress;
      })
    );
  }

  setDevicePolicyFirmwareSetting(
    pid: number,
    deviceId: string,
    settings: { archive_url: string; rules_json: string }
  ): void {}
}
