import { Component, OnInit } from "@angular/core";
import { UtilityService } from "../../services/utility.service";

@Component({
  selector: "oz-bp-camera",
  templateUrl: "./camera.component.pug",
})
export class BPCameraComponent implements OnInit {
  cards = [
    {
      title: "CAMERA.MOUNTING.TITLE",
      description: "CAMERA.MOUNTING.DESCRIPTION",
      logo: "camera_mounting",
      id: "mounting",
    },
    {
      title: "CAMERA.CENTERING.TITLE",
      description: "CAMERA.CENTERING.DESCRIPTION",
      logo: "camera_centering",
      id: "centering",
    },
    {
      title: "CAMERA.LIGHTING.TITLE",
      description: "CAMERA.LIGHTING.DESCRIPTION",
      logo: "camera_lighting",
      id: "lighting",
    },
    {
      title: "CAMERA.BACKGROUND.TITLE",
      description: "CAMERA.BACKGROUND.DESCRIPTION",
      logo: "camera_background",
      id: "background",
    },
  ];

  constructor() {}

  ngOnInit(): void {
    UtilityService.scrollToTop("scrollable-content");
  }
}
