import { Injectable } from "@angular/core";
import { DeviceManagerService } from "./device-manager.service";
import {
  NetworkProvisioningParams,
  NetworkProvisioningInfo,
} from "@poly/hub-native";
import { from, Observable } from "rxjs";
import { Toasts } from "../shared/components/toast/toasts.service";

@Injectable({
  providedIn: "root",
})
export class ProvisioningServer {
  constructor(
    private deviceManager: DeviceManagerService,
    private toasts: Toasts
  ) {}

  setParams(request: NetworkProvisioningParams): void {
    this.deviceManager
      .setNetworkProvisioning(request)
      .then((res: NetworkProvisioningInfo) => {
        this.toasts.push({
          type: "status",
          status: res.status === "OK" ? "success" : "error",
          text:
            res.status === "OK"
              ? "NOTIFICATIONS.CHANGE_PROVISIONING_CONFIG_SUCCEEDED"
              : "NOTIFICATIONS.CHANGE_PROVISIONING_CONFIG_FAILED",
        });
      });
  }

  getParams(deviceId: string): Observable<NetworkProvisioningParams> {
    return from(this.deviceManager.getNetworkProvisioning(deviceId));
  }
}
