import { Injectable } from "@angular/core";
// import * as remote from "@electron/remote";

/**
 * RemoteService is just a wrapper for electron/remote module
 * This service is mocked in mock-providers
 */
@Injectable({
  providedIn: "root",
})
export class RemoteService {
  constructor() {}

  // getRemote() {
  //   return remote;
  // }
}
