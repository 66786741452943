import { Device, FirmwareVersion } from "@poly/hub-native";
import {
  ComponentVersion,
  DeviceInfoConnection,
  DeviceSpecificInfo,
} from "./device.message.json.subobjects";
import { AuthProfile } from "../../auth.service";
import { IotService } from "../../iot.service";
import { DEVICE_TYPE } from "../../../utils/constants";
export class DeviceMessagingUtils {
  static encryptedName: string;
  static encryptedMail: string;
  static encryptedUserName: string;

  public generateDeviceConnection(
    deviceId: string,
    targetDeviceId: string,
    device: Device,
    viaConnections: string[],
    targetEntityType: EntityType
  ): DeviceInfoConnection {
    const connectionType = this.getConnectionProtocol(device);
    const protocol = viaConnections.length > 0 ? null : connectionType;
    return {
      connected: device.isConnected,
      from: deviceId,
      to: targetDeviceId,
      protocol: protocol,
      via: viaConnections,
      targetEntityType: targetEntityType,
      stats: {
        type: protocol,
      },
      // stats: {
      //   type: "", //TODO NA at this moment (waiting for native)
      //   version: "", //TODO NA at this moment (waiting for native)
      //   bitrate: 0, //TODO NA at this moment (waiting for native)
      // },
    };
  }

  public generateLensAppConnection(
    lensAppId: string,
    isAuthenticated: boolean,
    userProfile: AuthProfile
  ): DeviceInfoConnection {
    if (isAuthenticated && userProfile.updated_at !== null) {
      return {
        connected: isAuthenticated,
        from: lensAppId,
        to: userProfile ? userProfile.sub : "",
        protocol: DeviceProtocol.AUTH,
        targetEntityType: EntityType.USER,
        via: [],
        stats: {
          type: DeviceProtocol.AUTH,
        },
      };
    } else {
      //loginless flow will provide mail & fullName & userName
      return {
        connected: isAuthenticated,
        from: lensAppId,
        to: userProfile ? userProfile.sub : "",
        mail: DeviceMessagingUtils.encryptedMail,
        fullName: DeviceMessagingUtils.encryptedName,
        userName: DeviceMessagingUtils.encryptedUserName,
        protocol: DeviceProtocol.AUTH,
        targetEntityType: EntityType.USER,
        via: [],
        stats: {
          type: DeviceProtocol.AUTH,
        },
      };
    }
  }

  public generateDeviceSpecificInfo(
    device: Device,
    formFactor = "",
    genesSerial?: string
  ): DeviceSpecificInfo {
    return {
      type: "", //TODO NA at this moment (waiting for native)
      buildCode: device.productBuildCode?.base,
      attributes: this.getAttributes(device, formFactor),
      versionHash: "", //TODO NA at this moment (waiting for native)
      componentVersions: this.toComponentVersion(device.firmwareVersion),
      genesSerialNumber: !!genesSerial
        ? genesSerial
        : device.serialNumber?.base,
      tattooSerialNumber: device.productSerialNumber?.base,
      modelId: device.modelId,
      headsetType: device.headsetType,
      hardwareRevision: device.manufacturerInfo?.hardwareVersion,
    };
  }

  public toComponentVersion(
    firmwareVersion: FirmwareVersion
  ): ComponentVersion {
    return {
      base: String(firmwareVersion.base),
      bluetooth: String(firmwareVersion.bluetooth),
      headset: String(firmwareVersion.headset),
      pic: String(firmwareVersion.pic),
      tuning: String(firmwareVersion.tuning),
      usb: String(firmwareVersion.usb),
    };
  }

  public getParentUniqueId(
    device: Device,
    allDevices: Device[],
    lensAppId: string
  ): string {
    let parent;
    if (device.parentDeviceId) {
      parent = allDevices.find(
        (d) => d.id == device.parentDeviceId && d.pid != device.pid
      );
    }
    return parent ? parent.uniqueId : lensAppId;
  }

  public getConnectionProtocol(device: Device): string {
    return device.parentDeviceId
      ? DeviceProtocol.BLUETOOTH
      : DeviceProtocol.USB;
  }

  public getAttributes(device: Device, formFactor?: string): any[] {
    let attributes: any[] = [];
    if (typeof device.isTeamsSKU === "boolean") {
      attributes.push({ msTeams: device.isTeamsSKU });
    }
    if (typeof device.isUSBTypeC === "boolean") {
      attributes.push({ usbType: device.isUSBTypeC ? "C" : "A" });

      if (device.deviceType === DEVICE_TYPE.EARBUDS) {
        attributes.push({
          "dongle-type": device.isUSBTypeC ? "usb-c" : "usb-a",
        });
      }
    }
    if (!!formFactor) {
      attributes.push({ formFactor });
    }

    return attributes;
  }

  public getEntityType(deviceId: string, lensAppId: string) {
    return deviceId === lensAppId ? EntityType.APPLICATION : EntityType.DEVICE;
  }

  public getViaConnections(
    device: Device,
    targetDeviceId: string,
    allDevices: Device[]
  ): string[] {
    let parent;
    const via = [];

    allDevices.forEach(() => {
      if (device?.parentDeviceId) {
        parent = allDevices.find(
          (d) => d.id == device.parentDeviceId && d.pid != device.pid
        );
        if (parent && parent.uniqueId !== targetDeviceId) {
          via.push(parent?.uniqueId);
        }

        device = parent;
      } else {
        return via;
      }
    });

    return via;
  }

  public getHexString(value: any): string {
    return (value || 0).toString(16);
  }
}

export class RandomTimeInDayObject {
  public hour: number;
  public minute: number;
  public second: number;

  constructor(hour: number, minute: number, second: number) {
    this.hour = hour;
    this.minute = minute;
    this.second = second;
  }

  public toString() {
    let timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    return (
      "" + this.hour + ":" + this.minute + ":" + this.second + " " + timezone
    );
  }
}

export enum DeviceInfoUpdateType {
  REMOVED = "REMOVED",
  ADDED = "ADDED",
  CHANGED = "CHANGED",
}

export enum DeviceProtocol {
  BLUETOOTH = "bluetooth",
  USB = "USB",
  IP = "IP",
  HDMI = "HDMI",
  UNKNOWN = "UNKNOWN",
  AUTH = "auth",
}

export enum EntityType {
  DEVICE = "device",
  USER = "user",
  APPLICATION = "application",
}
