import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";

@Component({
  selector: "oz-input-time",
  templateUrl: "./input-time.component.pug",
})
export class InputTimeComponent implements OnInit {
  @Input() value: string;
  @Input() step: number = 60;

  @Output() valueChanged = new EventEmitter<string>();

  constructor() {}

  ngOnInit(): void {}

  onTimeChange(event) {
    this.valueChanged.emit(event);
  }
}
