import { EulaService } from "./eula.service";
import { Injectable, NgZone, OnDestroy } from "@angular/core";
import { Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { BehaviorSubject, Subscription } from "rxjs";
import { ConfigService } from "./config.service";
import { DeviceManagerService, OzDevice } from "./device-manager.service";
// note TrayTyped because Tray will be undefined
import { filter, take } from "rxjs/operators";
import { LensSettingsService } from "./lens-settings.service";
import { MainWindowState, StateService } from "./state.service";
import { UtilityService } from "./utility.service";
import { BATTERY } from "../utils/constants";
import { OzDeviceWithRepositoryFirmware, Dfu } from "./dfu.service";
import * as path from "path";
import { sortDevices } from "../utils/utils";
import { AgentAudioControl } from "@poly/hub-native";
import {PolytronServiceApi} from "../polytron/polytron.service.api";

// TODO: const fs = require("fs");

export enum DeviceStatus {
  UNKNOWN,
  MUTED,
  CONNECTED,
  DISCONNECTED,
  // see also documentation later in code about battery state mapping
  CHARGED_0,
  UPDATE,
}

@Injectable({
  providedIn: "root",
})
export class TrayService implements OnDestroy {
  private soundscapeSub: Subscription;
  private stateServiceSub: Subscription;
  // combination of lensSettings - if soundscape disabled, will be undefined; otherwise a string
  private soundscape$: BehaviorSubject<
    string | undefined
  > = new BehaviorSubject(undefined);
  // local tracking of devices; exists so updateMenu can be called without parameters,
  // anytime an update is needed across devices, soundscaping and future uses
  // public not private to enable testing of various functions
  private devices$: BehaviorSubject<
    OzDeviceWithRepositoryFirmware[] | undefined
  > = new BehaviorSubject(undefined);
  // TODO: private tray: TrayTyped;
  private translations = {
    OPEN: "",
    NO_DEVICES: "",
    QUIT: "",
    SOUNDSCAPING: "",
    MDA_AUDIO_RECORDING: "",
    MDA_AUDIO_RECORDING_START: "",
    MDA_AUDIO_RECORDING_STOP: "",
    OFF: "",
  };
  private translationsFinished = false;
  private showOpenItem = false;
  private get ICON_DIR_PATH() {
    return path.join(
      path.dirname(
        UtilityService.removeStringAfter(
          this.polytron.getAppPath(),
          "node_modules/"
        )
      ),
      "assets",
      "tray"
    );
  }
  // included in this service since soundscaping.component.ts
  public soundscapeOptions$: BehaviorSubject<any> = new BehaviorSubject([]);

  /**
   * Generate class.
   *
   * Developer references
   * @help https://www.electronjs.org/docs/api/tray
   * @help https://livebook.manning.com/book/electron-in-action/chapter-9/46
   */
  constructor(
    private configService: ConfigService,
    private lensSettingsService: LensSettingsService,
    private translateService: TranslateService,
    private router: Router,
    private ngZone: NgZone,
    private eulaService: EulaService,
    private stateService: StateService,
    private deviceManager: DeviceManagerService,
    private dfu: Dfu,
    private polytron: PolytronServiceApi,
  ) {
    this.init();
  }

  init() {
    this.destroyTray();
    this.eulaService.agreed$
      .pipe(
        filter((v) => v),
        take(1)
      )
      .subscribe(() => {
        this.createTray();

        // capture the case of `ng:serve` hot reloading to ensure tray (with icon) is destroyed properly
        window.onbeforeunload = () => this.destroyTray();

        this.translateService
          .stream([
            "MAIN.TRAY.OPEN",
            "MAIN.TRAY.NO_DEVICES",
            "MAIN.TRAY.QUIT",
            "MAIN.TRAY.SOUNDSCAPING",
            "MAIN.TRAY.MDA_AUDIO_RECORDING",
            "MAIN.TRAY.MDA_AUDIO_RECORDING_START",
            "MAIN.TRAY.MDA_AUDIO_RECORDING_STOP",
            "MAIN.TRAY.OFF",
            "HEALTH_AND_WELLNESS.SOUNDSCAPING.MOUNTAIN_RANCH",
            "HEALTH_AND_WELLNESS.SOUNDSCAPING.GENTLE_OCEAN",
            "HEALTH_AND_WELLNESS.SOUNDSCAPING.BABBLING_BROOK",
            "HEALTH_AND_WELLNESS.SOUNDSCAPING.GENTLE_RAIN",
          ])
          .subscribe((translations) => {
            ({
              "MAIN.TRAY.OPEN": this.translations.OPEN,
              "MAIN.TRAY.NO_DEVICES": this.translations.NO_DEVICES,
              "MAIN.TRAY.QUIT": this.translations.QUIT,
              "MAIN.TRAY.SOUNDSCAPING": this.translations.SOUNDSCAPING,
              "MAIN.TRAY.MDA_AUDIO_RECORDING": this.translations
                .MDA_AUDIO_RECORDING,
              "MAIN.TRAY.MDA_AUDIO_RECORDING_START": this.translations
                .MDA_AUDIO_RECORDING_START,
              "MAIN.TRAY.MDA_AUDIO_RECORDING_STOP": this.translations
                .MDA_AUDIO_RECORDING_STOP,
              "MAIN.TRAY.OFF": this.translations.OFF,
            } = translations);

            // update soundscaping names
            const options = translations
              ? [
                  {
                    value: "soundscapes/mountain_ranch/mountain_ranch_1",
                    text:
                      translations[
                        "HEALTH_AND_WELLNESS.SOUNDSCAPING.MOUNTAIN_RANCH"
                      ],
                  },
                  {
                    value: "soundscapes/gentle_ocean/gentle_ocean_1",
                    text:
                      translations[
                        "HEALTH_AND_WELLNESS.SOUNDSCAPING.GENTLE_OCEAN"
                      ],
                  },
                  {
                    value: "soundscapes/babbling_brook/babbling_brook_1",
                    text:
                      translations[
                        "HEALTH_AND_WELLNESS.SOUNDSCAPING.BABBLING_BROOK"
                      ],
                  },
                ]
              : [];

            this.soundscapeOptions$.next(options);

            this.translationsFinished = true;
            this.updateMenu();
          });

        this.deviceManager.getConnectedDevices().subscribe((devices) => {
          this.updateDevices(devices);
          this.updateMenu();
        });

        this.dfu.allConnectedDevices().subscribe((devices) => {
          this.updateDevices(devices);
          this.updateMenu();
        });

        this.soundscapeSub = this.lensSettingsService.lensSettings.subscribe(
          (settings) => {
            if (!settings["soundscapeEnabled"]) {
              this.soundscape$.next(undefined);
            } else {
              this.soundscape$.next(settings["soundscapeSound"]);
            }

            this.updateMenu();
          }
        );
      });
  }

  /**
   * Considered public to enable testing.
   *
   * @param devices
   */
  public updateDevices(devices: OzDeviceWithRepositoryFirmware[]) {
    // copy devices to local BehaviorSubject
    this.devices$.next(devices);
  }

  /**
   * from status, determine what the prefix should be
   * for example, DeviceStatus.MUTED returns "muted", which can then
   * associate with the image `muted_black.ico`
   * IMPORTANT: altering iconPrefix values? icon.service.ts and secret.component.pug will also change
   */
  private getIconPrefix = (status: DeviceStatus): string => {
    let iconPrefix;

    switch (status) {
      case DeviceStatus.MUTED:
        iconPrefix = "muted";
        break;
      case DeviceStatus.CHARGED_0:
        iconPrefix = "0";
        break;
      case DeviceStatus.CONNECTED:
        iconPrefix = "connected";
        break;
      case DeviceStatus.UPDATE:
        iconPrefix = "update";
        break;
      case DeviceStatus.DISCONNECTED:
      case DeviceStatus.UNKNOWN:
      default:
        iconPrefix = "disconnected";
        break;
    }

    return iconPrefix;
  };

  /* TODO:
  private getIconNativeImage = (status: DeviceStatus): NativeImage => {
    const iconPrefix = this.getIconPrefix(status);

    // icon is relative to src/renderer/assets/icons/tray
    const colorVariant = this.configService.isOSDarkThemeEnabled()
      ? "white"
      : "black";

    // if changing png/ico variants, please update the secret.component.ts changeTrayIcon method
    // important note per https://www.electronjs.org/docs/api/native-image,
    // Mac High DPI icons are automatically considered for this PNG based on the @2x, @3x, @4x and @5x suffix
    // e.g. connected_white.png and high DPI connected_white@2x.png
    let icon = `${iconPrefix}_${colorVariant}.png`;

    // this path assumes a Windows build; may need tweaking to work for
    // Windows serve `npm run start`
    if ("win32" === process.platform) {
      // colorVariant does not yet vary on Windows platform as Windows 10 tray is always dark,
      // and older Windows OS versions have not yet been integrated

      // note this ICO file includes multiple resolutions, and is the
      // white variant (good for a dark background like Windows 10)
      const windowsColorVariant = "white";
      icon = `${iconPrefix}_${windowsColorVariant}.ico`;
    }

    const fullIconPath = path.join(this.ICON_DIR_PATH, icon);

    if (fs.existsSync(fullIconPath)) {
      return remote.nativeImage.createFromPath(fullIconPath);
    } else {
      // NOTE this condition may occur during karma testing, and can be safely ignored.
      // example output: `Could not find a file at icon path /Users/me/app/node_modules/
      // karma-electron/lib/src/renderer/assets/icons/tray/disconnected_black_16.png,
      // returning an empty image.`
      console.error(
        `Could not find a file at icon path ${fullIconPath}, returning an empty image.`
      );

      return remote.nativeImage.createEmpty();
    }
  };
   */

  private createTray = () => {
    try {
      // NOTE can initialize with an icon file
      /* TODO:
      this.tray = new remote.Tray(
        this.getIconNativeImage(DeviceStatus.UNKNOWN)
      );
      if ("win32" === process.platform) {
        this.tray.on("click", () => this.tray.popUpContextMenu());
      }
      this.tray.setToolTip("Poly Lens");
       */

      this.updateMenu();

      this.stateServiceSub = this.stateService
        .getDeepState$("MainWindow", "state", MainWindowState.OPEN)
        .subscribe((state: MainWindowState) => {
          // show open item when the main window is NOT open
          this.showOpenItem =
            state !== MainWindowState.OPEN &&
            state !== MainWindowState.OPEN_MAXIMIZED;

          this.updateMenu();
        });
    } catch (e) {
      console.error("Error initializing Tray", e);
    }
  };

  private updateMenu = (): void => {
    /* TODO:
    if (!this.tray || !this.translationsFinished) {
      // tray not set up, delay
      setTimeout(() => {
        this.updateMenu();
      }, 300);

      return;
    }

    const separator = new remote.MenuItem({ type: "separator" });

    const openAppWindowMenuItem = new remote.MenuItem({
      label: this.translations.OPEN,
      click() {
        ipcRenderer.send("__AppWindow_Restored__");
      },
    });

    const unsortedDevices = this.devices$.value;
    let deviceMenuItems: any[] = [];

    // handle no devices found
    if (!unsortedDevices || !unsortedDevices.length) {
      deviceMenuItems = [
        {
          label: this.translations.NO_DEVICES,
        },
      ];
    } else {
      const { devices } = sortDevices(unsortedDevices);

      // handle devices found
      deviceMenuItems = devices.map((device: OzDevice) => {
        // add black four-pointed star, or "U+2726" character for primary device
        const suffix = device.isPrimary ? " ✦" : "";

        return {
          label: device.name + suffix,
          click: () => {
            this.ngZone.run(() => {
              ipcRenderer.send("__AppWindow_Restored__");
              this.router.navigate(["/detail", device.uniqueId]);
            });
          },
        };
      });
    }

    const status = this.getDeviceStatus();

    this.tray.setImage(this.getIconNativeImage(status));

    const indent = "     ";
    let soundscapeMenuItems = this.soundscapeOptions$
      .getValue()
      .map((option) => {
        const soundscapeSelected = this.soundscape$.getValue() === option.value;
        return {
          label: indent + option.text,
          // if radio, no way to uncheck an item (thus muting soundscapes)
          type: "checkbox",
          checked: soundscapeSelected,
          click: () => {
            this.soundscapeAction(option.value, soundscapeSelected);
          },
        } as any;
      });

    if (soundscapeMenuItems.length) {
      // OFF option
      // const soundscapeSelected = this.soundscape$.getValue() === option.value;
      const soundscapeIsOff = !this.soundscape$.getValue();
      soundscapeMenuItems.unshift({
        label: indent + this.translations.OFF,
        // if radio, no way to uncheck an item (thus muting soundscapes)
        type: "checkbox",
        checked: soundscapeIsOff,
        click: () => {
          this.soundscapeOff();
        },
      });

      soundscapeMenuItems.unshift(separator, {
        label: this.translations.SOUNDSCAPING,
        enabled: false,
      });
    }

    // MDA5XX Audio Recording while muted enabled
    let mdaMenuItems = [];
    const devices = this.devices$.value;
    const isPrimaryArr = devices?.length
      ? devices?.filter((device) => device.isPrimary)
      : [];
    if (isPrimaryArr.length) {
      const primaryDevice = isPrimaryArr[0];
      if (primaryDevice.recordVoiceNotes === "disabled") {
        mdaMenuItems = [
          separator,
          {
            label: this.translations.MDA_AUDIO_RECORDING,
            enabled: false,
          },
        ];
      }
      if (primaryDevice.recordVoiceNotes === "off") {
        mdaMenuItems = [
          separator,
          {
            label: this.translations.MDA_AUDIO_RECORDING_START,
            enabled: true,
            click: () => {
              const aac: AgentAudioControl = {
                command: "recordVoiceNotes",
                value: true,
              };
              this.deviceManager.setAacSetting(aac);
            },
          },
        ];
      }
      if (primaryDevice.recordVoiceNotes === "on") {
        mdaMenuItems = [
          separator,
          {
            label: this.translations.MDA_AUDIO_RECORDING_STOP,
            enabled: true,
            click: () => {
              const aac: AgentAudioControl = {
                command: "recordVoiceNotes",
                value: false,
              };
              this.deviceManager.setAacSetting(aac);
            },
          },
        ];
      }
    }

    // note cannot use default {role: "quit"} because of possible need for translation
    const quitMenuItems = [
      separator,
      {
        label: this.translations.QUIT,
        // future Mac use: accelerator provides a method like Cmd + Q
        click() {
          this.polytron.quit();
        },
      },
    ];

    // separators are conditionally included in the "items" array
    let menu = remote.Menu.buildFromTemplate([
      ...deviceMenuItems,
      ...soundscapeMenuItems,
      ...mdaMenuItems,
      ...quitMenuItems,
    ]);

    if (this.showOpenItem) {
      menu.insert(0, separator);
      menu.insert(0, openAppWindowMenuItem);
    }

    this.tray.setContextMenu(menu);

     */
  };

  /**
   * As there are potentially multiple devices connected at same time, follow a logic flow to decide which
   * status tray icon should be showing.
   *
   * Icon source: https://www.figma.com/file/xKCzhmhXBSUzHn1K4TKlMR/Poly_Icon_Library?node-id=0%3A1658
   *
   * No devices: disconnected icon
   * 1+ devices: select preferred device, see also getPreferredDevice(...) function
   *   If battery device selected:
   *     Charging? headset/charging (Windows) icon
   *     Not charging? Most appropriate of 5 power indicator icons
   *   If wired device selected: connected icon
   */
  getDeviceStatus() {
    const devices = this.devices$.value;
    if (!devices || !devices.length) {
      return DeviceStatus.DISCONNECTED;
    } else {
      // If update is available for any connected device
      if (devices.find((device) => device.repositoryFirmware)) {
        return DeviceStatus.UPDATE;
      }

      // has devices, get preferred device to key the icon from
      const device = this.getPreferredDevice();

      if (device) {
        // per device-manager.api.d.ts, isMuted can only be trusted if it is the primary device
        // Oct 2020 update: this is now considered a bug, so using device.isMuted bare
        // so that icons will reflect properly when that is fixed
        if (device.isMuted) {
          return DeviceStatus.MUTED;
        }

        // note device.battery may be `null`, even for battery devices (Oct 2020: this is a known bug and is being worked on)
        // if device.battery is set, typing is indicated in device-manager.api.d.ts
        if (device.battery) {
          if (device.battery.level >= 0) {
            switch (device.battery.level) {
              case BATTERY.PERCENT_0:
                return DeviceStatus.CHARGED_0;
              case BATTERY.PERCENT_25:
                // NOTE there is no 25% charged, also consider 25% charge a low charge
                return DeviceStatus.CHARGED_0;

              // unknown, don't return any battery status
              case BATTERY.UNKNOWN:
              default:
                break;
            }
          }
        }
      }

      // no more specific icons available, fallback to the CONNECTED icon
      return DeviceStatus.CONNECTED;
    }
  }

  /**
   * Given an array of devices, return the primary device.
   * If not found, return the first battery device.
   * If not found, return the first device.
   */
  private getPreferredDevice(): OzDevice | undefined {
    const devices = this.devices$.value;

    if (!devices || !devices.length) {
      return undefined;
    }

    // there are more CPU efficient ways to do this (via lodash or a special Array.some call),
    // but performance difference wouldn't be considerable or noticeable when filtering < 1000 devices
    const isPrimaryArr = devices.filter((device: OzDevice) => device.isPrimary);

    if (isPrimaryArr.length) {
      return isPrimaryArr[0];
    }

    const hasBatteryStatusArr = devices.filter(
      (device: OzDevice) => !!device.battery
    );

    if (hasBatteryStatusArr.length) {
      return hasBatteryStatusArr[0];
    }

    // nothing found, return first device
    return devices[0];
  }

  private soundscapeAction(soundscapeClicked: string, isSelected: boolean) {
    // if soundscape clicked is currently selected, just turn off soundscape
    if (isSelected) {
      return this.soundscapeOff();
    }

    this.lensSettingsService.patchLensSettings({
      // may or may not already be enabled; however, clicking a sound
      // should always enable
      soundscapeEnabled: true,
      soundscapeSound: soundscapeClicked,
    });
  }

  private soundscapeOff() {
    return this.lensSettingsService.patchLensSettings({
      soundscapeEnabled: false,
    });
  }

  generateIconVariants(
    os: "mac" | "windows" | "all",
    color: "white" | "black"
  ) {
    const icons = {};

    for (const status of Object.values(DeviceStatus)) {
      // retrieve the icon prefix, e.g. "0" or "disconnected"
      const iconPrefix = this.getIconPrefix(status as DeviceStatus);
      const osNormalized = "all" === os ? "" : os + "_";
      const path = `tray_${osNormalized}${iconPrefix}_${color}`;

      // using object keys to eliminate duplicates
      icons[path] = true;
    }

    return Object.keys(icons);
  }

  temporarilyOverrideTrayIcon(icon: string) {
    // TODO: this.tray.setImage(path.join(this.ICON_DIR_PATH, icon));
  }

  ngOnDestroy() {
    this.destroyTray();
    this.soundscapeSub?.unsubscribe();
    this.stateServiceSub?.unsubscribe();
  }

  destroyTray() {
    // cleanup with `ng:serve` developer scenario in mind to avoid multiple tray icons
    /* TODO:
    if (this.tray) {
      this.tray.destroy();
    }
     */
  }
}
