import { Device } from "@poly/hub-native";
import { LensDeviceStatus } from "./../device.event.service";

export class DeviceStatusEventDataBuilder {
  public translateToDeviceStatusEventData(
    device: Device | LensDeviceStatus
  ): DeviceStatusEventData {
    let deviceEventData: DeviceStatusEventData = {
      deviceId: device?.uniqueId,
      muted: device?.isMuted,
      connected: device?.isConnected,
    };
    if (device?.battery) {
      deviceEventData.battery = {
        level: device.battery.level,
        charging: device.battery.charging,
      };
    } else {
      deviceEventData.battery = null;
    }
    return deviceEventData;
  }
}

export type DeviceStatusEventData = {
  deviceId: string;
  connected: boolean;
  muted: boolean;
  battery?: {
    level: number;
    charging: boolean;
  };
};
