import { Injectable } from "@angular/core";
import { UtilityService } from "./utility.service";
import { get as _get } from "lodash";

// TODO: const os = require("os");
// TODO: const si = require("systeminformation");

/**
 * While there are packages that handle os.platform and os.release handling
 * for Mac, they are not well maintained.
 * This mapping is loosely based off https://github.com/sindresorhus/macos-release/blob/main/index.js
 *
 * As of August 2021, this mapping is a fallback, and thus can be casually maintained
 * as developers notice updates on:
 * https://en.wikipedia.org/wiki/Darwin_%28operating_system%29#Release_history
 */
const fallbackMacOSVersions = {
  "21.0.0": ["Monterey", "12.0 beta 1"],
  "21": ["Monterey", "12"],
  "20.6.0": ["Big Sur", "11.5"],
  "20.5.0": ["Big Sur", "11.4"],
  "20.4.0": ["Big Sur", "11.3"],
  "20.3.0": ["Big Sur", "11.2"],
  "20.2.0": ["Big Sur", "11.1"],
  "20.1.0": ["Big Sur", "11.0"],
  "20.0.0": ["Big Sur", "11.0 beta 1"],
  "20": ["Big Sur", "11"],
  "19": ["Catalina", "10.15"],
  "18": ["Mojave", "10.14"],
  "17": ["High Sierra", "10.13"],
  "16": ["Sierra", "10.12"],
  "15": ["El Capitan", "10.11"],
  "14": ["Yosemite", "10.10"],
  "13": ["Mavericks", "10.9"],
  "12": ["Mountain Lion", "10.8"],
  "11": ["Lion", "10.7"],
  "10": ["Snow Leopard", "10.6"],
  "9": ["Leopard", "10.5"],
  "8": ["Tiger", "10.4"],
  "7": ["Panther", "10.3"],
  "6": ["Jaguar", "10.2"],
  "5": ["Puma", "10.1"],
};

@Injectable({
  providedIn: "root",
})
export class OsService {
  constructor() {
    this.hostPlatform = navigator.platform;
    //SEE: https://stackoverflow.com/questions/71392342/property-useragentdata-does-not-exist-on-type-navigator
    if ('userAgentData' in navigator) {
      const uaData: any = navigator['userAgentData'];
      this.hostOs = uaData.platform;
      const brands = uaData.brands;
      let browser = brands[0];  // Google Chrome or Microsoft Edge
      this.browser = browser.brand;
      this.browserVer = browser.version;
    }
  }

/* TODO:
  get networkInterfaces() {
    return os.networkInterfaces();
  }
*/

  /**
   * For example, "Mac OS X", "macOS" or "Windows"
   */
  get platform() {
    return this.release + ' (' + this.host + ')';
  }

  /**
   * HOW THIS WORKS
   * To determine Mac OS version, three methods are used:
   * 1. Running `sw_vers -productVersion`
   * 2. Fallback: the fallbackMacOSVersions mapping
   * 3. Fallback: raw os.release() combined with os.platform(), e.g. "Darwin 20.50.0"
   */
    get release() {
      return this.browser + ' ' + this.browserVer
  }

  get host() {
      return this.hostOs + '/' + this.hostPlatform;
  }
/*
  get arch() {
    return os.arch();
  }
*/

  get build() {
    let build = "";

    /* TODO:
        if (UtilityService.isMac()) {
          try {
            build = require("child_process")
              .execSync("sw_vers -buildVersion")
              .toString()
              .trim();
          } catch (e) {}
        }

       if (UtilityService.isWindows()) {
          const release = os.release().split(".");
          if (3 === release.length) {
            // found pattern major.minor.build, extract build
            return release[2];
          }
        }
    */
    return build;
  }

  /**
   * Create a string representing current hardware capabilities.
   *
   * Example output:
   * "MANUFACTURER: Apple Inc., ARCHITECTURE: x64, MEMORY_MB: 32768, CPU_COUNT: 16, FIRST_CPU: Intel(R) Core(TM) i9-9980HK CPU @ 2.40GHz"
   * "MANUFACTURER: LENOVO, ARCHITECTURE: ia32, MEMORY_MB: 8192, CPU_COUNT: 2, FIRST_CPU: Intel(R) Core(TM) i9-9980HK CPU @ 2.40GHz"
   */
/* TODO:
  async hostHardware() {
    const cpu_data = os.cpus();
    const cpu_class = _get(cpu_data, [0, "model"], "unknown");
    // TODO: const system_info = await si.system();
    // TODO: const manufacturer = system_info.manufacturer;
    const memory_mb = Math.floor(os.totalmem() / 1024 / 1024);
    const cpu_speed_mhz = cpu_data.reduce((accumulator, cpu_datum) => {
      return accumulator + cpu_datum.speed || 0;
    }, 0);
    // round to one significant digit
    const cpu_speed_ghz = Math.round(cpu_speed_mhz / 100) / 10;
    const architecture = os.arch();

    const hardware = {
      // TODO: MANUFACTURER: manufacturer,
      ARCHITECTURE: architecture,
      MEMORY_MB: memory_mb,
      CPU_COUNT: cpu_data?.length,
      CPU_SPEED_GHZ: cpu_speed_ghz,
      CPU_CLASS: cpu_class,
    };

    // map to string like "MANUFACTURER: Apple Inc., ARCHITECTURE: ia32, ..."
    return Object.keys(hardware)
      .map((key) => `${key}: ${hardware[key]}`)
      .join(", ");
  }
*/

  /**
   * Fallback function to process Mac info based on node os package.
   * Made public to aid with test coverage.
   *
   * @param osPlatform - os.platform() output, e.g. "darwin"
   * @param osRelease - os.release() output, e.g. "20.50.0"
   */
  public getMacOSRelease(osPlatform: string, osRelease: string) {
    const release = String(osRelease.split(".")[0]);
    let releaseSpecific;

    // initialize to a basic format, even though this will require decoding "Darwin" version
    // @help https://en.wikipedia.org/wiki/Darwin_%28operating_system%29#Release_history
    let version = osPlatform + " " + osRelease;

    // a more specific version number is available
    if (Number(release) >= 20) {
      releaseSpecific = String(osRelease);

      if (fallbackMacOSVersions.hasOwnProperty(releaseSpecific)) {
        [, version] = fallbackMacOSVersions[releaseSpecific];
      }

      return version;
    }

    if (fallbackMacOSVersions.hasOwnProperty(release)) {
      [, version] = fallbackMacOSVersions[release];
    }

    return version;
  }

  async getActiveNetworkInterface() {
/* TODO:
    const name = await si.networkInterfaceDefault();
    const ifaces = await si.networkInterfaces();
    return ifaces.find((i) => i.iface === name);
*/
  }

  async getMacAddress() {
    const primaryInterface = await this.getActiveNetworkInterface();

    // TODO: return primaryInterface?.mac || "";
  }

  readonly hostOs: string = '';    // Windows, macOS, Chrome OS
  readonly hostPlatform: string;   // Win32, MacIntel, Linux x86_64
  private browser: string = '';
  private browserVer: string = '';
}
