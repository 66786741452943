import { DetailNavService } from "../services/detail-nav.service";
import { ILoggingService } from "../services/logging.service";
import {
  DeviceManagerService,
  OzDevice,
} from "../services/device-manager.service";
import { BluetoothService } from "../services/bluetooth.service";
import { AuthService } from "../services/auth.service";
import { Component, OnDestroy, OnInit } from "@angular/core";
import { ActivatedRoute, ParamMap, Router } from "@angular/router";
import { combineLatest, Observable, of } from "rxjs";
import { filter, map, startWith, switchMap } from "rxjs/operators";
import { MS_IN_SECOND, ROUTE_HOME, DEVICE_FEATURES } from "../utils/constants";
import { DeviceControlsState, StateService } from "../services/state.service";
import { Subscriptions } from "../utils/subscriptions";
import { ReconnectDeviceEvents } from "../services/reconnect-device-events.service";
import { Dfu } from "../services/dfu.service";
import { AdminConfig } from "../services/admin-config.service";
import { BrickedDeviceService } from "../services/bricked-device.service";
import { HomeComponentState } from "../home/home.component.state";

const HOME_COMPONENT_STATE_DEVICE_NOT_FOUND_TOAST: HomeComponentState = {
  toast: {
    type: "status",
    status: "error",
    text: "NOTIFICATIONS.DEVICE_NOT_FOUND.MESSAGE",
  },
};

/**
 * **IMPORTANT LIFECYCLE NOTE** this component stays alive (not destroyed)
 * if navigating between two different devices
 */
@Component({
  templateUrl: "./detail.component.pug",
})
export class DetailComponent implements OnInit, OnDestroy {
  private completed = false;
  private deviceId: string;
  public controlsState: DeviceControlsState = {};
  public device: OzDevice;
  public deviceIsResetting$: Observable<boolean>;
  public hasSettings = false;
  public hasDevices = false;
  public hover = false;
  public navRoutes: any[];
  public noiseBlockAIAvailable: boolean = false;
  private subs = new Subscriptions();
  private timeout: ReturnType<typeof setTimeout>;
  deviceUpdateAvailable = false;
  parentDeviceUpdateAvailable = false;
  caseUpdateAvailable = false;
  brickedDevice = false;
  showChargingCaseTab = false;

  /**
   * Indicates if the device is currently being reconnected,
   * (e.g. after Restart Device or Restore Defaults options)
   */
  deviceReconnecting = false;

  /**
   * DetailComponent is hoisted when a device is selected in left sidemenu
   * or system tray/dock.  It is destroyed on route change to a non-device detail
   * page.  Thus, state is preserved between navigation between two different devices.
   */
  constructor(
    public authService: AuthService,
    public detailNav: DetailNavService,
    public stateService: StateService,
    private deviceManager: DeviceManagerService,
    private logger: ILoggingService,
    private route: ActivatedRoute,
    private router: Router,
    private bluetoothService: BluetoothService,
    private reconnectDeviceEvents: ReconnectDeviceEvents,
    private dfu: Dfu,
    public adminConfig: AdminConfig,
    private brickedDeviceService: BrickedDeviceService
  ) {
    // This is used to manage the css classes necessary for video preview "expand"
    // taking over the title / navigation
    this.subs.add(
      this.stateService.getState$("DeviceDetails").subscribe((state) => {
        // timeout necessary to prevent ExpressionChangedAfterItHasBeenCheckedError in some situations
        setTimeout(() => {
          this.controlsState = state;
        }, 0);
      })
    );
    // Check if there is connected Bluetooth Remote and display "Devices" tab for the device.
    // This will respond to direct getBluetoothStatus call or bluetooth settings change and show/hide Devices tab accordingly
    this.subs.add(
      this.bluetoothService.bluetoothStatus$.subscribe((status) => {
        this.hasDevices = !!(
          this.device?.videoDeviceStatus?.remoteActive &&
          status?.status === "OK" &&
          status?.enable &&
          status?.rcc_enable
        );
      })
    );
  }

  ngOnInit(): void {
    // TODO: find a way of "completing" the BehaviorSubject in device-manager for non-existent devices so can rely on "complete" callback
    this.completed = false;

    // note this can be called multiple times switching between devices
    this.route.paramMap
      .pipe(
        switchMap((paramMap: ParamMap) => {
          this.deviceId = paramMap.get("id");
          return this.deviceManager.getDevice(this.deviceId); // "id" is Device#uniqueId
        }),
        filter((device) => !!device),
        switchMap((device) =>
          combineLatest([
            // Device
            of(device),
            // Reconnection status of the device
            this.reconnectDeviceEvents.getStatus(device).pipe(
              map((status) => status === "Reconnecting"),
              startWith(false)
            ),
            this.dfu.availableUpdate(device).pipe(startWith(null)),
            this.dfu.availableUpdate(device.parent).pipe(startWith(null)),
            this.dfu
              .availableUpdate(
                this.deviceManager.getDeviceByUniqueId(
                  device.peerChargeCase?.GenesGuid
                )
              )
              .pipe(startWith(null)),
            this.brickedDeviceService.brickedDeviceInfo$,
          ])
        )
      )
      .subscribe(
        ([
          device,
          reconnecting,
          repoFirmware,
          repoFirmwareParent,
          repoFirmwareCase,
          brickedDeviceStatus,
        ]) => {
          this.deviceUpdateAvailable = !!repoFirmware;
          this.parentDeviceUpdateAvailable = !!repoFirmwareParent;
          this.caseUpdateAvailable = !!repoFirmwareCase;
          this.brickedDevice =
            brickedDeviceStatus?.recoveryArchivePath &&
            brickedDeviceStatus?.deviceId === device?.pid;

          this.timeout = setTimeout(() => {
            if ((!this.device || !this.deviceId) && !this.completed) {
              this.logger.debug(
                "Attempted to route to device that does not appear to exist",
                { deviceId: this.deviceId }
              );
              this.deviceNotFoundErrorHandler();
            }
          }, MS_IN_SECOND);

          this.device = device;
          this.deviceReconnecting = reconnecting;

          if (this.deviceReconnecting) {
            setTimeout(() => {
              this.router.navigate(["./overview"], {
                relativeTo: this.route,
              });
            }, 5 * MS_IN_SECOND);
          }

          // Check if we should display "Settings" tab for the device.
          this.hasSettings =
            this.device?.featureList.includes(DEVICE_FEATURES.SETTINGS) ||
            this.device?.parent?.featureList.includes(DEVICE_FEATURES.SETTINGS);

          // This will emit bluetooth status observable value for specific device
          if (this.device.featureList.includes(DEVICE_FEATURES.BLUETOOTH)) {
            this.bluetoothService.getBluetoothStatus(this.device.id);
          }

          // Check if charging case tab should be visible
          this.showChargingCaseTab = device?.connectedDevices?.includes(
            "chargeCase"
          );
        },
        (err) => {
          this.logger.error("Error in detail component", err);
        },
        () => {
          this.completed = true;
        }
      );
  }

  private deviceNotFoundErrorHandler() {
    this.router.navigate([ROUTE_HOME], {
      state: { ...HOME_COMPONENT_STATE_DEVICE_NOT_FOUND_TOAST },
    });
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }
}
