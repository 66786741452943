import { Injectable } from "@angular/core";
import { combineLatest, Observable, of } from "rxjs";
import { map, switchMap } from "rxjs/operators";
import { Accessor, AccessorService } from "./accessor.service";
import { AuthService } from "./auth.service";
import { TenantService } from "./tenant.service";

// TODO: UI: Need to better understand possible user roles, and how to resolve current user to each of them.
export enum OzUserRole {
  Admin,
  Other,
}

function isAdmin(tenantId: string, accessor: Accessor) {
  const currentTenantAccessor = accessor.grants.find(
    (g) => g.resourceId === tenantId
  );
  return currentTenantAccessor
    ? !!currentTenantAccessor?.roles.find(
        (role) => role.name === "admin" || role.name === "it-admin"
      )
    : false;
}

@Injectable({
  providedIn: "root",
})
export class OzUser {
  constructor(
    private tenantService: TenantService,
    private accessor: AccessorService,
    private authService: AuthService
  ) {}

  get role(): Observable<OzUserRole> {
    return this.authService.isLoginless$.pipe(
      switchMap((isLoginless) => {
        if (isLoginless) {
          return of(OzUserRole.Other);
        }

        return combineLatest([
          this.tenantService.tenantId$,
          this.accessor.accessor$,
        ]).pipe(
          map(([tenantId, accessor]) =>
            isAdmin(tenantId, accessor) ? OzUserRole.Admin : OzUserRole.Other
          )
        );
      })
    );
  }
}
