import { Location } from "@angular/common";
import { Component, NgZone, OnDestroy } from "@angular/core";
import { Router, Routes } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { DeviceSetting } from "@poly/hub-native";
import { filter, first, take } from "rxjs/operators";
import { ConfigService, Environment } from "../services/config.service";
import { DeviceManagerService } from "../services/device-manager.service";
import {
  defaultFeatures,
  Features,
  StateService,
} from "../services/state.service";
import { TrayService } from "../services/tray.service";
import { UtilityService } from "../services/utility.service";
import { TranslatedOptions } from "../shared/components/dropdown/dropdown.component";
import { RestoreDefaultUtils } from "../utils/restoreDefaultsUtils";
import { IconService } from "../icon/icon.service";
import {
  NotificationEvent,
  NotificationsService,
  SingleNotification,
} from "../services/notifications.service";
import { Subscriptions } from "../utils/subscriptions";
import { Observable } from "rxjs";
import { AuthService } from "../services/auth.service";
import { TenantService } from "../services/tenant.service";

@Component({
  selector: "oz-secret",
  templateUrl: "./secret.component.pug",
})
export class SecretComponent implements OnDestroy {
  env: Environment;
  envOptions = [
    { value: "prod01", text: "Production" },
    { value: "stage01", text: "Staging" },
    { value: "dev01", text: "Development" },
    { value: "local", text: "Local" },
    { value: "custom", text: "Custom" },
  ];
  savingState: "saved" | "not" = "not";

  icons;
  allIcons: Array<string>;
  showIcons = false;
  showDeviceControls = false;
  showOther = false;
  showNotificationEvent = false;
  features: Features = defaultFeatures;
  hubNativeVersion = "";
  deviceOptions: TranslatedOptions = [];
  deviceSelected: string;
  // initialize with example that sets P5 value to low exposure on Mac
  deviceSettingsJSON1: string = '[{"id": "0xc0e", "value": "3"}]';
  deviceSettingsJSON2: string = "[]";
  deviceSettingsJSON3: string = "[]";
  retrievedSettings: Array<DeviceSetting> = [];
  adminConfigStatus: Observable<boolean>;

  public notificationEvent: NotificationEvent;
  public routes: Routes;
  private subs = new Subscriptions();

  constructor(
    public config: ConfigService,
    public iconService: IconService,
    public location: Location,
    private deviceManagerService: DeviceManagerService,
    private ngZone: NgZone,
    private notificationService: NotificationsService,
    private restoreDefaultUtils: RestoreDefaultUtils,
    private stateService: StateService,
    private translateService: TranslateService,
    private trayService: TrayService,
    private authService: AuthService,
    private tenantService: TenantService,
    public router: Router
  ) {
    config.env$.pipe(take(1)).subscribe((env) => (this.env = env));

    this.routes = router.config;

    this.icons = {
      all: {
        dark: trayService.generateIconVariants("all", "white"),
        light: trayService.generateIconVariants("all", "black"),
      },
    };

    this.allIcons = Object.keys(iconService.icons);

    this.featureUpdated("experimentalLanguages");
    this.featureUpdated("experimentalDeviceControlsAndSettings");
    this.featureUpdated("cameraFavorites");
    this.featureUpdated("frequentReminders");
    this.featureUpdated("acousticEvents");

    this.getDevices();
    this.hubNativeVersion = UtilityService.getNativeVersion();

    this.subs.add(
      this.notificationService.events.subscribe((event) => {
        this.ngZone.run(() => {
          this.showNotificationEvent = true;
          this.notificationEvent = event;
        });
      })
    );
  }

  back() {
    this.location.back();
  }

  onEnvChanged(env: string) {
    if (env !== "custom") {
      this.env = this.config.getEnvironment(env);
    } else {
      this.env.name = "custom";
    }
  }

  async save() {
    await this.restoreDefaultUtils.restoreDefault();
    this.config.setEnvironment(this.env);
    this.savingState = "saved";
  }

  changeTrayIcon(icon: string) {
    icon = icon.replace("tray_", "");
    if ("win32" === process.platform) {
      icon += ".ico";
    } else {
      icon += ".png";
    }

    this.trayService.temporarilyOverrideTrayIcon(icon);
  }

  changeLanguage(language: string) {
    this.translateService.use(language);
  }

  featureUpdated(key: keyof Features) {
    this.stateService.setDeepState("Features", key, this.features[key]);
  }

  getDeviceSettings() {
    // reset
    this.retrievedSettings = [];

    this.deviceManagerService
      .getDeviceSettings(this.deviceSelected)
      .pipe(take(1))
      .subscribe((res) => (this.retrievedSettings = res));
  }

  setDeviceSettings(settingsString: string) {
    let settings: Array<Partial<DeviceSetting>> = [];
    try {
      settings = JSON.parse(settingsString);
    } catch (e) {
      alert("Invalid JSON, please check formatting.");
    }

    this.deviceManagerService.setDeviceSettings(this.deviceSelected, settings);
  }

  /**
   * Get updated devices list.
   */
  getDevices() {
    this.deviceManagerService
      .getDevices()
      .pipe(
        filter((devices) => !!devices.length),
        first()
      )
      .subscribe((devices) => {
        const deviceOptions: TranslatedOptions = devices.map((device) => {
          return {
            value: device.id,
            text: device.displayName,
            disabled: !device.isConnected,
          };
        });

        if (deviceOptions.length) {
          this.deviceSelected = deviceOptions[0].value;
        }

        this.deviceOptions = deviceOptions;
      });
  }

  triggerNotification(type?: "button") {
    const params: SingleNotification = {
      title: "Secret Title",
      body: "Secret screen has triggered a notification",
      // icon: "app-icon.png",
    };

    if (["button", "buttons"].includes(type)) {
      params.actions = [{ type: "button", text: "My Button" }];
      params.body += ` (with ${type})`;
    }

    this.notificationService.singleNotification(params);
  }

  logout() {
    this.tenantService.setTenantId(String(null));
    this.tenantService.setTenantName(String(null));
    this.authService.startLogoutFlow("/home");
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }
}
