import { Observable, BehaviorSubject } from "rxjs";
import { hexEqual, nullOrUndefined } from "./utils/utils";
import * as productsJson from "./products.json";

export interface FeatureList {
  batteryIndication: string[];
  appsSupported: string[];
  callcontrol: string[];
  dfu: string[];
  fmh: string[];
  hasSensor: string[];
  langdfu: string[];
  langsetting: string[];
  otaCapable: string[];
  settingsSupported: string[];
  hasXevent: string[];
  langsettings: string[];
  deviceLog: string[];
  bluetoothPairing: string[];
  wiFiSupported: string[];
  bluetoothSupported: string[];
  networkProvisioning: string[];
}

export interface Product {
  displayName: string;
  displayNameTeams?: string;
  featurelist: Partial<FeatureList>;
  pids: string[];
  friendlyNames: string[];
  description: string;
  otaDfuMode: string;
  storeUrl: {
    android: string;
    ios: string;
  };
  systemName: string;
  systrayName: string;
}

export class Products {
  constructor() {
    const a:any = productsJson;
    this.products = new BehaviorSubject<Partial<Product>[]>(a.default);
  }

  json(): Observable<Partial<Product>[]> {
    return this.products.asObservable();
  }

  getById(pid: string): Partial<Product> | undefined {
    const value: Partial<Product>[] = this.products.getValue();
    return value.find((product) => {
      // If product is unknown or pids are missing
      if (undefined === product.pids || null === product.pids) {
        // Then this is not the product we're looking for
        return false;
      }

      // If this product contains the pid we're looking for
      for (const productPid of product.pids) {
        if (hexEqual(pid, productPid)) {
          // Then this is the product we need!
          return true;
        }
      }

      return false;
    });
  }

  private products: BehaviorSubject<Partial<Product>[]>;
}
