import {
  appId,
  branch,
  build,
  commitId,
  productId,
  releaseChannel,
  version,
} from "../../../buildInfo.json";
import { isEqual as _isEqual, trimStart as _trimStart } from "lodash";
import { DeviceSettingMetadata } from "../device-settings/device-settings.component";
import { Device, DeviceSetting } from "@poly/hub-native";
import { DevicePID } from "../utils/constants";
import { OzDevice } from "./device-manager.service";

const path = require("path");

export type LodashAccessor = (string | number)[] | string;

export class UtilityService {
  static toHex(value: number | string): string {
    return (+value).toString(16);
  }

  static removeStringAfter(str: string, find: string) {
    if (!str || typeof str !== "string") {
      return str;
    }

    return str.split(find)[0];
  }

  static openExternalBrowser(url: string) {
    window.open(url, '_blank');
  }

  static scrollToTop(
    classNames: string,
    top = 0,
    behavior: ScrollBehavior = "smooth"
  ) {
    const el = document.getElementsByClassName(classNames)[0];
    el?.scrollTo({
      top,
      behavior,
    });
  }

  /**
   * From buildInfo.json file
   *
   * @example "master"
   * @example "develop"
   * @example "363-internationalization"
   */
  static getBuildBranch(): string {
    return branch;
  }

  /**
   * From buildInfo.json file
   * @example "0.1.6-363-internationalization-485857d2"
   */
  static getBuild(): string {
    return build;
  }

  /**
   * From buildInfo.json file
   * @example "0.1.6"
   */
  static getBuildVersion(): string {
    return version;
  }

  /**
   * From buildInfo.json file
   * @example "lens-desktop-mac"
   */
  static getBuildProductId(): string {
    return productId;
  }

  /**
   * From buildInfo.json file
   * @example "363-internationalization"
   */
  static getBuildReleaseChannel(): string {
    return releaseChannel;
  }

  /**
   * From buildInfo.json file
   * @example "com.poly.lens.client.app"
   */
  static getBuildAppId(): string {
    return appId;
  }

  /**
   * From buildInfo.json file
   * @example "485857d2"
   */
  static getBuildCommitId(): string {
    return commitId;
  }

  static getNativeVersion(): string {
    return "n/a";
  }

  /**
   * Note if checking for a develop branch, best to use !UtilityService.isBuildBranchMaster(),
   * because there are numerous sub-branches of develop.
   */
  static isBuildBranchMaster(): boolean {
    return "main" === this.getBuildBranch().toLowerCase();
  }

  /**
   * Unpads a hex value.  Note negative numbers are not supported.
   *
   * @param x - e.g. "0x0090" would return "0x90"
   * @param upperCase - e.g. true would mean "0x00ca" would return "0xCA",
   *                  false would mean "0x00AF" would return "0xaf"
   */
  static unpadHex(x: string | number, upperCase: boolean): string {
    // while 0x0 is falsy, it is a legitimate hex value and needs to be processed
    // falsy values such as undefined, null or "" will still respond as "0x0"
    if (x || 0x0 === x) {
      if (typeof x === "string") {
        x = parseInt(x, 16);
      }

      let hexString = x.toString(16);
      if (upperCase) {
        hexString = hexString.toUpperCase();
      }

      return `0x${hexString}`;
    }

    // respond with hex value of 0 for empty string or 0x0
    return "0x0";
  }

  /**
   * Compare two hex values to check for equality.  Disregards
   * padding, string or number formatting, and case.
   *
   * @param a
   * @param b
   */
  static hexIsEqual(a: string | number, b: string | number) {
    return _isEqual(+a, +b);
  }

  /**
   * This function helps differentiate between cases where hex values
   * and product names are mixed.  For instance, "fa0", "0x090" and "G 7500".
   *
   * This function is NOT designed to differentiate between base-2 or base-10 values
   * such as "100" from base-16 values "fa0".
   *
   * As long as a product name contains a space, a letter G-Z, it can
   * detect when it is not a hex value.
   */
  static couldBeHex(val: string | number) {
    return /^(0x)?[0-9a-fA-F]+$/.test("" + val);
  }

  /**
   * Get translation path based on a setting's id value and path.
   *
   * @param setting
   * @param path - path without "." prefix, e.g. "options.name" or "description"
   */
  static getDeviceSettingTranslatePath(
    setting: DeviceSetting | Partial<DeviceSettingMetadata>,
    path: string
  ): string {
    if (setting?.id) {
      return `DeviceSettings.${UtilityService.unpadHex(
        setting.id,
        true
      )}.${path}`;
    }

    // "Tried to create translation path from a setting object without id",
    return "";
  }

  /**
   * Find the next available file name.
   *
   * @param directoryPath
   * @param prefix
   * @param filetype
   * @param separator
   */
  static getUniqueFileName(
    directoryPath: any,
    prefix: string,
    filetype: string,
    separator = "-"
  ) {
    let unique_id = 0;
    let filePath = "";
    filetype = _trimStart(filetype, ".");

    const generateNextFilePath = () => {
      unique_id++;
      filePath = path.join(
        directoryPath,
        `${prefix}${separator}${unique_id}.${filetype}`
      );
    };
    generateNextFilePath();

    try {
      /* TODO:
      while (fs.existsSync(filePath)) {
        generateNextFilePath();
      }*/
    } catch (err) {
      // console.error(err);
    }

    // if error, just resort to original file path guess and let user find a unique name for it
    return filePath;
  }

  /**
   * This method was created outside of config.service.ts so that it can be called statically
   * and help avoid circular dependencies.
   */
  static isInTestRunner() {
    return Boolean(window && !!(window as any).jasmine);
  }

  static isWindows(): boolean {
    return false; // "win32" === process.platform;
  }

  static isMac(): boolean {
    return false; // "darwin" === process.platform;
  }

  static isLinux(): boolean {
    // TODO: allow "freebsd", etc
    return false; // "linux" === process.platform;
  }

  static isBrowser(): boolean {
    return true;
  }

  static routeMatches(route, match) {
    return route.replace(/^\//, "") === match.replace(/^\//, "");
  }

  /* TODO:
  static getFullIconPath(icon: string, iconDirPath: string): string {
    // in Angular tests, remote.app.getAppPath() can return a path like:
    // `/Users/cr/Sites/oz-client/node_modules/karma-electron/lib`, which
    // the icon is certainly not in. Thus, remove node_modules/* from path
    const appPath = this.removeStringAfter(
      PolytronService.getAppPath(),
      "node_modules/"
    );
    const iconPath = path.join(appPath, iconDirPath);

    return path.join(iconPath, icon);
  }
   */

  /**
   * Historically, some tests report node's path to be undefined (seemingly
   * due to a fringe case test problem), so provide method to check if
   * path is undefined.
   */
  static isNodePathAccessible(): boolean {
    return undefined !== path && "function" === typeof path.join;
  }

  /**
   * Add a prefetch tag to load a URL, such as an image.
   * It is up to the calling code to only add one prefetch per URL, per lift of Angular.
   * This is ideal for a service to do, as services are singletons.
   *
   * This assists with initial load of content that may be requested later. Not
   * only does it speed up the request later since it is prefetched, it also
   * enables an offline capability.
   */
  static addPrefetch(url: string) {
    let node = document.createElement("link");
    node.rel = "prefetch";
    node.href = url;
    document.getElementsByTagName("head")[0].appendChild(node);
  }

  /**
   * Try/catch handling for an async function.
   */
  static async attemptAsync(func, ...args) {
    try {
      return await func(...args);
    } catch (e) {
      return e;
    }
  }

  static getKeyByValue(object, val) {
    return Object.keys(object).find((key) => object[key] === val);
  }

  /**
   * Get device.pid and convert it to a hex value, with specific overrides
   * for certain devices like the P21.
   *
   * Per LENS-1100, there is a hard-coded lookup table since the P21 device
   * is a hub with multiple devices, and there is not currently multiple pid
   * support on the Device type. It was decided 4/1/21, after examining potential options
   * to extend Device type with a videoProviderPid field or extend the product catalog
   * with an alias field, to hard-code this field and wait to see if a pattern
   * emerges over time that could rework this logic with a more agile solution.
   * If it does not, and pids for the P21 remain the same, this can be a permanent solution.
   */
  static getDeviceVideoPid(device: Device): string {
    const videoPidLookup: { [key: number]: number } = {
      // P21 device; from audio device's 17178 (0x431a) to video device 37520 (0x9290)
      [DevicePID.P21]: 37528,
    };

    const pid = videoPidLookup[device.pid] ?? device.pid;

    return (+pid).toString(16);
  }

  static mapChildDeviceName(devices: Array<OzDevice>): Array<OzDevice> {
    return devices.map((d) => {
      const child = devices.find((c) => d.id === c.parentDeviceId);
      return {
        ...d,
        displayName: child ? child.displayName : d.displayName,
      };
    });
  }

  /**
   * Get the local UTC offset, e.g. "-06:00" of the current machine.
   *
   * Could also be accomplished by date-fns `format(new Date(), "xxx")`,
   * however avoiding 87KB unminified JS with a simple function
   * @help https://date-fns.org/v2.23.0/docs/format
   *
   * GMT is a time zone, whereas UTC is a time standard.
   *
   * @example response "-06:00", "+00:00", "+08:45"
   */
  static getUTCOffset(): string {
    return UtilityService.getUTCOffsetFromTimezoneOffset(
      new Date().getTimezoneOffset()
    );
  }

  /**
   * Given a new Date().getTimezoneOffset() offset number (negative or positive),
   * produce the UTC offset.
   *
   * @param timezoneOffset - e.g. 360 for MDT, 0 for GMT, -525 for Eucla Wa, Australia
   */
  static getUTCOffsetFromTimezoneOffset(timezoneOffset: number): string {
    const pad = (value) => (value < 10 ? "0" + value : value);
    const sign = timezoneOffset > 0 ? "-" : "+";
    const offset = Math.abs(timezoneOffset);
    const hours = pad(Math.floor(offset / 60));
    const minutes = pad(offset % 60);

    return `${sign}${hours}:${minutes}`;
  }
}
