import {
  Component,
  Input,
  OnInit,
  QueryList,
  ViewChildren,
} from "@angular/core";
import { Device, SetSimplePasswordResponse } from "@poly/hub-native";
import { Toast, Toasts } from "../../shared/components/toast/toasts.service";
import { InputPasswordComponent } from "../../input-password/input-password.component";
import { DeviceManagerService } from "../../services/device-manager.service";
import { ILoggingService } from "../../services/logging.service";
import { UtilityService } from "../../services/utility.service";

const PASSWORD_CHANGE_STATUS_SUCCESS_TOAST: Toast = {
  type: "status",
  status: "success",
  text: "NOTIFICATIONS.DEVICE_PASSWORD_SUCCESSFULLY_CHANGED",
};

@Component({
  selector: "oz-device-password-change",
  templateUrl: "./device-password-change.component.pug",
})
export class DevicePasswordChangeComponent implements OnInit {
  readonly TRANSLATE_PREFIX = "DeviceSettings.ADMIN_PASSWORD.";

  @Input()
  device: Device;

  // Manages visibility of a dialog where user changes her password
  showDialog = false;

  // Current user's password
  currentPassword: string = "";

  // New password
  newPassword: string = "";

  // Message to display if user successfully changes a password
  toastMessage: string;

  // Shows general error message
  public showGeneralErrorMessage: boolean = false;
  public showPasswordSameError: boolean = false;

  public simplePassword: boolean = false;

  private readonly passwordPattern = /^(?=(.*?[0-9]){2})(?=(.*[a-z]){2})(?=(.*[A-Z]){2,})(?=(.*?[#?!@$%^&*\-+()/\\|{}~_.,:;\[\]<=>"']){2}).{15,}/gm;

  @ViewChildren(InputPasswordComponent) inputPassword!: QueryList<
    InputPasswordComponent
  >;

  constructor(
    private deviceManager: DeviceManagerService,
    private logger: ILoggingService,
    private toasts: Toasts
  ) {}

  ngOnInit(): void {
    this.fetchSimplePassword();
  }

  private fetchSimplePassword(): void {
    this.deviceManager.simplePassword(this.device.id).then((simplePassword) => {
      this.simplePassword = simplePassword.simple;
    });
  }

  public onCurrentPasswordChange(password: string): void {
    this.currentPassword = password;
  }

  public onNewPasswordChange(password: string): void {
    this.newPassword = password;
  }

  onCancel() {
    this.showDialog = false;
    this.resetInputs();
  }

  public changeSimplePassword(): void {
    this.simplePassword = !this.simplePassword;
    this.deviceManager
      .setSimplePassword({
        deviceId: this.device.id,
        simple: this.simplePassword,
      })
      .then((response: SetSimplePasswordResponse) => {
        this.logger.log(
          `Simple password changed status: ${response.status}`,
          response
        );
      });
  }

  public onUpdate(): void {
    this.showGeneralErrorMessage = false;
    this.showPasswordSameError = false;
    if (this.validatePassword()) {
      this.changeAdminPassword();
    } else {
      this.showPasswordSameError = this.currentPassword === this.newPassword;
    }
  }

  public isPasswordConditionMet(): boolean {
    return this.simplePassword
      ? true
      : !!this.newPassword.match(this.passwordPattern);
  }

  private validatePassword(): boolean {
    return (
      this.currentPassword !== this.newPassword && this.isPasswordConditionMet()
    );
  }

  private changeAdminPassword(): void {
    this.deviceManager
      .adminPasswordChange({
        deviceId: this.device.id,
        old_password: this.currentPassword,
        new_password: this.newPassword,
      })
      .then(({ result: updated, status }) => {
        if (status === "OK" && updated) {
          this.showDialog = false;
          this.resetInputs();
          this.toasts.push(PASSWORD_CHANGE_STATUS_SUCCESS_TOAST);
        } else {
          this.showGeneralErrorMessage = true;
          this.logger.error(
            `Device communication error has occurred while user was trying to change a password for device: status="${status}", result=${updated} ("${
              this.device.displayName
            }", PID=${UtilityService.toHex(this.device.pid)})`
          );
        }
      });
  }

  /**
   * Reset all input fields.
   */
  private resetInputs() {
    this.showGeneralErrorMessage = false;
    this.showPasswordSameError = false;
    this.inputPassword.forEach((item) => item.reset());
  }
}
