import { Component, OnInit, Input } from "@angular/core";
import { Device } from "@poly/hub-native";

@Component({
  selector: "oz-ftc-completed",
  templateUrl: "./ftc-completed.component.pug",
})
export class FtcCompletedComponent implements OnInit {
  @Input() device: Device;

  constructor() {}

  ngOnInit(): void {}
}
