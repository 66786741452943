import { Injectable } from "@angular/core";
import {MS_IN_DAY, MS_IN_MINUTE} from "../utils/constants";
import {Observable, Subscription, zip} from "rxjs";
import { filter, map, mergeMap, switchMap } from "rxjs/operators";
import { AuthService } from "./auth.service";
import { DeviceManagerService } from "./device-manager.service";
import { ILoggingService } from "./logging.service";
import { Repository } from "./repository/repository.service";
import {Features, StateService} from "./state.service";

@Injectable({
  providedIn: "root",
})
export class DevicePolicyService {
  private pollInterval: number = MS_IN_DAY;
  private authSubscription: Subscription | undefined;
  private currentPollState: boolean = false;

  constructor(
    private authService: AuthService,
    private deviceManager: DeviceManagerService,
    private logger: ILoggingService,
    private repo: Repository,
    private stateService: StateService,
  ) {
    this.init();
  }

  private init() {
    const features = this.stateService.getState("Features");
    this.currentPollState = features.frequentPolicyPolling;
    this.logger.debug(`Device polling interval set to ${this.pollInterval} ms`)
    this.authSubscription = this.authService.isAuthenticated$
      .pipe(
        filter((authenticated) => authenticated),
        switchMap(() => this.deviceManager.getConnectedDevices()),
        mergeMap((devices) => {
          const responses = devices.map((device) =>
            this.repo.getDevicePolicy({
              deviceId: device.uniqueId,
              productId: device.pid,
              pollInterval: this.pollInterval,
            })
          );
          return zip(...responses);
        }),
        map((responses) => responses.map(({ deviceId }) => deviceId))
      )
      .subscribe((deviceIds) => {
        this.logger.debug("Received policy for deviceIds: ", deviceIds);
      });

    this.stateService.state$
      .subscribe(() => {
        this.handleReload();
      });
  }

  private handleReload(): void {
    const features = this.stateService.getState("Features");
    if (features.frequentPolicyPolling != this.currentPollState) {
      this.authSubscription?.unsubscribe();
      this.pollInterval = features.frequentPolicyPolling ? MS_IN_MINUTE * 5 : MS_IN_DAY;
      this.init();
    }
  }
}
