import { Injectable } from "@angular/core";
import { DeviceMessagingUtils } from "./utils/device.messaging.utils";
import {
  DeviceInfoConnection,
  DeviceInfoUpdateEventData,
  DeviceInfoUpdatePayload,
} from "./utils/device.message.json.subobjects";
import { getFirmwareVersion } from "../../shared/pipes/firmware-version.pipe";
import { removeManufacturerName } from "../../shared/pipes/device-name.pipe";
import { OzDevice } from "../device-manager.service";
import { OzDeviceWithRepositoryFirmware } from "../dfu.service";

@Injectable({
  providedIn: "root",
})
export class DeviceInfoUpdatePayloadBuilder {
  constructor(private deviceMessagingUtils: DeviceMessagingUtils) {}

  public translateToDeviceInfoUpdatePayload(
    device: OzDeviceWithRepositoryFirmware,
    parentUniqueId: string,
    updateTypeArg: string,
    lensAppId: string
  ): DeviceInfoUpdatePayload {
    let connectionsArray: DeviceInfoConnection[] = [];
    connectionsArray.push(
      this.deviceMessagingUtils.generateDeviceConnection(
        device.uniqueId,
        parentUniqueId,
        device,
        this.deviceMessagingUtils.getViaConnections(device, parentUniqueId, [
          device,
        ]),
        this.deviceMessagingUtils.getEntityType(parentUniqueId, lensAppId)
      )
    );
    let eventData: DeviceInfoUpdateEventData = {
      proxyAgentId: lensAppId,
      updateType: updateTypeArg,
      deviceType: "", //TODO NA at this moment (waiting for native)
      deviceId: device.uniqueId,
      capabilities: device.featureList,
      productId: this.deviceMessagingUtils.getHexString(device.pid),
      productName: removeManufacturerName(device.displayName),
      manufacturer: device.manufacturerName,
      vendorId: this.deviceMessagingUtils.getHexString(device.vid),
      deviceSpecificInfo: this.deviceMessagingUtils.generateDeviceSpecificInfo(
        device
      ),
      connections: connectionsArray,
      hardwareModel: removeManufacturerName(device.displayName),
    };

    if (device.isConnected) {
      eventData.isUpdateAvailable = !!device.repositoryFirmwareVersion;
      eventData.updateVersion = device.repositoryFirmwareVersion;
    }

    eventData.softwareVersion = getFirmwareVersion(device);

    return DeviceInfoUpdatePayloadBuilder.generateFullDeviceInfoStatePayload(
      eventData
    );
  }

  public arePayloadsDifferent(first: OzDevice, second: OzDevice): boolean {
    let firstPayload: DeviceInfoUpdatePayload = this.translateToDeviceInfoUpdatePayload(
      first,
      "",
      "",
      ""
    );
    let secondPayload: DeviceInfoUpdatePayload = this.translateToDeviceInfoUpdatePayload(
      second,
      "",
      "",
      ""
    );

    if (
      firstPayload.value &&
      firstPayload.value.eventData &&
      secondPayload.value &&
      secondPayload.value.eventData
    ) {
      let firstEventData: string = JSON.stringify(firstPayload.value.eventData);
      let secondEventData: string = JSON.stringify(
        secondPayload.value.eventData
      );

      return firstEventData !== secondEventData;
    } else {
      throw new Error("Invalid devices to compare");
    }
  }

  public static generateFullDeviceInfoStatePayload(
    eventData: DeviceInfoUpdateEventData
  ): DeviceInfoUpdatePayload {
    return {
      attr: "hubv3",
      version: "0.0.1",
      value: {
        eventTime: new Date(),
        eventType: "DeviceInfo.deviceInfoUpdate",
        eventVersion: "0.0.1",
        eventData: eventData,
      },
    };
  }
}
