import { Component, Input, OnInit } from "@angular/core";
import { Device } from "@poly/hub-native";
import {
  DeviceManagerService,
  OzDevice,
} from "../../services/device-manager.service";
import { UtilityService } from "../../services/utility.service";
import { DEVICE_SETTING_ID_CLEAR_TRUSTED_DEVICES } from "../../utils/constants";

/**
 * Clear Trusted Device List
 *
 * This option will delete all paired devices (BT700, mobile phones,...) from headset memory
 */

@Component({
  selector: "oz-device-setting-clear-trusted-devices",
  templateUrl: "./clear-trusted-devices.component.pug",
})
export class DeviceSettingClearTrustedDevicesComponent implements OnInit {
  // Translated name of a setting
  name: string;

  // Translated button text of a setting
  buttonText: string;

  // Translated description of a setting
  description: string;

  @Input()
  device: OzDevice;

  constructor(private deviceManager: DeviceManagerService) {}

  ngOnInit() {
    this.name = this.translate("name");
    this.description = this.translate("description");
    this.buttonText = this.translate("resetBtn");
  }

  /**
   * Sends request to device to clear all trusted devices,
   * removes parent of this device in application (historical devices in localStorage)
   */
  onClearTrustedDevicesClick() {
    // Initiate a process of deleting trusted (paired) devices from headsets
    this.deviceManager.setDeviceSetting(this.device.id, {
      id: DEVICE_SETTING_ID_CLEAR_TRUSTED_DEVICES,
      value: "true",
    });

    // Delete parent (e.g. BT700) of headsets
    this.device.parentDeviceId = "";
    delete this.device.parent;
    this.deviceManager.saveDevices();
  }

  /**
   * Obtains a translation string for this setting.
   *
   * @param path The translation path
   * @returns Translated string found on the provided path
   */
  private translate(path: string) {
    return UtilityService.getDeviceSettingTranslatePath(
      { id: DEVICE_SETTING_ID_CLEAR_TRUSTED_DEVICES },
      path
    );
  }
}
