import {
  AfterContentInit,
  Component,
  ContentChildren,
  forwardRef,
  Input,
  QueryList,
} from "@angular/core";
import { ButtonGroupButtonComponent } from "./button-group-button.component";
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";

/**
 * Creates a "group" of buttons, similar to tabs.
 * Requires sub-components of oz-buttongroup-button
 * ngModel binds to the "value" attribute of the subcomponents.
 *
 * @example
 * oz-buttongroup([(ngModel)]="someKey", [defaultSelected]="off")
 *    oz-buttongroup-button(value="off") {{ "TRANSLATION_KEY.OFF" | translate }}
 *    oz-buttongroup-button(value="on") {{ "TRANSLATION_KEY.ON" | translate }}
 */
@Component({
  selector: "oz-buttongroup",
  templateUrl: "button-group.component.pug",
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ButtonGroupComponent),
      multi: true,
    },
  ],
})
export class ButtonGroupComponent
  implements AfterContentInit, ControlValueAccessor {
  @ContentChildren(ButtonGroupButtonComponent) buttons!: QueryList<
    ButtonGroupButtonComponent
  >;
  @Input() defaultSelected: string = "";
  @Input() disabled = false;

  private onTouched: (_: any) => {};
  private onChange: (_: string) => {};
  private value = "";

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  writeValue(value: string) {
    this.value = value;
  }

  ngAfterContentInit() {
    this.buttons.forEach((button) => {
      if (button.value === this.value) {
        this.setActive(this.value);
      }

      button.onClick.subscribe((id) => {
        this.setActive(id);
      });
    });

    if (this.defaultSelected) {
      // wait one tick to prevent ExpressionChangedAfterItHasBeenCheckedError errors
      setTimeout(() => {
        this.setActive(this.defaultSelected);
      }, 0);
    }
  }

  setActive(value) {
    if (!this.buttons) {
      return;
    }

    this.value = value;
    this.buttons.forEach((button) => {
      button.selected = value === button.value;
    });

    this.onChange(this.value);
  }
}
