import { Component, OnDestroy, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { Device } from "@poly/hub-native";
import {
  DeviceManagerService,
  DeviceSetupParams,
} from "../services/device-manager.service";
import { Subscriptions } from "../utils/subscriptions";
import { switchMap } from "rxjs/operators";
import { OzStepStatus } from "../shared/components/stepper/step/step.component";
import { Location } from "@angular/common";
import { Identity } from "../device-settings/device-ftc-settings/ftc-identity/ftc-identity.component";
import { ProvisioningForm } from "../device-settings/device-settings-provisioning/provisioning-form.component";
import { FactoryPasswordState } from "../device-settings/device-ftc-settings/ftc-enter-password/ftc-enter-password.component";
import { DEVICE_FEATURES } from "../utils/constants";

interface ToolbarButton {
  enabled: boolean;
  visible: boolean;
}

interface Toolbar {
  back: ToolbarButton;
  skip: ToolbarButton;
  next: ToolbarButton;
  finish: ToolbarButton;
}

@Component({
  templateUrl: "./ftc-wizard-manual-setup.component.pug",
})
export class FtcWizardManualSetupComponent implements OnInit, OnDestroy {
  device: Device;
  deviceSupportsWifi: boolean = false;
  deviceSupportsProvisioning: boolean = false;
  showCloseModal = false;

  OzStepStatus = OzStepStatus;

  startStatus = OzStepStatus.Active;
  networkStatus = OzStepStatus.Inactive;
  provisioningStatus = OzStepStatus.Inactive;
  completeStatus = OzStepStatus.Inactive;

  model: DeviceSetupParams = {};

  toolbar: Toolbar = {
    back: {
      enabled: false,
      visible: false,
    },
    skip: {
      enabled: false,
      visible: false,
    },
    next: {
      enabled: false,
      visible: true,
    },
    finish: {
      enabled: false,
      visible: false,
    },
  };

  showError = false;

  private subs = new Subscriptions();

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private deviceManager: DeviceManagerService,
    private location: Location
  ) {}

  ngOnInit() {
    this.subs.add(
      this.route.paramMap
        .pipe(
          switchMap((paramMap) =>
            this.deviceManager.getDevice(paramMap.get("id"))
          )
        )
        .subscribe((device) => {
          if (!device.isConnected) {
            this.router.navigate(["../../"], { relativeTo: this.route });
          }
          this.device = device;
          this.deviceSupportsWifi = this.device.featureList.includes(
            DEVICE_FEATURES.WIFI
          );
          this.deviceSupportsProvisioning = this.device.featureList.includes(
            DEVICE_FEATURES.NETWORK_PROVISIONING
          );
        })
    );
    this.model.oldPassword = (this.location.getState() as FactoryPasswordState).password;
  }

  onExitSetup() {
    this.location.back();
  }

  onNext() {
    if (this.startStatus === OzStepStatus.Active) {
      this.changePassword().then((status: boolean) => {
        if (status) {
          this.startStatus = OzStepStatus.Completed;
          if (this.deviceSupportsWifi) {
            // skip if device does not support WiFi like R30 NoRadio
            this.networkStatus = OzStepStatus.Active;
            this.toolbarBackSkip();
          } else {
            this.networkStatus = OzStepStatus.Skipped;
            this.provisioningStatus = OzStepStatus.Skipped;
            this.model.provisioningParams = null; // is this necessary?
            this.completeStatus = OzStepStatus.Active;
            this.toolbarBackFinish();
          }
        } else {
          this.showError = true;
        }
      });
    } else if (this.networkStatus === OzStepStatus.Active) {
      this.networkStatus = OzStepStatus.Completed;
      this.provisioningStatus = OzStepStatus.Active;
      this.toolbarBackSkip();
    } else if (this.provisioningStatus === OzStepStatus.Active) {
      this.provisioningStatus = OzStepStatus.Completed;
      this.completeStatus = OzStepStatus.Active;
      this.toolbarBackFinish();
    }
  }

  onBack() {
    this.showError = false;
    if (this.completeStatus === OzStepStatus.Active) {
      this.completeStatus = OzStepStatus.Inactive;
      if (this.deviceSupportsWifi && this.deviceSupportsProvisioning) {
        if (this.networkStatus === OzStepStatus.Skipped) {
          this.networkStatus = OzStepStatus.Active;
          this.provisioningStatus = OzStepStatus.Skipped;
        } else {
          this.provisioningStatus = OzStepStatus.Active;
        }
        this.toolbarBackSkip();
      } else {
        // Device does not support Network provisioning, we should back to start
        this.networkStatus = OzStepStatus.Inactive;
        this.provisioningStatus = OzStepStatus.Inactive;
        this.startStatus = OzStepStatus.Active;
        this.resetToolbar();
        this.toolbar.next.visible = true;
        this.toolbar.next.enabled = false;
      }
    } else if (this.provisioningStatus === OzStepStatus.Active) {
      this.provisioningStatus = OzStepStatus.Inactive;
      this.networkStatus = OzStepStatus.Active;
      this.toolbarBackNext();
    } else if (this.networkStatus === OzStepStatus.Active) {
      this.networkStatus = OzStepStatus.Inactive;
      this.startStatus = OzStepStatus.Active;
      this.resetToolbar();
      this.toolbar.next.visible = true;
      this.toolbar.next.enabled = false;
    }
  }

  onSkip() {
    if (this.networkStatus === OzStepStatus.Active) {
      this.networkStatus = OzStepStatus.Skipped;
      this.provisioningStatus = OzStepStatus.Skipped;
      this.completeStatus = OzStepStatus.Active;
    } else if (this.provisioningStatus === OzStepStatus.Active) {
      this.provisioningStatus = OzStepStatus.Skipped;
      this.completeStatus = OzStepStatus.Active;
      this.model.provisioningParams = null;
    }
    this.toolbarBackFinish();
  }

  onFinish() {
    this.model.oldPassword = (this.location.getState() as FactoryPasswordState).password;
    this.deviceManager.setupDevice(this.device.id, this.model);
    this.deviceManager.setNeedOOBmap(this.device.id, false);
    // Navigate to device
    this.router.navigate(["detail", this.device?.uniqueId, "overview"]);
  }

  onNewPasswordAndIdentitySuccess(identity: Identity) {
    this.showError = false;
    if (identity) {
      this.model.country = identity.country;
      this.model.deviceName = identity.deviceName;
      this.model.newPassword = identity.password;
    }
    this.resetToolbar();
    this.toolbar.next.visible = true;
    this.toolbar.next.enabled = !!identity;
  }

  private changePassword(): Promise<boolean> {
    return this.deviceManager
      .adminPasswordChange({
        deviceId: this.device.id,
        old_password: this.model.oldPassword,
        new_password: this.model.newPassword,
      })
      .then(({ result: updated, status }) => {
        if (status === "OK" && updated) {
          this.model.oldPassword = this.model.newPassword;
        } else {
          this.toolbar.next.visible = true;
          this.toolbar.next.enabled = false;
        }
        return status === "OK" && updated;
      });
  }

  onProvisioningConfigChanged(config: ProvisioningForm) {
    this.model.provisioningParams = {
      deviceId: this.device.id,
      ...config,
    };
    this.resetToolbar();
    this.toolbar.back.visible = true;
    this.toolbar.back.enabled = true;

    const {
      provisioningMode,
      serverAddress,
      serverType,
      username,
      password,
    } = config;

    const showNext =
      provisioningMode === "Auto" ||
      (provisioningMode === "Manual" &&
        !!serverType &&
        !!serverAddress &&
        !!username &&
        !!password);

    this.toolbar.skip.visible = !showNext;
    this.toolbar.skip.enabled = !showNext;
    this.toolbar.next.visible = showNext;
    this.toolbar.next.enabled = showNext;
  }

  onWiFiConfigured(status: boolean) {
    this.resetToolbar();
    this.toolbar.back.visible = true;
    this.toolbar.back.enabled = true;
    this.toolbar.skip.visible = !status;
    this.toolbar.skip.enabled = !status;
    this.toolbar.next.visible = status;
    this.toolbar.next.enabled = status;
  }

  private resetToolbar() {
    this.resetToolbarButton(this.toolbar.back);
    this.resetToolbarButton(this.toolbar.skip);
    this.resetToolbarButton(this.toolbar.next);
    this.resetToolbarButton(this.toolbar.finish);
  }

  private toolbarBackSkip() {
    this.resetToolbar();
    this.toolbar.back.visible = true;
    this.toolbar.back.enabled = true;
    this.toolbar.skip.visible = true;
    this.toolbar.skip.enabled = true;
  }

  private toolbarBackNext() {
    this.resetToolbar();
    this.toolbar.back.visible = true;
    this.toolbar.back.enabled = true;
    this.toolbar.next.visible = true;
    this.toolbar.next.enabled = true;
  }

  private toolbarBackFinish() {
    this.resetToolbar();
    this.toolbar.back.visible = true;
    this.toolbar.back.enabled = true;
    this.toolbar.finish.visible = true;
    this.toolbar.finish.enabled = true;
  }

  private resetToolbarButton(button: ToolbarButton) {
    button.enabled = false;
    button.visible = false;
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }
}
