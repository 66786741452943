import { Component, Input, OnInit, Output, EventEmitter } from "@angular/core";

@Component({
  selector: "oz-slider",
  templateUrl: "./slider.component.pug",
})
export class SliderComponent {
  @Input() disabled: boolean;
  @Input() max: number;
  @Input() min: number;
  @Input() step: number;
  @Input() valueModel: number;
  @Output() valueModelChange = new EventEmitter<number>();
}
