import { Injectable } from "@angular/core";
import { DeviceManagerService } from "./device-manager.service";
import { ILoggingService } from "./logging.service";
import { UtilityService } from "./utility.service";
import { SETTING_ID_MAP } from "../utils/constants";
import { DeviceSetting } from "@poly/hub-native";
import { FavoritesService } from "./favorites.service";
import { take, map } from "rxjs/operators";

export type capabilities = {
  [key: string]: string;
};

/**
 * C10 = abbreviation for "Capabilities" service (C + 10 letters).
 */
@Injectable({
  providedIn: "root",
})
export class C10SettingMappingService {
  private includesVideoControls = false;

  constructor(
    private deviceManager: DeviceManagerService,
    private logger: ILoggingService,
    private favoritesService: FavoritesService
  ) {}

  updateDeviceSettings(id: string, settings: Partial<DeviceSetting>[]) {
    const deviceId = this.deviceManager.getDeviceIdByUniqueId(id);
    if (!deviceId) {
      this.logger.error(`DeviceId not found for device with unique id ${id}`);

      return;
    }

    // if the settings incoming from the cloud include video settings
    // then we want to update the currently selected video controls favorite to IT Managed
    if (this.includesVideoControls) {
      this.favoritesService.ensureITManaged(id);
    }

    this.deviceManager.setDeviceSettings(deviceId, settings);
  }

  /**
    Method takes in a capabilities object and maps it to an array of
    objects with the setting reverse domain name as the key and setting value as the value

    Example input:

      "com": {
        "poly": {
          "audio": {
            "bass": "0",
            "treble": "0"
          },
          "video": {
            "pan": "0",
            "tilt": "0"
          }
        }
      }

    Example output:

    {
      "com.poly.audio.bass": "0",
      "com.poly.audio.treble": "0",
      "com.poly.video.pan": "0",
      "com.poly.video.tilt": "0"
    }
  */

  getCapabilityMap = (obj: any): { [key: string]: any } => {
    const capabilities = {};

    const traverseC10Obj = (obj: any, nodes: Array<string>) => {
      Object.keys(obj).forEach((key) => {
        if (typeof obj[key] === "object") {
          traverseC10Obj(obj[key], [...nodes, key]);
        }
        if (key === "video") {
          this.includesVideoControls = true;
        }

        if (["boolean", "string", "number"].includes(typeof obj[key])) {
          // create reverse domain name from nodes collected along traversal
          const id = [...nodes, key].join(".");
          // get value for setting
          const value: string = obj[key];
          capabilities[id] = value;
        }
      });
    };

    traverseC10Obj(obj, []);
    return capabilities;
  };

  /**
    Method takes in an array objects with the setting reverse domain name
    as the key and setting value as the value and returns an array of objects
    with the setting gsid as the key and settings value as the value for the native layer

    Example input:

    {
      "com.poly.audio.bass": "0",
      "com.poly.audio.treble": "0",
      "com.poly.video.pan": "0",
      "com.poly.video.pan_auto_enabled": true,
      "com.poly.video.tilt": "0"
    }


    Example output:

      [
        {
          id: "0cx21",
          value: "0"
        },
        {
          id: "0xc22",
          value: "0"
        },
        {
          id: "0xc0a",
          value: "0"
        },
        {
          id: "0xc0a",
          value: "0"
          autoEnabled: true
        },
        {
          id: "0xc0b",
          value: "0"
        }
      ]
  */

  getSettingsFromCapabilities = (capabilities: {
    [key: string]: any;
  }): Partial<DeviceSetting>[] => {
    return Object.keys(capabilities).map((capKey) => {
      const result: Partial<DeviceSetting> = {
        id: "0x0",
      };

      // get global setting id for native layer from reverse domain name in settingIdMap
      let gsid = Object.keys(SETTING_ID_MAP).find(
        (key) => SETTING_ID_MAP[key] === capKey
      );

      if (!gsid && capKey.includes("_auto_enabled")) {
        const baseCapKey = capKey.replace("_auto_enabled", "");
        gsid = Object.keys(SETTING_ID_MAP).find(
          (key) => SETTING_ID_MAP[key] === baseCapKey
        );
        result.value = capabilities[baseCapKey];
        result.autoEnabled = capabilities[capKey];
      }

      if (gsid) {
        result.id = UtilityService.unpadHex(parseInt(gsid), false);
      }

      if (result.autoEnabled === undefined) {
        result.value = capabilities[capKey];
      }

      return result;
    });
  };

  verifySupportedSettings(id: string, settings: Partial<DeviceSetting>[]) {
    const deviceId = this.deviceManager.getDeviceIdByUniqueId(id);
    return this.deviceManager.getDeviceSettings(deviceId).pipe(
      take(1),
      map((supportedSettings: DeviceSetting[]) => {
        let settingsToRemove: DeviceSetting[] = [];
        let settingsToUpdate = settings.filter((setting: DeviceSetting) => {
          const supportedSetting = supportedSettings.find(
            (supportedSetting) => {
              return supportedSetting.id === setting.id;
            }
          );

          if (
            supportedSetting &&
            supportedSetting.options.includes(setting.value)
          ) {
            return true;
          }

          if (!supportedSetting) {
            settingsToRemove.push(setting);
          }
          return false;
        });

        return { supportedSettings: settingsToUpdate, settingsToRemove };
      })
    );
  }
}
