import ByteBuffer from "bytebuffer";
import { ILoggingService } from "../../app/services/logging.service";
import { buf2hex } from "./wh-utils";


export enum RohType {
  RT_GET = "GET",
  RT_PUT = "PUT",
  RT_PATCH = "PATCH",
  RT_POST = "POST",
  RT_DELETE = "DELETE"
}

export enum RohUrl {
  RU_DEVICE_INFO = "device_info",
  RU_GENERAL_SETTINGS = "general",
  RU_BLUETOOTH_SETTINGS = "bluetooth",
  RU_BLUETOOTH_PAIRING = "bluetooth_pair",
  RU_BLUETOOTH_LE_PAIRING = "bluetooth_le_pair",
  RU_ADMIN_LOGIN = "login",
  RU_PASSWORD_CHANGE = "admin",
  RU_ENABLE_PASSWORD = "enable_password",
  RU_SIMPLE_PASSWORD = "simple_password",
  RU_CERTIFICATE = "csr_info_provision",
  RU_CERTIFICATE_FILE = "csr_file_provision",
  RU_DELETE_CERTIFICATE = "ca_delete",
  RU_LIST_CERTIFICATES = "cert_info",
  RU_CONFIG_FILE = "config_file",
  RU_PROVISION_STATUS = "provision_status",
  RU_PROVISION_SERVER = "provision_server",
  RU_SERVER_CA_VALIDATION = "server_ca_validation",
  RU_NEED_OOB = "if_need_oob",
  RU_WIFI_CLIENT_CERT_PASSWORD = "wifi_client_cert_password",
  RU_COREDUMP_LOG = "coredump_log",
  RU_SYSTEM_LOG = "system_log",
  RU_MAXIMUM_ZOOM = "maximum_zoom_level",
  RU_CAMERA_MOVEMENT = "smooth_transition",
  RU_BASS_CONTROL = "audio_eq_bass",
  RU_TREBLE_CONTROL = "audio_eq_treble",
  RU_IMAGE_MIRROR_FLIP = "image_mirror_flip",
  RU_AUDIO = "audio",
  RU_AUTO_TRACKING = "auto_tracking",
  RU_LECTURE_MODE = "lecture_mode",
  RU_CONVERSATION_MODE = "conversation_view",
  RU_GALLERY_MODE_MODE = "gallery_view",

  // audio test
  RU_START_AUDIO_TEST = "audio_diagnostic_record_start",
  RU_STOP_AUDIO_TEST = "audio_diagnostic_record_stop",
  // Wi Fi connectivity
  RU_WIFI = "wifi",
  RU_WIFI_STATUS = "wifi_status",
  RU_WIFI_AVAILABLE = "wifi_available_ssid",
  RU_WIFI_SAVED = "wifi_saved_ssid",

  RU_OPS = "ops",
  RU_FACTORY_RESET_ACTION = "reset",
  RU_FACTORY_RESTART_ACTION = "restart",

  RU_CALL_STATUS = "call_status_check",
  RU_SUPPORTED_URLS = "supported_urls",
  RU_ON_SCREEN_DISPLAY = 'osd'
}


enum RohFlags {
  RF_BODY_FIN = 0x01,
  RF_BODY_DATA = 0x02,
  RF_HEAD_FIN = 0x04,
  RF_HEAD_DATA = 0x08,
  RF_MSG_START = 0x10
}

enum RohFieldOffset {
  PACKET_LENGTH = 1,
  FLAGS = 3,
  TOTAL_HEADER_LEN = 4,
  TOTAL_BODY_LEN = 6,
  BODY_TYPE = 10,
}

enum PayloadOffset {
  START_PKT = 8,  // start packet has: flags, header length, body length and body type
  CONT_PKT = 1    // continuation packet has only flags
}

export enum RohBodyType {
  JSON = 0,
  FILE = 1,
}

const ROH_BASE_HEADER_SIZE: number = 2 + PayloadOffset.CONT_PKT;  // 2 bytes for packet length

let msgId: number = 0;

export class RohRequest {
  constructor(type: RohType, url: string, private reqLen: number, private body?: string) {
    this.id = "" + (++msgId);
    const hdr = {
      type: type,
      url: url,
      msg_id: this.id
    };
    this.header = JSON.stringify(hdr);
    this.remainingHeader = this.header.length;
    this.remainingBody = body ? body.length : 0;
    this.first = true;
  }

  getPacket(): ArrayBuffer {
    if (this.remainingHeader == 0 && this.remainingBody == 0) {
      throw new Error("Attempt to get packet that has no header or body");
    }

    let b = new ByteBuffer(this.reqLen, ByteBuffer.LITTLE_ENDIAN);
    let flags = 0;

    b.writeUint16(0); // payload length
    b.writeByte(0); // flags

    if (this.first) {
      flags = RohFlags.RF_MSG_START;
      b.writeUint16(this.remainingHeader);
      b.writeUint32(this.remainingBody);
      b.writeByte(RohBodyType.JSON)
    }

    let headLen = Math.min(this.remainingHeader, b.capacity() - b.offset);
    if (headLen > 0) {
      const start = this.header.length - this.remainingHeader;
      b.writeString(this.header.substring(start, start + headLen));
      this.remainingHeader -= headLen;
      flags |= RohFlags.RF_HEAD_DATA;
      if (this.remainingHeader == 0) {
        flags |= RohFlags.RF_HEAD_FIN;
      }
    }

    let bodyLen = Math.min(this.remainingBody, b.capacity() - b.offset);
    if (bodyLen > 0 && this.body) {
      const start = this.body.length - this.remainingBody;
      b.writeString(this.body.substring(start, start + bodyLen));
      this.remainingBody -= bodyLen;
      flags |= RohFlags.RF_BODY_DATA;
      if (this.remainingBody == 0) {
        flags |= RohFlags.RF_BODY_FIN;
      }
    }
    b.writeUint16(b.offset - 2, 0);
    b.writeByte(flags, 2);
    b.offset = b.capacity();
    b.flip();
    return b.toArrayBuffer();
  }

  id: string;
  first: boolean;
  public header: string;
  private remainingHeader: number;
  private remainingBody: number;
}

export class RohResponseHeader {
  msg_id?: string;
  diag_info?: string;
  status_code?: string;
  status_string?: string;
}

export enum ROH_PACKET_STATUS {
  NOT_LAST,
  LAST,
  ERROR
}

export class RohResponseParser {
  private packets: DataView[] = [];

  constructor(readonly logger: ILoggingService) {
  }

  public examinePacket(dv: DataView): ROH_PACKET_STATUS {
    const [pktLen, flags] = getRohHeader(dv);
    if (pktLen === 0 && flags === 0) {
      const headerLen: number = read16(dv, RohFieldOffset.TOTAL_HEADER_LEN);
      if (0 < headerLen) {
        if (0 < this.packets.length) {
          this.logger.warn(`dropping ${this.packets.length} RoH packets, missing FIN (is Lens Desktop running?)`,
            buf2hex(this.packets[0].buffer));
          this.clearPackets();
        }
        this.logger.warn(`RoH packet with no length and no flags`, buf2hex(dv.buffer));
        this.packets.push(dv);
        return ROH_PACKET_STATUS.LAST;
      }
    }
    if (flags === 0) {
      this.logger.error("No flags in Roh packet (is Lens Desktop running?)");
      this.clearPackets();
      return ROH_PACKET_STATUS.ERROR;
    }
    const [headData, headFin, bodyData, bodyFin] = processRohFlag(flags);
    const msgStart = 0 < (flags & RohFlags.RF_MSG_START);
    if (!msgStart && 0 === this.packets.length) {
      this.logger.warn("unexpected continuation RoH packet (is Lens Desktop running?)", buf2hex(dv.buffer));
      return ROH_PACKET_STATUS.ERROR;
    }
    if (msgStart && 0 < this.packets.length) {
      this.logger.warn(`dropping ${this.packets.length} RoH packets, missing FIN (is Lens Desktop running?)`,
        buf2hex(this.packets[0].buffer));
      this.clearPackets();
    }
    this.packets.push(dv);
    const lastPkt = (headFin && !bodyData) || bodyFin;
    if (!lastPkt) {
      return ROH_PACKET_STATUS.NOT_LAST;
    }
    const startPkt = this.packets[0];
    const hdrLen = read16(startPkt, RohFieldOffset.TOTAL_HEADER_LEN);
    const bodyLen = read32(startPkt, RohFieldOffset.TOTAL_BODY_LEN);
    const recvLen = this.packets.reduce((len, pkt) => len + getPktLength(pkt), 0);
    const overhead = PayloadOffset.START_PKT + PayloadOffset.CONT_PKT * (this.packets.length - 1);
    const lenDiff = (hdrLen + bodyLen - (recvLen - overhead));
    if (0 !== lenDiff) {
      this.logger.error(`missing RoH packet(s) (hdr+body=${hdrLen}+${bodyLen} recv=${recvLen} missing=${lenDiff})` +
        " (is Lens Desktop running?)");
      this.clearPackets();
      return ROH_PACKET_STATUS.ERROR;
    }
    return ROH_PACKET_STATUS.LAST;
  }

  public parse(): [ByteBuffer | undefined, ByteBuffer, RohBodyType] {
    let headerBytesLeft: number = 0;
    let totalHeaderLen: number = 0;
    let totalBodyLen: number = 0;
    let bodyBytesLeft: number = 0;
    let headerBB: ByteBuffer | undefined = undefined;
    let bodyBB: ByteBuffer = new ByteBuffer(0);
    let bodyType: number = 0;
    for (let pkt of this.packets) {
      const [pktLen, flags] = getRohHeader(pkt);
      let bytesRead: number = PayloadOffset.CONT_PKT; // Need to make sure we include the flags in the bytes read!
      const msgStart: boolean = (flags & RohFlags.RF_MSG_START) > 0;
      if (msgStart || (pktLen === 0 && flags === 0)) {
        totalHeaderLen = read16(pkt, RohFieldOffset.TOTAL_HEADER_LEN);
        headerBytesLeft = totalHeaderLen;
        totalBodyLen = read32(pkt, RohFieldOffset.TOTAL_BODY_LEN);
        bodyType = readByte(pkt, RohFieldOffset.BODY_TYPE);
        bodyBytesLeft = totalBodyLen;
        headerBB = new ByteBuffer(totalHeaderLen);
        if (totalBodyLen !== 0) {
          bodyBB = new ByteBuffer(totalBodyLen);
        }
        bytesRead += PayloadOffset.START_PKT - PayloadOffset.CONT_PKT;
      }

      const [headData, headFin, bodyData, bodyFin] = (pktLen === 0 && flags === 0) ? [true, true, false, false]
        : processRohFlag(flags);
      // process header first, if present in this pkt
      if (headData && headerBB) {
        let len: number = calcBytesToCopy(pktLen, headerBytesLeft, bytesRead);
        let newArray = pkt.buffer.slice(bytesRead + ROH_BASE_HEADER_SIZE, bytesRead + ROH_BASE_HEADER_SIZE + len);
        bytesRead += len;
        headerBytesLeft -= len;
        this.logger.debug("Reading header totalHeaderLen=" + totalHeaderLen + " headerBytesLeft=" + headerBytesLeft
          + " this pktLen=" + pktLen + " bytesRead=" + bytesRead + " copying len=" + len);
        headerBB.append(newArray);
        if (!headFin) {
          continue;
        }
      }

      if (bodyData) {
        let len: number = calcBytesToCopy(pktLen, bodyBytesLeft, bytesRead);
        let newArray = pkt.buffer.slice(bytesRead + ROH_BASE_HEADER_SIZE, bytesRead + ROH_BASE_HEADER_SIZE + len);
        bytesRead += len;
        bodyBytesLeft -= len;
        this.logger.debug("Reading body totalBodyLen=" + totalBodyLen + " bodyBytesLeft=" + bodyBytesLeft
          + " this pktLen=" + pktLen + " bytesRead=" + bytesRead + " copying len=" + len);
        bodyBB.append(newArray);
      }
    }
    this.clearPackets();
    headerBB?.flip();
    bodyBB.flip();

    return [headerBB, bodyBB, bodyType];
  }

  private clearPackets() {
    this.packets = [];
  }
}

export function decodeText(buffer: ArrayBuffer): string {
  return new TextDecoder().decode(buffer);
}

function calcBytesToCopy(pktLen: number, componentBytesLeft: number, bytesRead: number): number {
  return componentBytesLeft > (pktLen - bytesRead) ? pktLen - bytesRead : componentBytesLeft;
}

function readByte(dv: DataView, field: RohFieldOffset): number {
  return dv.getUint8(field.valueOf());
}

function read16(dv: DataView, field: RohFieldOffset): number {
  return dv.getUint16(field.valueOf(), true);
}

function read32(dv: DataView, field: RohFieldOffset): number {
  return dv.getUint32(field.valueOf(), true);
}

function getPktLength(dv: DataView): number {
  return read16(dv, RohFieldOffset.PACKET_LENGTH);
}

function getRohHeader(dv: DataView): [pktLen: number, flags: number] {
  const pktLen = read16(dv, RohFieldOffset.PACKET_LENGTH);
  const flags = readByte(dv, RohFieldOffset.FLAGS);
  return [pktLen, flags];
}

function processRohFlag(flag: number): [headData: boolean, headFin: boolean, bodyData: boolean, bodyFin: boolean] {
  let headData: boolean = (flag & RohFlags.RF_HEAD_DATA) > 0;
  let headFin: boolean = (flag & RohFlags.RF_HEAD_FIN) > 0;
  let bodyData: boolean = (flag & RohFlags.RF_BODY_DATA) > 0;
  let bodyFin: boolean = (flag & RohFlags.RF_BODY_FIN) > 0;

  return [headData, headFin, bodyData, bodyFin];
}
