import { DeviceManagerService } from "../services/device-manager.service";
import {
  Component,
  OnDestroy,
  AfterViewInit,
  NgZone,
  ChangeDetectorRef,
  Input,
} from "@angular/core";
import {
  DeviceUpdateProgress,
  DFUError,
  DFUStatus,
  DFUUserAction,
} from "@poly/hub-native";
import { Subscriptions } from "../utils/subscriptions";
import { Output } from "@angular/core";
import { EventEmitter } from "@angular/core";
import { DfuModalParams } from "../services/dfu-modal.service";
import { Observable, of } from "rxjs";
import { throttleTime, tap, mergeMap, filter, map } from "rxjs/operators";
import { ILoggingService } from "../services/logging.service";
import { runInZone } from "../utils/rxjs.utils";
import { DfuNeedReconnectService } from "../services/dfu-need-reconnect.service";
import { Repository } from "../services/repository/repository.service";
import { RepositoryFirmwareArchiveDownloadOptions } from "../services/repository/model";
import { OzFileSystem } from "../services/file-system.service";
import { BrickedDeviceService } from "../services/bricked-device.service";

const prefix = "DFU";

type DfuPhase = "download" | "progress" | "retry" | "upToDate" | "replug";

@Component({
  selector: "oz-dfu-progress-container",
  templateUrl: "./dfu-progress-container.component.pug",
})
export class DfuProgressContainerComponent implements OnDestroy, AfterViewInit {
  private subs = new Subscriptions();

  percentage = 0;
  completed = false;
  status: DFUStatus;
  phase: DfuPhase;
  message: DFUError | DFUUserAction;

  dest: string; // filepath of the archive used for undergoing dfu

  @Input() params: DfuModalParams;
  @Output() close = new EventEmitter<void>();

  constructor(
    private deviceManager: DeviceManagerService,
    private zone: NgZone,
    private changeDetector: ChangeDetectorRef,
    private fileSystem: OzFileSystem,
    private logger: ILoggingService,
    private dfuNeedReconnectService: DfuNeedReconnectService,
    private repo: Repository,
    private brickedDeviceService: BrickedDeviceService
  ) {}

  ngAfterViewInit() {
    this.logger.log(
      `${prefix}: Starting "${
        this.params.dfuType
      }" DFU with params: ${JSON.stringify(this.params)}`
    );

    // Start DFU
    switch (this.params.dfuType) {
      case "Remote":
      case "LanguageChange":
        this.startRemoteDfu(
          this.params.archiveLocation,
          this.params.languageId
        );
        break;
      case "Local":
        this.startLocalDfu(this.params.archiveLocation);
        break;
      case "Recovery":
        this.startRecoveryDfu(this.params.archiveLocation);
        break;
      default:
        throw new Error("DFU Type not supported!");
    }

    this.changeDetector.detectChanges();
  }

  // Starts a Remote DFU (or Language Change)
  private startRemoteDfu(url: string, languageId?: string) {
    // Subscribe to start DFU archive download
    this.download(url)
      .pipe(
        mergeMap((dest) => {
          if (dest) {
            // download is sucesfull and we should start DFU process
            this.phase = "progress";
            this.percentage = 0;
            return this.deviceManager.startDfu({
              deviceId: this.params.device.id,
              filepath: dest,
              languageId,
            });
          } else {
            // download returned null because it failed
            this.phase = "retry";
            this.percentage = 0;
            return of(null);
          }
        }),
        runInZone(this.zone)
      )
      .subscribe((progress: DeviceUpdateProgress) => {
        if (progress) {
          this.handleDeviceUpdateProgress(progress);
        }
      });
  }

  // Starts a Local DFU
  private startLocalDfu(filepath: string) {
    this.phase = "progress";

    // No need to download anything, just start a DFU
    this.deviceManager
      .startDfu({
        deviceId: this.params.device.id,
        filepath,
      })
      .pipe(runInZone(this.zone))
      .subscribe((progress: DeviceUpdateProgress) => {
        if (progress) {
          this.handleDeviceUpdateProgress(progress);
        }
      });
  }

  // Starts a Recvery DFU
  private startRecoveryDfu(filepath: string) {
    this.phase = "progress";

    // No need to put deviceId = -2 in order to pass DFU prerequisites on Native side
    this.deviceManager
      .startDfu({
        deviceId: "-2",
        filepath,
      })
      .pipe(runInZone(this.zone))
      .subscribe((progress: DeviceUpdateProgress) => {
        if (progress) {
          this.handleDeviceUpdateProgress(progress);
        }
      });
  }

  // Downloads a file and returns a destination filepath
  private download(url: string): Observable<string> {
    this.zone.run(() => {
      this.phase = "download";
    });

    // Creates temporary filepath for DFU archive.
    // We need to save this into local variable in order to remove the archive
    // from the local filesystem when progress reportes the completed status.
    this.dest = this.fileSystem.createTemp("DFU.zip");

    const options: RepositoryFirmwareArchiveDownloadOptions = {
      archiveLocation: url,
      path: this.dest,
    };

    // Needs subscription to start file download
    return this.repo.getFirmwareArchive(options).pipe(
      throttleTime(20, undefined, { leading: true, trailing: true }),
      runInZone(this.zone),
      filter(({ progress, status }) => {
        this.percentage = progress;
        return status !== "progress";
      }),
      map(({ status }) => {
        return status === "success" ? this.dest : null;
      }),
      tap((dest) => {
        dest
          ? this.logger.log(`${prefix}: Archive saved to`, dest)
          : this.logger.error(`${prefix}: Failed to save archive to`, dest);
      })
    );
  }

  private handleDeviceUpdateProgress(progress: DeviceUpdateProgress) {
    this.status = progress.status;

    if (
      progress.status === "Failed" ||
      (progress.status === "InProgress" && progress.message)
    ) {
      if (progress.message === "ReplugDevice") {
        this.dfuNeedReconnectService.setNeedReconnect(this.params.device);
        this.phase = "replug";
      } else {
        this.phase = "retry";
      }
    } else {
      this.phase = progress.status === "UpToDate" ? "upToDate" : "progress";
    }

    this.completed =
      progress.status === "Completed" || progress.status === "Failed";
    this.percentage = progress.percentage;
    this.message = progress.message;

    // When DFU is completed, remove the archive from the local filesystem
    if (this.completed && this.dest) {
      this.removeDestParentDir();
      this.dest = null;
    }

    // Check for bricked device
    if (progress.status === "Failed") {
      this.brickedDeviceService.getBrickedDeviceInfo();
    }
  }

  private removeDestParentDir() {
    this.fileSystem.removeDir(this.dest).subscribe((success) => {
      if (!success) {
        this.logger.log(`${prefix}: Removing DFU archive failed: ${this.dest}`);
      }
    });
  }

  onCancelDfu() {
    this.deviceManager.cancelDfu();
    this.close.emit();
  }

  onRetryDfu() {
    this.phase = "progress";
    this.deviceManager.retryDfu();
  }

  onContinueDfu() {
    this.phase = "progress";
    this.deviceManager.continueDfu();
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }
}
