import { Component, OnInit } from "@angular/core";
import { SoftwareSetting } from "@poly/hub-native";
import { combineLatest, Subscription } from "rxjs";
import { DeviceManagerService } from "../../services/device-manager.service";

export interface IKeepLinkUpOption {
  value: string;
  text: string;
}

const activeOnlyDuringCall = "activeOnlyDuringCall";
const alwaysActive = "alwaysActive";

enum KeepLinkUpOption {
  activeOnlyDuringCall = "DeviceSettings.KEEP_LINK_UP.ACTIVE_ONLY_DURING_CALL",
  alwaysActive = "DeviceSettings.KEEP_LINK_UP.ALWAYS_ACTIVE",
}

const SettingKeys = {
  keepLinkUp: "keepLinkUp",
};

const SettingsMap = {
  activeOnlyDuringCall: {
    keepLinkUp: false,
  },
  alwaysActive: {
    keepLinkUp: true,
  },
};

@Component({
  selector: "oz-device-keeplinkup",
  templateUrl: "./device-settings-keeplinkup-item.component.pug",
})
export class DeviceSettingsKeeplinkupItemComponent implements OnInit {
  private softwareSettingsSub: Subscription;
  keepLinkUp: boolean;
  keeplinkupOptions: IKeepLinkUpOption[] = [
    {
      value: activeOnlyDuringCall,
      text: KeepLinkUpOption.activeOnlyDuringCall,
    },
    {
      value: alwaysActive,
      text: KeepLinkUpOption.alwaysActive,
    },
  ];
  selectedKeeplinkupOption: string;

  constructor(private deviceManager: DeviceManagerService) {}

  ngOnInit(): void {
    this.softwareSettingsSub = this.deviceManager
      .getSoftwareSettings()
      .subscribe((settings) => {
        if (!!settings.length) {
          settings.forEach((setting) => {
            if (setting.id === SettingKeys.keepLinkUp) {
              this.keepLinkUp = setting.value;
            }
          });
          this.setOptionValueFromSWSettings();
        }
      });
  }

  onKeeplinkupOptionChanged(value: string) {
    this.setSWSettingsValues(value);

    const keeplinkupSoftwareSetting: Partial<SoftwareSetting> = {
      id: SettingKeys.keepLinkUp,
      value: this.keepLinkUp,
    };

    this.deviceManager.setSoftwareSettings([keeplinkupSoftwareSetting]);
  }

  setSWSettingsValues(value: string) {
    this.keepLinkUp = SettingsMap[value].keepLinkUp;
  }

  setOptionValueFromSWSettings() {
    for (const [key, value] of Object.entries(SettingsMap)) {
      if (value.keepLinkUp === this.keepLinkUp) {
        this.selectedKeeplinkupOption = key;
      }
    }
  }

  ngOnDestroy() {
    this.softwareSettingsSub.unsubscribe();
  }
}
