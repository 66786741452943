import {ILoggingService} from "../logging.service";
import {
  IConfigManager,
  IDeviceManager,
  IDeviceSettingsManager,
  IDFUManager,
  IEventsManager,
  IHubNative,
} from "@poly/hub-native";
import { WhDeviceManager } from "../../../dev-mgmt/webhid/wh-device-manager";
import { WhDeviceSettingsManager } from "../../../dev-mgmt/webhid/wh-device-settings-manager";
import { WhDFUManager } from "../../../dev-mgmt/webhid/wh-dfu-manager";
import { WhConfigManager } from "../../../dev-mgmt/webhid/wh-config-manager";
import { WhEventsManager } from "../../../dev-mgmt/webhid/wh-events-manager";


/**
 * WebHID impl of IHubNative
 */
export class HubNativeWebHID extends IHubNative {
  private readonly deviceManager: IDeviceManager;
  private readonly deviceSettingsManager: IDeviceSettingsManager;
  private readonly dfuManager: IDFUManager;
  private readonly eventsManager: IEventsManager;
  private readonly configManager: IConfigManager;

  constructor(private logger: ILoggingService) {
    super();

    const dm = new WhDeviceManager(logger);
    this.deviceManager = dm;
    this.deviceSettingsManager = new WhDeviceSettingsManager(dm);
    this.dfuManager = new WhDFUManager();
    this.configManager = new WhConfigManager();
    this.eventsManager = new WhEventsManager();
  }

  getDeviceManager(): IDeviceManager {
    return this.deviceManager;
  }

  getDeviceSettingsManager(): IDeviceSettingsManager {
    return this.deviceSettingsManager;
  }

  getDFUManager(): IDFUManager {
    return this.dfuManager;
  }

  getEventsManager(): IEventsManager {
    return this.eventsManager;
  }

  getConfigManager(): IConfigManager {
    return this.configManager;
  }

  stop(): void {}
}


export const WEBHID_HUB_NATIVE_PROVIDER = {
  provide: IHubNative,
  useClass: HubNativeWebHID,
};
