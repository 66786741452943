import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ContentChildren,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
  QueryList,
} from "@angular/core";
import { Subscriptions } from "../../../utils/subscriptions";
import {
  OzStepBarStatus,
  OzStepStatus,
  StepComponent,
} from "./step/step.component";

/** How to use oz-stepper
 * 
oz-stepper(closeable="true", (close)="test()")
  oz-step([status]="OzStepStatus.Active") Label 1
  oz-step([status]="OzStepStatus.Inactive") Label 2
  oz-step([status]="OzStepStatus.Inactive") Label 3
  oz-stepper-page
  oz-stepper-toolbar
    button.btn.btn-wizard.btn-wizard-back((click)="onClickNext(stepper)") Back
    button.btn.btn-wizard((click)="onClickPrevious(stepper)") Finish
 */

@Component({
  selector: "oz-stepper",
  templateUrl: "./stepper.component.pug",
})
export class StepperComponent implements AfterViewInit, OnDestroy {
  @Input()
  closeable = true;

  @Output()
  close = new EventEmitter<void>();

  @ContentChildren(StepComponent) steps: QueryList<
    StepComponent
  > = new QueryList();

  private queryListSubs = new Subscriptions();

  private statuses: OzStepStatus[] = [];

  constructor(private cdr: ChangeDetectorRef) {}

  ngAfterViewInit() {
    this.statuses = new Array(this.steps.length);
    // Listen for status change of each step, and update internal step model accordinately
    this.steps.forEach((step, index) => {
      this.queryListSubs.add(
        step._status.subscribe((status: OzStepStatus) => {
          this.statuses[index] = status;
          this.refreshVerticalBars();
          this.cdr.detectChanges();
        })
      );
    });
    this.cdr.detectChanges();
  }

  private refreshVerticalBars() {
    // By default all bars are "complete"
    this.steps.forEach((step: StepComponent) =>
      step.setBarStatus(OzStepBarStatus.Complete)
    );
    // Now, check cases where bars need to have "incomplete" status
    this.steps.forEach(
      (step: StepComponent, index: number, array: StepComponent[]) => {
        const currStepStatus = this.statuses[index];
        if (
          currStepStatus === OzStepStatus.Skipped ||
          currStepStatus === OzStepStatus.Inactive
        ) {
          step.setBarStatus(OzStepBarStatus.Incomplete);
          if (index > 0) {
            array[index - 1].setBarStatus(OzStepBarStatus.Incomplete);
          }
        }
      }
    );
  }

  ngOnDestroy() {
    this.queryListSubs.unsubscribe();
  }
}
