import {
  Component,
  Input,
  OnInit,
  OnChanges,
  SimpleChanges,
  ElementRef,
  HostListener,
} from "@angular/core";

interface IndicatorOptions {
  scale: {
    min: number;
    max: number;
  };
  danger: { min: number; max: number; label?: string };
}

export interface SpeedMetric {
  type: "upload" | "download" | "ping";
  value: number;
  unit: string;
  label: string;
  complete: boolean;
  left?: number;
  danger?: boolean;
}

/**
 * 1. Animation -
 * - indication of "running"
 * - Dim while it's running #aaa (opacity .5)

 * 2. Scale - linear
 * - Indicator stops at max / min, but number reflects actual
 * - Max network speed: 350
 * - Latency - new "danger" zone is 100+
 * - Latency - min 0, max 150

 * 3. Similar speeds / overlapping - Geoffrey to fiddle

 * 4. Ball turns red if in "danger" zone, even while running (border red color)
 * - After complete, number turns red if in the danger zone
 *
 * 5. "Loaded" vs "Unloaded"
 * - "loaded" -> ping WHILE in the middle of big download, for example.
 * - "loaded latency" is measured while downloads / uploads are happening (See fast.com)
 */

@Component({
  selector: "oz-speed-indicator",
  templateUrl: "./speed-indicator.component.pug",
})
export class SpeedIndicatorComponent implements OnInit, OnChanges {
  @Input() options: IndicatorOptions;
  @Input() series: Array<SpeedMetric>;
  @Input() values: Array<number>;

  public classes: Array<string> = [];
  public dangerLeft = 0;
  public dangerWidth = 0;
  public inDanger: Array<boolean> = [];
  public seriesLeft: Array<number> = [];

  private buffer = 0;
  private hasSame = false;
  private max = 9999999;
  private min = 0;
  private pixelsPerUnit = 1;
  private scale = 0;
  private unitPercent = 0;

  constructor(private el: ElementRef) {
    this.onResize();
  }

  parseSeries(values: Array<number>) {
    if (!this.unitPercent) {
      return;
    }

    this.seriesLeft = values.map((value, i) => {
      value = Math.min(this.max, Math.max(this.min, value));
      this.inDanger[i] =
        value >= this.options.danger.min && value <= this.options.danger.max;
      return value * this.unitPercent;
    });

    if (this.seriesLeft.length > 0) {
      // wait for DOM to render before adjusting positioning / CSS classes
      setTimeout(() => {
        this.prepBounds();
      }, 0);
    }
  }

  prepBounds() {
    const width = this.el.nativeElement.getBoundingClientRect().width;
    this.pixelsPerUnit = width / (this.unitPercent * 100);
    const indicators = this.el.nativeElement.getElementsByClassName(
      "indicator"
    );

    const indicatorWidths = [];

    for (let indicator of indicators) {
      let width = indicator.getBoundingClientRect().width;
      // widths can be manually manipulated, so store the "natural" width on the element
      if (width > 20) {
        indicator.dataset.width = width;
      }

      // always use the stored "valid" value
      width = +indicator.dataset.width;

      indicatorWidths.push(width);
    }

    const largestTwo = indicatorWidths.slice(-2).reduce((acc, width) => {
      return acc + width;
    }, 0);

    // On initial render ensure there's at least some spacing implemented
    this.buffer = Math.max(15, Math.ceil(largestTwo / 2) * this.unitPercent);
    this.hasSame = false;

    // This really only works with up to 2 indicators.  A third would have problems.
    this.classes = this.seriesLeft.map((left, i) => {
      let classes = [];
      let toLeft, toRight, toPrev, toNext;

      if (i > 0) {
        toPrev = left - this.seriesLeft[i - 1];
      }

      if (i + 1 < this.seriesLeft.length) {
        toNext = left - this.seriesLeft[i + 1];
      }

      if (typeof toPrev !== "undefined" && Math.abs(toPrev) < this.buffer) {
        if (toPrev < 0) {
          toLeft = Math.abs(toPrev);
        } else {
          toRight = toPrev;
        }
      }

      if (typeof toNext !== "undefined" && Math.abs(toNext) < this.buffer) {
        if (toNext <= 0) {
          toLeft = Math.abs(toNext);
        } else {
          toRight = toNext;
        }
      }

      classes = this.determineClasses(classes, toRight, left, largestTwo);
      classes = this.determineClasses(classes, toLeft, left, largestTwo);
      return classes.join(" ").trim();
    });
  }

  determineClasses(
    classes: Array<string>,
    toAdjacent: number | undefined,
    leftPercent: number | undefined,
    width
  ) {
    if (typeof leftPercent === "undefined") {
      return classes;
    }

    const bufferPercent = Math.floor(width / this.pixelsPerUnit / 4);

    if (leftPercent < bufferPercent) {
      classes.push("close-push-right");
    }

    if (leftPercent > 100 - bufferPercent) {
      classes.push("close-push-left");
    }

    if (typeof toAdjacent === "undefined") {
      return classes;
    }

    // ball width is 21px
    const ballWidthInUnits = 21 / this.pixelsPerUnit;
    // 3 is 3% of the width, prevent ball indicators from overlapping
    // this should do some calculations based on the bar width (as calculated above)
    if (toAdjacent < ballWidthInUnits) {
      if (this.hasSame) {
        classes.push("close-hide");
      } else {
        this.hasSame = true;
        classes.push("close-same");
      }
    }

    return classes;
  }

  @HostListener("window:resize")
  onResize() {
    this.prepBounds();
  }

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes.options && changes.options.currentValue) {
      const current = changes.options.currentValue;
      this.scale = current.scale.max - current.scale.min;
      ({ max: this.max, min: this.min } = current.scale);
      this.unitPercent = 100 / this.scale;
      this.dangerLeft = current.danger.min * this.unitPercent;
      this.dangerWidth =
        (current.danger.max - current.danger.min) * this.unitPercent;
    }

    if (
      changes.values &&
      changes.values.currentValue &&
      changes.values.currentValue.length
    ) {
      this.parseSeries(changes.values.currentValue);
    }
  }
}
