import { Component, OnInit, Input } from "@angular/core";

@Component({
  selector: "oz-badge",
  templateUrl: "./badge.component.pug",
})
export class BadgeComponent implements OnInit {
  @Input("count") count: number = 0;
  @Input("className") className?: string;

  constructor() {}

  ngOnInit(): void {}
}
