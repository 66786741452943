import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { TranslateModule } from "@ngx-translate/core";
import { FormsModule } from "@angular/forms";
import {
  WebviewDirective,
  DebounceClickDirective,
  LongPressDirective,
} from "./directives/";
import { SliderComponent } from "./components/slider/slider.component";
import { SliderRangeComponent } from "./components/slider-range/slider-range.component";

@NgModule({
  declarations: [
    WebviewDirective,
    DebounceClickDirective,
    LongPressDirective,
    SliderComponent,
    SliderRangeComponent,
  ],
  imports: [CommonModule, TranslateModule, FormsModule],
  exports: [
    TranslateModule,
    WebviewDirective,
    DebounceClickDirective,
    LongPressDirective,
    FormsModule,
    SliderComponent,
    SliderRangeComponent,
  ],
})
export class SharedModule {}
