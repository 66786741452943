import { Injectable } from "@angular/core";
import { combineLatest, Observable } from "rxjs";
import { map } from "rxjs/operators";
import { DeviceManagerService, OzDevice } from "./device-manager.service";
import { Dfu } from "./dfu.service";

@Injectable({
  providedIn: "root",
})
export class DfuBadgeCounterService {
  constructor(private dfu: Dfu, private deviceManager: DeviceManagerService) {}

  /**
   * @returns
   *  - `3` if update is available for all of the following: `device`, parent device and charge case
   *  - `2` if update is available for two of the following: the `device`, parent device or charge case
   *  - `1` if update is available for one of the following: the `device`, parent device or charge case
   *  - `0` if update is not available for all of the following: the `device`, parent device and charge case
   */
  count(device: OzDevice): Observable<number> {
    // Find Charge Case connected with USB cable
    const chargeCase = this.deviceManager.getDeviceByUniqueId(
      device.peerChargeCase?.GenesGuid
    );
    return combineLatest([
      this.dfu.availableUpdate(device),
      this.dfu.availableUpdate(device.parent),
      this.dfu.availableUpdate(chargeCase),
    ]).pipe(
      map((updates) => {
        return updates.filter((x) => !!x).length;
      })
    );
  }
}
