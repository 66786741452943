import { EulaService } from "./../services/eula.service";
import { Router } from "@angular/router";
import { UtilityService } from "./../services/utility.service";
import { EULA_URL } from "./../utils/constants";
import { Component, OnInit } from "@angular/core";
import { AdminConfig } from "../services/admin-config.service";
import { AuthService } from "../services/auth.service";
import { combineLatest } from "rxjs";
import { PolytronServiceApi } from "../polytron/polytron.service.api";

@Component({
  selector: "oz-eula",
  templateUrl: "./eula.component.pug",
})
export class EulaComponent implements OnInit {
  checked: boolean = false;
  isLoginless: boolean = false;
  acceptEulaByUser: boolean = false;

  constructor(
    private router: Router,
    private eulaService: EulaService,
    private adminConfig: AdminConfig,
    private authService: AuthService,
    private polytronService: PolytronServiceApi
  ) {}

  ngOnInit(): void {
    combineLatest([
      this.authService.isLoginless$,
      this.eulaService.acceptEula$,
    ]).subscribe(([isLoginless, acceptEula]) => {
      this.isLoginless = isLoginless;
      this.acceptEulaByUser = acceptEula && acceptEula === "1";
    });
  }

  agree() {
    this.eulaService.agree();
    if (this.adminConfig.mode === "network" || this.isLoginless) {
      this.router.navigateByUrl("/home");
    } else {
      this.router.navigateByUrl("/ftu");
    }
  }

  close() {
    this.polytronService.quit();
  }

  closeable(): boolean {
    return this.polytronService.quitable();
  }

  openEula($event: Event) {
    // prevent checkbox from being toggled
    $event.preventDefault();

    UtilityService.openExternalBrowser(EULA_URL);
  }
}
