import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { SETTINGS } from "../../app/utils/constants";
import { IDeviceSettingsManager, DeviceSetting, AERSSetting } from "../api/device-settings-manager.api";
import { hexEqual } from "../common/utils/utils";
import { WhDeviceManager } from "./wh-device-manager";


@Injectable({
    providedIn: "root"
})
export class WhDeviceSettingsManager extends IDeviceSettingsManager {
  constructor(private deviceMgr: WhDeviceManager) {
    super()
  }

  getDeviceSettings(deviceId: string): Observable<DeviceSetting[]> {
    return this.deviceMgr.getDeviceSettings(deviceId)
  }

  getDeviceSetting(deviceId: string, deviceSettingId: string): Observable<DeviceSetting> {
    throw new Error("Method not implemented.");
  }

  setDeviceSetting(deviceId: string, deviceSetting: Partial<DeviceSetting>): void {
    this.deviceMgr.setDeviceSetting(deviceId, deviceSetting)
    .then(() => {
      if (deviceSetting.id && hexEqual(deviceSetting.id, SETTINGS.TRACKING_MODE)) {
        this.scheduleSettingsPublish(deviceId)
      }
    })
    .catch(() => this.scheduleSettingsPublish(deviceId));
  }

  setDeviceSettings(deviceId: string, deviceSettings: Partial<DeviceSetting>[]): void {
    let promise = Promise.resolve();
    for (let sett of deviceSettings) {
      promise = this.deviceMgr.setDeviceSetting(deviceId, sett)
    }
    promise.finally(() => {
      setTimeout(() => {
        this.scheduleSettingsPublish(deviceId)
        this.scheduleSettingsPublish(deviceId)
      }, 0)
    });
  }

  // Used to republish the device settings after applying camera favorites since ControlsComponent.initDevice()
  // (in particular pendingDeviceSettingChangeRequests) expects 2 or 3 device setting updates even if no settings have
  // changed (= lame way to find out that the changes have been applied). Otherwise the favorites widget stays disabled.
  // Also used to restore the original setting value after a setting update failure.
  // Sent during the next macrotask since immediate updates get ignored.
  private scheduleSettingsPublish(deviceId: string) {
    setTimeout(() => this.deviceMgr.publishDeviceSettings(deviceId), 0)
  }

  setAERSSettings(deviceId: string, aersSettings: Partial<AERSSetting>[]): void {
    //TODO: implement for PWA
  }
}
