import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { Injectable } from "@angular/core";
import { shareReplay } from "rxjs/operators";
import { Repository } from "./repository/repository.service";
import { RepositoryDeviceResources } from "./repository/model";

const DEFAULT_LINK = "https://www.poly.com/us/en/support/product";

@Injectable({
  providedIn: "root",
})
export class DeviceResourceService {
  pidMap: { [pid: number]: Observable<RepositoryDeviceResources> } = {};
  constructor(private repo: Repository) {}

  getDeviceResources(pid: number) {
    if (!this.pidMap[pid]) {
      this.pidMap[pid] = this.repo.getDeviceResources(pid).pipe(
        map((resources) => {
          return {
            quickStartUrl: resources?.quickStartUrl || DEFAULT_LINK,
            userGuideUrl: resources?.userGuideUrl || DEFAULT_LINK,
          };
        }),
        shareReplay()
      );
    }

    return this.pidMap[pid];
  }
}
