import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { RemoteService } from "./remote.service";

export interface BadgeCount {
  total: number;
  [key: string]: number;
}

export const BADGE_SOFTWARE_UPDATE = "Software Update";
export const BADGE_ACCOUNT = "Account";

@Injectable({
  providedIn: "root",
})
export class BadgeCountService {
  private _count = new BehaviorSubject<BadgeCount>({ total: 0 });

  constructor(private remote: RemoteService) {}

  get count$() {
    return this._count.asObservable();
  }

  public incrementCountBy1(key: string): void {
    this.addCountForKey(key, 1);
  }

  public decrementCountBy1(key: string): void {
    this.addCountForKey(key, -1);
  }

  public setCountForKey(key: string, count: number) {
    const currentState = { ...this._count.value };
    currentState[key] = count;
    currentState.total = Object.keys(currentState).reduce((result, key) => {
      if (key !== "total") {
        result += currentState[key];
      }
      return result;
    }, 0);
    // TODO: this.remote.getRemote().app.setBadgeCount(currentState.total);
    this._count.next(currentState);
  }

  private addCountForKey(key: string, delta: number): void {
    const currentState = this._count.value;
    const newTotal = Math.max(0, currentState.total + delta);
    const newValue = Math.max(0, (currentState[key] ?? 0) + delta);
    // TODO: this.remote.getRemote().app.setBadgeCount(newTotal);
    this._count.next(
      Object.assign(currentState, {
        [key]: newValue,
        total: newTotal,
      })
    );
  }
}
