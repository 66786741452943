import { mergeMap } from "rxjs/operators";
import { filter } from "rxjs/operators";
import { MS_IN_HOUR } from "../utils/constants";
import { EulaService } from "./eula.service";
import { Injectable } from "@angular/core";
import { Subscription } from "rxjs";
import {
  BADGE_SOFTWARE_UPDATE,
  BadgeCountService,
} from "./badge-count.service";
import { LensSettingsService } from "./lens-settings.service";
import { NotificationsService } from "./notifications.service";
import { gte as semverGte, valid as semverValid } from "semver";
import { TranslateService } from "@ngx-translate/core";
import { UtilityService } from "./utility.service";
import { Repository } from "../services/repository/repository.service";
import { RepositorySoftware } from "../services/repository/model";
import { AdminConfig } from "./admin-config.service";

@Injectable({
  providedIn: "root",
})
export class SwUpdateNotificationsService {
  softwareUpdateSub: Subscription;

  build: string = UtilityService.getBuild();
  version: string = UtilityService.getBuildVersion();
  notificationsEnabled: boolean;
  badgeIncremented: boolean = false;

  constructor(
    private lensSettingsService: LensSettingsService,
    private notificationService: NotificationsService,
    private repo: Repository,
    private badgeCountService: BadgeCountService,
    private translateService: TranslateService,
    private eulaService: EulaService,
    private adminConfig: AdminConfig,
  ) {
    this.lensSettingsService.lensSettings.subscribe((settings) => {
      this.notificationsEnabled =
        settings.softwareUpdate && settings.enableNotifications;
    });
    if(adminConfig.mode !== 'pwa') {
      this.setupNotifications();
    }
  }

  private incrementBadge(): void {
    if (!this.badgeIncremented) {
      this.badgeCountService.incrementCountBy1(BADGE_SOFTWARE_UPDATE);
      this.badgeIncremented = true;
    }
  }

  private setupNotifications() {
    this.softwareUpdateSub = this.eulaService.agreed$
      .pipe(
        filter((v) => v),
        mergeMap(() =>
          this.repo.getSoftware({
            productId: UtilityService.getBuildProductId(),
            releaseChannel: UtilityService.getBuildReleaseChannel(),
            pollInterval: MS_IN_HOUR * 4,
          })
        )
      )
      .subscribe((result: RepositorySoftware) => {
        const build = result?.productBuild?.build;
        const version = result?.version;
        if (!build || !version) {
          return;
        }
        const versionsValid: boolean = null !== semverValid(version) && null !== semverValid(this.version);
        const greaterVersion: boolean = semverGte(version, this.version);
        // If the semver version is greater or equal, and the builds are different, update
        if ((!versionsValid || greaterVersion) && build != this.build) {
          this.incrementBadge();
          this.notify(build);
        }
      });
  }

  private notify(updateBuild: string) {
    if (!this.notificationsEnabled) {
      return;
    }
    this.translateService
      .get(["GENERAL.POLY_LENS", "NOTIFICATIONS.SOFTWARE_UPDATE.DESCRIPTION"], {
        build: updateBuild,
      })
      .subscribe((trans) => {
        this.notificationService.singleNotification({
          title: trans["GENERAL.POLY_LENS"],
          body: trans["NOTIFICATIONS.SOFTWARE_UPDATE.DESCRIPTION"],
        });
      });
  }
}
