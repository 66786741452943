import { Component, Input } from "@angular/core";
import {
  Toast,
  ToastAction,
  Toasts,
} from "../../../../shared/components/toast/toasts.service";
import { DeviceNotificationService } from "../../../../services/device-notification.service";
import { TOAST_CLOSE_ACTION_TYPES } from "../../../../utils/constants";

@Component({
  selector: "oz-action-toast",
  templateUrl: "./action-toast.component.pug",
})
export class ActionToastComponent {
  @Input()
  options: Toast;

  constructor(
    private toasts: Toasts,
    private deviceNotification: DeviceNotificationService
  ) {}

  onActionClick(toastAction: ToastAction) {
    toastAction.action();
    if (toastAction.dismissToast) {
      this.dismiss();
    }
  }

  onClose() {
    if (
      this.options?.closeAction === TOAST_CLOSE_ACTION_TYPES.P21_DISPLAY_LINK
    ) {
      this.deviceNotification.onDisplayLinkToastClose(this.options);
    }
    this.dismiss();
  }

  private dismiss() {
    this.toasts.dismiss(this.options.id);
  }
}
