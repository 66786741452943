import {Component, Input} from "@angular/core";
import { DeviceManagerService } from "../../../services/device-manager.service";
import {ActivatedRoute, NavigationEnd, Router} from "@angular/router";
import {Location} from "@angular/common";
import {BadgeCountService} from "../../../services/badge-count.service";
import {StateService} from "../../../services/state.service";
import {filter, map, startWith, switchMap} from "rxjs/operators";

export interface TitleBar {
  disabled: boolean;
  hidden: boolean;
  back?: {
    labelKey: string;
    url?: string;
    goBack?: boolean;
  };
}

export const defaultTitleBar: TitleBar = {
  disabled: false,
  hidden: false,
  back: undefined,
};

@Component({
  selector: "control-bar",
  templateUrl: "./control-bar.component.pug",
})
export class ControlBarComponent {
  @Input() goBack: boolean = false;
  private disabled: boolean = false;
  public showSettingsBadge = false;
  public state: TitleBar = defaultTitleBar;

  constructor(
    private deviceManager: DeviceManagerService,
    private activatedRoute: ActivatedRoute,
    private badgeCountService: BadgeCountService,
    private location: Location,
    private router: Router,
    private stateService: StateService,
  ) {}

  backLinkClick() {
    if (this.state?.back?.goBack) {
      this.location.back();
      return;
    }

    this.router.navigateByUrl(this.state?.back?.url);
  }

  ngOnInit() {
    // watcher to see if the "update" badge should be displayed
    this.badgeCountService.count$.subscribe((n) => {
      this.showSettingsBadge = !!n.total;
    });

    // watcher to see if icons should be disable (when modals are shown)
    this.stateService.getState$("TitleBar").subscribe((state) => {
      this.state = {
        ...this.state,
        disabled: state.disabled,
      };
    });

    this.router.events
      .pipe(
        filter((event) => {
          return event instanceof NavigationEnd;
        }),
        startWith(this.activatedRoute),
        map(() => this.activatedRoute),
        map((route) => {
          while (route.firstChild) {
            route = route.firstChild;
          }

          return route;
        }),
        filter((route) => route.outlet === "primary"),
        switchMap((route) => route.data)
      )
      .subscribe((data) => {
        this.state = {
          ...defaultTitleBar,
          ...data?.titleBar,
        };
      });
  }

  async connect() {
    this.deviceManager.requestDevice();
  }
}
