import { ILoggingService } from "../app/services/logging.service";
import { SpeedTestValue } from "../IPC/SpeedTest";
import { Utility } from "./Utility";

// const defaultGateway = require("default-gateway");
// const dgram = require("dgram");
// const net = require("net");
// const pingLite = require("ping-lite");

export class PingTest {
  pings = BigInt(0);
  pongs = BigInt(0);
  jitterValues = [];
  pingValues = [];

  constructor(
    private options: any,
    private logger?: ILoggingService
  ) {}

  private resolveAddress() {
    if ("DEFAULT_GATEWAY" === this.options.address) {
      // special case to ping the default gateway (e.g. local router) address
      // since this is a simple call, can just run here and not use LocalNetworkTest
      try {
        console.log("XXX PingTest.resolveAddress");
        throw new Error("gateway not defined");
        // TODO:
        // const { gateway } = defaultGateway.v4.sync();
        // return gateway;
      } catch (e) {
        // TODO handle error
        this.logger.error(`Could not determine default gateway address: ${e}`);
      }
    }

    return this.options.address;
  }

  public async run(): Promise<Array<SpeedTestValue>> {
    let runCount;

    // Intentionally run one extra, throw out first pass which is consistently significantly larger
    for (runCount = 0; runCount <= this.options.count; runCount++) {
      let method = this.tcpPing;

      if (this.options.protocol) {
        switch (this.options.protocol) {
          case "udp":
            method = this.udpPing;
            break;
          case "icmp":
            method = this.icmpPing;
            break;
          case "tcp":
          default:
            method = this.tcpPing;
            break;
        }
      }
      let time = null;
      try {
        time = await method.apply(this, []);
      } catch (e:any) {
        // TODO add this error to object
        this.logger.error(
          `Error trying ${
            this.options.protocol
          } ping to ${this.resolveAddress()}:`,
          e
        );
      }

      if (time !== null && runCount > 0) {
        this.pingValues.push(time);
      }
    }

    this.jitterValues = this.pingValues.reduce((acc, ping, i: number) => {
      if (this.pingValues[i + 1]) {
        acc.push(Math.abs(Utility.nanoToMS(this.pingValues[i + 1] - ping)));
      }

      return acc;
    }, []);

    const jitter_ms = Utility.round(Utility.computeAverage(this.jitterValues));
    const ping_ms = Utility.round(Utility.computeAverage(this.pingValues));
    const ping_min_ms = Utility.round(Utility.computeMin(this.pingValues));
    const ping_max_ms = Utility.round(Utility.computeMax(this.pingValues));

    const packet_loss_percentage = +Math.abs(
      (Number(String(this.pongs)) / Math.max(1, Number(String(this.pings)))) * 100 - 100
    ).toFixed(2);

    return [
      {
        value: ping_min_ms,
        unit: "ms",
        metric: "min",
      },
      {
        value: ping_max_ms,
        unit: "ms",
        metric: "max",
      },
      {
        value: ping_ms,
        unit: "ms",
        metric: "ping",
      },
      {
        value: jitter_ms,
        unit: "ms",
        metric: "jitter",
      },
      {
        value: packet_loss_percentage,
        unit: "%",
        metric: "loss",
      },
    ];
  }

  private tcpPing(): Promise<null | bigint | number> {

    return new Promise((resolve, reject) => {
      try {
        var address = this.resolveAddress();
        if (! address.startsWith("http")) {
          address = "https://" + address;
        }

        const xhr = new XMLHttpRequest();
        xhr.open("GET", address);
        xhr.timeout = this.options.ping_timeout_ms;

        const start = Utility.getStampMS();

        xhr.onload = (ev) => {
          const delta = Utility.getStampMS() - start;
          this.pings++;
          this.pongs++;
          resolve(delta);
        }

        xhr.onerror = (ev) => {
          console.log("XXX tcpPing - onerror [" + JSON.stringify(ev, ["message", "arguments", "type", "name"]) + "]");
          // TODO: What is the error?
          const delta = Utility.getStampMS() - start;
          this.pings++;
          this.pongs++;
          resolve(delta);
          // resolve(null);
        }

        xhr.ontimeout = (ev) => {
          this.pings++;
          resolve(null);
        }

        xhr.send();

      } catch (e) {
        const error = `TCP ping error: ${e}`;
        this.logger.error(error);
        reject(error);
      }
    });
  }

  /**
   * Note, this does not utilize process.hrtime.bigint(), because
   * the overhead to spawn the process should not be counted.
   * Instead, the ms response time is retrieved.
   *
   * @private
   */
  private icmpPing(): Promise<null | bigint | number> {
    console.log("XXX PingTest.icmpPing");
    return Promise.resolve(Utility.randomMS());

  /* TODO:
    return new Promise((resolve, reject) => {
      // for now, just IPv4 though library may support IPv6
      try {
        const ping = new pingLite(this.resolveAddress());
        ping.send((err, ms: number) => {
          this.pings++;
          if (err) {
            reject(null);

            return;
          }

          this.pongs++;

          // e.g. 1.23 ms becomes 1,230,000 nanoseconds
          resolve(BigInt(Math.round(ms * NANOSECONDS_PER_MILLISECOND)));
        });
      } catch (e) {
        const error = `ICMP ping error: ${e}`;
        this.logger.error(error);
        reject(error);
      }
    });
  */
  }

  private udpPing(): Promise<null | bigint | number> {
    console.log("XXX PingTest.udpPing");
    return Promise.resolve(Utility.randomMS());

    /* TODO:
    return new Promise((resolve, reject) => {
      try {
        const start = process.hrtime.bigint();
        const socket = dgram.createSocket("udp4");
        const closeOnTimeout = setTimeout(() => {
          socket.close();
          this.pings++;
          resolve(null);
        }, this.options.ping_timeout_ms);

        socket.on("message", (msg, rinfo) => {
          // packet response
          const end = process.hrtime.bigint();
          socket.close();
          clearTimeout(closeOnTimeout);
          this.pings++;
          if ("who's there?" === msg.toString()) {
            this.pongs++;
            resolve(end - start);
          } else {
            resolve(null);
          }
        });

        socket.on("error", (e) => {
          socket.close();
          clearTimeout(closeOnTimeout);
          this.pings++;
          resolve(null);
        });

        // proper message sending
        // NOTE: the host/port pair points at server
        const message = Buffer.from("knock knock");
        socket.send(
          message,
          0,
          message.length,
          this.options.port,
          this.resolveAddress()
        );
      } catch (e) {
        const error = `UDP ping error: ${e}`;
        this.logger.error(error);
        reject(error);
      }
    });
    */
  }
}
