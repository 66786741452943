import { Component, OnDestroy, OnInit } from "@angular/core";
import { ActivatedRoute, ParamMap } from "@angular/router";
import { StopAudioTestResponse } from "./../../dev-mgmt/api/device-manager.api";
import { DeviceManagerService } from "./../services/device-manager.service";
import {
  OzFileDialog,
  OzFileDialogOptions,
} from "./../services/file-dialog.service";
import { Toasts, Toast } from "../shared/components/toast/toasts.service";
import { combineLatest, interval, Observable, of, Subscription } from "rxjs";
import { filter, switchMap } from "rxjs/operators";

const TRANSLATION_PREFIX = "DeviceSettings.AUDIO_TEST";

const FILTERS = [{ name: "Diagnostic File", extensions: ["tgz"] }];

const EXPORT_CONFIG_DIALOG_OPTIONS: OzFileDialogOptions = {
  title: `${TRANSLATION_PREFIX}.DIALOG_TITLE`,
  titleTranslate: true,
  filters: FILTERS,
};

const AUDIO_DIAGNOSTIC_FAILED_TOAST: Toast = {
  type: "status",
  status: "error",
  text: "NOTIFICATIONS.AUDIO_DIAGNOSTIC_FAILED",
};

const AUDIO_RECORDING_START_TOAST: Toast = {
  type: "status",
  status: "success",
  text: "NOTIFICATIONS.AUDIO_RECORDING_START",
};

@Component({
  selector: "oz-audio-test",
  templateUrl: "./audio-test.component.pug",
})
export class AudioTestComponent implements OnInit, OnDestroy {
  public showDialog: boolean = false;
  public showWaitText: boolean = false;
  public start: Date = new Date(0, 0, 0, 0, 1, 30);

  private clock: Subscription;
  private stopAudioTest: ReturnType<typeof setTimeout>;
  private deviceId: string;

  private readonly stopTest: number = 1000 * 60 * 1.5;
  private readonly clockRate: number = 1000;
  private readonly duration: number = 1.5 * 60;

  constructor(
    private deviceManager: DeviceManagerService,
    private route: ActivatedRoute,
    private fileDialog: OzFileDialog,
    private toasts: Toasts
  ) {}

  ngOnDestroy(): void {
    this.showDialog = false;
    if (!!this.clock) {
      this.clock.unsubscribe();
    }
    clearTimeout(this.stopAudioTest);
  }

  ngOnInit(): void {
    this.getDevice();
  }

  /**
   * Get current device
   */
  private getDevice(): void {
    this.route.parent.paramMap
      .pipe(
        switchMap((paramMap: ParamMap) => {
          return this.deviceManager.getDevice(paramMap.get("id"));
        }),
        filter((device) => !!device)
      )
      .subscribe((device) => {
        this.deviceId = device.id;
      });
  }

  /**
   * Start recording for audio diagnostics that will stop automatically after 3 minutes
   */
  public startRecording(): void {
    this.showDialog = true;
    this.deviceManager.startAudioTest(this.deviceId).then(
      // TODO: UI: Check with the UX team for the success/error toasts messages
      (response) => {
        this.toasts.push(AUDIO_RECORDING_START_TOAST);
      },
      (error) => {
        // TODO: UI: We need to check with the UX team for the actual message.
      }
    );
    this.clock = interval(this.clockRate).subscribe((seconds) => {
      this.start = new Date(0, 0, 0);
      const timeLeft = this.duration - seconds;
      this.start.setSeconds(timeLeft);
    });

    this.stopAudioTest = setTimeout(() => {
      this.stopRecording();
    }, this.stopTest);
  }

  /**
   * Stop recording for audio diagnostics and save file
   */
  public stopRecording(): void {
    this.start = new Date(0, 0, 0);
    this.clock.unsubscribe();
    clearTimeout(this.stopAudioTest);
    this.showWaitText = true;

    this.saveAudioTest().subscribe(([response]) => {
      if (response.status !== "OK") {
        this.toasts.push(AUDIO_DIAGNOSTIC_FAILED_TOAST);
      }

      this.showDialog = false;
      this.showWaitText = false;
    });
  }

  /**
   * Save audio diagnostics file
   * @returns Observable<[StopAudioTestResponse]>
   */
  private saveAudioTest(): Observable<[StopAudioTestResponse]> {
    return this.fileDialog.saveFile(EXPORT_CONFIG_DIALOG_OPTIONS).pipe(
      switchMap(({ path, canceled }) => {
        return !canceled
          ? combineLatest([
              this.deviceManager.stopAudioTest({
                deviceId: this.deviceId,
                fileName: path,
              }),
            ])
          : of<[StopAudioTestResponse]>([
              {
                status: "Unknown",
                deviceId: this.deviceId,
              },
            ]);
      })
    );
  }
}
