import { Component, Output, EventEmitter, Input } from "@angular/core";
import { DFUError, DFUUserAction } from "@poly/hub-native";

@Component({
  selector: "oz-dfu-retry",
  templateUrl: "./dfu-retry.component.pug",
})
export class DfuRetryComponent {
  @Input() message: DFUError | DFUUserAction;
  @Output() cancelDfu = new EventEmitter();
  @Output() retryDfu = new EventEmitter();
  @Output() continueDfu = new EventEmitter();
}
