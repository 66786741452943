import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { DeviceSetting } from "@poly/hub-native";
import { DeviceManagerService } from "../../services/device-manager.service";
import { UtilityService } from "../../services/utility.service";
import { AccordionItem } from "../../shared/components/accordion/accordion.component";
import { TranslatedOption } from "../../shared/components/dropdown/dropdown.component";
import { SETTINGS_MODALS, SETTINGS } from "../../utils/constants";
import { DeviceSettingMetadata } from "../device-settings.component";
import { debounce as _debounce, isEqual as _isEqual } from "lodash";
import { SettingsUI } from "../settings-ui.model";
import { hexEqual, sortOptionsAlphabetically } from "../../utils/utils";
import { Subscriptions } from "../../utils/subscriptions";

@Component({
  selector: "oz-device-setting",
  templateUrl: "./device-settings-category-item.component.pug",
})
export class DeviceSettingsCategoryItemComponent implements OnInit, OnDestroy {
  @Input() set accordionData(value: AccordionItem) {
    this.setting = value.data;
  }

  private uiActionDebounceMS = 100;

  public childDisabled = false;
  public childHidden = false;
  public childSetting: Partial<DeviceSettingMetadata>;
  public childTranslatedOptions: TranslatedOption[] = [];
  public dependencies: SettingsUI.SettingDependency[] = SettingsUI.dependencies;
  public disabled = false;
  public hasChild = false;
  public hidden = false;
  public setting: DeviceSettingMetadata;
  public showAntiStartleModal = false;
  public translatedOptions: TranslatedOption[] = [];
  public subs = new Subscriptions();
  public UtilityService = UtilityService;
  public SETTINGS_MODALS = SETTINGS_MODALS;
  public settingsModal = {
    show: false,
    id: "",
  };
  public settingOptions: string[] = [];

  antiStartleIds: string[] = [
    SETTINGS.ANTI_STARTLE,
    SETTINGS.ANTI_STARTLE_ENHANCED,
    SETTINGS.G616_ANTI_STARTLE,
  ];
  antiStartle = false;

  constructor(
    private translate: TranslateService,
    private deviceManager: DeviceManagerService
  ) {}

  ngOnInit() {
    if (this.setting.mutateOptions) {
      this.settingOptions = this.setting.mutateOptions(this.setting.options);
    } else {
      this.settingOptions = this.setting.options;
    }

    // In case this setting is represented as a dropdown, find all translation
    // paths for all possible options; dropdown component which renders these
    // options will translate them based on provided translation paths.
    if (this.setting.type === "dropdown") {
      const localizationPaths = this.settingOptions.map((option: string) =>
        UtilityService.getDeviceSettingTranslatePath(
          this.setting,
          `options.${option}`
        )
      );

      this.translate.get(localizationPaths).subscribe((translatedOptions) => {
        const orderedList: TranslatedOption[] = [];

        for (const option of this.settingOptions) {
          orderedList.push({
            value: option,
            text:
              translatedOptions[
                UtilityService.getDeviceSettingTranslatePath(
                  this.setting,
                  `options.${option}`
                )
              ],
          });
        }

        if (this.setting.sortAlpha) {
          sortOptionsAlphabetically(orderedList);
        }

        this.translatedOptions = orderedList;
      });
    }

    if (this.setting.childSettingId) {
      this.hasChild = true;
      this.childSetting = {
        id: this.setting.childSettingId,
      };

      for (const category of SettingsUI.json) {
        const subS = category.settings.find((s) =>
          UtilityService.hexIsEqual(s.id, this.childSetting.id)
        );
        if (!subS) {
          continue;
        }
        this.childSetting.type = subS.type;
        break;
      }
    }

    this.subs.add(
      this.setting.settings$.subscribe(([deviceSettings]) => {
        this.checkForDependencies(deviceSettings, this.setting.id);

        if (this.hasChild) {
          const dSetting = deviceSettings.find((s) =>
            UtilityService.hexIsEqual(s.id, this.childSetting.id)
          );

          this.childSetting.options = dSetting.options;
          this.childSetting.value = dSetting.value;

          this.checkForDependencies(deviceSettings, this.childSetting.id, true);
        }
      })
    );

    // translate options for child setting
    if (this.hasChild && this.childSetting.type === "dropdown") {
      const localizationPaths = this.childSetting.options.map(
        (option: string) =>
          UtilityService.getDeviceSettingTranslatePath(
            this.childSetting,
            `options.${option}`
          )
      );

      this.translate.get(localizationPaths).subscribe((translatedOptions) => {
        const orderedList: TranslatedOption[] = [];

        for (const option of this.childSetting.options) {
          orderedList.push({
            value: option,
            text:
              translatedOptions[
                UtilityService.getDeviceSettingTranslatePath(
                  this.childSetting,
                  `options.${option}`
                )
              ],
          });
        }

        if (this.childSetting.sortAlpha) {
          sortOptionsAlphabetically(orderedList);
        }

        this.childTranslatedOptions = orderedList;
      });
    }

    // Check if the setting is Anti-Startle
    if (
      this.antiStartleIds.filter((id) => hexEqual(id, this.setting.id)).length
    ) {
      this.antiStartle = true;
    }
  }

  checkForDependencies(
    settingsList: DeviceSetting[],
    settingId: string,
    isChild = false
  ) {
    this.dependencies
      .filter((d) => UtilityService.hexIsEqual(d.settingId, settingId))
      .forEach((dependency) => {
        let compareResult = false;
        dependency.when.forEach((source) => {
          compareResult =
            compareResult ||
            _isEqual(
              source.value,
              settingsList.find((s) =>
                UtilityService.hexIsEqual(s.id, source.settingId)
              )?.value
            );

          switch (dependency.action) {
            case "disable":
              {
                if (isChild) this.childDisabled = compareResult;
                else this.disabled = compareResult;
              }
              break;
            case "hide":
              {
                if (isChild) this.childHidden = compareResult;
                else this.hidden = compareResult;
              }
              break;
            default:
              break;
          }
        });
      });
  }

  onSettingChanged(val: any, childSetting: boolean) {
    this.performChange(
      val,
      childSetting ? this.childSetting.id : this.setting.id
    );
  }

  onSubsettingChanged(val: any, subsetting: DeviceSettingMetadata) {
    this.performChange(val, subsetting.id);
  }

  private performChange(val: any, settingId: string) {
    const deviceSetting: Partial<DeviceSetting> = {
      id: UtilityService.unpadHex(settingId, false),
    };

    if (typeof val === "boolean") {
      deviceSetting.value = val ? "true" : "false";
    } else if (typeof val === "string") {
      deviceSetting.value = val;
    } else if (typeof val === "number") {
      deviceSetting.value = `${val}`; // native layer expects numeric values to be strings
    } else {
      throw new Error("Unhandled value type!");
    }

    this.deviceManager.setDeviceSetting(this.setting.device.id, deviceSetting);
  }

  /**
   * Debounce will wait until function stops being called for n milliseconds
   * to issue an update, which helps for cases like sliders.
   */
  onSettingChangedDebounced = _debounce(
    (val: boolean | string | number) => {
      this.onSettingChanged(val, false);
    },
    this.uiActionDebounceMS,
    { leading: false, trailing: true }
  );

  openPolySite() {
    UtilityService.openExternalBrowser("https://www.poly.com/us/en");
  }

  showSettingModal(id: string) {
    this.settingsModal = {
      show: true,
      id,
    };
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }
}
