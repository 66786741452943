import { Component, OnInit, Input } from "@angular/core";
import {
  Device,
  CreateCertificateRequest,
  CreateCertificateResponse,
  InstallCertificateRequest,
  InstallCertificateResponse,
  CertificateDetailsRequest,
  DeleteCertificateRequest,
  SetWifiClientCertPasswordRequest,
  SetWifiClientCertPasswordResponse, GetCertificateFileResponse,
} from "@poly/hub-native";
import { AccordionItem } from "../../shared/components/accordion/accordion.component";
import { DeviceManagerService } from "../../services/device-manager.service";
import { TranslateService } from "@ngx-translate/core";
import { Toasts, Toast } from "../../shared/components/toast/toasts.service";
import {PolytronServiceApi} from "../../polytron/polytron.service.api";
import {ILoggingService} from "../../services/logging.service";
import {AdminConfig} from "../../services/admin-config.service";
// TODO: import * as fs from "fs";

const SAVE_CSR_FILE_SUCCESS_TOAST: Toast = {
  type: "status",
  status: "success",
  text: "NOTIFICATIONS.SAVE_CSR_FILE_SUCCESS",
};

const SAVE_CSR_FILE_FAILED_TOAST: Toast = {
  type: "status",
  status: "error",
  text: "NOTIFICATIONS.SAVE_CSR_FILE_FAILED",
};

const CERTIFICATE_DELETE_SUCCESS_TOAST: Toast = {
  type: "status",
  status: "success",
  text: "NOTIFICATIONS.CERTIFICATE_DELETE_SUCCESS",
};

const CERTIFICATE_INSTALL_SUCCESS_TOAST: Toast = {
  type: "status",
  status: "success",
  text: "NOTIFICATIONS.CERTIFICATE_INSTALL_SUCCESS",
};

const CERTIFICATE_INSTALL_FAILED_TOAST: Toast = {
  type: "status",
  status: "error",
  text: "NOTIFICATIONS.CERTIFICATE_INSTALL_FAILED",
};

const FILE_READ_FAILED_TOAST: Toast = {
  type: "status",
  status: "error",
  text: "NOTIFICATIONS.FILE_READ_FAILED",
};

export enum RestErrorCodes {
  OK = 200,
  NeedPassword = 201,
  Fail = 408,
}
@Component({
  selector: "oz-device-settings-certificates-item",
  templateUrl: "./device-settings-certificates-item.component.pug",
})
export class DeviceSettingsCertificatesItemComponent implements OnInit {
  @Input() set accordionData(newValue: AccordionItem) {
    this.device = newValue.data.device;
  }

  // Device instance
  device: Device;

  // Always validate peer certificates from server
  validatePeerCertificats = false;

  // Certificate Signing Request
  showCsrModal = false;
  csr: CreateCertificateRequest;

  // Certificate details
  showCertDetailsModal = false;
  certificateDetails = "";

  certificateTypeMap = {
    provisionClient: {
      value: "provision_pem",
      details: "provision_client_detail",
      label: "Provisioning, Client",
    },
    provisionServerCa: {
      value: "provision_server_ca",
      details: "provision_server_detail",
      label: "Provisioning, CA",
    },
    wifiCaCert: {
      value: "wifi_ca_cert",
      details: "wifi_server_detail",
      label: "Wi-Fi, CA",
    },
    wifiClientCert: {
      value: "wifi_client_cert",
      details: "wifi_client_detail",
      label: "Wi-Fi, User",
    },
  };

  certificateTypes = [
    {
      value: this.certificateTypeMap.provisionClient.value,
      text: "Provisioning Client Certificate",
    },
    {
      value: this.certificateTypeMap.provisionServerCa.value,
      text: "Provisioning CA Certificate",
    },
    {
      value: this.certificateTypeMap.wifiCaCert.value,
      text: "Wi-Fi CA Certificate",
    },
    {
      value: this.certificateTypeMap.wifiClientCert.value,
      text: "Wi-Fi User Certificate",
    },
  ];

  // Install Certificates
  installedCertificates: any[] = [];
  showInstallModal = false;
  selectedCertType = this.certificateTypeMap.provisionClient.value;
  certificatePath: string|FileSystemFileHandle = "";

  // Delete Certificate
  certificateToDelete = "";

  public showCertificatePasswordModal: boolean = false;
  public password: string = "";
  public showPasswordError: boolean = false;

  private labels = { SAVE_FILE_TITLE: "", CHOOSE_FILE_TITLE: "" };

  constructor(
    private deviceManager: DeviceManagerService,
    private translateService: TranslateService,
    private toasts: Toasts,
    private polytron: PolytronServiceApi,
    private logger: ILoggingService,
    private adminConfig: AdminConfig,
  ) {
    this.translateService
      .stream([
        "MODALS.CREATE_CERTIFICATE_SIGNING_REQUEST.SAVE_FILE_TITLE",
        "MODALS.INSTALL_CERTIFICATE.CHOOSE_FILE_TITLE",
      ])
      .subscribe((translations) => {
        Object.keys(translations).forEach((key) => {
          const strArr = key.split(".");
          const keyLabel = strArr[strArr.length - 1];
          this.labels[keyLabel] = translations[key];
        });
      });
  }

  ngOnInit(): void {
    this.getInstalledCertificates();
    this.initCsr();
  }

  getInstalledCertificates() {
    this.deviceManager
      .getInstalledCertificates(this.device?.id)
      .then((response) => {
        this.installedCertificates = response.certs;
        this.validatePeerCertificats = response.validation;
      });
  }

  showCertDetails(cert: any) {
    this.showCertDetailsModal = true;
    this.certificateToDelete = cert.type;
    const request: CertificateDetailsRequest = {
      deviceId: this.device.id,
      type: this.certificateTypeMap[cert.type].details,
    };
    this.deviceManager.certificateDetails(request).then((response) => {
      if (response.status === "OK") {
        this.certificateDetails = response.info;
      } else {
        this.certificateDetails = "";
      }
    });
  }

  deleteCertificate() {
    if (this.certificateToDelete) {
      const request: DeleteCertificateRequest = {
        deviceId: this.device.id,
        type: this.certificateToDelete,
      };
      this.deviceManager.deleteCertificate(request).then((response) => {
        if (response.status === "OK") {
          this.toasts.push(CERTIFICATE_DELETE_SUCCESS_TOAST);
          this.showCertDetailsModal = false;
          this.getInstalledCertificates();
        }
      });
    }
  }

  initCsr() {
    this.csr = {
      deviceId: this.device?.id,
      country: "",
      state: "",
      locality: "",
      organization: "",
      unit: "",
      commonName: "",
    };
  }

  changeValidatePeerCerts(value: boolean) {
    const request = {
      deviceId: this.device.id,
      enable: value,
    };
    this.deviceManager.setServerCAValidation(request).then((response) => {
      if (response.status !== "OK") {
        this.validatePeerCertificats = !value;
      }
    });
  }

  async createCSR() {
    const downloadsPath = this.polytron.getPath("downloads");
    const certificate: CreateCertificateResponse = await this.deviceManager.createCertificate(
      this.csr
    );

    if (this.adminConfig.mode !== 'pwa') {
      const saveDialog = await this.polytron.showSaveDialog(
        {
          title: this.labels.SAVE_FILE_TITLE,
          defaultPath: downloadsPath,
          filters: [{name: "CSR File", extensions: ["csr"]}],
          properties: ["createDirectory"], // [macOS] Allow creating new directories from dialog.
        }
      );
      // If user made selection
      if (!saveDialog.canceled && saveDialog.filePath) {
        // Clear CSR modal
        this.showCsrModal = false;
        this.initCsr();
        // Generate CSR content
        const certificateFile = await this.deviceManager.getCertificateFile(
          certificate.deviceId
        );
        // Create file and save it locally
        try {
          // TODO: fs.promises.writeFile(saveDialog.filePath, certificateFile.file);
          this.toasts.push(SAVE_CSR_FILE_SUCCESS_TOAST);
        } catch (e) {
          this.toasts.push(SAVE_CSR_FILE_FAILED_TOAST);
          console.log(e);
        }
      }
    }
    else {
      this.polytron.showSaveDialog({
        title: this.labels.SAVE_FILE_TITLE,
        description: "CSR File",
        suggestedName: "device",
        filters: {'application/pkcs10': ['.csr']},
      })
        .then(async ({canceled, filePath, writableFile}) => {
          if (!canceled && filePath && writableFile !== undefined) {
            // Clear CSR modal
            this.showCsrModal = false;
            this.initCsr();
            // Generate CSR content
            this.deviceManager.getCertificateFile(certificate.deviceId)
              .then(async (certificateFile: GetCertificateFileResponse ) => {
                this.logger.info("About to write CSR to [" + filePath + "]");
                try {
                  await writableFile.write(certificateFile.file);
                  this.toasts.push(SAVE_CSR_FILE_SUCCESS_TOAST);
                } catch (err) {
                  this.logger.error(`Could not save file ${filePath}, error ${err}`);
                  this.toasts.push(SAVE_CSR_FILE_FAILED_TOAST);
                } finally {
                  await writableFile.close();
                }
              })
              .catch((err) => {
                this.logger.error(`Failed saving CSR from device, error ${err}`);
                this.toasts.push(SAVE_CSR_FILE_FAILED_TOAST);
              });
          }
        })
        .catch((err) => {
          this.logger.error(`Failed saving CSR from device, error ${err}`);
          this.toasts.push(SAVE_CSR_FILE_FAILED_TOAST);
        });
    }
  }

  changeCertificateType($event) {
    this.selectedCertType = $event;
  }

  chooseCertificate() {
    let filter = { name: "Cert Files", extensions: ["crt", "pem"] };
    if (
      this.selectedCertType === this.certificateTypeMap.wifiClientCert.value
    ) {
      filter = { name: "P12 Files", extensions: ["p12"] };
    }
    this.polytron
      .showOpenDialog({
        title: this.labels.CHOOSE_FILE_TITLE,
        properties: ["openFile"],
        filters: [filter],
      })
      .then(({ canceled, filePaths }) => {
        if (!canceled) {
          this.certificatePath = filePaths[0];
        }
      });
  }

  async installCertificate() {
    try {
      /* TODO:
      const certificateContent = await fs.promises.readFile(
        this.certificatePath
      );
      const request: InstallCertificateRequest = {
        deviceId: this.device.id,
        type: this.selectedCertType,
        cert: certificateContent,
      };
      // Install certificate on device
      const response: InstallCertificateResponse = await this.deviceManager.installCertificate(
        request
      );
      if (
        response.status === "OK" &&
        response.restErrorCode === RestErrorCodes.OK
      ) {
        this.toasts.push(CERTIFICATE_INSTALL_SUCCESS_TOAST);
        this.getInstalledCertificates();

        // Wi-Fi User Certificate (.p12) cert file requires password
      } else if (
        response.status === "OK" &&
        response.restErrorCode === RestErrorCodes.NeedPassword
      ) {
        this.showCertificatePasswordModal = true;
      } else {
        this.toasts.push(CERTIFICATE_INSTALL_FAILED_TOAST);
      }
      */
    } catch (e) {
      this.toasts.push(FILE_READ_FAILED_TOAST);
      console.log(e);
    }
    this.closeInstallModal();
  }

  closeInstallModal() {
    this.showInstallModal = false;
    this.selectedCertType = this.certificateTypeMap.provisionClient.value;
    this.certificatePath = "";
  }

  closeCertificatePasswordModal() {
    this.showCertificatePasswordModal = false;
    this.showPasswordError = false;
    this.password = "";
    this.selectedCertType = this.certificateTypeMap.provisionClient.value;
    this.certificatePath = "";
  }

  async setCertificatePassword() {
    const request: SetWifiClientCertPasswordRequest = {
      deviceId: this.device.id,
      password: this.password,
    };
    const response: SetWifiClientCertPasswordResponse = await this.deviceManager.setWifiClientCertPassword(
      request
    );
    if (response.status === "OK" && response.success) {
      this.toasts.push(CERTIFICATE_INSTALL_SUCCESS_TOAST);
      this.closeCertificatePasswordModal();
      this.getInstalledCertificates();
    } else {
      this.showPasswordError = true;
      this.toasts.push(CERTIFICATE_INSTALL_FAILED_TOAST);
    }
  }
}
