import {
  Component,
  Input,
  OnInit,
  OnDestroy,
  Output,
  EventEmitter,
} from "@angular/core";
import {
  Device,
  WiFiStatusResponse,
  ScannedNetwork,
  WiFiConnectParams,
  CertificateDetailsRequest,
} from "@poly/hub-native";
import { timer } from "rxjs";
import { AccordionItem } from "../../shared/components/accordion/accordion.component";
import { Option } from "../../shared/components/dropdown/dropdown-with-icons.component";
import { WifiService } from "../../services/wifi.service";
import { Toast, Toasts } from "../../shared/components/toast/toasts.service";
import {
  EncryptionType,
  IPSettingsType,
  EAPmethod,
  getWiFiIconName,
  findEncryptionType,
  WifiConnectionStatus,
  NETWORK_JOIN_TIMEOUT,
} from "../../utils/wifiUtils";
import { Subscriptions } from "../../utils/subscriptions";
import { TranslateService } from "@ngx-translate/core";
import { DeviceManagerService } from "../../services/device-manager.service";
import { StringNullableChain } from "lodash";

const joinOtherNetworkValue = "join-other-network";

const WIFI_JOIN_ERROR_TOAST: Toast = {
  type: "status",
  status: "error",
  text: "NOTIFICATIONS.WIFI_JOIN_ERROR",
};

@Component({
  selector: "oz-device-wifi",
  templateUrl: "./device-settings-wifi-item.component.pug",
})
export class DeviceSettingsWifiItemComponent implements OnInit, OnDestroy {
  @Input() set accordionData(newValue: AccordionItem) {
    this.device = newValue.data.device;
  }

  // First Time Connection, change layout
  ftcLayout = false;
  @Input() set ftcDevice(newValue: Device) {
    this.device = newValue;
    this.ftcLayout = true;
  }
  @Output() wifiConfigured = new EventEmitter<boolean>();
  lastEmit: boolean | null = null;

  // Device instance
  device: Device;

  // Wifi Subscriptions
  subs = new Subscriptions();

  // Wi-Fi status
  wifiStatus: WiFiStatusResponse;
  wifiEnabled = false;
  wifiIconName = "";

  // Scanned/Available networks
  scannedNetworks: ScannedNetwork[];
  scannedNetworksOptions: Option[] = [];
  joinOtherNetworkOption: Option = {
    text: "",
    value: joinOtherNetworkValue,
  };

  // Network change
  selectedNetwork: ScannedNetwork;
  selectedNetworkId: string;
  selectNetworkPlaceholder: string;
  previouslyConnectedNetworkId: string;

  joinNetworkRequest: WiFiConnectParams;
  joiningNetwork = false;

  // Autoconnect
  autoconnect = false;

  // Known networks
  knownNetworks: string[] = [];

  // Modals
  showAdvancedSettings = false;
  showJoinNetworkModal = false;
  showKnownNetworksModal = false;
  otherNetwork = false;

  dhcpOptions = [
    { value: IPSettingsType.DHCP, text: "DHCP" },
    { value: IPSettingsType.Static, text: "Static" },
  ];

  securityOptions = [
    { value: "", text: "None" },
    { value: EncryptionType.WEP, text: "WEP" },
    { value: EncryptionType.PSK, text: "WPA/WPA2/FT PSK" },
    { value: EncryptionType.EAP, text: "802.1 x EAP" },
  ];
  selectedSecurityType = "";

  eapMethodOptions = [
    { value: EAPmethod.PEAP, text: EAPmethod.PEAP },
    { value: EAPmethod.TLS, text: EAPmethod.TLS },
    { value: EAPmethod.TTLS, text: EAPmethod.TTLS },
    { value: EAPmethod.PWD, text: EAPmethod.PWD },
  ];

  WifiConnectionStatus = WifiConnectionStatus;
  EncryptionType = EncryptionType;
  EAPmethod = EAPmethod;

  selectedEapMethod = "";

  phase2PEAPOptions = [
    { value: "None", text: "None" },
    { value: "MSCHAPV2", text: "MSCHAPV2" },
    { value: "GTC", text: "GTC" },
  ];

  phase2TTLSOptions = [
    { value: "None", text: "None" },
    { value: "PAP", text: "PAP" },
    { value: "MSCHAP", text: "MSCHAP" },
    { value: "MSCHAPV2", text: "MSCHAPV2" },
    { value: "GTC", text: "GTC" },
  ];

  caCertificateOptions: any[] = [];
  userCertificateOptions: any[] = [];

  constructor(
    private wifiService: WifiService,
    private toasts: Toasts,
    private translateService: TranslateService,
    private deviceManager: DeviceManagerService
  ) {}

  initJoinNetworkRequest() {
    this.joinNetworkRequest = {
      deviceId: this.device.id,
      wifi_action: "connect",
      ssid: "",
      securityType: "",
      eapMethod: "NONE",
      phase2: "NONE",
      caCert: "",
      clientCert: "",
      identity: "",
      anonymousIdentity: "",
      password: "",
      auto_connect: false,
      dhcp: IPSettingsType.DHCP,
      ip_address: "",
      gateway: "",
      subnet_mask: "",
      dns1: "",
      dns2: "",
    };
  }

  ngOnInit() {
    this.translateService
      .stream([
        "DeviceSettings.WIFI.PLACEHOLDER",
        "DeviceSettings.WIFI.JOIN_OTHER_NETWORK",
      ])
      .subscribe((translations) => {
        this.selectNetworkPlaceholder =
          translations["DeviceSettings.WIFI.PLACEHOLDER"];
        this.joinOtherNetworkOption.text = `${translations["DeviceSettings.WIFI.JOIN_OTHER_NETWORK"]}...`;
      });

    this.initJoinNetworkRequest();
    this.wifiService.getScannedNetworks(this.device?.id);

    // Subscribe to Scanned Networks
    this.subs.add(
      this.wifiService.scannedNetworks$.subscribe((response) => {
        this.scannedNetworks = response?.scannedNetworks;
        this.scannedNetworksOptions = response?.scannedNetworks.map(
          (networkInfo) => {
            const protectedNetwork = findEncryptionType(
              networkInfo.encryptionType
            )
              ? "lock"
              : "";
            const signalStrength = getWiFiIconName(networkInfo.signalStrength);

            let icons: { name: string; size: number }[] = [];

            if (protectedNetwork)
              icons.push({ name: protectedNetwork, size: 5 });
            if (signalStrength) icons.push({ name: signalStrength, size: 5 });

            const option: Option = {
              value: networkInfo.bssid,
              text: networkInfo.ssid,
            };

            if (icons.length) option.icons = icons;

            return option;
          }
        );
      })
    );

    // Subscribe to WiFi Status
    this.subs.add(
      this.wifiService
        .getWiFiStatus(this.device?.id)
        .subscribe((status: WiFiStatusResponse) => {
          this.wifiStatus = status;
          this.wifiEnabled = status.wifi_enable;

          // Wi-Fi Connection
          if (status.wifi_status === WifiConnectionStatus.Connected) {
            this.selectedNetworkId = status.wifi_connection_info.mBSSID;
            this.previouslyConnectedNetworkId =
              status.wifi_connection_info.mBSSID;
            // Emit the connected status for FTC process
            if (this.ftcLayout && !this.lastEmit) {
              this.wifiConfigured.emit(true);
              this.lastEmit = true;
            }
            this.closeJoinNetworkModal();
          } else {
            this.selectedNetworkId = "";
            this.previouslyConnectedNetworkId = "";
            if (this.ftcLayout && this.lastEmit) {
              // Emit the disconnected status for FTC process
              this.wifiConfigured.emit(false);
              this.lastEmit = false;
            }
          }

          this.wifiIconName = getWiFiIconName(
            +status.wifi_connection_info.signalStrength
          );
          this.autoconnect = status.wifi_auto_connect;
        })
    );

    // Subscribe to Known Networks
    this.subs.add(
      this.wifiService.knownNetworks$.subscribe((response) => {
        this.knownNetworks = response?.knownNetworks;
      })
    );
  }

  onNetworkDropdownClicked() {
    this.wifiService.getScannedNetworks(this.device?.id);
    this.getInstalledCertificates();
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }

  onWifiEnableChanged(value: boolean) {
    const action = value ? "enable" : "disable";
    const request: WiFiConnectParams = {
      deviceId: this.device.id,
      wifi_action: action,
    };
    this.wifiService.setWiFiParameters(request);

    this.wifiEnabled = value;
  }

  onWifiNetworkChanged(networkId: string) {
    if (networkId === joinOtherNetworkValue) {
      this.joinOtherNetwork();
      return;
    }

    this.selectedNetwork = this.scannedNetworks.find(
      (network) => network.bssid === networkId
    );

    this.selectedSecurityType = this.selectedNetwork
      ? findEncryptionType(this.selectedNetwork.encryptionType)
      : null;

    this.initJoinNetworkRequest();
    this.joinNetworkRequest = {
      ...this.joinNetworkRequest,
      ssid: this.selectedNetwork.ssid,
      securityType: this.selectedSecurityType,
      auto_connect: this.autoconnect,
    };

    this.otherNetwork = false;

    // If no encryption type, don't show the Join Network modal just join the network
    if (!this.selectedSecurityType) {
      this.joinNetwork();
    } else {
      this.showJoinNetworkModal = true;
    }
  }

  joinOtherNetwork() {
    this.selectedSecurityType = "";
    this.selectedEapMethod = "";

    this.initJoinNetworkRequest();
    this.joinNetworkRequest = {
      ...this.joinNetworkRequest,
      securityType: this.selectedSecurityType,
      auto_connect: this.autoconnect,
    };

    this.otherNetwork = true;
    this.showJoinNetworkModal = true;
  }

  onSecurityTypeChanged(value: string) {
    if (value === EncryptionType.EAP) {
      this.selectedEapMethod = EAPmethod.PEAP;
    } else {
      this.selectedEapMethod = "";
    }
    this.joinNetworkRequest.securityType = this.selectedSecurityType = value;
  }

  onEapMethodChanged(value: string) {
    this.joinNetworkRequest.eapMethod = this.selectedEapMethod = value;
  }

  onShowAdvanced(value: boolean) {
    this.showAdvancedSettings = value;
    this.onIPSettingsChanged(IPSettingsType.DHCP);
  }

  onIPSettingsChanged(value: any) {
    this.joinNetworkRequest = {
      ...this.joinNetworkRequest,
      dhcp: +value,
      ip_address: "",
      gateway: "",
      subnet_mask: "",
      dns1: "",
      dns2: "",
    };
  }

  joinNetwork() {
    this.joiningNetwork = true;

    this.wifiService.setWiFiParameters(this.joinNetworkRequest);

    // If status is disconnected after defined timeout (10sec) show error
    timer(NETWORK_JOIN_TIMEOUT).subscribe((timeout) => {
      if (this.wifiStatus?.wifi_status === WifiConnectionStatus.Disconnected) {
        this.joiningNetwork = false;
        this.toasts.push(WIFI_JOIN_ERROR_TOAST);
        if (this.ftcLayout) this.wifiConfigured.emit(false);
      }
    });
  }

  closeJoinNetworkModal() {
    this.initJoinNetworkRequest();
    this.selectedEapMethod = "";
    this.showJoinNetworkModal = false;
    this.joiningNetwork = false;
    this.selectedNetworkId = this.previouslyConnectedNetworkId
      ? this.previouslyConnectedNetworkId
      : "";
  }

  onWifiAutoconnectChanged(value: boolean) {
    this.autoconnect = value;
    const request: WiFiConnectParams = {
      deviceId: this.device.id,
      auto_connect: value,
      wifi_action: "connect",
    };
    this.wifiService.setWiFiParameters(request);
  }

  openKnownNetworksModal() {
    this.wifiService.getKnownNetworks(this.device?.id);
    this.showKnownNetworksModal = true;
  }

  removeKnownNetwork(networkName: string) {
    const request: WiFiConnectParams = {
      deviceId: this.device.id,
      wifi_action: "delete",
      ssid: networkName,
    };
    this.wifiService.setWiFiParameters(request);
    this.wifiService.getKnownNetworks(this.device?.id);
  }

  validate() {
    let validateStatic = true;
    if (!this.joinNetworkRequest.dhcp) {
      validateStatic =
        !!this.joinNetworkRequest.ip_address &&
        !!this.joinNetworkRequest.gateway &&
        !!this.joinNetworkRequest.subnet_mask &&
        !!this.joinNetworkRequest.dns1 &&
        !!this.joinNetworkRequest.dns2;
    }
    switch (this.selectedSecurityType) {
      case "":
        {
          if (this.joinNetworkRequest.ssid && validateStatic) return true;
        }
        break;
      case EncryptionType.WEP:
      case EncryptionType.PSK:
      case EncryptionType.EAP: {
        if (
          this.joinNetworkRequest.ssid &&
          this.joinNetworkRequest.password &&
          validateStatic
        ) {
          return true;
        } else if (
          this.joinNetworkRequest.ssid &&
          validateStatic &&
          this.selectedEapMethod === EAPmethod.TLS
        ) {
          return true;
        }
      }
    }
    return false;
  }

  getInstalledCertificates() {
    this.caCertificateOptions = [{ value: "", text: "Do Not Validate" }];
    this.userCertificateOptions = [{ value: "", text: "Do Not Validate" }];
    this.deviceManager
      .getInstalledCertificates(this.device?.id)
      .then((certificatesResponse) => {
        for (let i = 0; i < certificatesResponse.certs?.length; i++) {
          var certificateData = {
            text: certificatesResponse.certs[i].issuer,
            value: "utopia_wifi",
          };
          if (certificatesResponse.certs[i].type === "wifiCaCert")
            this.caCertificateOptions.push({ ...certificateData });
          if (certificatesResponse.certs[i].type === "wifiClientCert")
            this.userCertificateOptions.push({ ...certificateData });
        }
      });
  }
}
