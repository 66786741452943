import { OzDevice } from "../services/device-manager.service";
import _ from "lodash";

export function nullOrUndefined(x: any) {
  return x === null || x === undefined;
}

export function sortOptionsAlphabetically(options: any[]) {
  options.sort((a, b) =>
    a.text.toLowerCase().localeCompare(b.text.toLowerCase())
  );
}

export function hexEqual(
  firstHex: string | number,
  secondHex: string | number
) {
  // If either of these hex are unknown, then they are not equal
  if (nullOrUndefined(firstHex) || nullOrUndefined(secondHex)) {
    return false;
  }

  try {
    // If firstHex is string, convert it to number
    if (typeof firstHex === "string") {
      firstHex = parseInt(firstHex, 16);
    }

    // If secondHex is string, convert it to number
    if (typeof secondHex === "string") {
      secondHex = parseInt(secondHex, 16);
    }

    // Compare two numbers
    return firstHex === secondHex;
  } catch (ex) {
    // In case of error, hex are not equal
    return false;
  }
}

export function toPerc(numerator: number, denominator: number): number {
  const perc = Math.floor((numerator / denominator) * 100);
  return perc > 100 ? 100 : perc < 0 ? 0 : perc;
}

/**
 * Merges `source` into `target` by skipping already existing properties in the `target`.
 *
 * @returns `target`
 */
export function mergeObjectsWithoutOverwrite<T, K>(target: T, source: K): T {
  const missingKeys = Object.keys(source).filter(
    (sourceKey: string) => !Object.keys(target).includes(sourceKey)
  );

  missingKeys.forEach((sourceKey: string) => {
    target[sourceKey] = source[sourceKey];
  });

  return target;
}

/**
 * Sort a device list and separates devices into two groups:
 *  1. Group od devices that are usually displayed on the UI (e.g. device list and sys tray)
 *  2. Group of parent devices (usually dongles, which are available on the UI under "USB Adapter" tab)
 */
export function sortDevices(
  ozDevices: OzDevice[]
): {
  devices: OzDevice[];
  parentDevices: OzDevice[];
} {
  ozDevices.sort((a, b) => {
    if (a.isConnected && a.isPrimary) {
      return -1;
    }
    if (b.isConnected && b.isPrimary) {
      return 1;
    }
    if (a.isConnected && !b.isConnected) {
      return -1;
    }
    if (b.isConnected && !a.isConnected) {
      return 1;
    }
    return a.name < b.name ? -1 : 1;
  });
  // List of parent devices ids
  const parentDeviceIds: string[] = [];
  // Find parent devices
  ozDevices.forEach(({ parentDeviceId }) => {
    if (parentDeviceId?.length) {
      parentDeviceIds.push(parentDeviceId);
    }
  });
  // List of parent devices unqiueids
  const parentDeviceUniqueIds: string[] = [];
  ozDevices.forEach(({ id, uniqueId }) => {
    if (parentDeviceIds.includes(id)) {
      parentDeviceUniqueIds.push(uniqueId);
    }
  });
  // List of devices which do not have parent device
  const devices = ozDevices.filter(({ uniqueId }) => {
    return !parentDeviceUniqueIds.includes(uniqueId);
  });
  // list of parent devices
  const parentDevices = ozDevices.filter(({ uniqueId }) => {
    return parentDeviceUniqueIds.includes(uniqueId);
  });
  return { devices, parentDevices };
}

//
export function addPeripheralSuffix(id: string) {
  return `${id}_peripheral`;
}

export function removePeripheralSuffix(id: string) {
  return id.replace("_peripheral", "");
}
