import { Observable } from "rxjs";

/**
 * It provides interface for retrieving and changing device settings.
 */
export abstract class IDeviceSettingsManager {
  /**
   * Receives a list of device settings for a specific device.
   *
   * @param deviceId Target device.
   */
  abstract getDeviceSettings(deviceId: string): Observable<DeviceSetting[]>;

  /**
   * Receives a device setting for a specific device.
   *
   * @param deviceId The target device.
   * @param deviceSettingId The target device setting. (Global ID of a device setting.)
   */
  abstract getDeviceSetting(
    deviceId: string,
    deviceSettingId: string
  ): Observable<DeviceSetting>;

  /**
   * Changes a value of a device setting, for a specific device.
   *
   * @param deviceId The Target device.
   * @param deviceSetting New device setting value. Expected to contain device setting id and value.
   */
  abstract setDeviceSetting(
    deviceId: string,
    deviceSetting: Partial<DeviceSetting>
  ): void;

  /**
   * Changes values of a set of device settings, for a specific device.
   *
   * @param deviceId The Target device.
   * @param deviceSetting New values of device settings. Each device setting is expected to contain device setting id and value.
   */
  abstract setDeviceSettings(
    deviceId: string,
    deviceSettings: Partial<DeviceSetting>[]
  ): void;

  /**
   * Changes values of a set of device AERS reporting settings.
   *
   * @param deviceId The target device.
   * @param aersSettings New values for AERS settings.
   */
  abstract setAERSSettings(
    deviceId: string,
    aersSettings: Partial<AERSSetting>[]
  ): void;
}

export interface DeviceSetting {
  /**
   * Global id of the setting.
   */
  id: string;

  /**
   * Possible values for the setting.
   */
  options: any[];

  /**
   * Value of the setting.
   */
  value: any;

  /**
   * Possible status of a device setting.
   */
  status: DeviceSettingStatus;

  /**
   * Indicates if setting supports auto feature.
   */
  autoEnabledSupported?: boolean;

  /**
   * true if auto feature is enabled.
   */
  autoEnabled?: boolean;
}

export declare type AERSSettingName =
  | "LinkQualityReporting"
  | "LinkQualityPeriod"
  | "TWAReporting"
  | "AcousticIncidentReporting"
  | "ConversationDynamicsReporting"
  | "AALReportingThreshold"
  | "TWAReportingPeriod"
  | "ConversationDynamicsPeriod";
export interface AERSSetting {
  /**
   * Name of AERS reporting setting.
   */
  name: AERSSettingName;
  /**
   * Value of AERS reporting setting.
   */
  value: any;
  /**
   * Secondary value for AALReportingThreshold time.
   */
  value2?: any;
}

export declare type DeviceSettingStatus =
  | "OK" // Device setting was successfully written or read from the device
  | "NoDevice" // Trying to set a device setting but the target device is not connected
  | "DeviceSettingNotFound" // Trying to set a device setting value but the setting is not supported by device
  | "DeviceSettingValueInvalid" // Trying to set a device setting value but value is invalid
  | "WriteError" // Failed to write to device
  | "ReadError" // Failed to read from device
  | "WriteReadUnmatched" // Managed to write device setting value but subsequent read operation returned different value from the one that had been written, meaning that operation of setting a value has failed
  | "Unknown" // Unknown error occurred
  | "Setting"; // setDeviceSetting is called
