import { Injectable } from "@angular/core";
import { RESTDeviceError } from "@poly/hub-native";
import { BehaviorSubject, Observable, timer, zip } from "rxjs";
import { map } from "rxjs/operators";
import { DeviceManagerService } from "./device-manager.service";

/**
 * The intention of this service is to keep the state of the downloading of device logs.
 *
 * Scenario:
 *  We don't maintain the state in the "Device Info and Logs" component because user may
 *  start downloading process which might last more than a minute (which is a case for P15),
 *  and while downloading is in progress user may leave the "Device Info and Logs" page,
 *  and return to the page in a short period of time while progress is still going on. If
 *  we had managed the state in a component, the state would have been destroyed once user
 *  has left the page, and when user returns to "Device Info and Logs" page the progress
 *  information would have been lost. At that point we wouldn't know if we need to indicate
 *  progress (on the UI) or not.
 */

export interface SaveLogsData {
  state?: "Progress" | "Completed"; // undefined state means that downloading logs has never started for a device
  status?: RESTDeviceError; // undefined status means that there is still no response from native (i.e. operation is still in progress)
}

@Injectable({
  providedIn: "root",
})
export class SaveLogsService {
  private inProgress: BehaviorSubject<{
    [deviceId: string]: SaveLogsData;
  }> = new BehaviorSubject({});

  constructor(private deviceManager: DeviceManagerService) {}

  start(deviceId: string, filePath: string|FileSystemWritableFileStream): void {
    // Create entry for downloading logs (for provided device)
    const all = this.inProgress.getValue();
    all[deviceId] = { state: "Progress" };
    // Refresh devices list which has download logs progress
    this.inProgress.next(all);

    // Start actual logs downloading
    //  TODO: The intention of the "zip" is to wait at least for
    //  1.2 seconds bewteen changing a state of the download button
    //  from "Downloading" to "Download" in order to avoid flickering
    //  and bad UX. There has to be a time-based operator that can
    //  replace the zip in this case.
    zip(this.deviceManager.saveLogs(deviceId, filePath), timer(1200)).subscribe(
      ([saveLogsResponse]) => {
        // Update device logs downloading entry to completed state
        const all = this.inProgress.getValue();
        all[deviceId].state = "Completed";
        all[deviceId].status = saveLogsResponse.status;
        // Refresh devices list with latest (updated) data
        this.inProgress.next(all);
        // Remove entry for the device as downloading logs has completed
        delete all[deviceId];
        this.inProgress.next(all);
      }
    );
  }

  progress(deviceId: string): Observable<SaveLogsData> {
    return this.inProgress.pipe(
      map((devices) => devices[deviceId]), // select save logs entry for a requested device
      map((entry) => (entry ? entry : {})) // if entry does not exist, pass on an empty object to indicate idle state
    );
  }
}
