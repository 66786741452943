import { AuthService } from "../services/auth.service";
import { from, Observable, of } from "rxjs";
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
} from "@angular/common/http";
import { Injectable } from "@angular/core";
import { take, catchError, switchMap } from "rxjs/operators";
import { ILoggingService } from "../services/logging.service";

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  constructor(
    private authService: AuthService,
    private logger: ILoggingService
  ) {}
  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    // local files (such as ./assets/i18n/en.json) and non-secure transmissions
    const isLocalOrInsecure = !req.url.startsWith("https");

    // images rarely require authentication, can adapt to a fringe case later if necessary
    const isImage =
      req.url.endsWith(".png") ||
      req.url.endsWith(".jpg") ||
      req.url.endsWith(".jpeg") ||
      req.url.endsWith(".gif");

    // prevent certain requests from having an auth token (Bearer request header)
    if (isLocalOrInsecure || isImage) {
      return next.handle(req);
    }

    return this.modifyAndSendRequest(req, next).pipe(
      catchError((err) => {
        // If we get an error in the request
        // then refresh the auth tokens and try again
        this.logger.error(err);
        if (
          err?.error?.errors[0].extensions?.code == "GRAPHQL_VALIDATION_FAILED"
        ) {
          return of(null);
        }
        return this.modifyAndSendRequest(req, next, true);
      })
    );
  }

  modifyAndSendRequest(
    req: HttpRequest<any>,
    next: HttpHandler,
    refreshTokens = false
  ): Observable<HttpEvent<any>> {
    const readyForNextStep = refreshTokens
      ? from(this.authService.refreshTokens())
      : of(null);

    return readyForNextStep.pipe(
      // Take subscribes and unsubscribes after the first value.
      // Without this we don't kick off the observable. When this was missing it caused really weird effects.
      take(1),
      switchMap(() => {
        const token = this.authService.accessTokenValue;
        if (!token) {
          return next.handle(req);
        }

        return next.handle(
          req.clone({ setHeaders: { Authorization: `Bearer ${token}` } })
        );
      })
    );
  }
}
