import { AsyncPipe } from "@angular/common";
import { BehaviorSubject } from "rxjs";
import { TranslateService } from "@ngx-translate/core";
import {
  ChangeDetectorRef,
  OnDestroy,
  Pipe,
  PipeTransform,
} from "@angular/core";
import { cloneDeep as _cloneDeep, get as _get, set as _set } from "lodash";
import { LodashAccessor } from "../../services/utility.service";

@Pipe({
  name: "translateCollection",
  pure: false,
})
export class TranslateCollectionPipe implements PipeTransform, OnDestroy {
  private asyncPipe: AsyncPipe;
  sub$: BehaviorSubject<Array<any>> = new BehaviorSubject([]);

  constructor(
    private translateService: TranslateService,
    private cdr: ChangeDetectorRef
  ) {
    this.asyncPipe = new AsyncPipe(this.cdr);
  }

  /**
   * Take any collection (an array of objects), find translation keys based on accessor,
   * and translate.
   *
   * As of 1/20/21, ngx-translate cannot stream an array of keys with an array of interpolated
   * parameters.  This means translateCollection cannot simply process multiple parameters and
   * multiple strings via the stream method where the parameters are the same.
   * @help https://github.com/ngx-translate/core/issues/123
   *
   * @param collection
   * @param accessor - for example 'value', 'deep.value' or even ['deep', 0, 'value', 5]
   * @param paramsAccessor - object with translation parameters
   */
  transform(
    collection: Array<any>,
    accessor: LodashAccessor,
    paramsAccessor?: LodashAccessor
    // ): Observable<any> | null | undefined {
  ): any {
    const keys = collection.map((item) => {
      return _get(item, accessor);
    });

    let params = {};

    if (paramsAccessor) {
      // ngx-translate cannot currently parse an array with interpolateParams
      // with different values for each
      // @help https://github.com/ngx-translate/core/issues/123#issuecomment-763709546
      keys.forEach((key, i) => {
        const params = paramsAccessor
          ? _get(collection[i], paramsAccessor, undefined)
          : {};

        this.translateService.stream(key, params).subscribe((translation) => {
          let translatedCollection;
          if (this.sub$.value.length) {
            translatedCollection = _cloneDeep(this.sub$.value);
          } else {
            translatedCollection = _cloneDeep(collection);
          }

          _set(translatedCollection[i], accessor, translation);

          this.sub$.next(translatedCollection);
        });
      });
    } else {
      // can process all streams at once
      this.translateService.stream(keys, params).subscribe((translations) => {
        const translatedCollection = _cloneDeep(collection);
        translatedCollection.forEach((item) => {
          // example key, "GENERAL.HOME"
          const key = _get(item, accessor);

          if (key && key in translations) {
            // for example, set "Home" as item.value
            _set(item, accessor, translations[key]);
          }
        });

        this.sub$.next(translatedCollection);
      });
    }

    return this.asyncPipe.transform(this.sub$);
  }

  ngOnDestroy() {
    this.sub$?.unsubscribe();
    this.asyncPipe.ngOnDestroy();
  }
}
