import { Observable, BehaviorSubject } from "rxjs";
import { Injectable } from "@angular/core";
import {
  WiFiStatusResponse,
  ScannedNetworksResponse,
  WiFiConnectParams,
  KnownNetworksResponse,
} from "@poly/hub-native";
import { DeviceManagerService } from "./device-manager.service";

@Injectable({
  providedIn: "root",
})
export class WifiService {
  private _scannedNetworks$ = new BehaviorSubject<ScannedNetworksResponse>(
    null
  );
  private _knownNetworks$ = new BehaviorSubject<KnownNetworksResponse>(null);

  constructor(private deviceManager: DeviceManagerService) {}

  get scannedNetworks$() {
    return this._scannedNetworks$.asObservable();
  }

  get knownNetworks$() {
    return this._knownNetworks$.asObservable();
  }

  getWiFiStatus(deviceId: string): Observable<WiFiStatusResponse> {
    return this.deviceManager.getWiFiStatus(deviceId);
  }

  getScannedNetworks(deviceId: string): void {
    this.deviceManager.getScannedNetworks(deviceId).then((scannedNetworks) => {
      this._scannedNetworks$.next(scannedNetworks);
    });
  }

  getKnownNetworks(deviceId: string): void {
    this.deviceManager.getKnownNetworks(deviceId).then((knownNetworks) => {
      this._knownNetworks$.next(knownNetworks);
    });
  }

  setWiFiParameters(request: WiFiConnectParams): void {
    this.deviceManager.setWiFiParameters(request);
  }
}
