import {IpcChannelInterface, IPCEvent} from "./IpcChannelInterface";
import { SpeedTest } from "./SpeedTest";
import {throttle} from "lodash";
import {NotificationEvent, Notifications} from "./Notifications";
import {Injectable} from "@angular/core";
import {ILoggingService} from "../app/services/logging.service";

/**
 * The MessagesChannel class is a generic message channel for use
 * when a more specialized channel is not required.  It is intended
 * to reduce developer complexity for IPC communications.
 *
 * Parameters sent are namespace (such as "speedtest"), action (such as "run"),
 * and data (such as { configuration: 18 }).
 *
 * When IpcService is injected in an Angular controller, this can be called
 * like this:
 *  this.ipcService.send("messages", {
 *    namespace: "speedtest",
 *    action: "run",
 *    data: {
 *      sampleValue: 40583
 *    },
 *  });
 *
 * Note responses should have namespace as Messages is generic, and to ensure
 * Angular developer flexibility:
 * event.sender.send(request.responseChannel, { namespace, data });
 */
@Injectable({
  providedIn: "root",
})
export class MessagesChannel implements IpcChannelInterface {

  constructor(
    private notifications: Notifications,
    private logger: ILoggingService
  ) {}

  getName(): string {
    return "messages";
  }

  handle(event: IPCEvent, request: any) {

    if (!request.responseChannel) {
      request.responseChannel = `${this.getName()}_response`;
    }

    const { namespace, action, data } = request.params;

    // Adding a new namespace? Make sure to update typing in ipc.service.ts
    switch (namespace) {
      case "speedtest":
        // currently, the only speedtest action is "run", so do not check action
        // prevent updates from being sent more than n times per second
        const throttle_ms = data?.throttle_ms || 200;
        const parentThis = this;  // Needed in function below for logging

        const sendUpdateThrottled = throttle(
          // intentionally not a fat arrow function due to loss of scope
          function (channel, args) {
            // put in try/catch; have had one case where closing app on Mac during a speedtest
            // led to a JS error due to event.sender object being destroyed
            try {
              event.sender.send(channel, args);
            } catch (err) {
              parentThis.logger.error(
                "Error relaying speedtest result back to Angular client",
                err
              );
            }
          },
          throttle_ms,
          {
            leading: true,
            trailing: true,
          }
        );
        new SpeedTest(this.logger).getResults$().subscribe((update) => {
          const completed = update?.completed;

          sendUpdateThrottled(request.responseChannel, {
            // completed comes up a level so that it can close out the observable
            completed,
            namespace,
            update,
          });
        });

        break;
      case "notification":
        if ("listen" === action) {
          this.notifications
            .getEvents$()
            .subscribe((notificationEvent: NotificationEvent) => {
              event.sender.send(request.responseChannel, notificationEvent);
            });

          return;
        }

        this.notifications.handle(data);

        break;
      default:
        console.log(
          "Unsupported MessagesChannel message received:",
          request.params
        );
    }
  }
}
