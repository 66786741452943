import { Component, EventEmitter, Input, Output } from "@angular/core";
import { NetworkProvisioningParams } from "@poly/hub-native";
import { TranslateService } from "@ngx-translate/core";

export type ProvisioningForm = Partial<
  Omit<NetworkProvisioningParams, "deviceId" | "interval">
>;

const AUTO = "Auto";
const MANUAL = "Manual";
const OFF = "Disable";

const HTTPS = "https";
const CLOUD = "cloud";
const FTPS = "ftps";

@Component({
  selector: "oz-provisioning-form",
  templateUrl: "./provisioning-form.component.pug",
})
export class ProvisioningFormComponent {
  // In Device Setup wizard, this should be "false" (i.e. "expanded" mode),
  // In Admin settings, this should be "true"
  @Input()
  collapse = true;

  @Input()
  set config(value: ProvisioningForm) {
    this._config = Object.assign({}, value) as ProvisioningForm;
  }

  _config: ProvisioningForm = {
    provisioningMode: OFF,
    serverType: HTTPS,
    serverAddress: "",
    username: "",
    password: "",
  };

  disabledManualConfig = true;

  @Output()
  configChanged = new EventEmitter<ProvisioningForm>();

  provisioningModeOptions = [];
  serverTypeOptions = [];

  constructor(private translateService: TranslateService) {
    this.translateService
      .get([
        "DeviceSettings.NETWORK_PROVISIONING.PROVISIONING_MODE_OPTION_AUTO",
        "DeviceSettings.NETWORK_PROVISIONING.PROVISIONING_MODE_OPTION_MANUAL",
        "DeviceSettings.NETWORK_PROVISIONING.PROVISIONING_MODE_OPTION_OFF",
        "DeviceSettings.NETWORK_PROVISIONING.SERVER_TYPE_OPTION_HTTPS",
        "DeviceSettings.NETWORK_PROVISIONING.SERVER_TYPE_OPTION_CLOUD",
        "DeviceSettings.NETWORK_PROVISIONING.SERVER_TYPE_OPTION_FTPS",
      ])
      .subscribe((translations) => {
        this.provisioningModeOptions = [
          {
            value: AUTO,
            text:
              translations[
                "DeviceSettings.NETWORK_PROVISIONING.PROVISIONING_MODE_OPTION_AUTO"
              ],
          },
          {
            value: MANUAL,
            text:
              translations[
                "DeviceSettings.NETWORK_PROVISIONING.PROVISIONING_MODE_OPTION_MANUAL"
              ],
          },
          {
            value: OFF,
            text:
              translations[
                "DeviceSettings.NETWORK_PROVISIONING.PROVISIONING_MODE_OPTION_OFF"
              ],
          },
        ];

        this.serverTypeOptions = [
          {
            value: HTTPS,
            text:
              translations[
                "DeviceSettings.NETWORK_PROVISIONING.SERVER_TYPE_OPTION_HTTPS"
              ],
          },
          {
            value: CLOUD,
            text:
              translations[
                "DeviceSettings.NETWORK_PROVISIONING.SERVER_TYPE_OPTION_CLOUD"
              ],
          },
          {
            value: FTPS,
            text:
              translations[
                "DeviceSettings.NETWORK_PROVISIONING.SERVER_TYPE_OPTION_FTPS"
              ],
          },
        ];
      });
  }

  onProvisioningModeChanged() {
    if (this._config && this._config.provisioningMode !== MANUAL) {
      this._config.serverType = "";
      this._config.serverAddress = "";
      this._config.username = "";
      this._config.password = "";
    }
    this.disabledManualConfig = this._config?.provisioningMode !== MANUAL;
    this.configChanged.emit(this._config);
  }
}
