import { Component, OnInit, OnDestroy } from "@angular/core";
import { DetailNavService } from "../services/detail-nav.service";
import { DeviceManagerService } from "../services/device-manager.service";
import { Subscription, of, combineLatest } from "rxjs";
import { filter, switchMap } from "rxjs/operators";
import { ActivatedRoute, ParamMap } from "@angular/router";
import { UtilityService } from "../services/utility.service";
import { Dfu } from "../services/dfu.service";
import { BATTERY } from "../utils/constants";

@Component({
  selector: "oz-device-overview-bluetooth-remote",
  templateUrl: "./device-overview-bluetooth-remote.component.pug",
})
export class DeviceOverviewBluetoothRemoteComponent
  implements OnInit, OnDestroy {
  remote_name = "Bluetooth Remote";

  deviceSub: Subscription;

  public releaseNotes: string;
  public firmwareStr: string = "";
  public deviceName: string = "";
  public batteryIcon: string;
  public BATTERY = BATTERY;
  public batteryLevelPercent: string;
  public batteryLevel: number;

  constructor(
    private detailNav: DetailNavService,
    private route: ActivatedRoute,
    public deviceManager: DeviceManagerService,
    private dfu: Dfu
  ) {}

  ngOnInit(): void {
    this.detailNav.configure({
      deviceHeading: this.remote_name,
    });

    this.deviceSub = this.route.parent.paramMap
      .pipe(
        switchMap((paramMap: ParamMap) => {
          return this.deviceManager.getDevice(paramMap.get("id"));
        }),
        filter((d) => !!d),
        switchMap((device) => {
          return combineLatest([of(device), this.dfu.availableUpdate(device)]);
        })
      )
      .subscribe(([device, repoFirmware]) => {
        this.releaseNotes = repoFirmware?.rules?.releaseNotes;
        this.firmwareStr = device.videoDeviceStatus?.remoteVersionFW;
        this.deviceName = device?.videoDeviceStatus?.remoteName;
        this.batteryLevel = parseInt(
          device?.videoDeviceStatus?.remoteBatteryStatus
        );
        this.batteryLevelPercent = `${this.batteryLevel}%`;
        this.batteryIcon = this.getBatteryIcon(this.batteryLevel);
      });
  }

  public isNumber(value: any): boolean {
    return !isNaN(value);
  }

  public getBatteryIcon(batteryLevel: number): string {
    if (batteryLevel < 15) {
      return `battery_critical`;
    }
    if (batteryLevel < 25) {
      return `battery_low`;
    }
    if (batteryLevel < 50) {
      return `battery_medium`;
    }
    if (batteryLevel < 75) {
      return `battery_high`;
    }
    return `battery_full`;
  }

  handleOpenBrowser() {
    UtilityService.openExternalBrowser(this.releaseNotes);
  }

  ngOnDestroy(): void {
    this.deviceSub?.unsubscribe();
  }
}
