import { Component, OnInit, Input } from "@angular/core";
import {
  ActivatedRoute,
  NavigationEnd,
  Router,
  RouterOutlet,
} from "@angular/router";
import {
  routeAnimation,
  topNavAnimation,
} from "../animations/router.animations";
import { filter, map, startWith, switchMap } from "rxjs/operators";
import { Toasts } from "../shared/components/toast/toasts.service";
import { UtilityService } from "../services/utility.service";
import { FEEDBACK_FORM_URL } from "../utils/constants";

@Component({
  selector: "app-layout",
  templateUrl: "./app-layout.component.pug",
  animations: [routeAnimation, topNavAnimation],
})
export class AppLayoutComponent implements OnInit {
  @Input("className") className = "";

  public hasToasts: boolean;
  public background: string;
  public deviceDetails: boolean;
  public classes = [];

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private toasts: Toasts
  ) {
    this.hasToasts = !!this.toasts.all.length;
  }

  ngOnInit(): void {
    this.router.events
      .pipe(
        filter((event) => {
          return event instanceof NavigationEnd;
        }),
        startWith(this.activatedRoute),
        map(() => this.activatedRoute),
        map((route) => {
          this.classes = [];
          while (route.firstChild) {
            this.parseComponentName(route);
            route = route.firstChild;
          }

          this.parseComponentName(route);

          return route;
        }),
        filter((route) => route.outlet === "primary"),
        switchMap((route) => route.data)
      )
      .subscribe((event) => {
        this.background = event?.background;
      });
  }

  /**
   * The css class is pulled from the route data object defined in
   * app-routing.module
   *
   * The classes "stack" for nested routes.
   * eg. "device-details" and "device-info-and-logs" classes would
   * both be applied to the main-content container.
   */
  parseComponentName(route) {
    const cssClass = route.routeConfig?.data?.cssClass;
    if (cssClass) {
      this.classes.push(cssClass);
    }
  }

  prepareRouteAnimation(outlet: RouterOutlet) {
    return (
      outlet && outlet.activatedRouteData && outlet.activatedRouteData.animation
    );
  }

  openFeedbackForm() {
    UtilityService.openExternalBrowser(FEEDBACK_FORM_URL);
  }

  getPolyIcon(): string {
    return "src/assets/icons/favicon.png";
  }
}
