import { Component, HostListener } from "@angular/core";
// import * as Highcharts from "highcharts";
import { LocalNetworkValue } from "../../SpeedTest/LocalNetworkTest";
import {
  NetworkSpeedTestService,
  TestResults,
} from "../services/network-speed-test.service";
import { DeviceManagerService } from "../services/device-manager.service";
import { TenantService } from "../services/tenant.service";
import { Device } from "@poly/hub-native";
import { SpeedMetric } from "../shared/components/speed-indicator/speed-indicator.component";
import { combineLatest, fromEvent, merge, Observable } from "rxjs";
import { distinctUntilChanged, map } from "rxjs/operators";
import { TranslateService } from "@ngx-translate/core";
import { Repository } from "../services/repository/repository.service";
import { RepositoryDevicesUsage } from "../services/repository/model";

interface TestConfig {
  type: "upload" | "download" | "ping";
  simultaneous_streams: number;
  runtime_ms: number;
  ping_timeout_ms: number;
  port: number;
  protocol: "tcp" | "udp";
  address: string;
  sizes_kib: Array<number>;
  sizes_udp_kib: Array<number>;
}

export interface SpeedTestValue {
  value: number;
  unit?: string;
  metric: "speed" | "jitter" | "ping" | "loss" | "min" | "max";
}

export interface NetworkTestResult {
  completed: boolean;
  testConfig: TestConfig;
  values: Array<SpeedTestValue | LocalNetworkValue>;
}

export interface NetworkTestResults {
  currentTestIndex: number | undefined;
  completed: boolean;
  results: Array<NetworkTestResult>;
}

interface ChartTranslations {
  [index: string]: string;
}

@Component({
  selector: "device-usage-and-network",
  templateUrl: "./device-usage-and-network.component.pug",
})
export class DeviceUsageAndNetworkComponent {
  private devices: Device[];
  private deviceUniqueIds: string[];
  public showCallUsageChart = true;
  public loadingChartData = true;
  private translateStream$: Observable<ChartTranslations>;

  // TODO: UI: Create i18n "map" for this word
  private labels = {
    upload: "Upload",
    download: "Download",
  };
  // private readonly UNKNOWN_TEST_TYPE = "unknown";

  // public Highcharts: typeof Highcharts = Highcharts;
  // public chartOptions: Highcharts.Options;

  public online;
  public testExpanded = false;

  public get testRunning(): boolean {
    return (
      this.testResults?.status?.started && !this.testResults?.status?.completed
    );
  }

  public trigger = 0;
  public testResults: TestResults = {
    ping: {
      ping: 0,
      run: false,
      complete: false,
      jitter: 0,
      loss: 0,
      group: "latency",
    },
    download: {
      speed: 0,
      run: false,
      complete: false,
      group: "transfer",
    },
    upload: {
      speed: 0,
      run: false,
      complete: false,
      group: "transfer",
    },
    status: {
      started: false,
      completed: false,
    },
  };

  public series: { [key: string]: Array<SpeedMetric> } = {
    transfer: [],
    latency: [],
  };

  public values: { [key: string]: Array<number> } = {
    transfer: [],
    latency: [],
  };

  private devMode = {
    metric: "",
    stack: [],
    on: false,
    upload: false,
    download: false,
  };

  constructor(
    private deviceManager: DeviceManagerService,
    private tenantService: TenantService,
    private repo: Repository,
    private networkSpeedTestService: NetworkSpeedTestService,
    private translateService: TranslateService
  ) {
    this.translateStream$ = this.translateService.stream([
      "DEVICE_USAGE.CHART.SERIES_NAME",
      "SCHEDULE_OPTIONS.MON",
      "SCHEDULE_OPTIONS.TUE",
      "SCHEDULE_OPTIONS.WED",
      "SCHEDULE_OPTIONS.THU",
      "SCHEDULE_OPTIONS.FRI",
      "SCHEDULE_OPTIONS.SAT",
      "SCHEDULE_OPTIONS.SUN",
    ]);

    this.deviceManager.getDevices().subscribe((devices) => {
      this.devices = devices;
      this.deviceUniqueIds = this.devices.map((device) => device.uniqueId);
    });

    this.tenantService.tenantId$
      .pipe(distinctUntilChanged())
      .subscribe((id: string) => {
        // if (id !== null) {
        //   this.getCallUsageWithChartData(id);
        // } else {
        //   this.showCallUsageChart = false;
        // }
      });

    this.online = navigator.onLine;
    merge(fromEvent(window, "online"), fromEvent(window, "offline")).subscribe(
      (event) => {
        this.online = navigator.onLine;
        if (!this.online) {
          this.testExpanded = false;
        }
      }
    );

    this.networkSpeedTestService.getTestResults$().subscribe((testResults) => {
      this.testResults = testResults;
      this.mapToIndicators();
    });
  }

  // getCallUsageWithChartData(tenantId: string) {
  //   const chartData$ = this.repo.getDevicesUsage(tenantId).pipe(
  //     map((repoDevicesUsage: RepositoryDevicesUsage) => {
  //       const edges = repoDevicesUsage?.edges || [];
  //
  //       // Extract week data series per node/device - filter only devices used in the app
  //       const devicesSeriesData = edges
  //         .filter(
  //           (edge) =>
  //             edge?.node?.device?.id &&
  //             this.deviceUniqueIds.includes(edge.node.device.id) &&
  //             edge?.node?.chartData?.series
  //         )
  //         .map((edge) => edge.node.chartData.series[0].data);
  //
  //       // Loop through devices data, aggregate values per day
  //       const seriesTotal = [];
  //
  //       devicesSeriesData.forEach((deviceSeries) => {
  //         deviceSeries.forEach((point, index) => {
  //           if (isNaN(seriesTotal[index]) && point.y !== null) {
  //             seriesTotal[index] = point.y;
  //           } else if (point.y !== null) {
  //             seriesTotal[index] += point.y;
  //           }
  //         });
  //       });
  //       return seriesTotal;
  //     })
  //   );
  //
  //   combineLatest([chartData$, this.translateStream$]).subscribe(
  //     ([seriesTotal, translations]) => {
  //       this.chartOptions = {
  //         credits: {
  //           enabled: false,
  //         },
  //         title: {
  //           text: "",
  //           align: "left",
  //           style: { fontWeight: "bold", fontSize: "14px" },
  //           margin: 18,
  //         },
  //         legend: {
  //           align: "left",
  //           x: 36,
  //         },
  //         xAxis: {
  //           categories: [
  //             translations["SCHEDULE_OPTIONS.SUN"],
  //             translations["SCHEDULE_OPTIONS.MON"],
  //             translations["SCHEDULE_OPTIONS.TUE"],
  //             translations["SCHEDULE_OPTIONS.WED"],
  //             translations["SCHEDULE_OPTIONS.THU"],
  //             translations["SCHEDULE_OPTIONS.FRI"],
  //             translations["SCHEDULE_OPTIONS.SAT"],
  //           ],
  //           min: 0.5,
  //           max: 5.5,
  //           startOnTick: false,
  //           endOnTick: false,
  //         },
  //         yAxis: {
  //           title: { text: null },
  //         },
  //         plotOptions: {
  //           area: {
  //             stacking: "normal",
  //           },
  //         },
  //         series: [
  //           {
  //             name: translations["DEVICE_USAGE.CHART.SERIES_NAME"],
  //             data: seriesTotal, // data example: [156, 200, 100, 300, 175, 375, 75]
  //             type: "area",
  //             color: "#0270e3",
  //             fillColor: "#dcebfb",
  //           },
  //         ],
  //       };
  //
  //       this.loadingChartData = false;
  //     }
  //   );
  // }

  /**
   * Listen for keystrokes to initiate dev "test" mode.
   * Enables moving the values on the speed test indicators manually
   * to test edge cases.
   *
   * Start test mode for "speed": type "tests" on your keyboard.
   * Start test mode for "latency": type "testl" on your keyboard.
   *
   * Once in a test mode, use the left / right arrows to move the value.
   * (if there are values, use up / down arrows to control other value)
   */
  @HostListener("window:keydown", ["$event"])
  keyDown(e: KeyboardEvent) {
    this.devMode.stack.push(e.key);
    this.devMode.stack = this.devMode.stack.slice(-10);

    if (["tests", "testl"].includes(this.devMode.stack.slice(-5).join(""))) {
      this.startDevMode(this.devMode.stack.slice(-1)[0]);
    }

    if (!this.devMode.on) {
      return;
    }

    const values = [...this.values[this.devMode.metric]];

    if ("ArrowLeft" === e.key) {
      values[0] = Math.max(0, this.values[this.devMode.metric][0] - 2);
    }

    if ("ArrowRight" === e.key) {
      values[0] = Math.max(0, this.values[this.devMode.metric][0] + 2);
    }

    if ("ArrowUp" === e.key) {
      values[1] = Math.max(0, this.values[this.devMode.metric][1] + 2);
    }

    if ("ArrowDown" === e.key) {
      values[1] = Math.max(0, this.values[this.devMode.metric][1] - 2);
    }

    this.values[this.devMode.metric] = values;
  }

  startDevMode(mode: "l" | "s") {
    const metric = "s" === mode ? "transfer" : "latency";

    if (metric !== this.devMode.metric) {
      this.devMode.on = true;
    } else {
      this.devMode.on = !this.devMode.on;
    }

    this.testExpanded = this.devMode.on;
    if (this.devMode.on) {
      const type = "transfer" === metric ? "download" : "ping";
      const label = "transfer" === metric ? "Download" : " ";
      const unit = "transfer" === metric ? "Mbps" : "ms";
      const value = "transfer" === metric ? 50 : 20;
      this.devMode.metric = metric;
      this.series[metric] = [];
      this.series[metric].push({
        type,
        value,
        unit,
        label,
        complete: false,
      });

      if (!this.values[metric].length) {
        this.values[metric] = [];
        this.values[metric].push(value);
      }

      if ("transfer" === metric) {
        this.series[metric].push({
          type: "upload",
          value: 50,
          unit,
          label: "Upload",
          complete: false,
        });

        if (this.values[metric].length < 2) {
          this.values[metric].push(30);
        }
      }
    }
  }

  handleTestResults(testResults: TestResults) {
    if (testResults) {
      this.testResults = testResults;
    }
    this.mapToIndicators();
  }

  mapToIndicators() {
    let results = [];
    Object.keys(this.testResults).forEach((key) => {
      const result = this.testResults[key];

      if (result.run) {
        results.push({ ...result, type: key });
      }
    });

    const transfer = results.filter((r) => "transfer" === r.group);
    const latency = results.filter((r) => "latency" === r.group);

    // ensure transfer series contains all items that it should...
    if (this.series.transfer.length !== transfer.length) {
      this.series.transfer = transfer.map((result) => {
        return {
          type: result.type,
          value: 0,
          label: this.labels[result.type],
          // TODO: get this dynamically...
          unit: "Mbps",
          complete: false,
        };
      });
    }

    // ensure transfer series contains all items that it should...
    if (this.series.latency.length !== latency.length) {
      this.series.latency = latency.map((result) => {
        return {
          type: result.type,
          value: 0,
          // TODO: Create i18n "map" for this word
          label: "",
          // TODO: get this dynamically...
          unit: "ms",
          complete: false,
        };
      });
    }

    const values = [];
    // Preserve original order by iterating existing series values
    this.series.transfer.forEach((src) => {
      values.push(Math.round(transfer.find((s) => s.type === src.type).speed));
    });

    this.values.transfer = values;

    const latencyValues = [];
    // Preserve original order by iterating existing series values
    this.series.latency.forEach((src) => {
      latencyValues.push(
        Math.round(latency.find((s) => s.type === src.type).ping)
      );
    });

    this.values.latency = latencyValues;
  }

  startNetworkTest() {
    this.networkSpeedTestService.startNetworkTest();

    this.devMode.on = false;
    this.series = { transfer: [], latency: [] };
    this.values = { transfer: [], latency: [] };

    this.testExpanded = true;
  }
}
