import { Component, OnDestroy, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { Device } from "@poly/hub-native";
import {
  DeviceManagerService,
  DeviceSetupByConfig,
} from "../services/device-manager.service";
import { Subscriptions } from "../utils/subscriptions";
import { switchMap } from "rxjs/operators";
import { Location } from "@angular/common";
import { OzStepStatus } from "../shared/components/stepper/step/step.component";

@Component({
  templateUrl: "./ftc-wizard-imported-config.component.pug",
})
export class FtcWizardImportedConfigComponent implements OnInit, OnDestroy {
  device: Device;

  OzStepStatus = OzStepStatus;
  showCloseModal = false;
  finished = false;
  private subs = new Subscriptions();

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private deviceManager: DeviceManagerService,
    private location: Location
  ) {}

  ngOnInit() {
    this.subs.add(
      this.route.paramMap
        .pipe(
          switchMap((paramMap) =>
            this.deviceManager.getDevice(paramMap.get("id"))
          )
        )
        .subscribe((device) => {
          if (!device.isConnected) {
            this.router.navigate(["../../"], { relativeTo: this.route });
          }
          this.device = device;
        })
    );
  }

  onBack() {
    // Navigate back
    this.location.back();
  }

  onFinish() {
    this.finished = true;

    this.deviceManager.setupDeviceByConfig(
      this.device.id,
      this.location.getState() as DeviceSetupByConfig
    );

    // Navigate to device
    setTimeout(() => {
      this.router.navigate(["detail", this.device?.uniqueId, "overview"]);
    }, 700);
  }

  onExitSetup() {
    this.location.back();
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }
}
