import { Injectable } from "@angular/core";
import { UtilityService } from "../utility.service";
import {
  DeviceInfoConnection,
  DeviceInfoStateEntity,
  DeviceInfoStateEventData,
  DeviceInfoStatePayload,
} from "./utils/device.message.json.subobjects";
import { DeviceMessagingUtils } from "./utils/device.messaging.utils";
import { AuthProfile } from "../auth.service";
import {
  BAD_DEVICE_IDS,
  DEVICE_TYPE,
  POLY_LENS_PWA,
} from "../../utils/constants";
import { getFirmwareVersion } from "../../shared/pipes/firmware-version.pipe";
import { removeManufacturerName } from "../../shared/pipes/device-name.pipe";
import { OzDevice } from "../device-manager.service";
import { OzDeviceWithRepositoryFirmware } from "../dfu.service";
import { cloneDeep as _cloneDeep } from "lodash";

@Injectable({
  providedIn: "root",
})
export class DeviceInfoStatePayloadBuilder {
  private lensAppId: string;
  public userInfoFlag: string;

  constructor(private deviceMessagingUtils: DeviceMessagingUtils) {}

  public translateToDeviceInfoStatePayload(
    allDevices: OzDeviceWithRepositoryFirmware[],
    lensAppId: string,
    isAuthenticated: boolean,
    userProfile: AuthProfile
  ): DeviceInfoStatePayload {
    allDevices = allDevices.filter(
      (d) =>
        d.uniqueId &&
        d.uniqueId.trim().length > 0 &&
        !BAD_DEVICE_IDS.includes(d.uniqueId.trim())
    );

    this.lensAppId = lensAppId;

    let deviceInfoStateEntitiesArray: DeviceInfoStateEntity[] = [];

    const earbuds = allDevices.find(
      (device) => device.deviceType === DEVICE_TYPE.EARBUDS
    );

    if (earbuds) {
      const dongle = allDevices.find(
        (device) => earbuds.parentDeviceId === device.id
      );

      // All devices except type 'earbuds' and it's dongle
      deviceInfoStateEntitiesArray = allDevices
        .filter((device) => device.id !== earbuds.id && device.id !== dongle.id)
        .map((device) =>
          this.generateDeviceInfoStateEntity(device, allDevices)
        );

      // If there is 'earbuds' device resolve it additionally as left and right
      deviceInfoStateEntitiesArray.push(
        ...this.generateEarbudsDeviceInfoStateEntity(earbuds)
      );

      // If dongle is connected resolved it
      if (dongle) {
        deviceInfoStateEntitiesArray.push(
          this.generateEarbudsDongleInfoStateEntity(dongle, earbuds)
        );
      }
    } else {
      deviceInfoStateEntitiesArray = allDevices.map((device) =>
        this.generateDeviceInfoStateEntity(device, allDevices)
      );
    }

    // Resolve App Entity
    deviceInfoStateEntitiesArray.push(
      this.getLensAppEntity(isAuthenticated, userProfile, allDevices)
    );

    let deviceInfoStateEventData: DeviceInfoStateEventData = {
      entities: deviceInfoStateEntitiesArray,
    };

    return this.generateFullDeviceInfoStatePayload(deviceInfoStateEventData);
  }

  private getLensAppEntity(
    isAuthenticated: boolean,
    userProfile: AuthProfile,
    allDevices: OzDevice[]
  ): DeviceInfoStateEntity {
    let connections: DeviceInfoConnection[] = allDevices.map((d) =>
      this.deviceMessagingUtils.generateDeviceConnection(
        this.lensAppId,
        d.uniqueId,
        d,
        this.deviceMessagingUtils.getViaConnections(
          d,
          d.parentDeviceId,
          allDevices
        ),
        this.deviceMessagingUtils.getEntityType(
          d.parentDeviceId,
          this.lensAppId
        )
      )
    );
    if (
      isAuthenticated &&
      (!this.userInfoFlag || (this.userInfoFlag && this.userInfoFlag === "0"))
    ) {
      connections.push(
        this.deviceMessagingUtils.generateLensAppConnection(
          this.lensAppId,
          isAuthenticated,
          userProfile
        )
      );
    }

    return {
      proxyAgentId: this.lensAppId,
      deviceType: "",
      deviceId: this.lensAppId,
      capabilities: [],
      productId: UtilityService.getBuildProductId(),
      productName: POLY_LENS_PWA,
      manufacturer: "Poly",
      vendorId: "",
      deviceSpecificInfo: null /* TODO null for now, but some thinks like build number, might be needed */,
      connections: connections,
    };
  }

  private generateDeviceInfoStateEntity(
    device: OzDeviceWithRepositoryFirmware,
    allDevices: OzDevice[]
  ): DeviceInfoStateEntity {
    let connectionsArray: DeviceInfoConnection[] = [];
    const parentId = this.deviceMessagingUtils.getParentUniqueId(
      device,
      allDevices,
      this.lensAppId
    );
    connectionsArray.push(
      this.deviceMessagingUtils.generateDeviceConnection(
        device.uniqueId,
        parentId,
        device,
        this.deviceMessagingUtils.getViaConnections(
          device,
          parentId,
          allDevices
        ),
        this.deviceMessagingUtils.getEntityType(parentId, this.lensAppId)
      )
    );
    let deviceInfoStateEntity: DeviceInfoStateEntity = {
      proxyAgentId: this.lensAppId,
      deviceType: device.deviceType || "",
      deviceId: device.uniqueId,
      capabilities: device.featureList,
      productId: this.deviceMessagingUtils.getHexString(device.pid),
      productName: removeManufacturerName(device.displayName),
      manufacturer: device.manufacturerName,
      vendorId: this.deviceMessagingUtils.getHexString(device.vid),
      deviceSpecificInfo: this.deviceMessagingUtils.generateDeviceSpecificInfo(
        device
      ),
      connections: connectionsArray,
    };

    if (device.isConnected) {
      deviceInfoStateEntity.isUpdateAvailable = !!device.repositoryFirmwareVersion;
      deviceInfoStateEntity.updateVersion = device.repositoryFirmwareVersion;
    }

    deviceInfoStateEntity.softwareVersion = getFirmwareVersion(device);

    return deviceInfoStateEntity;
  }

  private generateEarbudsDeviceInfoStateEntity(
    device: OzDeviceWithRepositoryFirmware
  ): DeviceInfoStateEntity[] {
    let peerDevices: DeviceInfoStateEntity[] = [];

    let deviceInfoStateEntity: DeviceInfoStateEntity = {
      proxyAgentId: this.lensAppId,
      deviceType: DEVICE_TYPE.EARBUDS,
      deviceId: device.uniqueId,
      capabilities: device.featureList,
      productId: this.deviceMessagingUtils.getHexString(device.pid),
      productName: removeManufacturerName(device.displayName),
      manufacturer: device.manufacturerName,
      vendorId: this.deviceMessagingUtils.getHexString(device.vid),
      deviceSpecificInfo: this.deviceMessagingUtils.generateDeviceSpecificInfo(
        device
      ),
      connections: [],
    };

    if (device.isConnected) {
      deviceInfoStateEntity.isUpdateAvailable = !!device.repositoryFirmwareVersion;
      deviceInfoStateEntity.updateVersion = device.repositoryFirmwareVersion;
    }

    deviceInfoStateEntity.softwareVersion = getFirmwareVersion(device);

    const leftEarbudId = device.leftEarbudStatus?.primary
      ? device.serialNumber?.base.toLowerCase()
      : device.peerEarbudInfo?.GenesGuid;
    const rightEarbudId = device.rightEarbudStatus?.primary
      ? device.serialNumber?.base.toLowerCase()
      : device.peerEarbudInfo?.GenesGuid;

    // Left Earbud is primary
    let leftEarbudInfoStateEntity: DeviceInfoStateEntity = _cloneDeep(
      deviceInfoStateEntity
    );
    leftEarbudInfoStateEntity.deviceId = leftEarbudId;
    leftEarbudInfoStateEntity.productName =
      removeManufacturerName(device.displayName) + " (Left Earbud)";
    leftEarbudInfoStateEntity.deviceSpecificInfo = this.deviceMessagingUtils.generateDeviceSpecificInfo(
      device,
      "left",
      leftEarbudId
    );
    (leftEarbudInfoStateEntity.connections = [
      {
        connected: device.connectedDevices?.includes("rightEarbud"),
        from: leftEarbudId,
        to: rightEarbudId, // Connected to Right Earbud
        via: [""], // TODO check
        targetEntityType: "device",
        type: "peer",
      },
      {
        connected: device.connectedDevices?.includes("chargeCase"),
        from: leftEarbudId,
        to: device.peerChargeCase?.GenesGuid, // Connected to Charge Case
        via: [""], // TODO
        targetEntityType: "device",
        type: "peer",
      },
    ]),
      peerDevices.push(leftEarbudInfoStateEntity);

    // Right Earbud is primary
    let rightEarbudInfoStateEntity: DeviceInfoStateEntity = _cloneDeep(
      deviceInfoStateEntity
    );
    rightEarbudInfoStateEntity.deviceId = rightEarbudId;
    rightEarbudInfoStateEntity.productName =
      removeManufacturerName(device.displayName) + " (Right Earbud)";
    rightEarbudInfoStateEntity.deviceSpecificInfo = this.deviceMessagingUtils.generateDeviceSpecificInfo(
      device,
      "right",
      rightEarbudId
    );
    (rightEarbudInfoStateEntity.connections = [
      {
        connected: device.connectedDevices?.includes("leftEarbud"),
        from: rightEarbudId,
        to: leftEarbudId, // Connected to Left Earbud
        via: [""], // TODO
        targetEntityType: "device",
        type: "peer",
      },
      {
        connected: device.connectedDevices?.includes("chargeCase"),
        from: rightEarbudId,
        to: device.peerChargeCase?.GenesGuid, // Connected to Charge Case
        via: [""], // TODO
        targetEntityType: "device",
        type: "peer",
      },
    ]),
      peerDevices.push(rightEarbudInfoStateEntity);

    return peerDevices;
  }

  private generateEarbudsDongleInfoStateEntity(
    device: OzDeviceWithRepositoryFirmware,
    earbuds: OzDeviceWithRepositoryFirmware
  ): DeviceInfoStateEntity {
    const dongleId = device?.serialNumber?.base.toLowerCase();
    const leftEarbudId = earbuds.leftEarbudStatus?.primary
      ? earbuds.serialNumber?.base.toLowerCase()
      : earbuds.peerEarbudInfo?.GenesGuid;
    const rightEarbudId = earbuds.rightEarbudStatus?.primary
      ? earbuds.serialNumber?.base.toLowerCase()
      : earbuds.peerEarbudInfo?.GenesGuid;

    let deviceInfoStateEntity: DeviceInfoStateEntity = {
      proxyAgentId: this.lensAppId,
      deviceType: "",
      deviceId: dongleId,
      capabilities: device.featureList,
      productId: this.deviceMessagingUtils.getHexString(device.pid),
      productName: removeManufacturerName(device.displayName),
      manufacturer: device.manufacturerName,
      vendorId: this.deviceMessagingUtils.getHexString(device.vid),
      deviceSpecificInfo: this.deviceMessagingUtils.generateDeviceSpecificInfo(
        device
      ),
      connections: [
        {
          connected: device.isConnected,
          from: dongleId,
          to: this.lensAppId, // Connected to Lens App
          via: [""], // TODO
          targetEntityType: "application",
          type: "proxy",
        },
        {
          connected: earbuds.connectedDevices?.includes("leftEarbud"),
          from: dongleId,
          to: leftEarbudId, // Connected to Left Earbud
          via: [""], // TODO
          targetEntityType: "device",
          type: "adapter",
        },
        {
          connected: earbuds.connectedDevices?.includes("rightEarbud"),
          from: dongleId, // TODO
          to: rightEarbudId, // Connected to Right Earbud
          via: [""], // TODO
          targetEntityType: "device",
          type: "adapter",
        },
      ],
    };

    if (device.isConnected) {
      deviceInfoStateEntity.isUpdateAvailable = !!device.repositoryFirmwareVersion;
      deviceInfoStateEntity.updateVersion = device.repositoryFirmwareVersion;
    }

    deviceInfoStateEntity.softwareVersion = getFirmwareVersion(device);

    return deviceInfoStateEntity;
  }

  private generateFullDeviceInfoStatePayload(
    eventData: DeviceInfoStateEventData
  ): DeviceInfoStatePayload {
    return {
      attr: "hubv3",
      version: "0.0.1",
      value: {
        eventTime: new Date(),
        eventType: "DeviceInfo.deviceInfoState",
        eventVersion: "0.0.1",
        eventData: eventData,
      },
    };
  }
}
