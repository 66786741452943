import {
  IDeviceManager,
  Device,
  BrickedDevice,
  SerialNumber,
  SaveDeviceLogsStatus,
  BuildCode,
  NonPolyDevice,
  KnownNetworksResponse,
  ScannedNetworksResponse,
  WiFiStatusResponse,
  WiFiConnectParams,
  RESTDeviceError,
  NetworkProvisioningParams,
  NetworkProvisioningInfo,
  DeviceNameResponse,
  BluetoothParams,
  BluetoothInfo,
  SetBluetoothResponse,
  GetGeneralSettingsResponse,
  SetGeneralSettingsResponse,
  GeneralSettings,
  AdminLoginRequest,
  AdminLoginResponse,
  AdminPasswordChangeRequest,
  AdminPasswordChangeResponse,
  AdminPasswordEnabledResponse,
  SimplePasswordResponse,
  SetSimplePasswordRequest,
  SetSimplePasswordResponse,
  CreateCertificateRequest,
  CreateCertificateResponse,
  GetCertificateInfoResponse,
  GetCertificateFileResponse,
  InstallCertificateRequest,
  InstallCertificateResponse,
  GetInstalledCertificatesResponse,
  DeleteCertificateRequest,
  DeleteCertificateResponse,
  CertificateDetailsRequest,
  CertificateDetailsResponse,
  ImportConfigurationRequest,
  ImportConfigurationResponse,
  ExportConfigurationResponse,
  SetServerCAValidationRequest,
  SetServerCAValidationResponse,
  StartAudioTestResponse,
  StopAudioTestRequest,
  StopAudioTestResponse,
  NeedOOBResponse,
  BluetoothPairingRequest,
  SetWifiClientCertPasswordRequest,
  SetWifiClientCertPasswordResponse,
  GetCrashFilesRequest,
  GetCrashFilesResponse,
  IsAppInstalledResponse,
  AgentAudioControl,
  FirmwareVersion,
  SetId,
  Battery,
  PeripheralInfo,
  ManufacturerInfo, WiFiConnectionInfo, WiFiStatus, WifiSsid,
} from "../api/device-manager.api";
import { DeviceSetting } from "../api/device-settings-manager.api";
import { Subject, Observable, BehaviorSubject } from "rxjs";
import { map } from "rxjs/operators";
import { Products } from "../common/products-json";
import {
  pick,
  DEEP_COPY,
} from "../common/utils/utils";
import { Injectable } from "@angular/core";
import { ILoggingService } from "../../app/services/logging.service";
import { VID, WhDevice, WhDeviceObserver } from "./wh-device";
import { hex4l } from "./wh-utils";


function REMOVE_ALL_UNDEFINED_DEVICE_OBJECTS() {
  return (devices: Device[]) => {
    return devices.filter((device) => device && device.id);
  };
}


interface HidDeviceInfo {
  device: HIDDevice,
  connected: boolean
  uniqueId?: string    // copy of Device.uniqueId
}


@Injectable({
  providedIn: "root"
})
export class WhDeviceManager extends IDeviceManager {
  setPrimaryDevice(deviceId: string): void {
    throw new Error("Method not implemented.");
  }

  mutePrimaryDevice(mute: boolean): void {
    throw new Error("Method not implemented.");
  }

  setAacSetting(aac: AgentAudioControl): void {
    throw new Error("Method not implemented.");
  }

  setDeviceName?(deviceId: string, deviceName: string): Promise<DeviceNameResponse> {
    throw new Error("Method not implemented.");
  }

  nonPolyDeviceAdded(): Observable<NonPolyDevice> {
    throw new Error("Method not implemented.");
  }

  nonPolyDeviceRemoved(): Observable<NonPolyDevice> {
    throw new Error("Method not implemented.");
  }

  saveDeviceLogs(deviceId: string, writableFile: string | FileSystemWritableFileStream): Promise<SaveDeviceLogsStatus> {
    return new Promise<SaveDeviceLogsStatus>(resolve => {
      const ret: SaveDeviceLogsStatus = { status: "OK", deviceId, logFilePath: "" }
      const whDev = this.whDevices.find(dev => deviceId === dev.deviceId())
      if (!whDev) {
        ret.status = "NoDevice";
        resolve(ret)
      }
      else if (!(writableFile instanceof FileSystemWritableFileStream)) {
        ret.status = "WriteFileFailed";
        resolve(ret)
      }
      else {
        whDev.downloadLogs(writableFile)
          .then(() => resolve(ret))
          .catch(err => {
            this.logger.error("Failed to write device logs for deviceId=" + deviceId, err);
            ret.status = "WriteFileFailed";
            resolve(ret);
          })
          .finally(() => writableFile.close())
      }
    });
  }

  getKnownNetworks(deviceId: string): Promise<KnownNetworksResponse> {
    throw new Error("Method not implemented.");
  }

  getScannedNetworks(deviceId: string): Promise<ScannedNetworksResponse> {
    return this.getWhDevice(deviceId)
    .then(dev => dev.getScannedNetworks());
  }

  private getWhDevice(deviceId: string): Promise<WhDevice> {
    const whDev = this.whDevices.find(dev => deviceId === dev.deviceId());
    return whDev ? Promise.resolve(whDev) : Promise.reject(new Error(`Device ${deviceId} not found`));
  }

  getWiFiStatus(deviceId: string): Observable<WiFiStatusResponse> {
    const whDev = this.whDevices.find(dev => deviceId === dev.deviceId());
    if (whDev && whDev.getWiFiStatusObservable()) {
        return whDev.getWiFiStatusObservable();
    }
    if (this.wifiStatus$) {
      return this.wifiStatus$;
    }

    const o = new Observable<WiFiStatusResponse>((observer) => {
      this.logger.info("Network status listener called", deviceId);
      const whDev = this.whDevices.find(dev => deviceId === dev.deviceId());
      if (!whDev) {
        this.logger.error("No device found", deviceId);
        return;
      }
      whDev.getWiFiStatus().then((s) => observer.next(s));
    });

    if (whDev) {
      whDev.setWiFiStatusObservable(o);
    } else {
      this.wifiStatus$ = o;
    }
    return o;
  }

  setWiFiParameters(request: WiFiConnectParams): void {
    this.getWhDevice(request.deviceId)
    .then(dev => dev.setWiFiParameters(request));
  }

  getNetworkProvisioning(deviceId: string): Promise<NetworkProvisioningInfo> {
    return this.getWhDevice(deviceId)
    .then(dev => dev.getNetworkProvisioning());
  }

  setNetworkProvisioning(request: NetworkProvisioningParams): Promise<NetworkProvisioningInfo> {
    return this.getWhDevice(request.deviceId)
    .then(dev => dev.setNetworkProvisioning(request));
  }

  getBluetoothStatus(deviceId: string): Promise<BluetoothInfo> {
    throw new Error("Method not implemented.");
  }

  setBluetoothSettings(request: BluetoothParams): Promise<SetBluetoothResponse> {
    throw new Error("Method not implemented.");
  }

  setBluetoothPairing(request: BluetoothPairingRequest): Promise<SetBluetoothResponse> {
    throw new Error("Method not implemented.");
  }

  getGeneralSettings(deviceId: string): Promise<GetGeneralSettingsResponse> {
    return this.getWhDevice(deviceId)
    .then(dev => dev.getGeneralSettings() )
  }

  setGeneralSettings(request: GeneralSettings): Promise<SetGeneralSettingsResponse> {
    return this.getWhDevice(request.deviceId)
    .then(dev => dev.setGeneralSettings(request));
  }

  adminLogin(request: AdminLoginRequest): Promise<AdminLoginResponse> {
    return this.getWhDevice(request.deviceId)
    .then(dev => dev.adminLogin(request.password));
  }

  adminPasswordChange(request: AdminPasswordChangeRequest): Promise<AdminPasswordChangeResponse> {
    throw new Error("Method not implemented.");
  }

  adminPasswordEnabled(deviceId: string): Promise<AdminPasswordEnabledResponse> {
    throw new Error("Method not implemented.");
  }

  simplePassword(deviceId: string): Promise<SimplePasswordResponse> {
    return this.getWhDevice(deviceId)
    .then(dev => dev.simplePassword());
  }

  setSimplePassword(request: SetSimplePasswordRequest): Promise<SetSimplePasswordResponse> {
    return this.getWhDevice(request.deviceId)
    .then(dev => dev.setSimplePassword(request.simple));
  }

  getCertificateInfo(deviceId: string): Promise<GetCertificateInfoResponse> {
    return this.getWhDevice(deviceId)
    .then(dev => dev.getCertificateInfo());
  }

  createCertificate(request: CreateCertificateRequest): Promise<CreateCertificateResponse> {
    return this.getWhDevice(request.deviceId)
    .then(dev => dev.createCertificateCsr(request));
  }

  getCertificateFile(deviceId: string): Promise<GetCertificateFileResponse> {
    return this.getWhDevice(deviceId)
    .then(dev => dev.getCsrFile());
  }

  installCertificate(request: InstallCertificateRequest): Promise<InstallCertificateResponse> {
    throw new Error("Method not implemented.");
  }

  getInstalledCertificates(deviceId: string): Promise<GetInstalledCertificatesResponse> {
    return this.getWhDevice(deviceId)
    .then(dev => dev.getInstalledCertificates());
  }

  deleteCertificate(request: DeleteCertificateRequest): Promise<DeleteCertificateResponse> {
    throw new Error("Method not implemented.");
  }

  certificateDetails(request: CertificateDetailsRequest): Promise<CertificateDetailsResponse> {
    throw new Error("Method not implemented.");
  }

  importConfiguration(request: ImportConfigurationRequest): Promise<ImportConfigurationResponse> {
    throw new Error("Method not implemented.");
  }

  exportConfiguration(deviceId: string): Promise<ExportConfigurationResponse> {
    throw new Error("Method not implemented.");
  }

  startAudioTest(deviceId: string): Promise<StartAudioTestResponse> {
    throw new Error("Method not implemented.");
  }

  stopAudioTest(request: StopAudioTestRequest): Promise<StopAudioTestResponse> {
    throw new Error("Method not implemented.");
  }

  setServerCAValidation(request: SetServerCAValidationRequest): Promise<SetServerCAValidationResponse> {
    return this.getWhDevice(request.deviceId)
    .then(dev => dev.setServerCAValidation(request.enable));
  }

  needOOB(deviceId: string): Promise<NeedOOBResponse> {
    return Promise.resolve({deviceId, status: "Unknown"});  // TODO for pids of 0x9217 and 0x92B2
  }

  setWifiClientCertPassword(request: SetWifiClientCertPasswordRequest): Promise<SetWifiClientCertPasswordResponse> {
    throw new Error("Method not implemented.");
  }

  getCrashFiles(request: GetCrashFilesRequest): Promise<GetCrashFilesResponse> {
    throw new Error("Method not implemented.");
  }

  isAppInstalled(appName: string): Promise<IsAppInstalledResponse> {
    throw new Error("Method not implemented.");
  }

  //private DeviceList$ = new Subject<Device[]>();
  private brickedDevice$ = new Subject<BrickedDevice>();

  private NonPltDeviceAdded$ = new Subject<NonPolyDevice>();
  private NonPltDeviceRemoved$ = new Subject<NonPolyDevice>();

  private devices$ = new BehaviorSubject<Device[]>([]);
  private deviceSettingsMap$ = new Map<string, BehaviorSubject<DeviceSetting[]>>();

  private idGen: number = 0;
  private hidDevices: HidDeviceInfo[] = []  // devices that are being loaded or already disconnected
  private whDevices: WhDevice[] = [];       // connected and loaded devices
  private publishedDevices: WhDevice[] = [];// this.whDevices without duplicates (based on uniqueId)

  //private primaryDevice: PrimaryDevice = null;

  private products = new Products();

  private deviceNameCB: Object = new Object();
  private getKnownNetworksCB: Object = new Object();
  private getScannedNetworksCB: Object = new Object();
  private getWiFiStatusMap = new Map<string, BehaviorSubject<WiFiStatusResponse[]>>();
  private statusMap: Object = new Object();
  private setProvisioningMap: Object = new Object();
  private getProvisioningMap: Object = new Object();
  private setBluetoothSettingsMap: Object = new Object();
  private getBluetoothStatusMap: Object = new Object();
  private setBluetoothPairingMap: Object = new Object();
  private setGeneralSettingsMap: Object = new Object();
  private getGeneralSettingsMap: Object = new Object();
  private adminLoginMap: Object = new Object();
  private adminPasswordChangeMap: Object = new Object();
  private adminPasswordEnabledMap: Object = new Object();
  private simplePasswordMap: Object = new Object();
  private setSimplePasswordMap: Object = new Object();
  private createCertificateMap: Object = new Object();
  private getCertificateInfoMap: Object = new Object();
  private getCertificateFileMap: Object = new Object();
  private installCertificateMap: Object = new Object();
  private getInstalledCertificatesMap: Object = new Object();
  private deleteCertificateMap: Object = new Object();
  private certificateDetailsMap: Object = new Object();
  private importConfigurationMap: Object = new Object();
  private exportConfigurationMap: Object = new Object();
  private setServerCAValidationMap: Object = new Object();
  private startAudioTestMap: Object = new Object();
  private stopAudioTestMap: Object = new Object();
  private needOOBMap: Object = new Object();
  private setWifiClientCertPasswordMap: Object = new Object();
  private getCrashFilesMap: Object = new Object();
  private isAppInstalledMap: Object = new Object();

  private wifiStatus$: Observable<WiFiStatusResponse> = undefined;

  constructor(private logger: ILoggingService) {
    super();

    if ("hid" in navigator) {
      navigator.hid.addEventListener("connect", (e: HIDConnectionEvent): any => {
        this.handleConnectedDevice(e);
      });
      navigator.hid.addEventListener("disconnect", (e: HIDConnectionEvent): any => {
        this.handleDisconnectedDevice(e);
      });
      this.loadDevices();
    }
  }

  private async loadDevices() {
    const devices = await navigator.hid.getDevices();
    this.logger.info("device count=" + devices.length);
    for (const dev of devices) {
      this.loadDevice(dev);
    }
  }

  private loadDevice(dev: HIDDevice, ms: number = 250) {
    let info = this.hidDevices.find(d => d.device === dev);
    if (undefined === info) {
      info = { device: dev, connected: true };
      this.hidDevices.push(info);
    }
    info.connected = true;
    this.loadDeviceAttempt(3, ms, (++this.idGen).toString(), info);
  }

  private loadDeviceAttempt(attempts: number, ms: number, deviceId: string, info: HidDeviceInfo) {
    setTimeout(() => {
      if (info.connected) {
        this.loadDevice2(deviceId, info.device).catch((error) => {
          if (1 < attempts) {
            this.logger.warn(`${deviceId}: scheduling device reload`, error);
            this.loadDeviceAttempt(attempts - 1, 3000, deviceId, info);
          }
        });
      }
    }, ms);
  }

  private async loadDevice2(deviceId: string, dev: HIDDevice) {
    await dev.open();
    const dm = this;
    const o: WhDeviceObserver = {
      onWhDeviceUpdate() {
        dm.publishWhDevices();
      },
      onWhDeviceSettingsUpdate(dev: WhDevice) {
        dm.publishWhDeviceSettings(dev);
      },
      onChildDeviceAdded(dev: WhDevice) {
        dm.addWhDevice(dev);
      },
      onChildDeviceRemoved(dev: WhDevice) {
        dm.removeWhDevice(dev);
      }
    };
    const whDev = WhDevice.make(deviceId, dev, o, this.logger);
    return whDev.loadDevice()
      .then(() => {
        const index = this.hidDevices.findIndex(d => d.device === whDev.hidDevice);
        if (0 <= index) {
          this.hidDevices.splice(index, 1);
          this.addWhDevice(whDev)
        }
        else {
          // likely got disconnected while being loaded
          this.logger.info(`${whDev.deviceId()}: no HID device`);
        }
      })
      .catch(async (error) => {
        this.logger.error(`${whDev.deviceId()}: initial fetch: ${error}`)
        await dev.close();
        return Promise.reject(error);
      });
  }

  private addWhDevice(whDev: WhDevice) {
    this.logger.info(`${whDev.deviceId()}: added device ${whDev.devMgrDevice.displayName}`);
    this.whDevices.push(whDev);
    this.publishWhDevices();
    if (!this.deviceSettingsMap$.has(whDev.deviceId())) {
      this.deviceSettingsMap$.set(whDev.deviceId(), new BehaviorSubject<DeviceSetting[]>([]));
    }
    this.publishWhDeviceSettings(whDev);
  }

  private removeWhDevice(whDev: WhDevice, publish: boolean = true): boolean {
    const index = this.whDevices.findIndex(d => whDev === d);
    this.logger.info(`${whDev.deviceId()}: remove device idx=${index} len=${this.whDevices.length}`);
    if (0 <= index) {
      this.whDevices.splice(index, 1);
      this.logger.info(`${whDev.deviceId()}: device removed at idx=${index} len=${this.whDevices.length}`);
      whDev.onRemoval();  // this may invalidate 'index'
      if (!whDev.devMgrDevice.parentDeviceId) {
        this.hidDevices.push({ device: whDev.hidDevice, connected: false, uniqueId: whDev.devMgrDevice.uniqueId });
      }
      if (publish) {
        this.publishWhDevices();
      }
      return true;
    }
    return false;
  }

  public removeDevice(uniqueId: string): void {
    this.logger.info(`Received delete device from UI for ID=${uniqueId}`);
    const whDev = this.whDevices.find(d => d.devMgrDevice.uniqueId === uniqueId.toUpperCase());
    if (undefined === whDev) {
      const index = this.hidDevices.findIndex(d => d.uniqueId === uniqueId.toUpperCase());
      if (0 <= index) {
        const [dev] = this.hidDevices.splice(index, 1);
        dev.device.forget();
        this.logger.warn(`Device ${uniqueId} forgotten`);
      }
    }
    else {
      this.logger.warn(`${whDev.deviceId()}: cannot remove connected device`);
    }
  }

  private publishWhDevices() {
    const map = new Map<string, WhDevice>();
    this.whDevices.forEach(dev => {
      const d = map.get(dev.devMgrDevice.uniqueId);
      if (undefined === d || dev.displayPriority() < d.displayPriority()) {
        map.set(dev.devMgrDevice.uniqueId, dev);
      }
    });
    this.publishedDevices = Array.from(map.values());
    this.logger.info(`publishing devices ${this.publishedDevices.length} of ${this.whDevices.length}`)
    this.devices$.next(this.publishedDevices.map(dev => dev.devMgrDevice));
  }

  private publishWhDeviceSettings(whDev: WhDevice) {
    if (this.publishedDevices.includes(whDev)) {
      const settings = whDev.getDeviceSettings();
      this.logger.info(`${whDev.deviceId()}: publishWhDeviceSettings`, settings)
      this.deviceSettingsMap$.get(whDev.deviceId())?.next(settings);
    }
  }

  requestDevice(): void {
    this.requestDev();
  }

  private async requestDev(): Promise<void> {
    if ("hid" in navigator) {
      const f1: HIDDeviceFilter = {vendorId: VID.PLANTRONICS};
      const f2: HIDDeviceFilter = {vendorId: VID.POLYCOM};
      const devices = await navigator.hid.requestDevice({filters: [f1, f2]});
      this.logger.info("all devices", devices);
      devices.filter(dev => undefined === this.findRootDevice(dev)).forEach(dev => this.loadDevice(dev));
    }
    return Promise.resolve();
  }

  private handleConnectedDevice(e: HIDConnectionEvent): void {
    this.logger.info(`HID(${hex4l(e.device.vendorId)}/${hex4l(e.device.productId)}) connected`, e);
    this.loadDevice(e.device);
  }

  private handleDisconnectedDevice(e: HIDConnectionEvent): void {
    this.logger.info(`HID(${hex4l(e.device.vendorId)}/${hex4l(e.device.productId)}) disconnected`, e);
    const whDev = this.findRootDevice(e.device);
    if (undefined === whDev) {
      const index = this.hidDevices.findIndex(d => d.device === e.device);
      if (0 <= index) {
        const [info] = this.hidDevices.splice(index, 1);
        info.connected = false;
        this.logger.warn(`incompletely loaded device disconnected`, e.device);
      }
      else {
        this.logger.error(`unknown device disconnected`, e.device);
      }
      return;
    }
    this.removeWhDevice(whDev);
  }

  private findRootDevice(hidDev: HIDDevice): WhDevice | undefined {
    return this.whDevices.find((dev) => dev.hidDevice === hidDev && undefined === dev.devMgrDevice.parentDeviceId);
  }

  /**
   * API -----------------------------------------------------------------
   */

  getDevices(): Observable<Device[]> {
    this.logger.info("getDevices$");
    //return this.devices$.pipe(
    return this.devices$.pipe(map(REMOVE_ALL_UNDEFINED_DEVICE_OBJECTS())).pipe(
      // Perform deep copy of the device list, because we don't want to share
      // the same device objects with the API consumer, as the consumer may
      // change the internals of the devices objects. We don't want this change
      // to affect our internal objects here.
      map(DEEP_COPY())
    );
  }

  getDevice(deviceId: string): Observable<Device> {
    this.logger.info("getDevice$", deviceId);
     return this.getDevices()
       .pipe(pick((device) => deviceId === device.id))
  }

  getPrimaryDevice(): Observable<Device> {
    throw new Error("getPrimaryDevice not implemented.");
  }

  getBrickedDevice(): Observable<BrickedDevice> {
    this.logger.info("getBrickedDevice$");
    return this.brickedDevice$;
  }

  getDeviceSettings(deviceId: string): Observable<DeviceSetting[]> {
    this.logger.info(`${deviceId}: getDeviceSettings$`)
    let o = this.deviceSettingsMap$.get(deviceId);
    if (undefined === o) {
      o = new BehaviorSubject<DeviceSetting[]>([]);
      this.deviceSettingsMap$.set(deviceId, o)
    }
    return o
      .pipe(
        map(
          // Perform deep copy of the device settings list, because we don't want
          // to share the same objects with the API consumer, as the consumer may
          // change the internals of settings objects.
          DEEP_COPY()
        )
      )
  }

  setDeviceSetting(deviceId: string, setting: Partial<DeviceSetting>): Promise<void> {
    this.logger.info(`${deviceId}: setDeviceSetting(${setting.id}, ${setting.value}, ${setting.autoEnabled})`);
    return this.getWhDevice(deviceId)
    .then(whDev => {
      if (setting.id && (undefined !== setting.value) &&
        ((typeof setting.value === 'string') || (setting.value instanceof String) ||
        (typeof setting.value === 'number') || (setting.value instanceof Number))) {
        const id = parseInt(setting.id);
        const value = setting.value as string;
        return whDev.setDeviceSetting(id, value, setting.autoEnabled)
          .catch(async (error) => {
            this.logger.error(`Setting ${setting.id}: ${error}`)
            if (whDev.tooManyTimeouts()) {
              // buggy devices stop communicating when Chromebook comes back from sleep so reload parent device
              const dev = this.findRootDevice(whDev.hidDevice);
              if (dev) {
                const hidDev = dev.hidDevice;
                await hidDev.close();
                this.removeWhDevice(dev, false);
                this.loadDevice(hidDev, 0);
              }
            }
            return Promise.reject(error)
          });
      }
      else {
        throw new Error(`${deviceId}: Invalid setting ID or value ${setting.id} ${setting.value} ${typeof setting.value}`);
      }
    });
  }

  publishDeviceSettings(deviceId: string) {
    const whDev = this.whDevices.find(dev => deviceId === dev.deviceId())
    if (whDev) {
      this.publishWhDeviceSettings(whDev);
    }
  }
}
