import { Injectable } from "@angular/core";
import { combineLatest, Observable, of } from "rxjs";
import { map, switchMap } from "rxjs/operators";
import { DeviceManagerService, OzDevice } from "./device-manager.service";
import { RepositoryFirmware } from "./repository/model";
import { Repository } from "./repository/repository.service";
import {
  ALL_DEVICES_LANGUAGES,
  ALL_DEVICES_LANGUAGES_IDS,
  DeviceLanguages,
  MS_IN_DAY,
} from "../utils/constants";
import {AdminConfig} from "./admin-config.service";

function toUnique(languageId: string, index: number, arr: string[]): boolean {
  return arr.indexOf(languageId) === index;
}

export interface OzDeviceWithRepositoryFirmware extends OzDevice {
  repositoryFirmware?: RepositoryFirmware;
  repositoryFirmwareVersion?: string;
}

@Injectable({
  providedIn: "root",
})
export class Dfu {
  constructor(
    private repo: Repository,
    private deviceManager: DeviceManagerService,
    private adminConfig: AdminConfig,
  ) {}

  /**
   * Obtains available firmware information if the `device` needs update.
   */
  availableUpdate(device: OzDevice): Observable<RepositoryFirmware> {
    if (!device || this.adminConfig.mode === 'pwa') {
      return of(null);
    }
    return this.repo
      .getFirmware({
        device,
        pollInterval: MS_IN_DAY,
      })
      .pipe(
        switchMap((repoFirmware) => {
          return combineLatest([
            of(repoFirmware),
            // Update needed only if provided version is higher
            this.deviceManager.isUpdateNeeded(
              device.id,
              (repoFirmware?.rules as unknown) as string,
              !!repoFirmware?.policy
            ),
          ]);
        }),
        map(([availableFirmware, isUpdateNeeded]) => {
          return isUpdateNeeded ? availableFirmware : null;
        })
      );
  }

  /**
   * Obtains available update per connected device.
   */
  allConnectedDevices(): Observable<OzDeviceWithRepositoryFirmware[]> {
    return this.deviceManager.getConnectedDevices().pipe(
      switchMap((devices: OzDevice[]) => {
        const observables = devices.map((device) =>
          combineLatest([of(device), this.availableUpdate(device)]).pipe(
            map(([device, firmware]) => {
              const foo = device as OzDeviceWithRepositoryFirmware;
              foo.repositoryFirmware = firmware;
              foo.repositoryFirmwareVersion =
                firmware?.rules?.setid || firmware?.rules?.version;
              return foo;
            })
          )
        );
        if (observables.length) {
          return combineLatest([...observables]);
        } else {
          return of([]);
        }
      })
    );
  }

  /**
   * Obtains available update per device.
   */
  allDevices(): Observable<OzDeviceWithRepositoryFirmware[]> {
    return this.deviceManager.getDevices().pipe(
      switchMap((devices: OzDevice[]) => {
        if (devices.length) {
          return combineLatest([
            ...devices.map((device) =>
              combineLatest([of(device), this.availableUpdate(device)]).pipe(
                map(([device, firmware]) => {
                  // TODO: UI: Should we have a deep copy here?
                  const foo = device as OzDeviceWithRepositoryFirmware;
                  foo.repositoryFirmware = firmware;
                  foo.repositoryFirmwareVersion =
                    firmware?.rules?.setid || firmware?.rules?.version;
                  return foo;
                })
              )
            ),
          ]);
        } else {
          return of([]);
        }
      })
    );
  }

  /**
   * Obtains a list of supported languages for a device.
   */
  availableLanguages(device: OzDevice): Observable<DeviceLanguages> {
    return this.repo
      .getFirmware({
        device,
        pollInterval: MS_IN_DAY,
      })
      .pipe(
        map((repoFirmware: RepositoryFirmware) => {
          const list = repoFirmware.rules.components
            // Filter only components having information about language
            .filter((c) => !!c.languageId)
            // Map component into Language ID
            .map((c) => c.languageId)
            // Keep only languages that are supported by Lens
            .filter((languageId) =>
              ALL_DEVICES_LANGUAGES_IDS.includes(languageId)
            )
            // Keep unique Language IDs
            .filter(toUnique)
            // Map Language ID into Language
            .map(
              (langId) =>
                ALL_DEVICES_LANGUAGES.filter((lang) => lang.id === langId)[0]
            );
          return {
            archiveLocation: repoFirmware.archiveLocation,
            list,
          };
        })
      );
  }
}
