import { Device } from "@poly/hub-native";
import { Component, EventEmitter, Input, Output } from "@angular/core";
import { DevicePID, DEVICE_FEATURES } from "../utils/constants";

type DeviceClassification = "DEFAULT" | "WIRELESS" | "P5|P21";
const P5ORP21_PIDS = [DevicePID.P5, "9296", DevicePID.P21];

@Component({
  selector: "oz-dfu-confirm",
  templateUrl: "./dfu-confirm.component.pug",
})
export class DfuConfirmComponent {
  public deviceClassification: DeviceClassification = "DEFAULT";
  private _device: Device;
  @Input()
  set device(d: Device) {
    if (!d) return;
    this._device = d;
    this.deviceClassification = P5ORP21_PIDS.includes(d.pid)
      ? "P5|P21"
      : d.featureList.includes(DEVICE_FEATURES.OTA)
      ? "WIRELESS"
      : "DEFAULT";
  }
  get device(): Device {
    return this._device;
  }
  @Output() cancel = new EventEmitter<void>();
  @Output() confirm = new EventEmitter<void>();
}
