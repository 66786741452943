import { Component, OnDestroy, OnInit } from "@angular/core";
import { SettingsMetaData } from "../../lens-settings/lens-settings-ui.model";
import { LensSettingsService } from "../../services/lens-settings.service";
import {
  HydrationAndVisionUtils,
  WellnessKeys,
  WellnessDaysFrequency,
  OptionData,
} from "../../utils/hydrationAndVisionUtils";
import { cloneDeep as _cloneDeep } from "lodash";
import { ReminderModalSettings } from "../reminders-modal/reminders-modal.component";
import { Subscriptions } from "../../utils/subscriptions";

@Component({
  templateUrl: "./vision.component.pug",
})
export class VisionComponent implements OnInit, OnDestroy {
  private subs = new Subscriptions();

  public allLensSettings: SettingsMetaData;
  public cards = [
    {
      title: "HEALTH_AND_WELLNESS.VISION.CARDS.LOOK_AWAY.TITLE",
      description: "HEALTH_AND_WELLNESS.VISION.CARDS.LOOK_AWAY.DESCRIPTION",
      logo: "vision_look_away",
      id: "lookAway",
    },
    {
      title: "HEALTH_AND_WELLNESS.VISION.CARDS.TAKE_BREAK.TITLE",
      description: "HEALTH_AND_WELLNESS.VISION.CARDS.TAKE_BREAK.DESCRIPTION",
      logo: "vision_stand_up_move",
      id: "moveBody",
    },
  ];

  public frequencyLabel = "";
  public visionDayOptions;
  public visionTimeOptions;
  public visionTimeSpanOptions;
  public option: OptionData;
  public reminderModal: { open: boolean; settings: ReminderModalSettings } = {
    open: false,
    settings: {
      from: "",
      to: "",
      span: "",
      days: [],
    },
  };
  public wellnessDaysFrequency = WellnessDaysFrequency;
  public wellnessKeys = WellnessKeys;

  constructor(
    private lensSettings: LensSettingsService,
    public hydrationAndVisionUtils: HydrationAndVisionUtils
  ) {
    this.visionDayOptions = this.hydrationAndVisionUtils.getDayOptions();
    this.visionTimeOptions = this.hydrationAndVisionUtils.getTimeOptions();
    this.visionTimeSpanOptions = this.hydrationAndVisionUtils.getTimeSpanOptions();

    this.option = {
      action: "visionEnabled",
      schedule: {
        days: this.visionDayOptions,
        timeFrom: this.visionTimeOptions,
        timeTo: this.visionTimeOptions,
        timespan: this.visionTimeSpanOptions,
      },
      title: "",
      type: "",
    };
  }

  ngOnInit(): void {
    this.subs.add(
      this.lensSettings.lensSettings.subscribe((settings) => {
        this.allLensSettings = _cloneDeep(settings);

        this.hydrationAndVisionUtils.initializeRemindersData(
          this.allLensSettings,
          this.lensSettings
        );
      })
    );
  }

  showReminderModal() {
    this.reminderModal = {
      open: true,
      settings: this.hydrationAndVisionUtils.getReminderSettings(
        "visionEnabled",
        this.allLensSettings
      ),
    };
  }

  confirmReminderModal(settings: ReminderModalSettings) {
    const visionSettings: Pick<
      SettingsMetaData,
      "visionDays" | "visionTimeTo" | "visionTimeFrom" | "visionTimeSpan"
    > = {
      visionTimeFrom: settings.from,
      visionTimeTo: settings.to,
      visionTimeSpan: settings.span,
      visionDays: settings.days,
    };

    this.allLensSettings = { ...this.allLensSettings, ...visionSettings };

    this.lensSettings.setLensSettings(this.allLensSettings);
    this.wellnessChange();
    this.closeReminderModal();
  }

  closeReminderModal() {
    this.reminderModal = {
      open: false,
      settings: {
        from: "",
        to: "",
        span: "",
        days: [],
      },
    };
  }

  wellnessChange() {
    const val = this.allLensSettings[WellnessKeys.VISION_ENABLED];
    this.allLensSettings = this.hydrationAndVisionUtils.enableOrDisableNotifications(
      this.allLensSettings
    );

    this.lensSettings.setLensSettings(this.allLensSettings);
    this.hydrationAndVisionUtils.handleNotificationToggle(
      "vision",
      this.allLensSettings,
      val
    );
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }
}
