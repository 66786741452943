import { Pipe, PipeTransform } from "@angular/core";
import { TranslatedOptions } from "./dropdown.component";
import { compact as _compact } from "lodash";

export type DisabledOptions = Array<string>;
export type HiddenOptions = Array<string>;

/**
 * Pipe that allows certain options (e.g. in a dropdown) to be disabled.
 *
 * Named "conditionalOptions" because a future implementation may include hiding
 * options rather than just disabling them.
 *
 * This is currently considered a pure pipe, does not need to introspect an
 * observable or value that might change.
 *
 * @example options | conditionalOptions:['disabledValueA', 'disabledValueB']:['hiddenValueC']
 * @example options | conditionalOptions:[]:['hiddenValueC'] // indicates no disabled values
 */
@Pipe({
  name: "conditionalOptions",
})
export class ConditionalOptionsPipe implements PipeTransform {
  constructor() {}

  /**
   * @param options
   * @param disabledValues - greys out dropdown options, thus making them not selectable
   * Use cautiously for the user experience side; UX should be in on a decision where an
   * item is disabled instead of just hidden.
   * Note TranslatedOption's `value` key is optional, when it is
   * not present useIndexAsValue may be used to infer the value.
   * Currently transform does not support disabling for options without values as this is uncommon and
   * potentially dangerous (e.g. option order could change dynamically as is the case
   * with camera setting favorite options)
   * @param hiddenValues - hides dropdown values, thus making them not selectable
   */
  transform(
    options: TranslatedOptions,
    disabledValues: DisabledOptions,
    hiddenValues: HiddenOptions
  ) {
    const results = options.map((option) => {
      let disabled = false;

      // not option.value may empty in favor of `useIndexAsValue`, which
      // gets value based on index
      if (
        option.hasOwnProperty("value") &&
        hiddenValues?.includes(option.value)
      ) {
        return undefined;
      }

      // not option.value may empty in favor of `useIndexAsValue`, which
      // gets value based on index
      if (
        option.hasOwnProperty("value") &&
        disabledValues?.includes(option.value)
      ) {
        disabled = true;
      }

      return {
        ...option,
        disabled,
      };
    });

    // remove undefined and falsy values
    return _compact(results);
  }
}
