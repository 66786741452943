import {
  Component,
  Input,
  OnInit,
  OnChanges,
  ViewChild,
  ElementRef,
  forwardRef,
  HostListener,
} from "@angular/core";
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";

export interface Icon {
  name: string;
  size: number;
}
export interface Option {
  value: string;
  text: string;
  icons?: Icon[];
  disabled?: boolean;
}

export type Options = Array<Option>;

@Component({
  selector: "oz-dropdown-with-icons",
  templateUrl: "./dropdown-with-icons.component.pug",
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DropdownWithIconsComponent),
      multi: true,
    },
  ],
})
export class DropdownWithIconsComponent
  implements OnInit, OnChanges, ControlValueAccessor {
  @Input() options: Option[];
  @Input() additionalOptions: Option[];
  @Input() placeholder = "";
  @Input() disabled: boolean;
  @Input() translate: boolean = false;
  @Input() scrollIntoView: boolean = false;

  value = null;
  placeholderText = "";
  placeholderIcons: Icon[] = [];
  openDropdown = false;

  @ViewChild("dropdownWithIcons") dropdownRef: ElementRef<HTMLElement>;
  @ViewChild("dropdownInput") dropdownInputRef: ElementRef<HTMLElement>;
  @ViewChild("dropdownList") dropdownListRef: ElementRef<HTMLElement>;

  constructor() {}

  private onChange = (_: any) => {};
  private onTouched = () => {};

  ngOnInit(): void {}

  ngOnChanges() {
    this.setPlaceholder();
  }

  emitChanges(option: Option) {
    this.value = option.value;
    this.setPlaceholder();
    this.onChange(this.value);
  }

  writeValue(value: any): void {
    this.value = value;
    this.setPlaceholder();
  }

  setPlaceholder() {
    if (!this.value) {
      this.placeholderText = this.placeholder;
      this.placeholderIcons = [];
    } else {
      this.options.forEach((option) => {
        if (option.value === this.value) {
          this.placeholderText = option.text;
          this.placeholderIcons = option.icons?.length ? option.icons : [];
        }
      });
    }
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  toggleDropdown() {
    this.openDropdown = !this.openDropdown;
    if (this.scrollIntoView && this.openDropdown) {
      setTimeout(() => {
        this.dropdownListRef?.nativeElement.scrollIntoView({
          behavior: "smooth",
          block: "start",
          inline: "nearest",
        });
      }, 200);
    }
  }

  @HostListener("document:click", ["$event"])
  @HostListener("document:touchstart", ["$event"])
  onClick(event) {
    // event.path is undefined in PWA so this does not work
    const dropdownRefInPath = event.path?.find((e) => {
      return e === this.dropdownRef?.nativeElement;
    });
    const dropdownInputRefInPath = event.path?.find((e) => {
      return e === this.dropdownInputRef?.nativeElement;
    });
    const dropdownListRefInPath = event.path?.find((e) => {
      return e === this.dropdownListRef?.nativeElement;
    });
    if (this.disabled && dropdownInputRefInPath) {
      event.preventDefault();
      return;
    }
    const isClickIn =
      !!dropdownRefInPath ||
      !!dropdownInputRefInPath ||
      !!dropdownListRefInPath;
    if (isClickIn) {
      this.toggleDropdown();
    } else {
      this.openDropdown = false;
    }
  }
}
