import { IconService } from "./icon.service";
import { Component, OnInit, Input } from "@angular/core";
import { SafeHtml, DomSanitizer } from "@angular/platform-browser";

@Component({
  selector: "oz-icon",
  templateUrl: "./icon.component.pug",
})
export class IconComponent implements OnInit {
  _iconSVG: SafeHtml;
  _iconName: string;
  _px = 0;
  _size = 0.25;
  _color = null;
  style: any = {};

  @Input() set icon(iconName: string) {
    this._iconName = iconName;
    this.prepareSVG();
  }

  @Input() set size(val: number) {
    this._size = val / 4; // Just make the scale start smaller. So a size=1 ends up like 70px.
    this.prepareSVG();
  }

  @Input() set px(val: number) {
    this._px = val;
    this.prepareSVG();
  }

  @Input() set color(val: string) {
    this._color = val;
    this.prepareSVG();
  }

  @Input() offset: { top: string; left: string } = null;

  constructor(
    private iconService: IconService,
    private sanitizer: DomSanitizer
  ) {
    this.icon = "question_help_circle";
  }

  ngOnInit(): void {}

  prepareSVG() {
    if (!this.iconService.icons[this._iconName]) {
      console.warn(`oz-icon does not support icon named: ${this._iconName}`);
      return;
    }

    const svgStr = this.iconService.icons[this._iconName];
    const naturalWidth = Number(
      this.getSVGAttribute(svgStr, "width").replace("px", "")
    );
    const naturalHeight = Number(
      this.getSVGAttribute(svgStr, "height").replace("px", "")
    );

    let sizedWidth = naturalWidth;
    let sizedHeight = naturalHeight;

    if (!this._px) {
      sizedWidth = naturalWidth * this._size;
      sizedHeight = naturalHeight * this._size;

      this.style.width = sizedWidth + "px";
      this.style.height = sizedHeight + "px";
    }

    if (this._px) {
      sizedWidth = this._px;
      sizedHeight = (naturalHeight / naturalWidth) * this._px;

      this.style.width = sizedWidth + "px";
      this.style.height = sizedHeight + "px";
    }

    let result = svgStr
      .replace(/width="(.*?)"/, `width="${sizedWidth}"`)
      .replace(/height="(.*?)"/, `height="${sizedHeight}"`);

    if (this._color) {
      result = result
        .replace(/stroke="(.*?)"/g, (match, $1) =>
          $1 === "none" ? match : `stroke="${this._color}"`
        )
        .replace(/fill="(.*?)"/g, (match, $1) =>
          $1 === "none" ? match : `fill="${this._color}"`
        );
    }

    this._iconSVG = this.sanitizer.bypassSecurityTrustHtml(result);
  }

  private getSVGAttribute(svgStr: string, att: string) {
    let result = "none";
    let matches = svgStr.match(`${att}="(.*?)"`);
    if (matches && matches.length >= 2) {
      result = matches[1];
    }
    return result;
  }
}
