import { Injectable } from "@angular/core";
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  UrlTree,
  Router,
} from "@angular/router";
import { combineLatest, from, of, Observable } from "rxjs";
import { filter, map, switchMap } from "rxjs/operators";
import { DeviceManagerService } from "../services/device-manager.service";

@Injectable({
  providedIn: "root",
})
export class FirstTimeDeviceGuard implements CanActivate {
  constructor(
    private deviceManager: DeviceManagerService,
    private router: Router
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    const uniqueId = route.parent.paramMap.get("id");
    return this.deviceManager.getDevice(uniqueId).pipe(
      filter((device) => !!device),
      switchMap((device) => {
        return combineLatest([of(device), this.deviceManager.needOOBmap$]);
      }),
      map(([device, needOob]) => {
        if (needOob.has(device.id) && needOob.get(device.id)) {
          this.router.navigate([
            "/detail",
            device.uniqueId,
            "ftc_enter_password",
          ]);
          return false;
        }
        return true;
      })
    );
  }
}
