import { Observable } from "rxjs";

/**
 * Provides an interface for managing softphone and software settings.
 */
export abstract class IConfigManager {
    /**
     * Receives a list of softphone settings.
     */
  abstract getSoftphones(): Observable<Softphone[]>;

    /**
     * Receives information about specific softphone.
     * 
     * @param softphoneId Target softphone.
     */
  abstract getSoftphone(softphoneId: number): Observable<Softphone>;

    /**
     * Changes a setting for a specific softphone.
     * 
     * @param softphone New softphone setting. It is expected to contain softphone id and enabled status.
     */
  abstract setSoftphone(softphone: Partial<Softphone>): void;

    /**
     * Changes a set of softphones settings.
     * 
     * @param softphones New values for softphone settings. Each is expected to contain softphone id and enabled status.
     */
  abstract setSoftphones(softphones: Partial<Softphone>[]): void;

    /**
     * Receives a list of software settings.
     */
  abstract getSoftwareSettings(): Observable<SoftwareSetting[]>;

    /**
     * Receives information about specific software setting.
     * 
     * @param softwareSettingId The target software setting.
     */
  abstract getSoftwareSetting(
    softwareSettingId: string
  ): Observable<SoftwareSetting>;

    /**
     * Changes a setting's value.
     * 
     * @param softwareSetting New setting value. It is expected to contain name and value.
     */
  abstract setSoftwareSetting(softwareSetting: Partial<SoftwareSetting>): void;

    /**
     * Changes a set of software settings.
     * 
     * @param softwareSettings New values for software settings. Each is expected to contain software setting name and value.
     */
  abstract setSoftwareSettings(
    softwareSettings: Partial<SoftwareSetting>[]
  ): void;

  /**
   * Starts software update when Lens is installed per-machine.
   * Update download URL will be formed in this format:
   * - https://swupdate.<environment>.lens.poly.com/lens-desktop-windows/<version>/<version>/PolyLens-<version>.msi
   *   for "dev" and "stage" environments.
   * - https://swupdate.lens.poly.com/lens-desktop-windows/<version>/<version>/PolyLens-<version>.msi
   *   for "prod" environment.
   *
   * @param environment Cloud environment for file download. One of: "dev", "stage", "prod".
   * @param version Version of Lens installer to download and run.
   */
  abstract startElevatedSoftwareUpdate(
    environment: string,
    version: string
  ): void;
}

export interface SoftwareSetting {
    /**
     * Techical name of the setting, like "logLevel".
     */
  id: string;
 
    /**
     * List of options for this setting.
     */
  options: any[];

    /**
     * Value of the setting.
     */
  value: any;
}

export interface Softphone {
    /**
     * Softphone id.
     */
  id: number;

    /**
     * Softphone display name.
     */
  displayName: string;

    /**
     * If true, Poly Lens can attach to this softphone.
     */
  enabled: boolean;

    /**
     * If true, Poly Lens is attached to this softphone.
     */
  attached: boolean;

    /**
     * If true, this softphone can be a target softphone, e.g. for making outgoing calls from a headset.
     */
  canBeTarget: boolean;
}
