import { Injectable } from "@angular/core";
import { Subscription } from "rxjs";
import { switchMap } from "rxjs/operators";
import { filter } from "rxjs/operators";
import { TranslateService } from "@ngx-translate/core";
import { EulaService } from "./eula.service";
import { NotificationsService } from "./notifications.service";
import { SettingsMetaData } from "../lens-settings/lens-settings-ui.model";
import { DeviceManagerService, OzDevice } from "./device-manager.service";
import { LensSettingsService } from "./lens-settings.service";
import { addManufacturerPrefix } from "../utils/device.utils";

@Injectable({
  providedIn: "root",
})
export class ChargingNotificationsService {
  private chargingState: { [uniqueId: string]: boolean } = {};
  private connectedState: { [uniqueId: string]: boolean } = {};
  private devicesSub: Subscription;
  private translations = {
    "NOTIFICATIONS.CHARGING.STOPPED": "Stopped Charging",
    "NOTIFICATIONS.CHARGING.CHARGING": "Charging",
  };

  constructor(
    private lensSettingsService: LensSettingsService,
    private deviceManager: DeviceManagerService,
    private notificationService: NotificationsService,
    private translateService: TranslateService,
    private eulaService: EulaService
  ) {
    this.lensSettingsService.lensSettings.subscribe((settings) => {
      if (settings.enableNotifications && settings.deviceAlerts) {
        this.setupNotifications(settings);
      } else {
        this.teardownNotifications();
      }
    });

    this.translateService
      .stream(Object.keys(this.translations))
      .subscribe((translations) => {
        Object.keys(translations).forEach((key) => {
          this.translations[key] = translations[key];
        });
      });
  }

  private setupNotifications(settings: SettingsMetaData) {
    if (settings.deviceCharging && !this.devicesSub) {
      this.devicesSub = this.eulaService.agreed$
        .pipe(
          filter((v) => v),
          switchMap(() => this.deviceManager.getDevices())
        )
        .subscribe((devices) => {
          devices.forEach((d) => this.notifyIfNeeded(d));
        });
    } else if (!settings.deviceCharging && this.devicesSub) {
      this.teardownNotifications();
    }
  }

  private teardownNotifications() {
    this.devicesSub?.unsubscribe();
    this.devicesSub = null;
    this.chargingState = {};
  }

  private notifyIfNeeded(device: OzDevice) {
    if (!device.featureList.includes("batteryIndication")) return false;

    const deviceCharging = this.chargingState[device.uniqueId];
    if (
      device.isConnected &&
      ((deviceCharging === undefined && device.battery?.charging) ||
        (deviceCharging !== device.battery?.charging &&
          device.battery?.charging))
    ) {
      this.sendNotification(device);
    } else if (
      this.connectedState[device.uniqueId] !== undefined &&
      !this.connectedState[device.uniqueId] &&
      device.isConnected &&
      device.battery?.charging
    ) {
      this.sendNotification(device);
    }

    this.connectedState[device.uniqueId] = device.isConnected;
    this.chargingState[device.uniqueId] = device.battery?.charging;
  }

  private sendNotification(device: OzDevice) {
    const bodyKey = "CHARGING";
    this.notificationService.singleNotification({
      title: `${addManufacturerPrefix(device.displayName)}`,
      body: this.translations["NOTIFICATIONS.CHARGING." + bodyKey],
      // TODO: This icon is no good in dark mode. Plus, it's huge
      // icon: "charging-notification.png",
      origin: "CHARGING",
    });
  }
}
