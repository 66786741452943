import {Injectable} from "@angular/core";
import {debounce as _debounce} from "lodash";
// import path from "path";
import {HISTORICAL_DEVICES_KEY, IOT_DEVICE_ID} from "../utils/constants";
import {Device} from "@poly/hub-native";
import {UtilityService} from "./utility.service";
import {AdminConfig} from "./admin-config.service";
import {ILoggingService} from "./logging.service";

/**
 * StorageService is a generic data storage utility.  It can store
 * JavaScript primitives and objects, always encoded via JSON.stringify
 * and retrieved using a safe JSON.parse.
 *
 * localStorage is available in Chromium and is thus the simplest way to store key/value pairs.
 * However, there is also a desire to persist storage; for instance, if a person is logged
 * into the Electron app, then upgrades the app, it would be nice to persist key/value pairs
 * for the login session and not require the user to login.
 *
 * Thus, this service is a hybrid of both methods.
 *
 */
@Injectable({
  providedIn: "root",
})
export class StorageService {
  // simple flag to indicate if the test for localStorage has run and completed
  public hydrateProcessCompleted: boolean = false;
  // debounced saveToOperatingSystem to prevent multiple calls to save within a certain millisecond time period
  private debouncedSaveToOperatingSystem = _debounce(
    this.saveToOperatingSystem,
    500,
    {
      leading: false,
      trailing: true,
    }
  );
  private readonly browserStorageFile = "browserStorage.json";

  /**
   * Path to persist browser data.
   *
   * Windows: %APPDATA% folder, e.g. C:\Users\person\AppData\Roaming\oz-client\browserStorage.json
   * Mac: /Users/person/Library/Application Support/oz-client/browserStorage.json
   * Linux: ($XDG_CONFIG_HOME or ~/.config)/oz-client/browserStorage.json
   *
   * @private
   */
  private readonly browserStoragePath = this.browserStorageFile;

  constructor(
    private adminConfig: AdminConfig,
    private logger: ILoggingService
  ) {

    this.hydrateIfEmpty();

    if (this.adminConfig.mode === "network") {
      this.clearItem("AUTH_ACCESS_TOKEN");
      this.clearItem("AUTH_REFRESH_TOKEN");
      this.clearItem("IS_AUTHENTICATED");
      this.clearItem("PRODUCT_CATALOG");
      this.clearItem("IOT_DEVICE_ID");
      this.clearItem("AUTH_PROFILE");
      this.clearItem("RETURNING_USER");
    }
  }

  /**
   * Synchronous set.  Will eventually save to operating system level file.
   */
  setItem(
    key: string,
    item: string | number | object | boolean | undefined,
    sessionStorage = false
  ) {
    // IMPORTANT: Session storage does not persist across sessions... ;)
    if (sessionStorage) {
      window.sessionStorage.setItem(key, JSON.stringify(item));
      return;
    }

    window.localStorage.setItem(key, JSON.stringify(item));

    this.debouncedSaveToOperatingSystem();
  }

  /**
   * Get an item, with optional defaultItem if no item is stored or undefined was stored.
   *
   * @param key
   * @param defaultItem - returned if stored value was undefined (which after JSON.stringify
   * is "undefined"), or is not set; if a falsy value is stored in setItem, it will come back falsy
   * because JSON.stringify is used when setting
   * @param sessionStorage
   */
  getItem(key: string, defaultItem: any = undefined, sessionStorage = false) {
    try {
      const storage = sessionStorage ? "sessionStorage" : "localStorage";
      const val = window[storage].getItem(key);

      // if getItem key does not exist, null is returned
      // localStorage.setItem(myKey, JSON.stringify(undefined)) saves the string "undefined";
      // thus check for that value here
      if (null === val || "undefined" === val) {
        return defaultItem;
      }

      return JSON.parse(val);
    } catch (e) {
      console.error(e);

      return defaultItem;
    }
  }

  hasItem(key: string, sessionStorage = false) {
    const storage = sessionStorage ? "sessionStorage" : "localStorage";

    return key in window[storage];
  }

  clearItem(key: string, sessionStorage = false) {
    if (sessionStorage) {
      window.sessionStorage.removeItem(key);
      return;
    }

    window.localStorage.removeItem(key);

    // save a new version to Operating System so that the item does not come back during hydration
    this.debouncedSaveToOperatingSystem();
  }

  async clearAllItems() {
    // we never want to delete IOT_DEVICE_ID from local storage
    const iotDeviceId = this.getItem(IOT_DEVICE_ID);
    // we never want to delete connected devices from local storage
    const devices = this.getConnectedDevices();

    // clear sessionStorage
    window.sessionStorage.clear();

    // clear localStorage
    window.localStorage.clear();

    // clear storage file
    const success = await this.clearFromOperatingSystem();
    if (!success) {
      console.error("Error removing persistent storage file.");
    }

    // rehydrate IOT_DEVICE_ID and HISTORICAL_DEVICES in local storage if it exists
    if (success) {
      iotDeviceId ? this.setItem(IOT_DEVICE_ID, iotDeviceId) : null;
      devices ? this.setItem(HISTORICAL_DEVICES_KEY, devices) : null;
    }

    return success;
  }

  private getConnectedDevices(): Array<Device> {
    const devices = JSON.parse(
      window.localStorage.getItem(HISTORICAL_DEVICES_KEY)
    );
    for (let uid in devices) {
      if (!devices[uid].isConnected) {
        delete devices[uid];
      }
    }
    return devices;
  }

  /**
   * Async function that returns a boolean indicating success of operating
   * system storage.
   */
  private async saveToOperatingSystem(): Promise<boolean> {
    return new Promise((resolve) => {
      // fs is not currently mocked in test runs, resolve as true (saved properly)
      if (UtilityService.isInTestRunner()) {
        resolve(true);
        return;
      }

      if (!this.browserStoragePath) {
        console.error("Unable to retrieve operating system storage path.");

        return;
      }

      this.writeFile(
        this.browserStoragePath,
        JSON.stringify(window.localStorage),
        (err) => {
          if (err) {
            console.error(
              "Could not persist browser storage to operating system storage: ",
              err
            );
            return;
          }

          resolve(true);
        }
      );
    });
  }

  private async hydrateIfEmpty() {
    // NOTE this is an admittedly simple check, but should work for an app upgrade
    if (!window.localStorage.length) {
      // empty localStorage, check
      try {
        const previousStorage = await this.retrieveFromOperatingSystem(
          this.browserStoragePath
        );

        for (const property in previousStorage) {
          window.localStorage.setItem(property, previousStorage[property]);
        }
      } catch (e) {
        console.log("Unable to retrieve prior browser storage.");
      }
    }

    this.hydrateProcessCompleted = true;
  }

  /**
   * Retrieves saved browser storage from operating system.
   */
  private retrieveFromOperatingSystem(
    filePath: string
  ): Promise<any> {
    const defaultData = {};

    return new Promise((resolve, reject) => {
      // in a test run? if so, fs is currently not mocked;
      // assume for now that the hydration process is immediately completed and return default data
      if (UtilityService.isInTestRunner()) {
        resolve(defaultData);

        return;
      }

      if (!filePath) {
        reject("Unable to retrieve operating system storage path.");

        return;
      }

      this.readFile(filePath, "utf8", (err, data) => {
        if (err) {
          console.error(
            "Could not retrieve browser storage from operating system storage: ",
            err
          );
          reject(err);

          return;
        }

        if (data) {
          try {
            resolve(JSON.parse(data));
          } catch (e) {
            console.error(
              `Could not parse data retrieved from persistent storage file.`
            );
            reject(e);
          }

          return;
        }

        resolve(defaultData);
      });
    });
  }

  /**
   * Resolves to a boolean indicating success of clearing browser storage (deleting file).
   * Does not throw an error or a catchable Promise.
   *
   * @private
   */
  private clearFromOperatingSystem(): Promise<boolean> {
    return new Promise((resolve, reject) => {
      // in a test run? if so, fs is currently not mocked; return immediate true value
      if (UtilityService.isInTestRunner()) {
        resolve(true);

        return;
      }

      if (!this.browserStoragePath) {
        reject("Unable to retrieve operating system storage path.");

        return;
      }

      try {
        this.unlinkSync(this.browserStoragePath);
        resolve(true);
      } catch (err) {
        resolve(false);
      }
    });
  }

  /*
  ** Unimplemented methods from lensdesktop
   */
  private readFile(
    path: string,
    options: { encoding: BufferEncoding; flag?: string | undefined; } | BufferEncoding,
    callback: (err: Error | null, data: string) => void
  ): void {
    this.logger.debug("readFile (" + path + ", " + options + ", " + callback + ")");
  }

  private writeFile(path: string | number, data: string, callback: (err: Error, data: string) => void): void {
    this.logger.debug("writeFile (" + path + ", " + data.length + " Bytes)");
  }

  private unlinkSync(path: string): void {
    this.logger.debug("unlinkSync (" + path + ")");
  }
}
