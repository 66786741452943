import { FavoritesService } from "./services/favorites.service";
import { ILoggingService, LoggingService } from "./services/logging.service";
import "reflect-metadata";
import "../polyfills";
import { InMemoryCache, from, ApolloLink } from "@apollo/client/core";
import { HttpLink } from "apollo-angular/http";
import { onError } from "@apollo/client/link/error";
import { RetryLink } from "@apollo/client/link/retry";
import { COMMON_APP_MODULES } from "./common-modules";
import { DeviceManagerService } from "./services/device-manager.service";
import { TokenInterceptor } from "./interceptors/token.interceptor";
import { APOLLO_OPTIONS } from "apollo-angular";
import { HTTP_INTERCEPTORS, HttpHeaders } from "@angular/common/http";
import { APP_INITIALIZER, NgModule } from "@angular/core";
import { AppComponent } from "./app.component";
import { COMMON_COMPONENTS } from "./common-components";
import { AuthService } from "./services/auth.service";
import { AuthServiceZero } from "./services/auth.service.zero";
import { AuthClientConfig, AuthConfig, AuthModule } from '@auth0/auth0-angular';
import { ConfigService } from "./services/config.service";
import { IFileDownload, FileDownload } from "./services/file-download.service";
import { NativeService } from "./services/hub-native-service/hub-native.service";
import { SoundscapeService } from "./services/soundscape.service";
import { StateService } from "./services/state.service";
import { StorageService } from "./services/storage.service";
import { TrayService } from "./services/tray.service";
import { UtilityService } from "./services/utility.service";
import { DfuModalService } from "./services/dfu-modal.service";
import { DeviceNotificationService } from "./services/device-notification.service";
import { WindowsEventsService } from "./services/windows-events.service";
import { SaveLogsService } from "./services/save-logs-service";
import { NetworkSpeedTestService } from "./services/network-speed-test.service";
import { DfuNotificationsService } from "./services/dfu-notifications.service";
import { DfuNeedReconnectService } from "./services/dfu-need-reconnect.service";
import { WifiService } from "./services/wifi.service";
import { BluetoothService } from "./services/bluetooth.service";
import { LoggedInDevices } from "./services/loggedin-devices.service";
import { AdminConfig } from "./services/admin-config.service";
import { PostponeDfuService } from "./shared/components/toast/postpone-dfu-toast/postpone-dfu.service";
import { Toasts } from "./shared/components/toast/toasts.service";
import { DfuBadgeCounterService } from "./services/dfu-badge-counter.service";
import { HttpConnectivityService } from "./services/http-connectivity.service";
import { BrickedDeviceService } from "./services/bricked-device.service";
import { AcousticEventsService } from "./services/acoustic-events.service";
import { environment } from '../environments/environment';
import { ServiceWorkerModule } from '@angular/service-worker';
import { TranslateModule } from "@ngx-translate/core";
import {IpcRenderer, PolytronServiceApi} from "./polytron/polytron.service.api";
import {PolytronEventEmitter, PolytronService} from "./polytron/polytron.service";

// Provide an initializer function that returns a Promise
function configInitializer(
  config: AuthClientConfig,
  configService: ConfigService
) {
  const env = configService.currentEnv;

  const ac: AuthConfig = {
    audience: env.AUTH0_AUDIENCE,
    clientId: env.AUTH0_CLIENT_ID,
    domain: env.AUTH0_DOMAIN,
    redirectUri: env.AUTH0_CALLBACK,
    scope: "openid profile email offline_access",
    useRefreshTokens: true,
    useRefreshTokensFallback: true,
    skipRedirectCallback: false,
    // advancedOptions: {}
  }
  return () => config.set(ac);
}

@NgModule({
  declarations: [
    AppComponent,
    COMMON_COMPONENTS
  ],
  imports: [
    COMMON_APP_MODULES,
    AuthModule.forRoot(), //fyi: config is built and assigned dynamically
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
      // Register the ServiceWorker as soon as the application is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000'
    })
  ],
  providers: [
    AdminConfig,
    {
      provide: APP_INITIALIZER,
      useFactory: (adminConfig: AdminConfig) => {
        return () => {
          adminConfig.load();
        };
      },
      deps: [AdminConfig],
      multi: true,
    },
    //TODO: trying to do this in AuthService ctor instead ...
    {
      provide: APP_INITIALIZER,
      useFactory: configInitializer,
      deps: [AuthClientConfig, ConfigService],
      multi: true,
    },
    {
      provide: APOLLO_OPTIONS,
      useFactory: (
        httpLink: HttpLink,
        config: ConfigService,
        adminConfig: AdminConfig,
        httpConnectivity: HttpConnectivityService
      ) => {
        // Apollo - Http link configuration
        const httpLinkConfigured = httpLink.create({
          uri: config.currentEnv.GRAPHQL_ENDPOINT,
          headers: new HttpHeaders()
            .append(
              "apollographql-client-name",
              UtilityService.getBuildProductId()
            )
            .append(
              "apollographql-client-version",
              UtilityService.getBuildVersion()
            ),
        });

        // Apollo - Error handling
        const errorLinkConfigured = onError(
          ({ graphQLErrors, networkError }) => {
            if (graphQLErrors)
              graphQLErrors.forEach(({ message, locations, path }) =>
                console.error(
                  `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
                )
              );
            if (networkError) {
              console.error(`[Network error]: `, networkError);
              httpConnectivity.setConnection(false);
            }
          }
        );

        // Apollo - handle response
        const responseLinkConfigured = new ApolloLink((operation, forward) => {
          return forward(operation).map((data) => {
            // Called after server responds
            console.log(`Apollo operation '${operation.operationName}' completed`, data);
            httpConnectivity.setConnection(true);
            return data;
          });
        });

        // Apollo - handle retry
        const retryLinkConfigured = new RetryLink({
          delay: {
            initial: 10000,
            max: Infinity,
            jitter: true,
          },
          attempts: {
            max: 10,
            retryIf: (error, _operation) => {
              return (
                error?.error?.errors[0]?.extensions?.code !=
                "GRAPHQL_VALIDATION_FAILED"
              );
            },
          },
        });

        if (adminConfig.mode !== "network") {
          return {
            cache: new InMemoryCache(),
            link: from([
              retryLinkConfigured,
              responseLinkConfigured,
              errorLinkConfigured,
              httpLinkConfigured,
            ]),
          };
        } else {
          return null;
        }
      },
      deps: [HttpLink, ConfigService, AdminConfig, HttpConnectivityService],
    },
    HttpConnectivityService,
    DeviceManagerService,
    { provide: AuthService, useClass: AuthServiceZero },
    { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true },
    DeviceNotificationService,
    WindowsEventsService,
    DfuModalService,
    FavoritesService,
    NativeService,
    NetworkSpeedTestService,
    SaveLogsService,
    WifiService,
    BluetoothService,
    // NOTE TrayService is a dependency of AppComponent and thus lifts with the app
    // if this method is ever insufficient for Tray initialization, can block the app
    // from lifting by using APP_INITIALIZER method: https://stackoverflow.com/a/44731279/3232832
    SoundscapeService,
    StateService,
    StorageService,
    TrayService,
    UtilityService,
    { provide: IFileDownload, useClass: FileDownload },
    { provide: ILoggingService, useClass: LoggingService },
    { provide: IpcRenderer, useClass: PolytronEventEmitter },
    { provide: PolytronServiceApi, useClass: PolytronService },
    DfuNotificationsService,
    DfuNeedReconnectService,
    LoggedInDevices,
    PostponeDfuService,
    Toasts,
    DfuBadgeCounterService,
    BrickedDeviceService,
    AcousticEventsService,
    TranslateModule,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
