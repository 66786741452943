import {
  Component,
  EventEmitter,
  HostBinding,
  HostListener,
  Input,
  Output,
} from "@angular/core";

/**
 * Not a stand-alone component / element.
 *
 * Dedicated child element of oz-buttongroup
 * Requires parent element of oz-buttongroup
 *
 * @example
 * oz-buttongroup([(ngModel)]="someKey", [defaultSelected]="off")
 *    oz-buttongroup-button(value="off") {{ "TRANSLATION_KEY.OFF" | translate }}
 *    oz-buttongroup-button(value="on") {{ "TRANSLATION_KEY.ON" | translate }}
 */
@Component({
  selector: "oz-buttongroup-button",
  template: "<ng-content></ng-content>",
})
export class ButtonGroupButtonComponent {
  @Output() onClick: EventEmitter<string> = new EventEmitter<string>();
  @Input() textKey: string;
  @Input() value: string;
  @HostBinding("class.selected")
  public selected: boolean = false;

  constructor() {}

  @HostListener("click")
  clickEvent() {
    this.onClick.emit(this.value);
  }
}
