// import { IpcMainEvent } from "electron";
// import { BrowserWindow } from "electron";
// import { IpcRequest } from "../IpcRequest";

import {NotificationEvent} from "./Notifications";
import {PolytronEventEmitter} from "../app/polytron/polytron.service";

export interface IpcRequest {
  responseChannel?: string;

  params?: any;
}

// TODO: Stub out IPCEvent
export class IPCEvent {
  constructor(
    private polyEE: PolytronEventEmitter,
  ){}

  sender = this;
  send(channel: string, event:NotificationEvent) {
    this.polyEE.emit(channel, event);
  }
}

export interface IpcChannelInterface {
  getName(): string;

  handle(
    event: IPCEvent,
    request: IpcRequest,
    mainWindow?: Window | undefined
  ): void;
}
