import { Component, OnInit, OnDestroy, NgZone } from "@angular/core";
import { LensSettingsService } from "../../services/lens-settings.service";
import { Subscription } from "rxjs";
import { TrayService } from "../../services/tray.service";
import { TranslatedOptions } from "../../shared/components/dropdown/dropdown.component";

@Component({
  selector: "oz-soundscaping",
  templateUrl: "./soundscaping.component.pug",
})
export class SoundscapingComponent implements OnInit, OnDestroy {
  private lensSettingsServiceSub: Subscription;
  public soundscapeEnabled = false;
  public soundscapeSound = "";
  public soundscapeVolume = 1;
  public cards = [
    {
      title: "HEALTH_AND_WELLNESS.SOUNDSCAPING.CARDS.ADD_SOUNDSCAPE.TITLE",
      description:
        "HEALTH_AND_WELLNESS.SOUNDSCAPING.CARDS.ADD_SOUNDSCAPE.DESCRIPTION",
      logo: "soundscaping",
      id: "addSoundscapesToYourEnvironment",
    },
  ];
  public soundscapeOptions: TranslatedOptions;

  constructor(
    private lensSettingsService: LensSettingsService,
    private ngZone: NgZone,
    private trayService: TrayService
  ) {
    this.trayService.soundscapeOptions$.subscribe((soundscapeOptions) => {
      this.ngZone.run(() => {
        this.soundscapeOptions = soundscapeOptions;
      });
    });
  }

  ngOnInit(): void {
    this.lensSettingsServiceSub = this.lensSettingsService.lensSettings.subscribe(
      (settings) => {
        ({
          soundscapeEnabled: this.soundscapeEnabled,
          soundscapeSound: this.soundscapeSound,
          soundscapeVolume: this.soundscapeVolume,
        } = settings);

        // Invalid sound selected
        if (
          this.soundscapeOptions.length > 0 &&
          !this.soundscapeOptions
            .map((opt) => opt.value)
            .some((s) => s == this.soundscapeSound)
        ) {
          this.soundscapeSound = this.soundscapeOptions[0].value;
          this.handleChange();
        }
      }
    );
  }

  handleChange() {
    this.lensSettingsService.patchLensSettings({
      soundscapeEnabled: this.soundscapeEnabled,
      soundscapeSound: this.soundscapeSound,
      soundscapeVolume: this.soundscapeVolume,
    });
  }

  ngOnDestroy(): void {
    this.lensSettingsServiceSub.unsubscribe();
  }
}
