import { Pipe, PipeTransform } from "@angular/core";
import { Device } from "@poly/hub-native";

@Pipe({
  name: "pid",
})
export class PidPipe implements PipeTransform {
  transform(device: Device): string {
    if (device) {
      return `0x${device.pid.toString(16).toUpperCase().padStart(4, "0")}`;
    }
  }
}
