import { EulaGuard } from "./guards/eula.guard";
import { EulaComponent } from "./eula/eula.component";
import { SecretComponent } from "./secret/secret.component";
import { ControlsComponent } from "./controls/controls.component";
import { DetailComponent } from "./detail/detail.component";
import { FirstTimeUserGuard } from "./guards/first-time-user.guard";
import { HomeComponent } from "./home/home.component";
import { Route } from "@angular/router";
import { PageNotFoundComponent } from "./shared/components";
import { FtuComponent } from "./ftu/ftu.component";
import { HealthWellnessComponent } from "./health-wellness/health-wellness.component";
import { SoundscapingComponent } from "./health-wellness/soundscaping/soundscaping.component";
import { HydrationComponent } from "./health-wellness/hydration/hydration.component";
import { VisionComponent } from "./health-wellness/vision/vision.component";
import { BestPracticesComponent } from "./best-practices/best-practices.component";
import { BPCameraComponent } from "./best-practices/camera/camera.component";
import { DeskSetupComponent } from "./best-practices/desk-setup/desk-setup.component";
import { BPHeadsetComponent } from "./best-practices/headset/headset.component";
import { DeviceOverviewComponent } from "./device-overview/device-overview.component";
import { DeviceOverviewBluetoothRemoteComponent } from "./device-overview-bluetooth-remote/device-overview-bluetooth-remote.component";
import { ConnectionChecklistComponent } from "./connection-checklist/connection-checklist.component";
import { LensSettingsComponent } from "./lens-settings/lens-settings.component";
import { SupportComponent } from "./support/support.component";
import { PolySupportComponent } from "./poly-support/poly-support.component";
import { DeviceInfoAndLogsComponent } from "./device-info-and-logs/device-info-and-logs.component";
import { DfuProgressContainerComponent } from "./dfu-progress-container/dfu-progress-container.component";
import { DeviceUsageAndNetworkComponent } from "./device-usage-and-network/device-usage-and-network.component";
import { AppLayoutComponent } from "./app-layout/app-layout.component";
import { ROUTE_CONTROLS, ROUTE_HOME, ROUTE_SETTINGS } from "./utils/constants";
import { TitleBarRouteData } from "./shared/components/title-bar/title-bar.component";
import { DeviceSettingsComponent } from "./device-settings/device-settings.component";
import { FtcWizardManualSetupComponent } from "./ftc-wizard-manual-setup/ftc-wizard-manual-setup.component";
import { FtcWizardImportedConfigComponent } from "./ftc-wizard-imported-config/ftc-wizard-imported-config.component";
import { FtcEnterPasswordComponent } from "./device-settings/device-ftc-settings/ftc-enter-password/ftc-enter-password.component";
import { FtcDeviceStartSetupComponent } from "./device-settings/device-ftc-settings/ftc-device-start-setup/ftc-device-start-setup.component";
import { FirstTimeDeviceGuard } from "./guards/first-time-device.guard";

interface OzRoute extends Route {
  data?: {
    animation?: "OtherPage" | "HomePage" | "SubPage";
    background?: "ocean";
    deviceDetails?: boolean;
    navIndex?: number;
    childAnimation?: "SubPage" | "OtherSubPage";
    titleBar?: TitleBarRouteData;
    children?: OzRoutes;
  };
}

type OzRoutes = Array<OzRoute>;

export const routes: OzRoutes = [
  {
    path: "",
    component: EulaComponent,
    canActivate: [EulaGuard],
    data: {
      titleBar: {
        hidden: true,
      },
    },
  },
  {
    path: "ftu",
    component: FtuComponent,
    canActivate: [FirstTimeUserGuard],
    data: {
      titleBar: {
        disabled: true,
      },
    },
  },
  {
    path: "ftc-wizard/:id",
    component: FtcWizardManualSetupComponent,
    data: {
      titleBar: {
        hidden: true,
      },
    },
  },
  {
    path: "ftc-wizard-imported-config/:id",
    component: FtcWizardImportedConfigComponent,
    data: {
      titleBar: {
        hidden: true,
      },
    },
  },
  {
    path: "",
    component: AppLayoutComponent,
    children: [
      {
        path: ROUTE_HOME,
        component: HomeComponent,
        data: {
          animation: "HomePage",
          background: "ocean",
        },
      },
      {
        path: "health-wellness",
        component: HealthWellnessComponent,
        data: {
          animation: "OtherPage",
        },
        children: [
          {
            path: "soundscaping",
            component: SoundscapingComponent,
            data: {
              animation: "SubPage",
              navIndex: 0,
            },
          },
          {
            path: "hydration",
            component: HydrationComponent,
            data: {
              animation: "SubPage",
              navIndex: 1,
            },
          },
          {
            path: "vision",
            component: VisionComponent,
            data: {
              animation: "SubPage",
              navIndex: 2,
            },
          },
        ],
      },
      {
        path: "best-practices",
        component: BestPracticesComponent,
        data: {
          animation: "OtherPage",
        },
        children: [
          {
            path: "headset",
            component: BPHeadsetComponent,
            data: {
              childAnimation: "SubPage",
              navIndex: 0,
            },
          },
          {
            path: "camera",
            component: BPCameraComponent,
            data: {
              childAnimation: "OtherSubPage",
              navIndex: 1,
            },
          },
          {
            path: "desk-setup",
            component: DeskSetupComponent,
            data: {
              childAnimation: "SubPage",
              navIndex: 2,
            },
          },
        ],
      },
      {
        // if this path changes, please update tray.service.ts
        // and its call to `this.router.navigate( ... )`
        path: "detail/:id",
        component: DetailComponent,
        data: {
          animation: "OtherPage",
          deviceDetails: true,
          cssClass: "device-details",
        },
        children: [
          {
            path: "overview",
            component: DeviceOverviewComponent,
            canActivate: [FirstTimeDeviceGuard],
            data: {
              navIndex: 0,
            },
          },
          {
            path: "ftc_enter_password",
            component: FtcEnterPasswordComponent,
            data: {
              navIndex: 0,
            },
          },
          {
            path: "ftc_start_setup",
            component: FtcDeviceStartSetupComponent,
            data: {
              titleBar: {
                hidden: true,
              },
            },
          },
          {
            path: "usb_adapter",
            component: DeviceOverviewComponent,
            data: {
              navIndex: 1,
            },
          },
          {
            path: "charging_case",
            component: DeviceOverviewComponent,
            data: {
              navIndex: 2,
            },
          },
          {
            path: "bluetooth_remote",
            component: DeviceOverviewBluetoothRemoteComponent,
            data: {
              navIndex: 3,
            },
          },
          {
            path: ROUTE_CONTROLS,
            component: ControlsComponent,
            data: {
              navIndex: 4,
            },
          },
          {
            path: ROUTE_SETTINGS,
            component: DeviceSettingsComponent,
            data: {
              navIndex: 5,
            },
          },
          {
            path: "support",
            component: SupportComponent,
            data: {
              animation: "SubPage",
              navIndex: 6,
            },
          },
          {
            path: "device-info-and-logs",
            component: DeviceInfoAndLogsComponent,
            data: {
              navIndex: 7,
              titleBar: {
                back: { labelKey: "DETAIL.SUPPORT", goBack: true },
              },
              cssClass: "device-info-and-logs",
            },
          },
          {
            path: "dfu",
            component: DfuProgressContainerComponent,
            data: {
              navIndex: 8,
            },
          },
          // "Contact Support" page, accessed from device/support
          {
            path: "poly-support/:id",
            component: PolySupportComponent,
            data: {
              animation: "SubPage",
              navIndex: 9,
              titleBar: {
                back: { labelKey: "DETAIL.SUPPORT", goBack: true },
              },
            },
          },
          {
            path: "**",
            redirectTo: "overview",
            pathMatch: "full",
          },
        ],
      },
      {
        path: "usage-network",
        component: DeviceUsageAndNetworkComponent,
        data: {
          animation: "OtherPage",
        },
      },
      {
        path: "connection-checklist",
        component: ConnectionChecklistComponent,
        data: {
          animation: "OtherPage",
          titleBar: {
            back: {
              labelKey: "DETAIL.OVERVIEW.OVERVIEW",
              goBack: true,
            },
          },
        },
      },
      // "Contact Support" page, accessed from home cards
      {
        path: "poly-support/:id",
        component: PolySupportComponent,
        data: {
          // This has toggles that are managed within the component
          animation: "OtherPage",
        },
      },
      {
        path: "settings",
        component: LensSettingsComponent,
        data: {
          animation: "OtherPage",
        },
      },
    ],
  },
  {
    path: "secret",
    component: SecretComponent,
  },
  {
    path: "**",
    component: PageNotFoundComponent,
    data: {
      animation: "OtherPage",
      background: "ocean",
    },
  },
];
