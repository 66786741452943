import { BehaviorSubject } from "rxjs";
import { Injectable } from "@angular/core";
import { Toasts, Toast } from "../shared/components/toast/toasts.service";
import { distinctUntilChanged } from "rxjs/operators";

@Injectable({
  providedIn: "root",
})
export class HttpConnectivityService {
  private _connected$ = new BehaviorSubject<boolean>(null);
  private networkErrorToastId = "";
  private errorShowed = false;

  constructor(private toasts: Toasts) {}

  get connection$() {
    return this._connected$.asObservable().pipe(distinctUntilChanged());
  }

  setConnection(conn: boolean) {
    if (!conn) {
      const networkErrorToast: Toast = {
        type: "status-persistent",
        closeable: true,
        dismissAfter: 0,
        status: "error",
        text: "NOTIFICATIONS.NETWORK_ERROR",
      };
      const errorToast = this.toasts.all.find(
        (errorToast: Toast) =>
          errorToast.status === "error" &&
          errorToast.id === this.networkErrorToastId
      );
      if (!errorToast && !this.errorShowed) {
        this.networkErrorToastId = this.toasts.push(networkErrorToast);
        this.errorShowed = true;
      }
    } else {
      if (this.networkErrorToastId) {
        this.toasts.dismiss(this.networkErrorToastId);
        // Reset condition after network recovery
        this.errorShowed = false;
      }
    }
    this._connected$.next(conn);
  }
}
