import { Injectable, NgZone, OnDestroy } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { Device } from "@poly/hub-native";
import {
  cloneDeep as _cloneDeep,
  get as _get,
  intersection as _intersection,
  isArrayLikeObject as _isArrayLikeObject,
  isEqual as _isEqual,
  set as _set,
} from "lodash";
import { BehaviorSubject, Observable, Subject } from "rxjs";
import { filter, map, take } from "rxjs/operators";
import { ControlsDeviceSetting } from "../controls/controls.component";
import { TranslatedOption } from "../shared/components/dropdown/dropdown.component";
import { HISTORICAL_DEVICES_KEY } from "../utils/constants";
import { runInZone } from "../utils/rxjs.utils";
import { Subscriptions } from "../utils/subscriptions";
import { HistoricalDevices } from "./device-manager.service";
import { LensSettingsService } from "./lens-settings.service";
import { ILoggingService } from "./logging.service";
import { StorageService } from "./storage.service";
import { LodashAccessor, UtilityService } from "./utility.service";

// FavoriteOption does not have value, so value will be set to the index
export interface FavoriteOption extends TranslatedOption {
  canEditName: boolean;
  canDelete: boolean;
  isITManaged: boolean;
  // store entire setting object retrieved from deviceManager.getDeviceSettings
  settings?: ControlsDeviceSetting[];
}

export type FavoriteOptionsByDevice = {
  [uniqueId: string]: Array<FavoriteOption>;
};
export type FavoriteSelectedByDevice = { [key: string]: number };

export const SANDBOX_FAVORITE_INDEX = 0;

// these indices not exported, likely not useful externally and DEFAULT_FAVORITE_INDEX
// is a temporary baseline when not IT managed entry does not exist
// default when user loads controls page for first time
const DEFAULT_FAVORITE_INDEX = 1;
// same as DEFAULT_FAVORITE_INDEX, because IT_MANAGED_FAVORITE_INDEX will increment this.defaultFavoriteIndex when needed
const IT_MANAGED_FAVORITE_INDEX = 1;

const createFavorite = (number: number) => {
  return {
    text: "DETAIL.FAVORITE_NUMBER",
    params: { number },
    isITManaged: false,
    canEditName: true,
    canDelete: true,
  };
};

const createSandbox = () => ({
  text: "DETAIL.SANDBOX",
  isITManaged: false,
  canEditName: false,
  canDelete: false,
});

const createITManaged = () => ({
  text: "DETAIL.IT_MANAGED",
  isITManaged: true,
  canEditName: false,
  canDelete: false,
});

export const defaultFavoriteOptions: Array<FavoriteOption> = [
  createSandbox(),
  createFavorite(1),
  createFavorite(2),
  createFavorite(3),
  createFavorite(4),
];

/**
 * The favorites service manages user favorites in the camera controls page.
 * This will likely only be used by the controls.component.ts, but is used
 * to split our a significant portion of functionality for camera controls.
 */
@Injectable({
  providedIn: "root",
})
export class FavoritesService implements OnDestroy {
  private favorites$: Subject<undefined> = new Subject();
  // tracks ALL favorite options for all devices
  private _favoriteOptions$: BehaviorSubject<
    FavoriteOptionsByDevice | undefined
  > = new BehaviorSubject(undefined);
  private _favoriteSelectedByDevice$: BehaviorSubject<
    FavoriteSelectedByDevice | undefined
  > = new BehaviorSubject(undefined);
  private defaultFavoriteIndex = DEFAULT_FAVORITE_INDEX;
  private subs = new Subscriptions();
  private translatedCopy = "";

  constructor(
    private lensSettingsService: LensSettingsService,
    private ngZone: NgZone,
    private logger: ILoggingService,
    private translateService: TranslateService,
    private storageService: StorageService
  ) {
    this.initMigration();

    this.translateService
      .stream("DETAIL.FAVORITE_COPY")
      .subscribe((translation) => {
        this.translatedCopy = translation;
      });
  }

  /**
   * See LENS-1545, migration pathway from favorites stored with Device.id
   * to storing with Device.uniqueId.  Several versions down the road (e.g. late 2021)
   * when customers have had ample time to migrate favorite data, this.initMigration()
   * in constructor could be replaced simply with this.initSubscriptions(), and
   * remove the this.migrateFavoritesToUniqueId method.
   */
  private initMigration() {
    // inspect Lens Settings for possible migration of favorites
    this.lensSettingsService.lensSettings
      .pipe(
        filter((settings) => !!settings),
        take(1)
      )
      .subscribe((settings) => {
        this.migrateFavoritesToUniqueId(
          settings.favoriteOptionsByDevice,
          settings.favoriteSelectedByDevice
        );

        // only init subscriptions after migration pass is complete
        this.initSubscriptions();
      });
  }

  private initSubscriptions() {
    this.subs.add(
      this.lensSettingsService
        .getLensSetting$("favoriteOptionsByDevice")
        .subscribe((options) => {
          this.ngZone.run(() => this._favoriteOptions$.next(options));
        })
    );

    this.subs.add(
      this.lensSettingsService
        .getLensSetting$("favoriteSelectedByDevice")
        .subscribe((favoriteSelectedByDevice) => {
          this.ngZone.run(() =>
            this._favoriteSelectedByDevice$.next(favoriteSelectedByDevice)
          );
        })
    );
  }

  public get favoriteOptions$() {
    return this._favoriteOptions$.pipe(runInZone(this.ngZone));
  }

  /**
   * Get stream of favorites specific to a uniqueId.
   */
  public getFavoriteOptionsByUniqueId$(
    uniqueId: string
  ): Observable<Array<FavoriteOption> | undefined> {
    return this._favoriteOptions$.pipe(
      map((options) =>
        options && options[uniqueId] ? options[uniqueId] : undefined
      ),
      runInZone(this.ngZone)
    );
  }

  /**
   * This service may update favorite index, so does not make sense to have a point-in-time value.
   */
  public getFavoriteIndex$(uniqueId: string): Observable<number> {
    return this._favoriteSelectedByDevice$.pipe(
      map(
        (favoriteSelectedByDevice) =>
          +_get(favoriteSelectedByDevice, uniqueId, this.defaultFavoriteIndex)
      )
    );
  }

  public setFavoriteIndex(uniqueId: string, index: string | number) {
    const currentFavoriteSelectedByDevice = this._favoriteSelectedByDevice$
      .value;

    const favoriteSelectedByDevice: FavoriteSelectedByDevice = {
      ...currentFavoriteSelectedByDevice,
      [uniqueId]: +index,
    };

    this.lensSettingsService.patchLensSettings({ favoriteSelectedByDevice });
  }

  public setFavorites(uniqueId: string, favorites: any) {
    // store a cloned copy (and thus immutable) of favorites to storage
    const newFavorites = _cloneDeep(favorites);

    const currentFavoriteOptions = this._favoriteOptions$.value;

    const favoriteOptionsByDevice = {
      ...currentFavoriteOptions,
      [uniqueId]: newFavorites,
    };

    // patching is an expensive operation, so ensure new data
    if (!_isEqual(favoriteOptionsByDevice, currentFavoriteOptions)) {
      this.lensSettingsService.patchLensSettings({ favoriteOptionsByDevice });
    }
  }

  /**
   * Clear favorites for a specific ID.
   */
  public clearFavorites(uniqueId: string) {
    const currentFavoriteOptions = this._favoriteOptions$.value;
    const favoriteOptionsByDevice = _cloneDeep(currentFavoriteOptions);

    // if ID exists, delete it and patch settings
    if (favoriteOptionsByDevice.hasOwnProperty(uniqueId)) {
      delete favoriteOptionsByDevice[uniqueId];

      this.lensSettingsService.patchLensSettings({ favoriteOptionsByDevice });
    }
  }

  /**
   * Checks that:
   * 1. Favorites exist for the ID. If not, sets default favorites and returns.
   * 2. If favorites exist, but the settings are different than optional
   * controlSettings, reset favorites to default.
   *
   * @param uniqueId
   * @param controlSettings - optional, if set ensures settings match with stored settings for parameter parity
   */
  public ensureFavorites(
    uniqueId: string,
    controlSettings: ControlsDeviceSetting[] = []
  ): Array<FavoriteOption> {
    const currentFavoriteOptions = this._favoriteOptions$.value?.[uniqueId];

    // initial check to see if array-like object; ensures it has a .length property
    let favoritesIntegrity = _isArrayLikeObject(currentFavoriteOptions);

    if (currentFavoriteOptions && favoritesIntegrity) {
      if (!controlSettings.length) {
        // no more checking is possible due to no controlSettings passed in, return current value
        return currentFavoriteOptions;
      }

      return this.ensureFavoritesUsingCurrentSettings(
        uniqueId,
        currentFavoriteOptions,
        controlSettings
      );
    }

    // set default favorite and return, because:
    // either the uniqueId doesn't exist (no favorite settings yet for this device)
    // OR
    // favorites do not have integrity (favorite settings don't match with current controlSettings)
    const favoriteOptions = _cloneDeep(defaultFavoriteOptions);
    const favoriteOptionsWithSettings = this.ensureSettingsOnFavoriteOptions(
      favoriteOptions,
      controlSettings
    );

    this.setFavorites(uniqueId, favoriteOptionsWithSettings);

    // immediate response of newly generated object
    return favoriteOptions;
  }

  /**
   * Check historical devices and see if favorites need to be migrated.
   * Lens 1.0.6 and prior stored favorites using id, but uniqueId is more
   * appropriate because it stays the same between different USB positions.
   */
  public migrateFavoritesToUniqueId(
    options: FavoriteOptionsByDevice,
    selected: FavoriteSelectedByDevice
  ) {
    const historicalDevices: HistoricalDevices = this.storageService.getItem(
      HISTORICAL_DEVICES_KEY,
      {}
    );

    const historicalIDs = Object.values(historicalDevices).map(
      (device: Device) => {
        return device.id;
      }
    );

    const favoriteIDs = Object.keys(options);
    const intersection = _intersection(historicalIDs, favoriteIDs);

    // run migration task if there is any overlap
    if (!intersection.length) {
      return;
    }

    // start migration

    // this will use historicalDevices' mapping of uniqueId => { id: '...', ... }
    // to create a new options index
    // some devices will have multiple IDs for one uniqueId, so this method will
    // remove unmatched and duplicate IDs by creating a fresh newOptions object
    const idToUniqueIdMap = {};
    Object.keys(historicalDevices).forEach((uniqueId) => {
      const id = historicalDevices[uniqueId].id;
      if (id) {
        idToUniqueIdMap[id] = uniqueId;
      }
    });

    // new FavoriteOptionsByDevice and FavoriteSelectedByDevice
    const newOptions = {};
    const newSelectedByDevice = {};
    favoriteIDs.forEach((favoriteId) => {
      const uniqueId = idToUniqueIdMap[favoriteId];
      if (uniqueId) {
        newOptions[uniqueId] = options[favoriteId];

        // while selected favorites should match with options, still code defensively
        if (selected[favoriteId]) {
          newSelectedByDevice[uniqueId] = selected[favoriteId];
        }
      }
    });

    this.lensSettingsService.patchLensSettings({
      favoriteOptionsByDevice: newOptions,
      favoriteSelectedByDevice: newSelectedByDevice,
    });
  }

  public getSettingsIDs(controlSettings: Array<ControlsDeviceSetting>) {
    return controlSettings.map((controlSetting) => controlSetting.id);
  }

  /**
   * Apply default controlSettings to each favorite including sandbox, and return new favoriteOptions.
   *
   * @param favoriteOptions
   * @param controlSettings
   * @private
   */
  private ensureSettingsOnFavoriteOptions(
    favoriteOptions: Array<FavoriteOption>,
    controlSettings: Array<ControlsDeviceSetting>
  ): Array<FavoriteOption> {
    // nothing to do, no controlSettings given
    if (!controlSettings.length) {
      return favoriteOptions;
    }

    // iterate over each favoriteOption and apply settings if none already
    favoriteOptions.forEach((favoriteOption) => {
      if (!favoriteOption.settings) {
        favoriteOption.settings = _cloneDeep(controlSettings);
      }
    });

    return favoriteOptions;
  }

  /**
   * Update a specific favorite value given an accessor path.
   *
   * @param uniqueId
   * @param accessor - lodash style path, e.g. [0, 'translatedText'] or 'deep.path'
   * @param value
   */
  public setFavorite(
    uniqueId: string,
    accessor: LodashAccessor,
    value: string | number | ControlsDeviceSetting[]
  ) {
    // store a cloned copy (and thus immutable) of favorites to storage
    // ensureFavorites used as it is guaranteed to respond with a value
    const newFavoriteOptions = _cloneDeep(this.ensureFavorites(uniqueId));

    // update specific value
    _set(newFavoriteOptions, accessor, value);

    this.setFavorites(uniqueId, newFavoriteOptions);
  }

  /**
   * Reset sandbox back to original state, with no saved settings.
   */
  public resetSandbox(uniqueId: string) {
    // store a cloned copy (and thus immutable) of favorites to storage
    // ensureFavorites used as it is guaranteed to respond with a value
    const newFavoriteOptions = _cloneDeep(this.ensureFavorites(uniqueId));

    // update sandbox back to default with no saved settings
    newFavoriteOptions[SANDBOX_FAVORITE_INDEX] = createSandbox();

    this.setFavorites(uniqueId, newFavoriteOptions);
  }

  /**
   * Ensure an IT Managed favorite exists and is selected.
   * Called when video controls are changed from the cloud.
   */
  public ensureITManaged(uniqueId: string) {
    const newFavoriteOptions = _cloneDeep(this.ensureFavorites(uniqueId));
    const ITManagedIndex = newFavoriteOptions.findIndex(
      (favoriteOption: FavoriteOption) =>
        favoriteOption.hasOwnProperty("isITManaged") &&
        favoriteOption.isITManaged
    );

    // if IT Managed favorite does exist, set it as selected favorite
    if (ITManagedIndex !== -1) {
      this.setFavoriteIndex(uniqueId, ITManagedIndex);

      return;
    }

    // if IT Managed favorite doesn't exist yet, create it
    newFavoriteOptions.splice(IT_MANAGED_FAVORITE_INDEX, 0, createITManaged());
    this.setFavorites(uniqueId, newFavoriteOptions);
    this.setFavoriteIndex(uniqueId, IT_MANAGED_FAVORITE_INDEX);

    // increment default favorite index to be IT managed index plus 1
    this.defaultFavoriteIndex = IT_MANAGED_FAVORITE_INDEX + 1;
  }

  /**
   * Delete favorite by "value" from favoriteOptions.
   *
   * @return success: false or string of new favoriteOption value, e.g. 0
   */
  public deleteFavorite(
    uniqueId: string,
    index: string | number
  ): false | number {
    if (!this._favoriteOptions$.value?.[uniqueId]) {
      // unable to delete, no favorites set; this should never happen

      return false;
    }

    const newFavoriteOptions = _cloneDeep(
      this._favoriteOptions$.value[uniqueId]
    );

    const deleteIndex = newFavoriteOptions.findIndex(
      (favoriteOption, indexCheck) => indexCheck === +index
    );

    if (undefined === deleteIndex) {
      // this is most likely a code logic error
      this.logger.error(
        `Could not find favorite for device ${uniqueId} with index ${index}.`
      );

      return false;
    }

    newFavoriteOptions.splice(deleteIndex, 1);

    // reset to sandbox (first favorite option)
    const newIndexValue = 0;
    this.setFavoriteIndex(uniqueId, newIndexValue);
    this.setFavorites(uniqueId, newFavoriteOptions);

    return newIndexValue;
  }

  saveAsFavorite(uniqueId: string, index: string | number): false | number {
    if (!this._favoriteOptions$.value?.[uniqueId]) {
      // unable to duplicate, no favorites set; this should never happen

      return false;
    }

    if (!this._favoriteOptions$.value[uniqueId][+index]) {
      // index does not exist, this should not happen

      return false;
    }

    const newFavoriteOptions = _cloneDeep(
      this._favoriteOptions$.value[uniqueId]
    );

    // push copy of favorite onto end
    const favoriteCopy: FavoriteOption = _cloneDeep(newFavoriteOptions[index]);

    if (favoriteCopy.translatedText) {
      // e.g. "My Favorite" becomes "My Favorite copy"
      favoriteCopy.translatedText = this.translateService.parser.interpolate(
        this.translatedCopy,
        { favoriteName: favoriteCopy.translatedText }
      );
    }

    // increment copying a favorite that does not have a custom name (e.g. "Favorite 4")
    // and will find highest number used and increment it, e.g. "Favorite 5"
    const highestNumber = newFavoriteOptions.reduce(
      (acc, newFavoriteOption) => {
        // keep in mind, for sandbox, params.number won't exist
        // params are typed fairly open (string | number), so cast final result to a number
        return Math.max(acc, +(newFavoriteOption.params?.number ?? 0));
      },
      0
    );

    // generate new favorite; note user could be saving this favorite from sandbox,
    // so need full favorite text and default favorite settings
    newFavoriteOptions.push({
      ...favoriteCopy,
      ...createFavorite(highestNumber + 1),
    });

    // set to last option just added
    const newIndexValue = newFavoriteOptions.length - 1;

    this.setFavoriteIndex(uniqueId, newIndexValue);
    this.setFavorites(uniqueId, newFavoriteOptions);

    return newIndexValue;
  }

  /**
   * Take existing saved favorite settings and handle cases like:
   *
   * 1. setting has a new range (e.g. native layer change)
   * 2. setting is dropped (no longer supported or buggy setting removed)
   * 3. setting is added (e.g. now Lens Desktop supports "White Balance" for this device)
   */
  ensureFavoritesUsingCurrentSettings(
    uniqueId: string,
    currentFavoriteOptions: Array<FavoriteOption>,
    controlSettings: ControlsDeviceSetting[]
  ): Array<FavoriteOption> {
    if (!currentFavoriteOptions) {
      // this should not happen, but if it does, return empty array
      return [];
    }

    if (!controlSettings || !controlSettings.length) {
      // unable to perform any changes, no controlSettings provided
      return [];
    }

    // ensure all favoriteOptions have controlSettings
    const newFavoriteOptions = this.ensureSettingsOnFavoriteOptions(
      _cloneDeep(currentFavoriteOptions),
      controlSettings
    );

    newFavoriteOptions.forEach((favoriteOption) => {
      // all settings (existing controlSettings) and settings in each favorite should have
      // matching IDs; if they don't, that means a device update or new setting was enabled
      if (!favoriteOption?.settings) {
        // this should not happen based on call to ensureSettingsOnFavoriteOptions
        return;
      }

      // iterate over controlSettings
      controlSettings.forEach((setting) => {
        const idx = favoriteOption.settings.findIndex(
          (favoriteOptionSetting) => {
            return UtilityService.hexIsEqual(
              setting.id,
              favoriteOptionSetting.id
            );
          }
        );

        if (-1 === idx) {
          // setting not found in favoriteOption.settings, add a copy of it
          favoriteOption.settings.push(_cloneDeep(setting));

          return;
        }

        if (idx >= 0) {
          // setting found, check that options (array of [min, max, step]) are same
          if (
            !_isEqual(setting.options, favoriteOption.settings[idx].options)
          ) {
            // options have changed, update
            favoriteOption.settings[idx].options = _cloneDeep(setting.options);
          }

          return;
        }
      });

      // check that favoriteOption.settings does not have a setting that has been removed
      favoriteOption.settings.forEach(
        (favoriteOptionSetting, favoriteOptionIndex) => {
          const idx = controlSettings.findIndex((setting) => {
            return UtilityService.hexIsEqual(
              setting.id,
              favoriteOptionSetting.id
            );
          });

          if (-1 === idx) {
            // setting has been removed, remove it from saved setting
            favoriteOption.settings.splice(favoriteOptionIndex, 1);
          }
        }
      );
    });

    // if this function has changed options, update them appropriately
    if (!_isEqual(currentFavoriteOptions, newFavoriteOptions)) {
      this.setFavorites(uniqueId, newFavoriteOptions);

      return newFavoriteOptions;
    }

    return currentFavoriteOptions;
  }

  /**
   * Note as a singleton, this service may not be destroyed in typical
   * lifecycle of app. Including here as best practice.
   */
  ngOnDestroy() {
    this.subs.unsubscribe();
  }
}
