import { UtilityService } from "../services/utility.service";
import { Registry } from "rage-edit";
import { POLY_REGISTRY_PATH, POLY_REGISTRY_PATH_ALL_USERS } from "./constants";

export async function getRegistryValue(key?: string): Promise<any> {
  if (UtilityService.isWindows()) {
    const polyRegPathValue = await Registry.get(
      POLY_REGISTRY_PATH_ALL_USERS,
      key
    );
    if (polyRegPathValue) {
      return polyRegPathValue;
    } else {
      return Registry.get(POLY_REGISTRY_PATH, key);
    }
  } else {
    return Promise.resolve("");
  }
}
