import { Component, Input, OnInit } from "@angular/core";
import { BluetoothInfo } from "@poly/hub-native";
import { AccordionItem } from "../../shared/components/accordion/accordion.component";
import { BluetoothService } from "../../services/bluetooth.service";
import { OzDevice } from "../../services/device-manager.service";

@Component({
  selector: "oz-device-bluetooth",
  templateUrl: "./device-settings-bluetooth-item.component.pug",
})
export class DeviceSettingsBluetoothItemComponent implements OnInit {
  @Input() set accordionData(newValue: AccordionItem) {
    this.device = newValue.data.device;
  }

  device: OzDevice;

  bluetoothEnabled = false;
  bluetoothAutoconnect = false;
  remoteControlConnection = false;

  constructor(private bluetoothService: BluetoothService) {}

  ngOnInit() {
    this.bluetoothService.bluetoothStatus$.subscribe(
      (bluetoothStatus: BluetoothInfo) => {
        if (bluetoothStatus && bluetoothStatus?.status === "OK") {
          this.bluetoothEnabled = bluetoothStatus.enable;
          this.bluetoothAutoconnect = bluetoothStatus.auto_connect;
          this.remoteControlConnection = bluetoothStatus.rcc_enable;
        }
      }
    );
    this.bluetoothService.getBluetoothStatus(this.device?.id);
  }

  generateBluetoothRequest() {
    const request = {
      deviceId: this.device?.id,
      name: this.device.name,
      enable: this.bluetoothEnabled,
      auto_connect: this.bluetoothAutoconnect,
      rcc_enable: this.remoteControlConnection,
    };
    return request;
  }

  onBluetoothEnableChanged(value: boolean) {
    const request = { ...this.generateBluetoothRequest(), enable: value };
    this.bluetoothService.setBluetoothSettings(request);
  }

  onAutoconnectChanged(value: boolean) {
    const request = { ...this.generateBluetoothRequest(), auto_connect: value };
    this.bluetoothService.setBluetoothSettings(request);
  }

  onRemoteControlConnectionChanged(value: boolean) {
    const request = { ...this.generateBluetoothRequest(), rcc_enable: value };
    this.bluetoothService.setBluetoothSettings(request);
  }
}
