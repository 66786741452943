import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { FormsModule } from "@angular/forms";
import { HttpClient, HttpClientModule } from "@angular/common/http";
import { RouterModule } from "@angular/router";
import { RouterTestingModule } from "@angular/router/testing";
import { SharedModule } from "./shared/shared.module";
// import { HighchartsChartModule } from "highcharts-angular";
import { HttpLoaderFactory } from "./factories/http-loader.factory";
import { LottieModule } from "ngx-lottie";
import { TranslateLoader } from "@ngx-translate/core";
import { TranslateModule } from "@ngx-translate/core";
import {
  playerFactory,
  mockPlayerFactory,
} from "./factories/lottie-player.factory";
import { routes } from "./app-routing.module";

export const COMMON_MODULES = [
  BrowserAnimationsModule,
  BrowserModule,
  FormsModule,
  // HighchartsChartModule,
  HttpClientModule,
  SharedModule,
  TranslateModule.forRoot(),
];

export const COMMON_APP_MODULES = [
  ...COMMON_MODULES,
  LottieModule.forRoot({ player: playerFactory }),
  RouterModule.forRoot(routes, { useHash: true }),
  TranslateModule.forRoot({
    defaultLanguage: "en",
    loader: {
      provide: TranslateLoader,
      useFactory: HttpLoaderFactory,
      deps: [HttpClient],
    },
  }),
];

export const COMMON_TESTING_MODULES = [
  ...COMMON_MODULES,
  RouterTestingModule,
  TranslateModule.forRoot(),
  LottieModule.forRoot({ player: mockPlayerFactory }),
];
