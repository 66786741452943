import { Subscription } from "rxjs";

/**
 * Keeps array list of RxJs subscriptions and allows bulk unsubscribe.
 */
export class Subscriptions {
  /**
   * List of subscriptions.
   */
  private subscriptions: Subscription[] = [];

  /**
   * Adds provided subscription to the list.
   *
   * @param subscription RxJs subscription object.
   */
  add(subscription: Subscription) {
    this.subscriptions.push(subscription);
  }

  /**
   * Unsubscribe all subscriptions.
   */
  unsubscribe() {
    for (const subscription of this.subscriptions) {
      if (subscription && "function" === typeof subscription.unsubscribe) {
        subscription.unsubscribe();
      }
    }

    this.subscriptions = [];
  }
}
