import { Component, OnInit } from "@angular/core";
import { UtilityService } from "../../services/utility.service";

@Component({
  selector: "oz-desk-setup",
  templateUrl: "./desk-setup.component.pug",
})
export class DeskSetupComponent implements OnInit {
  cards = [
    {
      title: "DESK_SETUP.DESK.TITLE",
      description: "DESK_SETUP.DESK.DESCRIPTION",
      logo: "desk_setup_desk",
      id: "workstation",
    },
    {
      title: "DESK_SETUP.KEYBOARD.TITLE",
      description: "DESK_SETUP.KEYBOARD.DESCRIPTION",
      logo: "desk_setup_keyboard",
      id: "keyboard",
    },
    {
      title: "DESK_SETUP.CHAIR.TITLE",
      description: "DESK_SETUP.CHAIR.DESCRIPTION",
      logo: "desk_setup_chair",
      id: "chair",
    },
    {
      title: "DESK_SETUP.FEET.TITLE",
      description: "DESK_SETUP.FEET.DESCRIPTION",
      logo: "desk_setup_feet",
      id: "feet",
    },
    {
      title: "DESK_SETUP.MONITOR.TITLE",
      description: "DESK_SETUP.MONITOR.DESCRIPTION",
      logo: "desk_setup_monitor",
      id: "monitor",
    },
  ];

  constructor() {}

  ngOnInit(): void {
    UtilityService.scrollToTop("scrollable-content");
  }
}
