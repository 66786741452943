import {
  DEVICE_SETTING_ID_ACTIVE_CALL_AUDIO,
  DEVICE_SETTING_ID_ANC_MODE,
  DEVICE_SETTING_ID_ANSWERING_CALL,
  DEVICE_SETTING_ID_ANSWERING_CALL_ALERT,
  DEVICE_SETTING_ID_ANSWER_IGNORE,
  DEVICE_SETTING_ID_ANSWER_IGNORE_2,
  DEVICE_SETTING_ID_ANTI_STARTLE,
  DEVICE_SETTING_ID_ANTI_STARTLE_2,
  DEVICE_SETTING_ID_AUDIO_CHANNEL_TONE,
  DEVICE_SETTING_ID_AUTO_ANSWER,
  DEVICE_SETTING_ID_AUTO_PAUSE_MUSIC,
  DEVICE_SETTING_ID_CALLED_ID,
  DEVICE_SETTING_ID_CALLS_ANNOUNCEMENT,
  DEVICE_SETTING_ID_CLEAR_TRUSTED_DEVICES,
  DEVICE_SETTING_ID_CONNECTION_INDICATION,
  DEVICE_SETTING_ID_DIAL_TONE,
  DEVICE_SETTING_ID_EQIALIZER,
  DEVICE_SETTING_ID_EXTENDED_RANGE_MODE,
  DEVICE_SETTING_ID_G616,
  DEVICE_SETTING_ID_HD_VOICE,
  DEVICE_SETTING_ID_HOLD_REMINDER,
  DEVICE_SETTING_ID_HOURS_ON_PHONE_PER_DAY,
  DEVICE_SETTING_ID_INCOMMING_CALL,
  DEVICE_SETTING_ID_INCOMMING_CALL_ALERT,
  DEVICE_SETTING_ID_INDEPENDENT_VOLUME_CONTROL,
  DEVICE_SETTING_ID_MANAGE_ALL,
  DEVICE_SETTING_ID_MOBILE_PHONE,
  DEVICE_SETTING_ID_MUTE_ALERTS,
  DEVICE_SETTING_ID_MUTE_ALERT_TYPES,
  DEVICE_SETTING_ID_MUTE_REMINDER_MODE,
  DEVICE_SETTING_ID_MUTE_REMINDER_TIMING,
  DEVICE_SETTING_ID_NOISE_EXPOSURE,
  DEVICE_SETTING_ID_NOTIFICATION_TONES,
  DEVICE_SETTING_ID_ONLINE_INDICATOR,
  DEVICE_SETTING_ID_PAIRING_MODE,
  DEVICE_SETTING_ID_PC,
  DEVICE_SETTING_ID_PC_AUDIO_PERSISTENCE_MODE,
  DEVICE_SETTING_ID_RESTORE_DEFAULTS,
  DEVICE_SETTING_ID_RINGTONE,
  DEVICE_SETTING_ID_ROCKET_BUTTON_BEHAVIOR,
  DEVICE_SETTING_ID_SECOND_INCOMMING_CALL,
  DEVICE_SETTING_ID_SIDETONE,
  DEVICE_SETTING_ID_STREAMING_AUDIO,
  DEVICE_SETTING_ID_TRANSPARENCY_MODE,
  DEVICE_SETTING_ID_VOLUME_LEVEL_TONES,
  DEVICE_SETTING_ID_VOLUME_MIN_MAX_ALERTS,
  DEVICE_SETTING_ID_WEARING_SENSOR_MASTER,
  SETTINGS, DEVICE_SETTING_ID_AUTO_ANSWER_NO_SENSOR
} from "../../app/utils/constants";


export enum DEV_SETTING_ID {
  SECOND_INCOMING_CALL = parseInt(DEVICE_SETTING_ID_SECOND_INCOMMING_CALL),
  PC = parseInt(DEVICE_SETTING_ID_PC),
  MOBILE_PHONE = parseInt(DEVICE_SETTING_ID_MOBILE_PHONE),
  RINGTONE = parseInt(DEVICE_SETTING_ID_RINGTONE),
  WEARING_SENSOR_MASTER = parseInt(DEVICE_SETTING_ID_WEARING_SENSOR_MASTER),
  AUTO_ANSWER = parseInt(DEVICE_SETTING_ID_AUTO_ANSWER),
  AUTO_PAUSE_MUSIC = parseInt(DEVICE_SETTING_ID_AUTO_PAUSE_MUSIC),
  ACTIVE_CALL_AUDIO = parseInt(DEVICE_SETTING_ID_ACTIVE_CALL_AUDIO),
  AUTO_ANSWER_NO_SENSOR = parseInt(DEVICE_SETTING_ID_AUTO_ANSWER_NO_SENSOR),
  NOISE_EXPOSURE = parseInt(DEVICE_SETTING_ID_NOISE_EXPOSURE),
  HOURS_ON_PHONE_PER_DAY = parseInt(DEVICE_SETTING_ID_HOURS_ON_PHONE_PER_DAY),
  ANTI_STARTLE = parseInt(DEVICE_SETTING_ID_ANTI_STARTLE),
  G616 = parseInt(DEVICE_SETTING_ID_G616),
  ANSWER_IGNORE = parseInt(DEVICE_SETTING_ID_ANSWER_IGNORE),
  ANSWERING_CALL_ALERT = parseInt(DEVICE_SETTING_ID_ANSWERING_CALL_ALERT),
  MUTE_REMINDER_TIMING = parseInt(DEVICE_SETTING_ID_MUTE_REMINDER_TIMING),
  MUTE_ALERT_TYPES = parseInt(DEVICE_SETTING_ID_MUTE_ALERT_TYPES),
  VOLUME_LEVEL_TONES = parseInt(DEVICE_SETTING_ID_VOLUME_LEVEL_TONES),
  CALLER_ID = parseInt(DEVICE_SETTING_ID_CALLED_ID),
  AUDIO_CHANNEL_TONE = parseInt(DEVICE_SETTING_ID_AUDIO_CHANNEL_TONE),
  MUTE_REMINDER_MODE = parseInt(DEVICE_SETTING_ID_MUTE_REMINDER_MODE),
  CONNECTION_INDICATION = parseInt(DEVICE_SETTING_ID_CONNECTION_INDICATION),
  MUTE_ALERTS = parseInt(DEVICE_SETTING_ID_MUTE_ALERTS),
  STREAMING_AUDIO = parseInt(DEVICE_SETTING_ID_STREAMING_AUDIO),
  EXTENDED_RANGE_MODE = parseInt(DEVICE_SETTING_ID_EXTENDED_RANGE_MODE),
  HD_VOICE = parseInt(DEVICE_SETTING_ID_HD_VOICE),
  PAIRING_MODE = parseInt(DEVICE_SETTING_ID_PAIRING_MODE),
  SIDETONE = parseInt(DEVICE_SETTING_ID_SIDETONE),
  NOTIFICATION_TONES = parseInt(DEVICE_SETTING_ID_NOTIFICATION_TONES),
  INDEPENDENT_VOLUME_CONTROL = parseInt(DEVICE_SETTING_ID_INDEPENDENT_VOLUME_CONTROL),
  EQUALIZER = parseInt(DEVICE_SETTING_ID_EQIALIZER),
  ANC_MODE = parseInt(DEVICE_SETTING_ID_ANC_MODE),
  CLEAR_TRUSTED_DEVICES = parseInt(DEVICE_SETTING_ID_CLEAR_TRUSTED_DEVICES),
  ONLINE_INDICATOR = parseInt(DEVICE_SETTING_ID_ONLINE_INDICATOR),
  ROCKET_BUTTON_BEHAVIOR = parseInt(DEVICE_SETTING_ID_ROCKET_BUTTON_BEHAVIOR),
  RESTORE_DEFAULTS = parseInt(DEVICE_SETTING_ID_RESTORE_DEFAULTS),
  PC_AUDIO_PERSISTENCE_MODE = parseInt(DEVICE_SETTING_ID_PC_AUDIO_PERSISTENCE_MODE),
  HOLD_REMINDER = parseInt(DEVICE_SETTING_ID_HOLD_REMINDER),
  DIAL_TONE = parseInt(DEVICE_SETTING_ID_DIAL_TONE),
  INCOMING_CALL_ALERT = parseInt(DEVICE_SETTING_ID_INCOMMING_CALL_ALERT),
  VOLUME_MIN_MAX_ALERTS = parseInt(DEVICE_SETTING_ID_VOLUME_MIN_MAX_ALERTS),
  BRIGHTNESS = parseInt(SETTINGS.BRIGHTNESS),
  CONTRAST = parseInt(SETTINGS.CONTRAST),
  SATURATION = parseInt(SETTINGS.SATURATION),
  SHARPNESS = parseInt(SETTINGS.SHARPNESS),
  WHITE_BALANCE = parseInt(SETTINGS.WHITE_BALANCE),
  PAN = parseInt(SETTINGS.PAN),
  TILT = parseInt(SETTINGS.TILT),
  ZOOM = parseInt(SETTINGS.ZOOM),
  MANAGE_ALL = parseInt(DEVICE_SETTING_ID_MANAGE_ALL),
  CALLS_ANNOUNCEMENT = parseInt(DEVICE_SETTING_ID_CALLS_ANNOUNCEMENT),
  INCOMING_CALL = parseInt(DEVICE_SETTING_ID_INCOMMING_CALL),
  ANSWERING_CALL = parseInt(DEVICE_SETTING_ID_ANSWERING_CALL),
  ANSWER_IGNORE_2 = parseInt(DEVICE_SETTING_ID_ANSWER_IGNORE_2),
  TRANSPARENCY_MODE = parseInt(DEVICE_SETTING_ID_TRANSPARENCY_MODE),
  MOBILE_RINGTONE_MODE = 0xFF31,
  VOIP_RINGTONE_MODE = 0xFF32,
  ANTI_STARTLE_2 = parseInt(DEVICE_SETTING_ID_ANTI_STARTLE_2),
}

// Deckard messages (commands, settings and events)
export const enum DECKARD {
  AUTO_ANSWER = 0x204,
  AUTO_ANSWER_SETTING = 0x206,
  AUTO_PAUSE = 0x208,
  AUTO_PAUSE_SETTING = 0x20A,
  WEARING_SENSOR_MODE_SETTING = 0x214,   // setting ID is diff from cmd and event ones
  WEARING_SENSOR_MODE = 0x216,
  ACTIVE_CALL_AUDIO = 0x21B,
  ANC_MODE = 0x315,
  USER_NOTIFICATION_BITMASKS = 0x3AD,
  VOLUME_TONE_BEHAVIOR = 0x403,
  SECOND_INBOUND_CALL_RING_TYPE = 0x404,
  MUTE_TONE_BEHAVIOR = 0x405,
  SECOND_INBOUND_CALL_RING_TYPE_SETTING = 0x406,   // setting ID is diff from cmd and event ones
  SCO_TONE_MODE = 0x408,
  OLI_FEATURE_MODE = 0x409,
  MUTE_ALERT_MODE = 0x40A,
  MUTE_ONOFF_TRANSITION_MODE = 0x40B,
  ANSWERING_CALL_VOICE_PROMPT_MODE = 0x40C,
  SIDE_TONE_LEVEL = 0x410,
  NOTIFICATION_TONES_MODE = 0x411,
  DEV_CONNECTIVITY_PROMPT_MODE = 0x412,
  CALL_ANNOUNCEMENT_PROMPT_MODE = 0x413,
  INCOMING_CALL_PROMPT = 0x417,
  VOLUME_TONE_MINMAX = 0x418,
  INDEPENDENT_VOLUME_CONTROL = 0x419,
  CUSTOM_BUTTON = 0x41A,
  TRUSTED_DEV_LIST_GETS_CLEARED = 0x41F,
  MUTE_UNMUTE_PROMPT_SWITCH = 0x423,
  PC_AUDIO_PERSISTENCE_MODE = 0x428,
  HOLD_TONE_MODE = 0x42A,
  UNKNOWN_42C = 0x42C,   // not described in Deckard PDF
  CALLER_ANNOUNCEMENT_MODE = 0x804,
  PRODUCT_NAME = 0x0A00,
  TATTOO_SERIAL_NO = 0x0A01,
  USB_PID = 0x0A02,
  TATTOO_BUILD_CODE = 0x0A03,
  FIRMWARE_VER = 0x0A04,
  PART_NO = 0x0A05,
  A2DP_MODE = 0xA0C,
  A2DP_MODE_SETTING = 0xA0E,
  MODEL_ID = 0x0A11,
  BATTERY_INFO = 0xA1A,
  BATTERY_STATUS = 0xA1C,
  SET_ID = 0x0A1D,
  GENES_GUID = 0x0A1E,
  MUTE_REMINDER_TIMING = 0x0A20,
  MUTE_REMINDER_TIMING_SETTING = 0x0A22,   // setting ID is diff from cmd and event ones
  PAIRING_MODE = 0x0A24,
  PAIRING_MODE_SETTING = 0x0A26,   // setting ID is diff from cmd and event ones
  SPOKEN_ANSWER_IGNORE = 0xA2E,
  SPOKEN_ANSWER_IGNORE_SETTING = 0xA30,   // setting ID is diff from cmd and event ones
  LYNC_DIALTONE_ON_CALL_PRESS_MODE = 0xA32,
  LYNC_DIALTONE_ON_CALL_PRESS_MODE_SETTING = 0xA34,   // setting ID is diff from cmd and event ones
  BLUETOOTH_ADDRESS = 0xA40,
  BLUETOOTH_CONN_INFO = 0xA42,
  VENDOR_ID = 0x0A52,
  HARDWARE_REVISION = 0xA53,
  CONNECTED_DEVICE = 0xC00,
  DISCONNECTED_DEVICE = 0xC02,
  MIC_MUTE_STATE = 0x0E01,
  HEADSET_AVAILABLE= 0xE09,
  LANGUAGE = 0xE1A,
  ANTISTARTLE_MODE = 0xF0A,
  G616_MODE = 0xF0C,
  TIME_WEGHTED_AVG_VALUE = 0xF0E,
  TIME_WEGHTED_AVG_PERIOD = 0xF10,
  RESTORE_DEFAULTS = 0xF13,
  MOBILE_INTERFACE_RINGTONE = 0xF15,
  VOIP_INTERFACE_RINGTONE = 0xF16,
  MOBILE_INTERFACE_AUDIO_BW = 0xF18,
  AUTO_ANSWER_NO_SENSOR = 0xF1E,
  VOIP_MAX_AUDIO_CHANNELS = 0xF3A,
  VOIP_RINGTONE_MODE = 0xF3B,
  MOBILE_RINGTONE_MODE = 0xF3C,
  DSP_MUSIC_EQ = 0xF45,
  BATTERY_SOC_REPORT = 0xF58,
}

export interface DeviceSettingDef {
  id: DEV_SETTING_ID;
  values: Array<string>;
  deckard?: DeckardSettingDef;
  virtual?: VirtualSettingDef;
  uvc?: UvcSettingDef;
}


interface DeckardSettingDef {
  id?: DECKARD;
  type?: "none" | "uint8" | "uint16";
  values: Array<number>;
  bit?: BitDef;
  cmd?: DECKARD;
  get?: DECKARD;
  event?: DECKARD;
}

export interface BitDef {
  bit: number;
  size: number;
  order: "bitmask_1st" | "value_1st";
}

interface SubsettingDef {
  id: DEV_SETTING_ID;
  values: Array<string | undefined>;
}

interface VirtualSettingDef {
  subsettings: Array<SubsettingDef>;
}

interface UvcSettingDef {
  name: string;
}


// sorted by id
const devSettings: DeviceSettingDef[] = [
  { id: DEV_SETTING_ID.SECOND_INCOMING_CALL,
    values: ["ignore", "once", "continuous"],
    deckard: { id: DECKARD.SECOND_INBOUND_CALL_RING_TYPE, type: "uint8", values: [0, 1, 2],
      get: DECKARD.SECOND_INBOUND_CALL_RING_TYPE_SETTING }
  },
  { id: DEV_SETTING_ID.PC,
    values: ["sound1", "sound2", "sound3", "off"],
    deckard: { id: DECKARD.VOIP_INTERFACE_RINGTONE, type: "uint8", values: [0, 1, 2, 3] }
  },
  { id: DEV_SETTING_ID.MOBILE_PHONE,
    values: ["sound1", "sound2", "sound3", "off"],
    deckard: { id: DECKARD.MOBILE_INTERFACE_RINGTONE, type: "uint8", values: [0, 1, 2, 3] }
  },
  { id: DEV_SETTING_ID.RINGTONE,
    values: ["false", "true"],
    virtual: { subsettings: [
      { id: DEV_SETTING_ID.MOBILE_RINGTONE_MODE, values: [ "false", "true" ] },
      { id: DEV_SETTING_ID.VOIP_RINGTONE_MODE, values: [ "false", "true" ] },
    ] }
  },
  { id: DEV_SETTING_ID.WEARING_SENSOR_MASTER,
    values: ["false", "true"],
    deckard: { id: DECKARD.WEARING_SENSOR_MODE, type: "uint8", values: [0, 1],
      get: DECKARD.WEARING_SENSOR_MODE_SETTING }
  },
  { id: DEV_SETTING_ID.AUTO_ANSWER,
    values: ["false", "true"],
    deckard: { id: DECKARD.AUTO_ANSWER, type: "uint8", values: [0, 1],
      get: DECKARD.AUTO_ANSWER_SETTING }
  },
  { id: DEV_SETTING_ID.AUTO_PAUSE_MUSIC,
    values: ["false", "true"],
    deckard: { id: DECKARD.AUTO_PAUSE, type: "uint8", values: [0, 1],
      get: DECKARD.AUTO_PAUSE_SETTING }
  },
  { id: DEV_SETTING_ID.ACTIVE_CALL_AUDIO,
    values: ["doNothing", "transferAudioToMobile", "muteMic"],
    deckard: { id: DECKARD.ACTIVE_CALL_AUDIO, type: "uint8", values: [0, 1, 2] }
  },
  { id: DEV_SETTING_ID.AUTO_ANSWER_NO_SENSOR,
    values: ["false", "true"],
    deckard: { id: DECKARD.AUTO_ANSWER_NO_SENSOR, type: "uint8", values: [0, 1] }
  },
  { id: DEV_SETTING_ID.NOISE_EXPOSURE,
    values: ["off", "85db", "80db"],
    deckard: { id: DECKARD.TIME_WEGHTED_AVG_VALUE, type: "uint8", values: [0, 1, 2] }
  },
  { id: DEV_SETTING_ID.HOURS_ON_PHONE_PER_DAY,
    values: ["2", "4", "6", "8"],
    deckard: { id: DECKARD.TIME_WEGHTED_AVG_PERIOD, type: "uint8", values: [1, 2, 3, 4] }
  },
  { id: DEV_SETTING_ID.ANTI_STARTLE,
    values: ["false", "true"],
    deckard: { id: DECKARD.ANTISTARTLE_MODE, type: "uint8", values: [0, 1] }
  },
  { id: DEV_SETTING_ID.G616,
    values: ["false", "true"],
    deckard: { id: DECKARD.G616_MODE, type: "uint8", values: [0, 1] }
  },
  { id: DEV_SETTING_ID.ANSWER_IGNORE,
    values: ["false", "true"],
    deckard: { id: DECKARD.SPOKEN_ANSWER_IGNORE, type: "uint8", values: [0, 1],
      get: DECKARD.SPOKEN_ANSWER_IGNORE_SETTING }
  },
  { id: DEV_SETTING_ID.ANSWERING_CALL_ALERT,
    values: ["false", "true"],
    deckard: { id: DECKARD.ANSWERING_CALL_VOICE_PROMPT_MODE, type: "uint8", values: [0, 1] }
  },
  { id: DEV_SETTING_ID.MUTE_REMINDER_TIMING,
    values: ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "12", "13", "14", "15"],
    deckard: { id: DECKARD.MUTE_REMINDER_TIMING, type: "uint16", values: [60, 120, 180, 240, 300, 360, 420, 480, 540, 600, 660, 720, 780, 840, 900],
      get: DECKARD.MUTE_REMINDER_TIMING_SETTING }
  },
  { id: DEV_SETTING_ID.MUTE_ALERT_TYPES,
    values: ["voice", "doubleTone", "singleTone"],
    deckard: { id: DECKARD.MUTE_ONOFF_TRANSITION_MODE, type: "uint8", values: [0, 1, 2] }
  },
  { id: DEV_SETTING_ID.VOLUME_LEVEL_TONES,
    values: ["atEveryLevel", "minMaxOnly"],
    deckard: { id: DECKARD.VOLUME_TONE_BEHAVIOR, type: "uint8", values: [1, 2] }
  },
  { id: DEV_SETTING_ID.CALLER_ID,
    values: ["false", "true"],
    deckard: { id: DECKARD.CALLER_ANNOUNCEMENT_MODE, type: "uint8", values: [0, 0xff] }
  },
  { id: DEV_SETTING_ID.AUDIO_CHANNEL_TONE,
    values: ["false", "true"],
    deckard: { id: DECKARD.SCO_TONE_MODE, type: "uint8", values: [0, 1] }
  },
  { id: DEV_SETTING_ID.MUTE_REMINDER_MODE,
    values: ["off", "timed", "voiceVisibleAndAudible", "voiceVisible", "voiceAudible"],
    deckard: { id: DECKARD.MUTE_ALERT_MODE, type: "uint16", values: [0x000, 0x100, 0x200, 0x201, 0x202] }
  },
  { id: DEV_SETTING_ID.CONNECTION_INDICATION,
    values: ["voice", "tone"],
    deckard: { id: DECKARD.DEV_CONNECTIVITY_PROMPT_MODE, type: "uint8", values: [2, 3] }
  },
  { id: DEV_SETTING_ID.MUTE_ALERTS,
    values: ["false", "true"],
    deckard: { id: DECKARD.MUTE_UNMUTE_PROMPT_SWITCH, type: "uint8", values: [0, 1] }
  },
  { id: DEV_SETTING_ID.STREAMING_AUDIO,
    values: ["false", "true"],
    deckard: { id: DECKARD.A2DP_MODE, type: "uint8", values: [0, 1],
      get: DECKARD.A2DP_MODE_SETTING }
  },
  { id: DEV_SETTING_ID.EXTENDED_RANGE_MODE,
    values: ["false", "true"],
    deckard: { id: DECKARD.VOIP_MAX_AUDIO_CHANNELS, type: "uint8", values: [2, 1] }
  },
  { id: DEV_SETTING_ID.HD_VOICE,
    values: ["narrowband", "wideband"],
    deckard: { id: DECKARD.MOBILE_INTERFACE_AUDIO_BW, type: "uint8", values: [1, 2] }
  },
  { id: DEV_SETTING_ID.PAIRING_MODE,
    values: ["false", "true"],
    deckard: { id: DECKARD.PAIRING_MODE, type: "uint8", values: [0, 1],
      get: DECKARD.PAIRING_MODE_SETTING }
  },
  { id: DEV_SETTING_ID.SIDETONE,
    values: ["low", "medium", "high"],
    deckard: { id: DECKARD.SIDE_TONE_LEVEL, type: "uint8", values: [0, 1, 3] }
  },
  { id: DEV_SETTING_ID.NOTIFICATION_TONES,
    values: ["false", "true"],
    deckard: { id: DECKARD.NOTIFICATION_TONES_MODE, type: "uint8", values: [0, 1] }
  },
  { id: DEV_SETTING_ID.INDEPENDENT_VOLUME_CONTROL,
    values: ["false", "true"],
    deckard: { id: DECKARD.INDEPENDENT_VOLUME_CONTROL, type: "uint8", values: [0, 1] }
  },
  { id: DEV_SETTING_ID.EQUALIZER,
    values: ["bass", "flat", "bright"],
    deckard: { id: DECKARD.DSP_MUSIC_EQ, type: "uint8", values: [0, 1, 2] }
  },
  { id: DEV_SETTING_ID.ANC_MODE,
    values: ["off", "adaptive", "standard"],
    deckard: { id: DECKARD.ANC_MODE, type: "uint8", values: [0, 4, 5] }
  },
  { id: DEV_SETTING_ID.CLEAR_TRUSTED_DEVICES,
    values: ["false", "true"],
    deckard: { id: DECKARD.TRUSTED_DEV_LIST_GETS_CLEARED, type: "uint8", values: [0, 1] }
  },
  { id: DEV_SETTING_ID.ONLINE_INDICATOR,
    values: ["false", "true"],
    deckard: { id: DECKARD.OLI_FEATURE_MODE, type: "uint8", values: [0, 1] }
  },
  { id: DEV_SETTING_ID.ROCKET_BUTTON_BEHAVIOR,
    values: ["playPause", "redial" , "vpa", "clearTrustedDevice", "statusCheck", "holdResume"],
    deckard: { id: DECKARD.CUSTOM_BUTTON, type: "uint8", values: [0, 1, 2, 3, 4, 5] }
  },
  { id: DEV_SETTING_ID.RESTORE_DEFAULTS,
    values: [],
    deckard: { type: "none", values: [], cmd: DECKARD.RESTORE_DEFAULTS }
  },
  { id: DEV_SETTING_ID.PC_AUDIO_PERSISTENCE_MODE,
    values: ["false", "true"],
    deckard: { id: DECKARD.PC_AUDIO_PERSISTENCE_MODE, type: "uint8", values: [0, 1] }
  },
  { id: DEV_SETTING_ID.HOLD_REMINDER,
    values: ["false", "true"],
    deckard: { id: DECKARD.HOLD_TONE_MODE, type: "uint8", values: [0, 1] }
  },
  /* TWS - comment for Phase 1 since Softphones not supported yet
  { id: DEV_SETTING_ID.DIAL_TONE,
    values: ["false", "true"],
    deckard: { id: DECKARD.LYNC_DIALTONE_ON_CALL_PRESS_MODE, type: "uint8", values: [0, 1],
      get: DECKARD.LYNC_DIALTONE_ON_CALL_PRESS_MODE_SETTING }
  },
   */
  { id: DEV_SETTING_ID.INCOMING_CALL_ALERT,
    values: ["false", "true"],
    deckard: { id: DECKARD.INCOMING_CALL_PROMPT, type: "uint8", values: [0, 1] }
  },
  { id: DEV_SETTING_ID.VOLUME_MIN_MAX_ALERTS,
    values: ["tone", "voice"],
    deckard: { id: DECKARD.VOLUME_TONE_MINMAX, type: "uint8", values: [0, 1] }
  },
  { id: DEV_SETTING_ID.TRANSPARENCY_MODE,
    values: ["off", "environment", "speech"],
    deckard: { id: DECKARD.UNKNOWN_42C, type: "uint8", values: [0, 1, 2] }
  },
  { id: DEV_SETTING_ID.MOBILE_RINGTONE_MODE,
    values: ["false", "true"],
    deckard: { id: DECKARD.MOBILE_RINGTONE_MODE, type: "uint8", values: [0, 1] }
  },
  { id: DEV_SETTING_ID.VOIP_RINGTONE_MODE,
    values: ["false", "true"],
    deckard: { id: DECKARD.VOIP_RINGTONE_MODE, type: "uint8", values: [0, 1] }
  },
  { id: DEV_SETTING_ID.ANTI_STARTLE_2,
    values: ["off", "standard", "enhanced"],
    virtual: { subsettings: [
      { id: DEV_SETTING_ID.G616, values: [ "false", "true", "true" ] },
      { id: DEV_SETTING_ID.ANTI_STARTLE, values: [ "false", "false", "true" ] },
    ] }
  },

  // UVC
  { id: DEV_SETTING_ID.BRIGHTNESS, values: [], uvc: { name: 'brightness' }},
  { id: DEV_SETTING_ID.CONTRAST, values: [], uvc: { name: 'contrast' }},
  { id: DEV_SETTING_ID.SATURATION, values: [], uvc: { name: 'saturation' }},
  { id: DEV_SETTING_ID.SHARPNESS, values: [], uvc: { name: 'sharpness' }},
  { id: DEV_SETTING_ID.WHITE_BALANCE, values: [], uvc: { name: 'whiteBalance' }},
  { id: DEV_SETTING_ID.PAN, values: [], uvc: { name: 'pan' }},
  { id: DEV_SETTING_ID.TILT, values: [], uvc: { name: 'tilt' }},
  { id: DEV_SETTING_ID.ZOOM, values: [], uvc: { name: 'zoom' }},
]


function disable(id: DEV_SETTING_ID): DeviceSettingDef {
  return { id, values: [] }
}

const override40B_w_405: DeviceSettingDef = {
  id: DEV_SETTING_ID.MUTE_ALERT_TYPES,
  values: ["doubleTone", "singleTone"],
  deckard: { id: DECKARD.MUTE_TONE_BEHAVIOR, type: "uint8", values: [1, 2] }
}
const timed_mute_reminder_only: DeviceSettingDef = {
  id: DEV_SETTING_ID.MUTE_REMINDER_MODE,
  values: ["off", "timed"],
  deckard: { id: DECKARD.MUTE_ALERT_MODE, type: "uint16", values: [0x000, 0x100] }
}
const audio_tone_2: DeviceSettingDef = { id: DEV_SETTING_ID.AUDIO_CHANNEL_TONE,
  values: ["false", "true"],
  deckard: { id: DECKARD.USER_NOTIFICATION_BITMASKS, values: [0, 1], bit: { bit: 83, size: 12, order: "bitmask_1st" } }
}
const hold_reminder_2: DeviceSettingDef = { id: DEV_SETTING_ID.HOLD_REMINDER,
  values: ["false", "true"],
  deckard: { id: DECKARD.USER_NOTIFICATION_BITMASKS, values: [0, 1], bit: { bit: 85, size: 12, order: "bitmask_1st" } }
}
const manage_all: DeviceSettingDef = { id: DEV_SETTING_ID.MANAGE_ALL,
  values: ["false", "true"],
  deckard: { id: DECKARD.USER_NOTIFICATION_BITMASKS, values: [0, 1], bit: { bit: 33, size: 12, order: "bitmask_1st" } }
}
const call_announcements: DeviceSettingDef = { id: DEV_SETTING_ID.CALLS_ANNOUNCEMENT,
  values: ["false", "true"],
  deckard: { id: DECKARD.USER_NOTIFICATION_BITMASKS, values: [0, 1], bit: { bit: 34, size: 12, order: "bitmask_1st" } }
}
const incoming_call_2: DeviceSettingDef = { id: DEV_SETTING_ID.INCOMING_CALL,
  values: ["false", "true"],
  deckard: { id: DECKARD.USER_NOTIFICATION_BITMASKS, values: [0, 1], bit: { bit: 40, size: 12, order: "bitmask_1st" } }
}
const answering_call_2: DeviceSettingDef = { id: DEV_SETTING_ID.ANSWERING_CALL,
  values: ["false", "true"],
  deckard: { id: DECKARD.USER_NOTIFICATION_BITMASKS, values: [0, 1], bit: { bit: 42, size: 12, order: "bitmask_1st" } }
}
const answer_ignore_2: DeviceSettingDef = { id: DEV_SETTING_ID.ANSWER_IGNORE_2,
  values: ["false", "true"],
  deckard: { id: DECKARD.USER_NOTIFICATION_BITMASKS, values: [0, 1], bit: { bit: 38, size: 12, order: "bitmask_1st" } }
}
const rocket_button_2: DeviceSettingDef = { id: DEV_SETTING_ID.ROCKET_BUTTON_BEHAVIOR,
  values: ["anc", "statusCheck", "nothing"],
  deckard: { id: DECKARD.CUSTOM_BUTTON, type: "uint8", values: [0xB, 4, 0x10] }
}
const conn_ind_2: DeviceSettingDef = { id: DEV_SETTING_ID.CONNECTION_INDICATION,
  values: ["false", "true"],
  deckard: { id: DECKARD.DEV_CONNECTIVITY_PROMPT_MODE, type: "uint8", values: [1, 2] }
}

const settingsOverrides = new Map<number, DeviceSettingDef[]>([
  [0x16E, [disable(DEV_SETTING_ID.CALLER_ID), disable(DEV_SETTING_ID.INCOMING_CALL_ALERT),
    disable(DEV_SETTING_ID.ANSWERING_CALL_ALERT), disable(DEV_SETTING_ID.ANSWER_IGNORE),
    manage_all, call_announcements, incoming_call_2, answering_call_2, answer_ignore_2, audio_tone_2, conn_ind_2, hold_reminder_2,
    rocket_button_2]],
  [0x173, [disable(DEV_SETTING_ID.CALLER_ID), disable(DEV_SETTING_ID.INCOMING_CALL_ALERT),
    disable(DEV_SETTING_ID.ANSWERING_CALL_ALERT), disable(DEV_SETTING_ID.ANSWER_IGNORE),
    manage_all, call_announcements, incoming_call_2, answering_call_2, answer_ignore_2, audio_tone_2, conn_ind_2, hold_reminder_2]],
  [0x4303, [override40B_w_405, timed_mute_reminder_only]],
  [0x430A, [override40B_w_405]],
  [0x430B, [override40B_w_405]],
  [0x430C, [override40B_w_405]],
  [0x430D, [override40B_w_405]],
  [0x430E, [override40B_w_405]],
  [0x430F, [override40B_w_405]],
  [0x4311, [override40B_w_405]],
  [0x4312, [override40B_w_405]],
  [0x4313, [override40B_w_405]],
  [0x4314, [override40B_w_405]],
  [0x431B, [override40B_w_405]],
  [0x431D, [override40B_w_405]],
  [0x431E, [override40B_w_405]],
  [0x431F, [override40B_w_405]],
  [0xAF06, [override40B_w_405, timed_mute_reminder_only]],
  [0xAF07, [override40B_w_405, timed_mute_reminder_only]],
])


function deepCopy(ds: DeviceSettingDef[]): DeviceSettingDef[] {
  return JSON.parse(JSON.stringify(ds)) as DeviceSettingDef[];
}

function applyOverride(settings: DeviceSettingDef[], override: DeviceSettingDef[]): DeviceSettingDef[] {
  for (let o of override) {
    const sett = settings.find(e => o.id === e.id);
    if (sett) {
      sett.values = o.values;
      sett.deckard = o.deckard;
      sett.virtual = o.virtual;
      sett.uvc = o.uvc;
    }
    else {
      settings.push(o);
    }
  }
  return settings;
}

function validate(settings: DeviceSettingDef[]) {
  const ids = new Set<number>();
  settings.forEach(sett => {
    if (ids.has(sett.id)) {
      console.error(`setting ${hex4l(sett.id)} has multiple definitions`);
    }
    ids.add(sett.id);
    if (undefined !== sett.values && hasDuplicates(sett.values)) {
      console.error(`setting ${hex4l(sett.id)} has duplicates in values`);
    }
    let cnt = 0
    if (undefined !== sett.deckard) cnt += 1;
    if (undefined !== sett.uvc) cnt += 1;
    if (undefined !== sett.virtual) cnt += 1;
    if (1 < cnt) {
      console.error(`setting ${hex4l(sett.id)} has multiple implementations`);
    }
    if (undefined !== sett.deckard) {
      const deck = sett.deckard;
      cnt = 0;
      if (undefined !== deck.id) cnt += 1;
      if (undefined !== deck.get) cnt += 1;
      if (undefined !== deck.cmd) cnt += 1;
      if (undefined !== deck.event) cnt += 1;
      if (0 === cnt) {
        console.error(`setting ${hex4l(sett.id)} has no Deckard IDs`);
      }
      if (undefined !== sett.values) {
        if (sett.values.length !== deck.values.length) {
          console.error(`setting ${hex4l(sett.id)} has mismatching values lengths`);
        }
        if (hasDuplicates(deck.values)) {
          console.error(`setting ${hex4l(sett.id)} has duplicates in Deckard values`);
        }
      }
      else {
        console.error(`setting ${hex4l(sett.id)} is Deckard and has no values`);
      }
      cnt = 0;
      if (undefined !== deck.type) cnt += 1;
      if (undefined !== deck.bit) cnt += 1;
      if (1 < cnt) {
        console.error(`setting ${hex4l(sett.id)} has multiple Deckard encodings`);
      }
      if (undefined !== deck.bit && 8 * deck.bit.size <= deck.bit.bit) {
        console.error(`setting ${hex4l(sett.id)} has incorrect bit size or bit number`);
      }
    }
    if (undefined !== sett.virtual) {
      if (hasDuplicates(sett.virtual.subsettings.map(ss => ss.id))) {
        console.error(`setting ${hex4l(sett.id)} has duplicates in subsetting IDs`);
      }
      const virt = sett.virtual;
      if (undefined !== sett.values) {
        const valLen = sett.values.length;
        virt.subsettings.forEach(ss => {
          if (valLen !== ss.values.length) {
            console.error(`setting ${hex4l(sett.id)} subsetting ${ss.id} has mismatching values lengths`);
          }
        })
      }
      else {
        console.error(`setting ${hex4l(sett.id)} is virtual and has no values`);
      }
    }
  });
}

function hex4l(n: number) {
  return '0x' + n.toString(16);
}

function hasDuplicates<T>(arr: T[]): boolean {
  const set = new Set<T>(arr);
  return set.size != arr.length;
}

export function deviceSettingDefs(productId: number): DeviceSettingDef[] {
  const override = settingsOverrides.get(productId);
  const s = (override ? applyOverride(deepCopy(devSettings), override) : devSettings).sort((e1, e2) => e1.id - e2.id);
  validate(s);
  return s
}
