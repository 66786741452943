import { Component, Input, Output, EventEmitter } from "@angular/core";
import { OzDevice } from "../../services/device-manager.service";
import {
  COLOR_DANGER,
  COLOR_SUCCESS,
  BATTERY,
  COLOR_WARNING,
  BATTERY_WITH_CRITICAL_VALUE,
} from "../../utils/constants";
import { getBatteryIconNameFromBatteryPercent } from "../../utils/device.utils";

@Component({
  selector: "oz-device-split-view",
  templateUrl: "./device-split-view.component.pug",
})
export class DeviceSplitViewComponent {
  @Input() device: OzDevice;
  @Input() upToDate: boolean;
  @Input() releaseNotes: string;
  @Input() firmwareStr: string;
  @Input() availableVersionStr: string;
  @Output() openReleaseNotes = new EventEmitter<void>();

  LEFT = "left";
  RIGHT = "right";
  LEFT_EARBUD = "leftEarbud";
  RIGHT_EARBUD = "rightEarbud";
  LEFT_STATUS = "leftEarbudStatus";
  RIGHT_STATUS = "rightEarbudStatus";

  BATTERY = BATTERY;
  COLOR_CONNECTED = COLOR_SUCCESS;
  COLOR_DANGER = COLOR_DANGER;
  COLOR_WARNING = COLOR_WARNING;

  constructor() {}

  /**
   * Earbud is connected if connected === true && primary === true, this means that earbud is primary and only connected to USB dongle
   * Earbud is connected if connected === true && peerConnected === true, this means that earbud is secondary and connected to primary
   */
  isEarbudConnected(earbudStatus: string): boolean {
    return (
      this.device?.isConnected &&
      this.device[earbudStatus]?.connected &&
      (this.device[earbudStatus]?.primary ||
        this.device[earbudStatus]?.peerConnected)
    );
  }

  getBatteryIconName(earbud: string) {
    const iconName = getBatteryIconNameFromBatteryPercent(
      this.getBatteryLevelPercentNumber(earbud)
    );
    const charging = this.device?.additionalBatteryInfo[
      earbud + "EarbudCharging"
    ];

    return charging ? `${iconName}_charging` : iconName;
  }

  isBatteryLevelCritical(earbud: string): boolean {
    return (
      this.device?.additionalBatteryInfo[earbud] ===
      BATTERY_WITH_CRITICAL_VALUE.BATTERY_CRITICAL
    );
  }

  getBatteryLevelPercent(earbud: string): string {
    const batteryPercent = this.getBatteryLevelPercentNumber(earbud);
    const batteryPercentString =
      batteryPercent < 0 ? "0%" : `${batteryPercent}%`;

    return batteryPercentString;
  }

  getBatteryLevelPercentNumber(earbud: string): number {
    const numLevels = this.device?.additionalBatteryInfo[
      earbud + "EarbudNumLevels"
    ];

    if (numLevels - 1 <= 0) {
      return 0;
    }

    const level = this.device?.additionalBatteryInfo[
      earbud + "EarbudIncrementalLevel"
    ];
    const percent = (100 * level) / (numLevels - 1);

    return percent | 0; // "|0" is to make sure we return integer value
  }

  isBatteryLevelValid(earbud: string): boolean {
    return (
      this.device?.additionalBatteryInfo[earbud] !==
      BATTERY_WITH_CRITICAL_VALUE.UNKNOWN
    );
  }
}
