import { Component, OnInit } from "@angular/core";

@Component({
  selector: "oz-user-guide",
  templateUrl: "./user-guide.component.pug",
})
export class UserGuideComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
