import { Component, Input } from "@angular/core";
import {
  GetGeneralSettingsResponse,
  GeneralSettings,
  SetGeneralSettingsResponse,
} from "@poly/hub-native";
import {
  DeviceManagerService,
  OzDevice,
} from "../../services/device-manager.service";
import { AccordionItem } from "../../shared/components/accordion/accordion.component";
import { Toasts } from "../../shared/components/toast/toasts.service";

@Component({
  selector: "oz-device-settings-ntp-item",
  templateUrl: "./device-settings-ntp-item.component.pug",
})
export class DeviceSettingsNtpItemComponent {
  @Input() set accordionData(newValue: AccordionItem) {
    this.device = newValue.data.device;
  }

  @Input() set generalSettings(settings: GetGeneralSettingsResponse) {
    if (settings) {
      this.ntpMode = settings.ntp_mode;
      this.checkNtpMode(this.ntpMode);
      this.ntpServer = settings.ntp_server;
      this.cameraVividMode = settings.camera_vivid_mode;
    }
  }

  device: OzDevice;

  ntpMode: string;
  ntpManual: boolean;
  ntpServer: string;
  cameraVividMode: boolean;

  ntpModeValues = {
    AUTO: "auto",
    MANUAL: "manual",
  };

  ntpModeOptions = [
    { value: this.ntpModeValues.AUTO, text: "Auto" },
    { value: this.ntpModeValues.MANUAL, text: "Manual" },
  ];

  constructor(
    private deviceManager: DeviceManagerService,
    private toasts: Toasts
  ) {}

  onNtpModeChanged(value: string) {
    this.ntpMode = value;
    this.checkNtpMode(this.ntpMode);
  }

  checkNtpMode(value: string) {
    this.ntpManual = value === this.ntpModeValues.MANUAL;
    if (!this.ntpManual) this.ntpServer = "";
  }

  onSaveGeneralSettings() {
    const request: GeneralSettings = {
      deviceId: this.device?.id,
      device_name: this.device.name,
      country_region: this.device?.videoDeviceStatus?.countryRegion,
      ntp_server: this.ntpServer,
      ntp_mode: this.ntpMode,
      camera_vivid_mode: this.cameraVividMode,
    };

    this.deviceManager
      .setGeneralSettings(request)
      .then((response: SetGeneralSettingsResponse) => {
        this.toasts.push({
          type: "status",
          status: response.status === "OK" ? "success" : "error",
          text:
            response.status === "OK"
              ? "NOTIFICATIONS.CHANGE_NTP_SETTINGS_SUCCEEDED"
              : "NOTIFICATIONS.CHANGE_NTP_SETTINGS_FAILED",
        });
      });
  }
}
