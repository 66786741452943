import { Injectable } from "@angular/core";

const icons = {
  moon: require("!raw-loader!../../assets/icons/moon.svg").default,
  sun: require("!raw-loader!../../assets/icons/sun.svg").default,
  link: require("!raw-loader!../../assets/icons/link.svg").default,
  unlink: require("!raw-loader!../../assets/icons/unlink.svg").default,
  prop: require("!raw-loader!../../assets/icons/prop.svg").default,
  poly: require("!raw-loader!../../assets/icons/poly.svg").default,
  LENS: require("!raw-loader!../../assets/icons/LENS.svg").default,
  add: require("!raw-loader!../../assets/icons/add.svg").default,
  circle: require("!raw-loader!../../assets/icons/circle.svg").default,
  circle_o: require("!raw-loader!../../assets/icons/circle_o.svg").default,
  question_help_circle: require("!raw-loader!../../assets/icons/question_help_circle.svg")
    .default,
  poly_logo_lens: require("!raw-loader!../../assets/icons/poly_logo_lens.svg")
    .default,
  poly_plus: require("!raw-loader!../../assets/icons/poly_plus.svg").default,
  settings: require("!raw-loader!../../assets/icons/settings.svg").default,
  home: require("!raw-loader!../../assets/icons/home.svg").default,
  chart: require("!raw-loader!../../assets/icons/chart.svg").default,
  health: require("!raw-loader!../../assets/icons/health.svg").default,
  play_on_screen: require("!raw-loader!../../assets/icons/play_on_screen.svg")
    .default,
  support: require("!raw-loader!../../assets/icons/support.svg").default,
  unboxing_cable_config: require("!raw-loader!../../assets/icons/unboxing_cable_config.svg")
    .default,
  health_wellness: require("!raw-loader!../../assets/icons/health_wellness.svg")
    .default,
  best_practices: require("!raw-loader!../../assets/icons/best_practices.svg")
    .default,
  device_capabilities: require("!raw-loader!../../assets/icons/device_capabilities.svg")
    .default,
  headband_adjustment: require("!raw-loader!../../assets/icons/headband_adjustment.svg")
    .default,
  boom_placement: require("!raw-loader!../../assets/icons/boom_placement.svg")
    .default,
  boom_shape: require("!raw-loader!../../assets/icons/boom_shape.svg").default,
  timer_icon: require("!raw-loader!../../assets/icons/timer_icon.svg").default,
  ergo_positioning: require("!raw-loader!../../assets/icons/ergo_positioning.svg")
    .default,
  ergo_forearms: require("!raw-loader!../../assets/icons/ergo_forearms.svg")
    .default,
  ergo_seated_position: require("!raw-loader!../../assets/icons/ergo_seated_position.svg")
    .default,
  voyager_focus: require("!raw-loader!../../assets/icons/voyager_focus.svg")
    .default,
  wellness_hydration: require("!raw-loader!../../assets/icons/wellness_hydration.svg")
    .default,
  back_arrow: require("!raw-loader!../../assets/icons/back_arrow.svg").default,
  soundscaping: require("!raw-loader!../../assets/icons/soundscaping.svg")
    .default,
  vision_look_away: require("!raw-loader!../../assets/icons/vision_look_away.svg")
    .default,
  vision_stand_up_move: require("!raw-loader!../../assets/icons/vision_stand_up_move.svg")
    .default,
  camera_mounting: require("!raw-loader!../../assets/icons/camera_mounting.svg")
    .default,
  camera_centering: require("!raw-loader!../../assets/icons/camera_centering.svg")
    .default,
  desk_setup_desk: require("!raw-loader!../../assets/icons/desk_setup_desk.svg")
    .default,
  desk_setup_keyboard: require("!raw-loader!../../assets/icons/desk_setup_keyboard.svg")
    .default,
  camera_lighting: require("!raw-loader!../../assets/icons/camera_lighting.svg")
    .default,
  camera_background: require("!raw-loader!../../assets/icons/camera_background.svg")
    .default,
  desk_setup_chair: require("!raw-loader!../../assets/icons/desk_setup_chair.svg")
    .default,
  desk_setup_feet: require("!raw-loader!../../assets/icons/desk_setup_feet.svg")
    .default,
  desk_setup_monitor: require("!raw-loader!../../assets/icons/desk_setup_monitor.svg")
    .default,
  noiseblockai_intro: require("!raw-loader!../../assets/icons/noiseblockai_intro.svg")
    .default,
  offline: require("!raw-loader!../../assets/icons/offline.svg").default,
  left_arrow: require("!raw-loader!../../assets/icons/left_arrow.svg").default,
  bluetooth: require("!raw-loader!../../assets/icons/bluetooth.svg").default,
  power_switch: require("!raw-loader!../../assets/icons/power_switch.svg")
    .default,
  support_circle: require("!raw-loader!../../assets/icons/support_circle.svg")
    .default,
  moon_astronaut: require("!raw-loader!../../assets/icons/moon_astronaut.svg")
    .default,
  rocket: require("!raw-loader!../../assets/icons/rocket.svg").default,
  book: require("!raw-loader!../../assets/icons/book.svg").default,
  info: require("!raw-loader!../../assets/icons/info.svg").default,
  life_raft: require("!raw-loader!../../assets/icons/life_raft.svg").default,
  cactus: require("!raw-loader!../../assets/icons/cactus.svg").default,
  earth: require("!raw-loader!../../assets/icons/earth.svg").default,
  phone: require("!raw-loader!../../assets/icons/phone.svg").default,
  check_badge: require("!raw-loader!../../assets/icons/check_badge.svg")
    .default,
  check: require("!raw-loader!../../assets/icons/check.svg").default,
  circle_check: require("!raw-loader!../../assets/icons/circle_check.svg")
    .default,
  circle_times: require("!raw-loader!../../assets/icons/circle_times.svg")
    .default,
  times: require("!raw-loader!../../assets/icons/times.svg").default,
  cta: require("!raw-loader!../../assets/icons/cta.svg").default,
  search: require("!raw-loader!../../assets/icons/search.svg").default,
  star_empty: require("!raw-loader!../../assets/icons/star_empty.svg").default,
  star_filled: require("!raw-loader!../../assets/icons/star_filled.svg")
    .default,
  contact_support: require("!raw-loader!../../assets/icons/contact_support.svg")
    .default,
  poly_lens: require("!raw-loader!../../assets/icons/poly_lens.svg").default,
  prop_poly: require("!raw-loader!../../assets/icons/prop_poly.svg").default,
  camera_eye: require("!raw-loader!../../assets/icons/camera_eye.svg").default,
  camera_eye_slash: require("!raw-loader!../../assets/icons/camera_eye_slash.svg")
    .default,
  camera_snapshot: require("!raw-loader!../../assets/icons/camera_snapshot.svg")
    .default,
  camera_grid: require("!raw-loader!../../assets/icons/camera_grid.svg")
    .default,
  camera_contract: require("!raw-loader!../../assets/icons/camera_contract.svg")
    .default,
  camera_expand: require("!raw-loader!../../assets/icons/camera_expand.svg")
    .default,
  pan_left: require("!raw-loader!../../assets/icons/pan_left.svg").default,
  pan_right: require("!raw-loader!../../assets/icons/pan_right.svg").default,
  tilt_up: require("!raw-loader!../../assets/icons/tilt_up.svg").default,
  tilt_down: require("!raw-loader!../../assets/icons/tilt_down.svg").default,
  zoom_in: require("!raw-loader!../../assets/icons/zoom_in.svg").default,
  zoom_out: require("!raw-loader!../../assets/icons/zoom_out.svg").default,
  loading: require("!raw-loader!../../assets/icons/loading.svg").default,
  buttonSpinner: require("!raw-loader!../../assets/icons/buttonSpinner.svg")
    .default,
  video_no_signal: require("!raw-loader!../../assets/icons/video_no_signal.svg")
    .default,
  video_off: require("!raw-loader!../../assets/icons/video_off.svg").default,
  warning_triangle: require("!raw-loader!../../assets/icons/warning_triangle.svg")
    .default,
  battery_flat: require("!raw-loader!../../assets/icons/battery/battery_flat.svg")
    .default,
  battery_flat_charging: require("!raw-loader!../../assets/icons/battery/battery_flat_charging.svg")
    .default,
  battery_critical: require("!raw-loader!../../assets/icons/battery/battery_critical.svg")
    .default,
  battery_critical_charging: require("!raw-loader!../../assets/icons/battery/battery_critical_charging.svg")
    .default,
  battery_low: require("!raw-loader!../../assets/icons/battery/battery_low.svg")
    .default,
  battery_low_charging: require("!raw-loader!../../assets/icons/battery/battery_low_charging.svg")
    .default,
  battery_medium: require("!raw-loader!../../assets/icons/battery/battery_medium.svg")
    .default,
  battery_medium_charging: require("!raw-loader!../../assets/icons/battery/battery_medium_charging.svg")
    .default,
  battery_high: require("!raw-loader!../../assets/icons/battery/battery_high.svg")
    .default,
  battery_high_charging: require("!raw-loader!../../assets/icons/battery/battery_high_charging.svg")
    .default,
  battery_full: require("!raw-loader!../../assets/icons/battery/battery_full.svg")
    .default,
  battery_full_charging: require("!raw-loader!../../assets/icons/battery/battery_full_charging.svg")
    .default,
  wifi_weak: require("!raw-loader!../../assets/icons/wifi/wifi_weak.svg")
    .default,
  wifi_medium: require("!raw-loader!../../assets/icons/wifi/wifi_medium.svg")
    .default,
  wifi_strong: require("!raw-loader!../../assets/icons/wifi/wifi_strong.svg")
    .default,
  wifi_disconnected: require("!raw-loader!../../assets/icons/wifi/wifi_disconnected.svg")
    .default,
  close: require("!raw-loader!../../assets/icons/close.svg").default,
  copy: require("!raw-loader!../../assets/icons/copy.svg").default,
  close_circle: require("!raw-loader!../../assets/icons/close_circle.svg")
    .default,
  device_detected: require("!raw-loader!../../assets/icons/device_detected.svg")
    .default,
  device_success: require("!raw-loader!../../assets/icons/device_success.svg")
    .default,
  arrow_right: require("!raw-loader!../../assets/icons/arrow_right.svg")
    .default,
  step_success: require("!raw-loader!../../assets/icons/step-success.svg")
    .default,
  step_skipped: require("!raw-loader!../../assets/icons/step-skipped.svg")
    .default,
  wrench: require("!raw-loader!../../assets/icons/wrench.svg").default,
  yellow_triangle: require("!raw-loader!../../assets/icons/yellow_triangle.svg")
    .default,
  lock: require("!raw-loader!../../assets/icons/lock.svg").default,
  toast_success: require("!raw-loader!../../assets/icons/toast-success.svg")
    .default,
  toast_error: require("!raw-loader!../../assets/icons/toast-error.svg")
    .default,
  toast_close: require("!raw-loader!../../assets/icons/toast-close.svg")
    .default,
  up_arrow_white: require("!raw-loader!../../assets/icons/up-arrow-white.svg")
    .default,
  down_arrow_white: require("!raw-loader!../../assets/icons/down-arrow-white.svg")
    .default,
  toast_warning_triangle: require("!raw-loader!../../assets/icons/toast_warning_triangle.svg")
    .default,
  beta: require("!raw-loader!../../assets/icons/beta.svg").default,
  preview: require("!raw-loader!../../assets/icons/preview.svg").default,
  do_not_enter: require("!raw-loader!../../assets/icons/do_not_enter.svg")
    .default,
  message_bubble: require("!raw-loader!../../assets/icons/message_bubble.svg")
    .default,
  megaphone: require("!raw-loader!../../assets/icons/megaphone.svg").default,
  play_circle: require("!raw-loader!../../assets/icons/play_circle.svg")
    .default,
    trash: require("!raw-loader!../../assets/icons/trash.svg").default,

};

@Injectable({
  providedIn: "root",
})
export class IconService {
  constructor() {}

  get icons(): { [id in keyof typeof icons]: string } {
    return icons;
  }
}
