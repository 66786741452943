import { Component, Input, OnInit } from "@angular/core";
import { Device } from "@poly/hub-native";
import { AccordionItem } from "../../shared/components/accordion/accordion.component";
import { ProvisioningServer } from "../../services/provisioning-server.service";
import { ProvisioningForm } from "./provisioning-form.component";

@Component({
  selector: "oz-device-settings-provisioning-server",
  templateUrl: "./device-settings-provisioning.component.pug",
})
export class DeviceSettingsProvisioningComponent implements OnInit {
  @Input() set accordionData(newValue: AccordionItem) {
    this.device = newValue.data.device;
  }

  device: Device;
  config: ProvisioningForm;
  applyEnabled = false;

  constructor(private provisioningServer: ProvisioningServer) {}

  ngOnInit() {
    this.provisioningServer.getParams(this.device.id).subscribe((params) => {
      this.config = params;
      this.resolveApplyStatus(params);
    });
  }

  onApply() {
    this.provisioningServer.setParams({
      deviceId: this.device.id,
      ...this.config,
    });
  }

  onConfigChanged(config: ProvisioningForm) {
    this.config = config;
    this.resolveApplyStatus(config);
  }

  private resolveApplyStatus(form: ProvisioningForm) {
    const {
      provisioningMode,
      serverType,
      serverAddress,
      username,
      password,
    } = form;
    this.applyEnabled =
      provisioningMode !== "Manual" ||
      (provisioningMode === "Manual" &&
        !!serverType &&
        !!serverAddress &&
        !!username &&
        !!password);
  }
}
