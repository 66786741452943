import {Injectable, NgModule, NgZone} from "@angular/core";
import { Observable, of, Subscriber } from "rxjs";
import { v4 } from "uuid";
import path from "path";
import { UtilityService } from "./utility.service";
import { runInZone } from "../utils/rxjs.utils";
import {PolytronServiceApi} from "../polytron/polytron.service.api";

interface OzFileSystemResult {
  success: boolean;
}

export interface ozFileWriteResult extends OzFileSystemResult {}

export interface OzFileReadResult extends OzFileSystemResult {
  content?: string;
}

export type OzDirectories = "documents" | "temp";

const MSG_READ_FILE = "__FS_READ_FILE__";
const MSG_COPY_FILE = "__FS_COPY_FILE__";

@Injectable({
  providedIn: "root",
})
export class OzFileSystem {

  // Maps the message id to subscriber
  private readFileRequests: {
    [messageId: string]: Subscriber<OzFileReadResult>;
  } = {};
  private copyFileRequests: {
    [messageId: string]: Subscriber<boolean>;
  } = {};

  constructor(private ngZone: NgZone, private polytron: PolytronServiceApi) {
    this.polytron.getIpcRenderer().on(
      MSG_READ_FILE,
      (ev: any, { messageId, content, success }) => {
        if (messageId) {
          const ob = this.readFileRequests[messageId];
          if (ob) {
            ob.next({ success, content });
            ob.complete();
          }
          delete this.readFileRequests[messageId];
        }
      }
    );

    this.polytron.getIpcRenderer().on(
      MSG_COPY_FILE,
      (ev: any, { messageId, success }) => {
        if (messageId) {
          const ob = this.copyFileRequests[messageId];
          if (ob) {
            ob.next(success);
            ob.complete();
          }
          delete this.copyFileRequests[messageId];
        }
      }
    );
  }

  /**
   * Saves a file.
   *
   * @param path A destination file path
   * @param content A content that needs to be saved on the provided `path`
   * @returns Operation status
   */
  saveFile(path: string, content: string): Observable<ozFileWriteResult> {
    return new Observable((ob) => {
      /* TODO:
      fs.writeFile(path, content, (err) => {
        if (err) {
          ob.next({
            success: false,
          });
        } else {
          ob.next({
            success: true,
          });
        }
        ob.complete();
      });
       */
    });
  }

  /**
   * Reads a content of a file.
   *
   * @param path A destination file path
   * @returns Operation status
   */
  readFile(path: string): Observable<OzFileReadResult> {
    return new Observable((ob) => {
      const messageId = v4();
      this.readFileRequests[messageId] = ob;
      this.polytron.getIpcRenderer().send(MSG_READ_FILE, { messageId, path });
    }).pipe(runInZone<OzFileReadResult>(this.ngZone));
  }

  /**
   * Retrieves a path from the underlying system to the system's specific directory.
   *
   * @param dirName Specific directory name (e.g. "documents")
   */
  getDir(dirName: OzDirectories): string {
    switch (dirName) {
      case "temp":
        return this.polytron.getPath("temp");
      case "documents":
        return this.polytron.getPath("documents");
      default:
        throw new Error(`Directory name ${dirName} unknown.`);
    }
  }

  /**
   * Creates a path for a temporary file.
   *
   * @returns file path
   */
  createTemp(filename?: string): string {
    return path.join(this.getDir("temp"), v4(), filename ?? "");
  }

  /**
   * Removes a directory and its content.
   *
   * @returns Remove directory status.
   */
  removeDir(dirPath: string): Observable<boolean> {
    return new Observable((ob) => {
      try {
        // TODO: fs.rmdirSync(path.parse(dirPath).dir, { recursive: true });
        ob.next(true);
      } catch (err) {
        ob.next(false);
      } finally {
        ob.complete();
      }
    });
  }

  /** Copies a file from its source to a destination.
   *
   * @param sorucePath The path that needs to be copied.
   * @param destinationPath The destination path.
   */
  copyFile(sorucePath: string, destinationPath: string): Observable<boolean> {
    return new Observable((ob) => {
      const messageId = v4();
      this.copyFileRequests[messageId] = ob;
      this.polytron.getIpcRenderer().send(MSG_COPY_FILE, {
        messageId,
        sorucePath,
        destinationPath,
      });
    }).pipe(runInZone<boolean>(this.ngZone));
  }

  /**
   * Return system specific path to read system-wide configuration files.
   * In most cases user will do not have write access to that file.
   *
   * On Windows it is expanded to %PROGRAMDATA%/Poly/LensDesktop path, not supported on other systems
   */
  static systemWideConfigurationPath(fileName: string): string {
    if (UtilityService.isWindows() && path) {
      return path.join(process.env.PROGRAMDATA, "Poly/LensDesktop", fileName);
    } else {
      return null;
    }
  }
}
