import { Component, Input, OnInit } from "@angular/core";
import { DFUError } from "@poly/hub-native";
import { OzDevice } from "../services/device-manager.service";
import { removeManufacturerName } from "../shared/pipes/device-name.pipe";

interface TranslateParams {
  deviceName: string;
}

@Component({
  selector: "oz-dfu-warning-tooltip-content",
  templateUrl: "./dfu-warning-tooltip-content.component.pug",
})
export class DfuWarningTooltipContentComponent implements OnInit {
  @Input() device: OzDevice;
  @Input() warnings: DFUError[];
  translateParams: TranslateParams;

  ngOnInit() {
    this.translateParams = {
      deviceName: removeManufacturerName(this.device?.name),
    };
  }
}
