import { Observable } from "rxjs";

/**
 * Provides an interface for managing a DFU, e.g. starting a DFU and tracking progress.
 */
export abstract class IDFUManager {
  /**
   * Performs DFU for a specific device.
   *
   * @param options DFU options.
   */
  abstract startDFU(options: DFUOptions): Observable<DeviceUpdateProgress>;

  /**
   * Retries the subsequent DFU which failed for any reason.
   */
  abstract retryDFU(): void;

  /**
   * It is possible to cancel the DFU after error occured if user is
   * asked for a retry, but it is not possible to cancel the DFU while
   * DFU is in progress.
   */
  abstract cancelDFU(): void;

  /**
   * Checks if a FW update is needed or not
   *
   * @param rulesJson from cloud.
   * @param deviceId device id for a specific device.
   */
  abstract isUpdateNeeded(
    deviceId: string,
    rulesJson: string,
    policy?: boolean
  ): Promise<boolean>;

  abstract IsPolicyUpdateNeeded(
    deviceId: string,
    rulesJson: string
  ): Promise<boolean>;
  /**
   * Check if DFU can be performed.
   * Returns list of errors if can not.
   */
  abstract canPerformDFU(deviceId: string): Promise<DFUError[]>;

  /**
   * Check if the bricked device exists.
   */
  abstract brickedDeviceStatus(): Promise<BrickedDeviceInfo>;
}

export interface DeviceUpdateProgress {
  /**
   * Device for which the DFU is started.
   */
  deviceId: string;

  /**
   * Update progress percentage for undergoing DFU: [0%, 100%].
   */
  percentage: number;

  /**
   * Update progress percentage for undergoing DFU component: [0%, 100%].
   */
  percentageCurrentComponent?: number;

  /**
   * Update status.
   */
  status: DFUStatus;

  /**
   * DFU errors (e.g. "BatteryLowError") or user actions (e.g. "ReplugDevice").
   */
  message: DFUError | DFUUserAction;

  /**
   * Component currently being updated.
   */
  currentComponent?: number;

  /**
   * Number of components that will be updated.
   */
  componentsNumber?: number;

  /**
   * Maximal duration of component curently being updated in minutes.
   */
  currentComponentMaxDuration?: number;

  /**
   * Maximal duration of all components in minutes.
   */
  maxDuration?: number;
}

export declare type DFUStatus =
  | "Started"
  | "InProgress"
  | "Completed"
  | "Failed"
  | "UpToDate";

export declare type DFUError =
  | "BatteryLowError" // Battery low.
  | "MultipleDevicesError" // More than one device with the same product ID is attached.
  | "PeripheralDeviceError" // Cannot perform firmware update because the device is a peripheral device. To perform firmware update, please attach your device via cable.
  | "DeviceNotConnectedError" // Device is not attached.
  | "FirmwareUpdateInProgressError" // Firmware update is already in progress. Cannot perform another DFU in parallel.
  | "FirmwareUpToDateError" // Device is already up to date.
  | "FailedToStartError" // Internal error occurred while starting to perform firmware update.
  | "FirmwareUpdateNotSupportedError" // Firmware update is not supported for this device.
  | "OsNotSupportedError" // Device is not supported on underlying operating system.
  | "IncompleteFirmwareArchiveError" // Files referred to in rules file are missing from the archive.
  | "InvalidRulesFileError" // Invalid rules file.
  | "InvalidFirmwareArchiveError" // Invalid firmware archive.
  | "PrerequisitesFailedError" // Device did not meet all prerequisites in order to start firmware update.
  | "UpdateHandlerNotImplementedError" // Cannot perform firmware update due to missing update handler.
  | "FirmwareVersionNotSupportedError" // Unsupported firmware version.
  | "FirmwareTransferError" // Transfer of the firmware update related data to the device has failed.
  | "UnrelatedDeviceError" // Firmware update is unrelated to this device.
  | "FirmwareDataIncorrectError" // Data related to this firmware update is incorrect.
  | "FilesystemError" // Filesystem error occurred. Cannot unzip archive. Cannot create temp folder.
  | "CannotBeSilentError" // Firmware update cannot be silent.
  | "CallinProgressError" // User is in a call
  | "DFUInProgressError" // DFU is already in progress
  | "FirmwareArchiveNotFoundError" // Firmware archive not found
  | "CameraInUseError" // Someone uses camera on video device
  | "SingleEarbudError" // Only one earbud is connected
  | "Unknown"; // Internal, unknown, error occurred.

export declare type DFUUserAction =
  | "ReplugDevice" // Device needs to be repluged in order to continue DFU, or in case the replug is mandatroy after the DFU in order for the DFU to take effect.
  | "RetryDFU"; // Native offers a DFU to be retried.

/**
 * This interface may differ for mobile platforms.
 */
export interface DFUOptions {
  /**
   * Device for which the DFU is being started.
   */
  deviceId: string;

  /**
   * Path to the DFU archive.
   */
  filepath: string;

  /**
   * Language ID in LCID Decimal format (e.g. 1033 - English US).
   */
  languageId?: string;
}

export interface BrickedDeviceInfo {
  deviceId: number;
  recoveryArchivePath: string;
}
