import {Injectable, NgModule} from "@angular/core";
import { Observable, Subject } from "rxjs";
import { v4 as uuid_v4 } from "uuid";
import {IpcRenderer, PolytronServiceApi} from "../polytron/polytron.service.api";
import {AdminConfig} from "./admin-config.service";

// Note, send and send$ methods auto-generate a guaranteed-unique response channel
export interface IIPCNotificationScheduledParams {
  namespace: "notification";
  action: "send";
  data?: {
    type: "cron";
    title: string;
    body: string;
    icon?: string;
    schedule: any;
    key: string;
    origin?: "HYDRATION" | "VISION";
  };
}

export interface IIPCNotificationSingleParams {
  namespace: "notification";
  action: "send";
  data?: {
    type: "single";
    title: string;
    body: string;
    icon: string;
    actions?: Array<{ type: "button"; text?: string }>;
    origin?:
      | "CHARGING"
      | "DISCONNECTED"
      | "MUTE"
      | "UNPAIRED"
      | "BATTERY_LOW"
      | "DFU_UPDATE";
  };
}

export interface IIPCNotificationDestroyParams {
  namespace: "notification";
  action: "send";
  data?: {
    type: "destroy";
    key: string;
  };
}

export interface IIPCNotificationListenParams {
  namespace: "notification";
  action: "listen";
}

export interface IIPCMessagesSpeedTestParams {
  namespace: "speedtest";
  action: "run";
  data?: {
    throttle_ms: number;
  };
}

type IPCRequestParams =
  | IIPCNotificationScheduledParams
  | IIPCNotificationSingleParams
  | IIPCNotificationDestroyParams
  | IIPCNotificationListenParams
  | IIPCMessagesSpeedTestParams;

export interface IIPCRequest {
  responseChannel?: string;
  params?: IPCRequestParams;
}

@Injectable({
  providedIn: "root",
})
export class IpcService {
  private ipcRenderer?: IpcRenderer;

  constructor(private polytron: PolytronServiceApi, private adminConfig: AdminConfig) {
  }

  private initializeIpcRenderer() {
/*
    if (!window || !window.process || !window.require) {
      throw new Error(`Unable to require renderer process`);
    }

    this.ipcRenderer = window.require("electron").ipcRenderer;
*/
    this.ipcRenderer = this.polytron.getIpcRenderer();
  }

  public send<T>(channel: string, params: IPCRequestParams): Promise<T> {
    // If the ipcRenderer is not available try to initialize it
    if (!this.ipcRenderer) {
      this.initializeIpcRenderer();
    }

    const request: IIPCRequest = {
      responseChannel: this.getResponseChannel(channel),
      params,
    };

    this.ipcRenderer.send(channel, request);

    // This method returns a promise which will be resolved when the response has arrived.
    return new Promise((resolve) => {
      this.ipcRenderer.once(
        request.responseChannel as string,
        (event, response) => resolve(response)
      );
    });
  }

  /**
   * Similar to send, but returns an observable.
   *
   * If a response is sent with { ... completed: true }, it will emit the data then
   * complete (close) the observable.  Completing the observable is strongly recommended
   * to tie up loose ends and help prevent memory leaks.
   *
   * @param channel
   * @param params
   */
  public send$<T>(channel: string, params: IPCRequestParams): Observable<T> {
    // If the ipcRenderer is not available try to initialize it
    if (!this.ipcRenderer) {
      this.initializeIpcRenderer();
    }

    const request: IIPCRequest = {
      responseChannel: this.getResponseChannel(channel),
      params,
    };

    this.ipcRenderer.send(channel, request);

    // This method returns a promise which will be resolved when the response has arrived.
    const obs$ = new Subject<T>();

    if(this.adminConfig.mode === 'pwa') {
      // NodeJS.EventEmitter sends (event, response), but the browser only send response
      this.ipcRenderer.on(request.responseChannel, (response) => {
        obs$.next(response);
        // optional response boolean to indicate observable is complete
        if (response && response.completed) {
          obs$.complete();
        }
      });
    }
    else {
      this.ipcRenderer.on(request.responseChannel, (event, response) => {
        obs$.next(response);
        // optional response boolean to indicate observable is complete
        if (response && response.completed) {
          obs$.complete();
        }
      });
    }


    return obs$.asObservable();
  }

  /**
   * Auto-generate a guaranteed-unique (using a UUID) responseChannel.
   *
   * @param channel
   *
   * @private
   */
  private getResponseChannel(channel: string) {
    return `${channel}_response_${uuid_v4()}`;
  }
}
