import { Component, OnDestroy, OnInit } from "@angular/core";
import { SoftwareSetting } from "@poly/hub-native";
import { DeviceManagerService } from "../../services/device-manager.service";
import { OzFileDialog } from "../../services/file-dialog.service";
import {
  SOFTWARE_SETTING_PLAY_GREETING,
  SOFTWARE_SETTING_PLAY_GREETING_FILE,
} from "../../utils/constants";
import * as path from "path";
// import * as electron from "electron";
import { TranslateService } from "@ngx-translate/core";
import { Subscription } from "rxjs";
import {PolytronServiceApi} from "../../polytron/polytron.service.api";

/**
 * Component offers to turn on/ff greeting wav file on MDA5xx device,
 * to select file and preview (play) it
 *
 * playGreeting and playGreetingFile are software settings placed in HubUser.config file
 * (C:\\Users\____\AppData\Local\Palantronics\)
 * main.ts (main part of electron app, nodejs) plays file with sound-player plugin
 * because it works with static files outside Angular aplication
 */

const PLAY_AUDIO_FILE = "PLAY_AUDIO_FILE";

@Component({
  selector: "oz-device-software-setting-play-greeting-item",
  templateUrl: "./device-settings-play-greeting-item.component.pug",
})
export class DeviceSettingsPlayGreetingComponent implements OnInit, OnDestroy {
  disabled = true;
  playButtonEnabled = false;
  playGreeting: SoftwareSetting;
  playGreetingValue = "false";
  playGreetingFile: SoftwareSetting;
  playGreetingFilename = "";
  translateServiceSubscription: Subscription;
  getSoftwareSettingsSubscription: Subscription;
  fileDialogSubscription: Subscription;

  constructor(
    private deviceManager: DeviceManagerService,
    private fileDialog: OzFileDialog,
    private translateService: TranslateService,
    private polytron: PolytronServiceApi,
  ) {
    this.translateServiceSubscription = this.translateService
      .stream(["GENERAL.LOADING"])
      .subscribe((translations) => {
        this.playGreetingFilename = translations["GENERAL.LOADING"] + "...";
      });
  }

  ngOnInit() {
    this.getSoftwareSettingsSubscription = this.deviceManager
      .getSoftwareSettings()
      .subscribe((settings) => {
        if (settings.length > 0) {
          // Find playGreeting settings in "settings" array
          this.playGreeting = settings.find(
            ({ id }) => id === SOFTWARE_SETTING_PLAY_GREETING
          );
          this.playGreetingFile = settings.find(
            ({ id }) => id === SOFTWARE_SETTING_PLAY_GREETING_FILE
          );
          // Set fetched values
          if (this.playGreeting) {
            this.playGreetingValue = this.playGreeting.value;
          }
          if (this.playGreetingFile) {
            this.playGreetingFilename = path.parse(
              this.playGreetingFile.value
            ).base; // audio file name only
            // Enable play button now when we have fetched the file path
            this.playButtonEnabled = true;
          }
          this.disabled = !this.playGreetingFilename?.length; // switch is disabled if there is no file set
        }
      });

    this.polytron.getIpcRenderer().on(PLAY_AUDIO_FILE, () => {
      this.audioEnded();
    });
  }

  onSettingChanged(value: string) {
    const isTrue = value === "true";
    this.deviceManager.setSoftwareSetting({
      id: SOFTWARE_SETTING_PLAY_GREETING,
      value: isTrue,
    });
  }

  onSelectFile() {
    this.fileDialogSubscription = this.fileDialog
      .openFile({
        title: "SELECT_FILE",
        titleTranslate: true,
        filters: [{ name: "Waveform Audio File", extensions: ["wav", "wave"] }],
      })
      .subscribe(({ paths, canceled }) => {
        if (!canceled && paths?.length) {
          this.deviceManager.setSoftwareSetting({
            id: SOFTWARE_SETTING_PLAY_GREETING_FILE,
            value: paths[0],
          });
        }
      });
  }

  onPlay() {
    if (this.playButtonEnabled) {
      this.playButtonEnabled = false;
      const filepath = this.playGreetingFile.value; //"C:\\Windows\\Media\\Alarm01.wav";
      this.polytron.getIpcRenderer().send(PLAY_AUDIO_FILE, { filepath });
    }
  }

  ngOnDestroy() {
    this.polytron.getIpcRenderer().removeListener(PLAY_AUDIO_FILE, this.audioEnded);
    this.translateServiceSubscription?.unsubscribe();
    this.getSoftwareSettingsSubscription?.unsubscribe();
    this.fileDialogSubscription?.unsubscribe();
  }

  private audioEnded() {
    this.playButtonEnabled = true;
  }
}
