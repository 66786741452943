import {ILoggingService} from "../app/services/logging.service";

const axios = require("axios");
// const fs = require("fs");
const path = require("path");
const _ = require("lodash");
// const logger = require("electron-log");

export const NANOSECONDS_PER_MILLISECOND = 1e6;
export const NANOSECONDS_PER_SECOND = 1e9;
export const BITS_PER_BYTE = 8;
export const BITS_PER_MEGABIT = 1e6;
export const BYTES_PER_MEGABIT = BITS_PER_MEGABIT / BITS_PER_BYTE;
export const BYTES_PER_KIBIBYTE = 1024;
// for example, 1Mbps is equal to 122.07 kibibytes per second
export const KIBIBYTE_PER_SECOND_PER_MEGABIT_PER_SECOND = 122.07;

export class Utility {
  // updating version? also update test-config.json meta.version
  public static readonly version = 2;
  private static logger: ILoggingService;

  static nanoToMS(val: number | bigint): number {
    if (typeof val !== "bigint") {
      return Number(val);
    }

    return Number(val / BigInt(NANOSECONDS_PER_MILLISECOND));
  }

  static getStampMS(): number {
    return this.nanoToMS(Date.now()); //process.hrtime.bigint());
  }

  static randomMS(): number {
    return Math.floor(Math.random() * 1000);
  }

  // static getStampNS(): bigint {
  //   return process.hrtime.bigint();
  // }

  static randomString(length: number): Buffer {
    var result           = '';
    var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var charactersLength = characters.length;
    for ( var i = 0; i < length; i++ ) {
      result += characters.charAt(Math.floor(Math.random() *
        charactersLength));
    }
    return new Buffer(result);
  }

  /**
   * Get megabits per second given progress_bytes and timing data.
   *
   * @param start_stamp_ms
   * @param current_stamp_ms
   * @param progress_bytes
   */
  static getMbps(
    start_stamp_ms: number,
    current_stamp_ms: number,
    progress_bytes: number
  ): number {
    const difference_ms = current_stamp_ms - start_stamp_ms;
    if (difference_ms < 0) {
      Utility.logger.error(
        `Unexpected time inputs of start ${start_stamp_ms} and last observed ${current_stamp_ms}`
      );

      return 0;
    }

    if (0 === difference_ms) {
      // not useful to calculate an Infinity mbps (megabits / 0) given
      // a 0ms time difference, so consider mbps to be 0
      return 0;
    }

    const difference_s = difference_ms / 1000;
    // e.g. 125000 bytes = 1 megabit
    const progress_megabits = progress_bytes / BYTES_PER_MEGABIT;

    return progress_megabits / difference_s;
  }

  static getSpeedTestFilename(): string  {
    return "test-config-pwa.json";
  }

  static async getTestConfig() {
    return axios
      .get(Utility.getBaseUrl() + Utility.getSpeedTestFilename(), {
        responseType: "json",
      })
      .then((resp: { data: any; }) => {
        return resp.data;
      })
      .catch((err: any) => {
        const message = `Error retrieving test config: ${err}`;
        Utility.logger.error(message);
      });
  }

  /**
   * This gets the authoritative test-config.json file. When changes are made to the local
   * test-config file and thoroughly tested such that it will not cause issues for existing desktop
   * clients, Azure should be updated.
   *
   * Preview of files:
   * Dev (not in use because versioning in place): https://portal.azure.com/#@plantronics.onmicrosoft.com/resource/subscriptions/ec74cb7f-61ed-4766-911a-df085c1945c1/resourceGroups/GP-dev-eastus2-storage/providers/Microsoft.Storage/storageAccounts/gpdeveus2prov/storageexplorer
   * Prod: https://portal.azure.com/#@plantronics.onmicrosoft.com/resource/subscriptions/0202072f-b13a-4fa1-8720-8fa8285c6a2e/resourceGroups/GP-prod-eastus2-storage/providers/Microsoft.Storage/storageAccounts/gpprodeus2prov/storageexplorer
   * Then navigate to BLOB CONTAINERS -> $web -> static/speedtest/v2
   */
  // TODO:
  // static async getLocalTestConfig() {
  //   console.log("XXX Utility.getLocalTestConfig");
  //   return new Promise((resolve, reject) => {
  //     fs.readFile(
  //       path.join(__dirname, "../assets/speedtest/test-config.json"),
  //       (err, data) => {
  //         if (err) {
  //           const message = `Error retrieving local test config: ${err}`;
  //           Utility.logger.error(message);
  //           reject(message);
  //
  //           return;
  //         }
  //
  //         try {
  //           const json = JSON.parse(data);
  //
  //           // for local test config, if the isolate key is set to true, only return that test
  //           const isolatedTest = _.find(json.tests, ["isolate", true]);
  //           if (isolatedTest) {
  //             json.tests = [isolatedTest];
  //           }
  //
  //           resolve(json);
  //         } catch (e) {
  //           const parse_error = `Error parsing local test config: ${e}`;
  //           Utility.logger.error(parse_error);
  //           reject(parse_error);
  //         }
  //       }
  //     );
  //   });
  // }

  static getBaseUrl() {
    return `https://speedtest.lens.poly.com/static/v${Utility.version}/`;
  }

  /**
   * Note this uses the known base URL, same as config. For the foreseeable
   * future, the test-config.json and download files will be on the same CDN.
   *
   * @param size
   */
  static getDownloadUrl(size: number) {
    // no cache busting, since node does not cache like a browser would,
    // and it is preferable to not have CDN do an origin pull
    return Utility.getBaseUrl() + `${size}k.bin`;
  }

  static computeAverage(values: Array<number | bigint>): number {
    let result = Utility.prepareValues(values, true);

    if (!result || 0 === values.length) {
      return 0;
    }

    return _.mean(result);
  }

  /**
   * Converts all bigint values (time in nanoseconds) to numbers.
   *
   * @param values
   * @param removeNull
   */
  static prepareValues(
    values: Array<number | bigint>,
    removeNull: boolean
  ): Array<number> {
    if ("object" !== typeof values) {
      return values;
    }

    let result = values;

    if (removeNull) {
      result = result.filter((val) => null !== val);
    }

    result = result.map((val) => Utility.nanoToMS(val));

    return result as Array<number>;
  }

  static round(num: number | bigint): number {
    return Math.round(Utility.nanoToMS(num));
  }

  static computeMin(values: Array<number | bigint>): number {
    let result = Utility.prepareValues(values, true);

    if (!result || 0 === values.length) {
      return 0;
    }

    return _.min(result);
  }

  static computeMax(values: Array<number>): number {
    let result = Utility.prepareValues(values, true);

    if (!result || 0 === values.length) {
      return 0;
    }

    return _.max(result);
  }

  static simpleHash(str: string): number {
    const hash = str.split("").reduce((a, b) => {
      a = (a << 5) - a + b.charCodeAt(0);
      return a & a;
    }, 0);

    // if negative value, prefix absolute value with a 1 and return
    if (hash < 0) {
      return Number("1" + Math.abs(hash));
    }

    return hash;
  }
}
