import { Component, OnDestroy } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { Features, StateService } from "../../../services/state.service";
import { TranslatedOptions } from "../../../shared/components/dropdown/dropdown.component";
import { EXPERIMENTAL_LANGUAGES } from "../../../utils/constants";
import { difference as _difference } from "lodash";
import { Subscription } from "rxjs";

@Component({
  selector: "oz-lens-settings-language",
  templateUrl: "./lens-settings-language.component.pug",
})
export class LensSettingsLanguageComponent implements OnDestroy {
  // not using `new Subscriptions` pattern because these subscriptions need to unsubscribe at different times
  private translationSub: Subscription;
  private featureSub: Subscription;
  public currentLang: string;
  public languages: Array<string> = [];
  public translatedLanguages: TranslatedOptions = [];

  constructor(
    private translateService: TranslateService,
    private stateService: StateService
  ) {
    this.initLanguages();
    this.watchForExperimentalLanguagesFeature();
  }

  initLanguages() {
    this.currentLang = this.translateService.currentLang;
    this.languages = this.translateService.getLangs();

    const keys = this.languages.map((key) => `LANGUAGES.${key}`);

    this.translatedLanguages = keys.map((value) => {
      const key = value.replace(/^LANGUAGES\./, "");
      return {
        value: key,
        text: key,
      };
    });

    // empty keys (e.g. in test runner) means nothing to translate
    if (!keys.length) {
      return;
    }

    this.translationSub?.unsubscribe();
    this.translationSub = this.translateService
      .stream(keys)
      .subscribe((translated) => {
        // Update options array in-place, otherwise rendered dropdown doesn't properly reflect current selection
        Object.keys(translated).forEach((value) => {
          const key = value.replace(/^LANGUAGES\./, "");
          const index = this.translatedLanguages.findIndex(
            (opt) => opt.value === key
          );
          this.translatedLanguages[index].text = `${translated[value]}`.replace(
            /^LANGUAGES\./,
            ""
          );
        });

        this.translatedLanguages.sort((a, b) => (a.text > b.text ? 1 : -1));
      });
  }

  watchForExperimentalLanguagesFeature() {
    const featureKey: keyof Features = "experimentalLanguages";
    this.featureSub = this.stateService
      .getDeepState$("Features", featureKey, false)
      .subscribe((experimentalLanguages) => {
        if (experimentalLanguages) {
          // this will fill in all the missing language keys, and can be run multiple times
          // (e.g. difference will be an empty array on second run)
          const newLanguageKeys = _difference(
            EXPERIMENTAL_LANGUAGES,
            this.languages
          );

          if (newLanguageKeys.length) {
            this.translateService.addLangs(newLanguageKeys);
          }

          this.initLanguages();
        }
      });
  }

  changeLanguage() {
    this.translateService.use(this.currentLang);
  }

  ngOnDestroy() {
    this.translationSub?.unsubscribe();
    this.featureSub?.unsubscribe();
  }
}
