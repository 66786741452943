import { Injectable } from "@angular/core";
import { DFUError } from "@poly/hub-native";
import { MS_IN_SECOND } from "../utils/constants";
import { DeviceManagerService, OzDevice } from "./device-manager.service";
import { Observable, ReplaySubject, timer } from "rxjs";
import { multicast, refCount, switchMap } from "rxjs/operators";

// TODO: UI: Native (API) should have subscription-based API instead of UI polling for the DFU warnings

// Composes device ID. This is important for polling DFUError[] as some
// devices change device.id while keeping the same device.unqiueId
function composeId(device: OzDevice): string {
  return device.uniqueId + device.id;
}

@Injectable({
  providedIn: "root",
})
export class DfuWarnings {
  private map: {
    [composedId: string]: Observable<DFUError[]>;
  } = {};

  constructor(private deviceManager: DeviceManagerService) {}
  /**
   * Polls for a list of DFU errors (every 5 seconds) until a subscription is unsubscribed.
   */
  poll(device: OzDevice): Observable<DFUError[]> {
    const composedIds = Object.getOwnPropertyNames(this.map);
    const composedId = composeId(device);
    if (composedIds.includes(composedId)) {
      return this.map[composedId];
    } else {
      const obs = new Observable((ob) => {
        const sub = timer(0, 5 * MS_IN_SECOND)
          .pipe(switchMap(() => this.deviceManager.canPerformDFU(device.id)))
          .subscribe(ob);
        return () => {
          sub.unsubscribe();
          delete this.map[composeId(device)];
        };
      }).pipe(
        multicast(() => new ReplaySubject<DFUError[]>(1)),
        refCount()
      );
      this.map[composedId] = obs;
      return obs;
    }
  }
}
