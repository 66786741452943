import { ILoggingService, LOG } from "../../app/services/logging.service";
import { Battery } from "../api/device-manager.api";
import { BrEventHandler, BrHid, BrMsgView, BR_MT, BR_REQ } from "./br-hid";
import { UnsupportedDeviceError, ErrorResponseError } from "./errors";
import { BitDef, DECKARD, deviceSettingDefs } from "./settings-def";
import { WhDevice } from "./wh-device";
import { hex, hex4l } from "./wh-utils";


function dId(i: number | undefined) {
  return undefined !== i ? hex(i) : '---'
}


interface DeckardEncode {
  cmdId?: DECKARD;
  getId?: DECKARD;
  type?: string;
  bit?: BitDef;
  map: Map<string, number>;
}

interface DeckardDecode {
  settingId: number;
  type?: string;
  bit?: BitDef;
  map: Map<number, string>
}


export class BrDevice implements BrEventHandler {
  static make(device: WhDevice, hidDevice: HIDDevice, inBrReportId: number, inBrReportSize: number,
    outBrReportId: number, outBrReportSize: number, logger: ILoggingService): BrDevice {
      return new BrDevice(device, 0, logger,
        (handler) => new BrHid(handler, hidDevice, inBrReportId, inBrReportSize, outBrReportId, outBrReportSize, logger));
  }

  makeChild(device: WhDevice, port: number, logger: ILoggingService): BrDevice {
    const result = new BrDevice(device, this.port2addr(port), logger, () => this.brHid);
    this.addChild(result);
    return result;
  }

  private constructor(private readonly device: WhDevice, private readonly brAddr: number, private logger: ILoggingService,
    maker: (handler: BrEventHandler) => BrHid) {
    this.brHid = maker(this);
  }

  onReport(dv: DataView) {
    this.brHid.onReport(dv)
  }

  onEvent(msg: BrMsgView): void {
    if (msg.address() === this.brAddr) {
      this.onEventOrValue(msg);
      return;
    }
    const child = this.children.find(child => child.brAddr === msg.address());
    if (undefined !== child) {
      child.onEventOrValue(msg);
    }
    else {
      if (LOG.DEBUG) this.logger.warn(`unknown BR address, ignored ${msg}`);
    }
  }

  loadDevice(): Promise<void> {
    return new Promise((resolve, reject) => {
      this.initialLoadCb = (error?: Error) => {
        this.initialLoadCb = undefined;
        if (undefined === error) resolve(); else reject(error);
      }
      this.brHid.postBrRequest(this.brAddr, BR_REQ.HOST_PROTO_VER, [0x01, 0x02, 0x00])
      .then(msg => this.onVersionResponse(msg))
      .catch(error => reject(error));
    });
  }

  inReportId() { return this.brHid.inReportId() }

  isInitialLoadDone(): boolean {
    return undefined === this.initialLoadCb;
  }

  supportsDeviceSetting(id: number): boolean {
    return this.sett2deckard.has(id)
  }

  supportsBtPairing(): boolean {
    return this.supportedGetIds.has(DECKARD.PAIRING_MODE_SETTING);
  }

  private addChild(child: BrDevice) {
    this.children.push(child);
  }

  private loadBrSetting(setting: DECKARD): Promise<BrMsgView> {
    if (LOG.DEBUG) this.logger.info(`loadBrSetting ${hex4l(setting)}`);
    return this.brHid.postBrRequest(this.brAddr, BR_REQ.GET_SETTING, [setting >> 8, setting & 0xFF])
    .then(msg => { this.onGetResponse(msg); return msg; })
  }

  private onGetResponse(msg: BrMsgView) {
    if (BR_MT.SETTING_FAILURE === msg.type()) {
      const exc = msg.payloadUint16();
      this.logger.error(`get ${hex4l(msg.deckardType())} failed, exception=${hex4l(exc)}`);
    }
    else {
      this.onEventOrValue(msg);
    }
  }

  private static encode(encode: DeckardEncode, val: number): number[] | undefined {
    let payload: Array<number> | undefined;
    if ("uint8" === encode.type) {
      payload = [val]
    }
    else if ("uint16" === encode.type) {
      payload = [val >> 8, val & 0xFF]
    }
    else if ("none" === encode.type) {
      payload = []
    }
    else if (undefined !== encode.bit) {
      payload = new Array<number>(2 * encode.bit.size).fill(0);
      const dwordOffset = Math.trunc(encode.bit.bit / 32)
      const byteOffset = Math.trunc(encode.bit.bit / 8)
      const mask = 1 << (7 - (encode.bit.bit % 8))
      let maskOffset = 4 * dwordOffset + byteOffset
      let valOffset = maskOffset
      if (encode.bit.order === "bitmask_1st") {
        valOffset += 4
      }
      else {
        maskOffset += 4
      }
      payload[maskOffset] = mask;
      if (0 !== val) {
        payload[valOffset] = mask;
      }
    }
    return payload
  }

  private static decode(decode: DeckardDecode, msg: BrMsgView): number | undefined {
    let val: number | undefined;
    if ("uint8" === decode.type) {
      val = msg.payloadUint8At(0);
    }
    else if ("uint16" === decode.type) {
      val = msg.payloadUint16At(0);
    }
    else if (undefined !== decode.bit) {
      const offset = Math.trunc(decode.bit.bit / 8)
      const mask = 1 << (7 - (decode.bit.bit % 8))
      val = (msg.payloadUint8At(offset) & mask) ? 1 : 0
    }
    return val
  }

  private setDeviceSetting(id: number, value: string): Promise<void>
  {
    const encode = this.sett2deckard.get(id);
    if (encode) {
      const val = "none" !== encode.type ? encode.map.get(value) : 0;
      if (undefined !== val) {
        const cmd = encode.cmdId;
        const payload = BrDevice.encode(encode, val);
        if (undefined !== cmd && undefined !== payload) {
          if (LOG.DEBUG) this.logger.info(`sendBrSetting ${hex4l(id)} deckard=${hex4l(cmd)}: ${value}`);
          const bytes = [cmd >> 8, cmd & 0xFF].concat(payload);
          return new Promise<void>((resolve, reject) => {
            this.brHid.postBrRequest(this.brAddr, BR_REQ.CMD, bytes)
            .then(msg => {
              if (BR_MT.CMD_FAILURE === msg.type()) {
                const exc = msg.payloadUint16();
                reject(new ErrorResponseError(`cmd ${hex4l(msg.deckardType())} failed, exception=${hex4l(exc)}`));
              }
              else {
                resolve();
              }
            })
            .catch(err => reject(err))
          })
        }
      }
      this.logger.error(`setting ${hex4l(id)}: unsupported value ${value} or type ${encode.type}`);
    }
    else {
      this.logger.error(`setting ${hex4l(id)}: unsupported`);
    }
    return Promise.reject(new Error(`Unsupported or invalid setting (${hex4l(id)} ${value})`))
  }

  private processMetadata(msg: BrMsgView): void {
    const cmdIds = new Set<number>();
    const eventIds = new Set<number>();
    let l = msg.payloadUint16();
    let s = 'supported sets:';
    for (let i = 0; i < l; ++i) {
      const id = msg.payloadUint16();
      cmdIds.add(id);
      s = s + ' ' + hex(id).padStart(3, '0');
    }
    l = msg.payloadUint16();
    let s2 = 'supported gets:';
    for (let i = 0; i < l; ++i) {
      const id = msg.payloadUint16();
      this.supportedGetIds.add(id);
      s2 = s2 + ' ' + hex(id).padStart(3, '0');
    }
    if (LOG.DEBUG) this.logger.info(s2);
    if (LOG.DEBUG) this.logger.info(s);
    l = msg.payloadUint16();
    s = 'supported events:';
    for (let i = 0; i < l; ++i) {
      const id = msg.payloadUint16();
      eventIds.add(id);
      s = s + ' ' + hex(id).padStart(3, '0');
    }
    if (LOG.DEBUG) this.logger.info(s);
    this.setupMetadata(cmdIds, eventIds);

    if (!this.supportedGetIds.has(DECKARD.SET_ID)) {
      this.device.deleteSetId();
    }
  }

  private setupMetadata(cmdIds: Set<number>, eventIds: Set<number>): void {
    const settings = this.device.settingDefs();
    for (let sett of settings) {
      if (sett.deckard) {
        const d = sett.deckard
        const cmdId1 = d.cmd ?? d.id;
        const getId1 = d.get ?? d.id;
        const eventId1 = d.event ?? d.id;
        const cmdId = undefined !== cmdId1 && cmdIds.has(cmdId1) ? cmdId1 : undefined;
        const getId = undefined !== getId1 && this.supportedGetIds.has(getId1) ? getId1 : undefined;
        const eventId = undefined !== eventId1 && eventIds.has(eventId1) ? eventId1 : undefined;
        if (sett.values.length !== d.values.length) {
          this.logger.error(`value array mismatch for ${hex4l(sett.id)}`);
        }
        if (undefined !== cmdId || undefined !== getId) {
          const s2n = sett.values.reduce((map, s, idx) => map.set(s, d.values[idx]), new Map<string, number>());
          this.sett2deckard.set(sett.id, { cmdId: cmdId, getId: getId, type: d.type, bit: d.bit, map: s2n })
        }

        const n2s = sett.values.reduce((map, s, idx) => map.set(d.values[idx], s), new Map<number, string>());
        const dd: DeckardDecode = { settingId: sett.id, type: d.type, bit: d.bit, map: n2s }
        if (undefined !== getId) {
          this.addDecoder(getId, dd)
        }
        if (undefined !== eventId && getId !== eventId) {
          this.addDecoder(eventId, dd)
        }

        const s = sett.values.reduce((log, s, idx) => log + `${s}=${d.values[idx]} `, '')
        if (LOG.DEBUG) this.logger.info(`setting ${hex4l(sett.id)}: deckard ${dId(getId)}/${dId(cmdId)}/${dId(eventId)} [${s}]`);
      }
    }
  }

  private addDecoder(id: number, dd: DeckardDecode) {
    let decoders = this.deckard2sett.get(id)
    if (undefined === decoders) {
      decoders = []
      this.deckard2sett.set(id, decoders)
    }
    decoders.push(dd)
}

  private processDevSetting(msg: BrMsgView): boolean {
    const deckardType = msg.deckardType();
    if (undefined !== deckardType) {
      const decoders = this.deckard2sett.get(deckardType)
      if (undefined !== decoders) {
        decoders.forEach(decode => {
          const val = BrDevice.decode(decode, msg);
          const logPrefix = `setting ${hex4l(decode.settingId)}` + (LOG.DEBUG ? ` deckard=${hex4l(deckardType)}:` : ':');
          if (undefined !== val) {
            const value = decode.map.get(val);
            if (undefined === value) {
              this.logger.error(`${logPrefix} unmapped value (${val})`)
            }
            else if (this.device.storeSetting(decode.settingId, value)) {
              if (LOG.DEBUG) this.logger.info(`${logPrefix} read ${value}(${val})`);
            }
            else {
              if (LOG.DEBUG) this.logger.info(`${logPrefix} unchanged "${value}"`);
            }
          }
          else {
            this.logger.error(`${logPrefix} unsupported type=${decode.type}`)
          }
        })
        return true
      }
    }
    return false
  }

  private onEventOrValue(msg: BrMsgView) {
    if (LOG.DEBUG) this.logger.debug(`BR recv ${msg}`);
    const settType = msg.deckardType();
      if (!this.processDevSetting(msg)) {
        if (DECKARD.TATTOO_SERIAL_NO === settType) {
          this.device.setSerialNo(msg.payloadAscii());
        }
        else if (DECKARD.TATTOO_BUILD_CODE === settType) {
          this.device.setBuildCode(msg.payloadAscii());
        }
        else if (DECKARD.FIRMWARE_VER === settType) {
          const extVer = msg.payloadUint16();
          const usbVer = msg.payloadUint16()
          if (LOG.DEBUG) this.logger.info(`usb=${hex4l(usbVer)}(${usbVer}) extver=${hex4l(extVer)}(${extVer})`)
          this.device.setUsbVersion(usbVer);
          if (this.supportedGetIds.has(DECKARD.BLUETOOTH_ADDRESS)) {
            this.device.setBluetoothVersion(usbVer);
          }
        }
        else if (DECKARD.HARDWARE_REVISION === settType) {
          this.device.setHwVer(msg.payloadAscii());
        }
        else if (DECKARD.PART_NO === settType) {
          this.device.setPartNo(msg.payloadUint16());
        }
        else if (DECKARD.MODEL_ID === settType) {
          this.device.setModelId(hex(msg.payloadUint16()));
        }
        else if (DECKARD.SET_ID === settType) {
          const major = hex(msg.payloadUint16());
          const minor = hex(msg.payloadUint16());
          const revision = hex(msg.payloadUint16());
          const build = hex(msg.payloadUint16());
          this.device.setSetId({ major, minor, revision, build });
        }
        else if (DECKARD.GENES_GUID === settType) {
          const a = msg.payloadUint8Array();
          this.device.setUniqueId(a.reduce((s, i) => s + hex(i).toUpperCase().padStart(2, '0'), ''));
        }
        else if (DECKARD.MIC_MUTE_STATE === settType) {
          this.device.setMuted(0 !== msg.payloadUint8());
        }
        else if (DECKARD.HEADSET_AVAILABLE === settType) {
          this.device.setHeadsetConnectedState(0 !== msg.payloadUint8());
        }
        else if (DECKARD.LANGUAGE === settType) {
          this.device.setLang(msg.payloadUint16());
        }
        else if (DECKARD.VENDOR_ID === settType) {
          this.device.setVendorId(msg.payloadUint16());
        }
        else if (DECKARD.PRODUCT_NAME === settType) {
          this.device.setProductName(msg.payloadAscii());
        }
        else if (DECKARD.CONNECTED_DEVICE === settType) {
          if (0 === msg.address()) {
            this.childConnected(msg.payloadUint8());
          }
        }
        else if (DECKARD.DISCONNECTED_DEVICE === settType) {
          this.childDisconnected(msg.payloadUint8());
        }
        else if (0xFF0F === settType) {
          const type = msg.payloadUint16();
          if (2 === type) {
            const s = msg.payloadAscii();
            if (LOG.DEBUG) this.logger.info(`debug from ${hex4l(msg.address())}: ${s}`);
          }
        }
        else if (DECKARD.BATTERY_INFO === settType || DECKARD.BATTERY_STATUS === settType) {
          const curLevel = msg.payloadUint8At(0);
          const numLevels = msg.payloadUint8At(1);
          const battery : Battery = {
            chargeLevel: Math.round((curLevel / numLevels) * 100 / 25) + 1,
            level: curLevel,
            charging: msg.payloadUint8At(2) !== 0,
            isChargeLevelValid: true,
            numLevels: numLevels,
            docked: false, //TODO/JTB: does this come from the payload?
          };
          if (LOG.DEBUG) this.logger.info(`Battery info ${curLevel} of ${numLevels - 1}`, battery);
          this.device.setBatteryStatus(battery);
        }
        else if (DECKARD.BATTERY_SOC_REPORT === settType) {
          if (LOG.DEBUG) {
            const device = msg.payloadUint8();
            const state = msg.payloadUint8();
            const startTime = msg.payloadUint32();
            const endTime = msg.payloadUint32();
            const duration = msg.payloadUint32();
            const prevState = msg.payloadUint8();
            this.logger.info(`device=${device} state=${state} prev-state=${prevState} ${startTime}-${endTime}ms ${duration}ms`);
          }
        }
      }
  }

  private onVersionResponse(msg: BrMsgView) {
    if (BR_MT.HOST_PROTO_VER_FAILURE === msg.type()) {
      if (undefined !== this.initialLoadCb) {
        const loadCb = this.initialLoadCb;
        this.initialLoadCb = undefined;
        loadCb(new UnsupportedDeviceError("Incompatible Bladerunner version"))
      }
    }
    else if (undefined !== this.initialLoadCb) {
      let promise = Promise.resolve();
      // we need USB PID for non-root devices
      if (0 !== this.brAddr) {
        promise = promise.then(() => this.loadBrSetting(DECKARD.USB_PID).then(msg2 => {
          if (BR_MT.SETTING_SUCCESS == msg2.type()) {
            this.device.setProductId(msg2.payloadUint16());
            return Promise.resolve();
          }
          // ignore child devices with no PID (e.g. Kalimba in Sync 10 or in Voyager 5200 charge case)
          return Promise.reject(new UnsupportedDeviceError("No USB PID"))
        }));
      }
      promise = promise.then(() => this.loadInitialState(msg))
      const loadCb = this.initialLoadCb;
      promise.then(() => loadCb(), error => loadCb(error));
    }
  }

  private loadInitialState(msg: BrMsgView): Promise<void> {
    this.processMetadata(msg);
      let promise = [
        DECKARD.TATTOO_SERIAL_NO,
        DECKARD.TATTOO_BUILD_CODE,
        DECKARD.FIRMWARE_VER,
        DECKARD.HARDWARE_REVISION,
        DECKARD.PART_NO,
        DECKARD.MODEL_ID,
        DECKARD.SET_ID,
        DECKARD.GENES_GUID,
        DECKARD.LANGUAGE,
        DECKARD.MIC_MUTE_STATE,
        DECKARD.HEADSET_AVAILABLE,
        DECKARD.BATTERY_INFO
      ].filter(id => this.supportedGetIds.has(id))
      .reduce((promise, id) => promise.then(msg => this.loadBrSetting(id)), Promise.resolve(msg));

      // child devices need USB VID, PID and name from Deckard
      if (0 !== this.brAddr) {
        promise = promise.then(() => this.loadBrSetting(DECKARD.VENDOR_ID));
        promise = promise.then(() => this.loadBrSetting(DECKARD.PRODUCT_NAME));
      }

      const sent = new Set<number>();
      this.sett2deckard.forEach((de, id) => {
        this.device.addSetting(id, de.map.keys(), (val: string) => this.setDeviceSetting(id, val));
        if (undefined !== de.getId && !sent.has(de.getId)) {
          const getId = de.getId;
          sent.add(getId);
          promise = promise.then(msg => this.loadBrSetting(getId))
        }
      })
    return promise.then(() => {});
  }

  private childConnected(port: number): void {
    const address = this.port2addr(port)
    const i = this.children.findIndex(c => c.brAddr === address);
    if (0 <= i) {
      this.logger.info(`Duplicate child device connect at address ${hex4l(address)}`);
      return;
    }
    if (LOG.DEBUG) this.logger.info(`New child device connected at address ${hex4l(address)}`);
    this.device.addBrChild(port)
    .catch(() => this.children = this.children.filter(c => c.brAddr !== address));
  }

  private childDisconnected(port: number): void {
    const address = this.port2addr(port)
    const i = this.children.findIndex(c => c.brAddr === address);
    if (i < 0) {
      this.logger.info(`Unknown child device disconnected at address ${hex4l(address)}`);
      return;
    }
    const [child] = this.children.splice(i, 1);
    if (LOG.DEBUG) this.logger.info(`Child device(${child.device.deviceId()}) disconnected at address ${hex4l(address)}`);
    this.device.removeChild(child.device);
  }

  private port2addr(port: number): number {
    // drop trailing zeros and append port
    const s = '0x' + this.brAddr.toString(16).replace(/0+$/, port.toString(16) + '000000').substring(0, 6)
    return parseInt(s)
  }

  forEachChild(callback: (value: WhDevice) => void): void {
    this.children.forEach(ch => callback(ch.device));
  }

  private initialLoadCb?: (error?: Error) => void;
  private brHid: BrHid;
  private children = new Array<BrDevice>();
  private sett2deckard = new Map<number, DeckardEncode>();
  private deckard2sett = new Map<number, Array<DeckardDecode>>();
  private supportedGetIds = new Set<number>();
}
