import {
  OpenDialogReturnValue,
  SaveDialogReturnValue} from "./polytron.api";
import { Injectable } from "@angular/core";
import {MessagesChannel} from "../../IPC/MessagesChannel";
import {IPCEvent} from "../../IPC/IpcChannelInterface";
import {IpcRenderer, PolytronServiceApi} from "./polytron.service.api";

@Injectable({
  providedIn: "root",
})
export class PolytronEventEmitter  extends IpcRenderer {
  constructor(
    private messageChannel: MessagesChannel
  ) {
    super();
  }

  send(channel: string, ...args: any[]): void {
    if (channel === "messages") {
      args.forEach(arg => {
        this.messageChannel.handle(new IPCEvent(this), arg);
      })
    }
  }
}

@Injectable({
  providedIn: "root",
})
export class PolytronService implements PolytronServiceApi{
  constructor(
    private ipcRenderer: PolytronEventEmitter,
  ) {}

  getIpcRenderer(): PolytronEventEmitter {
    return this.ipcRenderer;
  }

   askForCameraAccess() : Promise<boolean> {
     return navigator.permissions.query({name: 'camera'}).then((p) => {
       switch(p.state) {
         case 'granted': return true;
         case 'denied': return false;
         case 'prompt': {
           const opts:any = { video: {}};
           opts.video['pan'] = opts.video['tilt'] = opts.video['zoom'] = true;
           try {
             navigator.mediaDevices.getUserMedia(opts);
             return true;
           } catch (e) {
             console.error('')
           }
         }
         return false;
       }
     });
   }

  getPath(name: string): string {
    return name;
  }

  async showSaveDialog(options: {[key: string]: any}): Promise<SaveDialogReturnValue> {
    console.log("PolytronService.showSaveDialog(options: [" + JSON.stringify(options, null, 2) + "])");
    const opts = {
      suggestedName: options['suggestedName'],
      types: [{
        description: options['description'],
        accept: options['filters'],
      }]
    };

    let retVal:SaveDialogReturnValue;

    try {
      let fileHandle = await window.showSaveFilePicker(opts);
      let file = await fileHandle.createWritable();
      retVal =  {
        bookmark: "",
        canceled: false,
        filePath: fileHandle.name,
        writableFile: file
      };
    }
    catch (e) {
      console.error(e);
      retVal = {
        bookmark: "",
        canceled: true,
        filePath: "",
      };
    }
    return retVal;
  }

  getAppPath(): string {
    return "";
  }

  quit() {
  }

  quitable(): boolean {
    return false;
  }

  async showOpenDialog(options: {[key: string]: any}): Promise<OpenDialogReturnValue> {
    console.log("PolytronService.showOpenDialog(options: [" + JSON.stringify(options, null, 2) + "])");

    // TODO: what options do we want?
    const opts:OpenFilePickerOptions = {
      // multiple: ,
      // types:,
      // excludeAcceptAllOption: ,
    };

    let retVal: OpenDialogReturnValue;

    try {
      let fileHandles = await window.showOpenFilePicker(opts);
      let filePaths:string[] = [];
      fileHandles.forEach(handle => {
        // TODO: What is usable to return?
        filePaths.push(handle.name);
      });
      retVal = {
        bookmarks: [],
        canceled: false,
        filePaths: filePaths,
      }
    } catch (e) {
      console.error(e);
      retVal = {
        bookmarks: [],
        canceled: true,
        filePaths: []
      }
    }

    return retVal;
  }
}
