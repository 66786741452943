import {BYTES_PER_KIBIBYTE, Utility,} from "./Utility";
import {throttle, throttle as _throttle} from "lodash";
import {ILoggingService} from "../app/services/logging.service";
import {BaseTest, TransferType} from "./BaseTest";

/**
 * Architectural considerations:
 * - Run multiple tests of increasing size.  Running a single large test (e.g. 500MB file)
 *   is not a real world use case, though it may get closer to the theoretical max of
 *   the pipeline.  Also consider Speedtest.net and speedof.me perform multiple
 *   tests even though XHR requests can be destroyed.
 */
export class UploadTest extends BaseTest {
  public constructor(
    protected options: any,
    protected logger:ILoggingService) {
    super(TransferType.UPLOAD, options, logger);
  }

  protected runTest(size: number) {
    const activeStreams = this.getActiveStreamKeys().length;

    if (activeStreams < this.options.simultaneous_streams) {
      if ("udp" === this.options.protocol) {
        console.log("Web app cannot use UDP: test not implemented");
      } else {
        this.uploadTCP(size);
      }
    }
  }

  private async uploadTCP(size: number) {
    // initialize stream

    const streamKey = this.getNewStreamKey(size);
    const stream = (this.streams[streamKey] = []);
    this.streamsActive[streamKey] = true;
    this.streamsCompleted[streamKey] = false;
    const controller = new AbortController();
    const signal = controller.signal;
    this.requests[streamKey] = controller;

    const saveStatusThrottled = throttle(
      this.saveStatus,
      this.update_interval_ms,
      {
        leading: true,
        trailing: true,
      }
    );

    const uploadSize = BYTES_PER_KIBIBYTE * size;
    const postData = Utility.randomString(uploadSize);
    const url = this.options.address;
    const startTs = Utility.getStampMS();
    let lastTs:number = startTs;
      saveStatusThrottled(stream, {
      transfer_type: this.transferType,
      start_stamp_ms: startTs,
      size_bytes: uploadSize,
      progress_bytes: 0
    });
    await fetch(url, {
      method: "POST",
      mode: "cors",
      cache: "no-cache",
      headers: {
        "Content-Type": "text/plain",
        // "Access-Control-Allow-Headers": "*",
        // "Access-Control-Allow-Origin": "*",
        // "Access-Control-Allow-Private-Network": "true"
      },
      redirect: "error",
      body: postData,
      signal: signal,
    })
      .then((response) => {
        lastTs = Utility.getStampMS();
        return response.text()
      })
      .then((data) => {
        let dataSizes: string[] = data.split(":");
        const lastVal = Number.parseInt(dataSizes[dataSizes.length - 1]);
        saveStatusThrottled(stream, {
          transfer_type: this.transferType,
          start_stamp_ms: lastTs,
          size_bytes: uploadSize,
          progress_bytes: lastVal
        });
        setTimeout(() => {
          if (size > this.largest_completed_kib) {
            this.largest_completed_kib = size;
          }
          this.streamCleanup(streamKey);
        }, this.update_interval_ms * 1.2);
      })
      .catch((e) => {
        if(e.name !== "AbortError") {
          this.logger.error("Upload failure", e);
        }
        this.streamCleanup(streamKey);
      });
  }
}
