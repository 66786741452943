import { ConfirmComponent } from "./confirm/confirm.component";
import { AudioTestComponent } from "./audio-test/audio-test.component";
import { EulaComponent } from "./eula/eula.component";
import { LensSettingsAboutComponent } from "./lens-settings/lens-settings-accordion-items/lens-settings-about/lens-settings-about.component";
import { LensAppSettingsComponent } from "./lens-settings/lens-settings-accordion-items/lens-app-settings/lens-app-settings.component";
import { SettingOptionsPipe } from "./shared/components/dropdown/setting-options.pipe";
import { ConditionalOptionsPipe } from "./shared/components/dropdown/conditional-options.pipe";
import { DeviceNamePipe } from "./shared/pipes/device-name.pipe";
import { TranslateCollectionPipe } from "./shared/pipes/translate-collection.pipe";
import { UserGuideComponent } from "./user-guide/user-guide.component";
import { GettingStartedComponent } from "./getting-started/getting-started.component";
import { ComingSoonComponent } from "./coming-soon/coming-soon.component";
import { ModalComponent } from "./modal/modal.component";
import { HowNBWorksModalComponent } from "./shared/components/how-nb-works-modal/how-nb-works-modal.component";
import { AnimatedRouterOutletComponent } from "./shared/components/animated-router-outlet/animated-router-outlet.component";
import { AppComponent } from "./app.component";
import { AppLayoutComponent } from "./app-layout/app-layout.component";
import { AccordionComponent } from "./shared/components/accordion/accordion.component";
import { SecretTrigger } from "./secret/secret.trigger.component";
import { SecretComponent } from "./secret/secret.component";
import { ControlsComponent } from "./controls/controls.component";
import { DropdownDeprecatedComponent } from "./shared/components/dropdown/dropdown-deprecated.component";
import { EditCustomNameComponent } from "./shared/components/edit-custom-name/edit-custom-name.component";
import { PageNotFoundComponent } from "./shared/components";
import { CtaItemComponent } from "./cta/cta-item/cta-item.component";
import { CtaComponent } from "./cta/cta/cta.component";
import { LoadingComponent } from "./loading/loading.component";
import { SidebarComponent } from "./sidebar/sidebar.component";
import { SlideshowComponent } from "./slideshow/slideshow.component";
import { IconComponent } from "./icon/icon.component";
import { InputPasswordComponent } from "./input-password/input-password.component";
import { HomeComponent } from "./home/home.component";
import { FtuComponent } from "./ftu/ftu.component";
import { DynamicCardComponent } from "./dynamic-card/dynamic-card.component";
import { DetailComponent } from "./detail/detail.component";
import { BestPracticesComponent } from "./best-practices/best-practices.component";
import { BPCameraComponent } from "./best-practices/camera/camera.component";
import { BPHeadsetComponent } from "./best-practices/headset/headset.component";
import { DeskSetupComponent } from "./best-practices/desk-setup/desk-setup.component";
import { HealthWellnessComponent } from "./health-wellness/health-wellness.component";
import { HydrationComponent } from "./health-wellness/hydration/hydration.component";
import { SoundscapingComponent } from "./health-wellness/soundscaping/soundscaping.component";
import { VisionComponent } from "./health-wellness/vision/vision.component";
import { RemindersModalComponent } from "./health-wellness/reminders-modal/reminders-modal.component";
import { DeviceOverviewComponent } from "./device-overview/device-overview.component";
import { DeviceSplitViewComponent } from "./device-overview/device-split-view/device-split-view.component";
import { DeviceOverviewBluetoothRemoteComponent } from "./device-overview-bluetooth-remote/device-overview-bluetooth-remote.component";
import { ConnectionChecklistComponent } from "./connection-checklist/connection-checklist.component";
import { SupportComponent } from "./support/support.component";
import { LensSettingsComponent } from "./lens-settings/lens-settings.component";
import { LensSettingsAccountComponent } from "./lens-settings/lens-settings-accordion-items/lens-settings-account/lens-settings-account.component";
import { LensSettingsSoftphoneComponent } from "./lens-settings/lens-settings-accordion-items/lens-settings-softphone/lens-settings-softphone.component";
import { LensSettingsNotificationsComponent } from "./lens-settings/lens-settings-accordion-items/lens-settings-notifications/lens-settings-notifications.component";
import { LensSettingsDeviceAlertsComponent } from "./lens-settings/lens-settings-accordion-items/lens-settings-device-alerts/lens-settings-device-alerts.component";
import { LensSettingsSoftwareUpdateComponent } from "./lens-settings/lens-settings-accordion-items/lens-settings-software-update/lens-settings-software-update.component";
import { PolySupportComponent } from "./poly-support/poly-support.component";
import { DeviceSettingsComponent } from "./device-settings/device-settings.component";
import { DeviceSettingsCategoryItemComponent } from "./device-settings/device-settings-category-item/device-settings-category-item.component";
import { DeviceSettingsLanguageItemComponent } from "./device-settings/device-settings-language-item/device-settings-language-item.component";
import { DeviceAdminSettingsComponent } from "./device-settings/device-admin-settings/device-admin-settings.component";
import { DeviceSettingsWifiItemComponent } from "./device-settings/device-settings-wifi-item/device-settings-wifi-item.component";
import { DeviceSettingsBluetoothItemComponent } from "./device-settings/device-settings-bluetooth-item/device-settings-bluetooth-item.component";
import { DeviceSettingsNtpItemComponent } from "./device-settings/device-settings-ntp-item/device-settings-ntp-item.component";
import { DeviceSettingsCertificatesItemComponent } from "./device-settings/device-settings-certificates-item/device-settings-certificates-item.component";
import { DeviceSettingsDisconnectItemComponent } from "./device-settings/device-settings-disconnect-item/device-settings-disconnect-item.component";
import { DeviceSettingsKeeplinkupItemComponent } from "./device-settings/device-settings-keeplinkup-item/device-settings-keeplinkup-item.component";
import { DeviceInfoAndLogsComponent } from "./device-info-and-logs/device-info-and-logs.component";
import { DfuProgressContainerComponent } from "./dfu-progress-container/dfu-progress-container.component";
import { DfuProgressComponent } from "./dfu-progress-container/dfu-progress/dfu-progress.component";
import { DfuRetryComponent } from "./dfu-progress-container/dfu-progress-retry/dfu-retry.component";
import { DfuDownloadArchiveComponent } from "./dfu-progress-container/dfu-download-archive-progress/dfu-download-archive-progress.component";
import { DeviceUsageAndNetworkComponent } from "./device-usage-and-network/device-usage-and-network.component";
import { DfuConfirmComponent } from "./dfu-confirm/dfu-confirm.component";
import { DfuModalComponent } from "./dfu-modal/dfu-modal.component";
import { BadgeComponent } from "./shared/components/badge/badge.component";
import { ButtonGroupComponent } from "./shared/components/button-group/button-group.component";
import { ButtonGroupButtonComponent } from "./shared/components/button-group/button-group-button.component";
import { CopyButtonComponent } from "./shared/components/copy-button/copy-button.component";
import { CheckboxComponent } from "./shared/components/checkbox/checkbox.component";
import { DropdownComponent } from "./shared/components/dropdown/dropdown.component";
import { DropdownWithIconsComponent } from "./shared/components/dropdown/dropdown-with-icons.component";
import { ReminderScheduleComponent } from "./shared/components/reminder-schedule/reminder-schedule.component";
import { SpeedIndicatorComponent } from "./shared/components/speed-indicator/speed-indicator.component";
import { ConnectionIndicatorComponent } from "./shared/components/connection-indicator/connection-indicator.component";
import { MultiselectDropdownComponent } from "./shared/components/multiselect-dropdown/multiselect-dropdown.component";
import { SwitchComponent } from "./shared/components/switch/switch.component";
import { SwitchDeprecatedComponent } from "./shared/components/switch/switch-deprecated.component";
import { ControlBarComponent } from "./shared/components/control-bar/control-bar.component";
import { LoadingIndicatorComponent } from "./shared/components/loading-indicator/loading-indicator.component";
import { ProgressBarComponent } from "./shared/components/progress-bar/progress-bar.component";
import { StepperComponent } from "./shared/components/stepper/stepper.component";
import { StepComponent } from "./shared/components/stepper/step/step.component";
import { BatteryLevelPipe } from "./shared/pipes/battery-level.pipe";
import { FirmwareVersionPipe } from "./shared/pipes/firmware-version.pipe";
import { PidPipe } from "./shared/pipes/pid.pipe";
import { DeviceImagePipe } from "./shared/pipes/device-image.pipe";
import { LensSettingsLanguageComponent } from "./lens-settings/lens-settings-accordion-items/lens-settings-language/lens-settings-language.component";
import { InputWithConfirmComponent } from "./shared/components/input-with-confirm/input-with-confirm.component";
import { InputTimeComponent } from "./shared/components/input-time/input-time.component";
import { WarningTooltipComponent } from "./shared/components/warning-tooltip/warning-tooltip.component";
import { DfuWarningTooltipContentComponent } from "./dfu-warning-tooltip-content/dfu-warning-tooltip-content.component";
import { FirmwareUpToDateComponent } from "./dfu-progress-container/firmware-up-to-date/firmware-up-to-date.component";
import { DfuReplugComponent } from "./dfu-progress-container/replug-device/replug-device.component";
import { DeviceNotSupportedComponent } from "./shared/components/device-not-supported/device-not-supported.component";
import { DeviceComponentsComponent } from "./device-components/device-components.component";
import { UpDownComponent } from "./shared/components/up-down/up-down.component";
import { NoiseblockAIAnimationComponent } from "./shared/components/animations/noiseblockai/noiseblockai.animation.component";
import { AcousticFenceAnimationComponent } from "./shared/components/animations/acoustic-fence/acoustic-fence.animation.component";
import { DeviceSettingsImportExportConfigComponent } from "./device-settings/device-settings-import-export-config/device-settings-import-export-config.component";
import { DeviceAdminLoginComponent } from "./device-settings/device-admin-login/device-admin-login.component";
import { DevicePasswordChangeComponent } from "./device-settings/device-password-change/device-password-change.component";
import { DeviceSettingRestartDeviceComponent } from "./device-settings/restart-device/restart-device.component";
import { DeviceSettingRestoreDefaultsComponent } from "./device-settings/restore-defaults/restore-defaults.component";
import { DeviceSettingClearTrustedDevicesComponent } from "./device-settings/device-settings-clear-trusted-devices/clear-trusted-devices.component";
import { DeviceSettingsGeneralItemComponent } from "./device-settings/device-settings-general-item/device-settings-general-item.component";
import { FtcWizardManualSetupComponent } from "./ftc-wizard-manual-setup/ftc-wizard-manual-setup.component";
import { FtcEnterPasswordComponent } from "./device-settings/device-ftc-settings/ftc-enter-password/ftc-enter-password.component";
import { PageComponent } from "./shared/components/stepper/page.component";
import { ToolbarComponent } from "./shared/components/stepper/toolbar.component";
import { FtcIdentityComponent } from "./device-settings/device-ftc-settings/ftc-identity/ftc-identity.component";
import { FtcCompletedComponent } from "./device-settings/device-ftc-settings/ftc-completed/ftc-completed.component";
import { FtcWizardImportedConfigComponent } from "./ftc-wizard-imported-config/ftc-wizard-imported-config.component";
import { DeviceSettingsProvisioningComponent } from "./device-settings/device-settings-provisioning/device-settings-provisioning.component";
import { ProvisioningFormComponent } from "./device-settings/device-settings-provisioning/provisioning-form.component";
import { FtcDeviceStartSetupComponent } from "./device-settings/device-ftc-settings/ftc-device-start-setup/ftc-device-start-setup.component";
import { FtcWizardCloseModalContentComponent } from "./ftc-wizard-close-modal-content/ftc-wizard-close-modal-content.component";
import { DfuCountBadgeComponent } from "./dfu-count-badge/dfu-count-badge.component";
import { StatusToastComponent } from "./shared/components/toast/status-toast/status-toast.component";
import { ActionToastComponent } from "./shared/components/toast/action-toast/action-toast.component";
import { PostponeDfuToastComponent } from "./shared/components/toast/postpone-dfu-toast/postpone-dfu-toast.component";
import { ToastsComponent } from "./shared/components/toast/toasts.component";
import { SortToastsPipe } from "./shared/components/toast/sort-toasts.pipe";
import { DeviceSettingsPlayGreetingComponent } from "./device-settings/device-software-settings/device-settings-play-greeting-item.component";
import { DeviceSoftwareSettingsComponent } from "./device-settings/device-software-settings/device-software-settings.component";

export const COMMON_COMPONENTS = [
  AppComponent,
  AnimatedRouterOutletComponent,
  AppLayoutComponent,
  AccordionComponent,
  BadgeComponent,
  ButtonGroupComponent,
  ButtonGroupButtonComponent,
  CopyButtonComponent,
  BestPracticesComponent,
  BPCameraComponent,
  BPHeadsetComponent,
  CheckboxComponent,
  DetailComponent,
  DeskSetupComponent,
  DynamicCardComponent,
  FtuComponent,
  HomeComponent,
  HealthWellnessComponent,
  HydrationComponent,
  IconComponent,
  InputPasswordComponent,
  InputWithConfirmComponent,
  InputTimeComponent,
  SoundscapingComponent,
  VisionComponent,
  RemindersModalComponent,
  ReminderScheduleComponent,
  DeviceOverviewComponent,
  DeviceSplitViewComponent,
  DeviceOverviewBluetoothRemoteComponent,
  ConnectionChecklistComponent,
  SupportComponent,
  LensSettingsComponent,
  LensSettingsAccountComponent,
  LensSettingsDeviceAlertsComponent,
  LensSettingsLanguageComponent,
  LensSettingsNotificationsComponent,
  LensSettingsSoftphoneComponent,
  LensSettingsSoftwareUpdateComponent,
  LensSettingsAboutComponent,
  LensAppSettingsComponent,
  AcousticFenceAnimationComponent,
  NoiseblockAIAnimationComponent,
  CheckboxComponent,
  BadgeComponent,
  LoadingIndicatorComponent,
  PageNotFoundComponent,
  PolySupportComponent,
  DeviceSettingsComponent,
  DeviceSettingsCategoryItemComponent,
  DropdownComponent,
  DropdownWithIconsComponent,
  SettingOptionsPipe,
  ConditionalOptionsPipe,
  TranslateCollectionPipe,
  MultiselectDropdownComponent,
  DropdownDeprecatedComponent,
  EditCustomNameComponent,
  DeviceInfoAndLogsComponent,
  DeviceUsageAndNetworkComponent,
  DfuConfirmComponent,
  DfuProgressContainerComponent,
  DfuProgressComponent,
  DfuRetryComponent,
  ConfirmComponent,
  AudioTestComponent,
  SpeedIndicatorComponent,
  ConnectionIndicatorComponent,
  DeviceNotSupportedComponent,
  DfuModalComponent,
  DfuDownloadArchiveComponent,
  SidebarComponent,
  SlideshowComponent,
  SwitchComponent,
  SwitchDeprecatedComponent,
  BatteryLevelPipe,
  FirmwareVersionPipe,
  PidPipe,
  LoadingComponent,
  CtaComponent,
  CtaItemComponent,
  ControlsComponent,
  SecretComponent,
  SecretTrigger,
  ControlBarComponent,
  ModalComponent,
  HowNBWorksModalComponent,
  ComingSoonComponent,
  GettingStartedComponent,
  UserGuideComponent,
  DeviceSettingsLanguageItemComponent,
  DeviceAdminSettingsComponent,
  DeviceSettingsGeneralItemComponent,
  DeviceSettingsWifiItemComponent,
  DeviceSettingsBluetoothItemComponent,
  DeviceSettingsNtpItemComponent,
  DeviceSettingsCertificatesItemComponent,
  DeviceSettingsDisconnectItemComponent,
  DeviceSettingsKeeplinkupItemComponent,
  FtcEnterPasswordComponent,
  FtcIdentityComponent,
  FtcCompletedComponent,
  ProgressBarComponent,
  StepperComponent,
  StepComponent,
  DeviceNamePipe,
  DeviceImagePipe,
  EulaComponent,
  WarningTooltipComponent,
  DfuWarningTooltipContentComponent,
  FirmwareUpToDateComponent,
  DfuReplugComponent,
  DeviceComponentsComponent,
  UpDownComponent,
  DeviceSettingsImportExportConfigComponent,
  DeviceAdminLoginComponent,
  DevicePasswordChangeComponent,
  DeviceSettingRestartDeviceComponent,
  DeviceSettingRestoreDefaultsComponent,
  DeviceSettingClearTrustedDevicesComponent,
  FtcWizardManualSetupComponent,
  PageComponent,
  ToolbarComponent,
  FtcWizardImportedConfigComponent,
  DeviceSettingsProvisioningComponent,
  ProvisioningFormComponent,
  FtcDeviceStartSetupComponent,
  FtcWizardCloseModalContentComponent,
  DfuCountBadgeComponent,
  StatusToastComponent,
  ActionToastComponent,
  PostponeDfuToastComponent,
  ToastsComponent,
  SortToastsPipe,
  DeviceSettingsPlayGreetingComponent,
  DeviceSoftwareSettingsComponent,
];
