export enum WifiConnectionStatus {
  Connected = "connected",
  Disconnected = "disconnected",
}

export enum EncryptionType {
  WEP = "WEP",
  PSK = "PSK",
  EAP = "EAP",
}
export enum IPSettingsType {
  Static,
  DHCP,
}
export enum EAPmethod {
  PEAP = "PEAP",
  TLS = "TLS",
  TTLS = "TTLS",
  PWD = "PWD",
}

export const NETWORK_JOIN_TIMEOUT = 10000;

export function getWiFiIconName(signalStrength: number) {
  if (signalStrength > 66) {
    return "wifi_strong";
  } else if (signalStrength >= 33 && signalStrength <= 66) {
    return "wifi_medium";
  } else {
    return "wifi_weak";
  }
}

export function findEncryptionType(encryptionType: string) {
  if (encryptionType.includes(EncryptionType.WEP)) {
    return EncryptionType.WEP;
  } else if (encryptionType.includes(EncryptionType.PSK)) {
    return EncryptionType.PSK;
  } else if (encryptionType.includes(EncryptionType.EAP)) {
    return EncryptionType.EAP;
  } else return "";
}
