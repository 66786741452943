import { Component, OnInit, OnDestroy } from "@angular/core";

@Component({
  selector: "oz-loading",
  templateUrl: "./loading.component.pug",
})
export class LoadingComponent implements OnInit, OnDestroy {
  constructor() {}
  min = 1;
  max = 5;
  dir: "+" | "-" = "+";
  text = "...";
  intervalHandle;

  ngOnInit(): void {
    this.intervalHandle = setInterval(() => {
      this.update();
    }, 500);
  }

  ngOnDestroy(): void {
    clearInterval(this.intervalHandle);
  }

  update() {
    if (this.text.length >= this.max) {
      this.dir = "-";
    } else if (this.text.length <= this.min) {
      this.dir = "+";
    }

    switch (this.dir) {
      case "+":
        this.text += ".";
        break;
      case "-":
        this.text = this.text.substr(0, this.text.length - 1);
        break;
    }
  }
}
