import { Component, OnInit } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { cloneDeep as _cloneDeep, get as _get, set as _set } from "lodash";
import { AccordionCategories } from "../shared/components/accordion/accordion.component";
import { RestoreDefaultUtils } from "../utils/restoreDefaultsUtils";
import { LensSettingsUI } from "./lens-settings-ui.model";
import { BadgeCount, BadgeCountService } from "../services/badge-count.service";

@Component({
  templateUrl: "./lens-settings.component.pug",
})
export class LensSettingsComponent implements OnInit {
  badgeCount: BadgeCount = { total: 0 };
  categories: AccordionCategories = [];
  filteredCategories: AccordionCategories = [];
  searchString: string = "";
  showConfirmModal = false;
  restoring = false;
  transParams = {
    open_tag: "<span class='thin-text'>",
    close_tag: "</span>",
  };

  constructor(
    private badgeService: BadgeCountService,
    private lensSettingsUI: LensSettingsUI,
    private translateService: TranslateService,
    private restoreDefaultUtils: RestoreDefaultUtils
  ) {}

  ngOnInit(): void {
    this.categories = this.lensSettingsUI.getCategories();
    this.badgeService.count$.subscribe((count) => {
      this.badgeCount = count;
    });
    this.translateForSearch();
    this.search();
  }

  /**
   * Creates a rich searchable string (translated) and injects into
   * the categories collection so that the settings "Search" function
   * can find settings using the currently selected language.
   */
  translateForSearch() {
    // Paths contains the language path as a key => a Lodash "set" path for setting the translated string
    // eg: { "SETTINGS.Notifications.deviceAlerts.Title" => [2, "items", 2, "data", "translated"]
    const paths = {};

    // Assign the initial values to the translated properties
    this.categories = this.categories.map((item, catIndex) => {
      return {
        ...item,
        items: item.items.map((i, itemIndex) => {
          const path = [catIndex, "items", itemIndex, "data", "translated"];

          const translated = [i.data.title];

          paths[`SETTINGS.${item.title}.${i.data.action}.TITLE`] = path;

          if (i.data.options) {
            i.data.options.forEach((opt) => {
              paths[
                `SETTINGS.${item.title}.${i.data.action}.${opt.action}`
              ] = path;
            });
          }

          return {
            ...i,
            data: {
              ...i.data,
              translated: translated.join(" "),
            },
          };
        }),
      };
    });

    this.translateService
      .stream(Object.keys(paths))
      .subscribe((translations) => {
        Object.keys(translations).forEach((key) => {
          _set(this.categories, paths[key], "");
        });

        // Set the translated strings "in place" using forEach / _set
        Object.keys(translations).forEach((key) => {
          const strings = [
            _get(this.categories, paths[key]),
            `${translations[key]}`.toLowerCase(),
          ];

          _set(this.categories, paths[key], strings.join(" ").trim());
        });
      });
  }

  onAccordionChanged(ev: AccordionCategories) {
    this.categories = ev;
  }

  /**
   * Triggered by model change on Search Settings
   */
  search() {
    const search = this.searchString.toLowerCase();
    const copy: AccordionCategories = _cloneDeep(this.categories);
    this.filteredCategories = copy.map((item) => {
      return {
        title: item.title,
        items: item.items = item.items.filter((i) =>
          i.data.translated.includes(search)
        ),
        open: search.length > 0,
        disabled: search.length > 0,
      };
    });
  }

  async restoreDefault() {
    this.restoring = true;
    this.showConfirmModal = false;
    await this.restoreDefaultUtils.restoreDefault();
    this.restoring = false;
  }

  onButtonClick() {
    this.showConfirmModal = true;
  }
}
