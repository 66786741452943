import { Injectable } from "@angular/core";
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  UrlTree,
  Router,
} from "@angular/router";
import { Observable } from "rxjs";
import { RETURNING_USER_KEY } from "../home/home.component";
import { StorageService } from "../services/storage.service";

/**
 *
 */
@Injectable({
  providedIn: "root",
})
export class FirstTimeUserGuard implements CanActivate {
  constructor(private storageService: StorageService, private router: Router) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    // this key will return boolean true if set, otherwise undefined
    if (this.storageService.getItem(RETURNING_USER_KEY)) {
      this.router.navigate(["/home"]);
      return false;
    }

    return true;
  }
}
