import { Component, Input } from "@angular/core";
import { Device } from "@poly/hub-native";
import { DeviceComponent, DeviceUtils } from "../utils/device.utils";

@Component({
  selector: "oz-device-components",
  templateUrl: "./device-components.component.pug",
})
export class DeviceComponentsComponent {
  @Input() set device(value: Device) {
    this.deviceComponents = this.deviceUtils.getDeviceComponents(value);
  }

  // List of components to show on the UI
  deviceComponents: DeviceComponent[] = [];

  // Expanded/Collapsed state for the dropdown.
  expanded = false;

  constructor(private deviceUtils: DeviceUtils) {}
}
