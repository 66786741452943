import { ChangeDetectorRef, Component, Input } from "@angular/core";
import { BehaviorSubject } from "rxjs";

export enum OzStepStatus {
  Inactive, // User has not navigated to the step yet
  Active, // Step is currently activate (user is filling in a data)
  Skipped, // User has skipped a step
  Completed, // User has completed a step
}

export enum OzStepBarStatus {
  Complete,
  Incomplete,
}

@Component({
  selector: "oz-step",
  templateUrl: "./step.component.pug",
})
export class StepComponent {
  @Input()
  set status(_status: OzStepStatus) {
    this.currentStatus = _status;
    this._status.next(_status);
    this.cdr.detectChanges();
  }

  OzStepStatus = OzStepStatus;
  OzStepBarStatus = OzStepBarStatus;

  // Current status of the step (parent component subscribes to listen for changes)
  _status = new BehaviorSubject<OzStepStatus>(OzStepStatus.Inactive);

  // Used by this component
  currentStatus: OzStepStatus;

  // Status of the vertical bar
  barStatus = OzStepBarStatus.Incomplete;

  constructor(private cdr: ChangeDetectorRef) {}

  setBarStatus(status: OzStepBarStatus) {
    this.barStatus = status;
    this.cdr.detectChanges();
  }
}
