import { Injectable } from "@angular/core";
import { DFUError } from "@poly/hub-native";
import { DfuWarnings } from "../../../../services/dfu-warnings.service";
import { Toasts } from "../toasts.service";
import { BehaviorSubject, combineLatest, of, Subject, timer } from "rxjs";
import { map, mergeMap, switchMap } from "rxjs/operators";
import {
  Dfu,
  OzDeviceWithRepositoryFirmware,
} from "../../../../services/dfu.service";
import { AdminConfig } from "../../../../services/admin-config.service";
import { MS_IN_HOUR } from "../../../../utils/constants";
import { DfuNotificationsService } from "../../../../services/dfu-notifications.service";
import { DeviceManagerService } from "../../../../services/device-manager.service";

export interface OzDeviceWithRepositoryFirmwareAndWarnings
  extends OzDeviceWithRepositoryFirmware {
  warnings: DFUError[];
}

@Injectable({
  providedIn: "root",
})
export class PostponeDfuService {
  devices = new BehaviorSubject<OzDeviceWithRepositoryFirmwareAndWarnings[]>(
    []
  );

  private postponedDfus: OzDeviceWithRepositoryFirmwareAndWarnings[] = [];

  private toastId: string;

  private postponedDfusTimeouts = new Subject<string>();

  private tick = new Subject<void>();

  private remindTime = this.adminConfig.dfuRemindHours * MS_IN_HOUR;

  constructor(
    private dfu: Dfu,
    private warnings: DfuWarnings,
    private toasts: Toasts,
    private adminConfig: AdminConfig,
    private dfuNotifications: DfuNotificationsService,
    private deviceManager: DeviceManagerService
  ) {
    this.postponedDfusTimeouts
      .pipe(
        mergeMap((uniqueId) =>
          timer(this.remindTime).pipe(map(() => uniqueId))
        ),
        mergeMap((uniqueId) => this.deviceManager.getDevice(uniqueId))
      )
      .subscribe((device) => {
        if (device) {
          if (device.isConnected) {
            this.dfuNotifications.notifyIfNeeded(device);
          }
          this.removeDeviceFromPostponedDfus(device.uniqueId);
          this.tick.next();
        }
      });

    this.deviceManager.events.subscribe(({ type, deviceId }) => {
      if (type === "dfu-completed") {
        const index = this.postponedDfus.findIndex(
          (device) => device.id === deviceId
        );
        if (index > -1) {
          this.postponedDfus.splice(index, 1);
        }
      }
    });

    this.tick
      .pipe(
        switchMap(() => {
          return this.dfu.allConnectedDevices().pipe(
            // Pass on list of devices which have available update
            map((devices) =>
              devices.filter((device) => !!device?.repositoryFirmware)
            ),
            map((devices) => {
              return devices.filter(
                (device) => !this.getPostponed(device?.uniqueId)
              );
            }),
            switchMap((devices) => {
              return devices.length
                ? combineLatest([
                    ...devices.map((device) => {
                      return combineLatest([
                        of(device),
                        this.warnings.poll(device),
                      ]);
                    }),
                  ])
                : of([]);
            }),
            map((arr) => {
              return arr.map(([device, warnings]) => {
                return {
                  ...device,
                  warnings,
                } as OzDeviceWithRepositoryFirmwareAndWarnings;
              });
            }),
            switchMap((devices) => {
              return devices.length
                ? combineLatest([
                    ...devices.map((device) => {
                      return combineLatest([
                        of(device),
                        this.deviceManager.getNeedSetup(device),
                      ]);
                    }),
                  ])
                : of([]);
            }),
            map((arr) => {
              return arr
                .filter(([device, needSetup]) => !needSetup)
                .map(([device, needSetup]) => device);
            })
          );
        })
      )
      .subscribe((devices) => {
        this.devices.next(devices ?? []);
        if (devices?.length && !this.toastId) {
          this.toastId = this.toasts.push({
            type: "action-postpone-dfu",
          });
        } else if (devices?.length === 0) {
          this.toasts.dismiss(this.toastId);
          this.toastId = null;
        }
      });

    // Emit initial event to query for DFU availability for all connected devices
    this.tick.next();
  }

  /**
   * Postpones a DFU for a given device.
   *
   * @returns `true` if a DFU has been successfully postponed, `false` otherwise.
   */
  postponeDfu(device: OzDeviceWithRepositoryFirmwareAndWarnings): boolean {
    if (!this.getPostponed(device.uniqueId)) {
      this.postponedDfus.push(device);
      this.removeDevice(device.uniqueId);
      this.postponedDfusTimeouts.next(device.uniqueId);
      return true;
    }
    return false;
  }

  /**
   * Removes a device from the internal BehaviorSubject and re-emits event.
   */
  private removeDevice(uniqueId: string) {
    const value = this.devices.getValue();
    const index = value.findIndex((device) => device.uniqueId === uniqueId);
    if (index > -1) {
      value.splice(index, 1);
      this.devices.next(value);
      this.tick.next();
    }
  }

  private removeDeviceFromPostponedDfus(uniqueId: string) {
    const index = this.postponedDfus.findIndex(
      (device) => uniqueId === device.uniqueId
    );
    if (index > -1) {
      this.postponedDfus.splice(index, 1);
    }
  }

  // Checks if a device has been postponed for the DFU.
  private getPostponed(
    uniqueId: string
  ): OzDeviceWithRepositoryFirmwareAndWarnings {
    return this.postponedDfus.find((device) => uniqueId === device.uniqueId);
  }
}
