import { Component, Input } from "@angular/core";
import { Device } from "@poly/hub-native";
import { DeviceManagerService } from "../../services/device-manager.service";
import {
  OzFileDialog,
  OzFileDialogOptions,
} from "../../services/file-dialog.service";
import { OzFileSystem } from "../../services/file-system.service";
import { combineLatest, EMPTY, from, of } from "rxjs";
import { map, switchMap } from "rxjs/operators";
import { Toast, Toasts } from "../../shared/components/toast/toasts.service";

const CONFIG_EXPORT_FAILED_TOAST: Toast = {
  type: "status",
  status: "error",
  text: "NOTIFICATIONS.CONFIG_EXPORT_FAILED",
};

const CONFIG_EXPORT_SUCCESS_TOAST: Toast = {
  type: "status",
  status: "success",
  text: "NOTIFICATIONS.CONFIG_EXPORT_SUCCEEDED",
};

const CONFIG_IMPORT_FAILED_TOAST: Toast = {
  type: "status",
  status: "error",
  text: "NOTIFICATIONS.CONFIG_IMPORT_FAILED",
};

const CONFIG_IMPORT_SUCCEEDED_TOAST: Toast = {
  type: "status",
  status: "success",
  text: "NOTIFICATIONS.CONFIG_IMPORT_SUCCEEDED",
};

const TRANSLATION_PREFIX = "DeviceSettings.IMPORT_EXPORT_CONFIG";

const FILTERS = [{ name: "Configuration File", extensions: ["xml"] }];

const IMPORT_CONFIG_DIALOG_OPTIONS: OzFileDialogOptions = {
  title: `${TRANSLATION_PREFIX}.IMPORT_DIALOG_TITLE`,
  titleTranslate: true,
  filters: FILTERS,
};

const EXPORT_CONFIG_DIALOG_OPTIONS: OzFileDialogOptions = {
  title: `${TRANSLATION_PREFIX}.EXPORT_DIALOG_TITLE`,
  titleTranslate: true,
  filters: FILTERS,
};

@Component({
  selector: "oz-device-settings-import-export-config",
  templateUrl: "./device-settings-import-export-config.component.pug",
})
export class DeviceSettingsImportExportConfigComponent {
  @Input()
  device: Device;

  constructor(
    private deviceManager: DeviceManagerService,
    private toasts: Toasts,
    private fileDilaog: OzFileDialog,
    private fileSystem: OzFileSystem
  ) {}

  onExport() {
    this.fileDilaog
      // Open a save-file dialog
      .saveFile(EXPORT_CONFIG_DIALOG_OPTIONS)
      .pipe(
        switchMap(({ path, canceled }) =>
          !canceled
            ? // If user has selected a file
              combineLatest([
                of(path),
                // Obtain a configuration from the device
                this.deviceManager.exportConfiguration(this.device.id),
              ])
            : // If user hasnot selected a file, complete the subscription
              EMPTY
        ),
        switchMap(([path, { status, config }]) => {
          // If device successfully reported a config string
          if (status === "OK") {
            // Save the config string to the selected file destination
            /* TODO:
            return (
              this.fileSystem
                .saveFile(path, config)
                // And indicate operation status
                .pipe(map((result) => result.success))
            );
             */
          } else {
            // Otherwise, indicate an error occurrence
            return of(false);
          }
        })
      )
      .subscribe((success: boolean) => {
        // Overall operation status
        if (!success) {
          this.toasts.push(CONFIG_EXPORT_FAILED_TOAST);
        } else {
          this.toasts.push(CONFIG_EXPORT_SUCCESS_TOAST);
        }
      });
  }

  onImport() {
    this.fileDilaog
      // Open an open-file dialog
      .openFile(IMPORT_CONFIG_DIALOG_OPTIONS)
      .pipe(
        switchMap(({ paths, canceled }) =>
          // If user has selected a file
          !canceled && paths?.length
            ? // Read the file's content
              this.fileSystem.readFile(paths[0])
            : // Otherwise complete the subscription
              EMPTY
        ),
        switchMap(({ success, content }) => {
          // If successfully read contents of the file
          if (success) {
            // Import the config into device
            return from(
              this.deviceManager.importConfiguration({
                deviceId: this.device.id,
                config: content,
              })
            ).pipe(map(({ status }) => status === "OK"));
          } else {
            // Otherwise, indicate an error occurrence
            return of(false);
          }
        })
      )
      .subscribe((success: boolean) => {
        // Overall operation status
        if (!success) {
          this.toasts.push(CONFIG_IMPORT_FAILED_TOAST);
        } else {
          this.toasts.push(CONFIG_IMPORT_SUCCEEDED_TOAST);
        }
      });
  }
}
