import {
  DeviceManagerService,
  OzDevice,
} from "../services/device-manager.service";
import { Component } from "@angular/core";
import { AnimationItem } from "lottie-web";
import { AnimationOptions } from "ngx-lottie";
import { ProductCatalog } from "../services/product.catalog.service";
import { sortDevices } from "../utils/utils";
import { DEVICE_TYPE } from "../utils/constants";
import { DfuBadgeCounterService } from "../services/dfu-badge-counter.service";
import { BrickedDeviceService } from "../services/bricked-device.service";
import { map, switchMap, tap } from "rxjs/operators";
import { combineLatest, of } from "rxjs";
import { BrickedDeviceInfo } from "@poly/hub-native";

@Component({
  selector: "sidebar",
  templateUrl: "./sidebar.component.pug",
})
export class SidebarComponent {
  devices: OzDevice[];
  parentDevices: OzDevice[] = [];
  showPrimaryDeviceTooltip: boolean = false;
  counterMap: { [uniqueId: string]: number } = {};
  brickedDeviceStatus: BrickedDeviceInfo;
  chargingCase = DEVICE_TYPE.CHARGING_CASE;

  constructor(
    public deviceManager: DeviceManagerService,
    public productCatalog: ProductCatalog,
    private dfuBadgeCounter: DfuBadgeCounterService,
    private brickedDeviceService: BrickedDeviceService
  ) {
    this.deviceManager
      .getDevices()
      .pipe(
        map((devices) => this.hideChargingCase(devices)),
        map((devices) => sortDevices(devices)),
        tap(({ devices, parentDevices }) => {
          console.log(`sidebar devices: [${devices.map(d => d.id + (d.isConnected ? '<c>' : ''))}]` +
            ` parents: [${parentDevices.map(d => d.id + (d.isConnected ? '<c>' : ''))}]`)
          this.devices = devices;
          this.parentDevices = parentDevices;
        }),
        switchMap(({ devices }) =>
          combineLatest([
            ...devices.map((device) =>
              combineLatest([of(device), this.dfuBadgeCounter.count(device)])
            ),
          ])
        ),
        tap((deviceWithCounterArray) => {
          this.counterMap = {};
          deviceWithCounterArray.forEach(([device, counter]) => {
            if (counter) {
              this.counterMap[device.uniqueId] = counter;
            }
          });
        })
      )
      .subscribe();

    // Check for bricked device
    this.brickedDeviceService.brickedDeviceInfo$.subscribe(
      (brickedDeviceStatus: BrickedDeviceInfo) => {
        this.brickedDeviceStatus = brickedDeviceStatus;
      }
    );
  }

  options: AnimationOptions = {
    path: "assets/lottie/no_devices.json",
  };

  // very laggy Windows performance has been observed due to lottie; this improves the condition by not
  // rendering more subframes than necessary based on JSON After Effects frames per second
  animationCreated(animationItem: AnimationItem): void {
    animationItem.setSubframe(false);
  }

  togglePrimaryDeviceTooltip(showPrimaryDeviceTooltip: boolean) {
    this.showPrimaryDeviceTooltip = showPrimaryDeviceTooltip;
  }

  makePrimary(device: OzDevice) {
    if (!device.parentDeviceId) {
      this.deviceManager.setPrimaryDevice(device.id);
    } else {
      this.deviceManager.setPrimaryDevice(device.parentDeviceId);
    }
  }

  count(device: OzDevice) {
    return this.counterMap[device.uniqueId];
  }

  // If earbuds are also connected, hide charging case (Osprey device)
  hideChargingCase(devices: OzDevice[]) {
    // Find case unique IDs and return only devices which don't have these IDs
    const foundCaseIds = devices
      .filter(
        (device) =>
          device.deviceType === DEVICE_TYPE.EARBUDS &&
          device.isConnected &&
          device.connectedDevices?.includes("chargeCase") &&
          !!device.peerChargeCase?.GenesGuid
      )
      .map((earbuds) => earbuds.peerChargeCase?.GenesGuid.toLowerCase());

    if (foundCaseIds.length) {
      return devices.filter(
        (device) => !foundCaseIds.includes(device.uniqueId.toLowerCase())
      );
    } else {
      return devices;
    }
  }
}
