import { Component, OnInit, Input } from "@angular/core";
import { ProductCatalog } from "../../../services/product.catalog.service";

@Component({
  selector: "oz-device-not-supported",
  templateUrl: "./device-not-supported.component.pug",
})
export class DeviceNotSupportedComponent implements OnInit {
  @Input() pid: string | number;

  constructor(public productCatalog: ProductCatalog) {}

  ngOnInit(): void {}
}
