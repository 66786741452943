import { DetailNavService } from "../services/detail-nav.service";
import { ILoggingService } from "../services/logging.service";
import {
  DeviceManagerService,
  OzDevice,
} from "../services/device-manager.service";
import { BluetoothService } from "../services/bluetooth.service";
import { Component, OnDestroy, OnInit } from "@angular/core";
import {
  BluetoothInfo,
  DFUError,
  DFUUserAction,
  NetworkProvisioningInfo,
  WiFiStatusResponse,
  BrickedDeviceInfo,
} from "@poly/hub-native";
import { ActivatedRoute, ParamMap, Router } from "@angular/router";
import { Subscription, Observable, of, combineLatest } from "rxjs";
import { filter, switchMap, take, startWith, map } from "rxjs/operators";
import { DfuModalService } from "../services/dfu-modal.service";
import { DeviceEventService } from "../services/device.event.service";
import {
  DEVICE_SETTING_ID_PAIRING_MODE,
  COLOR_DANGER,
  COLOR_SUCCESS,
  BATTERY,
  OVERVIEW_PATH,
  USB_ADAPTER_PATH,
  CHARGING_CASE_PATH,
  WIFI_WEAK_SIGNAL,
  WIFI_STRONG_SIGNAL,
  DEVICE_FEATURES,
  COLOR_WARNING,
  BATTERY_WITH_CRITICAL_VALUE,
  DEVICE_TYPE,
} from "../utils/constants";
import { DfuWarnings } from "../services/dfu-warnings.service";
import { DfuNeedReconnectService } from "../services/dfu-need-reconnect.service";
import { UtilityService } from "../services/utility.service";
import { getFirmwareVersion } from "../shared/pipes/firmware-version.pipe";
import { ProvisioningServer } from "../services/provisioning-server.service";
import { OzUser, OzUserRole } from "../services/user.service";
import { ProvisioningForm } from "../device-settings/device-settings-provisioning/provisioning-form.component";
import { Dfu } from "../services/dfu.service";
import { RepositoryFirmware } from "../services/repository/model";
import {
  getMostSignificantComponentVersion,
  getBatteryIconNameWithCriticalLevel,
  getBatteryIconNameWithoutCriticalLevel,
  getBatteryIconNameFromBatteryPercent,
} from "../utils/device.utils";
import { BrickedDeviceService } from "../services/bricked-device.service";
import { cloneDeep as _cloneDeep } from "lodash";
import { TranslateService } from "@ngx-translate/core";

interface DfuInfo {
  repoFirmware: RepositoryFirmware;
  warnings: DFUError[];
}

/**
 * Device "Overview"
 * Used on route /device/:id/overview
 */
@Component({
  templateUrl: "./device-overview.component.pug",
})
export class DeviceOverviewComponent implements OnInit, OnDestroy {
  device: OzDevice;
  devices: OzDevice[];
  deviceSub: Subscription;
  routeDeviceSub: Subscription;
  parentDevice: OzDevice;
  COLOR_CONNECTED = COLOR_SUCCESS;
  COLOR_DANGER = COLOR_DANGER;
  COLOR_WARNING = COLOR_WARNING;
  BATTERY = BATTERY;
  batteryIcon: string;

  // URL which points to the firmware update archive in case update is available
  archiveLocation: string = null;
  // Rules for DFU (in JSON format), which contains the list of components which needs to be updated
  private rules: string = null; // TODO: UI: Remove this.
  // Indicates if all DFU prerequisites are satisfied (in case this array is empty, it's all good to start DFU)
  dfuWarnings: (DFUError | DFUUserAction)[] = [];
  // Implies if the device needs to be reconnected to complete the most recent DFU
  needReconnect = false;
  showPairingButton: boolean;
  showPairingOverflow: boolean;
  showPairingForVideoDevice = false;
  showDfuConfirmModal = false;

  availableVersionStr: string;
  releaseNotes: string;

  // TODO: UI: Avoid PID checks
  swPairingPids: number[] = [
    parseInt("0x2e6"),
    parseInt("0x2e7"),
    parseInt("0x2e8"),
    parseInt("0x2e9"),
  ];

  private readonly remotePairingPids: number[] = [
    parseInt("0x9217"), // Studio USB
    parseInt("0x92B2"), // Studio R30
  ];

  public remotePairingMode: boolean = false;

  wideLayout = false;
  splitView = false;

  updateButtonOffsetLeft = false;
  updateButtonOffsetRight = false;

  wifiStatus: WiFiStatusResponse;
  wifiName = "";
  wifiIconName = "";

  provisioning = false;
  provisioningConnected = false;
  provisioningServerUrl = "";
  provisioningModeOptions = {
    manual: "Manual",
    auto: "Auto",
    disable: "Disable",
  };
  provisioningMode = "";

  firmwareStr: string = "";
  upToDate: boolean = true;
  isUpdateAvailableFromPolicy = false;

  showEditName = false;

  showProvisioningModal = false;
  provisioningForm: ProvisioningForm;
  provisioningFormInit: ProvisioningForm = {
    provisioningMode: this.provisioningModeOptions.disable,
  };

  // Implies if the currently logged in user is administrator
  isUserAdmin = false;

  headsetPid: number;

  brickedDeviceStatus: BrickedDeviceInfo;

    // Flags for current route. This template is used on 2 tabs: Overview and USB Adapter
    isOverviewTab = false;
    isUsbAdapterTab = false;

  // Osprey Charge Case
  caseViaUsb = true;
  caseName = "";

  constructor(
    public deviceManager: DeviceManagerService,
    private route: ActivatedRoute,
    private router: Router,
    private dfuModalService: DfuModalService,
    private logger: ILoggingService,
    private dfu: Dfu,
    private detailNavService: DetailNavService,
    private warnings: DfuWarnings,
    private dfuNeedReconnectService: DfuNeedReconnectService,
    public devicesEventsService: DeviceEventService,
    private bluetoothService: BluetoothService,
    private provisioningServer: ProvisioningServer,
    private user: OzUser,
    private brickedDeviceService: BrickedDeviceService,
    private translateService: TranslateService
  ) {
      // Set route flags
      switch (this.route.routeConfig?.path) {
        case OVERVIEW_PATH:
          this.isOverviewTab = true;
          this.isUsbAdapterTab = false;
          break;
        case USB_ADAPTER_PATH:
          this.isOverviewTab = false;
          this.isUsbAdapterTab = true;
          break;
      }

    this.translateService
      .stream("DETAIL.CHARGING_CASE_NAME")
      .subscribe((trans) => {
        this.caseName = trans;
      });
  }

  ngOnInit() {
    this.brickedDeviceService.brickedDeviceInfo$.subscribe(
      (brickedStatus: BrickedDeviceInfo) => {
        this.brickedDeviceStatus = brickedStatus;
      }
    );

    this.detailNavService.configure({ showNav: true });

    this.deviceSub = this.route.parent.paramMap
      .pipe(
        switchMap((paramMap: ParamMap) =>
          this.deviceManager.getDevice(paramMap.get("id"))
        ),
        filter((device) => !!device),
        switchMap((device) => this.resolveParentDevice(device)),
        switchMap((device) => this.resolveCaseDevice(device)),
        switchMap((device) => {
          return combineLatest([
            of(device),
            this.getDfuInfo(device),
            this.dfuNeedReconnectService.getNeedReconnect(device),
            this.getBluetoothInfo(device),
            this.user.role.pipe(startWith(OzUserRole.Other)),
            this.deviceManager.needOOBmap$,
          ]);
        })
      )
      .subscribe(
        ([
          device,
          dfuInfo,
          needReconnect,
          bluetoothStatus,
          userRole,
          needOOBmap,
        ]) => {
          // Redirect to First Time Connection Wizard
          if (
            device.isConnected &&
            needOOBmap.has(device.id) &&
            needOOBmap.get(device.id)
          )
            this.setupRedirect(device);
          this.updateDfuInfo(dfuInfo);
          this.isUserAdmin = userRole === OzUserRole.Admin;
          // Device has changed (e.g. user is navigating through device list)
          const deviceChanged = device?.uniqueId !== this.device?.uniqueId;
          // Determines if the device became connected
          const deviceReconnected =
            device?.isConnected && !this.device?.isConnected;

          if (deviceChanged) {
            this.showEditName = false;
          }

          this.device = device;
          this.headsetPid = this.device?.additionalHeadsetInfo?.headsetPID;

          this.detailNavService.configure({
            deviceHeading: this.device.name,
          });

          this.firmwareStr = getFirmwareVersion(this.device);

          this.needReconnect = needReconnect;
          this.parentDevice = device.parent;

          if (
            (this.device?.isConnected && deviceChanged) ||
            (deviceReconnected && this.needReconnect)
          ) {
            this.dfuWarnings = ["ReplugDevice"];
          }

          //pids for BT700, ideally should introduce a field on Device for SoftwarePairing : bool, to get it from the native layer
          this.showPairingButton =
            this.swPairingPids.includes(this.device?.pid) &&
            !this.isUsbAdapterTab;

          this.showPairingOverflow =
            this.swPairingPids.includes(this.device?.pid) &&
            this.isUsbAdapterTab;

          // Devices with bluetoothSupported feature flag (i.e., Poly Studio USB)
          this.showPairingForVideoDevice =
            this.device?.featureList.includes(DEVICE_FEATURES.BLUETOOTH) &&
            bluetoothStatus?.enable;

          this.remotePairingMode =
            bluetoothStatus?.enable &&
            this.remotePairingPids.includes(this.device?.pid) &&
            !this.isUsbAdapterTab;

          this.batteryIcon = this.getBatteryIconName(this.getBatteryLevel());

          // Earbuds split view with separate battery indicators
          this.splitView =
            this.device?.deviceType === DEVICE_TYPE.EARBUDS &&
            !!this.device?.additionalBatteryInfo;
          // Move update button if only one earbud is connected
          this.updateButtonOffsetLeft =
            this.device?.deviceType === DEVICE_TYPE.EARBUDS &&
            this.device?.leftEarbudStatus?.connected &&
            this.device?.leftEarbudStatus?.primary &&
            !this.device?.leftEarbudStatus?.peerConnected;
          this.updateButtonOffsetRight =
            this.device?.deviceType === DEVICE_TYPE.EARBUDS &&
            this.device?.rightEarbudStatus?.connected &&
            this.device?.rightEarbudStatus?.primary &&
            !this.device?.rightEarbudStatus?.peerConnected;

          // WiFi and Provisioning indicators, only available for user with admin role
          if (
            this.device?.featureList.includes(DEVICE_FEATURES.WIFI) ||
            this.device?.featureList.includes(
              DEVICE_FEATURES.NETWORK_PROVISIONING
            )
          ) {
            this.deviceManager
              .getWiFiStatus(this.device.id)
              .pipe(take(1))
              .subscribe((wifiStatus) => {
                if (this.isUserAdmin) {
                  // Wide layout if user has admin role
                  this.wideLayout = this.device.isConnected;

                  // WiFi
                  this.wifiStatus = wifiStatus;
                  this.wifiIconName = this.getWiFiIconName(
                    +wifiStatus.wifi_connection_info.signalStrength
                  );
                  this.deviceManager
                    .getScannedNetworks(this.device.id)
                    .then((response) => {
                      const currentNetwork = response.scannedNetworks.find(
                        (network) =>
                          network.bssid ===
                          wifiStatus.wifi_connection_info.mBSSID
                      );
                      this.wifiName = currentNetwork ? currentNetwork.ssid : "";
                    });
                  // Provisioning
                  this.refreshProvisioning();
                }
              });
          } else {
            this.wifiStatus = null;
            this.provisioning = false;
            this.wideLayout = false;
          }
        }
      );

    // If we are on the parent's Overview page and the child is connected, let we go to the child's Overview page.
    this.routeDeviceSub = this.route.parent.paramMap
      .pipe(
        switchMap((paramMap: ParamMap) =>
          combineLatest([
            this.deviceManager.getDevice(paramMap.get("id")),
            this.deviceManager.getConnectedDevices(),
          ])
        ),
        filter(([overviewedDevice, connectedDevices]) => !!overviewedDevice)
      )
      .subscribe(([overviewedDevice, connectedDevices]) => {
        const connectedChild = connectedDevices.find(
          (cd) =>
            cd.parent !== undefined && cd.parentDeviceId === overviewedDevice.id
        );
        if (connectedChild)
          this.router.navigate([
            "/detail",
            connectedChild.uniqueId,
            "overview",
          ]);
      });
  }

  private getBatteryIconName(batteryLevel: number) {
    const iconName = this.device?.battery.isChargeLevelValid
      ? getBatteryIconNameWithCriticalLevel(batteryLevel)
      : getBatteryIconNameWithoutCriticalLevel(batteryLevel);
    return this.device.battery.charging ? `${iconName}_charging` : iconName;
  }

  public isBatteryLevelCritical(): boolean {
    const batteryLevel = this.getBatteryLevel();
    if (this.device?.battery?.isChargeLevelValid) {
      return batteryLevel === BATTERY_WITH_CRITICAL_VALUE.BATTERY_CRITICAL;
    }
    return batteryLevel === BATTERY.PERCENT_0;
  }

  public getBatteryLevelPercent(): string {
    if (this.device?.battery?.isChargeLevelValid) {
      const batteryLevelPercent = (this.device?.battery?.chargeLevel - 1) * 25;
      return batteryLevelPercent < 0 ? "0%" : `${batteryLevelPercent}%`;
    }
    return `${this.device?.battery?.level * 25}%`;
  }

  private getBatteryLevel(): number {
    if (this.device?.battery?.isChargeLevelValid) {
      return this.device?.battery?.chargeLevel;
    }
    return this.device?.battery?.level;
  }

  public isBatteryLevelValid(): boolean {
    if (this.device?.battery?.isChargeLevelValid) {
      return (
        this.device?.battery?.chargeLevel !==
        BATTERY_WITH_CRITICAL_VALUE.UNKNOWN
      );
    }
    return this.device?.battery?.level !== BATTERY.UNKNOWN;
  }

  // Charge Case battery methods
  isBatteryLevelValidCC(): boolean {
    return (
      this.device?.additionalBatteryInfo?.chargingCase !==
      BATTERY_WITH_CRITICAL_VALUE.UNKNOWN
    );
  }

  getBatteryIconNameCC() {
    const iconName = getBatteryIconNameFromBatteryPercent(
      this.getBatteryLevelPercentNumberCC()
    );
    const charging = this.device?.additionalBatteryInfo?.chargingCaseCharging;

    return charging ? `${iconName}_charging` : iconName;
  }

  getBatteryLevelPercentNumberCC(): number {
    const numLevels = this.device?.additionalBatteryInfo?.chargingCaseNumLevels;

    if (numLevels - 1 <= 0) {
      return 0;
    }

    const level = this.device?.additionalBatteryInfo
      ?.chargingCaseIncrementalLevel;
    const percent = (100 * level) / (numLevels - 1);

    return percent | 0; // "|0" is to make sure we return integer value
  }

  getBatteryLevelPercentCC(): string {
    const batteryPercent = this.getBatteryLevelPercentNumberCC();
    const batteryPercentString =
      batteryPercent < 0 ? "0%" : `${batteryPercent}%`;

    return batteryPercentString;
  }

  isBatteryLevelCriticalCC(): boolean {
    return (
      this.device?.additionalBatteryInfo?.chargingCase ===
      BATTERY_WITH_CRITICAL_VALUE.BATTERY_CRITICAL
    );
  }

  private refreshProvisioning(): void {
    if (this.device?.videoDeviceStatus?.provisionServerInfo) {
      this.deviceManager
        .getNetworkProvisioning(this.device.id)
        .then((networkProvisioningInfo: NetworkProvisioningInfo) => {
          this.provisioning = true;
          this.provisioningServerUrl = networkProvisioningInfo.serverAddress;
          this.provisioningMode = networkProvisioningInfo.provisioningMode;
          this.provisioningConnected =
            networkProvisioningInfo.provisioningMode !==
            this.provisioningModeOptions.disable;
        });
    }
  }

  private setupRedirect(device: OzDevice) {
    this.router.navigate(["/detail", device.uniqueId, "ftc_enter_password"]);
  }

  private getDfuInfo(device: OzDevice): Observable<DfuInfo> {
    if (device?.isConnected) {
      return this.dfu.availableUpdate(device).pipe(
        switchMap((repoFirmware) => {
          return combineLatest([
            of(repoFirmware),
            repoFirmware ? this.warnings.poll(device) : of([] as DFUError[]),
          ]);
        }),
        map(([repoFirmware, warnings]) => ({
          repoFirmware,
          warnings,
        })),
        startWith(null)
      );
    } else {
      return of(null);
    }
  }

  private getBluetoothInfo(device: OzDevice): Observable<BluetoothInfo> {
    if (device?.featureList.includes(DEVICE_FEATURES.BLUETOOTH)) {
      return this.bluetoothService.bluetoothStatus$;
    } else {
      return of(null);
    }
  }

  private updateDfuInfo(dfuInfo?: DfuInfo) {
    this.upToDate = true;
    this.isUpdateAvailableFromPolicy = false;

    // Don't show available update if Charge Case is connected over BT700
    if (
      this.route.routeConfig?.path === CHARGING_CASE_PATH &&
      !this.caseViaUsb
    ) {
      this.upToDate = true;
      return;
    }

    if (dfuInfo?.repoFirmware) {
      const { repoFirmware, warnings } = dfuInfo;
      this.dfuWarnings = warnings;
      this.archiveLocation = repoFirmware.archiveLocation;
      if (!!repoFirmware?.rules?.setid) {
        this.availableVersionStr = repoFirmware?.rules?.setid;
      } else {
        this.availableVersionStr = getMostSignificantComponentVersion(
          repoFirmware?.rules?.version,
          this.device.isVideoDevice
        );
      }
      this.upToDate = !repoFirmware;
      if (repoFirmware?.rules?.releaseNotes) {
        this.releaseNotes = repoFirmware?.rules?.releaseNotes;
      }
      this.isUpdateAvailableFromPolicy = dfuInfo?.repoFirmware?.policy;
    } else {
      this.archiveLocation = null;
      this.dfuWarnings = [];
    }
  }

  handleOpenBrowser() {
    UtilityService.openExternalBrowser(this.releaseNotes);
  }

  // TODO: UI: We do not need Observable here, use device.parent
  private resolveParentDevice(device: OzDevice): Observable<OzDevice> {
    if (this.isUsbAdapterTab) {
      const parent = device.parent;
      return this.deviceManager.getDevice(parent ? parent.uniqueId : null);
    } else {
      return of(device);
    }
  }

  private resolveCaseDevice(device: OzDevice): Observable<OzDevice> {
    if (
      this.route.routeConfig?.path === CHARGING_CASE_PATH &&
      device.deviceType === DEVICE_TYPE.EARBUDS &&
      device.connectedDevices?.includes("chargeCase")
    ) {
      // If case is connected via USB cable find it in device list per unique id
      const caseDevice = this.deviceManager.getDeviceByUniqueId(
        device.peerChargeCase?.GenesGuid
      );
      if (caseDevice?.isConnected) {
        this.caseViaUsb = true;
        return of(caseDevice);
      }

      // Otherwise transform device (earbuds) to case - changes pid, fw etc.
      this.caseViaUsb = false;
      let chargeCase = this.deviceManager.transformDeviceIntoChargeCase(device);
      chargeCase.name = this.caseName;
      return of(chargeCase);
    } else {
      return of(device);
    }
  }

  deleteDevice() {
    if (this.deviceManager.removeDevice(this.device.uniqueId)) {
      this.devicesEventsService.onDeviceRemoved(this.device);
      this.router.navigate(["/home"]);
    }
  }

  makePrimary(device: OzDevice) {
    if (!device.parentDeviceId) {
      this.deviceManager.setPrimaryDevice(device.id);
    } else {
      this.deviceManager.setPrimaryDevice(device.parentDeviceId);
    }
  }

  // TODO: UI: This can be removed if we replace OzDevice#isPrimary with primary set/get
  showMakePrimary(device: OzDevice): boolean {
    if (!device) {
      return false;
    }
    return device?.isConnected && device?.canBePrimary && !device?.isPrimary;
  }

  onDfuConfirm() {
    if (this.archiveLocation) {
      this.runDfu();
    } else {
      this.logger.error(
        `Device Overview: Confirmed to start DFU on a dialog. However DFU archive URL is not available, DFU cannot start.`
      );
    }
  }

  runDfu() {
    this.dfuModalService.open({
      device: this.device,
      dfuType: "Remote",
      rules: this.rules,
      archiveLocation: this.archiveLocation,
    });
  }

  runDfuRecovery() {
    if (this.brickedDeviceStatus?.recoveryArchivePath) {
      this.dfuModalService.open({
        device: this.device,
        dfuType: "Recovery",
        archiveLocation: this.brickedDeviceStatus.recoveryArchivePath,
      });
    } else {
      this.logger.error(
        `Device Overview: Recovery DFU archive URL is not available, DFU cannot start.`
      );
    }
  }

  pairNewDevice() {
    // Devices with bluetoothSupported feature flag (i.e., Poly Studio USB)
    if (this.showPairingForVideoDevice) {
      this.deviceManager.setBluetoothPairing({
        deviceId: this.device?.id,
        le: false,
      });
      return;
    }
    this.deviceManager.setDeviceSetting(this.device.id, {
      id: DEVICE_SETTING_ID_PAIRING_MODE,
      value: "true",
    });
  }

  pairRemoteDevice() {
    // Devices with bluetoothSupported feature flag (i.e., Poly Studio USB)
    if (this.showPairingForVideoDevice) {
      this.deviceManager.setBluetoothPairing({
        deviceId: this.device?.id,
        le: true,
      });
      return;
    }
    this.deviceManager.setDeviceSetting(this.device.id, {
      id: DEVICE_SETTING_ID_PAIRING_MODE,
      value: "true",
    });
  }

  getWiFiIconName(signalStrength: number) {
    if (signalStrength < WIFI_WEAK_SIGNAL) {
      return "wifi_weak";
    } else if (
      signalStrength >= WIFI_WEAK_SIGNAL &&
      signalStrength < WIFI_STRONG_SIGNAL
    ) {
      return "wifi_medium";
    } else {
      return "wifi_strong";
    }
  }

  onSetProvisioning() {
    this.provisioningForm = null;
    this.showProvisioningModal = true;
  }

  onProvision() {
    this.provisioningServer.setParams({
      deviceId: this.device.id,
      ...this.provisioningForm,
    });
    this.showProvisioningModal = false;
    this.refreshProvisioning();
  }

  public showEditNameDevice(): void {
    this.showEditName = true;
  }

  public onDeviceNameSave(newName: string): void {
    this.deviceManager
      .setDeviceName(this.device.id, newName.trim() || this.device?.displayName)
      .then(() => {
        // TODO: UI: At this moment, setDeviceName native API does not resolve the promise,
        // so we'll never get here. Once this is fixed, we should (probably) handle error.
      });
    this.showEditName = false;
  }

  public onDeviceNameCancel(): void {
    this.showEditName = false;
    this.detailNavService.configure({
      showNav: true,
      deviceHeading: this.device?.deviceName || this.device?.displayName,
    });
  }

  public onDeviceNameChange(newName: string): void {
    this.detailNavService.configure({
      showNav: true,
      deviceHeading: newName.trim() || this.device?.displayName,
    });
  }

  ngOnDestroy(): void {
    this.deviceSub?.unsubscribe();
    this.routeDeviceSub?.unsubscribe();
  }
}
