import { Component, EventEmitter, Input, Output } from "@angular/core";

@Component({
  selector: "oz-edit-custom-name",
  templateUrl: "./edit-custom-name.component.pug",
})
export class EditCustomNameComponent {
  @Input() name: string;

  @Output() nameChange: EventEmitter<string> = new EventEmitter<string>();
  @Output() save: EventEmitter<string> = new EventEmitter<string>();
  @Output() cancel: EventEmitter<void> = new EventEmitter<void>();

  constructor() {}

  public changeName(customName: string): void {
    this.name = customName;
    this.nameChange.emit(this.name);
  }

  public onSave(): void {
    this.save.emit(this.name);
  }

  public onCancel(): void {
    this.cancel.emit();
  }
}
