import { Observable } from "rxjs";

export abstract class IEventsManager {

    /**
     * Receives a device event.
     */
    abstract getDeviceEvents(): Observable<DeviceEvent>

    /**
     * Receives a call event (client event).
     */
    abstract getCallEvents(): Observable<CallEvent>

    /**
     * Receives a acoustic incident.
     */
    abstract getAcousticIncident(): Observable<AcousticIncident>

    /**
     * Receives a TWA event.
     */
    abstract getTwaEvents(): Observable<TwaEvent>

    /**
     * Receives a conversation analytics event.
     */
    abstract getConversationAnalyticsEvents(): Observable<ConversationAnalyticsEvent>

    /**
     * Receives a link quality event.
     */
    abstract getLinkQualityEvents(): Observable<LinkQualityEvent>

    /**
     * Receives an audio intelligence event for AERS & Link Quality.  
     */
    abstract getAudioIntelligenceEvents(): Observable<object>

    /**
     * Enables or disables audio intelligence events.
     * 
     * @param enabled If true, enables audio intelligence events, otherwise disables audio intelligence events.
     */
    abstract setAudioIntelligenceEnabled(enabled: boolean): void

    /**
     * Receives a video intelligence event.
     */
    abstract getVideoIntelligenceEvents(): Observable<object>

    /**
     * Enables or disables video intelligence events.
     * 
     * @param enabled If true, enables video intelligence events, otherwise disables video intelligence events.
     */
    abstract setVideoIntelligenceEnabled(enabled: boolean): void
}

export interface DeviceEvent {
    callId: string
    deviceId: string
    eventTime: string
    lineType: string
    name: string
    source?: string
    type: string
}

export interface CallEvent {
    callId: string
    deviceId: string
    direction: string
    duration?: number
    eventTime: string
    lineType: string
    name: string
    pluginId: string
    relatedDeviceEvent: string
    sessionId: string
    softphoneVersion: string
    source: string
    type: string
    userAction: string
}

export interface AcousticIncident {
    activeLimiters: number
    duration: number
    gainThreshold: number
    durationThreshold: number
    preLimiterSplEstimate: number
    postLimiterSplEstimate: number
    eventTime: string
    deviceId: string
    callId: string
    lineType: string
    type: string
}

export interface TwaEvent {
    periodicity: number
    preLimiterSplEstimate: number
    postLimiterSplEstimate: number
    twaDuration: number
    twaThreshold: number
    deviceId: string
    eventTime: string
    callId: string
    lineType: string
    type: string
}

export interface ConversationAnalyticsEvent {
    eventTime: string
    periodicity: number
    farTalk: number
    nearTalk: number
    overTalk: number
    txLevelIn: number
    txLevelOut: number
    rxLevelIn: number
    rxLevelOut: number
    txNoiseIn: number
    txNoiseOut: number
    rxNoiseIn: number
    rxNoiseOut: number
    txPeakIn: number
    txPeakOut: number
    rxPeakIn: number
    rxPeakOut: number
    txVolume: number
    rxVolume: number
    sideToneVolume: number
    deviceId: string
    callId: string
    lineType: string
    type: string
}

export interface LinkQualityEvent {
    eventTime: string
    linkQualityVersion: string
    periodicity: number
    RSSI: number
    LFOM: number
    rawLinkQuality: string
    deviceId: string
    callId: string
    type: string
}