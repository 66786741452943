import {Injectable} from "@angular/core";
import {Observable} from "rxjs";
import {
  BrickedDeviceInfo,
  DeviceUpdateProgress,
  DFUError,
  DFUOptions,
  IDFUManager
} from "../api/dfu-manager.api";

@Injectable({
  providedIn: "root"
})
export class WhDFUManager extends IDFUManager {

  startDFU(options: DFUOptions): Observable<DeviceUpdateProgress> {
    throw new Error("startDFU not implemented.");
  }

  retryDFU() {
    throw new Error("retryDFU not implemented.");
  }

  cancelDFU() {
    throw new Error("cancelDFU not implemented.");
  }

  isUpdateNeeded(deviceId: string, rulesJson: string): Promise<boolean> {
    return Promise.resolve(false);
  }

  canPerformDFU(deviceId: string): Promise<DFUError[]> {
    return Promise.resolve([]);
  }

  brickedDeviceStatus(): Promise<BrickedDeviceInfo> {
    return new Promise<BrickedDeviceInfo>(resolved => undefined);
  }

  IsPolicyUpdateNeeded(deviceId: string, rulesJson: string): Promise<boolean> {
    return Promise.resolve(false);
  }
}
