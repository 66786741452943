import { Component, Input, OnInit } from "@angular/core";
import { Device, GetGeneralSettingsResponse } from "@poly/hub-native";
import { DeviceManagerService } from "../../services/device-manager.service";
import { Observable } from "rxjs";
import { map, take } from "rxjs/operators";
import { AccordionItem } from "../../shared/components/accordion/accordion.component";
import {
  DEVICE_SETTING_ID_RESTORE_DEFAULTS,
  DEVICE_FEATURES,
  DEVICE_SETTING_ID_RESTART_DEVICE,
} from "../../utils/constants";
import { UtilityService } from "../../services/utility.service";
import { LoggedInDevices } from "../../services/loggedin-devices.service";
import { Subscriptions } from "../../utils/subscriptions";
import { OzUser, OzUserRole } from "../../services/user.service";

@Component({
  selector: "oz-device-admin-settings",
  templateUrl: "./device-admin-settings.component.pug",
})
export class DeviceAdminSettingsComponent implements OnInit {
  data: AccordionItem;
  device: Device;

  deviceSupportsRestartDeviceSetting = false;
  deviceSupportsRestoreDefaultsSetting = false;

  // Indicates if user that is logged -in into Lens has admin role
  isAdmin = false;
  // Indicates if user is logged-in to device
  loggedIn = false;

  // General settings
  generalSettings: GetGeneralSettingsResponse;

  private subs = new Subscriptions();

  @Input() set accordionData(newValue: AccordionItem) {
    this.data = newValue;
    this.device = this.data.data.device;
  }

  constructor(
    private deviceManager: DeviceManagerService,
    private loggedInDevices: LoggedInDevices,
    private user: OzUser
  ) {}

  ngOnInit() {
    // Check if user has admin role
    this.subs.add(
      this.user.role.subscribe((role) => {
        //this.isAdmin = role === OzUserRole.Admin;
        this.isAdmin = true;
      })
    );

    // Check if user has already been logged-in
    this.subs.add(
      this.loggedInDevices.all
        .pipe(map((uniqueIds) => uniqueIds.includes(this.device.uniqueId)))
        .subscribe((loggedIn) => {
          this.loggedIn = loggedIn;
        })
    );

    this.deviceSupportsRestartDeviceSettingPipeline().subscribe((supports) => {
      this.deviceSupportsRestartDeviceSetting = supports;
    });

    this.deviceSupportsRestoreDefaultsSettingPipeline().subscribe(
      (supports) => (this.deviceSupportsRestoreDefaultsSetting = supports)
    );

    this.deviceManager
      .getGeneralSettings(this.device?.id)
      .then((generalSettings: GetGeneralSettingsResponse) => {
        this.generalSettings = generalSettings;
      });
  }

  get deviceSupportsCameraVividMode(): boolean {
    return this.device.featureList.includes(DEVICE_FEATURES.CAMERA_VIVID_MODE);
  }

  get deviceSupportsWiFi(): boolean {
    return this.device.featureList.includes(DEVICE_FEATURES.WIFI);
  }

  get deviceSupportsBluetooth(): boolean {
    return this.device.featureList.includes(DEVICE_FEATURES.BLUETOOTH);
  }

  get deviceSupportsNetworkProvisioning(): boolean {
    return this.device.featureList.includes(
      DEVICE_FEATURES.NETWORK_PROVISIONING
    );
  }

  onLoggedIn() {
    this.loggedInDevices.addDevice(this.device);
  }

  /**
   * @returns Pipeline which checks if the device supports "Restart Device" setting.
   */
  private deviceSupportsRestartDeviceSettingPipeline(): Observable<boolean> {
    return this.deviceManager.getDeviceSettings(this.device.id).pipe(
      map(
        (settings) =>
          !!settings.find((setting) =>
            UtilityService.hexIsEqual(
              setting.id,
              DEVICE_SETTING_ID_RESTART_DEVICE
            )
          )
      ),
      take(1)
    );
  }

  /**
   * @returns Pipeline which checks if the device supports "Restore Defaults" setting.
   */
  private deviceSupportsRestoreDefaultsSettingPipeline(): Observable<boolean> {
    return this.deviceManager.getDeviceSettings(this.device.id).pipe(
      map(
        (settings) =>
          !!settings.find((setting) =>
            UtilityService.hexIsEqual(
              setting.id,
              DEVICE_SETTING_ID_RESTORE_DEFAULTS
            )
          )
      ),
      take(1)
    );
  }
}
