import { Component, Output, EventEmitter } from "@angular/core";
import { AnimationOptions } from "ngx-lottie";
import { AnimationItem } from "lottie-web";

@Component({
  selector: "oz-firmware-up-to-date",
  templateUrl: "./firmware-up-to-date.component.pug",
})
export class FirmwareUpToDateComponent {
  @Output() done = new EventEmitter();

  options: AnimationOptions = {
    path: "assets/lottie/firmware_up_to_date.json",
  };

  animationCreated(animationItem: AnimationItem) {
    animationItem.setSubframe(false);
  }
}
