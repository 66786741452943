import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, ParamMap } from "@angular/router";
import { pluck, switchMap, tap } from "rxjs/operators";
import { TenantService } from "../services/tenant.service";
import { AuthService, AuthProfile } from "../services/auth.service";
import { StorageService } from "../services/storage.service";
import { IOT_DEVICE_ID } from "../services/iot.service";
import { Device } from "@poly/hub-native";
import { DeviceManagerService } from "../services/device-manager.service";
import { UtilityService } from "../services/utility.service";
import { TranslateService } from "@ngx-translate/core";
import { DeviceUtils } from "../utils/device.utils";
import { DetailNavService } from "../services/detail-nav.service";
import { EMPTY, iif } from "rxjs";
import { PolytronServiceApi } from "../polytron/polytron.service.api";
import { ILoggingService } from "../services/logging.service";
import { AdminConfig } from "../services/admin-config.service";

@Component({
  selector: "poly-support",
  templateUrl: "./poly-support.component.pug",
})
export class PolySupportComponent implements OnInit {
  device: Device;
  type: string;
  supportEmail = "poly.com/contactsupport";
  tenantName: string;
  tenantId: string;
  appId: string;
  appVersion: string;
  nativeVersion: string;
  profile: any;

  private labels = { SAVE_LOGS: "" };

  constructor(
    private auth: AuthService,
    private deviceManager: DeviceManagerService,
    private deviceUtils: DeviceUtils,
    private detailNavService: DetailNavService,
    private route: ActivatedRoute,
    private storage: StorageService,
    private tenantService: TenantService,
    private translateService: TranslateService,
    private polytron: PolytronServiceApi,
    private logger:ILoggingService,
    private adminConfig:AdminConfig,
  ) {
    this.translateService
      .stream(["SUPPORT.SAVE_LOGS"])
      .subscribe((translations) => {
        Object.keys(translations).forEach((key) => {
          this.labels[key.replace("DEVICE_INFO_LOGS.", "")] = translations[key];
        });
      });
  }

  ngOnInit(): void {
    this.route.paramMap
      .pipe(
        tap((paramMap: ParamMap) => {
          this.type = paramMap.get("id") === "app" ? "app" : "device";
        }),
        switchMap((paramMap: ParamMap) => {
          if ("device" === this.type) {
            return this.deviceManager.getDevice(paramMap.get("id")); // "id" is Device#uniqueId
          } else {
            return EMPTY;
          }
        })
      )
      .subscribe((device: Device) => {
        if (device && this.type === "device") {
          this.device = device;
          this.detailNavService.configure({
            showDeviceHeading: false,
          });
        }
      });

    this.tenantService.tenantId$.subscribe((id: string) => {
      this.tenantId = id;
    });

    this.tenantService.tenantId$
      .pipe(
        switchMap((tenantId) =>
          iif(() => !!tenantId, this.tenantService.getTenantInfo(tenantId))
        ),
        pluck("data", "tenant")
      )
      .subscribe((tenant) => {
        this.tenantName = tenant.name;
      });

    this.auth.profile$.subscribe((profile: AuthProfile) => {
      this.profile = profile;
    });

    this.appId = this.storage.getItem(IOT_DEVICE_ID);
    this.appVersion = UtilityService.getBuild();
  }

  hasTenantName():boolean {
    return !!this.tenantName;
  }

  handleOpenSupport() {
    UtilityService.openExternalBrowser("https://support.hp.com/contact/product/poly-lens-desktop-and-web-app/2101801767/model/2101801770?source=polydropdown");
  }
  
  handleOpenCommunity() {
    UtilityService.openExternalBrowser("https://h30434.www3.hp.com/");
  }


  handleOpenDocumentation() {
    UtilityService.openExternalBrowser(
      "https://docs.poly.com/bundle/poly-lens-web"
    );
  }


  getSerialNumber() {
    return this.deviceUtils.getSerial(this.device);
  }

  async downloadLogs() {
    const now = new Date();
    const sn = `lenspwa-${now.getFullYear()}${(now.getMonth() + 1).toString().padStart(2, '0')}${now.getDate().toString().padStart(2, '0')}` +
      `-${now.getHours().toString().padStart(2, '0')}${now.getMinutes().toString().padStart(2, '0')}.log`
    let fileOpts = await this.polytron.showSaveDialog({
      title: this.labels.SAVE_LOGS,
      description: "Log file",
      suggestedName: sn,
      filters: {'text/plain': ['.log']},
      properties: ["createDirectory"], // [macOS] Allow creating new directories from dialog.
    });
    let {canceled, filePath, writableFile} = fileOpts;
    if (!canceled && writableFile) {
      // If user made selection
      let logs: string = this.logger.getAllLogs();
      this.logger.info("About to write logs to [" + filePath + "]");
      try {
        await writableFile.write(logs);
      }
      finally {
        await writableFile.close();
      }
    }
  }

  isPwa(): boolean {
    return this.adminConfig.mode === "pwa";
  }
}
