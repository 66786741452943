import { Device, SetId } from "@poly/hub-native";
import { Injectable } from "@angular/core";
import { removeManufacturerName } from "../shared/pipes/device-name.pipe";
import { BATTERY_WITH_CRITICAL_VALUE, BATTERY } from "./constants";

export interface DeviceComponent {
  name: string; // Name of a component (uppercased)
  version: string; // Firmware version of a component
}

/**
 * Finds the most significant component version from version string.
 *
 * @param version Version string in format that includes dots, for example '101.0.101'.
 * @returns Firmware version (the most significant part) as a string.
 */
export function getMostSignificantComponentVersion(
  version?: string,
  videoDevice = false
): string {
  if (videoDevice) {
    const parts = version
      ?.split(".")
      ?.map((each) => each.replace(/-.*|\D/g, ""));
    if (parts?.length) {
      let versionString = "";
      parts.forEach((p, i) => {
        if (i !== 0) versionString += ".";
        versionString += p;
      });
      return versionString;
    } else {
      return version;
    }
  } else {
    // If not a video device and has 4 numbers split with dot
    // It is probably setid format, return the same version to compare with cloud
    if (version?.split(".")?.length === 4) {
      return version;
    } else {
      let splitter = ".";
      if (version.includes("_")) {
        splitter = "_";
      }
      const parts = version?.split(splitter)?.map((each) => +each);
      for (const part of parts ?? []) {
        if (part !== 0) {
          return "" + part;
        }
      }
    }
  }
  return null;
}

/**
 * Finds battery icon name from battery level (Level 1 = critical)
 *
 * @param batteryLevel Battery level as number
 * @returns Icon name string
 */
export function getBatteryIconNameWithCriticalLevel(
  batteryLevel: number
): string {
  switch (batteryLevel) {
    case BATTERY_WITH_CRITICAL_VALUE.PERCENT_0:
      return "battery_flat";
    case BATTERY_WITH_CRITICAL_VALUE.BATTERY_CRITICAL:
      return "battery_critical";
    case BATTERY_WITH_CRITICAL_VALUE.PERCENT_25:
      return "battery_low";
    case BATTERY_WITH_CRITICAL_VALUE.PERCENT_50:
      return "battery_medium";
    case BATTERY_WITH_CRITICAL_VALUE.PERCENT_75:
      return "battery_high";
    case BATTERY_WITH_CRITICAL_VALUE.PERCENT_100:
      return "battery_full";
    default:
      return "battery_full";
  }
}

/**
 * Finds battery icon name from battery level
 *
 * @param batteryLevel Battery level as number
 * @returns Icon name string
 */
export function getBatteryIconNameWithoutCriticalLevel(
  batteryLevel: number
): string {
  switch (batteryLevel) {
    case BATTERY.PERCENT_0:
      return "battery_critical";
    case BATTERY.PERCENT_25:
      return "battery_low";
    case BATTERY.PERCENT_50:
      return "battery_medium";
    case BATTERY.PERCENT_75:
      return "battery_high";
    case BATTERY.PERCENT_100:
      return "battery_full";
    default:
      return "battery_full";
  }
}

/**
 * Finds battery icon name from battery percentage
 *
 * @param batteryLevel Battery level as number
 * @returns Icon name string
 */
export function getBatteryIconNameFromBatteryPercent(
  batteryPercentage: number
): string {
  if (batteryPercentage < 10) {
    return "battery_critical";
  }

  if (batteryPercentage < 30) {
    return "battery_low";
  }

  if (batteryPercentage < 50) {
    return "battery_medium";
  }

  if (batteryPercentage < 70) {
    return "battery_high";
  }

  if (batteryPercentage <= 100) {
    return "battery_full";
  }
}

/**
 * This function is used to compose a name of a device for the system level notifications.
 *
 * @param displayName Display name of a deivce (`device.displayName`)
 * @returns Device's `displayName` prefixed with the "Poly" string.
 */
export function addManufacturerPrefix(displayName: string) {
  return "Poly " + removeManufacturerName(displayName);
}

@Injectable({
  providedIn: "root",
})
export class DeviceUtils {
  public getSerial(device: Device) {
    if (device) {
      const tattooSerialObj = device.productSerialNumber;
      if (tattooSerialObj) {
        const tattooSerial = tattooSerialObj.headset
          ? tattooSerialObj.headset
          : tattooSerialObj.base;
        if (tattooSerial) {
          return tattooSerial;
        }
      }
      const serialObj = device.serialNumber;
      if (serialObj) {
        return serialObj.headset ? serialObj.headset : serialObj.base;
      }
    }
  }

  /**
   * Composes a SetId version as a string out of the SetId structure.
   *
   * @param version SetId firmware version structure.
   * @returns SetId firmware version as a string.
   */
  composeSetId(version: SetId): string {
    return `${version.major}.${version.minor}.${version.revision}.${version.build}`;
  }

  /**
   * Obtains the list of components that a device consists of.
   *
   * @param device Device for which the list of components is being obtained.
   * @returns A list of components that the device consists of.
   */
  getDeviceComponents(device: Device): DeviceComponent[] {
    const components = device?.firmwareVersion;
    // If headset is connected via bluetooth USB adapter, don't show USB component
    const excludeComponents = !!device.parentDeviceId ? ["usb"] : [];
    if (components) {
      return (
        Object.keys(components)
          // Retain only components that the device consists of + components that are not excluded
          .filter(
            (componentName) =>
              components[componentName] &&
              !excludeComponents.includes(componentName)
          )
          .map((componentName) => {
            const componentFirmwareVersion = components[componentName];
            return {
              name: componentName.toUpperCase(),
              version:
                componentName === "setId"
                  ? this.composeSetId(componentFirmwareVersion as SetId)
                  : componentFirmwareVersion,
            };
          })
      );
    } else {
      return [];
    }
  }
}
