import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { DeviceManagerService } from "./device-manager.service";
import { BrickedDevice, BrickedDeviceInfo } from "@poly/hub-native";

@Injectable({
  providedIn: "root",
})
export class BrickedDeviceService {
  private _brickedDeviceInfo$ = new BehaviorSubject<BrickedDeviceInfo>(null);
  constructor(private deviceManager: DeviceManagerService) {
    this.getBrickedDeviceInfo();

    this.deviceManager
      .getBrickedDevice()
      .subscribe((brickedDevice: BrickedDevice) => {
        const status: BrickedDeviceInfo = {
          deviceId: brickedDevice.pid,
          recoveryArchivePath: brickedDevice.recoveryArchivePath,
        };
        this._brickedDeviceInfo$.next(status);
      });
  }

  get brickedDeviceInfo$() {
    return this._brickedDeviceInfo$.asObservable();
  }

  getBrickedDeviceInfo() {
    this.deviceManager
      .brickedDeviceStatus()
      .then((status: BrickedDeviceInfo) => {
        this._brickedDeviceInfo$.next(status);
      });
  }
}
