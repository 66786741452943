import { Injectable } from "@angular/core";
import { HISTORICAL_DEVICES_KEY } from "../utils/constants";
import { DeviceManagerService } from "./device-manager.service";
import { CallEvent, Device } from "@poly/hub-native";
import { v4 as UUID } from "uuid";
import { StorageService } from "./storage.service";
import { Observable, Subject } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class CallEventsService {
  constructor(
    private deviceManager: DeviceManagerService,
    private storage: StorageService
  ) {
    //TODO/JTB: handle this
    // this.deviceManager.getCallEvents().subscribe((callEvent) => {
    //   this.processCallEvents(callEvent);
    // });
  }

  private callDetailRecord$ = new Subject<CallDetailRecord>();
  private callConnection$ = new Subject<CallConnection>();

  private processCallEvents(callEvent: CallEvent): void {
    const uniqueIds = this.findUniqueIdsForAllDevicesInCall(callEvent);

    // Create call message for each device connected during the call, parent and children,
    // e.g. Voyager 8200 connected to BT600 during the call.
    uniqueIds.forEach((uniqueId) => {
      // create cdr message only when call ends -  "ended" call event type
      if (callEvent.name === "ended") {
        this.callDetailRecord$.next(
          CallEventsService.createCallDetailRecordMessage(callEvent, uniqueId)
        );
      }
      this.callConnection$.next(
        CallEventsService.createCallConnectionMessage(callEvent, uniqueId)
      );
    });
  }

  /**
   * Returns array of uniqueIds for all devices connected during the call.
   *
   * @private
   */
  private findUniqueIdsForAllDevicesInCall(callEvent: CallEvent): string[] {
    // Find uniqueIds for all connected devices - child and parent
    const devicesObj = this.storage.getItem(HISTORICAL_DEVICES_KEY);
    const devices: Device[] = Object.values(devicesObj);

    const uniqueIds = [];
    const callEventDevice: Device = devices.find(
      (device) => callEvent.deviceId.toLowerCase() === device.uniqueId
    );
    if (callEventDevice?.isConnected) {
      uniqueIds.push(callEventDevice.uniqueId);
    }

    // Call event object can hold uniqueId of a parent only - find child in that case
    const callEventChildDevice = devices.find(
      (device) => device.parentDeviceId === callEventDevice?.id
    );
    if (callEventChildDevice && callEventChildDevice.isConnected)
      uniqueIds.push(callEventChildDevice.uniqueId);

    return uniqueIds;
  }

  public getCallDetailRecordMessages(): Observable<CallDetailRecord> {
    return this.callDetailRecord$;
  }

  public getCallConnectionMessages(): Observable<CallConnection> {
    return this.callConnection$;
  }

  private static createCallDetailRecordMessage(
    callEvent: CallEvent,
    uniqueId: string
  ): CallDetailRecord {
    const uuid = UUID();
    return {
      frameId: uuid,
      name: "LENS_APP",
      appName: "OZ_APP",
      type: "telemetry",
      deviceid: uniqueId,
      payload: {
        attr: "hubv3",
        version: "0.0.1",
        value: {
          eventType: "DeviceInfo.cdrInfo",
          eventTime: new Date().toJSON(),
          eventVersion: "1.0.0",
          eventdata: {
            callDirection: callEvent.direction,
            callId: callEvent.callId,
            name: CallEventsService.mapCallStatusName(callEvent.name),
            endTime: callEvent.eventTime,
            lineType: callEvent.lineType,
            source: callEvent.source,
            pluginId: callEvent.pluginId,
            softphoneVersion: callEvent.softphoneVersion,
            answeredInSoftphone: true,
            callDurationMs: callEvent.duration,
            userAction: callEvent.userAction,
            relatedDeviceEvent: callEvent.relatedDeviceEvent,
          },
        },
      },
    };
  }

  private static createCallConnectionMessage(
    callEvent: CallEvent,
    uniqueId: string
  ): CallConnection {
    const uuid = UUID();
    return {
      frameId: uuid,
      name: "LENS_APP",
      appName: "OZ_APP",
      type: "telemetry",
      deviceid: uniqueId,
      payload: {
        attr: "hubv3",
        version: "0.0.1",
        value: {
          eventType: "DeviceInfo.callConnection",
          eventTime: new Date().toJSON(),
          eventVersion: "1.0.0",
          eventdata: {
            callDirection: callEvent.direction,
            callId: callEvent.callId,
            callStatus: CallEventsService.mapCallStatusName(callEvent.name),
            eventTime: callEvent.eventTime,
            lineType: callEvent.lineType,
            source: callEvent.source,
          },
        },
      },
    };
  }

  private static mapCallStatusName(name: string): string {
    switch (name) {
      case "accept":
        return "started";
      case "terminate":
        return "stopped";
      case "resume":
        return "connected";
      case "inProgress":
        return "connected";
      case "ended":
        return "stopped";
      case "mobileCallRinging":
        return "started";
      case "mobileCallInProgress":
        return "connected";
      case "mobileCallEnded":
        return "stopped";
      case "reject":
        return "stopped";
      default:
        return name;
    }
  }
}

export type CallDetailRecord = {
  frameId: string;
  name: string;
  appName: string;
  type: string;
  deviceid: string;
  payload: {
    attr: string;
    version: string;
    value: {
      eventType: string;
      eventTime: string;
      eventVersion: string;
      eventdata: {
        callDirection: string;
        callId: string;
        name: string;
        endTime: string;
        lineType: string;
        source: string;
        pluginId: string;
        softphoneVersion: string;
        answeredInSoftphone: boolean;
        callDurationMs: number;
        userAction: string;
        relatedDeviceEvent: string;
      };
    };
  };
};

export type CallConnection = {
  frameId: string;
  name: string;
  appName: string;
  type: string;
  deviceid: string;
  payload: {
    attr: string;
    version: string;
    value: {
      eventType: string;
      eventTime: string;
      eventVersion: string;
      eventdata: {
        callDirection: string;
        callId: string;
        callStatus: string;
        eventTime: string;
        lineType: string;
        source: string;
      };
    };
  };
};
