import { HostListener } from "@angular/core";
import { Component } from "@angular/core";
import { Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { Toasts } from "../shared/components/toast/toasts.service";
import { isEqual as _isEqual } from "lodash";
import { Features, StateService } from "../services/state.service";
import { UtilityService } from "../services/utility.service";
import {BADGE_ACCOUNT, BADGE_SOFTWARE_UPDATE, BadgeCountService} from "../services/badge-count.service";
import {ConfigService} from "../services/config.service";

@Component({
  selector: "oz-secret-trigger",
  template: "",
})
export class SecretTrigger {
  maxSequenceLength = 0;

  // IMPORTANT: sequences are based on a queue, that is they should be unique and not build off each other
  // in this case, sequence 2 would never trigger:
  // sequence 1: ArrowUp, ArrowLeft
  // sequence 2: ArrowUp, ArrowLeft, k
  sequences = [
    {
      sequence: ["ArrowUp", "ArrowUp", "ArrowDown", "ArrowDown"],
      action: () => {
        this.router.navigate(["/secret"]);
      },
    },
    // if curious, try out these sequences to see what each language is useful for in debugging/developing/translating
    // more details on these languages in README.md
    {
      sequence: ["ArrowLeft", "ArrowUp", "ArrowRight", "e"],
      action: () => {
        if (!UtilityService.isBuildBranchMaster()) {
          this.translateService.use("en");
        }
      },
    },
    {
      sequence: ["ArrowLeft", "ArrowUp", "ArrowRight", "k"],
      action: () => {
        if (!UtilityService.isBuildBranchMaster()) {
          this.translateService.use("mock-klingon");
        }
      },
    },
    {
      sequence: ["ArrowLeft", "ArrowUp", "ArrowRight", "b"],
      action: () => {
        if (!UtilityService.isBuildBranchMaster()) {
          this.translateService.use("mock-blargon7");
        }
      },
    },
    {
      sequence: ["ArrowLeft", "ArrowUp", "ArrowRight", "u"],
      action: () => {
        if (!UtilityService.isBuildBranchMaster()) {
          this.translateService.use("mock-uid");
        }
      },
    },
    {
      sequence: ["b", "a", "d", "g", "e", "a", "ArrowUp"],
      action: () => {
        if (this.config.inDevMode) {
          this.badge.incrementCountBy1(BADGE_ACCOUNT);
        }
      }
    },
    {
      sequence: ["b", "a", "d", "g", "e", "a", "ArrowDown"],
      action: () => {
        if (this.config.inDevMode) {
          this.badge.decrementCountBy1(BADGE_ACCOUNT);
        }
      }
    },
    {
      sequence: ["b", "a", "d", "g", "e", "s", "ArrowUp"],
      action: () => {
        if (this.config.inDevMode) {
          this.badge.incrementCountBy1(BADGE_SOFTWARE_UPDATE);
        }
      }
    },
    {
      sequence: ["b", "a", "d", "g", "e", "s", "ArrowDown"],
      action: () => {
        if (this.config.inDevMode) {
          this.badge.decrementCountBy1(BADGE_SOFTWARE_UPDATE);
        }
      }
    },
    {
      // type "polyglot" on /settings page to enable experimentalLanguages
      // per Robert M 8-25-21, it is OK for master branch builds to have this feature
      sequence: ["p", "o", "l", "y", "g", "l", "o", "t"],
      action: () => {
        if (this.router.url !== "/settings") {
          return;
        }

        const featureKey: keyof Features = "experimentalLanguages";
        const newState = !this.stateService.getState("Features", {})[
          featureKey
        ];
        this.stateService.setDeepState("Features", featureKey, newState);

        if (newState) {
          this.toast.push({
            type: "status",
            status: "success",
            text: "NOTIFICATIONS.LANGUAGE_SECRET_FUNCTIONALITY_ENABLED.MESSAGE",
            dismissAfter: 5,
          });
        }
      },
    },
  ];

  constructor(
    private router: Router,
    private translateService: TranslateService,
    private stateService: StateService,
    private toast: Toasts,
    private badge: BadgeCountService,
    private config: ConfigService
  ) {
    this.sequences.forEach((sequenceObj) => {
      if (sequenceObj.sequence.length > this.maxSequenceLength) {
        this.maxSequenceLength = sequenceObj.sequence.length;
      }
    });
  }

  keySeries: string[] = [];

  @HostListener("document:keydown", ["$event"])
  keyDown(e: KeyboardEvent) {
    this.keySeries.push(e.key);

    this.sequences.forEach((sequenceObj) => {
      const sequenceLength = sequenceObj.sequence.length;
      if (sequenceLength > this.keySeries.length) {
        return;
      }

      const comparison = this.keySeries.slice(
        this.keySeries.length - sequenceLength,
        this.keySeries.length
      );
      if (_isEqual(comparison, sequenceObj.sequence)) {
        sequenceObj.action();
      }
    });

    // prune when keySeries gets too long
    if (this.keySeries.length > this.maxSequenceLength) {
      this.keySeries.shift();
    }
  }
}
