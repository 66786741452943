import { ZooEntityStatePusherService } from "./services/zoo-entity-state-pusher.service";
import { IpReporterService } from "./services/ip-reporter.service";
import { Router } from "@angular/router";
import { BehaviorSubject, Subscription } from "rxjs";
import { MuteNotificationsService } from "./services/mute-notifications.service";
import { DisconnectedNotificationsService } from "./services/disconnected-notifications.service";
import { ChargingNotificationsService } from "./services/charging-notifications.service";
import { StateService } from "./services/state.service";
import { SwUpdateNotificationsService } from "./services/sw-update-notifications.service";
import { DfuNotificationsService } from "./services/dfu-notifications.service";
import { DevicePolicyService } from "./services/device-policy.service";
import { IotService } from "./services/iot.service";
import { AuthService } from "./services/auth.service";
import { Component, HostListener, OnDestroy, OnInit } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { LensSettingsService } from "./services/lens-settings.service";
import { TrayService } from "./services/tray.service";
import { NotificationsService } from "./services/notifications.service";
import {
  LANGUAGES,
  DEFAULT_LANGUAGE,
  AUTH_RESOLVED_SESSION_KEY,
  // MOUSE_BUTTON,
} from "./utils/constants";
import { UtilityService } from "./services/utility.service";
import { ProductCatalog } from "./services/product.catalog.service";
import { StorageService } from "./services/storage.service";
import { LoggedInDevices } from "./services/loggedin-devices.service";
import { Repository } from "./services/repository/repository.service";
import { ILoggingService } from "./services/logging.service";
import { SoundscapeService } from "./services/soundscape.service";
import { Toasts } from "./shared/components/toast/toasts.service";
import { PostponeDfuService } from "./shared/components/toast/postpone-dfu-toast/postpone-dfu.service";
import { QdNotificationsService } from "./services/qd-notifications.service";
import { WindowsEventsService } from "./services/windows-events.service";
import { DeviceNotificationService } from "./services/device-notification.service";
import { AdminConfig } from "./services/admin-config.service";
import { LowBatteryNotificationsService } from "./services/low-battery-notifications.service";
import { AcousticEventsService } from "./services/acoustic-events.service";
import { environment } from 'src/environments/environment';
import { Renderer2, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';



@Component({
  selector: "app-root",
  templateUrl: "./app.component.pug",
})
export class AppComponent implements OnInit, OnDestroy {
  
  private authServiceSub: Subscription;
  private lensSettingsServiceSub: Subscription;
  public soundscapeEnabled$: BehaviorSubject<boolean> = new BehaviorSubject(
    false
  );

  // these are all necessary even if marked as unused by IDE
  constructor(
    public authService: AuthService,
    private chargingNotifications: ChargingNotificationsService,
    private disconnectNotifications: DisconnectedNotificationsService,
    private swUpdateNotifications: SwUpdateNotificationsService,
    private DfuNotifications: DfuNotificationsService,
    private devicePolicies: DevicePolicyService,
    private muteNotifications: MuteNotificationsService,
    private toasts: Toasts,
    private trayService: TrayService,
    private iot: IotService,
    private lensSettingsService: LensSettingsService,
    private notificationService: NotificationsService,
    private soundscapeService: SoundscapeService,
    private router: Router,
    private translateService: TranslateService,
    private stateService: StateService,
    private ipService: IpReporterService,
    private productCatalog: ProductCatalog,
    private storageService: StorageService,
    private zooEntityStatePusher: ZooEntityStatePusherService,
    private LoggedInDevices: LoggedInDevices,
    private repo: Repository,
    private logger: ILoggingService,
    private postponeDfuService: PostponeDfuService,
    private qdNotificationsService: QdNotificationsService,
    private windowsEventsService: WindowsEventsService,
    private DeviceNotificationService: DeviceNotificationService,
    private adminConfig: AdminConfig,
    private lowBatteryNotifications: LowBatteryNotificationsService,
    private acousticEventsService: AcousticEventsService,
    private renderer: Renderer2, @Inject(DOCUMENT) private document: Document
  ) {
    // Not using getBrowserLang, as it doesn't appear to properly pick up system locale
    // TODO: const userLang = UtilityService.getElectronArgument("locale");
    // TODO: const matchedLang = this.findLang(userLang);

    this.setGtmId();

    const languages = LANGUAGES;

    // if (!UtilityService.isBuildBranchMaster()) {
    //   languages.push(...["mock-klingon", "mock-blargon7", "mock-uid"]);
    // }

    translateService.addLangs(languages);
    translateService.setDefaultLang(DEFAULT_LANGUAGE);
    // TODO: translateService.use(matchedLang);

    // iot is null for on-prem deployment
    iot?.init();
    ipService.init();
    productCatalog.init();
  }

  /**
   * Attempt to match the requested language (e.g. "en", "en-US") against
   * the list of available languages.
   *
   * Uses some heuristics to match differing length language keys in the
   * even the language key does not match exactly.
   */
  findLang(needle: string) {
    const LANG_SEP = "-";
    needle = needle.toLowerCase().replace("_", LANG_SEP);
    const available = LANGUAGES.map((key) => key.toLowerCase());

    // Exact matches obviously return immediately
    if (available.includes(needle)) {
      return needle;
    }

    let matched = [];
    const short = needle.split(LANG_SEP)[0];

    // If the requested language is long (eg, "fr-FR"), attempt to match the short version ("fr") to "full" available language key
    if (needle.split(LANG_SEP).length > 1) {
      matched = available.filter((avail) => avail === short);

      if (matched.length) {
        return matched[0];
      }
    }

    // If no matches this far, attempt to match requested "short" version against "short" available language keys
    matched = available.filter((avail) => avail.split(LANG_SEP)[0] === short);
    if (matched.length) {
      return matched[0];
    }

    // If still no matches, return the internally defined default language
    return DEFAULT_LANGUAGE;
  }

  ngOnInit(): void {
    const url = new URL(window.location.href);
    // this.logger.debug(`JTB: AppComponent -> url=${url.toString()}`);

    /* TODO:
    this.lensSettingsService.lensSettings
      .pipe(take(1))
      .subscribe((settings) => {
        // Check if launchAtStartup and launchMinimized are configured in admin-config.json
        if (this.adminConfig.launchAtStartup !== null)
          settings.startOnSystemStartup = this.adminConfig.launchAtStartup;

        if (this.adminConfig.launchMinimized !== null)
          settings.startMinimized = this.adminConfig.launchMinimized;
        this.electronService.setStartOnSystemStartup(
          settings.startOnSystemStartup,
          settings.startMinimized
        );
      });
     */
    this.lensSettingsServiceSub = this.lensSettingsService.lensSettings.subscribe(
      (settings) => {
        this.soundscapeEnabled$.next(settings.soundscapeEnabled);
      }
    );

    this.authServiceSub = this.authService.isAuthenticated$.subscribe(
      (authed) => {
        if (!authed) {
          this.storageService.clearItem(AUTH_RESOLVED_SESSION_KEY, true);
        }
      }
    );
    this.checkBrowserSupported();
  }

  ngOnDestroy(): void {
    this.lensSettingsServiceSub.unsubscribe();
    this.authServiceSub.unsubscribe();
  }

  /**
   * App-wide squelch of middle-click mouse events; otherwise,
   * app opens a new browser window on middle click.
   */
  // @HostListener("document:auxclick", ["$event"])
  // onAuxClick(e: MouseEvent) {
  //   if (MOUSE_BUTTON.MIDDLE === e.button) {
  //     e.preventDefault();
  //   }
  // }

  title = 'lenspwa';

  checkBrowserSupported() {
    const browserName = this.detectBrowserName();
    const SUPPORTED_BROWSERS = ["chrome", "edge"];

    // console.log(window.navigator.platform); // MacIntel
    if (!SUPPORTED_BROWSERS.includes(browserName)) {
      window.alert("This Poly Lens Web application is designed to run on Chromium based browsers only - " +
        "please launch it with either Chrome or Edge, instead of using: " + browserName);
      const ok = window.confirm("Close this tab/window?");
      window.stop();
      if (ok) {
        //TODO: this by itself is not doing the close I thought it would - you know, based on its name!
        // window.close();
        window.history.back();
      }
    }
  }

  detectBrowserName() {
    const agent = window.navigator.userAgent.toLowerCase()
    switch (true) {
      case agent.indexOf('edge') > -1:
        return 'edge';
      case agent.indexOf('opr') > -1 && !!(<any>window).opr:
        return 'opera';
      case agent.indexOf('chrome') > -1 && !!(<any>window).chrome:
        return 'chrome';
      case agent.indexOf('trident') > -1:
        return 'ie';
      case agent.indexOf('firefox') > -1:
        return 'firefox';
      case agent.indexOf('safari') > -1:
        return 'safari';
      default:
        return 'other';
    }
  }

  private setGtmId() {
    const script = this.renderer.createElement('script');
    script.type = 'text/javascript';
    script.text = `
      (function(w,d,s,l,i){
        w[l]=w[l]||[];
        w[l].push({'gtm.start': new Date().getTime(),event:'gtm.js'});
        var f=d.getElementsByTagName(s)[0],
        j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';
        j.async=true;
        j.src='https://www.googletagmanager.com/gtm.js?id=${environment.gtmId}' + dl;
        f.parentNode.insertBefore(j,f);
      })(window,document,'script','dataLayer', '');
    `;
    this.renderer.appendChild(this.document.head, script);
  }
}
