import { DomSanitizer, SafeUrl } from "@angular/platform-browser";
import { map, switchMap } from "rxjs/operators";
import { ILoggingService } from "../../services/logging.service";
import { of, Observable } from "rxjs";
import { Pipe, PipeTransform } from "@angular/core";
import {
  NO_DEVICE_IMAGE,
  NO_DEVICE_TYPE,
  ProductCatalog,
} from "../../services/product.catalog.service";
import { UtilityService } from "../../services/utility.service";
import { isError as _isError } from "lodash";

/**
 * This does not work with AsyncPipe strategy from translate-collection.pipe.ts
 * Thus, using an async pipe until improved.
 *
 * @example {{ device | deviceImage | async }}
 */
@Pipe({
  name: "deviceImage",
})
export class DeviceImagePipe implements PipeTransform {
  constructor(
    private logger: ILoggingService,
    private productCatalog: ProductCatalog,
    private domSanitizer: DomSanitizer
  ) {}

  /**
   * As of 3-15-2021, locally stored static images are no longer used.
   * @see product.catalog.service.ts
   *
   * PID passed directly to avoid an image flicker when device object changes
   * even with same PID (LENS-1112).
   */
  transform(pid: number, headsetPID?: number): Observable<SafeUrl> {
    // caution: 0x0 is a valid pid but would evaluate to falsy
    if (0x0 !== pid && !pid) {
      return of(this.domSanitizer.bypassSecurityTrustUrl(NO_DEVICE_IMAGE));
    }

    // get image updates specific to a pid
    // if pid does not exist, NO_DEVICE_IMAGE will be returned
    // if headsetPID is available, try to find that image
    return this.productCatalog.productImageUrl$(pid, headsetPID).pipe(
      switchMap((url) => {
        return this.loadURL(url);
      }),
      map((imageData) => {
        // can inject url as link or as base64 encoded image directly into `src` tag
        return this.domSanitizer.bypassSecurityTrustUrl(imageData);
      })
    );
  }

  /**
   * Using fetch to post a browser-based request.  This provides multiple benefits:
   * 1. Automatic browser-based caching
   * 2. Responds with prefetched results when available
   * 3. Offline support when an image is prefetched
   * 4. Automatic etag/cache support, or ability to vary refreshes based on request headers
   *
   * This function uses base64 encoding rather than passing the URL, for the simple reason that it is
   * challenging to respond gracefully to image load failures.  There are a variety of methods for this,
   * but sending a base64 encoded image allows this pipe to maintain control of graceful degradation logic.
   * End result: the user never sees a failed image load, even when offline.
   */
  async loadURL(url: string): Promise<string> {
    let image;
    let contentType;
    let usingFallback = false;
    let response = await UtilityService.attemptAsync(fetch, url);

    if (_isError(response) || !response?.ok) {
      // could not retrieve image, load fallback
      this.logger.error(
        `Error downloading product image ${url}, using fallback`,
        {
          status: response.status,
          statusText: response.statusText,
          error: _isError(response) ? response : response.error,
        }
      );

      response = await UtilityService.attemptAsync(fetch, NO_DEVICE_IMAGE);

      if (_isError(response) || !response?.ok) {
        // fallback image did not load, out of options
        this.logger.error("Could not load fallback image");
        return "";
      }

      usingFallback = true;
    }

    const arrayBuffer = await response.arrayBuffer();
    // using `new Uint8Array` only for proper typing, `new Buffer(arrayBuffer)` works in practice
    image = new Buffer(new Uint8Array(arrayBuffer)).toString("base64");
    contentType = usingFallback
      ? NO_DEVICE_TYPE
      : response.headers.get("content-type")?.toLowerCase();

    // alternate method from arrayBuffer if needed: `return URL.createObjectURL(await response.blob())`,
    // which would require DOM sanitization bypass

    return `data:${contentType};base64,${image}`;
  }
}
