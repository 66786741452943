import { Injectable, NgZone } from "@angular/core";
import {
  AuthClientConfig,
  AuthService as Auth0Service
} from '@auth0/auth0-angular';
import { HttpClient } from "@angular/common/http";
import { Router } from "@angular/router";
import { BehaviorSubject } from "rxjs";
import { map } from "rxjs/operators";

import { IAuthService, AuthProfile } from "./auth.service";
import { ConfigService, Environment } from "./config.service";
import { ILoggingService } from "./logging.service";
import { StorageService } from "./storage.service";
import { REGISTERED_DEVICES } from "../utils/constants";

const ACCESS_TOKEN_KEY = "AUTH_ACCESS_TOKEN";
export const PROFILE_KEY = "AUTH_PROFILE";
const REFRESH_TOKEN_KEY = "AUTH_REFRESH_TOKEN";


@Injectable({
  providedIn: "root",
})
export class AuthServiceZero implements IAuthService {
  private _isAuthenticated$ = new BehaviorSubject<boolean>(false);
  private _profile$ = new BehaviorSubject<AuthProfile>(null);
  private _accessToken$ = new BehaviorSubject<string>(null);
  private _tenantToken$ = new BehaviorSubject<string>(null);
  private _preLogoutHandlers: Array<Function> = [];
  private env: Environment;
  private inited = false;
  private routeUrl: string = "/home";

  get isAuthenticated$() {
    // fyi - we need to keep our own state, so we can sync the use of the token
    return this._isAuthenticated$;
  }

  get accessToken$() {
    return this._accessToken$.asObservable();
  }
  get accessTokenValue() {
    return this._accessToken$.value;
  }

  get profile$() {
    return this._profile$.asObservable();
  }

  private set accessToken(val) {
    val
      ? this.storageService.setItem(ACCESS_TOKEN_KEY, val)
      : this.storageService.clearItem(ACCESS_TOKEN_KEY);
    this._accessToken$.next(val);
  }

  private set profile(val) {
    val
      ? this.storageService.setItem(PROFILE_KEY, val)
      : this.storageService.clearItem(PROFILE_KEY);
    this._profile$.next(val);
  }

  private get refreshToken() {
    return this.storageService.getItem(REFRESH_TOKEN_KEY);
  }

  hasRefreshToken() {
    return !!this.refreshToken;
  }

  private set refreshToken(val) {
    val
      ? this.storageService.setItem(REFRESH_TOKEN_KEY, val)
      : this.storageService.clearItem(REFRESH_TOKEN_KEY);
  }

  constructor(
    private auth0: Auth0Service,
    private authConfig: AuthClientConfig,
    private http: HttpClient,
    private router: Router,
    private ngZone: NgZone,
    private storageService: StorageService,
    private config: ConfigService,
    private logger: ILoggingService
  ) {
    this.config.env$.subscribe((env) => {
      this.env = env;

      const ac = this.authConfig.get();
      ac.audience = this.env.AUTH0_AUDIENCE;
      ac.domain = this.env.AUTH0_DOMAIN;
      ac.redirectUri = this.env.AUTH0_CALLBACK;
      ac.clientId = this.env.AUTH0_CLIENT_ID;

      !this.inited ? this.init() : null;
    });

    this.auth0.error$.subscribe((e) => this.logger.error("auth0 error", e));

    this.auth0.isAuthenticated$.subscribe( (b) => {
      this.logger.info("authenticated: " + b);
      // this.logger.info("crossOriginIsolated: " + (window as any).crossOriginIsolated);

      // Auth0 does not expose the refresh token, but fully encapsulates its use.
      // Our app needs this value set (and persisted) to behave consistent with the Desktop.
      this.refreshToken = "Yeah 220, 221 whatever it takes!";
    });

    this.auth0.user$.subscribe(user => {
      if (user) {
        this.logger.info("user profile: " + JSON.stringify(user, null, 2));
        this.profile = AuthProfile.build(user);
      }
    });
  }

  async init() {
    this.inited = true;
    if (this.refreshToken) {
      await this.refreshTokens();
    }
  }

  /**
   * Calls to this function should be debounced, e.g. using debounce-click directive.
   *
   * @param destinationUrl
   * @private
   */
  startLoginFlow(destinationUrl: string) {
    this.auth0.loginWithRedirect({ // RedirectLoginOptions
      // audience: this.env.AUTH0_AUDIENCE,
    });
  }


  // See: AppComponent ngOnInit()
  public handleCallback(url: URL) {
    const code = url.searchParams.get("code");

    if (code) {
      this.logger.debug("handling callback URL: " + url.href);
      this.setupAccessToken("handling login callback", () => {
        this.ngZone.run(() => {
          this.router.navigate([this.routeUrl]);
        });
      });
    } else {
      // this naturally & correctly happens the first time the app is loaded
      this.logger.warn("callback to load tokens called without a code - cannot proceed at this time");
    }
  }

  public async refreshTokens() {
    if (this.refreshToken) {
      this.logger.debug("refreshing tokens ...");
      this.setupAccessToken("refreshing token");
    } else {
      this.logger.warn("no refresh token setup - cannot proceed at this time");
    }
  }

  private setupAccessToken(during: string, onComplete?: Function) {
    this.auth0.getAccessTokenSilently({
      timeoutInSeconds: 10,
      ignoreCache: false
    }).subscribe({
      next: token => {
        if (token) {
          //TODO: when we sort out why this gets logged 2-3x, then we can comment this out
          this.logger.debug("access token: " + token.substring(0, 10) + "...");
          this.accessToken = token;
          this.isAuthenticated$.next(true);
        } else {
          this.logger.warn("setup access token did not get a token");
        }
      },
      error: e => {
        this.logger.error("problem getting access token while " + during, e);
      },
      complete: () => {
        if (onComplete) {
          this.logger.info("get access token complete");
          onComplete();
        }
      }
    });
  }

  public registerPreLogoutHandler(v: Function) {
    this._preLogoutHandlers.push(v);
  }

  async startLogoutFlow(destinationUrl: string) {
    for (const handlerIndex in this._preLogoutHandlers) {
      try {
        const handlerResult = await this._preLogoutHandlers[handlerIndex](
          this.isAuthenticated$ && !!this._accessToken$.value
        );
        if (!handlerResult) {
          this.logger.info(`aborting logout`);
          return;
        }
      } catch (error) {
        this.logger.error(`error in prelogout handler`, error);
        return;
      }
    }

    this.auth0.logout({
      // returnTo: window.location.origin, --> does not work for .../PWA/lenspwa/index.html type deployment
      returnTo: this.env.AUTH0_CALLBACK,
      federated: true,
      // localOnly: true,
    });
    this.profile = null;
    this.accessToken = null;
    this.refreshToken = null;
    this.storageService.clearItem(REGISTERED_DEVICES);
  }

  get tenantToken$() {
    return this._tenantToken$.asObservable();
  }

  public setTenantToken(token: string) {
    this._tenantToken$.next(token);
  }

  get isLoginless$() {
    return this.tenantToken$.pipe(map((token) => !!token));
  }

  public getTenantToken(): void {}

  setIsAuthenticated(isAuthenticated: boolean): void {
    this._isAuthenticated$.next(isAuthenticated);
  }

  public setProfile(profile: AuthProfile) {
    this._profile$.next(profile);
  }
}
