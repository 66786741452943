import { Component, EventEmitter, Input, Output } from "@angular/core";
import { Device } from "@poly/hub-native";
import { DeviceManagerService } from "../../services/device-manager.service";
import { ILoggingService } from "../../services/logging.service";
import { UtilityService } from "../../services/utility.service";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "oz-device-admin-login",
  templateUrl: "./device-admin-login.component.pug",
})
export class DeviceAdminLoginComponent {
  readonly TRANSLATE_PREFIX = "DeviceSettings.LOGIN_WITH_DEVICE_PASSWORD.";

  @Input()
  device: Device;

  @Output()
  success = new EventEmitter<void>();

  // Manages visibility of a dialog where user enters a password
  showDialog = false;

  // Device password that user enters to unlock Admin settings
  devicePassword = "";

  // User has entered an incorrect password
  passwordIncorrect = false;

  // Shows general error message
  showGeneralErrorMessage = false;

  constructor(
    private deviceManager: DeviceManagerService,
    private logger: ILoggingService,
    private translate: TranslateService,
  ) {}

  onCancel() {
    this.showDialog = false;
    this.resetInputs();
  }

  onLogin() {
    this.passwordIncorrect = false;
    this.deviceManager
      .adminLogin({
        deviceId: this.device?.id,
        password: this.devicePassword,
      })
      .then(({ result: loggedIn, status }) => {
        // Hide all error messages
        this.passwordIncorrect = false;
        this.showGeneralErrorMessage = false;

        if (status === "OK") {
          if (loggedIn) {
            this.showDialog = false;
            this.success.emit();
          } else {
            this.passwordIncorrect = true;
          }
        } else {
          this.logger.error(
            `Device communication error has occurred while user was trying to login to a device with password: status="${status}", result=${loggedIn}, ("${
              this.device.displayName
            }", PID=${UtilityService.toHex(this.device.pid)})`
          );
          this.showGeneralErrorMessage = true;
        }
      });
  }

  /**
   * Reset input fields.
   */
  private resetInputs() {
    this.passwordIncorrect = false;
    this.showGeneralErrorMessage = false;
    this.devicePassword = "";
  }
}
