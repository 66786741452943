import {
  animate,
  animateChild,
  group,
  query,
  style,
  transition,
  trigger,
} from "@angular/animations";

const resetRoute = [
  style({ position: "relative" }),
  query(":enter, :leave", [
    style({
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
    }),
  ]),
];

const options = { optional: true };

const resetSubRoute = [
  style({ position: "relative" }),
  query(
    ":enter, :leave",
    [
      style({
        position: "absolute",
        left: 0,
        width: "100%",
      }),
    ],
    options
  ),
];

export const routeAnimation = trigger("routeAnimations", [
  transition("HomePage => OtherPage", [
    ...resetRoute,
    query(":enter", [style({ left: "100%" })]),
    // query(":leave", animateChild()),
    group([
      query(":leave", [animate("300ms ease-out", style({ left: "-100%" }))]),
      query(":enter", [animate("300ms ease-out", style({ left: "0%" }))]),
    ]),
  ]),
  transition("OtherPage => HomePage", [
    ...resetRoute,
    query(":enter", [style({ left: "-100%" })]),
    group([
      query(":leave", [animate("300ms ease-out", style({ left: "100%" }))]),
      query(":enter", [animate("300ms ease-out", style({ left: "0%" }))]),
    ]),
  ]),
]);

/**
 * Animations for "tabbed" routes, eg. the tabs in "Best Practices"
 *
 * Uses values passed into the animation to determine direction.
 * @help https://medium.com/frontend-coach/angular-router-animations-what-they-dont-tell-you-3d2737a7f20b
 */
export const subRouteAnimation = trigger("subRouteAnimations", [
  transition("* <=> *", [
    ...resetSubRoute,
    query(":enter", [style({ left: "{{offsetEnter}}%" })], options),
    query(":leave", animateChild(), options),
    group([
      query(
        ":leave",
        [animate("300ms ease-out", style({ left: "{{offsetLeave}}%" }))],
        options
      ),
      query(
        ":enter",
        [animate("300ms ease-out", style({ left: "0%" }))],
        options
      ),
    ]),
    query(":enter", animateChild(), options),
  ]),
]);

export const topNavAnimation = trigger("topNavAnimation", [
  transition(":enter", [
    style({ opacity: 0 }),
    animate("500ms ease-out", style({ opacity: 1 })),
  ]),
  transition(":leave", [
    style({ opacity: 1 }),
    animate("500ms ease-out", style({ opacity: 0 })),
  ]),
]);
