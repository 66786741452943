import { BehaviorSubject, Observable } from "rxjs";
import { StorageService } from "./storage.service";
import { Injectable } from "@angular/core";
import { UtilityService } from "./utility.service";

export type Environment = {
  AUTH0_CLIENT_ID: string;
  AUTH0_AUDIENCE: string;
  AUTH0_DOMAIN: string;
  AUTH0_CALLBACK: string;
  IOT_PROVISIONING_HOST: string;
  IOT_ID_SCOPE: string;
  GRAPHQL_ENDPOINT: string;
  name: string;
};
export type Environments = {
  local: Environment;
  dev01: Environment;
  stage01: Environment;
  prod01: Environment;
};

function buildCallbackUrl() {
  const loc = window.location;
  if (loc.pathname.length > 1) {
    return loc.origin + loc.pathname;
  }
  return loc.origin;
}

function findEnvByDeployment() {
  const host = window.location.hostname;
  if (host.includes("webapp.lens") || host.includes("pwa.lens")) {
    return ENVIRONMENTS.prod01;
  } else if (host.includes("webapp.stage.lens") || host.includes("pwa.stage.lens")) {
    return ENVIRONMENTS.stage01;
  } else if (host.includes("webapp.dev.lens") || host.includes("pwa.dev.lens")) {
    return ENVIRONMENTS.dev01;
  } else if (host.includes("localhost") || host.includes("127.0.0.1")) {
    return ENVIRONMENTS.local;
  }
}

const LENS_DESKTOP_CALLBACK = "http://localhost/callback";
const LENS_DESKTOP_DPS_HOST = "global.azure-devices-provisioning.net";
const LENS_PWA_DPS_PROXY = window.location.hostname; //TODO: MUST be hostname only (not URL)
const LENS_PWA_CALLBACK = buildCallbackUrl();
export const LENS_TYPE: "desktop" | "pwa" = "pwa";

const ENVIRONMENTS: Environments = {
  local: {
    name: "local",
    AUTH0_AUDIENCE: "https://api.silica-dev01.glass.plthab.com/",
    AUTH0_CLIENT_ID: "JdGym0HFSPmC3XuSHZ6QMvLsZ6BzrWNJ",
    AUTH0_DOMAIN: "login.silica-dev01.glass.plthab.com",
    AUTH0_CALLBACK: LENS_PWA_CALLBACK, // "https://localhost:443",
    IOT_PROVISIONING_HOST: "15.52.66.81",     //OR: "pwa.dev.lens.poly.com"
    IOT_ID_SCOPE: "0ne00092668",
    GRAPHQL_ENDPOINT: "https://api.silica-dev01.glass.plthab.com/graphql",
  },
  dev01: {
    name: "dev01",
    AUTH0_AUDIENCE: "https://api.silica-dev01.glass.plthab.com/",
    AUTH0_CLIENT_ID: "JdGym0HFSPmC3XuSHZ6QMvLsZ6BzrWNJ",
    AUTH0_DOMAIN: "login.silica-dev01.glass.plthab.com",
    AUTH0_CALLBACK: LENS_TYPE === "pwa" ? LENS_PWA_CALLBACK : LENS_DESKTOP_CALLBACK,
    IOT_PROVISIONING_HOST: LENS_TYPE === "pwa" ? LENS_PWA_DPS_PROXY : LENS_DESKTOP_DPS_HOST,
    IOT_ID_SCOPE: "0ne00092668",
    GRAPHQL_ENDPOINT: "https://api.silica-dev01.glass.plthab.com/graphql",
  },
  stage01: {
    name: "stage01",
    AUTH0_AUDIENCE: "https://api.silica-stage01.io.lens.poly.com/",
    AUTH0_CLIENT_ID: "3qF9SwOqQcEeYrDFUsYUpVqPkQzZFCTW",
    AUTH0_DOMAIN: "login.silica-stage01.io.lens.poly.com",
    AUTH0_CALLBACK: LENS_TYPE === "pwa" ? LENS_PWA_CALLBACK : LENS_DESKTOP_CALLBACK,
    IOT_PROVISIONING_HOST: LENS_TYPE === "pwa" ? LENS_PWA_DPS_PROXY : LENS_DESKTOP_DPS_HOST,
    IOT_ID_SCOPE: "0ne0009E3CF",
    GRAPHQL_ENDPOINT: "https://api.silica-stage01.io.lens.poly.com/graphql",
  },
  prod01: {
    name: "prod01",
    AUTH0_AUDIENCE: "https://api.silica-prod01.io.lens.poly.com/",
    AUTH0_CLIENT_ID: "rM6M5UuHCTpbzU3QVR6EYieGIC4Rsd2x",
    AUTH0_DOMAIN: "login.lens.poly.com",
    AUTH0_CALLBACK: LENS_TYPE === "pwa" ? LENS_PWA_CALLBACK : LENS_DESKTOP_CALLBACK,
    IOT_PROVISIONING_HOST: LENS_TYPE === "pwa" ? LENS_PWA_DPS_PROXY : LENS_DESKTOP_DPS_HOST,
    IOT_ID_SCOPE: "0ne000B2A1D",
    GRAPHQL_ENDPOINT: "https://api.silica-prod01.io.lens.poly.com/graphql",
  },
};

const ENV_KEY = "CONFIG_ENV";

@Injectable({
  providedIn: "root",
})
export class ConfigService {
  public currentEnv: Environment;
  // default initialization of a light theme
  // private osDarkTheme$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  private _config$: BehaviorSubject<Environment>;

  constructor(private storage: StorageService) {
    this.currentEnv = storage.getItem(ENV_KEY);
    const deployedEnv = findEnvByDeployment();

    if (!this.currentEnv) {
      this.setEnvironment(deployedEnv || ENVIRONMENTS.local);
    } else {
      this.ensureEnvironment(this.currentEnv, deployedEnv?.name || "local");
    }
  }

  setEnvironmentByName(env: keyof Environments) {
    this.setEnvironment({ ...ENVIRONMENTS[env] });
  }

  setEnvironment(env: Environment) {
    this.currentEnv = env;
    this.storage.setItem(ENV_KEY, this.currentEnv);
    this._config$?.next({ ...this.currentEnv });
  }

  getEnvironment(env: string) {
    return { ...ENVIRONMENTS[env] };
  }

  get env$(): Observable<Environment> {
    if (!this._config$) {
      this._config$ = new BehaviorSubject<Environment>(this.currentEnv);
    }

    return this._config$.asObservable();
  }

  ensureEnvironment(env: Environment, name: string) {
    const fullEnv = Object.assign({ ...ENVIRONMENTS[name] }, env);
    this.setEnvironment(fullEnv);
  }

  /**
   * The "devmode" flag may not be set when tests are running.
   */
  get inDevMode() {
    return this.currentEnv.name === ENVIRONMENTS.dev01.name ||
            this.currentEnv.name === ENVIRONMENTS.local.name;
  }

  /**
   * Wrapper for UtilityService.inTestMode.
   */
  get inTestMode() {
    return UtilityService.isInTestRunner();
  }
}
