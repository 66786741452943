// import { Notification, BrowserWindow } from "electron";
// import * as Cron from "node-cron";
import {BehaviorSubject, Observable} from "rxjs";
import { size as _size } from "lodash";
// import {Toast, Toasts} from "../app/shared/components/toast/toasts.service";
import {Injectable} from "@angular/core";
import { CronJob as Cron } from "cron";

const path = require("path");


/**
 * Actions (buttons) supported by Mac natively.
 * @help https://www.electronjs.org/docs/api/structures/notification-action
 *
 * Note that in order for buttons to work, the notification style must be set to be "alert".
 * For builds, this is accomplished by setting NSUserNotificationAlertStyle to "alert"
 * in the Info.plist file.
 * During Development, in order to see the buttons on Mac, go to
 * "System Preferences" => "Notifications", find the app (Electron)
 * and set the notification style to "Alert"
 *
 * Additionally, the difference between "Alert" and "Banner" notifications on Mac
 * is that "Banner" appears to show at most one notification from the app and auto-dismisses,
 * while "Alert" will show multiple notifications from the app (and must be manually dismissed)
 * which is probably desired (in case there's multiple device updates available, etc)
 *
 * TODO: FINISH documenting
 * Actions (buttons) supported by Windows with the added package of electron-windows-notifications
 * @help https://github.com/felixrieseberg/electron-windows-notifications
 *
 *
 */
// interface NotificationOptions {
//   title: string;
//   body: string;
//   icon?: string;
//   actions?: Array<{ type: "button"; text?: string }>;
//   closeButtonText?: string;
// }

export interface NotificationEvent {
  action: "button" | "close";
  index: number;
  notification: {
    title: string;
    body: string;
    actions: Array<{ type: "button"; text: string }>;
  };
}

@Injectable({
  providedIn: "root",
})
export class Notifications {
  private _events$: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  private notifications: Array<Notification> = [];
  private scheduledNotificationMap = {};
  private notification: Notification;

  // This can be extended with UNPAIRED, CHARGING etc.
  private instantNotifications = ["MUTE"];
  private previousOrigin = "";

  constructor() {}

  getEvents$(): Observable<any> {
    return this._events$.asObservable();
  }

  private checkPermission(): boolean {
    if (!("Notification" in window)) {
      alert("This browser does not support desktop notification");
      return false;
    } else if (Notification.permission === "granted") {
      return true;
    } else if (Notification.permission !== "denied") {
      // We need to ask the user for permission
      Notification.requestPermission().then((permission) => {
        // If the user accepts, let's create a notification
        return permission === "granted";
      });
    }
  }

  handle(data) {
    // TODO: Check permission in constructor ???
    if (this.checkPermission()) {
      const notificationType = data.type;

      switch (notificationType) {
        case "single":
          this.singleNotification(data);
          break;
        case "cron":
          this.cronNotification(data);
          break;
        case "destroy":
          this.removeScheduledNotification(data.key);
          break;
        default:
          throw new Error("Notification type is not supported");
      }
    }
  }

  private singleNotification(data) {
    this.createNotification(data);
  }

  private cronNotification(data) {
    // if notification already exists destroy previous one
    if (this.scheduledNotificationMap[data.key]) {
      this.removeScheduledNotification(data.key);
    }

    try {
      // arrow function doesn't maintain desired scope
      const _this = this;
      // add new notification to the map
      this.scheduledNotificationMap[data.key] = {
        task: null,
        start: function () {
          this.task = new Cron(data.schedule, () => {
            _this.createNotification(data);
          });
          this.task.start();
        },
        clear: function () {
          this.task.stop();
        },
      };
    } catch (err) {
      console.log("XXX Error: " + JSON.stringify(err, null, 2));
    }
    this.scheduledNotificationMap[data.key].start();
  }

  private removeScheduledNotification(key: string) {
    if (this.scheduledNotificationMap[key]) {
      this.scheduledNotificationMap[key].clear();
      delete this.scheduledNotificationMap[key];
    }
  }

  private createNotification = (data) => {
    console.log("XXX createNotification data: [" + JSON.stringify(data, null, 2) + "]", Date.now());

    // const args = process.argv.slice(1);
    // const serve = args.some((val) => val === "--serve");
    // // NOTE: icon explicitly omitted for Mac, as it causes DUPLICATE icons in notification
    // const iconVersion =
    //   process.platform === "win32" ? "app-icon-96x96.png" : "";
    const polyIcon = "favicon.png";
    const icon = data.icon ? data.icon : polyIcon;

    let options: NotificationOptions = {
      body: data.body,
      icon: path.join("../assets/icons", icon),
      silent: true,
      // closeButtonText: "Dismiss",
    };

    if (data.actions) {
      options.actions = data.actions;
    }

    // If the new notification is declared as instant notification and the previous has the same origin/type
    // Close the previous and immediately show the new one - frequent notifications, e.g., mute on/off
    const closePrevious =
      this.instantNotifications.includes(data.origin) &&
      data.origin === this.previousOrigin;

    if (this.notification && closePrevious) {
      this.notification.close();
    }

    this.previousOrigin = data.origin;

    this.notification = new Notification(data.title, options);
    this.notifications = this.notifications.slice(-5);
    this.notifications.push(this.notification);

    // all notifications without action(s) are considered transient
    // future improvement would be configurable timeout
    // e.g. mute notifications 3 seconds, device update notifications 7 seconds
    // _size used because it handles unexpected input such as undefined and checks size of arrays
    if (!_size(options.actions) && !closePrevious) {
      setTimeout(() => {
        this.notification.close();
      }, 6 * 1000);
    }

    // click events on the body of the notification
    this.notification.addEventListener("click", () => {
      console.log("XXX Notification.createNotification 'click' event listener.");
      // TODO:
      // const win = BrowserWindow.getAllWindows();
      // win[0].show();
    });

    this.notification.addEventListener("close", (event) => {
      const { title, body, actions } = (event as any).target;

      this.sendIPCMessage({
        action: "close",
        index: 0,
        notification: {
          title,
          body,
          actions,
        },
      });
    });

    if (options.actions) {
      console.log("XXX Notification.createNotification add event listener for 'action'");
      // TODO:
      // this.notification.addEventListener("action", (event, index) => {
      //   const { title, body, actions } = (event as any).sender;
      //
      //   this.sendIPCMessage({
      //     action: "button",
      //     index,
      //     notification: {
      //       title,
      //       body,
      //       actions,
      //     },
      //   });
      // });
    }
  };

  private sendIPCMessage(body: NotificationEvent) {
    this._events$.next(body);
  }
}
