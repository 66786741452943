import { SettingsMetaData } from "../lens-settings/lens-settings-ui.model";
import { IIPCNotificationScheduledParams } from "../services/ipc.service";
import { NotificationsService } from "../services/notifications.service";
import { LensSettingsService } from "../services/lens-settings.service";
import { Injectable } from "@angular/core";
import { cloneDeep as _cloneDeep } from "lodash";
import { ReminderModalSettings } from "../health-wellness/reminders-modal/reminders-modal.component";
import { Features, StateService } from "../services/state.service";

// Schedule is created by the function
export type ICreateNotificationSchedule = Omit<
  IIPCNotificationScheduledParams,
  "schedule"
>;

export interface OptionData {
  action: string;
  schedule: {
    days: any[];
    timeFrom: any[];
    timeTo: any[];
    timespan: any[];
  };
  title: string;
  type: string;
}

export enum WellnessKeys {
  HYDRATION_ENABLED = "hydrationEnabled",
  HYDRATION_REMINDER = "hydrationReminder",
  VISION_ENABLED = "visionEnabled",
  VISION_REMINDER = "visionReminder",
  ENABLE_NOTIFICATIONS = "enableNotifications",
  WELLNESS = "wellness",
}

export enum WellnessDaysFrequency {
  WEEKDAYS,
  EVERY_DAY,
  CUSTOM,
}

export const hydrationNotification: Omit<
  ICreateNotificationSchedule["data"],
  "schedule"
> = {
  title: "HEALTH_AND_WELLNESS.HYDRATION.NOTIFICATION.TITLE",
  body: "HEALTH_AND_WELLNESS.HYDRATION.NOTIFICATION.BODY",
  type: "cron",
  key: "hydrationReminder",
  origin: "HYDRATION",
};

export const visionNotification: Omit<
  ICreateNotificationSchedule["data"],
  "schedule"
> = {
  title: "HEALTH_AND_WELLNESS.VISION.NOTIFICATION.TITLE",
  body: "HEALTH_AND_WELLNESS.VISION.NOTIFICATION.BODY",
  type: "cron",
  key: "visionReminder",
  origin: "VISION",
};

@Injectable({
  providedIn: "root",
})
export class HydrationAndVisionUtils {
  private dayOptions = [
    {
      value: "1",
      text: "SCHEDULE_OPTIONS.Monday",
      shortText: "SCHEDULE_OPTIONS.MON",
    },
    {
      value: "2",
      text: "SCHEDULE_OPTIONS.Tuesday",
      shortText: "SCHEDULE_OPTIONS.TUE",
    },
    {
      value: "3",
      text: "SCHEDULE_OPTIONS.Wednesday",
      shortText: "SCHEDULE_OPTIONS.WED",
    },
    {
      value: "4",
      text: "SCHEDULE_OPTIONS.Thursday",
      shortText: "SCHEDULE_OPTIONS.THU",
    },
    {
      value: "5",
      text: "SCHEDULE_OPTIONS.Friday",
      shortText: "SCHEDULE_OPTIONS.FRI",
    },
    {
      value: "6",
      text: "SCHEDULE_OPTIONS.Saturday",
      shortText: "SCHEDULE_OPTIONS.SAT",
    },
    {
      value: "7",
      text: "SCHEDULE_OPTIONS.Sunday",
      shortText: "SCHEDULE_OPTIONS.SUN",
    },
  ];

  private timeOptions = [
    {
      value: "0",
      // works because it is ngx-translate and does not have to be a valid JSON accessor
      text: "SCHEDULE_OPTIONS.12:00 AM",
      shortText: "SCHEDULE_OPTIONS.SHORT_LABELS_LOWER.12am",
      time: "00:00",
    },
    {
      value: "1",
      text: "SCHEDULE_OPTIONS.1:00 AM",
      shortText: "SCHEDULE_OPTIONS.SHORT_LABELS_LOWER.1am",
      time: "01:00",
    },
    {
      value: "2",
      text: "SCHEDULE_OPTIONS.2:00 AM",
      shortText: "SCHEDULE_OPTIONS.SHORT_LABELS_LOWER.2am",
      time: "02:00",
    },
    {
      value: "3",
      text: "SCHEDULE_OPTIONS.3:00 AM",
      shortText: "SCHEDULE_OPTIONS.SHORT_LABELS_LOWER.3am",
      time: "03:00",
    },
    {
      value: "4",
      text: "SCHEDULE_OPTIONS.4:00 AM",
      shortText: "SCHEDULE_OPTIONS.SHORT_LABELS_LOWER.4am",
      time: "04:00",
    },
    {
      value: "5",
      text: "SCHEDULE_OPTIONS.5:00 AM",
      shortText: "SCHEDULE_OPTIONS.SHORT_LABELS_LOWER.5am",
      time: "05:00",
    },
    {
      value: "6",
      text: "SCHEDULE_OPTIONS.6:00 AM",
      shortText: "SCHEDULE_OPTIONS.SHORT_LABELS_LOWER.6am",
      time: "06:00",
    },
    {
      value: "7",
      text: "SCHEDULE_OPTIONS.7:00 AM",
      shortText: "SCHEDULE_OPTIONS.SHORT_LABELS_LOWER.7am",
      time: "07:00",
    },
    {
      value: "8",
      text: "SCHEDULE_OPTIONS.8:00 AM",
      shortText: "SCHEDULE_OPTIONS.SHORT_LABELS_LOWER.8am",
      time: "08:00",
    },
    {
      value: "9",
      text: "SCHEDULE_OPTIONS.9:00 AM",
      shortText: "SCHEDULE_OPTIONS.SHORT_LABELS_LOWER.9am",
      time: "09:00",
    },
    {
      value: "10",
      text: "SCHEDULE_OPTIONS.10:00 AM",
      shortText: "SCHEDULE_OPTIONS.SHORT_LABELS_LOWER.10am",
      time: "10:00",
    },
    {
      value: "11",
      text: "SCHEDULE_OPTIONS.11:00 AM",
      shortText: "SCHEDULE_OPTIONS.SHORT_LABELS_LOWER.11am",
      time: "11:00",
    },
    {
      value: "12",
      text: "SCHEDULE_OPTIONS.12:00 PM",
      shortText: "SCHEDULE_OPTIONS.SHORT_LABELS_LOWER.12pm",
      time: "12:00",
    },
    {
      value: "13",
      text: "SCHEDULE_OPTIONS.1:00 PM",
      shortText: "SCHEDULE_OPTIONS.SHORT_LABELS_LOWER.1pm",
      time: "13:00",
    },
    {
      value: "14",
      text: "SCHEDULE_OPTIONS.2:00 PM",
      shortText: "SCHEDULE_OPTIONS.SHORT_LABELS_LOWER.2pm",
      time: "14:00",
    },
    {
      value: "15",
      text: "SCHEDULE_OPTIONS.3:00 PM",
      shortText: "SCHEDULE_OPTIONS.SHORT_LABELS_LOWER.3pm",
      time: "15:00",
    },
    {
      value: "16",
      text: "SCHEDULE_OPTIONS.4:00 PM",
      shortText: "SCHEDULE_OPTIONS.SHORT_LABELS_LOWER.4pm",
      time: "16:00",
    },
    {
      value: "17",
      text: "SCHEDULE_OPTIONS.5:00 PM",
      shortText: "SCHEDULE_OPTIONS.SHORT_LABELS_LOWER.5pm",
      time: "17:00",
    },
    {
      value: "18",
      text: "SCHEDULE_OPTIONS.6:00 PM",
      shortText: "SCHEDULE_OPTIONS.SHORT_LABELS_LOWER.6pm",
      time: "18:00",
    },
    {
      value: "19",
      text: "SCHEDULE_OPTIONS.7:00 PM",
      shortText: "SCHEDULE_OPTIONS.SHORT_LABELS_LOWER.7pm",
      time: "19:00",
    },
    {
      value: "20",
      text: "SCHEDULE_OPTIONS.8:00 PM",
      shortText: "SCHEDULE_OPTIONS.SHORT_LABELS_LOWER.8pm",
      time: "20:00",
    },
    {
      value: "21",
      text: "SCHEDULE_OPTIONS.9:00 PM",
      shortText: "SCHEDULE_OPTIONS.SHORT_LABELS_LOWER.9pm",
      time: "21:00",
    },
    {
      value: "22",
      text: "SCHEDULE_OPTIONS.10:00 PM",
      shortText: "SCHEDULE_OPTIONS.SHORT_LABELS_LOWER.10pm",
      time: "22:00",
    },
    {
      value: "23",
      text: "SCHEDULE_OPTIONS.11:00 PM",
      shortText: "SCHEDULE_OPTIONS.SHORT_LABELS_LOWER.11pm",
      time: "23:00",
    },
    {
      value: "24",
      text: "SCHEDULE_OPTIONS.12:00 AM",
      shortText: "SCHEDULE_OPTIONS.SHORT_LABELS_LOWER.12am",
      time: "00:00",
    },
  ];

  private timeSpanOptions = [
    {
      value: "30",
      text: "SCHEDULE_OPTIONS.30 minutes",
    },
    {
      value: "1",
      text: "SCHEDULE_OPTIONS.1 hour",
    },
    {
      value: "3",
      text: "SCHEDULE_OPTIONS.3 hours",
    },
  ];

  constructor(
    private notificationsService: NotificationsService,
    private stateService: StateService
  ) {}

  getDayOptions() {
    return this.dayOptions;
  }

  getTimeOptions() {
    return this.timeOptions;
  }

  getTimeSpanOptions() {
    const timeSpanOptions = [...this.timeSpanOptions];
    const features: Features = this.stateService.getState("Features", {});
    if (features.frequentReminders) {
      timeSpanOptions.unshift({
        value: "1 minute",
        text: "SCHEDULE_OPTIONS.1 minute",
      });
    }

    return timeSpanOptions;
  }

  getReminderSettings(
    which: "visionEnabled" | "hydrationEnabled",
    settings: SettingsMetaData
  ): ReminderModalSettings {
    const whichKey = "visionEnabled" === which ? "vision" : "hydration";
    let reminderSettings: ReminderModalSettings = {
      from: "",
      to: "",
      span: "",
      days: [],
    };

    const keys = {
      from: "TimeFrom",
      to: "TimeTo",
      span: "TimeSpan",
      days: "Days",
    };

    Object.keys(keys).forEach((key) => {
      reminderSettings[key] = settings[whichKey + keys[key]];
    });

    return _cloneDeep(reminderSettings);
  }

  handleNotificationToggle(
    action: "vision" | "hydration",
    settings: SettingsMetaData,
    enabled: boolean
  ) {
    if (enabled === true) {
      const notification =
        "vision" === action ? visionNotification : hydrationNotification;

      this.createScheduledNotification(notification, action, settings);
    } else {
      const key =
        "vision" === action
          ? WellnessKeys.VISION_REMINDER
          : WellnessKeys.HYDRATION_REMINDER;
      this.destroyNotification(key);
    }
  }

  createScheduledNotification(
    {
      title,
      body,
      type,
      key,
      origin,
    }: Omit<ICreateNotificationSchedule["data"], "schedule">,
    action: "hydration" | "vision",
    settings: SettingsMetaData
  ) {
    this.notificationsService.scheduleNotification({
      title,
      body,
      schedule: this.createCronExpression(settings, action),
      type,
      key,
      origin,
    });
  }

  destroyNotification(
    key: WellnessKeys.VISION_REMINDER | WellnessKeys.HYDRATION_REMINDER
  ) {
    this.notificationsService.destroyScheduledNotification(key);
  }

  createCronExpression(settings: SettingsMetaData, action: string): string {
    let cronExpression = "";

    let timeFrom = settings[action + "TimeFrom"];
    let timeTo = settings[action + "TimeTo"];

    // Range slider has 24 value as far right 12AM, this converts 24 to 0, since cron supports 0-23
    if (timeFrom === "24") timeFrom = "0";
    if (timeTo === "24") timeTo = "0";

    if (settings[action + "TimeSpan"] === "1 minute") {
      return "* * * * *";
    }

    if (settings[action + "TimeSpan"] === "30") {
      cronExpression = cronExpression.concat("*/30 ");
    } else {
      cronExpression = cronExpression.concat("0 ");
    }

    if (+timeFrom - +timeTo >= 0) {
      cronExpression = cronExpression.concat(timeFrom + "-23," + "0-" + timeTo);
    } else {
      cronExpression = cronExpression.concat(timeFrom + "-" + timeTo);
    }

    if (settings[action + "TimeSpan"] === "3") {
      cronExpression = cronExpression.concat(
        "/" + settings[action + "TimeSpan"]
      );
    }

    cronExpression = cronExpression.concat(" * * " + settings[action + "Days"]);
    return cronExpression;
  }

  getMultiselectDropdownText(arr: string[]): string {
    if (arr.length === 1) {
      return this.getDayOptions()[+arr[0] - 1].text;
    }

    if (arr.length === 5) {
      if (!arr.includes("6") && !arr.includes("7")) {
        return "SCHEDULE_OPTIONS.WEEKDAYS";
      }
    }

    return undefined;
  }

  initializeRemindersData(
    settings: SettingsMetaData,
    lensSettings: LensSettingsService
  ) {
    if (
      !settings["hydrationDays"] ||
      !settings["hydrationTimeFrom"] ||
      !settings["hydrationTimeTo"] ||
      !settings["hydrationTimeSpan"]
    ) {
      lensSettings.patchLensSettings({
        hydrationEnabled: false,
        hydrationDays: ["1", "2", "3", "4", "5"],
        hydrationTimeFrom: "9",
        hydrationTimeTo: "17",
        hydrationTimeSpan: "30",
      });
    }

    if (
      !settings["visionDays"] ||
      !settings["visionTimeFrom"] ||
      !settings["visionTimeTo"] ||
      !settings["visionTimeSpan"]
    ) {
      lensSettings.patchLensSettings({
        visionEnabled: false,
        visionDays: ["1", "2", "3", "4", "5"],
        visionTimeFrom: "9",
        visionTimeTo: "17",
        visionTimeSpan: "30",
      });
    }
  }

  enableOrDisableNotifications(settings: SettingsMetaData): SettingsMetaData {
    const oneOrMoreEnabled =
      settings[WellnessKeys.HYDRATION_ENABLED] ||
      settings[WellnessKeys.VISION_ENABLED];

    if (oneOrMoreEnabled) {
      settings[WellnessKeys.WELLNESS] = true;
      settings[WellnessKeys.ENABLE_NOTIFICATIONS] = true;
    }

    if (!oneOrMoreEnabled) {
      settings[WellnessKeys.WELLNESS] = false;
    }

    return settings;
  }

  convertDaysToFrequency(days: string[]) {
    if (
      days.includes("1") &&
      days.includes("2") &&
      days.includes("3") &&
      days.includes("4") &&
      days.includes("5") &&
      !days.includes("6") &&
      !days.includes("7")
    ) {
      return WellnessDaysFrequency.WEEKDAYS;
    } else if (
      days.includes("1") &&
      days.includes("2") &&
      days.includes("3") &&
      days.includes("4") &&
      days.includes("5") &&
      days.includes("6") &&
      days.includes("7")
    ) {
      return WellnessDaysFrequency.EVERY_DAY;
    } else {
      return WellnessDaysFrequency.CUSTOM;
    }
  }

  convertFrequencyToDays(freq: WellnessDaysFrequency) {
    if (freq === WellnessDaysFrequency.WEEKDAYS) {
      return ["1", "2", "3", "4", "5"];
    } else if (freq === WellnessDaysFrequency.EVERY_DAY) {
      return ["1", "2", "3", "4", "5", "6", "7"];
    }
  }

  convertValueToTime(value: string) {
    const timeOption = this.getTimeOptions().find(
      (option) => option.value === value
    );

    return timeOption.time;
  }

  convertTimeToValue(time: string) {
    const timeOption = this.getTimeOptions().find(
      (option) => option.time === time
    );
    return timeOption.value;
  }
}
