import { Component, EventEmitter, Input, Output } from "@angular/core";
import { DfuModalParams } from "../services/dfu-modal.service";

@Component({
  templateUrl: "dfu-modal.component.pug",
})
export class DfuModalComponent {
  showDfuModal = true;
  @Input() params: DfuModalParams;
  @Output() close = new EventEmitter<void>();
}
