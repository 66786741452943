import {
  Input,
  Output,
  EventEmitter,
  Component,
  OnChanges,
} from "@angular/core";
import { DfuType } from "../../services/dfu-modal.service";
import { AnimationOptions } from "ngx-lottie";
import { AnimationItem } from "lottie-web";
import { DFUStatus } from "@poly/hub-native";

@Component({
  selector: "oz-dfu-progress",
  templateUrl: "./dfu-progress.component.pug",
})
export class DfuProgressComponent implements OnChanges {
  @Input() status: DFUStatus;
  @Input() dfuType: DfuType;
  @Input() percentage = 0;
  @Input() phase;

  @Output() done = new EventEmitter<void>();

  rocketAnimationItem: AnimationItem;
  previousPoint: number = 0;

  ngOnChanges() {
    if (this.phase === "progress") {
      let currentPoint = this.percentage * 5;
      if (currentPoint === 0) {
        if (this.phase === "retry") {
          this.previousPoint = 0;
          this.rocketAnimationItem.playSegments([this.previousPoint, 1], true);
        }
      } else {
        this.rocketAnimationItem.playSegments(
          [this.previousPoint, currentPoint],
          true
        );
        this.previousPoint = currentPoint;
      }
    }
  }

  rocketAnimationOptions: AnimationOptions = {
    path: "assets/lottie/dfu_rocket_animation.json",
    autoplay: true,
    loop: false,
  };

  controlPanelAnimationOptions: AnimationOptions = {
    path: "assets/lottie/dfu_control_panel_animation.json",
  };

  rocketAnimationCreated(animationItem: AnimationItem): void {
    // very laggy Windows performance has been observed due to lottie; this improves the condition by not
    // rendering more subframes than necessary based on JSON After Effects frames per second
    animationItem.setSubframe(false);
    this.rocketAnimationItem = animationItem;
  }

  controlPanelAnimationCreated(animationItem: AnimationItem): void {
    // very laggy Windows performance has been observed due to lottie; this improves the condition by not
    // rendering more subframes than necessary based on JSON After Effects frames per second
    animationItem.setSubframe(false);
  }

  rocketAnimationDomLoaded(): void {
    this.rocketAnimationItem.totalFrames = 1;
    this.rocketAnimationItem.setSpeed(0.3);
  }

  getTitle() {
    switch (this.dfuType) {
      case "Local":
        return "DFU.CUSTOM_SOFTWARE_UPDATE";
      case "LanguageChange":
        return "DFU.LANGUAGE_UPDATE";
      case "Recovery":
        return "DFU.DEVICE_RECOVERY";
      default:
        return "DFU.SOFTWARE_UPDATE";
    }
  }

  getDescription() {
    switch (this.dfuType) {
      case "Local":
        return "DFU.UPLOADING_CUSTOM_SOFTWARE_UPDATE";
      case "LanguageChange":
        return "DFU.UPDATEING_LANGUAGE";
      case "Recovery":
        return "DFU.RECOVERING_DEVICE";
      default:
        return "DFU.UPDATING_SOFTWARE";
    }
  }

  getCompletedMessage() {
    if (this.status === "UpToDate") {
      return "DFU.SOFTWARE_IS_UP_TO_DATE";
    } else {
      switch (this.dfuType) {
        case "Local":
          return "DFU.CUSTOM_SOFTWARE_INSTALLED";
        case "LanguageChange":
          return "DFU.LANGUAGE_UPDATED";
        case "Recovery":
          return "DFU.DEVICE_RECOVERED";
        default:
          return "DFU.SOFTWARE_INSTALLED";
      }
    }
  }
}
