import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { SettingsMetaData } from "../../lens-settings-ui.model";
import { LensSettingsService } from "../../../services/lens-settings.service";
import {
  HydrationAndVisionUtils,
  OptionData,
  WellnessDaysFrequency,
  WellnessKeys,
} from "../../../utils/hydrationAndVisionUtils";
import { ReminderModalSettings } from "../../../health-wellness/reminders-modal/reminders-modal.component";
import { Subscriptions } from "../../../utils/subscriptions";
import { AccordionItem } from "../../../shared/components/accordion/accordion.component";

export interface LensSettingNotificationsMetadata {
  action: string;
  type: string;
  title: string;
  description?: string;
  options?: [];
  visibleInPwa: boolean;
}

type ReminderSettings =
  | Pick<
      SettingsMetaData,
      | "hydrationDays"
      | "hydrationTimeTo"
      | "hydrationTimeFrom"
      | "hydrationTimeSpan"
    >
  | Pick<
      SettingsMetaData,
      "visionDays" | "visionTimeTo" | "visionTimeFrom" | "visionTimeSpan"
    >;

@Component({
  selector: "oz-lens-settings-notifications",
  templateUrl: "./lens-settings-notifications.component.pug",
})
export class LensSettingsNotificationsComponent implements OnInit, OnDestroy {
  // private settingsSubscription: Subscription;
  private subs = new Subscriptions();

  @Input() set accordionData(value: AccordionItem) {
    this.setting = value.data;
  }

  setting: LensSettingNotificationsMetadata;

  allLensSettings: SettingsMetaData;

  public reminderModal: {
    open: boolean;
    type: "hydrationEnabled" | "visionEnabled" | "";
    option: OptionData;
    settings: ReminderModalSettings;
  } = {
    open: false,
    type: "",
    option: null,
    settings: {
      from: "",
      to: "",
      span: "",
      days: [],
    },
  };

  wellnessDaysFrequency = WellnessDaysFrequency;

  daysFrequency: { hydrationEnabled: number; visionEnabled: number } = {
    hydrationEnabled: WellnessDaysFrequency.WEEKDAYS,
    visionEnabled: WellnessDaysFrequency.WEEKDAYS,
  };

  frequencyLabels: { hydrationEnabled: string; visionEnabled: string } = {
    hydrationEnabled: "",
    visionEnabled: "",
  };

  constructor(
    private lensSettingsService: LensSettingsService,
    public hydrationAndVisionUtils: HydrationAndVisionUtils
  ) {}

  ngOnInit(): void {
    this.subs.add(
      this.lensSettingsService.lensSettings.subscribe((settings) => {
        this.allLensSettings = settings;
      })
    );

    this.hydrationAndVisionUtils.initializeRemindersData(
      this.allLensSettings,
      this.lensSettingsService
    );
  }

  showReminderModal(option) {
    this.reminderModal = {
      open: true,
      type: option.action,
      option,
      settings: this.hydrationAndVisionUtils.getReminderSettings(
        option.action,
        this.allLensSettings
      ),
    };
  }

  confirmReminderModal(settings: ReminderModalSettings) {
    let reminderSettings: ReminderSettings;

    if ("hydrationEnabled" === this.reminderModal.type) {
      reminderSettings = {
        hydrationTimeFrom: settings.from,
        hydrationTimeTo: settings.to,
        hydrationTimeSpan: settings.span,
        hydrationDays: settings.days,
      };
    }

    if ("visionEnabled" === this.reminderModal.type) {
      reminderSettings = {
        visionTimeFrom: settings.from,
        visionTimeTo: settings.to,
        visionTimeSpan: settings.span,
        visionDays: settings.days,
      };
    }

    this.lensSettingsService.patchLensSettings(reminderSettings);
    this.checkWellnessNotification(this.reminderModal.type as WellnessKeys);
    this.closeReminderModal();
  }

  closeReminderModal() {
    this.reminderModal = {
      open: false,
      type: "",
      option: null,
      settings: {
        from: "",
        to: "",
        span: "",
        days: [],
      },
    };
  }

  onValueChanged(ev: any | any[], action: WellnessKeys | string) {
    this.allLensSettings[action] = ev;
    this.lensSettingsService.setLensSettings(this.allLensSettings);
    this.checkWellnessNotification(action as WellnessKeys);
  }

  /**
   * fires when:
   * 1. Allow desktop notifications turned on / off - "enableNotifications"
   * 2. Health and Wellness notifications turned on / off - "wellness"
   * 3. Modal is closed to set schedule ('hydrationEnabled' | 'visionEnabled')
   *
   * What it does:
   * IF the action is hydration, create or turn off the notification.
   * IF the action is ALL or H&W on / off, create or turn off the notification.
   */
  checkWellnessNotification(action: WellnessKeys) {
    const globalNotificationsEnabled = this.allLensSettings[
      WellnessKeys.ENABLE_NOTIFICATIONS
    ];
    const wellnessNotificationsEnabled = this.allLensSettings[
      WellnessKeys.WELLNESS
    ];

    // if global notifications or wellness notifications are disabled, then hydration / vision are also
    if (!globalNotificationsEnabled || !wellnessNotificationsEnabled) {
      this.lensSettingsService.patchLensSettings({
        hydrationEnabled: false,
        visionEnabled: false,
        wellness: false,
      });
    }

    // vision cannot be enabled if wellness or global notifications are not enabled
    const visionEnabled =
      this.allLensSettings[WellnessKeys.VISION_ENABLED] &&
      globalNotificationsEnabled &&
      wellnessNotificationsEnabled;
    // hydration cannot be enabled if wellness or global notifications are not enabled
    const hydrationEnabled =
      this.allLensSettings[WellnessKeys.HYDRATION_ENABLED] &&
      globalNotificationsEnabled &&
      wellnessNotificationsEnabled;

    // limit notification changes to only relevant settings changing
    if (
      [
        WellnessKeys.HYDRATION_ENABLED,
        WellnessKeys.HYDRATION_REMINDER,
        WellnessKeys.WELLNESS,
        WellnessKeys.ENABLE_NOTIFICATIONS,
      ].includes(action)
    ) {
      this.hydrationAndVisionUtils.handleNotificationToggle(
        "hydration",
        this.allLensSettings,
        hydrationEnabled
      );
    }

    // limit notification changes to only relevant settings changing
    if (
      [
        WellnessKeys.VISION_ENABLED,
        WellnessKeys.VISION_REMINDER,
        WellnessKeys.WELLNESS,
        WellnessKeys.ENABLE_NOTIFICATIONS,
      ].includes(action)
    ) {
      this.hydrationAndVisionUtils.handleNotificationToggle(
        "vision",
        this.allLensSettings,
        visionEnabled
      );
    }
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }

  public visibleInPwa(): boolean {
    return this.setting?.visibleInPwa;
  }
}
