import { Observable } from "rxjs";

/**
 * Provides interface for device list and device details.
 */
export abstract class IDeviceManager {
  /**
   * Retrieves the list of devices.
   */
  abstract getDevices(): Observable<Device[]>;

  /**
   * Retrieves a device with a specific device id.
   *
   * @param deviceId The target device.
   */
  abstract getDevice(deviceId: string): Observable<Device>;

  /**
   * Receives a primary device.
   */
  abstract getPrimaryDevice(): Observable<Device>;

  /**
   * Reqeusts a change of a primary device.
   *
   * @param deviceId Target device.
   */
  abstract setPrimaryDevice(deviceId: string): void;

  /**
   * Reqeusts a mute of a primary device.
   */
  abstract mutePrimaryDevice(mute: boolean): void;

  /**
   * Set Agent Audio Control setting (only for MDA5xx).
   */
  abstract setAacSetting(aac: AgentAudioControl): void;

  abstract getBrickedDevice(): Observable<BrickedDevice>;

  /**
   * Set a new device name for a device with a specific device id.
   *
   * @param deviceId The target device.
   * @param deviceName New name for specified device.
   */
  abstract setDeviceName?(
    deviceId: string,
    deviceName: string
  ): Promise<DeviceNameResponse>;

  /**
   * Receives a non Poly device device added.
   */
  abstract nonPolyDeviceAdded(): Observable<NonPolyDevice>;

  /**
   * Receives a non Poly device device removed.
   */
  abstract nonPolyDeviceRemoved(): Observable<NonPolyDevice>;

  /**
   * Saves video device logs.
   *
   * @param deviceId The target device.
   * @param filePath file system path to gz file.
   */
  abstract saveDeviceLogs(
    deviceId: string,
    filePath: string|FileSystemWritableFileStream,
  ): Promise<SaveDeviceLogsStatus>;

  /**
   * Request devices known networks.
   *
   * @param deviceId The target device.
   */
  abstract getKnownNetworks(deviceId: string): Promise<KnownNetworksResponse>;

  /**
   * Request devices scanned networks.
   *
   * @param deviceId The target device.
   */
  abstract getScannedNetworks(
    deviceId: string
  ): Promise<ScannedNetworksResponse>;

  /**
   * Request devices WiFi status networks.
   *
   * @param deviceId The target device.
   */
  abstract getWiFiStatus(deviceId: string): Observable<WiFiStatusResponse>;

  /**
   * Set devices WiFi enabled or disabled.
   *
   * @param request WiFiConnectParams structure.
   */
  abstract setWiFiParameters(request: WiFiConnectParams): void;

  abstract getNetworkProvisioning(
    deviceId: string
  ): Promise<NetworkProvisioningInfo>;

  abstract setNetworkProvisioning(
    request: NetworkProvisioningParams
  ): Promise<NetworkProvisioningInfo>;

  abstract getBluetoothStatus(deviceId: string): Promise<BluetoothInfo>;

  abstract setBluetoothSettings(
    request: BluetoothParams
  ): Promise<SetBluetoothResponse>;

  abstract setBluetoothPairing(
    request: BluetoothPairingRequest
  ): Promise<SetBluetoothResponse>;

  abstract getGeneralSettings(
    deviceId: string
  ): Promise<GetGeneralSettingsResponse>;

  abstract setGeneralSettings(
    request: GeneralSettings
  ): Promise<SetGeneralSettingsResponse>;

  abstract adminLogin(request: AdminLoginRequest): Promise<AdminLoginResponse>;

  abstract adminPasswordChange(
    request: AdminPasswordChangeRequest
  ): Promise<AdminPasswordChangeResponse>;

  abstract adminPasswordEnabled(
    deviceId: string
  ): Promise<AdminPasswordEnabledResponse>;

  abstract simplePassword(deviceId: string): Promise<SimplePasswordResponse>;
  abstract setSimplePassword(
    request: SetSimplePasswordRequest
  ): Promise<SetSimplePasswordResponse>;

  abstract getCertificateInfo(
    deviceId: string
  ): Promise<GetCertificateInfoResponse>;

  abstract createCertificate(
    request: CreateCertificateRequest
  ): Promise<CreateCertificateResponse>;

  abstract getCertificateFile(
    deviceId: string
  ): Promise<GetCertificateFileResponse>;

  abstract installCertificate(
    request: InstallCertificateRequest
  ): Promise<InstallCertificateResponse>;

  abstract getInstalledCertificates(
    deviceId: string
  ): Promise<GetInstalledCertificatesResponse>;

  abstract deleteCertificate(
    request: DeleteCertificateRequest
  ): Promise<DeleteCertificateResponse>;

  abstract certificateDetails(
    request: CertificateDetailsRequest
  ): Promise<CertificateDetailsResponse>;

  abstract importConfiguration(
    request: ImportConfigurationRequest
  ): Promise<ImportConfigurationResponse>;

  abstract exportConfiguration(
    deviceId: string
  ): Promise<ExportConfigurationResponse>;

  abstract startAudioTest(deviceId: string): Promise<StartAudioTestResponse>;

  abstract stopAudioTest(
    request: StopAudioTestRequest
  ): Promise<StopAudioTestResponse>;

  abstract setServerCAValidation(request: SetServerCAValidationRequest)
    : Promise<SetServerCAValidationResponse>;

  abstract needOOB(deviceId: string): Promise<NeedOOBResponse>;

  abstract setWifiClientCertPassword(
    request: SetWifiClientCertPasswordRequest
  ): Promise<SetWifiClientCertPasswordResponse>;

  abstract getCrashFiles(
    request: GetCrashFilesRequest
  ): Promise<GetCrashFilesResponse>;

  abstract isAppInstalled(appName: string): Promise<IsAppInstalledResponse>;

  abstract requestDevice(): void;

  abstract removeDevice(uniqueId: string): void;
}

export interface HeadsetInfo {
  headsetPID: number;
  flags: string[];
}

export interface PeripheralInfo {
  isPeripheral: boolean;
  hostDevicePID: number;
  hostDeviceVersion: number;
  hostDeviceId: string;
  hostDeviceName: string;
}

export interface ManufacturerInfo {
  mftInfo: string;
  hardwareVersion: string;
  additionalHardwareVersion: string;
}

export interface Device {
  /**
   * Device ID. This ID is used for communication with native layer.
   */
  id: string;

  /**
   * Device ID of the parent device.
   *
   * undefined if parent does not exist.
   */
  parentDeviceId: string;

  /**
   * Unique ID. This uniquely identifies a device.
   */
  uniqueId: string;

  /**
   * Product ID.
   */
  pid: number;

  /**
   * Display name of the device.
   */
  displayName: string;

  /**
   * Custom name of the device.
   */
  deviceName?: string;

  /**
   * true if deviceName is customizable.
   */
  isDeviceNameCustomizable?: boolean;

  /**
   * Firmware version of the device.
   */
  firmwareVersion: FirmwareVersion;

  /**
   * Product serial number of the device.
   */
  productSerialNumber: SerialNumber;

  /**
   * Product build code of the device.
   */
  productBuildCode: BuildCode;

  /**
   * Serial number of a device.
   */
  serialNumber: SerialNumber;

  /**
   * True if device is primary.
   */
  isPrimary: boolean;

  /**
   * True if device can be primary.
   */
  canBePrimary: boolean;

  /**
   * True if device is muted.
   *
   * Currently, this value has effect only in case this device is primary.
   */
  isMuted: boolean;

  /**
   * Record voice notes setting availability for MDA
   */
  recordVoiceNotes?: string;

  /**
   * Play PC audio setting availability for MDA
   */
  playPCAudio?: string;

  /**
   * True if device is connected.
   */
  isConnected: boolean;

  /**
   * Language ID that the device is currently using.
   */
  languageId: string;

  /**
   * List of supported features based on underlying platform.
   *
   * NOTE: This list may differ for the same device on different platforms.
   */
  featureList: string[];

  /**
   * Current battery state.
   */
  battery: Battery;

  /**
   * Video device status.
   */
  videoDeviceStatus?: VideoDeviceStatus;

  /**
   * Vendor ID.
   */
  vid: number;

  /**
   * Manufacturer Name.
   */
  manufacturerName: string;

  /**
   * Model ID.
   */
  modelId: string;

  /**
   * Headset Model ID.
   */
  headsetModelId?: string;

  /**
   * Headset Type.
   */
  headsetType?: string;

  /**
   * True if device is Teams device.
   */
  isTeamsSKU?: boolean;

  /**
   * True if device is USB type C.
   */
  isUSBTypeC?: boolean;

  /**
   * True if device is a video device.
   */
  isVideoDevice: boolean;

  /**
   * True if camera controls are available.
   */
  cameraControlsAvailable: boolean;

  /**
   * Peripheral device info
   */

  peripheralInfo?: PeripheralInfo;

  /**
   *
   **/
  manufacturerInfo?: ManufacturerInfo;

  /**
   * True if device is connected (Only for DA devices).
   */
  headsetConnectedState?: boolean;

  /**
   * Additional headset info for DA devices.
   */
  additionalHeadsetInfo?: HeadsetInfo;

  /**
   * Device type.
   */
  deviceType?: string;

  /**
   * Additional battery info.
   */
  additionalBatteryInfo?: AdditionalBatteryInfo;

  /**
   *
   */
  connectedDevices?: string[];

  /**
   * Additional earbuds info.
   */
  leftEarbudStatus?: EarbudStatus;
  rightEarbudStatus?: EarbudStatus;
  peerEarbudInfo?: PeerInfo;
  peerChargeCase?: PeerInfo;

  /**
   * Voice detected during call (and device is muted)
   */
  voiceDetected?: boolean;
}

export interface EarbudStatus {
  connected: boolean;
  primary: boolean;
  peerConnected: boolean;
}

export interface PeerInfo {
  TattooSN: string;
  FirmwareVersion: string;
  TattooBC: string;
  UsbPid: number;
  GenesGuid: string;
  setId: SetId;
}

export interface VideoDeviceStatus {
  id: string;
  countryRegion: string;
  deviceName: string;
  deviceTime: string;
  mainIpAddress: string;
  mainMacAddress: string;
  diagnosticCode: string;
  model: string;
  networkMode: string;
  networkStatus: boolean;
  powerSupply: string;
  provisionStatus: string;
  serialNumber: string;
  usbMode: string;
  versionHW: string;
  versionSW: string;
  wifiSignalStrength: number;
  provisionServerInfo: ProvisionServerInfo;
  remoteName: string;
  remoteBatteryStatus: string;
  remoteVersionFW: string;
  remoteActive: boolean;
  remoteUpgradeEnable: boolean;
  remoteUpgrading: boolean;
}

export interface ProvisionServerInfo {
  provisionInterval: string;
  provisionMode: string;
  provisionServerUrl: string;
}

export interface Battery {
  /**
   * Current battery level.
   */
  level: number;

  /**
   * If true, device is charging.
   */
  charging: boolean;
  /**
   * Current charging level.
   */
  chargeLevel?: number;

  /**
   * If true, charge level is valid.
   */
  isChargeLevelValid?: boolean;

  /**
   *
   */
  docked: boolean;

  /**
   * Number of battery levels. For example, if levels are 0, 1, 2, 3...10 than number of levels is 11
   */
  numLevels: number;
}

export interface AdditionalBatteryInfo {
  chargingCase: number;
  chargingCaseNumLevels: number;
  chargingCaseIncrementalLevel: number;
  chargingCaseCharging: boolean;
  leftEarbud: number;
  leftEarbudDocked: boolean;
  leftEarbudNumLevels: number;
  leftEarbudIncrementalLevel: number;
  leftEarbudCharging: boolean;
  rightEarbud: number;
  rightEarbudDocked: boolean;
  rightEarbudNumLevels: number;
  rightEarbudIncrementalLevel: number;
  rightEarbudCharging: boolean;
}

export interface SerialNumber {
  base: string;
  headset: string;
}

export interface BuildCode {
  base: string;
  headset: string;
}

export interface SetId {
  major: string;
  minor: string;
  revision: string;
  build: string;
}

export interface FirmwareVersion {
  base: string;
  headset: string;
  bluetooth: string;
  pic: string;
  tuning: string;
  usb: string;
  setId: SetId;
}

export interface BrickedStatus {
  /**
   * The target device.
   */
  pid: number;

  /**
   * Indicates if the device is bricked.
   */
  bricked: boolean;
}

export interface BrickedDevice {
  pid: number;
  recoveryFilePresent: boolean;
  recoveryArchivePath: string;
}

export interface NonPolyDevice {
  productName: string;
  pid: string;
  vid: string;
}

export declare type RESTDeviceError =
  | "OK" // Operation successful
  | "NoDevice" // No device with provided deviceId
  | "DeviceCommunicationError" // Device comunication error
  | "WriteFileFailed" // Writing to device failed
  | "Unknown"; // Internal, unknown, error occurred

export interface SaveDeviceLogsStatus {
  deviceId: string;
  status: RESTDeviceError;
  logFilePath: string;
}

export interface KnownNetworksResponse {
  deviceId: string;
  knownNetworks: string[];
  status: RESTDeviceError;
}

export interface ScannedNetwork {
  bssid: string;
  encryptionType: string;
  lastUpdate: number;
  networkId: number;
  password: string;
  signalStrength: number;
  ssid: string;
}

export interface ScannedNetworksResponse {
  deviceId: string;
  scannedNetworks: ScannedNetwork[];
  status: RESTDeviceError;
}

export interface WifiSsid {}

export interface WiFiConnectionInfo {
  badRssiCount: number;
  linkStuckCount: number;
  lowRssiCount: number;
  mBSSID: string;
  mEphemeral: boolean;
  mFrequency: number;
  mIpAddress: string;
  mLinkSpeed: number;
  mMacAddress: string;
  mMeteredHint: boolean;
  mNetworkId: number;
  mRssi: number;
  mSupplicantState: string;
  mWifiSsid: WifiSsid;
  rxSuccess: number;
  rxSuccessRate: number;
  score: number;
  txBad: number;
  txBadRate: number;
  txRetries: number;
  txRetriesRate: number;
  txSuccess: number;
  txSuccessRate: number;
  signalStrength: string;
}

export declare type WiFiStatus = "connected" | "disconnected";

export interface WiFiStatusResponse {
  deviceId: string;
  status: RESTDeviceError;
  wifi_connection_info: WiFiConnectionInfo;
  wifi_enable: boolean;
  wifi_auto_connect: boolean;
  main_ip_address: string;
  main_mac_address: string;
  wifi_status: WiFiStatus;
}

export declare type WiFiAction =
  | "connect"
  | "delete"
  | "disable"
  | "enable"
  | "scan";

export interface WiFiConnectParams {
  deviceId: string;
  wifi_action?: WiFiAction;
  auto_connect?: boolean;
  ssid?: string;
  securityType?: string;
  eapMethod?: string;
  phase2?: string;
  caCert?: string;
  clientCert?: string;
  identity?: string;
  anonymousIdentity?: string;
  password?: string;
  dhcp?: number;
  ip_address?: string;
  gateway?: string;
  subnet_mask?: string;
  dns1?: string;
  dns2?: string;
}

export interface NetworkProvisioningInfo {
  deviceId: string;
  status: RESTDeviceError;
  serverAddress: string;
  serverType: string;
  username: string;
  interval: number;
  provisioningMode: string;
}

export interface NetworkProvisioningParams {
  deviceId: string;
  provisioningMode?: string;
  serverType?: string;
  serverAddress?: string;
  username?: string;
  password?: string;
  interval?: number;
}

export interface DeviceNameResponse {
  deviceId: string;
  status: RESTDeviceError;
}

export interface BluetoothParams {
  deviceId: string;
  name?: string;
  enable?: boolean;
  auto_connect?: boolean;
  rcc_enable?: boolean;
}

export interface BluetoothPairingRequest {
  deviceId: string;
  le?: boolean;
}

export interface BluetoothInfo {
  deviceId: string;
  status: RESTDeviceError;
  name?: string;
  enable?: boolean;
  auto_connect?: boolean;
  rcc_enable?: boolean;
}

export interface SetBluetoothResponse {
  deviceId: string;
  status: RESTDeviceError;
}

export interface GeneralSettings {
  deviceId: string;
  device_name?: string;
  country_region?: string;
  ntp_server?: string;
  ntp_mode?: string;
  camera_vivid_mode?: boolean;
}

export interface GetGeneralSettingsResponse {
  deviceId: string;
  status: RESTDeviceError;
  device_name?: string;
  country_region?: string;
  ntp_server?: string;
  ntp_mode?: string;
  camera_vivid_mode?: boolean;
}

export interface SetGeneralSettingsResponse {
  deviceId: string;
  status: RESTDeviceError;
}

export interface AdminLoginRequest {
  deviceId: string;
  password: string;
}

export interface AdminLoginResponse {
  deviceId: string;
  status: RESTDeviceError;
  result: boolean;
}

export interface AdminPasswordChangeRequest {
  deviceId: string;
  old_password: string;
  new_password: string;
}

export interface AdminPasswordChangeResponse {
  deviceId: string;
  status: RESTDeviceError;
  result: boolean;
}

export interface AdminPasswordEnabledResponse {
  deviceId: string;
  status: RESTDeviceError;
  enable: boolean;
}

export interface SimplePasswordResponse {
  deviceId: string;
  status: RESTDeviceError;
  simple: boolean;
}

export interface SetSimplePasswordRequest {
  deviceId: string;
  simple: boolean;
}

export interface SetSimplePasswordResponse {
  deviceId: string;
  status: RESTDeviceError;
}

export interface CreateCertificateRequest {
  deviceId: string;
  country?: string;
  state?: string;
  locality?: string;
  organization?: string;
  unit?: string;
  commonName?: string;
}

export interface GetCertificateInfoResponse {
  deviceId: string;
  status: RESTDeviceError;
  country?: string;
  state?: string;
  locality?: string;
  organization?: string;
  unit?: string;
  commonName?: string;
}

export interface CreateCertificateResponse {
  deviceId: string;
  status: RESTDeviceError;
}

export interface GetCertificateFileResponse {
  deviceId: string;
  status: RESTDeviceError;
  file?: string;
}

export interface InstallCertificateRequest {
  deviceId: string;
  type: string;
  cert: ArrayBuffer;
}

export interface InstallCertificateResponse {
  deviceId: string;
  status: RESTDeviceError;
  restErrorCode: number;
}

export interface Certificate {
  issuer: string;
  issueTo: string;
  validDate: string;
  type: string;
}

export interface GetInstalledCertificatesResponse {
  deviceId: string;
  status: RESTDeviceError;
  validation?: boolean;
  certs?: Certificate[];
}

export interface DeleteCertificateRequest {
  deviceId: string;
  type: string;
}

export interface DeleteCertificateResponse {
  deviceId: string;
  status: RESTDeviceError;
}

export interface CertificateDetailsRequest {
  deviceId: string;
  type: string;
}

export interface CertificateDetailsResponse {
  deviceId: string;
  status: RESTDeviceError;
  info?: string;
}

export interface ImportConfigurationRequest {
  deviceId: string;
  config: string;
}

export interface ImportConfigurationResponse {
  deviceId: string;
  status: RESTDeviceError;
  success: boolean;
}

export interface ExportConfigurationResponse {
  deviceId: string;
  status: RESTDeviceError;
  config?: string;
}

export interface SetServerCAValidationRequest {
  deviceId: string;
  enable: boolean;
}

export interface SetServerCAValidationResponse {
  deviceId: string;
  status: RESTDeviceError;
}

export interface StartAudioTestResponse {
  deviceId: string;
  status: RESTDeviceError;
}

export interface StopAudioTestRequest {
  deviceId: string;
  fileName: string;
}

export interface StopAudioTestResponse {
  deviceId: string;
  status: RESTDeviceError;
  path?: string;
}

export interface NeedOOBResponse {
  deviceId: string;
  status: RESTDeviceError;
  result?: boolean;
}

export interface SetWifiClientCertPasswordRequest {
  deviceId: string;
  password: string;
}

export interface SetWifiClientCertPasswordResponse {
  deviceId: string;
  status: RESTDeviceError;
  success: boolean;
}

export interface GetCrashFilesRequest {
  deviceId: string;
  fileName: string;
}

export interface GetCrashFilesResponse {
  deviceId: string;
  status: RESTDeviceError;
  path?: string;
}

export interface IsAppInstalledResponse {
  appName: string;
  isInstalled: boolean;
}
export interface AgentAudioControl {
  command: string;
  value: boolean;
}
