import { ILoggingService, LOG } from "../../app/services/logging.service";
import { ReqTimeoutError } from "./errors";
import { hex, buf2hex } from "./wh-utils";


const PKT_PREFIX_LEN = 2;  // first 2 bytes (packet number and packet count)
const BIG_ENDIAN = false;

// BladeRunner message types
export const enum BR_REQ {
  HOST_PROTO_VER = 1,
  GET_SETTING = 2,
  CMD = 5,
}

export const enum BR_MT {
  SETTING_SUCCESS = 3,
  SETTING_FAILURE = 4,
  CMD_SUCCESS = 6,
  CMD_FAILURE = 7,
  DEVICE_PROTO_VER = 8,
  METADATA = 9,
  EVENT = 10,
  CLOSE_SESSION = 11,
  HOST_PROTO_VER_FAILURE = 12,
  CONNECTION_CHANGE = 13,
}

function isMatchingResp(req: BrMsgView, msg: BrMsgView): boolean {
  if (req.address() === (msg.address() << 4)) {
    if (BR_REQ.GET_SETTING === req.type()) {
      return (BR_MT.SETTING_SUCCESS === msg.type() || BR_MT.SETTING_FAILURE === msg.type()) &&
        req.deckardType() === msg.deckardType();
    }
    if (BR_REQ.CMD === req.type()) {
      return (BR_MT.CMD_SUCCESS === msg.type() || BR_MT.CMD_FAILURE === msg.type()) &&
        req.deckardType() === msg.deckardType();
    }
    if (BR_REQ.HOST_PROTO_VER === req.type()) {
      return BR_MT.METADATA === msg.type() || BR_MT.HOST_PROTO_VER_FAILURE === msg.type();
    }
  }
  return false;
}


export class BrMsgView {
  constructor(dv: DataView) {
    this.data = dv;
    const type = this.type();
    this.payloadOffset = (BR_REQ.CMD === type || BR_MT.CMD_SUCCESS === type || BR_MT.CMD_FAILURE === type ||
      BR_REQ.GET_SETTING === type || BR_MT.SETTING_SUCCESS === type || BR_MT.SETTING_FAILURE === type ||
      BR_MT.EVENT === type) ? 10 : 8;
  }

  static make(address: number, type: BR_REQ | BR_MT, payload: Array<number>, reportSize: number): BrMsgView {
    const len = 4 + payload.length;        // 4 for address and msg type
    if (reportSize < PKT_PREFIX_LEN + 2 + len) {  // 4 for packet number, packet count and msg length
      throw new Error("BR payload too long");
    }
    const m = new Uint8Array(reportSize);
    const dv = new DataView(m.buffer);
    dv.setUint8(0, 1);
    dv.setUint8(1, 1);
    dv.setUint16(2, (1 << 12) + len, BIG_ENDIAN);
    // sends need slightly modified address (drop leading zero)
    dv.setUint32(4, (((address << 4) & 0xFFFFFFF) << 4) + (type & 0xF), BIG_ENDIAN);
    m.set(payload, 8);
    return new BrMsgView(dv);
  }

  buffer() { return this.data.buffer; }
  type(): number { return this.data.getUint8(7) & 0x0F; }
  brLength(): number { return this.data.getUint16(2, BIG_ENDIAN) & 0xFFF; }
  address(): number { return (this.data.getUint32(4, BIG_ENDIAN) >> 4); }

  hasDeckardMsg(): boolean { return 10 === this.payloadOffset; }

  deckardType(): number | undefined {
    return this.hasDeckardMsg() ? this.data.getUint16(8, BIG_ENDIAN) : undefined;
  }

  payloadUint8At(byteOffset: number): number { return this.data.getUint8(this.payloadOffset + byteOffset); }
  payloadUint16At(byteOffset: number): number { return this.data.getUint16(this.payloadOffset + byteOffset, BIG_ENDIAN); }
  payloadUint32At(byteOffset: number): number { return this.data.getUint32(this.payloadOffset + byteOffset, BIG_ENDIAN); }

  payloadUint8(): number { const o = this.currOffset; this.currOffset += 1; return this.payloadUint8At(o); }
  payloadUint16(): number { const o = this.currOffset; this.currOffset += 2; return this.payloadUint16At(o); }
  payloadUint32(): number { const o = this.currOffset; this.currOffset += 4; return this.payloadUint32At(o); }
  payloadUint8Array(): Uint8Array {
    const len = this.payloadUint16();
    const o = this.currOffset;
    this.currOffset += len;
    return new Uint8Array(this.data.buffer, this.payloadOffset + o, len);
  }

  payloadAscii(): string {
    let encoding = 'UTF-8';
    let len = this.payloadUint16();
    if (0 < len && this.remainingLength() === 2 * len) {
      console.info(`unexpected ASCII length=${len}, assuming UTF-16`);
      encoding = 'UTF-16BE';
      len = 2 * len;
    }
    const o = this.currOffset;
    this.currOffset += len;
    const array = new Uint8Array(this.data.buffer, this.payloadOffset + o, len);
    const decoder = new TextDecoder(encoding);
    return decoder.decode(array).replace(/\x00*$/, '');
  }

  remainingLength() { return 4 + this.brLength() - (this.payloadOffset + this.currOffset); }

  hex(): string { return buf2hex(this.data.buffer, this.brLength() + 4); }
  toString() {
    let s = this.hasDeckardMsg() ? `/0x${this.deckardType()?.toString(16)}` : "";
    return `type=${this.type()}${s} addr=${hex(this.address())} msg=[${this.hex()}]`
  }

  private readonly data: DataView;
  private readonly payloadOffset: number;
  private currOffset: number = 0;
}


export interface BrSender {
  sendReport(reportId: number, data: BufferSource): Promise<void>;
}

export interface BrEventHandler {
  onEvent(msg: BrMsgView): void;
}


export class BrHid {
  constructor(private device: BrEventHandler, private sender: BrSender, private inBrReportId: number, private inBrReportSize: number,
    private outBrReportId: number, private outBrReportSize: number,
    private logger: ILoggingService) {

  }

  inReportId() { return this.inBrReportId }

  private sendBrReport(msg: BrMsgView): Promise<void> {
    if (LOG.DEBUG) this.logger.debug(`BR send ${msg}, qlen=${1 + this.pendingReqs.length}`);
    return this.sender.sendReport(this.outBrReportId, msg.buffer())
  }

  private delPendingReq() {
    this.brRespCb = undefined;
    this.pendingReqs.shift();
    if (LOG.DEBUG) this.logger.info(`del pending req, qlen=${this.pendingReqs.length}`)
  }

  private sendBrRequest(req: BrMsgView): Promise<BrMsgView> {
    if (this.brRespCb) {
      this.logger.error("unexpected set callback");
    }
    return new Promise<BrMsgView>((resolve, reject) => {
      const id = setTimeout(() => {
        this.delPendingReq();
        reject(new ReqTimeoutError(`BR send timeout, ${req}`));
      }, 3000);
      this.brRespCb = (msg: BrMsgView) => {
        if (isMatchingResp(req, msg)) {
          this.delPendingReq();
          clearTimeout(id);
          resolve(msg);
          return true;
        }
        return false;
      };
      this.sendBrReport(req)
        .catch(error => {
          this.delPendingReq();
          clearTimeout(id);
          reject(error);
        });
    });
  }

  postBrRequest(address: number, type: BR_REQ, payload: Array<number>): Promise<BrMsgView> {
    if (LOG.DEBUG) this.logger.info(`add pending req type=${type}, qlen=${1 + this.pendingReqs.length}`)
    const req = BrMsgView.make(address, type, payload, this.outBrReportSize);
    let promise: Promise<BrMsgView> | undefined;
    if (0 === this.pendingReqs.length) {
      promise = this.sendBrRequest(req);
    }
    else {
      promise = new Promise<BrMsgView>((resolve, reject) => {
        this.pendingReqs[this.pendingReqs.length - 1].finally(() => {
          this.sendBrRequest(req).then(msg => resolve(msg), err => reject(err));
        })
      })
    }
    this.pendingReqs.push(promise);
    return promise;
  }

  onReport(dv: DataView) {
    const pktNo = dv.getUint8(0);
    const pktCnt = dv.getUint8(1);
    if (1 === pktNo) {
      if (1 < pktCnt) {
        this.brMsgBuffer = dv.buffer;
        if (LOG.DEBUG) {
          const msg = new BrMsgView(dv);
          this.logger.debug(`BR msg fragment 1/${pktCnt} len=${msg.brLength()} type=${msg.type()} addr=${hex(msg.address())}`, dv)
        }
        return;
      }
    }
    else if (!this.brMsgBuffer || pktNo !== 1 + Math.ceil((this.brMsgBuffer.byteLength - PKT_PREFIX_LEN) / (this.inBrReportSize - PKT_PREFIX_LEN))) {
      this.logger.error(`Unexpected BR msg fragment number ${pktNo}` + (LOG.DEBUG ? ` msg=[${buf2hex(dv.buffer)}]` : ''));
      return;
    }
    else {
      const newBuf = new ArrayBuffer(this.brMsgBuffer.byteLength + dv.buffer.byteLength - PKT_PREFIX_LEN);
      const a = new Uint8Array(newBuf);
      a.set(new Uint8Array(this.brMsgBuffer), 0);
      a.set(new Uint8Array(dv.buffer, PKT_PREFIX_LEN), this.brMsgBuffer.byteLength);
      this.brMsgBuffer = newBuf;
      if (pktNo < pktCnt) {
        if (LOG.DEBUG) this.logger.debug(`BR msg fragment ${pktNo}/${pktCnt}`, dv)
        return;
      }
      dv = new DataView(this.brMsgBuffer);
      this.brMsgBuffer = undefined;
    }
    this.onBrReport(dv);
  };

  private onBrReport(dv: DataView) {
    const msg = new BrMsgView(dv);
    if (this.brRespCb && this.brRespCb(msg)) {
      return;
    }
    if (BR_MT.EVENT === msg.type()) {
      this.device.onEvent(msg);
    }
    else if (BR_MT.DEVICE_PROTO_VER === msg.type()) {
      if (LOG.DEBUG) {
        const min = msg.payloadUint8();
        const max = msg.payloadUint8();
        this.logger.info(`BR device version support: ${min}..${max} addr=${hex(msg.address())}`);
      }
    }
    else {
      if (LOG.DEBUG) this.logger.warn(`unhandled BR message ${msg}`);
    }
  }

  private brMsgBuffer?: ArrayBuffer;
  private brRespCb?: (msg: BrMsgView) => boolean;
  private pendingReqs = new Array<Promise<BrMsgView>>();  // only request #0 has been really sent
}
