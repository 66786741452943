import { Component, Input, OnInit } from "@angular/core";
import { Device } from "@poly/hub-native";
import { DeviceManagerService } from "../../services/device-manager.service";
import { ReconnectDeviceEvents } from "../../services/reconnect-device-events.service";
import {
  FavoritesService,
  SANDBOX_FAVORITE_INDEX,
} from "../../services/favorites.service";
import { UtilityService } from "../../services/utility.service";
import { DEVICE_SETTING_ID_RESTORE_DEFAULTS } from "../../utils/constants";

/**
 * Restore Defaults
 *
 * For devices which support provisioning, this option is
 * available under Admin category (Settings tab).
 */

@Component({
  selector: "oz-device-setting-restore-defaults",
  templateUrl: "./restore-defaults.component.pug",
})
export class DeviceSettingRestoreDefaultsComponent implements OnInit {
  readonly TRANSLATION_PREFIX = "MODALS.RESTORE_DEFAULTS.";

  // Translated name of a setting
  name: string;

  // Translated description of a setting
  description: string;

  // Translated button text of a setting
  buttonText: string;

  // Manages visibility of the restore defaults confirm dialog
  showConfirmModal = false;

  // Manages visibility of the restore defaults complete dialog
  showCompleteModal = false;

  // Device may need to be manually re-plugged in order for restore defaults to take effect
  needReplug = false;

  @Input()
  device: Device;

  constructor(
    private reconnectDeviceEvents: ReconnectDeviceEvents,
    private deviceManager: DeviceManagerService,
    private favoritesService: FavoritesService
  ) {}

  ngOnInit() {
    this.name = this.translate("name");
    this.description = this.translate("description");
    this.buttonText = this.translate("resetBtn");
  }

  onConfirmClick() {
    // Hide confirmation dialog
    this.showConfirmModal = false;

    // Open completed dialog
    // (TODO: UI: Why do we show completed dialog right away?
    //  Is there a way to know when this operation has really completed?
    //  For video device it takes some significant time to restore defaults)
    this.showCompleteModal = true;

    // Determines if a device may need a manual re-plug for the restore of defaults to take effect
    this.needReplug = !this.device?.isVideoDevice;

    // Initiate a deivce to restore its factory defaults
    this.deviceManager.setDeviceSetting(this.device.id, {
      id: DEVICE_SETTING_ID_RESTORE_DEFAULTS,
      value: "true",
    });

    // For video device, manage Favorites
    if (this.device?.isVideoDevice) {
      this.manageFavorites();
    }

    // Fire off an event in the UI code that the device is being reconnected
    this.reconnectDeviceEvents.reconnectStarted(this.device);
  }

  /**
   * Resets Favorites choice (under Controls tab) to Sandbox
   * and resets sandbox settings to factory defaults (e.g. all
   * UVC sliders bulbs would position on the center of sliders,
   * etc.)
   */
  private manageFavorites() {
    this.favoritesService.setFavoriteIndex(
      this.device.uniqueId,
      SANDBOX_FAVORITE_INDEX
    );
    this.favoritesService.resetSandbox(this.device.uniqueId);
  }

  /**
   * Obtains a translation string for this setting.
   *
   * @param path The translation path
   * @returns Translated string found on the provided path
   */
  private translate(path: string) {
    return UtilityService.getDeviceSettingTranslatePath(
      { id: DEVICE_SETTING_ID_RESTORE_DEFAULTS },
      path
    );
  }
}
