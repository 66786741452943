import { Component, OnInit, OnDestroy } from "@angular/core";
import { ActivatedRoute, NavigationEnd, Router } from "@angular/router";
import { subRouteAnimation } from "../../../animations/router.animations";
import { filter, map, mergeMap, pairwise, startWith } from "rxjs/operators";
import { BehaviorSubject, Subscription, Observable } from "rxjs";

@Component({
  selector: "oz-animated-router-outlet",
  templateUrl: "./animated-router-outlet.component.pug",
  animations: [subRouteAnimation],
})
export class AnimatedRouterOutletComponent implements OnInit, OnDestroy {
  public routeTrigger$: Observable<any>;
  private routeChange$ = new BehaviorSubject<number>(0);
  private routeWatcher$: Subscription;

  constructor(private router: Router, private route: ActivatedRoute) {}

  ngOnInit() {
    this.routeWatcher$ = this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        map(() => this.route),
        map((route) => {
          while (route.firstChild) route = route.firstChild;
          return route;
        }),
        filter((route) => route.outlet === "primary"),
        mergeMap((route) => route.data)
      )
      .subscribe((data) => {
        if (!data.hasOwnProperty("navIndex")) {
          return;
        }

        // get the navIndex onto the stack
        this.routeChange$.next(data.navIndex);
      });

    this.routeTrigger$ = this.routeChange$.pipe(
      startWith(0),
      pairwise(),
      map(([prev, curr]) => {
        return {
          value: curr,
          params: {
            offsetEnter: prev > curr ? -100 : 100,
            offsetLeave: prev > curr ? 100 : -100,
          },
        };
      })
    );
  }

  ngOnDestroy() {
    this.routeWatcher$.unsubscribe();
  }
}
