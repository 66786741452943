import { Injectable, NgZone } from "@angular/core";
import { BehaviorSubject, combineLatest, from } from "rxjs";
import { SettingsMetaData } from "../lens-settings/lens-settings-ui.model";
import { runInZone } from "../utils/rxjs.utils";
// import { defaultFavoriteOptions } from "./favorites.service";
import { ILoggingService } from "./logging.service";
import { StorageService } from "./storage.service";
import {
  cloneDeep as _cloneDeep,
  defaults as _defaults,
  get as _get,
  isEqual as _isEqual,
} from "lodash";
import { map } from "rxjs/operators";
import { LodashAccessor } from "./utility.service";
import {
  ACCEPT_EULA,
  POLY_REGISTRY_PATH,
  TENANT_TOKEN,
} from "../utils/constants";
import { getRegistryValue } from "../utils/registry.utils";

export const LENS_SETTINGS_KEY = "LENS_SETTINGS";

@Injectable({
  providedIn: "root",
})
export class LensSettingsService {
  private token: string;
  private showEulaInfo: boolean;

  constructor(
    private storageService: StorageService,
    private ngZone: NgZone,
    private logger: ILoggingService
  ) {
    this.initialize();
  }

  initialize() {
    const settingsFromStorage = this.storageService.getItem(LENS_SETTINGS_KEY);

    if (settingsFromStorage) {
      const settingsFromStorageChecked = this.ensureDefaults(
        settingsFromStorage
      );
      this._lensSettings$.next(settingsFromStorageChecked);
    } else {
      this.storageService.setItem(LENS_SETTINGS_KEY, this.defaultLensSettings);
    }
    // Check registry for token and EULA settings
    combineLatest([
      from(getRegistryValue(TENANT_TOKEN)),
      from(getRegistryValue(ACCEPT_EULA)),
    ]).subscribe(([token, acceptEula]) => {
      this.token = token;
      this.logger.debug("LensSettingsService - Accept EULA: " + acceptEula);
      this.showEulaInfo = !(acceptEula && acceptEula === "1");
      this.patchLensSettings({
        eulaAccepted: !!(this.token && this.showEulaInfo),
      });
    });
  }

  /**
   * Ensure there is an entry for every object that should be defaulted.
   * That is, if a developer adds "settingAbc: true" as a default, and the user updates
   * software version, it should .
   *
   * @param settings
   */
  ensureDefaults(settings: SettingsMetaData) {
    // _cloneDeep used as _defaults will mutate original object
    const checked = _defaults(_cloneDeep(settings), this.defaultLensSettings);

    // if updated, save new settings
    if (!_isEqual(checked, settings)) {
      this.storageService.setItem(LENS_SETTINGS_KEY, checked);
      this.logger.info(
        "Updated LENS_SETTINGS with new default favorite values."
      );
    }

    return checked;
  }

  public defaultLensSettings: SettingsMetaData = {
    productImprovement: false,
    anonymousData: false,
    enableNotifications: true,
    wellness: false,
    deviceAlerts: true,
    deviceUnpaired: false,
    lowBattery: true,
    deviceCharging: false,
    updateAvailable: true,
    networkConnect: false,
    softwareUpdate: true,
    deviceDisconnected: false,
    muteAlert: true,
    backgroundNoiseDetected: false,
    deviceSoftwareUpdate: true,
    automaticUpdates: false,
    favoriteSelectedByDevice: {},
    favoriteOptionsByDevice: {},
    soundscapeEnabled: false,
    soundscapeSound: "soundscapes/mountain_ranch/mountain_ranch_1",
    soundscapeVolume: 1,
    hydrationEnabled: false,
    hydrationDays: ["1", "2", "3", "4", "5"],
    hydrationTimeFrom: "9",
    hydrationTimeTo: "17",
    hydrationTimeSpan: "30",
    visionEnabled: false,
    visionDays: ["1", "2", "3", "4", "5"],
    visionTimeFrom: "9",
    visionTimeTo: "17",
    visionTimeSpan: "30",
    startOnSystemStartup: true,
    startMinimized: true,
    eulaAccepted: false,
  };

  private _lensSettings$ = new BehaviorSubject<SettingsMetaData>(
    _cloneDeep(this.defaultLensSettings)
  );

  get lensSettings() {
    return this._lensSettings$.asObservable();
  }

  /**
   * Given an accessor, return an observable with updated values for that specific accessor.
   *
   * @param accessor e.g. 'hydrationTimeFrom' or ['favorites', 0, 'name'],
   */
  public getLensSetting$(accessor: LodashAccessor): any {
    return this._lensSettings$.pipe(
      map((lensSettings) => {
        return _get(lensSettings, accessor);
      }),
      runInZone(this.ngZone)
    );
  }

  public setLensSettings(settings: SettingsMetaData) {
    // without ngZone, Angular templates may not update even if the observable does
    this.ngZone.run(() => {
      this._lensSettings$.next(settings);
    });

    this.storageService.setItem(LENS_SETTINGS_KEY, settings);
  }

  /**
   * Given an object that is a subset of SettingsMetaData, override existing settings and re-save.
   */
  public patchLensSettings(settings: Partial<SettingsMetaData>) {
    const newSettings = { ...this._lensSettings$.value, ...settings };
    this.setLensSettings(newSettings);
  }

  resetDefaultSettings() {
    this.ngZone.run(() => {
      this._lensSettings$.next(_cloneDeep(this.defaultLensSettings));
    });
    this.storageService.setItem(LENS_SETTINGS_KEY, this.defaultLensSettings);
  }
}
