import { Component, Input } from "@angular/core";
import { GetGeneralSettingsResponse, GeneralSettings } from "@poly/hub-native";
import {
  DeviceManagerService,
  OzDevice,
} from "../../services/device-manager.service";
import { AccordionItem } from "../../shared/components/accordion/accordion.component";

@Component({
  selector: "oz-device-settings-general-item",
  templateUrl: "./device-settings-general-item.component.pug",
})
export class DeviceSettingsGeneralItemComponent {
  @Input() set accordionData(newValue: AccordionItem) {
    this.device = newValue.data.device;
  }

  @Input() set generalSettings(settings: GetGeneralSettingsResponse) {
    if (settings) {
      this.ntpMode = settings.ntp_mode;
      this.ntpServer = settings.ntp_server;
      this.cameraVividMode = settings.camera_vivid_mode;
    }
  }

  device: OzDevice;

  ntpMode: string;
  ntpServer: string;
  cameraVividMode: boolean;

  constructor(private deviceManager: DeviceManagerService) {}

  onVividModeChanged(value: boolean) {
    this.cameraVividMode = value;
    const request: GeneralSettings = {
      deviceId: this.device?.id,
      device_name: this.device.name,
      country_region: this.device?.videoDeviceStatus?.countryRegion,
      ntp_server: this.ntpServer,
      ntp_mode: this.ntpMode,
      camera_vivid_mode: this.cameraVividMode,
    };

    this.deviceManager.setGeneralSettings(request);
  }
}
