import {
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild,
  ElementRef,
  AfterViewInit,
  OnInit,
} from "@angular/core";
import { TranslateService } from "@ngx-translate/core";

export interface TranslatedOption {
  value: string;
  text: string;
}

@Component({
  selector: "app-multiselect-dropdown",
  templateUrl: "./multiselect-dropdown.component.pug",
})
export class MultiselectDropdownComponent implements OnInit, AfterViewInit {
  dropdownExpanded: boolean = false;

  @Input() className?: string;

  @Input() disallowNoSelections?: boolean;

  @Input() selectionText?: string;

  @Input() checkboxIdPrefix?: string;

  @Input() options: any;

  @Input() selected: string[];

  @Output() valueChanged = new EventEmitter<string[]>();

  @ViewChild("multiselectDropdown") multiselectDropdown: ElementRef;

  @ViewChild("multiselectDropdownOptionsContainer")
  multiselectDropdownOptionsContainer: ElementRef;

  translatedOptions: TranslatedOption[];

  constructor(private translateService: TranslateService) {}

  ngOnInit(): void {
    this.translateService
      .stream(this.options.map((e) => e.text))
      .subscribe((translations) => {
        this.translatedOptions = this.options.map((x) => ({
          ...x,
          text: translations[x.text],
        }));
      });
  }

  ngAfterViewInit(): void {
    this.setMultiselectDropdownOptionsContainerWidth();
  }

  onChange(e, value: string) {
    let index = this.selected.indexOf(value);

    if (index === -1) {
      this.selected.push(value);
    } else {
      if (this.disallowNoSelections && this.selected.length === 1) {
        return;
      }
      this.selected.splice(index, 1);
    }

    this.valueChanged.emit(this.selected);
  }

  onBlur(e) {
    if (!e.currentTarget.contains(e.relatedTarget)) {
      this.dropdownExpanded = false;
    }
  }

  onScroll = (e): void => {
    if (e.target !== this.multiselectDropdownOptionsContainer.nativeElement) {
      this.dropdownExpanded = false;
    }
  };

  toggleDropdown() {
    this.dropdownExpanded = !this.dropdownExpanded;

    if (this.dropdownExpanded) {
      window.addEventListener("scroll", this.onScroll, true);
    } else {
      window.removeEventListener("scroll", this.onScroll, true);
    }

    this.setMultiselectDropdownOptionsContainerPosition();
  }

  getSelectionText(): string {
    if (this.selectionText === undefined) {
      return (
        (this.selected.length !== 0 ? this.selected.length : "None") +
        " selected"
      );
    }
    return this.selectionText;
  }

  getUniqueCheckboxId(value: string): string {
    if (this.checkboxIdPrefix !== undefined) {
      return this.checkboxIdPrefix + value;
    }
    return value;
  }

  disableCheckbox(value: string): boolean {
    return (
      this.disallowNoSelections &&
      this.selected.length === 1 &&
      this.selected[0] === value
    );
  }

  setMultiselectDropdownOptionsContainerWidth() {
    this.multiselectDropdownOptionsContainer.nativeElement.style.width =
      this.multiselectDropdown.nativeElement.offsetWidth + "px";
  }

  setMultiselectDropdownOptionsContainerPosition() {
    this.multiselectDropdownOptionsContainer.nativeElement.style.top =
      this.multiselectDropdown.nativeElement.getBoundingClientRect().top +
      this.multiselectDropdown.nativeElement.offsetHeight +
      "px";
  }
}
