import { Injectable, OnDestroy } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { map } from "rxjs/operators";
import { Subscriptions } from "../utils/subscriptions";
import { Accessor, AccessorService } from "./accessor.service";
import { tag } from "rxjs-spy/operators/tag";
import { AuthService } from "./auth.service";

@Injectable({
  providedIn: "root",
})
export class TenantService implements OnDestroy {
  private subs = new Subscriptions();
  private _tenantId$ = new BehaviorSubject(null);
  private _tenantName$ = new BehaviorSubject(null);

  constructor(
    private accessorService: AccessorService,
    private authService: AuthService
  ) {
    this.initAccessorUpdates();
  }

  public get tenantId$(): Observable<string> {
    return this._tenantId$.asObservable().pipe(tag("TenantService.tenantId$"));
  }

  public setTenantId(id: string) {
    this._tenantId$.next(id);
  }

  public get tenantName$(): Observable<string> {
    return this._tenantName$.asObservable().pipe(tag("TenantService.tenantName$"));
  }

  public setTenantName(name: string) {
    this._tenantName$.next(name);
  }

  private initAccessorUpdates(): void {
    // in case of loginless installation skip accessor updates (not needed)
    this.subs.add(
      this.authService.isLoginless$.subscribe((isLoginless) => {
        if (isLoginless) {
          return;
        }

        this.subs.add(
          this.accessorService.accessor$
            .pipe(
              map((accessor: Accessor) => {
                // prefer tenant with a device_user role
                let grant = accessor.grants.find((g) => {
                  return (
                    g.resourceType === "Tenant" &&
                    g.roles.find((r) => r.name === "device_user")
                  );
                });

                if (grant?.resourceId) {
                  return grant?.resourceId;
                }

                // If the user is not a device_user in any tenant, see if they are an admin
                grant = accessor.grants.find((g) => {
                  return (
                    g.resourceType === "Tenant" &&
                    g.roles.find((r) => r.name === "admin")
                  );
                });

                return grant?.resourceId;
              }),
              tag("TenantService.initAccessorUpdates")
            )
            .subscribe((val) => {
              this._tenantId$.next(val);
            })
        );
      })
    );
  }

  public getTenantInfo(tenantId: string): Observable<any> {
    return this.accessorService.getTenant(tenantId);
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }
}
