import { IDeviceManager } from "./device-manager.api";
import { IConfigManager } from "./config-manager.api";
import { IDeviceSettingsManager } from "./device-settings-manager.api";
import { IDFUManager } from "./dfu-manager.api";
import { IEventsManager } from "./events-manager.api";

export abstract class IHubNative {

    // Retreives a device manager interface
    abstract getDeviceManager(): IDeviceManager

    // Retrieves a device settings manager
    abstract getDeviceSettingsManager(): IDeviceSettingsManager

    // Retreives a DFU manager
    abstract getDFUManager(): IDFUManager

    // Retreives a events manager
    abstract getEventsManager(): IEventsManager

    // Retreives configuration manager
    abstract getConfigManager(): IConfigManager

    // Stops the underlying device management
    abstract stop(): void
}
