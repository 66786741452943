import { ILoggingService } from "./logging.service";
import {
  ZooEntityUpdatePayload,
  ZooEntityUpdatePayloadValue,
} from "./device.messaging/utils/zoo-entity-update-message.types";
import { DeviceManagerService, OzDevice } from "./device-manager.service";
import { IotService } from "./iot.service";
import { Injectable } from "@angular/core";
import { distinct, map, mergeMap, filter, switchMap } from "rxjs/operators";
import { interval } from "rxjs";
import { DeviceUtils } from "../utils/device.utils";
import { getFirmwareVersion } from "../shared/pipes/firmware-version.pipe";
import { DeviceSetting } from "@poly/hub-native";
import { set as _set } from "lodash";
import { SETTING_ID_MAP } from "../utils/constants";

@Injectable({
  providedIn: "root",
})
export class ZooEntityStatePusherService {
  constructor(
    private iot: IotService,
    private deviceManager: DeviceManagerService,
    private deviceUtils: DeviceUtils,
    private logger: ILoggingService
  ) {
    this.iot?.ready$
      .pipe(
        filter((r) => r),
        switchMap(() => this.deviceManager.getDevices()),
        // This seems unnecessary because we already have the list of devices
        // but if we rely on the list to fire when the device info changes then
        // we end up sending messages for all devices anytime ANY device's info
        // changes.
        distinct((devices) =>
          JSON.stringify(devices.map((d) => d.uniqueId).sort())
        ),
        mergeMap((devices) => devices), // Turn it from an array to individual devices
        switchMap((d) => this.deviceManager.getDevice(d.uniqueId)),
        filter((d) => !!d),
        mergeMap((device) =>
          this.deviceManager
            .getDeviceSettings(device.id)
            .pipe(map((settings) => ({device, settings, fullPayload: false})))

        ),
        distinct((o) => o.device.id, interval(10000))
      )
      .subscribe(this.sendEntityUpdate);
  }

  sendEntityUpdate = ({
    device,
    settings,
    fullPayload = false,
  }: {
    device: OzDevice;
    settings: DeviceSetting[];
    fullPayload: boolean;
  }) => {
    this.logger.debug("sending ZooEntityUpdate");
    const message: ZooEntityUpdatePayload = {
      attr: "hubv3",
      version: "0.0.1",
      value: {
        eventTime: new Date(),
        eventType: "Zoo.entityUpdate",
        eventVersion: "0.0.1",
        eventData: {
          entity_id: device.uniqueId,
          data: {
            com: {
              poly: {
                device: {
                  pid: leaf(device.pid, null, true, false),
                  serial_number: leaf(
                    this.deviceUtils.getSerial(device),
                    null,
                    true,
                    false
                  ),
                  software_version: leaf(
                    getFirmwareVersion(device),
                    null,
                    true,
                    false
                  ),
                },
                general: {
                  is_online: leaf(device.isConnected, null, true, false),
                  battery_level: leaf(device.battery?.level, null, true, false),
                  battery_charging: leaf(
                    device.battery?.charging,
                    null,
                    true,
                    false
                  ),
                },
              },
            },
          },
        },
      },
    };

    if (fullPayload) {
      // Initialize all properties to null so that if they don't exist
      // they will be removed in Zoo
      for (let path of Object.values(SETTING_ID_MAP)) {
        _set(message.value.eventData.data, path, null);
      }
    }

    for (let setting of settings) {
      const settingPath = SETTING_ID_MAP[parseInt(setting.id)];
      if (settingPath) {
        const value: ZooEntityUpdatePayloadValue = leaf(
          setting.value,
          setting.options,
          true,
          true
        );
        _set(message.value.eventData.data, settingPath, value);

        if (setting.autoEnabledSupported) {
          this.logger.debug("zoo adding auto_enabled: ", settingPath);
          const value: ZooEntityUpdatePayloadValue = leaf(
            setting.autoEnabled,
            null,
            true,
            true
          );
          _set(
            message.value.eventData.data,
            settingPath + "_auto_enabled",
            value
          );
        }
      }
    }

    this.iot.sendZooEntityUpdateMessage(message);
  };
}

/**
 * Creates a leaf value for the ZooEntityUpdatePayload
 * @param value
 * @param options
 * @param readable
 * @param mutable
 * @returns
 */
function leaf<T>(
  value: T,
  options: any,
  readable: boolean,
  mutable: boolean
): ZooEntityUpdatePayloadValue<T> {
  const result: ZooEntityUpdatePayloadValue<T> = {
    _data: {
      value,
      mutable,
      readable,
    },
  };
  if (options) {
    result._data.constraints = {
      options,
    };
  }
  return result;
}
