import { Component, EventEmitter, Input, Output } from "@angular/core";
import { Device } from "@poly/hub-native";

@Component({
  selector: "oz-ftc-wizard-close-modal-content",
  templateUrl: "./ftc-wizard-close-modal-content.component.pug",
})
export class FtcWizardCloseModalContentComponent {
  @Input()
  device: Device;

  @Output()
  cancel = new EventEmitter<void>();

  @Output()
  exitSetup = new EventEmitter<void>();
}
