import { Component, Input } from "@angular/core";
import { DfuType } from "../../services/dfu-modal.service";
import { AnimationItem } from "lottie-web";
import { AnimationOptions } from "ngx-lottie";

@Component({
  selector: "oz-dfu-download-archive-progress",
  templateUrl: "./dfu-download-archive-progress.component.pug",
})
export class DfuDownloadArchiveComponent {
  @Input() percentage = 0;
  @Input() dfuType: DfuType;

  options: AnimationOptions = {
    path: "assets/lottie/downloading_dfu_animation.json",
  };

  animationCreated(animationItem: AnimationItem): void {
    // very laggy Windows performance has been observed due to lottie; this improves the condition by not
    // rendering more subframes than necessary based on JSON After Effects frames per second
    animationItem.setSubframe(false);
  }

  getTitle() {
    switch (this.dfuType) {
      case "LanguageChange":
        return "DFU.LANGUAGE_DOWNLOAD";
      default:
        return "DFU.SOFTWARE_DOWNLOAD";
    }
  }

  getDescription() {
    switch (this.dfuType) {
      case "LanguageChange":
        return "DFU.DOWNLOADING_LANGUAGE";
      default:
        return "DFU.DOWNLOADING_SOFTWARE";
    }
  }
}
