import {
  ApplicationRef,
  ComponentFactory,
  ComponentFactoryResolver,
  ComponentRef,
  EmbeddedViewRef,
  Injectable,
  Injector,
} from "@angular/core";
import { Subscriptions } from "../utils/subscriptions";
import { DfuModalComponent } from "../dfu-modal/dfu-modal.component";
import { OzDevice } from "./device-manager.service";
import { Subject } from "rxjs";
import { Router } from "@angular/router";

export type DfuType = "Remote" | "Local" | "LanguageChange" | "Recovery";

export interface DfuModalParams {
  device: OzDevice;
  dfuType: DfuType;
  rules?: string; // required if dfuType === "Remote"
  archiveLocation: string; // URL in case: dfuType === "Remote" | "LanguageChange", or local filepath in case: dfuType === "Local"
  languageId?: string; // required if: dfuType === "LanguageChange"
}

export type DfuModalEvent = "opened" | "closed";

@Injectable({
  providedIn: "root",
})
export class DfuModalService {
  private componentRef: ComponentRef<DfuModalComponent> = null;
  private factory: ComponentFactory<DfuModalComponent>;
  private subs = new Subscriptions();

  events = new Subject<DfuModalEvent>();

  constructor(
    private resolver: ComponentFactoryResolver,
    private injector: Injector,
    private appRef: ApplicationRef,
    private router: Router
  ) {
    // Creates a recipe for creating a component
    this.factory = this.resolver.resolveComponentFactory(DfuModalComponent);
  }

  // Opens DFU progress dialog
  open(params: DfuModalParams): void {
    // We already have DFU progress on screen, so ignore
    // any additional calls until the existing modal is closed
    if (this.componentRef) {
      return;
    }

    // Creates an instance of the component
    this.componentRef = this.factory.create(this.injector);

    // Pass on @Input() parameters
    this.componentRef.instance.params = params;

    // Add subscription
    this.subs.add(
      // Listen to close event coming from DFU progress component
      this.componentRef.instance.close.subscribe(() => {
        this.close();
      })
    );

    // Attach Angular component tree
    this.appRef.attachView(this.componentRef.hostView);

    // Get DOM of component
    const domElem = (this.componentRef.hostView as EmbeddedViewRef<any>)
      .rootNodes[0] as HTMLElement;

    // Attach component to document
    document.body.appendChild(domElem);

    this.events.next("opened");
  }

  // Closes DFU progress dialog
  close(): void {
    try {
      const device = this.componentRef.instance.params.device;
      this.subs.unsubscribe();
      this.componentRef.destroy();
      this.componentRef = null;
      this.events.next("closed");
      // redirect to Overview page
      this.router.navigate(["/detail", device.uniqueId]);
    } catch (ex) {
      console.log(ex);
    }
  }
}
