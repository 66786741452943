import { mergeMap, filter, switchMap } from "rxjs/operators";
import { EulaService } from "./eula.service";
import { SettingsMetaData } from "../lens-settings/lens-settings-ui.model";
import { NotificationsService } from "./notifications.service";
import { DeviceManagerService, OzDevice } from "./device-manager.service";
import { LensSettingsService } from "./lens-settings.service";
import { Subscription } from "rxjs";
import { Injectable } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";

@Injectable({
  providedIn: "root",
})
export class DisconnectedNotificationsService {
  private connectedState: { [uniqueId: string]: boolean } = {};
  private devicesSub: Subscription;
  private messageBody: string;

  constructor(
    private lensSettingsService: LensSettingsService,
    private deviceManager: DeviceManagerService,
    private notificationService: NotificationsService,
    private translateService: TranslateService,
    private eulaService: EulaService
  ) {
    this.lensSettingsService.lensSettings.subscribe((settings) => {
      if (settings.enableNotifications && settings.deviceAlerts) {
        this.setupNotifications(settings);
      } else {
        this.teardownNotifications();
      }
    });

    this.translateService
      .stream("NOTIFICATIONS.DEVICE_DISCONNECTED")
      .subscribe((translation) => {
        this.messageBody = translation.DESCRIPTION;
      });
  }

  private setupNotifications(settings: SettingsMetaData) {
    if (settings.deviceDisconnected && !this.devicesSub) {
      this.devicesSub = this.eulaService.agreed$
        .pipe(
          filter((v) => !!v),
          mergeMap(() => this.deviceManager.getDevices())
        )
        .subscribe((devices) => {
          devices.forEach((d) => this.notifyIfNeeded(d));
        });
    } else if (!settings.deviceDisconnected && this.devicesSub) {
      this.teardownNotifications();
    }
  }

  private teardownNotifications() {
    this.devicesSub?.unsubscribe();
    this.devicesSub = null;
    this.connectedState = {};
  }

  private notifyIfNeeded(device: OzDevice) {
    const previouslyConnected = this.connectedState[device.uniqueId];
    if (previouslyConnected && !device.isConnected) {
      this.notificationService.singleNotification({
        title: `${device.name}`,
        body: this.messageBody,
        origin: "DISCONNECTED",
      });
    }
    this.connectedState[device.uniqueId] = !!device.isConnected;
  }
}
