import { DeviceSetting } from "@poly/hub-native";
import { Pipe, PipeTransform } from "@angular/core";
import { UtilityService } from "../../../services/utility.service";
import { SETTINGS } from "../../../utils/constants";

// Map options icons
const settingOptionsIcons = new Map();
settingOptionsIcons.set(SETTINGS.TRACKING_MODE, {
  conversation: {
    name: "preview",
    size: 4.2,
    offset: { top: "2px", left: "0" },
  },
  gallery: { name: "preview", size: 4.2, offset: { top: "2px", left: "0" } },
});

/**
 * Designed to feed device settings into an oz-dropdown component.
 * Maps the translation path of the settings in order to pass
 * properly into the oz-dropdowns use of the translate pipe.
 */
@Pipe({
  name: "settingOptions",
})
export class SettingOptionsPipe implements PipeTransform {
  constructor() {}

  transform(setting: DeviceSetting) {
    return setting.options.map((o) => {
      const key = UtilityService.getDeviceSettingTranslatePath(
        setting,
        `options.${o}`
      );
      let icons: { name: string; size: number }[] = [];
      if (settingOptionsIcons.has(UtilityService.unpadHex(setting.id, true))) {
        const option = settingOptionsIcons.get(
          UtilityService.unpadHex(setting.id, true)
        );
        if (option[o]) icons.push(option[o]);
      }
      return {
        value: o,
        text: key,
        icons,
      };
    });
  }
}
