import { PingTest } from "../SpeedTest/PingTest";
import { DownloadTest } from "../SpeedTest/DownloadTest";
import { UploadTest } from "../SpeedTest/UploadTest";
import { Utility } from "../SpeedTest/Utility";
import { BehaviorSubject, combineLatest, Observable, Subject } from "rxjs";
import {
  LocalNetworkTest,
  LocalNetworkValue,
} from "../SpeedTest/LocalNetworkTest";
import { cloneDeep as _cloneDeep } from "lodash";
import {ILoggingService} from "../app/services/logging.service";

interface TestConfig {
  type: "upload" | "download" | "ping";
  simultaneous_streams: number;
  runtime_ms: number;
  ping_timeout_ms: number;
  port: number;
  protocol: "tcp" | "udp";
  address: string;
  sizes_kib: Array<number>;
  sizes_udp_kib: Array<number>;
}

export interface SpeedTestValue {
  value: number;
  unit?: string;
  metric: "speed" | "jitter" | "ping" | "loss" | "min" | "max";
}

export interface NetworkTestResult {
  completed: boolean;
  testConfig: TestConfig;
  values: Array<SpeedTestValue | LocalNetworkValue>;
}

export interface NetworkTestResults {
  currentTestIndex: number | undefined;
  completed: boolean;
  results: Array<NetworkTestResult>;
}

type NetworkTestSubjects = Array<BehaviorSubject<NetworkTestResult>>;

export class SpeedTest {
  private config: any = {};
  private defaultSpeedTestResult = {
    completed: false,
    testConfig: {},
    values: [],
  };
  private testResults$: NetworkTestSubjects = [];
  private currentTestIndex$ = new BehaviorSubject<number | undefined>(undefined);
  private speedTestResults$: Subject<NetworkTestResults> = new Subject();
  private testResultsToReportToUI$: NetworkTestSubjects = [];

  public constructor(
    private logger: ILoggingService
  ) {
    this.runTest();
  }

  public getResults$() {
    return this.speedTestResults$;
  }

  public runTest(): Observable<any> {
    console.log("XXX SpeedTest.runTest()");

    this.setupTests().then(() => {
      this.runTests().then(() => {});

      const combinedResultsToReportToUI$: Observable<Array<
        NetworkTestResult
      >> = combineLatest(this.testResultsToReportToUI$);

      // split up to work around typing limitation of 6 sources for combineLatest
      const all$ = combineLatest([
        this.currentTestIndex$,
        combinedResultsToReportToUI$,
      ]);

      // this reports updates specific to UI, based on report_to_ui boolean flag from test-config.json
      all$.subscribe(([currentTestIndex, combinedResultsToReportToUI]) => {
        const completed = !combinedResultsToReportToUI.some(
          (result: NetworkTestResult) => !result.completed
        );

        const update: NetworkTestResults = {
          currentTestIndex: completed ? undefined : currentTestIndex,
          completed,
          results: combinedResultsToReportToUI,
        };

        // dev tool, keep commented
        // console.log("\x1Bc");
        // console.log(JSON.stringify(update));

        this.speedTestResults$.next(update);
      });

      // TODO send final data to IoT
    });

    return this.speedTestResults$;
  }

  private setupTests = async () => {
    this.config = await Utility.getTestConfig();

    // TODO implement error handling that feeds through to frontend
    if (!this.config) {
      return;
    }

    // TODO for download, consider shaping that could be in a config from CDN: crop_first_ms?
    // TODO add typing for tests

    // development override with local config file
    // note the local config is authoritative, meaning updates to it should be reflected in Azure
    // for details see the Utility.getLocalTestConfig function
    // TODO:
    // this.config = await Utility.getLocalTestConfig();

    // remove any tests with skip set to true; this means indexing of testResults
    // is based on all non-skipped tests
    this.config.tests = this.config.tests.filter((test) => !test.skip);

    this.config.tests.forEach((test, i) => {
      // generate new BehaviorSubject to track test results
      // this is based on an default speed test result with the testConfig initialized
      const initialTestResult = {
        ..._cloneDeep(this.defaultSpeedTestResult),
        testConfig: test,
      };

      this.testResults$.push(
        new BehaviorSubject<NetworkTestResult>(initialTestResult)
      );

      if (test.report_to_ui) {
        this.testResultsToReportToUI$.push(this.testResults$[i]);
      }
    });
  };

  private runTests = async () => {
    for (let i = 0; i < this.config.tests.length; i++) {
      const test = this.config.tests[i];

      this.currentTestIndex$.next(i);

      switch (test.type) {
        case "ping":
          const pingValues = await new PingTest(test, this.logger).run();

          this.testResults$[i].next({
            completed: true,
            testConfig: test,
            values: pingValues,
          });

          break;
        case "download":
          await new Promise<void>((resolve, reject) => {
            new DownloadTest(test, this.logger).run$().subscribe(
              (speedTestResult) => {
                this.testResults$[i].next(speedTestResult);
              },
              (err) => {
                this.logger.error(err);
                reject(err);
              },
              () => {
                resolve();
              }
            );
          });

          break;
        case "upload":
          await new Promise<void>((resolve, reject) => {
            new UploadTest(test, this.logger).run$().subscribe(
              (speedTestResult) => {
                this.testResults$[i].next(speedTestResult);
              },
              (err) => {
                this.logger.error(err);
                reject(err);
              },
              () => {
                resolve();
              }
            );
          });

          break;
        case "localNetwork":
          const localNetworkValues = await new LocalNetworkTest(test).run();

          this.testResults$[i].next({
            completed: true,
            testConfig: test,
            values: localNetworkValues,
          });

          break;
        default:
          this.logger.error(`Test type ${test.type} not supported.`);

          return;
      }
    }

    // clear currentTest
    this.currentTestIndex$.next(undefined);
  };
}
