import { Component, Input, OnDestroy, OnInit } from "@angular/core";
// import { ElectronService } from "../../../core/services";
import { LensSettingsService } from "../../../services/lens-settings.service";
import { AccordionItem } from "../../../shared/components/accordion/accordion.component";
import { LensSettingDeviceAlertsMetadata } from "../lens-settings-device-alerts/lens-settings-device-alerts.component";
import { Subscription } from "rxjs";
import { SettingsMetaData } from "../../lens-settings-ui.model";
import { UtilityService } from "../../../services/utility.service";
import { EULA_URL } from "../../../utils/constants";
import { AdminConfig } from "../../../services/admin-config.service";

@Component({
  selector: "oz-lens-app-settings",
  templateUrl: "./lens-app-settings.component.pug",
})
export class LensAppSettingsComponent implements OnInit, OnDestroy {
  @Input() set accordionData(value: AccordionItem) {
    this.setting = value.data;
  }

  public setting: LensSettingDeviceAlertsMetadata;
  public settingsMetaData: SettingsMetaData;
  private lensSettingsServiceSub: Subscription;

  launchAtStartupConfigured = false;
  launchMinimizedConfigured = false;

  constructor(
    // private electronService: ElectronService,
    private lensSettingsService: LensSettingsService,
    private adminConfig: AdminConfig
  ) {}

  ngOnInit(): void {
    this.lensSettingsServiceSub = this.lensSettingsService.lensSettings.subscribe(
      (settings) => {
        // Check if launchAtStartup and launchMinimized are configured in admin-config.json
        this.launchAtStartupConfigured =
          this.adminConfig.launchAtStartup !== null;
        this.launchMinimizedConfigured =
          this.adminConfig.launchMinimized !== null;

        if (this.launchAtStartupConfigured)
          settings.startOnSystemStartup = this.adminConfig.launchAtStartup;
        if (this.launchMinimizedConfigured)
          settings.startMinimized = this.adminConfig.launchMinimized;

        this.settingsMetaData = settings;
      }
    );
  }

  public onValueChanged(value: any, action: string): void {
    this.settingsMetaData[action] = value;
    this.lensSettingsService.setLensSettings(this.settingsMetaData);
    // this.electronService.setStartOnSystemStartup(
    //   this.settingsMetaData.startOnSystemStartup,
    //   this.settingsMetaData.startMinimized
    // );
  }

  openEula() {
    UtilityService.openExternalBrowser(EULA_URL);
  }

  ngOnDestroy(): void {
    this.lensSettingsServiceSub.unsubscribe();
  }
}
