import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable, of } from "rxjs";
import { map } from "rxjs/operators";
import { StorageService } from "./storage.service";
import { ILoggingService } from "./logging.service";
import { UtilityService } from "./utility.service";
import { Repository } from "./repository/repository.service";
import { RepositoryProductCatalog } from "./repository/model";
import { MS_IN_DAY } from "../utils/constants";

export const PRODUCT_CATALOG_KEY = "PRODUCT_CATALOG";
export const NO_DEVICE_IMAGE = "./assets/no-device-image.svg";
export const NO_DEVICE_TYPE = "image/svg+xml";

type PID = string | number;

/**
 * This service, along with device-image.pipe.ts, assists with retrieval of product images.
 * Currently a Chromium-based prefetch and dynamic retrieval is at play.
 * device-image.pipe.ts handles the file retrieval via fetch, and graceful degradation
 * via a placeholder SVG if network is unavailable, image URL is incorrect, network problems,
 * CORS issues or any other unforeseen issues.
 *
 * The product seeder page (internal access only) is helpful for diagnostics and seeing
 * current catalog state:
 * http://poly-glass.onecode-pages.polycom.com/team-mo/tooling/products-seeder/
 */
@Injectable({
  providedIn: "root",
})
export class ProductCatalog {
  // TODO: UI: This should be Subject, not BehaviorSubject.
  private productCatalog = new BehaviorSubject<RepositoryProductCatalog>(null);

  constructor(
    private storageService: StorageService,
    private logger: ILoggingService,
    private repo: Repository
  ) {
    const products = this.storageService.getItem(PRODUCT_CATALOG_KEY, []);
    this.productCatalog.next(products);
  }

  /**
   * Recheck catalog on each Angular lift. This is called from the main app.component.ts.
   */
  init() {
    this.repo
      .getProductCatalog({
        pollInterval: MS_IN_DAY,
      })
      .subscribe((repoProductCatalog) => {
        this.storageService.setItem(PRODUCT_CATALOG_KEY, repoProductCatalog);
        this.productCatalog.next(repoProductCatalog);
      });
  }

  get products$(): Observable<RepositoryProductCatalog> {
    return this.productCatalog.asObservable();
  }

  /**
   * Observable to return the updated image URL of a device based on a pid.
   */
  productImageUrl$(pid: PID, headsetPID?: PID): Observable<string> {
    // caution: 0x0 is a valid pid but would evaluate to falsy
    if (this.isFalsyPID(pid)) {
      return of(NO_DEVICE_IMAGE);
    }

    // TODO: UI: Create deviceImage pipe to handle selection of an image for a device.
    return this.productCatalog.pipe(
      map((products) => {
        const productHeadset = headsetPID
          ? this.findMatchingProduct(headsetPID, products)
          : null;
        const product = this.findMatchingProduct(pid, products);
        if (!!productHeadset && productHeadset?.image) {
          return productHeadset.image;
        }
        if (product?.image) {
          return product.image;
        }
        this.logger.error(`Image not found for device with PID=${pid}.`);
        return NO_DEVICE_IMAGE;
      })
    );
  }

  /**
   * Returns an observable that returns truthy if the device is currently
   * fully / properly supported by the desktop app.
   *
   * Currently (as of 3/26/2021), "supported" is indicated by skus existing
   * in the product catalog for the pid, so this is just a wrapper around
   * "productHasSkus$", which checks if the product has skus.
   */
  productIsSupported$(pid: PID): Observable<boolean> {
    if (this.isFalsyPID(pid)) {
      return of(false);
    }
    return this.productCatalog.pipe(
      map((products) => {
        // for the edge case of initial lift with no internet, all products are considered supported / to have skus
        if (!products || !products.length) {
          return true;
        }
        return this.findMatchingProduct(pid, products)?.supported;
      })
    );
  }

  /**
   * Small utility to check if a PID is falsy.
   * NOTE: 0x0 is a valid pid but would evaluate to falsy
   */
  private isFalsyPID(pid: PID) {
    return 0x0 !== pid && !pid;
  }

  /**
   * Given an array of product catalog entries, find the entry that
   * matches the passed in PID.
   */
  private findMatchingProduct(pid: PID, products: RepositoryProductCatalog) {
    return products.find((product) => {
      if (UtilityService.couldBeHex(product.id)) {
        // normalize productPIDHex, e.g. 9212 -> 0x9212
        const productPIDHex = UtilityService.unpadHex(product.id, false);

        return UtilityService.hexIsEqual(productPIDHex, pid);
      }

      // dealing with a non-hex product.pid, require equality
      return String(product.id) === String(pid);
    });
  }
}
