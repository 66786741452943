import { Injectable } from "@angular/core";
import { NativeService } from "./hub-native-service/hub-native.service";
import { DeviceManagerService } from "./device-manager.service";
import { Features, StateService } from "./state.service";
import { ILoggingService } from "./logging.service";
import {
  LinkQualityEvent,
  TwaEvent,
  AcousticIncident,
  ConversationAnalyticsEvent,
  AERSSetting,
} from "@poly/hub-native";
import { v4 as UUID } from "uuid";
import { Subject, Observable, timer, combineLatest } from "rxjs";

export interface AcousticTelemetryMessage {
  frameId: string;
  name: string;
  appName: string;
  type: string;
  deviceid: string;
  payload: {
    attr: string;
    version: string;
    value: {
      eventType: string;
      eventTime: string;
      eventVersion: string;
      eventdata: {
        batchedData:
          | AcousticIncidentData[]
          | TwaData[]
          | LinkQualityData[]
          | ConversationAnalyticsData[];
      };
    };
  };
}

export interface AcousticIncidentData {
  incidentTime: string; // timestamp for this specific incident "2020-07-22T20:06:58.179Z"
  callId: string;
  gainthreshold: number;
  durationthreshold: number;
  limiterBitMask: number;
  activelimiters: string[];
  incidentduration: number;
  audiolevelbeforelimiting: number; // Decibel Level for a Chain Saw
  audiolevelafterlimiting: number; // Decibel Level for a normal conversation
}

export interface TwaData {
  incidentTime: string; // timestamp for this specific incident "2020-07-22T20:06:58.179Z"
  callId: string;
  duration: number;
  durationthreshold: number;
  periodicity: number; // (in ms). The value represents the “periodicity” in the first twa report sent by the device during the hour.
  audiolevelbeforelimiting: number; // Decibel Level for a Chain Saw
  audiolevelafterlimiting: number; // Decibel Level for a normal conversation
  lineType: string; // e.g. VOIP
}

export interface LinkQualityData {
  incidentTime: string; // timestamp for this specific incident "2020-07-22T20:06:58.179Z"
  callId: string;
  linkQualityVersion: string;
  periodicity: number; // (in ms).
  RSSI: number;
  LFOM: number;
  rawLinkQuality: string;
  type: string;
}

export interface ConversationAnalyticsData {
  incidentTime: string; // timestamp for this specific incident "2020-07-22T20:06:58.179Z"
  callId: string;
  periodicity: number; // (in ms).
  farTalk: number;
  nearTalk: number;
  overTalk: number;
  txLevelIn: number;
  txLevelOut: number;
  rxLevelIn: number;
  rxLevelOut: number;
  txNoiseIn: number;
  txNoiseOut: number;
  rxNoiseIn: number;
  rxNoiseOut: number;
  txPeakIn: number;
  txPeakOut: number;
  rxPeakIn: number;
  rxPeakOut: number;
  txVolume: number;
  rxVolume: number;
  sideToneVolume: number;
  lineType: string;
  type: string;
}

const BATCH_TIMER = 15000;

const activeLimitersMap = new Map([
  [0, [""]],
  [1, ["G616"]],
  [2, ["Anti-Startle Level"]],
  [3, ["G616", "Anti-Startle Level"]],
  [4, ["Anti-Startle Duration"]],
  [5, ["G616", "Anti-Startle Duration"]],
  [6, ["Anti-Startle Duration", "Anti-Startle Level"]],
  [7, ["G616", "Anti-Startle Duration", "Anti-Startle Level"]],
  [8, ["Anti-Startle Delta"]],
  [9, ["G616", "Anti-Startle Delta"]],
  [10, ["Anti-Startle Delta", "Anti-Startle Level"]],
  [11, ["G616", "Anti-Startle Delta", "Anti-Startle Level"]],
  [12, ["Anti-Startle Delta", "Anti-Startle Duration"]],
  [13, ["G616", "Anti-Startle Delta", "Anti-Startle Duration"]],
  [14, ["Anti-Startle Level", "Anti-Startle Duration", "Anti-Startle Delta"]],
  [
    15,
    [
      "G616",
      "Anti-Startle Level",
      "Anti-Startle Duration",
      "Anti-Startle Delta",
    ],
  ],
]);

@Injectable({
  providedIn: "root",
})
export class AcousticEventsService {
  private reportingFlags: AERSSetting[] = [
    { name: "LinkQualityReporting", value: false },
    { name: "TWAReporting", value: false },
    { name: "AcousticIncidentReporting", value: false },
    { name: "ConversationDynamicsReporting", value: false },
  ];

  private acousticIncident$ = new Subject<AcousticTelemetryMessage>();
  private twa$ = new Subject<AcousticTelemetryMessage>();
  private linkQuality$ = new Subject<AcousticTelemetryMessage>();
  private conversationAnalytics$ = new Subject<AcousticTelemetryMessage>();

  private acousticIncidentBatch: { [key: string]: AcousticIncidentData[] } = {};
  private twaBatch: { [key: string]: TwaData[] } = {};
  private linkQualityBatch: { [key: string]: LinkQualityData[] } = {};
  private conversationAnalyticsBatch: {
    [key: string]: ConversationAnalyticsData[];
  } = {};

  constructor(
    private nativeService: NativeService,
    private deviceManager: DeviceManagerService,
    private logger: ILoggingService,
    private stateService: StateService
  ) {
    this.logger.info("AERS init");
    // TODO: Enabling AERS reporting should come from cloud
    // Can be enabled for every connected device from secret
    const featureKey: keyof Features = "acousticEvents";
    combineLatest([
      this.stateService.getDeepState$<boolean>("Features", featureKey, false),
      this.deviceManager.getConnectedDevices(),
    ]).subscribe(([acousticEventsFlag, devices]) => {
      this.reportingFlags = [
        { name: "TWAReporting", value: acousticEventsFlag },
        { name: "AcousticIncidentReporting", value: acousticEventsFlag },
        { name: "LinkQualityReporting", value: false }, // TODO: disabled until Cloud support for this message
        { name: "ConversationDynamicsReporting", value: false }, // TODO: disabled until Cloud support for this message
      ];

      devices.forEach((device) =>
        this.nativeService
          .getApi()
          .getDeviceSettingsManager()
          .setAERSSettings(device.id, this.reportingFlags)
      );
    });

    // Listen to Link Quality Events
    this.nativeService
      .getApi()
      .getEventsManager()
      .getLinkQualityEvents()
      .subscribe((linkQualityEvent: LinkQualityEvent) => {
        this.logger.info(
          `AERS Link Quality event, device id: ${linkQualityEvent.deviceId}`
        );
        // Prepare data in acceptable format for telemetry message
        const linkQualityData: LinkQualityData = {
          incidentTime: linkQualityEvent.eventTime,
          callId: linkQualityEvent.callId,
          linkQualityVersion: linkQualityEvent.linkQualityVersion,
          periodicity: linkQualityEvent.periodicity,
          RSSI: linkQualityEvent.periodicity,
          LFOM: linkQualityEvent.LFOM,
          rawLinkQuality: linkQualityEvent.rawLinkQuality,
          type: linkQualityEvent.type,
        };
        // Add event to batch
        if (!this.linkQualityBatch.hasOwnProperty(linkQualityEvent.deviceId)) {
          this.linkQualityBatch[linkQualityEvent.deviceId] = [];
        }
        this.linkQualityBatch[linkQualityEvent.deviceId].push(linkQualityData);
      });

    // Listen to TWA Events
    this.nativeService
      .getApi()
      .getEventsManager()
      .getTwaEvents()
      .subscribe((twaEvent: TwaEvent) => {
        this.logger.info(`AERS TWA event, device id: ${twaEvent.deviceId}`);
        // Prepare data in acceptable format for telemetry message
        const twaData: TwaData = {
          incidentTime: twaEvent.eventTime,
          callId: twaEvent.callId,
          duration: twaEvent.twaDuration,
          durationthreshold: twaEvent.twaThreshold,
          periodicity: twaEvent.periodicity,
          audiolevelbeforelimiting: twaEvent.preLimiterSplEstimate,
          audiolevelafterlimiting: twaEvent.postLimiterSplEstimate,
          lineType: twaEvent.lineType,
        };
        // Add event to batch
        if (!this.twaBatch.hasOwnProperty(twaEvent.deviceId)) {
          this.twaBatch[twaEvent.deviceId] = [];
        }
        this.twaBatch[twaEvent.deviceId].push(twaData);
      });

    // Listen to Acoustinc Incident Events
    this.nativeService
      .getApi()
      .getEventsManager()
      .getAcousticIncident()
      .subscribe((acousticIncident: AcousticIncident) => {
        this.logger.info(
          `AERS Acoustic Incident event, device id: ${acousticIncident.deviceId}`
        );
        // Prepare data in acceptable format for telemetry message
        const acousticIncidentData: AcousticIncidentData = {
          incidentTime: acousticIncident.eventTime,
          callId: acousticIncident.callId,
          gainthreshold: acousticIncident.gainThreshold,
          durationthreshold: acousticIncident.durationThreshold,
          limiterBitMask: acousticIncident.activeLimiters,
          activelimiters: this.getActiveLimiters(
            acousticIncident.activeLimiters
          ),
          incidentduration: acousticIncident.duration,
          audiolevelbeforelimiting: acousticIncident.preLimiterSplEstimate,
          audiolevelafterlimiting: acousticIncident.postLimiterSplEstimate,
        };
        // Add event to batch
        if (
          !this.acousticIncidentBatch.hasOwnProperty(acousticIncident.deviceId)
        ) {
          this.acousticIncidentBatch[acousticIncident.deviceId] = [];
        }
        this.acousticIncidentBatch[acousticIncident.deviceId].push(
          acousticIncidentData
        );
      });

    // Listen to Conversation Analytics Events
    this.nativeService
      .getApi()
      .getEventsManager()
      .getConversationAnalyticsEvents()
      .subscribe((conversationAnalytics: ConversationAnalyticsEvent) => {
        this.logger.info(
          `AERS Conversation Analytics event, device id: ${conversationAnalytics.deviceId}`
        );
        // Prepare data in acceptable format for telemetry message
        const conversationAnalyticsData: ConversationAnalyticsData = {
          incidentTime: conversationAnalytics.eventTime,
          callId: conversationAnalytics.callId,
          periodicity: conversationAnalytics.periodicity,
          farTalk: conversationAnalytics.farTalk,
          nearTalk: conversationAnalytics.nearTalk,
          overTalk: conversationAnalytics.overTalk,
          txLevelIn: conversationAnalytics.txLevelIn,
          txLevelOut: conversationAnalytics.txLevelOut,
          rxLevelIn: conversationAnalytics.rxLevelIn,
          rxLevelOut: conversationAnalytics.rxLevelOut,
          txNoiseIn: conversationAnalytics.txNoiseIn,
          txNoiseOut: conversationAnalytics.txNoiseOut,
          rxNoiseIn: conversationAnalytics.rxNoiseIn,
          rxNoiseOut: conversationAnalytics.rxNoiseOut,
          txPeakIn: conversationAnalytics.txPeakIn,
          txPeakOut: conversationAnalytics.txPeakOut,
          rxPeakIn: conversationAnalytics.rxPeakIn,
          rxPeakOut: conversationAnalytics.rxPeakOut,
          txVolume: conversationAnalytics.txVolume,
          rxVolume: conversationAnalytics.rxVolume,
          sideToneVolume: conversationAnalytics.sideToneVolume,
          lineType: conversationAnalytics.lineType,
          type: conversationAnalytics.type,
        };
        // Add event to batch
        if (
          !this.conversationAnalyticsBatch.hasOwnProperty(
            conversationAnalytics.deviceId
          )
        ) {
          this.conversationAnalyticsBatch[conversationAnalytics.deviceId] = [];
        }
        this.conversationAnalyticsBatch[conversationAnalytics.deviceId].push(
          conversationAnalyticsData
        );
      });

    // Periodically check batched data and prepare Telemetry message if batch is not empty
    timer(BATCH_TIMER, BATCH_TIMER).subscribe((t) => {
      // Check Link Quality batched events
      if (Object.keys(this.linkQualityBatch).length) {
        // For every device create telemetry message
        for (const [deviceId, batchedData] of Object.entries(
          this.linkQualityBatch
        )) {
          this.linkQuality$.next(
            this.createLinkQualityMessage(deviceId, batchedData)
          );
        }

        this.linkQualityBatch = {}; // clear the batch after sending event
      }

      // Check TWA batched events
      if (Object.keys(this.twaBatch).length) {
        // For every device create telemetry message
        for (const [deviceId, batchedData] of Object.entries(this.twaBatch)) {
          this.twa$.next(this.createTwaMessage(deviceId, batchedData));
        }

        this.twaBatch = {}; // clear the batch after sending event
      }

      // Check Acoustic Incident batched events
      if (Object.keys(this.acousticIncidentBatch).length) {
        // For every device create telemetry message
        for (const [deviceId, batchedData] of Object.entries(
          this.acousticIncidentBatch
        )) {
          this.acousticIncident$.next(
            this.createAcousticIncidentMessage(deviceId, batchedData)
          );
        }

        this.acousticIncidentBatch = {}; // clear the batch after sending event
      }

      // Check Conversation Analytics batched events
      if (Object.keys(this.conversationAnalyticsBatch).length) {
        // For every device create telemetry message
        for (const [deviceId, batchedData] of Object.entries(
          this.conversationAnalyticsBatch
        )) {
          this.conversationAnalytics$.next(
            this.createConversationAnalyticsMessage(deviceId, batchedData)
          );
        }

        this.acousticIncidentBatch = {}; // clear the batch after sending event
      }
    });
  }

  private getActiveLimiters(bitMask: number): string[] {
    return activeLimitersMap.get(bitMask);
  }

  public getAcousticIncidentMessages(): Observable<AcousticTelemetryMessage> {
    return this.acousticIncident$;
  }

  public getTwaMessages(): Observable<AcousticTelemetryMessage> {
    return this.twa$;
  }

  public getLinkQualityMessages(): Observable<AcousticTelemetryMessage> {
    return this.linkQuality$;
  }

  public getConversationAnalyticsMessages(): Observable<
    AcousticTelemetryMessage
  > {
    return this.conversationAnalytics$;
  }

  private createAcousticIncidentMessage(
    deviceid: string,
    batchedData: AcousticIncidentData[]
  ): AcousticTelemetryMessage {
    const uuid = UUID();
    return {
      frameId: uuid,
      name: "LENS_APP",
      appName: "OZ_APP",
      type: "telemetry",
      deviceid,
      payload: {
        attr: "hubv3",
        version: "0.0.1",
        value: {
          eventType: "DeviceInfo.AcousticIncident",
          eventTime: new Date().toJSON(),
          eventVersion: "1.0.0",
          eventdata: {
            batchedData,
          },
        },
      },
    };
  }

  private createTwaMessage(
    deviceid: string,
    batchedData: TwaData[]
  ): AcousticTelemetryMessage {
    const uuid = UUID();
    return {
      frameId: uuid,
      name: "LENS_APP",
      appName: "OZ_APP",
      type: "telemetry",
      deviceid,
      payload: {
        attr: "hubv3",
        version: "0.0.1",
        value: {
          eventType: "DeviceInfo.AcousticAggregates",
          eventTime: new Date().toJSON(),
          eventVersion: "1.0.0",
          eventdata: {
            batchedData,
          },
        },
      },
    };
  }

  private createLinkQualityMessage(
    deviceid: string,
    batchedData: LinkQualityData[]
  ): AcousticTelemetryMessage {
    const uuid = UUID();
    return {
      frameId: uuid,
      name: "LENS_APP",
      appName: "OZ_APP",
      type: "telemetry",
      deviceid,
      payload: {
        attr: "hubv3",
        version: "0.0.1",
        value: {
          eventType: "DeviceInfo.LinkQuality",
          eventTime: new Date().toJSON(),
          eventVersion: "1.0.0",
          eventdata: {
            batchedData,
          },
        },
      },
    };
  }

  private createConversationAnalyticsMessage(
    deviceid: string,
    batchedData: ConversationAnalyticsData[]
  ): AcousticTelemetryMessage {
    const uuid = UUID();
    return {
      frameId: uuid,
      name: "LENS_APP",
      appName: "OZ_APP",
      type: "telemetry",
      deviceid,
      payload: {
        attr: "hubv3",
        version: "0.0.1",
        value: {
          eventType: "DeviceInfo.ConversationAnalytics",
          eventTime: new Date().toJSON(),
          eventVersion: "1.0.0",
          eventdata: {
            batchedData,
          },
        },
      },
    };
  }
}
