import { Component, OnInit } from "@angular/core";
import { UtilityService } from "../../services/utility.service";

@Component({
  selector: "oz-bp-headset",
  templateUrl: "./headset.component.pug",
})
export class BPHeadsetComponent implements OnInit {
  cards = [
    {
      title: "HEADSETS.HEADBAND.TITLE",
      description: "HEADSETS.HEADBAND.DESCRIPTION",
      logo: "headband_adjustment",
      id: "headbandAdjustment",
    },
    {
      title: "HEADSETS.BOOM_SHAPE.TITLE",
      description: "HEADSETS.BOOM_SHAPE.DESCRIPTION",
      logo: "boom_shape",
      id: "boomShape",
    },
    {
      title: "HEADSETS.BOOM_PLACEMENT.TITLE",
      description: "HEADSETS.BOOM_PLACEMENT.DESCRIPTION",
      logo: "boom_placement",
      id: "boomPlacement",
    },
  ];

  constructor() {}

  ngOnInit(): void {
    UtilityService.scrollToTop("scrollable-content");
  }
}
