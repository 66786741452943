import { Utility } from "./Utility";
import { throttle } from "lodash";
import { ILoggingService } from "../app/services/logging.service";
import { TransferType, BaseTest } from "./BaseTest";

/**
 * Architectural considerations:
 * - Run multiple tests of increasing size.  Running a single large test (e.g. 500MB file)
 *   is not a real world use case, though it may get closer to the theoretical max of
 *   the pipeline.  Also consider Speedtest.net and speedof.me perform multiple
 *   tests even though XHR requests can be destroyed.
 *
 * Binary files can be generated using this script on a Mac / Linux command line:
 * for i in {7..19..1}; do size=$((2**$i)); echo "${size}k.bin"; dd if=/dev/random of=${size}k.bin count=$size bs=1024; done
 *
 * Then, this.options.sizes_kib looks like [128, 256, 512, ... 32768]
 */
export class DownloadTest extends BaseTest {

  public constructor(protected options: any,
                     protected logger: ILoggingService) {
    super(TransferType.DOWNLOAD, options, logger);
  }

  protected runTest(size: number) {
    this.download(size);
  }

  private download(size: number) {
    // initialize stream
    const streamKey = this.getNewStreamKey(size);
    const stream = (this.streams[streamKey] = []);
    this.streamsActive[streamKey] = true;
    this.streamsCompleted[streamKey] = false;

    const url = Utility.getDownloadUrl(size);
    const saveStatusThrottled = throttle(
      this.saveStatus,
      this.update_interval_ms,
      {
        leading: true,
        trailing: true,
      }
    );

    try {
      const xhr = new XMLHttpRequest();
      xhr.open("GET", url);
      this.requests[streamKey] = xhr;

      let start_stamp_ms = Utility.getStampMS();

      xhr.onprogress = (ev) => {
        saveStatusThrottled(stream, {
          transfer_type: this.transferType,
          start_stamp_ms: start_stamp_ms,
          size_bytes: ev.total,
          progress_bytes: ev.loaded
        });
      }

      xhr.onerror = (ev) => {
        console.log("XXX DownloadTest onerror ev: [" + JSON.stringify(ev, ["message", "arguments", "type", "name"]) + "], "
          + xhr.response.length + "]");
      }

      xhr.onloadend = (ev) => {
        setTimeout(() => {
          if (size > this.largest_completed_kib) {
            this.largest_completed_kib = size;
          }

          this.streamCleanup(streamKey);
        }, this.update_interval_ms * 1.2);
        this.streamCleanup(streamKey);
      }

      xhr.send();
    } catch (e) {
      this.logger.error("Download failure.", e);
      this.streamCleanup(streamKey);
    }
  }
}
