import { Injectable } from "@angular/core";
import { combineLatest, timer, Subscription, from, of, Observable } from "rxjs";
import { Toasts, Toast } from "../shared/components/toast/toasts.service";
import { DevicePID } from "../utils/constants";
import { DeviceManagerService, OzDevice } from "./device-manager.service";
import {
  NotificationsService,
  SingleNotification,
} from "./notifications.service";
import { Repository } from "./repository/repository.service";
import { UtilityService } from "./utility.service";
import { StorageService } from "./storage.service";
import { AuthService } from "./auth.service";
import { TranslateService } from "@ngx-translate/core";
import { distinctUntilChanged, take, map } from "rxjs/operators";
import _ from "lodash";
import { addManufacturerPrefix } from "../utils/device.utils";
import {
  TOAST_CLOSE_ACTION_TYPES,
  P21_DISPLAY_LINK_TOASTS,
  MS_IN_DAY,
} from "../utils/constants";
import { IsAppInstalledResponse } from "@poly/hub-native";

@Injectable({
  providedIn: "root",
})
export class DeviceNotificationService {
  private archiveUrls = {
    "displaylink-manager-windows": "",
    "displaylink-manager-mac": "",
  };

  private readonly p21toastIds: Map<string, string> = new Map();

  private userId: string;
  private showDisplayLinkToastSub: Subscription;

  constructor(
    private notificationService: NotificationsService,
    private translateService: TranslateService,
    private deviceManager: DeviceManagerService,
    private repo: Repository,
    private toasts: Toasts,
    private storage: StorageService,
    private auth: AuthService
  ) {
    // Check if DisplayLink Graphics software is installed (for P21)
    // TODO: UI: This native check is currently supported only on Windows
    const displayLinkInstalledObs: Observable<boolean> = UtilityService.isWindows()
      ? from(this.deviceManager.isAppInstalled("DisplayLink Graphics")).pipe(
          map((response: IsAppInstalledResponse) => response?.isInstalled)
        )
      : of(false);

    combineLatest([
      this.repo.getDisplayLink(UtilityService.isWindows() ? "win" : "mac"),
      displayLinkInstalledObs,
      this.deviceManager.getDevices(),
    ])
      .pipe(
        distinctUntilChanged(
          (
            [prevDisplayLink, prevDisplayLinkInstalled, prevDevices],
            [currDisplayLink, currDisplayLinkInstalled, currDevices]
          ) => {
            return (
              this.areDevicesChanged(prevDevices, currDevices) &&
              _.isEqual(prevDisplayLink, currDisplayLink) &&
              _.isEqual(prevDisplayLinkInstalled, currDisplayLinkInstalled)
            );
          }
        )
      )
      .subscribe(([displayLink, displayLinkInstalled, devices]) => {
        this.setSetupNotifications(devices);
        if (!displayLinkInstalled) {
          this.setP21Notifications(displayLink, devices);
        }
      });
  }

  private areDevicesChanged(
    previousDevices: OzDevice[],
    currentDevices: OzDevice[]
  ): boolean {
    const prevDevices = previousDevices.map((device) =>
      _.pick(device, ["id", "pid", "isConnected"])
    );
    const currDevices = currentDevices.map((device) =>
      _.pick(device, ["id", "pid", "isConnected"])
    );
    const differencePreviousFromCurrent = _.differenceWith(
      prevDevices,
      currDevices,
      _.isEqual
    );
    const differenceCurrentFromPrevious = _.differenceWith(
      currDevices,
      prevDevices,
      _.isEqual
    );
    return (
      !differencePreviousFromCurrent.length &&
      !differenceCurrentFromPrevious.length
    );
  }

  private setP21Notifications(displayLink, devices) {
    if (!!this.showDisplayLinkToastSub) {
      this.showDisplayLinkToastSub.unsubscribe();
    }
    const OS_DISPLAYLINK_PRODUCT_ID = "displaylink-manager-" + this.getOSKey();
    this.archiveUrls[OS_DISPLAYLINK_PRODUCT_ID] =
      displayLink.productBuild.archiveUrl;

    this.dismissDisconnectedP21Toasts(devices);
    const p21Devices = this.findConnectedP21Device(devices);
    if (p21Devices.length) {
      // Wait because of auth profile, first value is always NULL, possible second value
      this.showDisplayLinkToastSub = combineLatest([
        this.auth.profile$,
        timer(3000),
      ]).subscribe(([profile, timer]) => {
        this.userId = profile?.sub;
        p21Devices.forEach((device) => {
          if (this.showDisplayLinkToast(device)) {
            this.setToastNotification(device);
            this.setSystemNotification();
          }
        });
      });
    }
  }

  // Show notifications for devices that need setup wizard
  private setSetupNotifications(devices: OzDevice[]) {
    this.deviceManager.needOOBmap$.subscribe((needOOBmap) => {
      devices.forEach((device) => {
        if (
          device.isConnected &&
          needOOBmap.has(device.id) &&
          needOOBmap.get(device.id)
        ) {
          this.notificationService.singleNotificationUntranslated({
            title: "GENERAL.POLY_LENS",
            body: "NOTIFICATIONS.FIRST_TIME_CONNECTION.DESCRIPTION",
            translationParams: {
              deviceName: addManufacturerPrefix(device.displayName),
            },
          });
        }
      });
    });
  }

  private dismissDisconnectedP21Toasts(devices: OzDevice[]): void {
    const p21DisconnectedDevices = this.findDisconnectedP21Device(devices);
    p21DisconnectedDevices.forEach((device) => {
      const toastId = this.p21toastIds.get(device.uniqueId);
      if (toastId) {
        this.toasts.dismiss(toastId);
        this.p21toastIds.delete(device.uniqueId);
      }
    });
  }

  private findDisconnectedP21Device(devices: OzDevice[]): OzDevice[] {
    return devices.filter(
      (device) => !device.isConnected && this.isP21(device)
    );
  }

  private findConnectedP21Device(devices: OzDevice[]): OzDevice[] {
    return devices.filter((device) => device.isConnected && this.isP21(device));
  }

  private getOSKey(): string {
    return UtilityService.isWindows() ? "windows" : "mac";
  }

  private isP21(device: OzDevice): boolean {
    return device.pid === DevicePID.P21;
  }

  private setToastNotification(device: any): void {
    if (this.p21toastIds.has(device.uniqueId)) {
      return;
    }
    const toastId = this.toasts.push({
      type: "action",
      text: "NOTIFICATIONS.P21_DISPLAYLINK_TOAST.CONTENT",
      closeable: true,
      closeAction: TOAST_CLOSE_ACTION_TYPES.P21_DISPLAY_LINK,
      deviceUniqueId: device.uniqueId,
      actions: [
        {
          type: "primary",
          text: "NOTIFICATIONS.P21_DISPLAYLINK_TOAST.BUTTON_DOWNLOAD",
          dismissToast: false,
          action: () => {
            this.handleAction(
              {
                mac: "displaylink-manager-mac",
                windows: "displaylink-manager-windows",
              },
              "openUrl"
            );
          },
        },
        {
          type: "secondary",
          text: "NOTIFICATIONS.P21_DISPLAYLINK_TOAST.BUTTON_MORE_INFO",
          dismissToast: false,
          action: () => {
            this.handleAction(
              {
                both: "https://www.poly.com/p21",
              },
              "openUrl"
            );
          },
        },
      ],
    });
    this.p21toastIds.set(device.uniqueId, toastId);
  }

  private handleAction(path, action): void {
    if ("close" === action) {
      return;
    }

    if ("openUrl" === action) {
      this.openBrowserUrl(path);
    }
  }

  private openBrowserUrl(path): void {
    const url = this.findUrl(path);

    if (!url) {
      return;
    }

    UtilityService.openExternalBrowser(url);
  }

  private findUrl(path): string {
    const osKey = this.getOSKey();
    let url = path.both || path[osKey];

    if (!url) {
      console.error(
        "Device notification action missing valid url for os " + osKey,
        path
      );
    }

    // Check for keyed urls (populated from graphql query)
    if (this.archiveUrls.hasOwnProperty(url)) {
      if (!this.archiveUrls[url]) {
        console.error("Device archive URL not properly set for os " + osKey);
      }

      url = this.archiveUrls[url];
    }

    return url;
  }

  private setSystemNotification(): void {
    this.translateService
      .stream([
        "NOTIFICATIONS.P21_DISPLAYLINK_OS.TITLE",
        "NOTIFICATIONS.P21_DISPLAYLINK_OS.CONTENT",
        "NOTIFICATIONS.P21_DISPLAYLINK_OS.BUTTON",
      ])
      .pipe(take(1))
      .subscribe((translations) => {
        const singleNotification: SingleNotification = {
          title: translations["NOTIFICATIONS.P21_DISPLAYLINK_OS.TITLE"],
          body: translations["NOTIFICATIONS.P21_DISPLAYLINK_OS.CONTENT"],
          actions: [
            {
              type: "button",
              text: translations["NOTIFICATIONS.P21_DISPLAYLINK_OS.BUTTON"],
            },
          ],
        };
        this.notificationService.singleNotification(singleNotification);
      });
  }

  onDisplayLinkToastClose(toast: Toast) {
    // check closed toasts in storage
    const currentStorageToasts = this.storage.getItem(P21_DISPLAY_LINK_TOASTS);
    let storageToasts = !!currentStorageToasts ? currentStorageToasts : {};

    // set time when toast is closed - for logged user
    if (!!this.userId) {
      storageToasts[toast.deviceUniqueId] = {
        ...storageToasts[toast.deviceUniqueId],
        [this.userId]: Date.now(),
      };
      this.storage.setItem(P21_DISPLAY_LINK_TOASTS, storageToasts);
    }
  }

  private showDisplayLinkToast(device: OzDevice) {
    // Check if there are closed toasts in the storage, show toast accordingly
    const currentStorageToasts = this.storage.getItem(P21_DISPLAY_LINK_TOASTS);
    if (
      !!this.userId &&
      !!currentStorageToasts &&
      currentStorageToasts[device.uniqueId]?.hasOwnProperty(this.userId)
    ) {
      // Calc if the toast is closed 7 or more days from now
      const daysDiff =
        Math.abs(
          +new Date() -
            +new Date(
              currentStorageToasts[device.uniqueId][this.userId] as string
            )
        ) / MS_IN_DAY;
      return daysDiff >= 7;
    }
    // In all other cases show toast
    return true;
  }
}
