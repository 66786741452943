import { Injectable } from "@angular/core";
import { BehaviorSubject, combineLatest, Observable } from "rxjs";
import { cloneDeep as _cloneDeep, random as _random } from "lodash";
import {
  DeviceInfoUpdateType,
  DeviceMessagingUtils,
} from "./device.messaging/utils/device.messaging.utils";
import {
  DeviceStatusEventData,
  DeviceStatusEventDataBuilder,
} from "./device.messaging/device.status.event.data.builder";
import { DeviceInfoStatePayloadBuilder } from "./device.messaging/device.info.state.payload.builder";
import { DeviceInfoUpdatePayloadBuilder } from "./device.messaging/device.info.update.payload.builder";
import {
  DeviceInfoStatePayload,
  DeviceInfoUpdateEventData,
  DeviceInfoUpdatePayload,
} from "./device.messaging/utils/device.message.json.subobjects";
import { ILoggingService } from "./logging.service";
import { StorageService } from "./storage.service";
import { AuthProfile, AuthService } from "./auth.service";
import {
  BAD_DEVICE_IDS,
  IS_AUTHENTICATED,
  LAST_DEVICE_INFO_STATE_SENDING,
  MAX_TEST_RUNNER_DEVICE_INFO_STATE_CALLS_BY_JOB,
  MS_IN_DAY,
  MS_IN_MINUTE,
  MS_IN_SECOND,
  POLY_LENS_DESKTOP, POLY_LENS_PWA,
} from "../utils/constants";
import { UtilityService } from "./utility.service";
import { OzDevice } from "./device-manager.service";
import { Dfu, OzDeviceWithRepositoryFirmware } from "./dfu.service";
import { WindowsEventsService } from "./windows-events.service";
import { first } from "rxjs/operators";

@Injectable({
  providedIn: "root",
})
export class DeviceEventService {
  private deviceStatusMessageBuilder: DeviceStatusEventDataBuilder;
  private deviceInfoStatePayloadBuilder: DeviceInfoStatePayloadBuilder;
  private deviceInfoUpdatePayloadBuilder: DeviceInfoUpdatePayloadBuilder;
  private deviceMessagingUtils: DeviceMessagingUtils;

  private savedDevicesMap: Map<string, OzDevice> = null;
  private lensAppId: string;
  private profile: AuthProfile;
  private cachedProfile: AuthProfile;
  private isAuthenticatedOrLoginless: boolean;
  private profileResolvingTimeoutMS = 5 * MS_IN_SECOND;
  private processAndSendDeviceInfoStateCallsByJob = 0;
  private needDeviceInfoState: boolean = true;
  public userInfoFlag: string;

  private deviceStatusEvent: BehaviorSubject<
    DeviceStatusEventData
  > = new BehaviorSubject(null);
  private deviceInfoStateEvent: BehaviorSubject<
    DeviceInfoStatePayload
  > = new BehaviorSubject(null);
  private deviceInfoUpdateEvent: BehaviorSubject<
    DeviceInfoUpdatePayload
  > = new BehaviorSubject(null);
  private reRegisterLensEvent: BehaviorSubject<null> = new BehaviorSubject(
    null
  );
  private sendDeviceInfoStateTimeout;

  constructor(
    private logger: ILoggingService,
    private storageService: StorageService,
    private authService: AuthService,
    private dfu: Dfu,
    private windowsEventsService: WindowsEventsService
  ) {
    this.deviceMessagingUtils = new DeviceMessagingUtils();
    this.deviceStatusMessageBuilder = new DeviceStatusEventDataBuilder();
    this.deviceInfoStatePayloadBuilder = new DeviceInfoStatePayloadBuilder(
      this.deviceMessagingUtils
    );
    this.deviceInfoUpdatePayloadBuilder = new DeviceInfoUpdatePayloadBuilder(
      this.deviceMessagingUtils
    );
    this.isAuthenticatedOrLoginless = this.storageService.getItem(
      IS_AUTHENTICATED
    );
  }

  public setLensAppId(lensAppId: string) {
    this.lensAppId = lensAppId;
  }

  // Guaranteed to return an array, even if this.savedDevicesMap is null
  get savedDevicesAsArray(): OzDevice[] {
    return !this.savedDevicesMap
      ? []
      : Array.from(this.savedDevicesMap.values());
  }

  public setCloudConnection(connected: boolean): void {
    if (!connected) {
      this.needDeviceInfoState = true;
    }
  }

  public getDeviceStatusEvent(): Observable<DeviceStatusEventData> {
    return this.deviceStatusEvent.asObservable();
  }

  public getDeviceInfoStateEvent(): Observable<DeviceInfoStatePayload> {
    return this.deviceInfoStateEvent.asObservable();
  }

  public getDeviceInfoUpdateEvent(): Observable<DeviceInfoUpdatePayload> {
    return this.deviceInfoUpdateEvent.asObservable();
  }

  public getReRegisterLensEvent(): Observable<null> {
    return this.reRegisterLensEvent.asObservable();
  }

  public async initDevicesConnectionSubscription(lensAppId: string) {
    this.lensAppId = lensAppId;
    this.dfu.allDevices().subscribe((devices) => {
      this.processForDeviceEvents(devices);
    });

    this.authService.profile$.pipe().subscribe((profile) => {
      this.profile = profile;
      if (profile) {
        this.cachedProfile = profile;
      }
    });
    // Check if authentication is changed (either via manual login/logout, or via automatic login with loginless) and process/report device state accordingly
    // In order for device reporting to work seamlessly for loginless we should wait until devices are picked up for the first time and savedDevicesMap initialized
    combineLatest([
      this.authService.isAuthenticated$,
      this.authService.isLoginless$,
      this.dfu.allDevices().pipe(first()),
    ]).subscribe(([authenticated, isLoginless]) => {
      const isAuthenticatedOrLoginless = authenticated || isLoginless;
      if (this.isAuthenticatedOrLoginless != isAuthenticatedOrLoginless) {
        this.isAuthenticatedOrLoginless = isAuthenticatedOrLoginless;
        this.processAndSendDeviceInfoState();
        if (isAuthenticatedOrLoginless) this.reRegisterLensEvent.next(null);
        this.processAndSendDeviceStatus(
          this.generateLensDeviceStatus(this.isAuthenticatedOrLoginless)
        );
      }
      this.storageService.setItem(IS_AUTHENTICATED, authenticated);
    });

    this.windowsEventsService.onWindowsEvent$.subscribe((windowsEvent) => {
      if (this.isAuthenticatedOrLoginless && windowsEvent === "unlock-screen") {
        this.processAndSendDeviceInfoState();
      }

      if (this.isUpdateOfDeviceInfoStateNeed(windowsEvent)) {
        this.processAndSendDeviceInfoState();
        this.needDeviceInfoState = false;
      }
    });

    this.scheduleSendingDeviceInfoState();
  }

  private isUpdateOfDeviceInfoStateNeed(windowsEvent: string): boolean {
    return (
      this.isAuthenticatedOrLoginless &&
      windowsEvent === "focus" &&
      this.needDeviceInfoState
    );
  }

  public onDeviceRemoved(device: OzDevice) {
    let parentUniqueId = this.deviceMessagingUtils.getParentUniqueId(
      device,
      this.savedDevicesAsArray,
      this.lensAppId
    );
    this.processAndSendDeviceInfoUpdate(
      device,
      parentUniqueId,
      DeviceInfoUpdateType.REMOVED
    );
    this.savedDevicesMap?.delete(device.uniqueId);
    this.logger.info(
      `Sending DeviceInfoUpdate REMOVED event for "${
        device.displayName
      }" (PID=${device.pid.toString(16)}, uniqueId=${device.uniqueId})`
    );
  }

  public sendDeviceInfoUpdate(
    payload: Partial<DeviceInfoUpdateEventData>,
    updateType?: DeviceInfoUpdateType
  ) {
    const resultData: DeviceInfoUpdateEventData = Object.assign(
      {
        deviceId: this.lensAppId,
        updateType: updateType ?? DeviceInfoUpdateType.CHANGED,
      },
      payload
    );

    const deviceInfoPayload: DeviceInfoUpdatePayload = DeviceInfoUpdatePayloadBuilder.generateFullDeviceInfoStatePayload(
      resultData
    );
    this.deviceInfoUpdateEvent.next(deviceInfoPayload);
  }

  public scheduleSendingDeviceInfoState() {
    const lastPerforming: number = this.storageService.getItem(
      LAST_DEVICE_INFO_STATE_SENDING
    );
    const currentTime = new Date().getTime();

    /* if last execution was more then 24h ago, run the task immediately: */
    if (!lastPerforming || currentTime - lastPerforming >= MS_IN_DAY) {
      /* if profile is not resolved yet, wait for 5 seconds: */
      if (!this.profile) {
        setTimeout(
          () => this.processAndSendDeviceInfoState(),
          this.profileResolvingTimeoutMS
        );
      } else {
        this.processAndSendDeviceInfoState();
      }
    }

    /* also, schedule task at random time in the next 24h: */
    // Previously, this method used node-schedule to schedule this task to run at a random time of day,
    // every day, and thus had node launch a renderer-side function; this was overly complex,
    // and required a 1.1MB minified JS bundle to do so, thus simplified
    // start job anytime between one minute and the next 24 hours; job will repeat on its own
    // sendDeviceInfoStateTimeout is an integer that could be 0
    const timeoutNotInitialized =
      !this.sendDeviceInfoStateTimeout && 0 !== this.sendDeviceInfoStateTimeout;

    if (timeoutNotInitialized) {
      const firstRunMS = _random(MS_IN_MINUTE, MS_IN_DAY, false);
      const firstRunTimeString = new Date(Date.now() + firstRunMS).toString();

      this.sendDeviceInfoStateTimeout = setTimeout(() => {
        this.executeSendDeviceInfoStateJob();
      }, firstRunMS);

      this.logger.info(
        "Sending of DeviceInfoState scheduled starting " +
          firstRunTimeString +
          " with 24 hour intervals."
      );
    }
  }

  /**
   * Process and send device info state now.  Will reschedule itself for 24 hours from now.
   */
  private executeSendDeviceInfoStateJob() {
    // this setTimeout may not be necessary, but is added to be more consistent with original intent, which delayed between 0-999ms
    setTimeout(() => {
      this.processAndSendDeviceInfoState();
      this.processAndSendDeviceInfoStateCallsByJob++;
    }, 0);

    // assist test runs by not calling for more than 10 days
    if (
      UtilityService.isInTestRunner() &&
      this.processAndSendDeviceInfoStateCallsByJob >
        MAX_TEST_RUNNER_DEVICE_INFO_STATE_CALLS_BY_JOB
    ) {
      return;
    }

    // always reschedule 24 hours from now, replace timeout ID
    // this is not inside the prior setTimeout to help prevent a bug from improper `this` binding
    this.sendDeviceInfoStateTimeout = setTimeout(() => {
      this.executeSendDeviceInfoStateJob();
    }, MS_IN_DAY);
  }

  private processForDeviceEvents(devices: OzDeviceWithRepositoryFirmware[]) {
    if (!this.savedDevicesMap) {
      this.initSavedDevicesMap(devices);
    } else {
      devices.forEach((device, i) => {
        const savedDevice: OzDevice = this.savedDevicesMap.get(device.uniqueId);
        let doUpdateMap = false;
        let parentUniqueId = this.deviceMessagingUtils.getParentUniqueId(
          device,
          devices,
          this.lensAppId
        );
        /* New device detected, send DeviceInfoAdded: */
        if (!savedDevice) {
          this.processAndSendDeviceInfoUpdate(
            device,
            parentUniqueId,
            DeviceInfoUpdateType.ADDED
          );
          this.logger.info(
            `Sending DeviceInfoUpdate ADDED event for "${
              device.displayName
            }" (PID=${device.pid.toString(16)}, uniqueId=${device.uniqueId})`
          );
          // Also need to send status event to update connection field
          this.processAndSendDeviceStatus(device);
          doUpdateMap = true;
        } else {
          /* Check for sending DeviceStatus event: */
          if (
            (savedDevice && savedDevice.isConnected !== device.isConnected) ||
            savedDevice.isMuted !== device.isMuted ||
            (device.battery &&
              (!savedDevice.battery ||
                savedDevice.battery.level !== device.battery.level)) ||
            (device.battery &&
              (!savedDevice.battery ||
                savedDevice.battery.charging !== device.battery.charging))
          ) {
            this.logger.info(
              `Sending DeviceStatus event for "${
                device.displayName
              }" (PID=${device.pid.toString(16)})`
            );
            this.processAndSendDeviceStatus(device);
            doUpdateMap = true;
          }
          /* Check for sending DeviceInfoUpdate CHANGED event: */
          try {
            if (
              this.deviceInfoUpdatePayloadBuilder.arePayloadsDifferent(
                savedDevice,
                device
              )
            ) {
              this.logger.info(
                `Sending DeviceInfoUpdate CHANGED event for "${
                  device.displayName
                }" (PID=${device.pid.toString(16)}, uniqueId=${
                  device.uniqueId
                })`
              );
              this.processAndSendDeviceInfoUpdate(
                device,
                parentUniqueId,
                DeviceInfoUpdateType.CHANGED
              );
              doUpdateMap = true;
            }
          } catch (e) {
            this.logger.error(e, e);
          }
        }
        if (doUpdateMap) {
          this.savedDevicesMap.set(device.uniqueId, _cloneDeep(device));
        }
      });
    }
  }

  public processAndSendDeviceInfoState() {
    this.authService.profile$.pipe().subscribe((profile) => {
      this.profile = profile;
    });
    let profile: AuthProfile = this.isAuthenticatedOrLoginless
      ? this.profile
      : this.cachedProfile;
    const devices = this.savedDevicesAsArray.filter(
      (d) =>
        d &&
        d.uniqueId &&
        d.uniqueId.trim().length > 0 &&
        !BAD_DEVICE_IDS.includes(d.uniqueId.trim())
    );
    this.deviceInfoStatePayloadBuilder.userInfoFlag = this.userInfoFlag;
    let deviceInfoStatePayload = this.deviceInfoStatePayloadBuilder.translateToDeviceInfoStatePayload(
      devices,
      this.lensAppId,
      this.isAuthenticatedOrLoginless,
      profile
    );
    this.logger.info("Sending deviceInfoState for Lens app ", this.lensAppId);
    this.logger.debug(`deviceInfoState`, deviceInfoStatePayload);

    this.deviceInfoStateEvent.next(deviceInfoStatePayload);

    // Send deviceStatus for connected devices when a state message is sent
    // If the app has been open for a long time, a disconnect message triggers all devices to show as disconnected
    devices.forEach((device) => {
      device.isConnected ? this.processAndSendDeviceStatus(device) : device;
    });

    this.storageService.setItem(
      LAST_DEVICE_INFO_STATE_SENDING,
      new Date().getTime()
    );
  }

  private processAndSendDeviceInfoUpdate(
    device: OzDeviceWithRepositoryFirmware,
    parentUniqueId: string,
    updateType: string
  ) {
    if (
      !device ||
      !device.uniqueId ||
      device.uniqueId.trim().length == 0 ||
      BAD_DEVICE_IDS.includes(device.uniqueId.trim())
    )
      return;
    let deviceInfoUpdatePayload = this.deviceInfoUpdatePayloadBuilder.translateToDeviceInfoUpdatePayload(
      device,
      parentUniqueId,
      updateType,
      this.lensAppId
    );
    this.deviceInfoUpdateEvent.next(deviceInfoUpdatePayload);
  }

  private processAndSendDeviceStatus(
    device: OzDeviceWithRepositoryFirmware | LensDeviceStatus
  ) {
    if (
      !device ||
      !device.uniqueId ||
      device.uniqueId.trim().length == 0 ||
      BAD_DEVICE_IDS.includes(device.uniqueId.trim())
    )
      return;
    let deviceStatusEventData = this.deviceStatusMessageBuilder.translateToDeviceStatusEventData(
      device
    );
    this.deviceStatusEvent.next(deviceStatusEventData);
  }

  private initSavedDevicesMap(devices: OzDevice[]) {
    this.savedDevicesMap = new Map();
    devices.forEach((device) => {
      this.savedDevicesMap.set(device.uniqueId, _cloneDeep(device));
    });
  }

  private generateLensDeviceStatus(connected: boolean): LensDeviceStatus {
    return {
      uniqueId: this.lensAppId,
      displayName: POLY_LENS_PWA,
      isMuted: null,
      isConnected: connected,
      battery: null,
    };
  }
}

export type LensDeviceStatus = {
  uniqueId: string;
  displayName: string;
  isMuted: boolean;
  isConnected: boolean;
  battery: null;
};
