import { Component, OnInit } from "@angular/core";
import { UtilityService } from "./../../../services/utility.service";
import { EULA_URL, PRIVACY_POLICY_URL } from "./../../../utils/constants";

@Component({
  selector: "oz-lens-settings-about",
  templateUrl: "./lens-settings-about.component.pug",
})
export class LensSettingsAboutComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}

  openPrivacyPolicy() {
    UtilityService.openExternalBrowser(PRIVACY_POLICY_URL);
  }
  openEula() {
    UtilityService.openExternalBrowser(EULA_URL);
  }
}
