import {
  LoggingService,
  ILoggingService,
} from "./../../../services/logging.service";
import { TenantService } from "../../../services/tenant.service";
import {
  AccessorService,
  UserInvite,
} from "../../../services/accessor.service";
import { Component, OnInit, Input, OnDestroy } from "@angular/core";
import { AuthService } from "../../../services/auth.service";
import { LensSettingsService } from "../../../services/lens-settings.service";
import { SettingsMetaData } from "../../lens-settings-ui.model";
import { Subscription } from "rxjs";
import { TranslateService } from "@ngx-translate/core";
import { AccordionItem } from "../../../shared/components/accordion/accordion.component";
import { UtilityService } from "../../../services/utility.service";
import {
  TERMS_OF_SERVICE,
  PRIVACY_POLICY_URL,
} from "./../../../utils/constants";
import { CloudRepository } from "src/app/services/repository/repository-cloud.service";
import { RestoreDefaultUtils } from "src/app/utils/restoreDefaultsUtils";

export interface LensSettingAccountMetadata {
  action: string;
  type: string;
  title: string;
  description: string;
}

@Component({
  selector: "oz-lens-settings-account",
  templateUrl: "./lens-settings-account.component.pug",
})
export class LensSettingsAccountComponent implements OnInit, OnDestroy {
  private isLoginlessSubscription: Subscription;
  private authSubscription: Subscription;
  private settingsSubscription: Subscription;
  private inviteSubscription: Subscription;
  showConfirmModal = false;

  @Input() set accordionData(value: AccordionItem) {
    this.setting = value.data;
  }

  setting: LensSettingAccountMetadata;
  accountSettings: SettingsMetaData;
  invites: UserInvite[];
  invitedByAdmin = "An IT Admin";

  isAuthed: boolean;
  isLoginless: boolean;

  constructor(
    public authService: AuthService,
    private lensSettingsService: LensSettingsService,
    private accessorService: AccessorService,
    private tenantService: TenantService,
    private translateService: TranslateService,
    private logger: ILoggingService,
    private cloudrepo: CloudRepository,
    private restoredefaultutils: RestoreDefaultUtils,
  ) {
    translateService
      .stream("SETTINGS.INVITED_BY_IT_ADMIN")
      .subscribe((trans) => {
        this.invitedByAdmin = trans;
      });
  }

  ngOnInit(): void {
    this.isLoginlessSubscription = this.authService.isLoginless$.subscribe(
      (isLoginless) => {
        this.isLoginless = isLoginless;
        if (this.isLoginless) {
          this.isAuthed = true;
        } else {
          this.authSubscription = this.authService.isAuthenticated$.subscribe(
            (authed) => {
              this.isAuthed = authed;
            }
          );

          this.inviteSubscription = this.accessorService.pendingInvites$.subscribe(
            (invites) => {
              this.invites = invites.map((invite) => ({
                ...invite,
                invited_by_email:
                  invite.invited_by_email || this.invitedByAdmin,
              }));
            }
          );
        }
      }
    );

    this.settingsSubscription = this.lensSettingsService.lensSettings.subscribe(
      (settings) => {
        this.accountSettings = { ...settings };
      }
    );
  }

  ngOnDestroy(): void {
    this.isLoginlessSubscription.unsubscribe();
    this.authSubscription?.unsubscribe();
    this.settingsSubscription.unsubscribe();
    this.inviteSubscription?.unsubscribe();
  }

  deleteUserAccount() {
    this.cloudrepo.executeDeleteUserAccount().subscribe(
      (response) => {
        console.log('Mutation result', response);
        
        this.restoredefaultutils.restoreDefaultAfterDeletion();
      },
      (error) => {
        console.error('Error executing mutation', error);
      }
    );
  }

  login() {
    this.authService.startLoginFlow("/home");
  }

  logout() {
    this.tenantService.setTenantId(null);
    this.tenantService.setTenantName(null);
    this.authService.startLogoutFlow("/home");
  }

  onValueChanged(ev: any, action: string) {
    this.accountSettings[action] = ev;
    this.lensSettingsService.setLensSettings(this.accountSettings);
  }

  decline(id: string) {
    this.accessorService.declineInvite(id).subscribe((result) => {
      this.logger.info("Declined invite: ", { id, result });
    });
  }

  accept(invite: UserInvite) {
    this.accessorService.acceptInvite(invite.id).subscribe((result) => {
      this.logger.info("Accepted invite: ", { invite, result });
      this.tenantService.setTenantId(invite.tenant_id);
      this.tenantService.setTenantName(invite.tenant_name);
    });
  }

  openTerms() {
    UtilityService.openExternalBrowser(TERMS_OF_SERVICE);
  }

  openPrivacy() {
    UtilityService.openExternalBrowser(PRIVACY_POLICY_URL);
  }

  onButtonClick() {
    this.showConfirmModal = true;
  }

}
