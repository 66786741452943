import { Component, OnInit, Input, NgZone } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import {
  HydrationAndVisionUtils,
  WellnessDaysFrequency,
} from "../../../utils/hydrationAndVisionUtils";
import { SettingsMetaData } from "../../../lens-settings/lens-settings-ui.model";
import { LensSettingsService } from "../../../services/lens-settings.service";
import { combineLatest } from "rxjs";
import { Subscriptions } from "../../../utils/subscriptions";

@Component({
  selector: "oz-reminder-schedule",
  templateUrl: "./reminder-schedule.component.pug",
})
export class ReminderScheduleComponent implements OnInit {
  @Input() action: "hydrationEnabled" | "visionEnabled";

  private translationStrings: Array<string>;
  private timeSpanOptions;
  private timeOptions;
  private dayOptions;
  private subs = new Subscriptions();

  public label: string = "";

  constructor(
    private translateService: TranslateService,
    private lensSettings: LensSettingsService,
    private hydrationAndVisionUtils: HydrationAndVisionUtils
  ) {
    this.translationStrings = [
      "HEALTH_AND_WELLNESS.REMINDER_SCHEDULE",
      "HEALTH_AND_WELLNESS.SET_REMINDER_FREQUENCY",
      "HEALTH_AND_WELLNESS.WEEKDAYS_SHORT",
      "HEALTH_AND_WELLNESS.EVERY_DAY",
      "HEALTH_AND_WELLNESS.CUSTOM",
      "SCHEDULE_OPTIONS.1 minute",
      "SCHEDULE_OPTIONS.30 minutes",
      "SCHEDULE_OPTIONS.1 hour",
      "SCHEDULE_OPTIONS.3 hours",
    ];

    this.dayOptions = hydrationAndVisionUtils.getDayOptions();
    this.timeOptions = hydrationAndVisionUtils.getTimeOptions();
    this.timeSpanOptions = hydrationAndVisionUtils.getTimeSpanOptions();

    this.dayOptions.forEach((option) =>
      this.translationStrings.push(option.shortText)
    );

    this.timeOptions.forEach((option) =>
      this.translationStrings.push(option.shortText)
    );
  }

  ngOnInit(): void {
    this.subs.add(
      combineLatest([
        this.lensSettings.lensSettings,
        this.translateService.stream(this.translationStrings),
      ]).subscribe(([settings, translations]) => {
        this.setFrequencyLabel(translations, settings);
      })
    );
  }

  setFrequencyLabel(translations: Array<string>, settings: SettingsMetaData) {
    if (!settings[this.action]) {
      this.label = translations["HEALTH_AND_WELLNESS.SET_REMINDER_FREQUENCY"];
    }

    const reminderSettings = this.hydrationAndVisionUtils.getReminderSettings(
      this.action,
      settings
    );

    const timeSpan = this.timeSpanOptions.find(
      (item) => item.value === reminderSettings.span
    );

    const timeFrom = this.timeOptions.find(
      (item) => item.value === reminderSettings.from
    );

    const timeTo = this.timeOptions.find(
      (item) => item.value === reminderSettings.to
    );

    if (
      !reminderSettings.days ||
      !reminderSettings.days.length ||
      !timeFrom ||
      !timeTo
    ) {
      this.label = translations["HEALTH_AND_WELLNESS.SET_REMINDER_FREQUENCY"];
    }

    const frequency = this.hydrationAndVisionUtils.convertDaysToFrequency(
      reminderSettings.days
    );

    let daysFrequency = "";

    switch (frequency) {
      case WellnessDaysFrequency.WEEKDAYS:
        daysFrequency = translations["HEALTH_AND_WELLNESS.WEEKDAYS_SHORT"];
        break;
      case WellnessDaysFrequency.EVERY_DAY:
        daysFrequency = translations["HEALTH_AND_WELLNESS.EVERY_DAY"];
        break;
      case WellnessDaysFrequency.CUSTOM:
        daysFrequency = reminderSettings.days
          .map((customDay) => {
            return translations[
              this.dayOptions.find((day) => day.value === customDay).shortText
            ];
          })
          .join(", ");
        break;
    }

    this.label = this.translateService.parser.interpolate(
      translations["HEALTH_AND_WELLNESS.REMINDER_SCHEDULE"],
      {
        timeSpan: translations[timeSpan.text],
        days: daysFrequency,
        startTime: translations[timeFrom.shortText],
        endTime: translations[timeTo.shortText],
      }
    );
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }
}
