import { Component, ElementRef, Input, ViewChild } from "@angular/core";

@Component({
  selector: "oz-progress-bar",
  templateUrl: "./progress-bar.component.pug",
})
export class ProgressBarComponent {
  @ViewChild("progress", { static: false }) progressRef: ElementRef;
  @Input() set percentage(value: number) {
    if (this.progressRef && this.progressRef.nativeElement) {
      this.progressRef.nativeElement.style.width = `${value}%`;
    }
  }
}
