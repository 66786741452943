import { Injectable } from "@angular/core";
import {
  FavoriteOptionsByDevice,
  FavoriteSelectedByDevice,
} from "../services/favorites.service";
import { HydrationAndVisionUtils } from "../utils/hydrationAndVisionUtils";
import {
  AccordionCategories,
  AccordionCategory,
  AccordionType,
} from "../shared/components/accordion/accordion.component";
import { AdminConfig } from "../services/admin-config.service";

export interface SettingsMetaData {
  productImprovement: boolean;
  anonymousData: boolean;
  enableNotifications: boolean;
  wellness: boolean;
  deviceAlerts: boolean;
  deviceUnpaired: boolean;
  lowBattery: boolean;
  deviceCharging: boolean;
  updateAvailable: boolean;
  networkConnect: boolean;
  softwareUpdate: boolean;
  deviceDisconnected: boolean;
  muteAlert: boolean;
  backgroundNoiseDetected: boolean;
  deviceSoftwareUpdate: boolean;
  automaticUpdates: boolean;
  favoriteSelectedByDevice: FavoriteSelectedByDevice;
  favoriteOptionsByDevice: FavoriteOptionsByDevice;
  soundscapeEnabled: boolean;
  soundscapeSound: string;
  soundscapeVolume: number;
  hydrationEnabled: boolean;
  hydrationDays: string[];
  hydrationTimeFrom: string;
  hydrationTimeTo: string;
  hydrationTimeSpan: string;
  visionEnabled: boolean;
  visionDays: string[];
  visionTimeFrom: string;
  visionTimeTo: string;
  visionTimeSpan: string;
  startOnSystemStartup: boolean;
  startMinimized: boolean;
  eulaAccepted: boolean;
}

/**
 * TODO Sure would be nice to have documentation here about what this class does.
 */
@Injectable({
  providedIn: "root",
})
export class LensSettingsUI {
  private hydrationReminderTimeSchedule = {
    days: [],
    timeFrom: [],
    timeTo: [],
    timespan: [],
  };

  private visionReminderTimeSchedule = {
    days: [],
    timeFrom: [],
    timeTo: [],
    timespan: [],
  };

  private accountCategory: AccordionCategory = {
    title: "Account",
    items: [
      {
        type: AccordionType.account,
        data: {
          action: "login",
          type: "button",
          title: "Your Account",
          description: "You are not currently logged in.",
        },
      },
    ],
  };

  private generalCategory: AccordionCategory = {
    title: "General",
    items: [
      {
        type: AccordionType.appSettings,
        data: {
          action: "startOnSystemStartup",
          type: "radio",
          title: "Launch Poly Lens on Startup",
          description: "Allow Poly Lens to start up automatically upon login.",
        },
      },
      {
        type: AccordionType.appSettings,
        data: {
          action: "startMinimized",
          type: "radio",
          title: "Start Minimized to System Tray",
        },
      },
      {
        type: AccordionType.appSettings,
        data: {
          action: "eulaAccepted",
          type: AccordionType.eulaAccepted,
          title: "EULA Accepted",
        },
      },
    ],
  };

  private softphoneCategory: AccordionCategory = {
    title: "Softphone",
    items: [
      {
        type: AccordionType.softphone,
        data: {
          action: "targetSoftphone",
          title: "Target Softphone",
          description:
            "Indicates the softphone that will be used for outgoing calls and dial tone (for supported " +
            "devices and softphone).",
        },
      },
      {
        type: AccordionType.softphone,
        data: {
          action: "table",
          title: "Softphones & Media Players | Plantronics Enabled",
        },
      },
    ],
  };

  private notificationsCategory = {
    title: "Notifications",
    items: [
      {
        type: AccordionType.notifications,
        data: {
          action: "enableNotifications",
          type: "radio",
          title: "Allow Desktop Notifications and Device Alerts",
        },
      },
      {
        type: AccordionType.notifications,
        data: {
          action: "wellness",
          type: "radio",
          title: "Wellbeing",
          description: "",
          visibleInPwa: true,
          options: [
            {
              action: "hydrationEnabled",
              type: "checkbox",
              title: "Hydration Reminders",
              schedule: this.hydrationReminderTimeSchedule,
            },
            {
              action: "visionEnabled",
              type: "checkbox",
              title: "Vision break reminder",
              schedule: this.visionReminderTimeSchedule,
            },
          ],
        },
      },
      {
        type: AccordionType.notifications,
        data: {
          action: "deviceAlerts",
          type: "radio",
          title: "Device Alerts",
          description: "",
          visibleInPwa: true,
          options: [
            {
              action: "deviceCharging",
              type: "radio",
              title: "SETTINGS.Notifications.deviceAlerts.deviceCharging",
              visibleInPwa: true,
            },
            {
              action: "deviceDisconnected",
              type: "radio",
              title: "SETTINGS.Notifications.deviceAlerts.deviceDisconnected",
              visibleInPwa: true,
            },
            {
              action: "muteAlert",
              type: "radio",
              title: "SETTINGS.Notifications.deviceAlerts.muteAlert",
              visibleInPwa: true,
            },
            {
              action: "deviceUnpaired",
              type: "radio",
              title: "SETTINGS.Notifications.deviceAlerts.deviceUnpaired",
              visibleInPwa: true,
            },
            {
              action: "lowBattery",
              type: "radio",
              title: "SETTINGS.Notifications.deviceAlerts.lowBattery",
              visibleInPwa: true,
            },
            {
              action: "deviceSoftwareUpdate",
              type: "radio",
              title: "SETTINGS.Notifications.deviceAlerts.deviceSoftwareUpdate",
              visibleInPwa: false,
            },
          ],
        },
      },
      {
        type: AccordionType.notifications,
        data: {
          action: "softwareUpdate",
          type: "radio",
          title: "Software Update",
          description: "",
          visibleInPwa: false,
        },
      },
    ],
  };

  private languageCategory: AccordionCategory = {
    title: "Language",
    items: [
      {
        type: AccordionType.language,
        data: { action: "languageUpdate", title: "Current Language" },
      },
    ],
  };

  private softwareUpdateCategory: AccordionCategory = {
    title: "Software Update",
    items: [
      {
        type: AccordionType.softwareUpdate,
        data: {
          action: "softwareUpdate",
          title: "Current Poly Lens Desktop Version",
        },
      },
    ],
  };

  private aboutCategory: AccordionCategory = {
    title: "About",
    items: [
      {
        type: AccordionType.about,
        data: {
          title: "About",
        },
      },
    ],
  };

  private categories: AccordionCategories;

  constructor(
    private hydrationAndVisionUtils: HydrationAndVisionUtils,
    private adminConfig: AdminConfig
  ) {
    this.categories = [];
    if (this.adminConfig.mode !== "network") {
      this.categories.push(this.accountCategory);
      if (this.adminConfig.mode !== "pwa") {
        this.categories.push(this.softphoneCategory);
      }
    }
    if (this.adminConfig.mode !== "pwa") {
      this.categories.push(this.generalCategory);
    }
    this.categories.push(this.notificationsCategory);
    this.categories.push(this.languageCategory);
    // TODO: product owner needs to decide how software version is displayed
    //if (this.adminConfig.mode !== "pwa") {
      this.categories.push(this.softwareUpdateCategory);
    //}
    this.categories.push(this.aboutCategory);
    this.setOptions();
  }

  setOptions() {
    const dayOptions = this.hydrationAndVisionUtils.getDayOptions();
    const timeOptions = this.hydrationAndVisionUtils.getTimeOptions();
    const timeSpanOptions = this.hydrationAndVisionUtils.getTimeSpanOptions();

    this.hydrationReminderTimeSchedule.days = dayOptions.map((opt) => ({
      ...opt,
      action: "hydrationDays",
    }));

    this.hydrationReminderTimeSchedule.timeFrom = timeOptions.map((opt) => ({
      ...opt,
      action: "hydrationTimeFrom",
    }));

    this.hydrationReminderTimeSchedule.timeTo = timeOptions.map((opt) => ({
      ...opt,
      action: "hydrationTimeTo",
    }));

    this.hydrationReminderTimeSchedule.timespan = timeSpanOptions.map(
      (opt) => ({
        ...opt,
        action: "hydrationTimeSpan",
      })
    );

    this.visionReminderTimeSchedule.days = dayOptions.map((opt) => ({
      ...opt,
      action: "visionDays",
    }));

    this.visionReminderTimeSchedule.timeFrom = timeOptions.map((opt) => ({
      ...opt,
      action: "visionTimeFrom",
    }));

    this.visionReminderTimeSchedule.timeTo = timeOptions.map((opt) => ({
      ...opt,
      action: "visionTimeTo",
    }));

    this.visionReminderTimeSchedule.timespan = timeSpanOptions.map((opt) => ({
      ...opt,
      action: "visionTimeSpan",
    }));

    this.categories.forEach((cat, catIndex) => {
      if (!cat.items) {
        return;
      }

      cat.items.forEach((item, itemIndex) => {
        if (!item.data?.options) {
          return;
        }

        item.data.options.forEach((opt, optIndex) => {
          const type = opt.action.includes("hydr")
            ? "hydrationReminderTimeSchedule"
            : "visionReminderTimeSchedule";
          this.categories[catIndex].items[itemIndex].data.options[
            optIndex
          ].schedule = this[type];
        });
      });
    });
  }

  getCategories() {
    return this.categories;
  }
}
