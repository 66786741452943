import { Injectable } from "@angular/core";
import { LOG } from '../../environments/environment';
import { inspect } from "util";
//import { datadogLogs } from "@datadog/browser-logs";
import { UtilityService } from "./utility.service";
import {
  appId,
  branch,
  build,
  commitId,
  productId,
  releaseChannel,
  version,
} from "../../../buildInfo.json";

export { LOG };

const MAX_LOG_LINES: number = 10000;

@Injectable({
  providedIn: "root",
})
export class ILoggingService {
  log(message: any, ...params: any[]) {}
  info(message: any, ...params: any[]) {}
  debug(message: any, ...params: any[]) {}
  warn(message: any, ...params: any[]) {}
  error(message: any, ...params: any[]) {}

  getAllLogs(): string {return ""}
}


export class LogWrapper extends ILoggingService {
  constructor(readonly logger: ILoggingService, private prefix: string) {
    super()
  }

  log(message: any, ...params: any[]) {
    this.logger.log(this.prefix + message, ...params)
  }

  info(message: any, ...params: any[]) {
    this.logger.info(this.prefix + message, ...params)
  }

  debug(message: any, ...params: any[]) {
    this.logger.debug(this.prefix + message, ...params)
  }

  warn(message: any, ...params: any[]) {
    this.logger.warn(this.prefix + message, ...params)
  }

  error(message: any, ...params: any[]) {
    this.logger.error(this.prefix + message, ...params)
  }
}


interface IQueue<T> {
  enqueue(item: T): void;
  dequeue(): T | undefined;
  size(): number;
}

class Queue<T> implements IQueue<T> {
  private storage: T[] = [];

  constructor(private capacity: number = Infinity) {}

  enqueue(item: T): void {
    if (this.size() === this.capacity) {
       this.dequeue();
    }
    this.storage.push(item);
  }
  dequeue(): T | undefined {
    return this.storage.shift();
  }
  size(): number {
    return this.storage.length;
  }

  toSingleString(): string {
    return this.storage.join("\n");
  }
}


@Injectable({
  providedIn: "root",
})
export class LoggingService {
  private logBuffer: Queue<string>;
  private tsFormatter = new Intl.DateTimeFormat('en', { hour12: false, hour: '2-digit', minute: '2-digit', second: '2-digit'})//, fractionalSecondDigits: '3' });
  private origInfo = console.info;
  private origError = console.error;
  private origDebug = console.debug;
  private origWarn = console.warn;
  private origTrace = console.trace;

  constructor() {
    // This is for turning on maximum logging in
    // third-party packages -- leave commented out so it can
    // used for future dev work
    // localStorage.debug = '*';
    this.logBuffer = new Queue<string>(MAX_LOG_LINES);

    console.error = (msg?: any, ...params: any[]) => this.origError.apply(console, [this.decorateAndStore('E', msg, ...params), ...params]);
    console.warn  = (msg?: any, ...params: any[]) => this.origWarn.apply(console,  [this.decorateAndStore('W', msg, ...params), ...params]);
    console.info  = (msg?: any, ...params: any[]) => this.origInfo.apply(console,  [this.decorateAndStore('I', msg, ...params), ...params]);
    console.debug = (msg?: any, ...params: any[]) => this.origDebug.apply(console, [this.decorateAndStore('D', msg, ...params), ...params]);
    console.trace = (msg?: any, ...params: any[]) => this.origTrace.apply(console, [this.decorateAndStore('T', msg, ...params), ...params]);
    console.log = console.info;

    this.log = this.info;
    this.logVersion();
  }

  log(message: string, ...params: any[]) {}

  info(message: string, ...params: any[]) {
    this.origInfo.apply(console, [this.decorateAndStore('I', message, ...params), ...params]);
  }

  debug(message: string, ...params: any[]) {
    // test runs are not the place to see debug console logging
    if (UtilityService.isInTestRunner()) {
      return;
    }

    this.origDebug.apply(console, [this.decorateAndStore('D', message, ...params), ...params]);
  }

  warn(message: string, ...params: any[]) {
    this.origWarn.apply(console, [this.decorateAndStore('W', message, ...params), ...params]);
  }

  error(message: string, ...params: any[]) {
    this.origError.apply(console, [this.decorateAndStore('E', message, ...params), ...params]);
  }

  private formatTimestamp(): string {
    const now = new Date();
    return this.tsFormatter.format(now) + '.' + now.getMilliseconds().toString().padStart(3, '0');
  }

  private decorateAndStore(type: string, msg: string, ...params: any[]): string {
    const loc = new Error('').stack?.split('\n')[3].replace(/.*\/(.*):[0-9)]*/, '$1');
    const m = `${this.formatTimestamp()} ${type} ${loc} ${msg}`;
    let s = ''
    for (let p of params) {
      s += '  ' + inspect(p)
    }
    this.logBuffer.enqueue(m + s);
    return m;
  }

  private logVersion() {
    this.info(`${new Date().toUTCString()} Poly Lens Web App ${build} ${commitId}`);
    this.info(`Browser version ${navigator.appVersion}`);
  }

  getAllLogs(): string {
    this.logVersion();
    return this.logBuffer.toSingleString();
  }
}
