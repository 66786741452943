import {NetworkTestResult, SpeedTestValue} from "../IPC/SpeedTest";
import {KIBIBYTE_PER_SECOND_PER_MEGABIT_PER_SECOND, Utility} from "./Utility";
import {Subject} from "rxjs";
import {ILoggingService} from "../app/services/logging.service";
import {get as _get, keys, pickBy} from "lodash";

interface IStatus {
  transfer_type: TransferType;

  // expected size of transfer
  size_bytes: number;
  // cumulative bytes for this transfer
  progress_bytes: number;
  start_stamp_ms: number;
  current_stamp_ms: number;
  // may be 0 on first status object, if time difference in ms may be 0
  upload_mbps?: number;
  download_mbps?: number;

  // 0 on first status object
  instant_mbps: number;
}

interface IStatusStreams {
  [key: string]: IStatus[];
}

interface IStreamResolve {
  streamKey: string;
  size: number;
}

export enum TransferType {
  UPLOAD,
  DOWNLOAD
}

export abstract class BaseTest {
  arbiter_interval: NodeJS.Timeout = setTimeout(()=>{}, 0);
  largest_completed_kib = 0;
  preflight_running = false;
  run_start_ms: number = -1;
  last_update_ms: number = 0;
  // max milliseconds between updating results observable
  max_update_results_interval_ms = 250;
  update_interval_ms = 300;
  requests: {
    [key: string]: XMLHttpRequest|AbortController;
  } = {};
  streams: IStatusStreams = {};
  streamsActive: {
    [key: string]: boolean;
  } = {};
  streamsCompleted: {
    [key: string]: boolean;
  } = {};
  results$: Subject<NetworkTestResult> = new Subject();

  public constructor(
    protected transferType: TransferType,
    protected options: any,
    protected logger: ILoggingService) {
    clearTimeout(this.arbiter_interval);
  }

  protected abstract runTest(size: number): void;

  public run$(): Subject<NetworkTestResult> {
    this.preflight().then(() => {
      this.resetTests();
      this.run_start_ms = Utility.getStampMS();
      this.arbiter_interval = setInterval(() => {
        this.arbiter();
      }, 100);
    });

    return this.results$;
  }

  private preflight() {
    return new Promise<void>((resolve) => {
      if (!this.options.preflight_ms) {
        // immediately resolve if no preflight test set up, or 0ms set
        resolve();

        return;
      }

      this.preflight_running = true;

      const median_index = Math.floor(this.options.sizes_kib.length / 2);
      const median_size_kib = this.options.sizes_kib[median_index];
      this.runTest(median_size_kib);
      setTimeout(() => {
        // end active stream
        this.forcefulEnd();
        // process the results
        // currentStreamValues would work, however since there is only one preflight stream
        // it is simpler to extract up/download_mbps from last stream value
        const firstStreamKey = Object.keys(this.streams)[0];
        const path = this.transferType == TransferType.UPLOAD ? "upload_mbps" : "download_mbps";
        const mbps = _get(
          this.streams[firstStreamKey].slice(-1)[0],
          path,
          0
        );

        if (mbps) {
          // for example, 133 Mbps would be 16,235 kibibytes per second
          const kibibytes_per_second =
            mbps * KIBIBYTE_PER_SECOND_PER_MEGABIT_PER_SECOND;

          // for simplicity, consider the largest completed KiB to be what the pipe can up/download per second
          this.largest_completed_kib = Math.ceil(kibibytes_per_second);

          // ensure largest_completed_kib is not larger than largest size
          const largest_available_kib_size = this.options.sizes_kib.slice(
            -1
          )[0];
          if (this.largest_completed_kib > largest_available_kib_size) {
            this.largest_completed_kib = largest_available_kib_size;
          }
        }

        this.preflight_running = false;
        resolve();
        // continue on as needed with "clean slate"
      }, this.options.preflight_ms);
    });
  }

  private resetTests() {
    // intentionally not resetting largest_completed_kib
    this.requests = {};
    this.streams = {};
    this.streamsActive = {};
    this.streamsCompleted = {};
  }

  private arbiter() {
    if (this.testPastTime()) {
      // do not call arbiter again
      clearInterval(this.arbiter_interval);

      // resolve with a final object based on final calculations
      this.updateResults(true);
      this.results$.complete();

      // forcefully end current streams; this is done after stream
      // analytics (currentStreamValues) run to avoid state issues
      // examining a stream while it is being destroyed
      this.forcefulEnd();

      return;
    }

    // for example, last_update_ms is 10234, now is 11000 and max_update_results_interval_ms is 500,
    // results$ last published update is too old; publish an update to the results$ observable
    if (
      this.last_update_ms <
      Utility.getStampMS() - this.max_update_results_interval_ms
    ) {
      this.updateResults(false);
    }

    this.addNewStreamIfPossible();
  }

  private updateResults(completed: boolean) {
    // do not report on preflight results
    if (this.preflight_running) {
      return;
    }

    this.last_update_ms = Utility.getStampMS();
    this.results$.next({
      completed,
      testConfig: this.options,
      values: this.currentStreamValues(),
    });
  }

  private streamCompleteTasks() {
    // test is still running, provide an update
    this.updateResults(false);

    this.addNewStreamIfPossible();
  }

  private addNewStreamIfPossible() {
    // do not add a new stream if test is past time or a preflight is running with one stream
    if (this.testPastTime() || this.preflight_running) {
      return;
    }

    const activeStreams = this.getActiveStreamKeys().length;

    if (activeStreams < this.options.simultaneous_streams) {
      // add a stream
      const nextSize = this.getNextSize();

      this.runTest(nextSize);
    }
  }

  private getNextSize(): number {
    const nextSizes = this.options.sizes_kib.filter(
      (size: number) => size > this.largest_completed_kib
    );

    const nextSize = nextSizes.length
      ? nextSizes[0]
      : this.options.sizes_kib.slice(-1)[0];

    return nextSize;
  }

  /**
   * Returns an array of active stream keys
   */
  protected getActiveStreamKeys(): string[] {
    return keys(pickBy(this.streamsActive));
  }

  private testPastTime(): boolean {
    const runningForMS = Utility.getStampMS() - this.run_start_ms;

    return runningForMS > this.options.runtime_ms;
  }

  private currentStreamValues(): SpeedTestValue[] {
    // get up/download_mbps from all streams with more than 1 record
    const lastStreamValues: IStatus[] = [];

    Object.keys(this.streams).forEach((streamKey) => {
      // first value returned is too early to be useful,
      // typically with 0 for up/download_mbps and instant_mbps
      if (this.streams[streamKey].length > 1) {
        // put last value onto array
        // for a finalized stream, this will be the accurate final tally of speed
        // for a non-finalized stream, this will be the last tally of speed
        lastStreamValues.push(this.streams[streamKey].slice(-1)[0]);
      }
    });

    // this reduce function takes all lastStreamValues and sums
    // the elapsed (current - start) time and the progress_bytes
    // this way, timing or gaps between streams does NOT matter
    const aggregated = lastStreamValues.reduce(
      (total, lastStreamValue) => {
        return {
          start_ms:
            total.start_ms && total.start_ms < lastStreamValue.start_stamp_ms
              ? total.start_ms
              : lastStreamValue.start_stamp_ms,
          end_ms:
            total.end_ms > lastStreamValue.current_stamp_ms
              ? total.end_ms
              : lastStreamValue.current_stamp_ms,
          progress_bytes: total.progress_bytes + lastStreamValue.progress_bytes,
        };
      },
      {start_ms: 0, end_ms: 0, progress_bytes: 0}
    );

    const aggregatedMbps = Utility.getMbps(
      aggregated.start_ms,
      aggregated.end_ms,
      aggregated.progress_bytes
    );

    return [
      {
        value: aggregatedMbps,
        unit: "Mbps",
        metric: "speed",
      },
    ];
  }

  // Note:
  //  saveStatus is not a method of BaseTest, it belongs to window(?)
  //  transfer_type needs to be passed in as part of statusPartial so we can set upload/download status
  saveStatus(
    stream: IStatus[],
    statusPartial: Omit<
      IStatus,
      "upload_mbps" | "download_mbps" | "current_stamp_ms" | "instant_mbps"
      >
  ) {
    const current_stamp_ms = Utility.getStampMS();
    let instant_mbps = 0;

    if (stream.length) {
      const lastStatus = stream.slice(-1)[0];
      // calculate instant mbps as diff between last status and current
      instant_mbps = Utility.getMbps(
        lastStatus.current_stamp_ms,
        current_stamp_ms,
        statusPartial.progress_bytes - lastStatus.progress_bytes
      );
    }

    const transfer_mbps = Utility.getMbps(
      statusPartial.start_stamp_ms,
      current_stamp_ms,
      statusPartial.progress_bytes);

    const status = {
      ...statusPartial,
      current_stamp_ms,
      download_mbps: statusPartial.transfer_type == TransferType.DOWNLOAD ? transfer_mbps : 0,
      upload_mbps: statusPartial.transfer_type == TransferType.UPLOAD ? transfer_mbps : 0,
      instant_mbps,
    };

    stream.push(status);
  }

  protected getNewStreamKey(size: number) {
    return `${size}_${Utility.getStampMS().toString()}`;
  }

  private forcefulEnd() {
    Object.keys(this.requests).forEach((streamKey) => {
      this.requests[streamKey].abort();
    });
  }

  protected streamCleanup(streamKey: string) {
    this.streamsActive[streamKey] = false;
    this.streamsCompleted[streamKey] = true;
    if (this.requests[streamKey]) {
      delete this.requests[streamKey];
    }

    this.streamCompleteTasks();
  }
}
