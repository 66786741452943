import { Injectable, NgZone } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { LocalNetworkValue} from "../../SpeedTest/LocalNetworkTest";
import { SpeedMetric } from "../shared/components/speed-indicator/speed-indicator.component";
import { DeviceEventService } from "./device.event.service";
import { DeviceInfoUpdateEventData } from "./device.messaging/utils/device.message.json.subobjects";
import { IpcService } from "./ipc.service";
import {ILoggingService} from "./logging.service";

interface TestConfig {
  type: "upload" | "download" | "ping";
  simultaneous_streams: number;
  runtime_ms: number;
  ping_timeout_ms: number;
  port: number;
  protocol: "tcp" | "udp";
  address: string;
  sizes_kib: Array<number>;
  sizes_udp_kib: Array<number>;
}

interface SpeedTestIPCMessage {
  complete: boolean;
  update: NetworkTestResults;
}

export interface SpeedTestValue {
  value: number;
  unit?: string;
  metric: "speed" | "jitter" | "ping" | "loss" | "min" | "max";
}

export interface NetworkTestResult {
  completed: boolean;
  testConfig: TestConfig;
  values: Array<SpeedTestValue | LocalNetworkValue>;
}

export interface NetworkTestResults {
  currentTestIndex: number | undefined;
  completed: boolean;
  results: Array<NetworkTestResult>;
}

export type TestResults = {
  ping: {
    ping: number;
    run: boolean;
    complete: boolean;
    jitter: number;
    loss: number;
    group: "latency";
  };
  download: {
    speed: number;
    run: boolean;
    complete: boolean;
    group: "transfer";
  };
  upload: {
    speed: number;
    run: boolean;
    complete: boolean;
    group: "transfer";
  };
  status: {
    started: boolean;
    completed: boolean;
  };
};

@Injectable({
  providedIn: "root",
})
export class NetworkSpeedTestService {
  constructor(
    private ipcService: IpcService,
    private devicesEventsService: DeviceEventService,
    private ngZone: NgZone,
    private logger: ILoggingService
  ) {}

  // TODO: Create i18n "map" for this word
  private labels = {
    upload: "Upload",
    download: "Download",
  };
  private readonly UNKNOWN_TEST_TYPE = "unknown";

  private _testResults$: BehaviorSubject<any> = new BehaviorSubject({});
  public testResults: TestResults = {
    ping: {
      ping: 0,
      run: false,
      complete: false,
      jitter: 0,
      loss: 0,
      group: "latency",
    },
    download: {
      speed: 0,
      run: false,
      complete: false,
      group: "transfer",
    },
    upload: {
      speed: 0,
      run: false,
      complete: false,
      group: "transfer",
    },
    status: {
      started: false,
      completed: false,
    },
  };

  public testExpanded = false;
  public testRunning = false;

  private devMode = {
    metric: "",
    stack: [],
    on: false,
    upload: false,
    download: false,
  };

  public series: { [key: string]: Array<SpeedMetric> } = {
    transfer: [],
    latency: [],
  };

  public values: { [key: string]: Array<number> } = {
    transfer: [],
    latency: [],
  };

  public getTestResults$(): Observable<any> {
    return this._testResults$.asObservable();
  }

  parseTestResults(results: NetworkTestResults) {
    if (results.completed) {
      this.testRunning = false;
      this.testResults.status.completed = true;
    } else {
      // if not completed, results.currentTestIndex should be set to an index value
      const currentTestType = this.getTestTypeFromNetworkTestResults(results);
      if (Object.keys(this.testResults).includes(currentTestType)) {
        this.testResults[currentTestType].run = true;
        this.testResults[currentTestType].complete = false;
      }
    }

    /**
     * .results is an array, type has to be determined from result.testConfig.type
     *
     * results[0].values:
     * [ {value: 40, unit: "ms", metric: "min"},
     *   {value: 55, unit: "ms", metric: "max"},
     *   {value: 48, unit: "ms", metric: "ping"},
     *   {value: 6, unit: "ms", metric: "jitter"},
     *   {value: 0, unit: "%", metric: "loss"}
     * ]
     *
     * results.download.values, results.upload.values:
     * [
     *   {value: 2141.0711080453484, unit: "Mbps", metric: "speed"}
     * ]
     */

    results.results.forEach((result) => {
      this.parseTestValues(result);
    });

    this._testResults$.next(this.testResults);

    if (results.completed) {
      this.prepAndSendDeviceInfoUpdate();
    }
  }

  private prepAndSendDeviceInfoUpdate() {
    const results = [];

    Object.keys(this.testResults).forEach((key) => {
      const result = this.testResults[key];

      if (result.run) {
        results.push({ ...result, type: key });
      }
    });

    const upload = results.find(
      (r) => "transfer" === r.group && "upload" === r.type
    );
    const download = results.find(
      (r) => "transfer" === r.group && "download" === r.type
    );
    const ping = results.find((r) => "latency" === r.group);

    const resultData: Partial<DeviceInfoUpdateEventData> = {
      metadata: {
        bandwidth: {
          endTime: new Date().toISOString(),
          downloadMbps: +download?.speed,
          uploadMbps: +upload?.speed,
          pingLatencyMs: +ping?.ping,
          pingJitterMs: +ping?.jitter,
          pingLossPercent: +ping?.loss,
        },
      },
    };

    this.devicesEventsService.sendDeviceInfoUpdate(resultData);
  }

  private getTestTypeFromNetworkTestResults(results: NetworkTestResults) {
    if ("undefined" === typeof results.currentTestIndex) {
      return this.UNKNOWN_TEST_TYPE;
    }

    const currentTestResult = results.results[results.currentTestIndex];

    return this.getTestTypeFromNetworkTestResult(currentTestResult);
  }

  private getTestTypeFromNetworkTestResult(result: NetworkTestResult) {
    return result?.testConfig?.type || this.UNKNOWN_TEST_TYPE;
  }

  parseTestValues(result: NetworkTestResult) {
    result.values.forEach((value) => {
      const type = this.getTestTypeFromNetworkTestResult(result);
      if (Object.keys(this.testResults).includes(type)) {
        this.testResults[type][value.metric] = value.value;
      } else {
        this.logger.error(
          "Device usage and network component not set up to handle test type",
          type
        );
      }
    });
  }

  startNetworkTest() {
    this.devMode.on = false;
    this.series = { transfer: [], latency: [] };
    this.values = { transfer: [], latency: [] };
    this.testResults = {
      ping: {
        ping: 0,
        run: false,
        complete: false,
        jitter: 0,
        loss: 0,
        group: "latency",
      },
      download: {
        speed: 0,
        run: false,
        complete: false,
        group: "transfer",
      },
      upload: {
        speed: 0,
        run: false,
        complete: false,
        group: "transfer",
      },
      status: {
        started: true,
        completed: false,
      },
    };

    this.testExpanded = true;
    this.testRunning = true;

    // The 'Run Test' or 'Test Again' button was pushed, thus always run test
    this.ipcService
      .send$("messages", {
        namespace: "speedtest",
        action: "run",
        data: {
          throttle_ms: 500,
        },
      })
      .subscribe(
        (update: SpeedTestIPCMessage) => {
          this.ngZone.run(() => {
            this.parseTestResults(update.update);
          });
        },
        (err) => {
          console.error("Speedtest error", err);
        },
        () => {
          // observable completed
          this.testRunning = false;
        }
      );
  }
}
