import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  ViewChild,
  ElementRef,
  AfterViewInit,
  OnDestroy,
} from "@angular/core";
import { Device, SetSimplePasswordResponse } from "@poly/hub-native";
import { DeviceManagerService } from "../../../services/device-manager.service";
import { ILoggingService } from "../../../services/logging.service";
import { Subscriptions } from "../../../utils/subscriptions";
import { COUNTRIES } from "../../../utils/countries";

export interface Identity {
  country: string;
  deviceName: string;
  password: string;
  isWeekPassword: boolean;
}

@Component({
  selector: "oz-ftc-identity",
  templateUrl: "./ftc-identity.component.pug",
})
export class FtcIdentityComponent implements OnInit, OnDestroy, AfterViewInit {
  @Input() device: Device;
  @Output() newPasswordAndIdentitySuccess = new EventEmitter<Identity>();
  @ViewChild("input") input: ElementRef;

  countryList: { value: string; text: string }[] = [];
  selectedCountry = "";
  deviceName = "";
  password = "";

  @Input() public showError: boolean = false;
  public simplePassword: boolean;

  private subs = new Subscriptions();

  constructor(
    private deviceManager: DeviceManagerService,
    private logger: ILoggingService
  ) {}

  ngOnInit(): void {
    this.fetchSimplePassword();
    this.countryList = COUNTRIES.map((country) => ({
      value: country.name,
      text: country.name,
    }));
  }

  ngAfterViewInit() {}

  ngOnDestroy() {
    this.subs.unsubscribe();
  }

  private fetchSimplePassword(): void {
    this.deviceManager.simplePassword(this.device.id).then((simplePassword) => {
      this.simplePassword = simplePassword.simple;
    });
  }

  public changeSimplePassword(): void {
    this.simplePassword = !this.simplePassword;
    this.deviceManager
      .setSimplePassword({
        deviceId: this.device.id,
        simple: this.simplePassword,
      })
      .then((response: SetSimplePasswordResponse) => {
        this.logger.log(
          `Simple password changed status: ${response.status}`,
          response
        );
        this.validate();
      });
  }

  onPasswordInputChange(value: string) {
    this.password = value;
    this.validate();
    this.showError = false;
  }

  validate() {
    // Device Name is optional
    if (this.selectedCountry && this.password.length) {
      const identityRequest: Identity = {
        country: this.selectedCountry,
        deviceName: this.deviceName,
        password: this.password,
        isWeekPassword: this.simplePassword,
      };
      this.newPasswordAndIdentitySuccess.emit(identityRequest);
    } else {
      // We need to signify that form is in invalid state.
      // TODO: UI: We should switch to reactive forms.
      this.newPasswordAndIdentitySuccess.emit(null);
    }
  }
}
