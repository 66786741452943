import { UtilityService } from "../services/utility.service";
import { AuthService } from "../services/auth.service";
import { Component, OnInit } from "@angular/core";
import { StorageService } from "../services/storage.service";
import { ActivatedRoute, ParamMap, Router } from "@angular/router";
import { AUTH_RESOLVED_SESSION_KEY } from "../utils/constants";
import { AdminConfig } from "../services/admin-config.service";
import { Location } from "@angular/common";
import { Toasts } from "../shared/components/toast/toasts.service";
import { HomeComponentState } from "./home.component.state";
import { map } from "rxjs/operators";

export const RETURNING_USER_KEY = "RETURNING_USER";

interface TileContent {
  title: string;
  description: string;
  logo: string;
  link: string;
  disabled?: boolean;
  tileType?: string; // this is used for html IDs of cards
}

@Component({
  templateUrl: "./home.component.pug",
})
export class HomeComponent implements OnInit {
  private authCheckTimeout;
  public authLoaded = false;
  public hiddenUser = this.adminConfig.mode === "network";
  public build = UtilityService.getBuild();
  public notification;
  public showFeedbackWidget = false;
  public titleParams = {
    open_tag: "<span class='thin-text'>",
    close_tag: "</span>",
    user_name: "",
  };

  constructor(
    public auth: AuthService,
    private route: ActivatedRoute,
    private storageService: StorageService,
    private adminConfig: AdminConfig,
    private router: Router,
    private location: Location,
    private toasts: Toasts
  ) {
    this.storageService.setItem(RETURNING_USER_KEY, true);
    this.auth.profile$.subscribe((profile) => {
      // profile is NULL for first load, even when logged in.
      if (profile?.given_name) {
        this.titleParams.user_name = profile.given_name;
      }

      this.checkAuthLoaded();
    });

    this.route.queryParamMap.subscribe((params: ParamMap) => {
      this.notification = params.get("notification");
    });

    this.showFeedbackWidget = !UtilityService.isBuildBranchMaster();

    this.toasts.changes
      .pipe(map((ts) => ts.filter((t) => t.type !== "status")))
      .subscribe((ts) => {
        this.showFeedbackWidget = this.showFeedbackWidget && ts.length === 0;
      });
  }

  ngOnInit() {
    const state = this.location.getState() as HomeComponentState;
    if (state?.toast) {
      this.toasts.push(state.toast);
    }
  }

  /**
   * Prevent flash of "Hello, Guest" while still allowing
   * fast-as-possible rendering of "Hello, [User Name]" once
   * auth is resolved.
   *
   * No way to be sure how many times the auth profile subscription
   * will resolve. Sometimes once, sometimes multiple times - sometimes
   * it resolves first with "null", then again later with auth information.
   *
   * The authLoaded flag adds a CSS class the animates the "Hello, ...."
   * with a timed delay of 2 seconds.
   */
  private checkAuthLoaded(called = false) {
    if (this.titleParams.user_name) {
      this.authIsLoaded();
      return;
    }

    if (called) {
      this.authIsLoaded();
      return;
    }

    if (
      true ===
      this.storageService.getItem(AUTH_RESOLVED_SESSION_KEY, false, true)
    ) {
      this.authIsLoaded();
      return;
    }

    if (this.authCheckTimeout) {
      return;
    }

    // It's important that this time is LONGER than the CSS animation delay on .initial-fade-in
    this.authCheckTimeout = setTimeout(() => {
      this.checkAuthLoaded(true);
    }, 3500);
  }

  private authIsLoaded() {
    clearTimeout(this.authCheckTimeout);
    this.storageService.setItem(AUTH_RESOLVED_SESSION_KEY, true, true);
    this.authLoaded = true;
  }

  public tiles: TileContent[] = [
    {
      title: "DEVICE_USAGE.TITLE",
      description: "DEVICE_USAGE.DESCRIPTION",
      logo: "chart",
      link: "/usage-network",
      disabled: this.hiddenUser,
      tileType: "insights",
    },
    {
      title: "HEALTH_AND_WELLNESS.TITLE",
      description: "HEALTH_AND_WELLNESS.DESCRIPTION",
      logo: "health",
      link: "/health-wellness/soundscaping",
      tileType: "wellness",
    },
    {
      title: "BEST_PRACTICES.TITLE",
      description: "BEST_PRACTICES.DESCRIPTION",
      logo: "play_on_screen",
      link: "/best-practices/headset",
      tileType: "practices",
    },
    {
      title: "SUPPORT.TITLE",
      description: "SUPPORT.DESCRIPTION",
      logo: "support",
      link: "/poly-support/app",
      tileType: "support",
    },
  ];

  onTileClicked(tile: TileContent) {
    if (!tile.disabled) {
      this.router.navigate([tile.link]);
    }
  }

  openAlphaSite() {
    UtilityService.openExternalBrowser(
      "https://polycom.centercode.com/project/home.html?cap=df76fbb46e6f4550bb7380ae838676e3"
    );
  }
}
